import { Injectable } from '@angular/core';
import { TypeService } from './type.service';

@Injectable({
  providedIn: 'root',
})
export class AssertService {
  public equal(actual: unknown, expected: unknown, message = 'Values are not equal') {
    AssertService.equal(actual, expected, message)
  }

  public notEqual(actual: unknown, expected: unknown, message = 'Values should not be equal') {
    AssertService.notEqual(actual, expected, message)
  }

  public truthy(value: unknown, message = 'Value should be truthy') {
    AssertService.truthy(value, message)
  }

  public falsy(value: unknown, message = 'Value should be falsy') {
    AssertService.falsy(value, message)
  }

  public arrayContainsProps(array: Array<object>, propKeys: Array<string>, message: string): void {
    AssertService.arrayContainsProps(array, propKeys, message)
  }

  public static equal(actual: unknown, expected: unknown, message = 'Values are not equal') {
    if (actual !== expected) {
      throw new Error(message);
    }
  }

  public static notEqual(actual: unknown,expected: unknown, message = 'Values should not be equal') {
    if (actual === expected) {
      throw new Error(message);
    }
  }

  public static truthy(value: unknown, message = 'Value should be truthy') {
    if (!value) {
      throw new Error(message);
    }
  }

  public static falsy(value: unknown, message = 'Value should be falsy') {
    if (value) {
      throw new Error(message);
    }
  }

  public static arrayContainsProps(array: Array<object>,propKeys: Array<string>,message = `Array objects should contain specified properties: ${propKeys}`): void {
    const validToTrace: boolean =
      TypeService.isArray(array) && TypeService.isArrayOfString(propKeys);
    if (validToTrace) {
      AssertService.traceArray(array, propKeys, message);
    } else {
      throw new Error(
        `Passed array is not valid array or props aren't array of string`
      );
    }
  }

  private static traceArray(array: Array<object>,propKeys: Array<string>,message = `Array objects should contain specified properties: ${propKeys}`): void {
    for (const obj of array) {
      if (!AssertService.hasAllProps(obj, propKeys)) {
        throw new Error(message);
      }
    }
  }

  private static hasAllProps(obj: object, propKeys: Array<string>): boolean {
    return propKeys.every((propKey) => propKey in obj);
  }
}
