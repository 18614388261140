import { Component, Input, OnInit } from '@angular/core';

import { FormsComponent } from '@components/forms/forms.component';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {

  @Input() field: any;
  @Input() value: any;
  @Input() errors: any;

  constructor(
    private formsComponent: FormsComponent,
  ) { }

  ngOnInit(): void {
    if (this.value.val === undefined || this.value.val == '') {
      this.value.val = false;
    }
    if (this.value.val == 'true') {
      this.value.val = true;
    }
    this.formsComponent.checkForm(this.field.campoId, false);
  }

  changed() {
    this.formsComponent.setValueFieldForm(this.field.campoId, this.field);
    this.formsComponent.applyUIRules(this.field, this.value);
    this.formsComponent.fireEvents('ValueChanged', this.field.events, { 
      value: this.value.val, 
      field: this.field
    });
    this.formsComponent.checkForm(this.field.campoId, false);
  };

}
