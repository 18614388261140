import { Injectable } from '@angular/core';
import * as math from 'mathjs';
import {
  BigNumber,
  Complex,
  Fraction,
  MathCollection,
  MathNumericType,
  MathScalarType,
  MathType,
  NoLiteralType,
  Unit,
} from 'mathjs';

@Injectable({
  providedIn: 'root',
})
export class MathService {
  public add<T extends MathType>(x: T, y: T): T {
    return math.add(x, y);
  }

  public subtract(x: MathType, y: MathType): MathType {
    return math.subtract(x, y);
  }

  public multiply(x: number, y: number): number {
    return math.multiply(x, y);
  }

  public divide(x: number, y: number): number {
    return math.divide(x, y);
  }

  public mod<T extends number | BigNumber | Fraction | MathCollection>(
    x: T,
    y: number | BigNumber | Fraction | MathCollection
  ): NoLiteralType<T> {
    return math.mod(x, y);
  }

  public unaryMinus<T extends MathType>(x: T): T {
    return math.unaryMinus(x);
  }

  public exp<T extends number | BigNumber | Complex>(x: T): T {
    return math.exp(x);
  }

  public log<T extends number | BigNumber | Complex>(
    x: T,
    base?: number | BigNumber | Complex
  ): NoLiteralType<T> {
    return math.log(x, base);
  }

  public sqrt(x: number): number | Complex {
    return math.sqrt(x);
  }

  public sin(x: number | Unit): number {
    return math.sin(x);
  }

  public cos(x: number | Unit): number {
    return math.cos(x);
  }

  public tan<T extends BigNumber | Complex>(x: T): T {
    return math.tan(x);
  }

  public sec(x: number | Unit): number {
    return math.sec(x);
  }

  public csc(x: number | Unit): number {
    return math.csc(x);
  }

  public cot(x: number | Unit): number {
    return math.cot(x);
  }

  public sinh(x: number | Unit): number {
    return math.sinh(x);
  }

  public cosh(x: number | Unit): number {
    return math.cosh(x);
  }

  public tanh(x: number | Unit): number {
    return math.tanh(x);
  }

  public sech(x: number | Unit): number {
    return math.sech(x);
  }

  public csch(x: number | Unit): number {
    return math.csch(x);
  }

  public coth(x: number | Unit): number {
    return math.coth(x);
  }

  public gamma<T extends number | BigNumber | Complex>(n: T): NoLiteralType<T> {
    return math.gamma(n);
  }

  public factorial<T extends number | BigNumber | MathCollection>(
    n: T
  ): NoLiteralType<T> {
    return math.factorial(n);
  }

  public transpose<T extends MathCollection>(x: T): T {
    return math.transpose(x);
  }

  public det(x: MathCollection): number {
    return math.det(x);
  }

  public inv<T extends number | Complex | MathCollection>(
    x: T
  ): NoLiteralType<T> {
    return math.inv(x);
  }

  public dot(x: MathCollection, y: MathCollection): number {
    return math.dot(x, y);
  }

  public cross(x: MathCollection, y: MathCollection): MathCollection {
    return math.cross(x, y);
  }

  public ones(
    size?: number | number[] | BigNumber | BigNumber[],
    format?: string
  ): MathCollection {
    return math.ones(size, format);
  }

  public mean<T extends MathScalarType>(...args: T[]): T {
    return math.mean(args);
  }

  public median(...args: MathScalarType[]): MathScalarType {
    return math.median(args);
  }

  public min(...args: MathScalarType[]): MathScalarType {
    return math.min(args);
  }

  public max<T extends MathScalarType>(...args: T[]): T {
    return math.max(args);
  }

  public variance(
    array: MathCollection,
    dimension?: number,
    normalization?: 'unbiased' | 'uncorrected' | 'biased'
  ): MathNumericType[] {
    return math.variance(array, dimension, normalization);
  }

  public std(
    array: MathCollection,
    dimension?: number,
    normalization?: 'unbiased' | 'uncorrected' | 'biased'
  ): MathNumericType[] {
    return math.std(array, dimension, normalization);
  }

  public pi() {
    return math.pi;
  }

  public e() {
    return math.e;
  }
}
