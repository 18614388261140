import { Injectable } from '@angular/core';

import { ServerTxApiService } from '@services/server-tx-api.service';
import { LocalTxApiService } from '@services/local-tx-api.service';
import { OfflineWorkerService } from './offline-worker.service';
import backandGlobal from '@env/env';
import { AttachmentsService } from './attachments.service';

@Injectable({
  providedIn: 'root'
})
export class ProxyTxApiService {

  constructor(
    private serverTxApiService: ServerTxApiService,
    private localTxApiService: LocalTxApiService,
    private offlineWorkerServices: OfflineWorkerService,
    private attachmentService: AttachmentsService
  ) { }

  synchronizeData() {
    if (localStorage.getItem('EntityNamesChanged'))
      this.offlineWorkerServices.executeOfflineWorker(localStorage.getItem('EntityNamesChanged'), JSON.stringify(backandGlobal), localStorage.getItem('Authorization'), sessionStorage.getItem('tenantId')).then((data: any) => {
        if (data) {
          let entities = JSON.parse(localStorage.getItem('EntityNamesChanged'));
          let newEntities =  entities.filter(item => !data.includes(item));
          if(newEntities.length > 0)
            localStorage.setItem('EntityNamesChanged', JSON.stringify(newEntities));
          else
            localStorage.removeItem('EntityNamesChanged');
        }
      }).catch((err: any) => console.log('ErrorDataSyncOffline', err));
  }

  getTxData(url: string, opt: any, status: boolean, params?: any): Promise<any> {
    if (backandGlobal.stateOnline) {
      this.synchronizeData()
      return this.serverTxApiService.getTxData(url, opt);
    } else {
      var options = JSON.parse(opt);
      var pageConfig = {
        limit: options.pageSize,
        skip: options.skip,
        include_docs: true
      };
      pageConfig.limit = 100000;
      var cascade = undefined;
      if (options.filter != undefined) {
        if (options.filter.filters[0].field) {
          pageConfig.limit = 100000;
          cascade = options.filter.filters[0];
        }
      }

      return this.localTxApiService.getAllLocalData(params, pageConfig, cascade, options.includeEntities);
    }
  }

  getTx(url: string, opt: any, status: boolean, params?: any): Promise<any[]> {
    if (backandGlobal.stateOnline) {
      this.synchronizeData()
      return this.serverTxApiService.getTxData(url, opt);
    } else {
      return this.localTxApiService.getLocalData(params.viewName, params.id);
    }
  }

  getTxDetailsData(url: string, opt: any, status: boolean, params?: any): Promise<any[]> {
    if (backandGlobal.stateOnline) {
      this.synchronizeData()
      return this.serverTxApiService.getTxData(url, opt);
    } else {
      var options = JSON.parse(opt);
      var pageConfig = {
        limit: options.pageSize,
        skip: options.skip,
        include_docs: true
      }
      pageConfig.limit = 100000;
      return this.localTxApiService.getAllLocalDetailsData(params, pageConfig);
    }
  }

  getTxDatatimeline(url: string, opc: any, entityName?: any): Promise<any> {
    if (backandGlobal.stateOnline) {
      this.synchronizeData()
      return this.serverTxApiService.getTxDataTimeline(url, opc);
    } else {
      return this.localTxApiService.getAllLocalData(entityName);
    }
  }

  dataGetTx(url: string, status: boolean): Promise<any[]> {
    if (status) {
      this.synchronizeData()
      return this.serverTxApiService.dataGetTx(url);
    }
  }

  getTxFiles(url: string, status: boolean): Promise<any> {
    if (status) {
      this.synchronizeData()
      return this.serverTxApiService.getTxFiles(url);
    }
  }

  operationTxApi(url: string, opt: any, t: string, status: boolean, entityName?: any): Promise<any[]> {
    if (backandGlobal.stateOnline) {
      this.synchronizeData()
      return this.serverTxApiService.operationTxApi(url, opt, t);
    } else {
      return this.localTxApiService.operationLocalData(entityName, opt, t, url);
    }
  }

  operationTxApiDetails(opt: any, t: string, status: boolean, entityName?: any): Promise<any[]> {
    if (status === false) {
      this.synchronizeData()
      return this.localTxApiService.operationLocalDetailsData(entityName, opt, t);
    }
  }

  insertBulkData(url: string, opt: any, status: boolean): Promise<any[]> {
    if (status) {
      this.synchronizeData()
      return this.serverTxApiService.insertBulkData(url, opt);
    }
  }

  execWF(url: string, token: string): Promise<any[]> {
    return this.serverTxApiService.execWF(url, token);
  }

  updateAssignee(url: string, data: any, status: boolean): Promise<any[]> {
    if (status) {
      this.synchronizeData()
      return this.serverTxApiService.updateAssignee(url, data);
    }
  }

  completeTaskTx(url: string, data: any, status: boolean): Promise<any[]> {
    if (status) {
      this.synchronizeData()
      return this.serverTxApiService.completeTaskTx(url, data);
    }
  }

  createUserTx(url: string, data: any, status: boolean): Promise<any[]> {
    if (status) {
      this.synchronizeData()
      return this.serverTxApiService.createUserTx(url, data);
    }
  }

  createRelationTx(url: string, data: any, status: boolean): Promise<any[]> {
    if (status) {
      this.synchronizeData()
      return this.serverTxApiService.createRelationTx(url, data);
    }
  }

  deleteRelationTx(url: string, status: boolean): Promise<any[]> {
    if (status) {
      this.synchronizeData()
      return this.serverTxApiService.deleteRelationTx(url);
    }
  }

  updateRelationTx(url: string, status: any): Promise<any[]> {
    if (status) {
      this.synchronizeData()
      return this.serverTxApiService.updateRelationTx(url);
    }
  }

  getRelationTx(url: string, status: boolean): Promise<any[]> {
    if (status) {
      this.synchronizeData()
      return this.serverTxApiService.getRelationTx(url);
    }
  }

  getNoFriendsTx(url: string, status: boolean): Promise<any[]> {
    if (status) {
      this.synchronizeData()
      return this.serverTxApiService.getNoFriendsTx(url);
    }
  }

  synchronizeFile(ids: string[], url: string) {
    return this.serverTxApiService.synchronizeFile(ids, url);
  }

  async execDB(query: any): Promise<any> {
    if (backandGlobal.stateOnline) {
      var tenantId = sessionStorage.tenantId;
      var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/lappiz/sp/query`;
      var opt = {
        query,
        tenantId,
        parameters: {
          aType: "execTx",
          environment: backandGlobal.environment,
          userId: sessionStorage.userId
        }
      }
      return this.serverTxApiService.execDB(url, opt);
    } else {
      return this.localTxApiService.execBD(query);
    }
  }
}
