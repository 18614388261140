import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { Router, RouteConfigLoadStart, RouteConfigLoadEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DOCUMENT } from '@angular/common';

import { SidebarComponent } from '@components/layout/sidebar/sidebar.component';
import { NavbarComponent } from '@components/layout/navbar/navbar.component';

import 'devextreme/integration/jquery';
import backandGlobal from '@env/env';
import { AppContextService } from '@services/app-context.service';

@Component({
  selector: 'app-base',
  templateUrl: './base.component.html',
  styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit {
  @ViewChild(SidebarComponent) menu: SidebarComponent;
  @ViewChild(NavbarComponent) navbar: NavbarComponent;

  isLoading: boolean;
  arrApps: any[];
  appMenu: any;
  userPhoto: any;
  isAnonymous: any = !localStorage.getItem('Authorization') ? true : false;
  imageApp: string;
  isAdmin: boolean;
  isMobile: boolean;
  configLayout: any;
  isReady: boolean;
  timeIdle: number = 0;
  intervalIdle: any;
  timeOut: number;
  razonSocial: string;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private modalService: NgbModal,
    private appContext: AppContextService
  ) {
    this.isReady = false;
    // Spinner for lazyload modules
    this.router.events.forEach((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.isLoading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.isLoading = false;
      }
    });
    this.configLayout = {
      colorMenu: 'Default',
      colorFooter: 'Default',
      colorHeader: 'Default',
      backgroundMenu: 'Default',
      backgroundFooter: 'Default',
      backgroundHeader: 'Default',
      web: {
        footerHide: false,
        headerHide: false,
        menuHide: false,
        footer: 'Default',
        header: 'Default',
        menu: 'Default',
        footerTemplate: '',
        headerTemplate: '',
        menuTemplate: '',
      },
      mobile: {
        footerHide: false,
        headerHide: false,
        menuHide: false,
        footer: 'Default',
        header: 'Default',
        menu: 'Default',
        footerTemplate: '',
        headerTemplate: '',
        menuTemplate: '',
      },
    };
    this.arrApps = [];
    this.appMenu = '';
    this.userPhoto = '';
    this.imageApp = '';
    this.initializeStateApp();
  }

  ngOnInit(): void {
    //SE COMENTA POR QUE AFECTA LAS VISTAS ANONIMAS
    // if (!localStorage.getItem('Authorization')) {
    //   let appName = ''
    //   if (backandGlobal.defaultApp) {
    //     appName = `/${backandGlobal.defaultApp}`;
    //     // return;
    //   } else if (backandGlobal.CurrentLoginApp) {
    //     appName = `/${backandGlobal.CurrentLoginApp}`;
    //     // return;
    //   } else if (sessionStorage.workspace) {
    //     appName = `/${sessionStorage.workspace}`;
    //   }
    //   this.router.navigate([`auth/login${appName}`]);
    // }
    
    if (!localStorage.getItem('Authorization')) {
      sessionStorage.removeItem('currentApp');
      delete backandGlobal.currentApp;
    }
  }

  private initializeStateApp(): void {
    this.appContext.initState()
      .then(() => console.log('Se ha creado correctamente el registro del stateApp'))
      .catch((error: any) => console.log('Ha ocurrido un error creando el registro del stateApp'));
  }


  onAppsLoaded($event) {
    this.arrApps.push($event);
  }

  onDeviceLoaded($event) {
    this.isMobile = $event;
  }

  onCallMenu($event) {
    this.appMenu = $event;
    this.menu.loadMenu(this.appMenu);
  }

  onPhotoUserLoaded($event) {
    this.userPhoto = $event;
  }

  isanonymous(isAnonymous: any) {
    if (isAnonymous) {
      this.isReady = true;
    }
    this.isAnonymous = isAnonymous;
  }

  onImageApp($event) {
    this.imageApp = $event;
  }

  onIsAdmin($event) {
    this.isAdmin = $event;
  }

  onSessionState($event) {
    if ($event) {
      if (this.modalService.hasOpenModals())
        this.modalService.dismissAll(this.modalService.activeInstances);
    }
    this.navbar?.onLogout($event);
  }

  onRazonSocial($event: string){
    this.razonSocial = $event;
  }

  onLayout($event) {
    this.configLayout = $event;
  }

  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  onIsReady($event: boolean) {
    this.isReady = $event;
  }

  onIdleTime($idleTime: number) {
    this.timeOut = $idleTime;
    this.setEventIdleTime();
    this.intervalIdle = setInterval(() => {
      if (this.timeIdle == this.timeOut) {
        clearInterval(this.intervalIdle);
        this.onSessionState(true);
      } else {
        this.timeIdle += 1;
      }
    }, 1000)
  }

  setEventIdleTime() {
    this.document.addEventListener('mousemove', (e) => {
      e.stopPropagation();
      this.timeIdle = 0;
    })
    this.document.addEventListener('click', (e) => {
      e.stopPropagation();
      this.timeIdle = 0;
    })
    this.document.addEventListener('keypress', (e) => {
      e.stopPropagation();
      this.timeIdle = 0;
    })
    this.document.addEventListener('scroll', (e) => {
      e.stopPropagation();
      this.timeIdle = 0;
    })
  }
}
