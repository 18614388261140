import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { ProxyConfigApiService } from '@services/proxy-config-api.service';
import { ProxyTxApiService } from '@services/proxy-tx-api.service';

import backandGlobal from '@env/env';
import PouchDB from 'pouchdb';
import { IConfigAppGlobal } from '../interfaces/IConfigAppGlobal';
import { EndPointService } from './end-point.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  urlConfigApi: string;
  urlTxApi: string;
  dbAppContext: any;

  constructor(
    private router: Router,
    private proxyTxApiService: ProxyTxApiService,
    private proxyConfigApiService: ProxyConfigApiService,
    private readonly endPointservice: EndPointService
  ) {
    this.urlConfigApi = `${backandGlobal.url}`;
    this.dbAppContext = new PouchDB('bd_AppContext');
    this.urlTxApi = `${backandGlobal.api2}/${sessionStorage.getItem(
      'workspace'
    )}.api/api`;
  }

  public getUser(): object | null {
    const userData = sessionStorage.getItem('LappizUser');
    if (userData) {
      return JSON.parse(userData);
    } else {
      throw new Error('User data not found in session storage');
    }
  }

  getIpUser() {
    return this.proxyConfigApiService.getConfigIpUser();
  }

  createUser(dataItem: any) {
    dataItem.$$app = sessionStorage.configAppName;

    return this.proxyTxApiService.createUserTx(
      `${this.urlTxApi}/lappiz/account/register`,
      JSON.stringify(dataItem),
      true
    );
  }

  register(dataItem: any) {
    dataItem.$$addrole = true;

    return this.createUser(dataItem);
  }

  replicateUser(dataItem: any) {
    let langApp = 'es', runtime = 'beta';

    return this.proxyConfigApiService.configReplicateUser(
      `${this.urlConfigApi}/api/Users/replicate?appCode=${sessionStorage.workspace}&languageApp=${langApp}&runtime=${runtime}`,
      JSON.stringify(dataItem)
    );
  }

  configRegisterExternalLogin(dataItem: any) {
    return this.proxyConfigApiService.configRegisterExternalLogin(`${this.urlConfigApi}/api/Users/registerExternalLogin?appCode=${sessionStorage.workspace}`, JSON.stringify(dataItem));
  }

  replicateDelete(idUser: string, idAplication: string) {
    return this.proxyConfigApiService.configReplicateDelete(
      `${this.urlConfigApi}/api/Users/replicateDel?idUser=${idUser}&idAplication=${idAplication}`
    );
  }

  addAplicationUser(idUser: string, aplicationId: string) {
    return this.proxyConfigApiService.addAplicationUser(
      `${this.urlConfigApi}/api/Users/addAppUser?aplicationId=${aplicationId}&userId=${idUser}`
    );
  }

  replicateUpdate(dataItem: any) {
    let langApp = 'es', runtime = 'beta';
    let dataSend = {
      UserName: dataItem.Email,
      FullName: dataItem.FullName || dataItem.Email,
      Email: dataItem.Email,
      PasswordHash: dataItem.OldPass,
      Id: dataItem.Id,
      Activo: dataItem.Activo,
      Contrasena: dataItem.Contrasena,
      OldPass: dataItem.OldPass,
      TwoFactorEnabled: dataItem.TwoFactorEnabled,  
      AuthType: dataItem.AuthType,
      PhoneNumber: dataItem.PhoneNumber,
    };

    return this.proxyConfigApiService.configReplicateUpdate(
      `${this.urlConfigApi}/api/Users/replicateUpd?appCode=${sessionStorage.workspace}&languageApp=${langApp}&runtime=${runtime}`,
      dataSend
    );
  }

  getInfoUserApp(emailUser: string) {
    return this.proxyConfigApiService.getConfigUserLogged(
      `${this.urlConfigApi}/api/Users/getInfoUserApp?email=${emailUser}&AppId=${sessionStorage.aplicattionuserid}`
    );
  }

  getStatus(emailUser: string) {
    return this.proxyConfigApiService.getConfigUserLogged(
      `${this.urlConfigApi}/api/Users/getStatus?email=${emailUser}`
    );
  }

  confirmEmail(id: string, codeApp: string) {
    return this.proxyConfigApiService.confirmEmail(
      `${this.urlConfigApi}/api/Users/ConfirmEmailRuntime?userId=${id}&code=${codeApp}`
    );
  }

  getLoginEntity(appName: string) {
    return this.proxyConfigApiService.getConfigDataGet(
      `${this.urlConfigApi}/api/view/config/${appName}`
    );
  }

  public async getLoginApp(appName: string): Promise<IConfigAppGlobal> {
    return await this.proxyConfigApiService.getConfigLoginApp(
      this.endPointservice.configAppName(this.urlConfigApi, appName)
    );
  }

  getLoginLdap(dataItem: any) {
    return this.proxyConfigApiService.configGetLoginLdap(
      `${this.urlConfigApi}/api/Account/LoginLDAP`,
      dataItem
    );
  }

  async getUserApps() {
    let response = await this.proxyConfigApiService.getUserApps(`${this.urlConfigApi}/api/Account/userApps?$format=json`);
    if (response == null || (Array.isArray(response) && response.length == 0)) {  
      let codeApp = sessionStorage.getItem('workspace');
      sessionStorage.clear();
      sessionStorage.setItem('workspace', codeApp);
      localStorage.clear();
      this.router.navigate([`/auth/login/${codeApp}`],);
    }
    try{
      const userAppsDoc = {
        _id: 'userApps',
        data: response,
        _rev: null
      };
      const existingDoc = await this.dbAppContext.get('userApps');
      let responseDB = { ok: false };
      if(!existingDoc._rev)
        responseDB = await this.dbAppContext.create(userAppsDoc);
      else{
        userAppsDoc._rev = existingDoc._rev
        responseDB = await this.dbAppContext.put(userAppsDoc);
      }
    if(!responseDB.ok) console.log('Error')
    } catch(error){
      //console.log(error)
      console.log('Error')
    }
    return response;
  }

  getADAuth(link: string) {
    if (link != null) {
      this.router.navigate([link]);
    } else {
      return `La aplicación no cuenta con gestión de usuarios por directorio activo. Si deseas conocer más al respecto ingresa a www.lappiz.io`;
    }
  }

  getAdminProfiles(email: string) {
    return this.proxyConfigApiService.getAdminProfiles(`${this.urlConfigApi}/api/Account/getAdminProfiles?email=${email}`)
  }

  getStatusContract(appId: string) {
    return this.proxyConfigApiService.getStatusContract(`${this.urlConfigApi}/api/Account/getStatusContract?aplicationId=${appId}`)
  }

  getStatusPay(appId: string) {
    return this.proxyConfigApiService.getStatusPay(`${this.urlConfigApi}/api/Account/getStatusPay?aplicationId=${appId}`)
  }

  getAuthTypeUser(user: string) {
    return this.proxyConfigApiService.getAuthTypeUser(`${this.urlConfigApi}/api/Users/getAuthTypeUser?user=${user}`)
  }
}
