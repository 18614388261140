import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Location } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import { NgbModal, NgbNavConfig } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import Speech from 'speak-tts';
import { v4 as uuidv4 } from 'uuid';
import swal from 'sweetalert2'
import { Constants } from '@common/constants';

import { CarouselComponent } from '@components/carousel/carousel/carousel.component';
import { ComboboxComponent } from '@components/combobox/combobox.component';
import { DetailsComponent } from '@components/details/details/details.component';
import { FormulaComponent } from '@components/formula/formula.component';
import { HistoryComponent } from '@components/history/history.component';
import { LoaderViewComponent } from '@components/loader-view/loader-view.component';
import { ModalDetailsComponent } from '@components/details/modal-details/modal-details.component';
import { PopupComponent } from '@components/popup/popup.component';
import { ContextValuesService } from '@services/context-values.service';
import { ProxyConfigApiService } from '@services/proxy-config-api.service';
import { ProxyTxApiService } from '@services/proxy-tx-api.service';
import { UserService } from '@services/user.service';
import { WorkFlowService } from '@services/work-flow.service';

import { RootScopeService } from '@shared/root-scope.service';

import backandGlobal from '@env/env';
import { AssertService } from '@services/assert.service';
import { DelayService } from '@services/delay.service';
import { ScriptService } from '@services/script.service';
import { TypeService } from '@services/type.service';
import { TXService } from '@services/tx.service';
import { ExcelService } from '@services/excel.service';
import { HttpService } from '@services/http.service';
import { MathService } from '@services/math.service';
import { IRulesBase } from 'src/app/interfaces/IRulesBase';
import { ILFCall } from 'src/app/interfaces/ILFCall';
import { NotificationsService } from '@services/notifications.service';

declare var kendo: any;

@Component({
  selector: 'app-forms',
  templateUrl: './forms.component.html',
  styleUrls: ['./forms.component.scss']
})
export class FormsComponent implements OnInit {
  @Input() viewName: any;
  @Input() entityId: any;
  @Input() rowId: any;
  @Input() dato: any;
  @Input() item: any;
  @Input() parent: any;
  @Input() parentRules: any;
  @Input() entityParentId: any;
  @Input() defaultFieldsValues: any;
  @Input() appViewIdDetails: any;
  @Input() isDetails: any;
  @Input() dataToSubmit: any;
  @Input() isViewItem: any;
  @Input() formFields: any[] = [];
  @Input() formValid: boolean = false;

  @ViewChild(ComboboxComponent)
  private comboboxComponent!: ComboboxComponent;

  @ViewChild(CarouselComponent)
  private carouselComponent!: CarouselComponent;

  @ViewChild(FormulaComponent)
  private formulaComponent!: FormulaComponent;

  @ViewChild(DetailsComponent)
  private detailsComponent!: DetailsComponent;

  @ViewChild(PopupComponent)
  private popupComponent!: PopupComponent;

  @ViewChild(LoaderViewComponent)
  private loaderComponent!: LoaderViewComponent;

  load: boolean;
  waiting: boolean;
  active;

  isAnonymous: boolean = !localStorage.getItem('Authorization') ? true : false;
  isFormRegister: boolean = this.isAnonymous === true ? true : false;

  customMessages: any;
  customMessagesFields: any;

  viewButtons: boolean = true;
  actionsMenuOptions: boolean = false;
  showActions: boolean = false;
  nameBack: any;

  searchParams: any;

  configInfo: any;
  paramsForm: any;

  dataToSubmitCache: any;
  detailsSaveName: any;

  appViewId: any;
  tablaId: any;

  pass: any;
  oldPass: any;
  isNew: any;
  saveAndEdit: any;
  continuar: any;

  showSave: any;
  nameSave: any;
  showSaveContinue: any;
  nameSaveContinue: any;
  showSaveEdit: any;
  nameSaveEdit: any;
  showCancel: any;

  allowSave: any;

  submitCaption: any;
  submitAndContinueCaption: any;

  logoApp: any;
  iconSave: any;

  hasHistory: any;
  showHistory: any;

  formLoadedEvent: any;
  selectOptions: any;

  dataForm: any;
  dataItemForm: any;
  selectOptionsForm: any;

  id: any;
  idOffline: any;
  rowsOffline: any;

  fieldOrActionAfecteds = [];
  fieldsCascada = [];
  changeQueryFieldsCascada: any;

  canCompleteTask: boolean;
  taskId: any;
  externalStorage: any;

  alerts: any;
  messages: any;
  createMessages: any;
  updateMessages: any;

  includesForPouch: any;

  listOptionsNavBar: any;
  navData: any;
  parameter: any = 0;

  tabsHeaderCol: any;
  spaceHeader: any;
  tabsHeaderMargin: any;
  optionLabel: string;
  field: any;
  optionscascade: any;
  campocascadeid: any;
  originalUrlBeforeFilter: any;
  lastSectionExpand: string;

  speech: any;
  isReady: boolean;
  isReadyEvent: boolean;
  generalEventsOfEntity: Array<IRulesBase> = [];
  Swal: any;
  constructor(
    private $rs: RootScopeService,
    private config: NgbNavConfig,
    private contextValueServices: ContextValuesService,
    private http: HttpClient,
    private locationApp: Location,
    private modalDetailsComponent: ModalDetailsComponent,
    private modalService: NgbModal,
    private proxyConfigApiService: ProxyConfigApiService,
    private proxyTxApiService: ProxyTxApiService,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService,
    private userService: UserService,
    private workflowService: WorkFlowService,
    private readonly assertService: AssertService,
    private readonly delayService: DelayService,
    private readonly excelService: ExcelService,
    private readonly scriptService: ScriptService,
    private readonly txService: TXService,
    private readonly typeService: TypeService,
    private readonly httpService: HttpService,
    private readonly mathService: MathService,
    private notificationsService: NotificationsService
  ) {
    this.Swal = swal;
    this.isReady = false;
    this.isReadyEvent = true;
    // @ts-ignore
    DevExpress.localization.locale('es');

    this.config.destroyOnHide = false;
    this.customMessages = Constants.MESSAGES;
    this.customMessagesFields = Constants.MESSAGEFIELDS;

    this.createMessages = {
      failure: this.customMessages.failureCreate,
      success: this.customMessages.SuccessCreate
    };

    this.updateMessages = {
      failure: this.customMessages.failureEdit,
      success: this.customMessages.SuccessEdit
    };

    this.configInfo = {
      fields: [],
      categoriesDictionary: {},
      title: '',
      id: null,
      viewName: this.viewName || this.route.snapshot.queryParams.viewName,
      entityId: this.entityId || this.route.snapshot.queryParams.entityId,
      categories: []
    };

    this.searchParams = this.route.snapshot.queryParams;

    //#region  Sección para eventos de Lappiz

    var myService = this;

    myService.evalEvent = (implementation: any, e: any) => {
      var functions = `var toastr = myService.toastr; var getUser = myService.getUser; var getEntityId = myService.getEntityId;
var getAppViewId = myService.getAppViewId; var getRowId = myService.getRowId; var getNewId = myService.getNewId;
var getCurrentItem = myService.getCurrentItem; var getCampoById = myService.getCampoById; var setFieldValue = myService.setFieldValue;
var getFieldValue = myService.getFieldValue; var disableField = myService.disableField; var requireField = myService.requireField;
var visibilityField = myService.visibilityField; var visibilitySection = myService.visibilitySection;
var visibilityLoader = myService.visibilityLoader; var disableAction = myService.disableAction; var isDisableField = myService.isDisableField;
var goLocation = myService.goLocation; var sendEmail = myService.sendEmail; var execQuery = myService.execQuery; var execSP = myService.execSP;
var openCustomModal = myService.openCustomModal; var closeModal = myService.closeModal; var sendSMS = myService.sendSMS;
var sendPush = myService.sendPush; var speakMe = myService.speakMe; var isReadyForm = myService.isReadyForm;var execLF = myService.execLF; var execTX = myService.execTX; var Swal = myService.Swal;

`;

      var functionsToAdd = `var getDate = myService.getDate; var changeEndpoint = myService.changeEndpoint; var refreshDetails = myService.refreshDetails;
      var restService = myService.restService;`;

      for (var i in this.$rs.$rootScope.rules) {
        functions += this.$rs.$rootScope.rules[i].Implementation;
      }

      var wrapper = `try { ${implementation} } catch (error) { console.error("Error: " + error); isReadyForm(true);}`;

      try {

        eval(this.getImplemetationCode(functions, wrapper));
        if (e.cancel !== undefined) {
          this.isReady = true;
          return !e.cancel;
        }
        else return true;
      } catch (error) {
        this.notificationsService.notificationApp('error', `${this.customMessages.ErrorRules} ${error}`);
        this.isReady = true;
        this.isReadyEvent = true;
      }
    }

    myService.transformFunction = (implementation: any) => {
      /*
        Cambiar esta forma de llamar la función, la acción al momento de crearla debe tener un campo
        Implementación y no un combo para seleccionar la regla
      */
      var methodName = implementation.split('function ');
      if (methodName.length > 1) {
        methodName = methodName[1].split('(');
        if (methodName.length > 1) {
          methodName = methodName[0];
          //Se hace esto para poder ejecutar la función que llega, validar si mejor se puede quitar la validación de function
          implementation += `${methodName}();`;
        }
      }

      return implementation;
    }

    myService.fireEvents = (eventName: any, events: any, param: any) => {
      var result = true;
      if (eventName == 'ValueChanged') param.isNew = this.isNew;
      if (!events || events.length == 0) return result;

      let implementationGeneral = this.generalEventsOfEntity.reduce((implementationScriptGeneral: string, event: any) => {
        implementationScriptGeneral += ` ${event.Implementation}`;
        return implementationScriptGeneral;
      }, ' ');
      var implementations = events.filter((event: any) => {
        return event.EventTypeName == eventName;
      });
      for (var i in implementations) {
        var impl = implementations[i];
        result = myService.evalEvent(`${implementationGeneral} ${impl.Implementation}`, param);
        if (!result) {
          this.isReady = true;
          break;
        }
      }

      return result;
    }

    myService.getUser = () => {
      return JSON.parse(sessionStorage.LappizUser);
    }

    myService.getEntityId = () => {
      return this.tablaId;
    }

    myService.getAppViewId = () => {
      return this.appViewId;
    }

    myService.getRowId = () => {
      return !this.isNew ? this.rowId : this.id;
    }

    myService.getNewId = () => {
      return uuidv4();
    }

    myService.getCurrentItem = () => {
      delete this.$rs.$rootScope.getCurrentItem;
      myService.getCurrentItemForm();
      var currentItem = this.$rs.$rootScope.getCurrentItem;
      return currentItem;
    }

    myService.getCampoById = (campoId: any) => {
      myService.getCampoByIdForm(campoId);
      var campo = this.$rs.$rootScope['campo' + campoId];
      delete this.$rs.$rootScope['campo' + campoId];
      return campo;
    }

    myService.setFieldValue = (campoId: any, value: any, toDetails: any) => {
      myService.changeFieldValue(campoId, value, toDetails);
    }

    myService.getFieldValue = (campoId: any) => {
      var fieldValue = myService.getFieldValueForm(campoId);
      return fieldValue;
    }

    myService.disableField = (campoId: any, value: any, toDetails: any) => {
      myService.disableFieldForm(campoId, value, toDetails);
    }

    myService.requireField = (campoId: any, value: any) => {
      myService.requireFieldForm(campoId, value);
    }

    myService.visibilityField = (campoId: any, value: any) => {
      myService.visibilityFieldForm(campoId, value);
    }

    myService.visibilitySection = (sectionId: any, value: any) => {
      myService.visibilitySectionForm(sectionId, value);
    }

    myService.visibilityLoader = (isVisible: any) => {
      this.loaderComponent.loader(isVisible);
    }

    myService.disableAction = (actionId: any, value: any) => {
      myService.disableActionForm(actionId, value);
    }

    myService.isDisableField = (fieldId: any) => {
      myService.isDisableFieldForm(fieldId);
      var isDisabled = this.$rs.$rootScope['isdisabled' + fieldId];
      delete this.$rs.$rootScope['isdisabled' + fieldId];
      return isDisabled;
    }

    myService.goLocation = (url: string) => {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        location.assign(url);
      });
    }

    myService.sendEmail = (smtpsender: string, to: any, subject: any, text: any, html: any, attachments: any[] = [], cc: any[] = [], bcc: any[] = []) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('Authorization')
        })
      };

      var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/utiles/sendEmail`;
      var dataEmail = JSON.stringify({ smtpsender: smtpsender, to: to, subject: subject, text: text, html: html, attachments: attachments, cc: cc, bcc: bcc, parameters: { aType: "sendMail", environment: `${backandGlobal.environment}` } });

      return this.http.post(url, dataEmail, httpOptions).toPromise();
    }

    myService.execDB = (query: string, queryNoSQL?: any) => {
      if (backandGlobal.stateOnline) {
        return this.proxyTxApiService.execDB(query);
      } else {
        return this.proxyTxApiService.execDB(queryNoSQL);
      }
    }

    myService.execQuery = (query: string, queryNoSQL?: any) => {
      return myService.execDB(query, queryNoSQL);
    }

    myService.execSP = (spname: string, params: any[]) => {
      var command = `exec ${spname}`;
      if (params && params.length > 0) {
        command += ` ${params[0]}`;
        for (var i = 1; i < params.length; i++) {
          var element = params[i];
          command += `, ${element}`;
        }
      }

      return myService.execDB(command);
    }

    myService.openCustomModal = (config: any, done: any, cancel: any) => {
      if (config) {
        if (!config.dataItem) config.dataItem = {};
        if (!config.parent) config.parent = {};
        var modalTemplate = '', headerTemplate = '', bodyTemplate = '', footerTemplate = '';

        if (config.htmlTemplate) {
          // Se abre una popup con el html inyectado
          if (config.headerTemplate) {
            headerTemplate = `<div class="modal-header">
              ${config.headerTemplate}
              <button type="button" id="btn-close" class="close" aria-label="Close" (click)="close()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>`;
          }

          if (config.bodyTemplate) {
            let classModal = `modal-body ${config.scrollable ? 'modal-body-scrollable' : ''}`;
            bodyTemplate = `<div class="${classModal}">
              ${config.bodyTemplate}
            </div>`;
          }

          if (config.footerTemplate) {
            footerTemplate = `<div class="modal-footer">
              ${config.footerTemplate}
              <br>`;
            if (config.showBtnsFooter) {
              footerTemplate += `<div class="col-md-12 text-center">
                <button class="btn btn-default active" type="button" id="btn-ok" (click)="ok()">Aceptar</button>
                <button class="btn btn-default active-color" type="button" id="btn-cancel" (click)="cancel()">Cancelar</button>
              </div>`;
            }

            footerTemplate += `</div>`;
          }

          modalTemplate = `${headerTemplate}
          ${bodyTemplate}
          ${footerTemplate}`;
        } else {
          // Se abre una popup con un formulario dependiendo de la configura
          modalTemplate = `<div class="modal-body" style="padding: 0px !important; margin-top: -38px; margin-left: -9px; margin-right: -9px; margin-bottom: -19px; min-height:100px">
            <div *ngIf="!hasHtmlTemplate"> <app-forms [viewName]="viewName" [rowId]="rowId" [entityId]="entityId" [isDetails]="isDetails" [entityParentId]="entityParentId" [parent]="parent" [parentRules]="parentRules" [item]="dataToSubmit" ></app-forms></div>
        </div>`;
        }

        var modalInstance = this.modalService.open(PopupComponent, {
          animation: true,
          size: config.size ? config.size : 'lg',
          scrollable: config.scrollable ? config.scrollable : false,
          centered: config.centered ? config.centered : false,
          backdrop: 'static',
          keyboard: config.keyboard ? config.keyboard : true
        });

        modalInstance.componentInstance.modalTemplate = modalTemplate;
        modalInstance.componentInstance.htmlTemplate = modalTemplate;
        modalInstance.componentInstance.dataToSubmit = config.dataItem;
        modalInstance.componentInstance.entityId = config.entityId;
        modalInstance.componentInstance.entityParentId = config.parent.Id;
        modalInstance.componentInstance.parent = config.parent.fKCode;
        modalInstance.componentInstance.parentRules = config.parentRules;
        modalInstance.componentInstance.rowId = config.dataItem.Id;
        modalInstance.componentInstance.viewName = config.viewName;

        modalInstance.componentInstance.done = done;
        modalInstance.componentInstance.cancelModal = cancel;

        modalInstance.result.then(done).catch(cancel);

        return modalInstance;
      }
    }

    myService.closeModal = (data: any) => {
      this.popupComponent.closeModal(this, data);
    }

    myService.sendSMS = (destination: string, message: string) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('Authorization')
        })
      };
      var data = {
        "message": message,
        "destination": destination
      }
      var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/utiles/sendSMS`;
      var dataSend = JSON.stringify({ data: data, parameters: { aType: "sendSMS", environment: `${backandGlobal.environment}` } });
      return this.http.post(url, dataSend, httpOptions).toPromise();
    }

    myService.sendPush = (usersId: string, data: any) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('Authorization')
        })
      };
      var urlTx = `${backandGlobal.url}/api/notification/getAllowNotification`;
      this.proxyConfigApiService.getSuscriptions(usersId, urlTx).then(pushSuscription => {
        var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/utiles/pushNotification`;
        var dataNotification = JSON.stringify({ pushSuscription: pushSuscription, data: data, parameters: { aType: "pushNotification", environment: `${backandGlobal.environment}` } });
        return this.http.post(url, dataNotification, httpOptions).toPromise();
      }).catch(err => {
        console.log(err);
        this.notificationsService.notificationApp('error', this.customMessages.FailNotification)
        return err;
      })
    }

    myService.speakMe = (lang: string = 'es-ES', voice: string = 'Google español', text: string = '') => {
      this.stop();
      this.speech = null;
      this.speech = new Speech(); // will throw an exception if not browser supported
      if (this.speech.hasBrowserSupport()) {
        // returns a boolean
        this.speech
          .init({
            volume: 1,
            lang: lang,
            rate: 1,
            pitch: 1,
            voice: voice,
            splitSentences: true,
            listeners: {
              onvoiceschanged: (voices) => {
                console.log('Event voiceschanged', voices);
              },
            },
          })
      }

      var temporalDivElement = document.createElement('div');
      // Set the HTML content with the providen
      temporalDivElement.innerHTML = text;
      // Retrieve the text property of the element (cross-browser support)
      var result =
        temporalDivElement.textContent || temporalDivElement.innerText || '';

      this.speech
        .speak({
          text: result,
        })
        .then(() => {
          console.log('Success !');
        })
        .catch((e) => {
          console.error('An error occurred :', e);
        });
    }

    myService.stop = () => {
      this.speech?.cancel();
    }

    myService.getDate = () => {
      var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/utiles/date`;
      var result = this.http.get(url, { responseType: 'text' }).toPromise().then((response: any) => {
        var resultDate = response;
        return new Date(JSON.parse(resultDate).timeinmil);
      });
    }

    myService.changeEndpoint = (campoId: any, query: any) => {
      if (combo) {
        var combo = myService.getCampoById(campoId);
        var url = `#UrlBase#/${backandGlobal.currentApp.name}.api/api/${query}`;
        url = this.contextValueServices.transformODataQuery(url);
        combo.options[0].endPoint.filter.Query = url;
      }
      this.comboboxComponent.urlchanged(url, campoId);
    }

    myService.refreshDetails = () => {
      this.detailsComponent.refreshDetails();
    }

    myService.restService = (url: string, method: any, format: any, body: any) => {
      var restObject = { url, method, format, body };
      var urlConfig = `${backandGlobal.url}/Api/api/utils/restService`;

      return this.proxyConfigApiService.getConfigDataPost(`${urlConfig}`, JSON.stringify(restObject));
    }

    myService.isReadyForm = (sw: boolean) => {
      this.isReadyEvent = sw;
    }

    myService.execLF = (config: ILFCall) => {
      if (!config.nameFunction) return { status: 400, message: 'el campo "nameFunction" es requerido' };
      if (!config.lappizFunctionId) return { status: 400, message: 'el campo "lappizFunctionId" es requerido' };
      const method = config.method || 'post';
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('Authorization') || ''
        })
      };

      var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/functions/${config.nameFunction}`;

      var opt = {
        parameters: {
          userId: sessionStorage.userId,
          aType: "lappizFunction",
          pType: "Execute",
          lappizFunctionId: config.lappizFunctionId,
          environment: `${backandGlobal.environment}`
        }
      }
      if (config.body) {
        let keys = Object.keys(config.body)
        keys.forEach(key => {
          opt[key] = config.body[key];
        })
      }
      return this.http[method.toLowerCase()](url, opt, httpOptions).toPromise();
    }

    myService.execTX = (config): Promise<any> => {
      const { dataItem, entity, Id } = config;
      return new Promise(async (resolve, reject) => {
        try {
          let url = `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api/lappiz/${entity}`;
          let method = 'post';
          if (Id) {
            url = `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api/lappiz/${entity}/${Id}`
            method = 'update';
          }
          let response = await myService.proxyTxApiService.operationTxApi(url, JSON.stringify(dataItem), method, backandGlobal.stateOnline, entity)
          if (backandGlobal.stateOnline === false) {
            var entitiesChanged = localStorage.EntityNamesChanged ? JSON.parse(localStorage.EntityNamesChanged) : [];
            entitiesChanged.filter((entityName) => entityName === entity).length == 0 ? entitiesChanged.push(entity) : '';
            localStorage.EntityNamesChanged = JSON.stringify(entitiesChanged);
          }
          resolve(response);
        } catch (error) {
          reject(error);
        }
      })
    };

    //#endregion

  }

  ngOnInit(): void {
    if (this.isDetails == false) {
      this.viewName = this.viewName || this.route.snapshot.queryParams.viewName;
      this.entityId = this.entityId || this.route.snapshot.queryParams.entityId;
      this.searchParams = this.route.snapshot.queryParams;
      this.rowId = this.parent == 'inForm' ? this.modalDetailsComponent?.rowId : this.rowId || this.route.snapshot.queryParams.rowId;
    }

    if (this.viewName) {
      if (this.rowId) {
        this.initForm({ viewName: this.viewName, rowId: this.rowId, entityId: this.entityId, isDetails: this.isDetails, parentRules: this.parentRules, dataToSubmit: this.item });
      } else {
        this.initForm({ viewName: this.viewName, entityId: this.entityId, isDetails: this.isDetails, parentRules: this.parentRules, dataToSubmit: this.item });
      }
    } else {
      this.initForm(this.searchParams);
    }
  }

  /**
   * Método encargado de cancelar en el formulario
   */
  cancelar() {
    if (this.isDetails || this.parent == 'inForm') {
      this.closeDetails();
      return;
    }

    if (this.isAnonymous) {
      this.router.navigate([`/auth/login`]);
    } else {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.locationApp.back();
      });
    }
  }

  /**
   * Método encargado de hacer redirección
   */
  backLappizUsers() {
    var back = false;

    if (this.$rs.$rootScope.timeline) {
      if (this.$rs.$rootScope.profileAppViewId == this.route.snapshot.queryParams.appViewId) {
        back = true;
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.locationApp.back();
        });
      }
    }

    if (this.viewName == `${sessionStorage.getItem('workspace')}_Users` && !back) {
      var tableUser = this.$rs.$rootScope.pages.filter((n: any) => {
        return n.partId == `${sessionStorage.getItem('workspace')}_Users`;
      });

      if (tableUser.length == 0) {
        this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
          this.locationApp.back();
        });
      }
    }
  }

  /**
   * Método encargado de cerrar los detalles
   */
  closeDetails() {
    this.modalDetailsComponent.closeModal();
  }

  closeAlert(index: any) {
    this.alerts.splice(index, 1);
  }

  /**
   * Método encargado de inicializar el formulario
   */
  async initForm(params: any) {
    this.externalStorage = [];
    this.includesForPouch = [];
    this.fieldsCascada = [];
    this.changeQueryFieldsCascada = false;
    this.customMessages = Constants.MESSAGES;
    this.continuar = false;
    this.saveAndEdit = false;
    this.load = false;
    this.paramsForm = params;
    this.isNew = !(params.rowId || params.dataToSubmit);
    this.isViewItem = location.hash.includes('#/viewItem') ? true : false;

    this.submitCaption = this.isNew ? 'Crear' : 'Actualizar';
    this.submitAndContinueCaption = this.isNew ? 'Crear y seguir' : '';

    this.rowsOffline = [];
    if (backandGlobal.stateOnline === false) {
      if (this.isNew) {
        this.idOffline = this.getNewId();
      } else {
        this.idOffline = params.rowId;
      }
    } else {
      if (this.isNew) {
        this.id = this.getNewId();
      } else {
        this.id = params.rowId;
      }
    }

    if (params.taskId) this.taskId = params.taskId;
    if (this.isDetails) {
      this.tabsHeaderCol = 12;
      this.spaceHeader = '';
      this.tabsHeaderMargin = 'tabs-header-margin';
    } else {
      this.tabsHeaderCol = 12;
      this.spaceHeader = 'space-header';
      this.tabsHeaderMargin = '';
      if (localStorage.getItem('fieldsUploaded')) {
        delete localStorage.fieldsUploaded;
        delete localStorage.fieldsDeleted
      }
    }

    if (!localStorage.getItem('Authorization')) {
      this.isAnonymous = true;
    }

    var formLoadedEvent = {
      entityId: params.entityId,
      entityCode: params.viewCode || this.modalDetailsComponent?.detailsId,
      isNew: this.isNew
    };

    this.formLoadedEvent = formLoadedEvent;

    var paramsViewName: any = {
      dataType: 'view',
      id: params.viewName,
      name: this.appViewIdDetails || params.viewCode || this.modalDetailsComponent?.detailsId,
      entityId: params.entityId
    };

    if (location.hash.includes('appViewId') && !this.isDetails) {
      var entityId = this.entityId || this.route.snapshot.queryParams.entityId;

      if (entityId != params.entityId) {
        paramsViewName.appViewId = undefined;
      }

      paramsViewName.appViewId = this.appViewIdDetails || this.route.snapshot.queryParams.appViewId;
    }

    var entityId = this.entityId || this.route.snapshot.queryParams.entityId;

    if (this.isDetails) {
      if (entityId == params.entityId) {
        paramsViewName.appViewId = this.appViewIdDetails || this.route.snapshot.queryParams.appViewId;
      } else {
        paramsViewName.appViewId = undefined;
      }
    }

    if (this.isDetails && this.appViewIdDetails) {
      paramsViewName.appViewId = this.appViewIdDetails;
    }

    if (!this.isDetails) {
      this.dataToSubmit = null;
    }

    this.appViewId = paramsViewName.appViewId;
    this.tablaId = paramsViewName.entityId;


    await this.proxyConfigApiService.getViewConfig(paramsViewName, async (data: any) => {
      var dataEditing: any = [];
      if (data.permissions[0].rolName == "lappizAnonymous") this.isAnonymous = true;
      for (var i = 0; i < data.dataEditing.length; i++) {
        if (this.isAnonymous == true) {
          sessionStorage.setItem('workspace', data.appName);
          this.isFormRegister = !localStorage.Authorization;
          dataEditing = data.dataEditing[i];
          if (data.Icono) {
            this.$rs.$rootScope.Logo = `data:image/PNG;base64,${data.Icono}`;
            this.logoApp = this.$rs.$rootScope.Logo;
          }
          // TODO: Poner servicio de applyTheme
        } else if (sessionStorage.getItem('rolesId')) {
          if (sessionStorage.getItem('rolesId').includes(',')) {
            let stringRoles = sessionStorage.getItem('rolesId').split(',');
            stringRoles.forEach(rol => {
              if (data.dataEditing[i].rolesId.includes(rol)) {
                dataEditing = data.dataEditing[i];
              }
            });
          } else if (data.dataEditing[i].rolesId.includes(sessionStorage.getItem('rolesId'))) {
            dataEditing = data.dataEditing[i];
          }
        }
      }

      this.allowSave = this.isNew ? dataEditing.allowAdd : dataEditing.allowEdit;

      if (location.hash.includes('viewItem')) {
        this.allowSave = false;
      } else if (this.isViewItem) {
        this.allowSave = false;
      }

      if (backandGlobal.currentApp == undefined || backandGlobal.currentApp == '') {
        backandGlobal.currentApp = { name: data.appName };
      } else {
        if (backandGlobal.currentApp.name == undefined || backandGlobal.currentApp.name == '') {
          backandGlobal.currentApp = { name: data.appName };
        }
      }

      document.body.scrollTop = 0;
      this.dataForm = data;
      this.selectOptionsForm = data;

      if (!this.selectOptions) {
        this.selectOptions = [];
      }

      if (!this.selectOptions[params.viewName] || this.selectOptions[params.viewName].length == undefined) {
        this.selectOptions[params.viewName] = data.selectOptions;
      }

      if (!this.isNew && !params.dataToSubmit) {
        var parameters = {
          "take": 10, "skip": 0, "page": 1, "pageSize": 10,
          "viewName": `${params.viewName}`,
          "filter": {
            "logic": 'or',
            "filters": [
              {
                "field": `[${params.viewName}].[Id]`,
                "operator": 'eq',
                "value": this.rowId || params.rowId
              }
            ]
          },
          "tenantId": `${sessionStorage.getItem('tenantId')}`,
          "parameters": {
            "userId": `${sessionStorage.getItem('userId')}`,
            "appViewId": `${this.appViewIdDetails || this.route.snapshot.queryParams.appViewId}`,
            "pType": 'showinmenu',
            "aType": (!!this.route.snapshot.queryParams.appViewId || !!this.appViewIdDetails) ? 'view' : 'ffija',
            "environment": `${backandGlobal.environment}`
          }
        };

        this.$rs.$rootScope.rowId = this.rowId || params.rowId;
        this.$rs.$rootScope.viewName = params.viewName;

        var objParams = {
          viewName: this.$rs.$rootScope.viewName,
          id: this.$rs.$rootScope.rowId
        };

        var urlTx = `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api/lappiz/get/${this.$rs.$rootScope.viewName}`;

        await this.proxyTxApiService.getTx(`${urlTx}`, JSON.stringify(parameters), backandGlobal.stateOnline, objParams).then((response: any) => {
          if (backandGlobal.stateOnline) {
            this.dataItemForm = response.data.rows[0];
          } else {
            this.dataItemForm = response;
          }

          this.execProcessForm(this.dataForm, this.dataItemForm, this.selectOptionsForm);
        }).catch((error: any) => {
          console.log("Error en tx de formulario:", error);
          this.isReady = true;
          this.isReadyEvent = true;
        });
      } else if (params.dataToSubmit) {
        this.dataItemForm = params.dataToSubmit;
        this.execProcessForm(this.dataForm, this.dataItemForm, this.selectOptionsForm);
      } else {
        this.execProcessForm(this.dataForm);
      }
    }).catch((error: any) => {
      console.log("Error en config de formulario:", error);
      this.isReady = true;
      this.isReadyEvent = true;
    });
  }

  execProcessForm(dataForm: any, dataItem?: any, selectOptions?: any) {
    if (!this.isNew) {
      if (!this.selectOptions[this.paramsForm.viewName]) {
        this.processForm(dataForm, dataItem, this.paramsForm);
      } else {
        this.processForm(dataForm, dataItem, this.paramsForm);
      }
    } else {
      var dataToSubmit: any = {};
      if (this.paramsForm.defaultFieldsValues) {
        var defaultFieldsValues = JSON.parse(this.paramsForm.defaultFieldsValues);
        if (Array.isArray(defaultFieldsValues)) {
          defaultFieldsValues.forEach(defaultOption => {
            dataToSubmit[defaultOption.fieldName] = defaultOption.value;
          });
        }
      }

      if (!this.selectOptions[this.paramsForm.viewName]) {
        this.processForm(dataForm, dataToSubmit, this.paramsForm);
      } else {
        this.processForm(dataForm, dataToSubmit, this.paramsForm);
      }
    }
  }

  /**
   * Método encargado de procesar el formulario
   */
  async processForm(viewConfig: any, dataItem: any, params: any) {
    this.configInfo.Actions = [];

    viewConfig.Actions.forEach(action => {
      if (this.isAnonymous == true) {
        if (!this.configInfo.Actions[action.Name]) {
          action.show = true;
        }

        if (this.isNew) {
          if (action.IsVisibleCreate) {
            action.show = true;
            this.showActions = true;
          } else {
            action.show = false;
          }
        } else if (action.IsVisibleEdit) {
          this.showActions = true;
          action.show = true;
        } else {
          action.show = false;
        }

        action.disabled = false;
        this.configInfo.Actions.push(action);

        if (this.isDetails && action.Type == 'Save') {
          this.nameSave = 'Agregar';
          this.nameSaveContinue = 'Agregar y nuevo';
          this.nameSaveEdit = 'Agregar y editar';
          this.iconSave = 'fa fa-plus-circle';
        }

        if (!this.isDetails && action.Type == 'Save') {
          this.nameSave = 'Guardar';
          this.nameSaveContinue = 'Guardar y nuevo';
          this.nameSaveEdit = 'Guardar y editar';
          this.iconSave = 'fa fa-floppy-o';
        }

        if (action.show && (action.Type == 'Codigo' || action.Type == 'NavegarVista' || action.Type == 'WorkFlow')) {
          this.actionsMenuOptions = true;
        }
      } else if (sessionStorage.getItem('rolesId')) {
        if (sessionStorage.getItem('rolesId').includes(',')) {
          let stringRoles = sessionStorage.getItem('rolesId').split(',');
          stringRoles.forEach(rol => {
            if (action.rolesId.includes(rol)) {
              if (!this.configInfo.Actions[action.Name]) {
                action.show = true;
              }

              if (this.isNew) {
                if (action.IsVisibleCreate) {
                  action.show = true;
                  this.showActions = true;
                } else {
                  action.show = false;
                }
              } else if (action.IsVisibleEdit) {
                this.showActions = true;
                action.show = true;
              } else {
                action.show = false;
              }

              action.disabled = false;
              this.configInfo.Actions.push(action);

              if (this.isDetails && action.Type == 'Save') {
                this.nameSave = 'Agregar';
                this.nameSaveContinue = 'Agregar y nuevo';
                this.nameSaveEdit = 'Agregar y editar';
                this.iconSave = 'fa fa-plus-circle';
              }

              if (!this.isDetails && action.Type == 'Save') {
                this.nameSave = 'Guardar';
                this.nameSaveContinue = 'Guardar y nuevo';
                this.nameSaveEdit = 'Guardar y editar';
                this.iconSave = 'fa fa-floppy-o';
              }

              if (action.show && (action.Type == 'Codigo' || action.Type == 'NavegarVista' || action.Type == 'WorkFlow')) {
                this.actionsMenuOptions = true;
              }
            }
          });
        } else if (action.rolesId.includes(sessionStorage.getItem('rolesId'))) {
          if (!this.configInfo.Actions[action.Name]) {
            action.show = true;
          }

          if (this.isNew) {
            if (action.IsVisibleCreate) {
              action.show = true;
              this.showActions = true;
            } else {
              action.show = false;
            }
          } else if (action.IsVisibleEdit) {
            this.showActions = true;
            action.show = true;
          } else {
            action.show = false;
          }

          action.disabled = false;
          this.configInfo.Actions.push(action);

          if (this.isDetails && action.Type == 'Save') {
            this.nameSave = 'Agregar';
            this.nameSaveContinue = 'Agregar y nuevo';
            this.nameSaveEdit = 'Agregar y editar';
            this.iconSave = 'fa fa-plus-circle';
          }

          if (!this.isDetails && action.Type == 'Save') {
            this.nameSave = 'Guardar';
            this.nameSaveContinue = 'Guardar y nuevo';
            this.nameSaveEdit = 'Guardar y editar';
            this.iconSave = 'fa fa-floppy-o';
          }

          if (action.show && (action.Type == 'Codigo' || action.Type == 'NavegarVista' || action.Type == 'WorkFlow')) {
            this.actionsMenuOptions = true;
          }
        }
      }
    });

    if (location.hash.includes('nameBack')) {
      var parts = location.hash.split('&');
      parts.forEach(item => {
        if (item.includes('nameBack')) {
          this.nameBack = ' - ' + decodeURI(item.split('=')[1]);
        }
      });
    }

    this.showCancel = viewConfig.ShowCancelButton;
    this.hasHistory = viewConfig.HasHistorics;
    this.showHistory = viewConfig.ShowHistorics;

    if (params.hasOwnProperty('type') && params.type == 'edit' && ($(window).width() > 768)) {
      this.showSave = false;
      this.showSaveContinue = false;
      this.showSaveEdit = true;
    } else {
      if (location.hash.includes('viewItem') || this.isViewItem) {
        this.showSave = false;
        this.showSaveContinue = false;
        this.showSaveEdit = false;
      } else {
        this.showSave = true;
        this.showSaveContinue = true;
        this.showSaveEdit = true;
      }
    }

    if (backandGlobal.environment == 'DEV' || backandGlobal.environment == 'TEST' || backandGlobal.environment == 'PROD') {
      if (this.isAnonymous == true && this.route.snapshot.queryParams) {
        this.showSave = true;
        this.showSaveContinue = false;
        this.showSaveEdit = false;
        this.showCancel = true;
        this.showActions = false;
        this.isFormRegister = !localStorage.Authorization;
        this.logoApp = 'images/app.png';
        if (this.$rs.$rootScope.Logo) {
          this.logoApp = this.$rs.$rootScope.Logo;
        }
        // TODO: Llamar applyTheme
      }
    }

    this.dataToSubmit = dataItem;

    this.configInfo.HasHistorics = viewConfig.HasHistorics;
    this.configInfo.ShowHistorics = viewConfig.ShowHistorics;
    this.configInfo.title = viewConfig.captionText;
    this.configInfo.rules = viewConfig.rules;
    this.configInfo.formsEvents = viewConfig.formsEvents;
    this.configInfo.showTabsMenus = viewConfig.DisplayMenuTab;

    this.$rs.$rootScope.rules = viewConfig.formsEvents.filter((event: any) => {
      return event.EventTypeName == 'GlobalGeneral';
    });
    this.generalEventsOfEntity = viewConfig.formsEvents.filter((event: any) => {
      return event.EventTypeName == 'General';
    });
    if (this.configInfo.showTabsMenus) {
      $('#PanelForm').css('margin-top', '0px');
      $('#Form').css('margin-top', '8%');
    } else {
      $('#PanelForm').css('margin-top', '4.3%');
      $('#Form').css('margin-top', '8%');
    }

    if (this.parentRules) {
      if (!this.configInfo.formsEvents) this.configInfo.formsEvents = [];
      this.parentRules.filter((r: any) => {
        return r.EventType == 'Form';
      }).forEach(item => {
        this.configInfo.formsEvents.push(item);
      });
    }

    var dataEditing: any = [];

    for (var i = 0; i < viewConfig.dataEditing.length; i++) {
      if (this.isAnonymous == true) {
        this.isFormRegister = !localStorage.Authorization;
        dataEditing = viewConfig.dataEditing[i];

        if (viewConfig.Icono) {
          this.$rs.$rootScope.Logo = `data:image/PNG;base64,${viewConfig.Icono}`;
          this.logoApp = this.$rs.$rootScope.Logo;
        }
        // TODO: Llamar applyTheme
      } else if (sessionStorage.getItem('rolesId')) {
        if (sessionStorage.getItem('rolesId').includes(',')) {
          let stringRoles = sessionStorage.getItem('rolesId').split(',');
          stringRoles.forEach(rol => {
            if (viewConfig.dataEditing[i].rolesId.includes(rol)) {
              dataEditing = viewConfig.dataEditing[i];
            }
          });
        } else if (viewConfig.dataEditing[i].rolesId.includes(sessionStorage.getItem('rolesId'))) {
          dataEditing = viewConfig.dataEditing[i];
        }
      }
    }

    this.configInfo.columnsInDialog = dataEditing.columnsInDialog;
    this.configInfo.editable = this.isNew && dataEditing.allowAdd || !this.isNew && dataEditing.allowEdit;
    this.configInfo.selectOptions = viewConfig.selectOptions;

    var nroFila = 0;
    var nroColumn = 0;
    var section = '';
    await viewConfig.fields.forEach(this.processField(dataItem, section, nroFila, nroColumn, params));

    this.configInfo.categories = [];

    viewConfig.categories.forEach(cat => {
      if (this.isAnonymous == true) {
        if (cat.ShowTab) {
          if (this.configInfo.categoriesDictionary[cat.TabId]) {
            this.configInfo.categoriesDictionary[cat.TabId].columnsInDialog = cat.columnsInDialog;
            this.configInfo.categoriesDictionary[cat.TabId].ShowTab = cat.ShowTab;
            this.configInfo.categoriesDictionary[cat.TabId].NroColumn = cat.NroColumn;
            this.configInfo.categories.push(this.configInfo.categoriesDictionary[cat.TabId]);
          }
        }
      } else if (sessionStorage.getItem('rolesId')) {
        if (sessionStorage.getItem('rolesId').includes(',')) {
          let stringRoles = sessionStorage.getItem('rolesId').split(',');
          stringRoles.forEach(rol => {
            if (cat.rolesId.includes(rol)) {
              if (cat.ShowTab) {
                if (this.configInfo.categoriesDictionary[cat.TabId]) {
                  this.configInfo.categoriesDictionary[cat.TabId].columnsInDialog = cat.columnsInDialog;
                  this.configInfo.categoriesDictionary[cat.TabId].ShowTab = cat.ShowTab;
                  this.configInfo.categoriesDictionary[cat.TabId].NroColumn = cat.NroColumn;
                  this.configInfo.categories.push(this.configInfo.categoriesDictionary[cat.TabId]);
                }
              }
            }
          });
        } else if (cat.rolesId.includes(sessionStorage.getItem('rolesId'))) {
          if (cat.ShowTab) {
            if (this.configInfo.categoriesDictionary[cat.TabId]) {
              this.configInfo.categoriesDictionary[cat.TabId].columnsInDialog = cat.columnsInDialog;
              this.configInfo.categoriesDictionary[cat.TabId].ShowTab = cat.ShowTab;
              this.configInfo.categoriesDictionary[cat.TabId].NroColumn = cat.NroColumn;
              this.configInfo.categories.push(this.configInfo.categoriesDictionary[cat.TabId]);
            }
          }
        }
      }
    });

    this.configInfo.categories.forEach(category => {
      if (category.ShowTab) {
        category.sections2 = [];
        Object.entries(category.sections).forEach(([key, value]: any) => {
          value.show = true;
          category.sections2.push(value);
        });
      }
    });

    if (this.isNew) {
      this.disableComboCascade();
    }

    if (!this.isNew) {
      this.configInfo.id = dataItem
      this.formLoadedEvent.dataItem = this.dataToSubmit;
    }

    this.getParentDataItem();
    this.fireEvents('FormLoaded', this.configInfo.formsEvents, this.formLoadedEvent);

    if (!this.isNew) {
      var dataLoadedEvent = this.formLoadedEvent;
      dataLoadedEvent.dataItem = dataItem;
      this.oldPass = dataLoadedEvent.dataItem.Contrasena;
      this.fireEvents('ItemLoaded', this.configInfo.formsEvents, dataLoadedEvent);

      if (this.fieldsCascada.length > 0) {
        this.changeQueryFieldsCascada = true;
        setTimeout(() => {
          this.fieldsCascada.forEach(field => {
            var item = { Id: field.value.val };
            this.cascade(item, field);
          });
        }, 1500);

        this.changeQueryFieldsCascada = false;
      }
    }

    if (this.configInfo.categories.length != 0) {
      this.active = this.configInfo.categories[0].catName;
    }
    this.isReady = true;
  }

  /**
   * Método encargado de procesar los campos
   */
  processField(dataItem: any, section: any, numRow: any, numColumn: any, params: any) {
    return (field: any) => {
      let sectionConfiguration = field.sectionConfiguration;
      try {
        if (sectionConfiguration)
          sectionConfiguration = JSON.parse(sectionConfiguration);
        if (sectionConfiguration.type == 'collapsible') {
          if (sectionConfiguration.styles) {
            sectionConfiguration.styles.display = 'flex';
            sectionConfiguration.styles['justify-content'] = 'space-between';
          }
        }
        else sectionConfiguration = '';
      } catch (e) {
        sectionConfiguration = '';
      }

      if (section == '' || section != field.sectionName) {
        section = field.sectionName;
        numRow = 0;
        numColumn = 0;
      }

      this.customMessagesFields = Constants.MESSAGEFIELDS;

      if (field.CustomMessages != null) {
        field.CustomMessages.forEach(message => {
          this.customMessagesFields[message.MessageType] = message.Message;
        });
      }

      if (field.InMemory) {
        field.type = 'Informative';
      }

      var type, currencySymbol;

      switch (field.type) {
        case 'Numeric':
          if (field.displayFormat == 'Currency') {
            type = 'currency';
            currencySymbol = '$';
          } else if (field.displayFormat == 'Percentage') {
            type = 'percentage';
          } else if (field.displayFormat == 'NumberWithSeparator') {
            type = 'numberWithSeparator';
          } else {
            type = 'numeric';
          }
          break;
        case 'Entero':
          type = 'number';
          break;
        case 'DateTime':
          if (field.displayFormat == 'Date_mm_dd' || field.displayFormat == 'Date_dd_mm' || field.displayFormat == 'None' || field.displayFormat == 'Date' || !field.displayFormat) {
            type = 'date';
          } else {
            type = 'datetime';
          }
          break;
        case 'LongText':
          if (field.displayFormat == 'MultiLines') {
            type = 'textarea';
          } else if (field.displayFormat == 'MultiLinesEditor') {
            type = 'editor';
          } else {
            type = 'text';
          }
          break;
        case 'SingleSelect':
          if (field.displayFormat == 'AutoCompleteStartWith' || field.displayFormat == 'AutoCompleteMatchAny' || field.displayFormat == 'SingleSelectRe') {
            type = 'combobox';
          } else {
            type = 'singleSelect';
          }
          break;
        case 'MultiSelect':
          if (field.displayFormat == 'Grid') {
            if (this.isNew) {
              type = 'disabledSubgrid';
            } else {
              type = 'subgrid';
            }
          } else if (field.displayFormat == 'CheckList') {
            type = 'multiSelect';
          } else {
            type = 'text';
          }
          break;
        case 'ShortText':
          if (field.displayFormat == 'MultiLines') {
            type = 'textarea';
          } else if (field.displayFormat == 'MultiLinesEditor') {
            type = 'editor';
          } else if (field.displayFormat == 'Hyperlink') {
            type = 'hyperlink';
          } else if (field.displayFormat == 'Email') {
            type = 'email';
          } else {
            type = 'text';
          }
          break;
        case 'Boolean':
          type = 'checkbox';
          break;
        case 'Url':
          type = 'hyperlink';
          break;
        case 'Email':
        case 'Image':
        case 'Map':
        case 'cronometro':
        case 'carrousel':
        case 'Detalles':
        case 'CheckList':
        case 'Password':
        case 'Formula':
        case 'Foto':
        case 'List':
        case 'ListView':
          type = field.type.toLowerCase();
          break;
        case 'RadioButton':
          type = 'radio';
          break;
        case 'Archive':
          if (field.RutaFtp) {
            type = 'fileLocal';
          } else {
            type = 'file';
          }
          break;
        case 'CustomViewPart':
          type = 'customviewPart';
          break;
        case 'QRCode':
          type = 'QRCode';
          break;
        case 'Time':
          type = 'time';
          break;
        case 'Signature':
          type = 'signature';
          break;
        default:
          type = 'text';
          break;
      }

      var val: any = '';
      if (type != 'list' && type != 'listview')
        if (this.isNew) {
          val = dataItem[field.name] || field.advancedLayout.defaultValue || '';
        } else if (field.EditInOtherEntity) {
          var paths = field.RutaGrid != null ? field.RutaGrid.split('.') : [];
          var obj = this.dataToSubmit;
          for (var i = 0; i < paths.length - 1; i++) {
            obj = obj[`${paths[i]}Details`];
          }

          val = obj[field.name] || '';
          if (obj[field.name] == 0 && field.type == 'SingleSelect') {
            val = '0';
          }
        } else {
          val = dataItem[field.name] || '';
          if (dataItem[field.name] == 0 && field.type == 'SingleSelect') {
            val = '0';
          }
        }

      var f: any = {
        campoId: field.campoId,
        campoParentId: field.campoParentId,
        name: field.name,
        displayName: field.displayName,
        type,
        value: { val },
        columns: field.formLayout.columnSpanInDialog,
        show: this.isNew ? !field.form?.hideInCreate : !field.form?.hideInEdit,
        disabled: this.isNew ? field.form?.disableInCreate : field.form?.disableInEdit,
        required: field.advancedLayout.required,
        viewName: params.viewName,
        relatedViewName: field.relatedViewName,
        relatedParentFieldName: field.relatedParentFieldName,
        minimumValue: field.advancedLayout.minimumValue,
        maximumValue: field.advancedLayout.maximumValue,
        scala: field.advancedLayout.scala,
        tooltip: field.grid?.tooltip,
        defaultValue: field.advancedLayout.defaultValue,
        gridDetails: field.gridDetails,
        IsEditable: field.IsEditable,
        IsSpeech: field.IsSpeech,
        FieldsTemplate: field.FieldsTemplates,
        IsMultiple: field.IsMultiple,
        Format: field.Format,
        ExternalStorage: field.ExternalStorage,
        javaType: field.javaType,
        Ruta: field.RutaGrid,
        Formula: field.Formula,
        Values: field.Values,
        events: field.events,
        SingleSelection: field.SingleSelection,
        EditInOtheEntity: field.EditInOtherEntity,
        Code: ['list', 'listview'].some(x => x == type) ? field.Code.split(";")[0] : field.Code,
        InMemory: field.InMemory,
        Import: field.Import,
        ImportAction: field.ImportAction,
        isFromOtherEntity: field.isFromOtherEntity,
        RutaFtp: field.RutaFtp,
        AuthenticationFtp: field.AuthenticationFtp,
        UserFtp: field.UserFtp,
        PasswordFtp: field.PasswordFtp,
        TablaId: ['list', 'listview'].some(x => x == type) ? field.Code.split(";")[1] : ''
      };

      switch (f.type.toLowerCase()) {
        case 'combobox':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'editor':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'email':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'text':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'map':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'currency':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'entero':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'number':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'numberwithseparator':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'password':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'percentage':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'textarea':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'shorttext':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'longtext':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'singleselect':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'detalles':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: false });
          break;
        case 'radio':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'multiselect':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'checkbox':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'hyperlink':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'foto':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'file':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'customviewpart':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: false });
          break;
        case 'date':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'datetime':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'qrcode':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'time':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
        case 'signature':
          if (f.show)
            this.formFields.push({ campoId: field.campoId, invalid: undefined });
          break;
      }

      if (location.hash.includes('viewItem')) {
        f.disabled = true;
      } else if (this.isViewItem) {
        f.disabled = true;
      }

      if (!this.isNew) {
        f.rowId = dataItem.Id;
      } else {
        if (backandGlobal.stateOnline === false) {
          f.rowId = this.idOffline;
        } else {
          f.rowId = this.id;
        }
      }

      if (field.isFromOtherEntity && !field.EditInOtherEntity) {
        f.IsEditable = false;
      }

      if (field.type == 'carrousel') {
        f.permissions = field.permissions;
      }

      if (field.type == 'Detalles') {
        f.show = true;
        f.IsEditable = true;
      }

      if (this.parentRules) {
        if (!f.events) f.events = [];

        this.parentRules.filter((r: any) => {
          return r.FieldId == f.campoId && r.EventType == 'Control';
        }).forEach(item => {
          f.events.push(item);
        });
      }

      if (f.Formula !== null) {
        if (f.Formula !== '') {
          var formula = f.Formula;
          f.Formula = { historyFormula: [{ field: '', formula, value: '' }] };
        } else {
          f.Formula = null;
        }
      }

      if (this.isAnonymous) {
        f.show = true;
      }

      if (field.isFromOtherEntity) {
        f.Relacion = field.Relacion;
        f.defaultField = field.FieldStandar;
      }

      if ((f.type == 'Entero' || f.type == 'numeric' || f.type == 'numberWithSeparator' || f.type == 'number' ||
        f.type == 'currency' || f.type == 'percentage') && f.defaultValue != null) {
        f.defaultValue = parseInt(f.defaultValue);
        field.advancedLayout.defaultValue = parseInt(field.advancedLayout.defaultValue);
      }

      if (field.categoryId && f.show) {
        if (!this.configInfo.categoriesDictionary[field.categoryId]) {
          let categoryConfiguration = this.dataForm.categories.filter(x => x.TabId == field.categoryId)[0].Configuration
          try {
            categoryConfiguration = JSON.parse(categoryConfiguration);
          } catch (error) {
            categoryConfiguration = '';
          }
          this.configInfo.categoriesDictionary[field.categoryId] = {
            catId: field.categoryId,
            catName: field.categoryName,
            catNameTab: field.categoryName,
            sections: {},
            categoryConfiguration
          }

          var tmpCatName = field.categoryName.replace(/[^a-zA-Z 0-9.]+/g, ' ').split(' ');

          if (tmpCatName.length > 1) {
            this.configInfo.categoriesDictionary[field.categoryId].catName = '';
            tmpCatName.forEach(item => {
              this.configInfo.categoriesDictionary[field.categoryId].catName += `${item}_`;
            });
          }
        }

        if (this.parameter == 0) {
          this.navData = [{
            text: this.configInfo.categoriesDictionary[field.categoryId].catNameTab,
            icon: 'fa fa-list-ul'
          }];
          this.parameter++;
        } else {
          var validate;
          for (var item in this.navData) {
            if (this.navData[item].text == this.configInfo.categoriesDictionary[field.categoryId].catNameTab) {
              validate = true;
            } else {
              validate = false;
            }
          }

          if (!validate) {
            this.navData.push({
              text: this.configInfo.categoriesDictionary[field.categoryId].catNameTab,
              icon: 'fa fa-list-ul'
            });
          }
        }

        this.listOptionsNavBar = {
          dataSource: this.navData,
          height: '95%'
        };

        if (!this.configInfo.categoriesDictionary[field.categoryId].sections[field.sectionId]) {
          let sectionConfiguration = field.sectionConfiguration;
          try {
            if (sectionConfiguration) {
              sectionConfiguration = JSON.parse(sectionConfiguration);
              if (sectionConfiguration.type == 'collapsible') {
                if (sectionConfiguration.styles) {
                  sectionConfiguration.styles.display = 'flex';
                  sectionConfiguration.styles['justify-content'] = 'space-between';
                }
              }
            }
            else sectionConfiguration = '';
          } catch (e) {
            sectionConfiguration = '';
          }
          this.configInfo.categoriesDictionary[field.categoryId].sections[field.sectionId] = {
            sectionId: field.sectionId,
            sectionName: field.sectionName,
            sectionOrder: field.sectionOrder,
            NroColumn: field.ColumnsSection,
            sectionConfiguration,
            rows: [],
            fields: []
          };
        }

        if (this.configInfo.categoriesDictionary[field.categoryId].sections[field.sectionId].fields.filter(field => field.campoId === f.campoId).length == 0) {
          this.configInfo.categoriesDictionary[field.categoryId].sections[field.sectionId].fields.push(f);
        }

        if (numColumn >= field.ColumnsSection) {
          numRow = numRow + 1;
          numColumn = 0;
        }

        if (numColumn == 0) {
          this.configInfo.categoriesDictionary[field.categoryId].sections[field.sectionId].rows[numRow] = { fields: [] };
        }

        numColumn = numColumn + 1;
        this.configInfo.categoriesDictionary[field.categoryId].sections[field.sectionId].rows[numRow].fields.push(f);
      } else {
        this.configInfo.fields.push(f);
      }

      if (f.type == 'singleSelect' || f.type == 'multiSelect') {
        if (this.selectOptions && this.selectOptions[params.viewName]) {
          f.options = this.selectOptions[params.viewName][f.name];
        }
        if (this.isNew) {
          f.inlineEditing = field.form.inlineAdding;
        } else {
          f.inlineEditing = field.form.inlineEditing;
        }
      } else if (type == 'autocomplete' || type == 'combobox') {
        if (this.selectOptions && this.selectOptions[params.viewName] && this.selectOptions[params.viewName][f.name]) {
          f.options = this.selectOptions[params.viewName][f.name];
          if (f.type == 'combobox') {
            this.includesForPouch.push(f.options[0].endPoint.view.replace('Details', ''));
            this.includesForPouch.push(f.name);
          }
        }

        if (this.isNew) {
          f.selected = val;
          if (val) {
            f.value.val = val.value;
          }
        } else {
          this.setValueCombobox(f, val);
        }
      } else if (type == 'currency') {
        f.currencySymbol = currencySymbol;
        f.value.val = Number(val);
      } else if (type == 'numberWithSeparator') {
        f.value.val = Number(val);
      } else if (type == 'hyperlink') {
        var url = '', linkText = '', target = '';
        if (val) {
          var segments = val.split('|');
          if (segments.length == 3) {
            linkText = segments[0];
            target = segments[1];
            url = segments[2];
          } else {
            url = val;
            linkText = val;
          }
        }

        f.value.url = url;
        f.value.linkText = linkText;
        f.value.target = target;
      } else if (type == 'date' || type == 'datetime') {
        if (field.advancedLayout.format) {
          f.format = field.advancedLayout.format;
        } else {
          f.format = field.displayFormat;
        }

        if (!this.isNew && val) {
          var fecha;
          if (typeof val === 'string') {
            if (val.includes('Date')) {
              fecha = val.replace(/[^a-zA-Z 0-9.-]+/g, ' ').split(' ')[2];
            } else {
              fecha = new Date(val).valueOf();
            }
          } else {
            fecha = val.getTime();
          }

          f.value.val = new Date(parseInt(fecha)).toISOString();
        }
      } else if (type == 'percentage') {
        f.value.val = val ? val : val;
      } else if (type == 'image') {
        f.urlPrefix = field.urlPrefix;
      } else if (type == 'formula') {
        f.value.val = val;
      } else if (type == 'radio') {
        f.value.val = val;
      } else if (type == 'customviewPart') {
        f.dataItem = this.dataToSubmit;
      } else if (f.type == 'editor') {
        f.value.val = f.value.val
          .replace(/&amp;/g, "&")
          .replace(/&lt;/g, '<')
          .replace(/&gt;/g, '>')
          .replace('&nbsp;', '  ')
          .replace('&ldquo;', '“')
          .replace('&rdquo;', '”');
      } else if (f.type == 'QRCode') {
        if (val) {
          [f.value.type, f.value.val] = val.split('|');
        }
      } else if (type == 'time') {
        f.value.val = val;
      } else if (type == 'signature') {
        f.value.val = val;
      }

      f.errors = { required: this.customMessagesFields.Required, minimumValue: this.customMessagesFields.MinLength + f.minimumValue, maximumValue: this.customMessagesFields.MaxLength + f.maximumValue, number: this.customMessagesFields.Number, email: this.customMessagesFields.InvalidEmail };

      if (f.options && f.type == 'combobox') {
        if (f.options[0].endPoint.cascadeId != null) {
          this.fieldsCascada.push(f);
        }
      }

      var parameters = window.location.hash.split('&');
      var campo;

      for (var i = 2; i < parameters.length; i++) {
        campo = parameters[i].split('=')[0];
        if (field.name == campo) {
          f.value.val = parameters[i].split('=')[1];
          if (f.type == 'number') {
            f.value.val = parseInt(f.value.val);
          }
          if (f.options) {
            this.setValueCombobox(f, f.value.val);
          }
        }
      }
    }
  }

  /**
   * Método encargado de preparar la data del formulario para envíarla a la tx
   */
  submit(e?: any, action?: any) {
    if (e != undefined) {
      this.continuar = e == 2;
      this.saveAndEdit = e == 3;
    }

    if (action != undefined) {
      this.load = true;
      this.submitAction(action);
    }
  }

  async submitAction(action: any) {
    this.generateDataItem(!this.isDetails);
    this.waiting = true;
    this.alerts = [];

    var submitParams: any = {
      viewName: this.paramsForm.viewName
    };

    if (!this.isNew) {
      submitParams.id = this.rowId || this.paramsForm.rowId;
      var idRowEntity = this.rowId || this.paramsForm.rowId;
    }

    if (!this.isNew && this.viewName == `${sessionStorage.getItem('workspace')}_Users`) {
      this.pass = this.dataToSubmit.Contrasena;
    }

    if (this.configInfo.HasHistorics) {
      await this.defaultValues();
    }

    if (this.dataForm.LogicalDelete === true) {
      this.setValueLogical();
    }

    this.getParentDataItem();
    var savingEvent = this.formLoadedEvent;
    savingEvent.dataItem = this.dataToSubmit;
    if (!this.fireEvents('ItemSaving', this.configInfo.formsEvents, savingEvent)) return;

    if (this.isNew) {
      if (this.isDetails) {
        var entityName = submitParams.viewName;
        this.dataToSubmit.Id = backandGlobal.stateOnline === true ? this.id : this.idOffline;
        this.dataToSubmit.continue = this.continuar;
        this.dataToSubmit.saveAndEdit = this.saveAndEdit;

        // detailsAdded
        this.modalDetailsComponent.detailsAdded(this.dataToSubmit);

        if (this.entityParentId) {
          this.dataToSubmit[this.parent] = this.entityParentId;
        }

        if (backandGlobal.stateOnline === false) {
          this.proxyTxApiService.operationTxApiDetails(JSON.stringify(this.dataToSubmit), 'post', backandGlobal.stateOnline, entityName).then((data: any) => {
            var arrDetails = sessionStorage.detailsAdded ? JSON.parse(sessionStorage.detailsAdded) : [];
            arrDetails.push({ Id: data.id, entityName: entityName });
            sessionStorage.detailsAdded = JSON.stringify(arrDetails);

            var entitiesChanged = localStorage.EntityNamesChanged ? JSON.parse(localStorage.EntityNamesChanged) : [];
            entitiesChanged.filter((entity) => entity === entityName).length == 0 ? entitiesChanged.push(entityName) : '';
            localStorage.EntityNamesChanged = JSON.stringify(entitiesChanged);
          }, this.errorCallback);
        }
        return;
      }

      if (this.isDetails == false) {
        this.viewName = this.viewName || this.route.snapshot.queryParams.viewName;
      }

      if (!this.fireEvents('ItemCreating', this.configInfo.formsEvents, savingEvent)) return;

      var nameEntity = submitParams.viewName;
      var dataPouch = this.rowsOffline;
      var includeForms = [], includeFormsEntities = [];
      var urlCreate = `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api/lappiz/${submitParams.viewName}`;

      if (!backandGlobal.stateOnline) {
        if (this.includesForPouch.length / 2 != 1) {
          for (var i = 0; i < this.includesForPouch.length; i++) {
            if (this.dataToSubmit[this.includesForPouch[i + 1]] != null || this.dataToSubmit[this.includesForPouch[i + 1]] != undefined) {
              includeForms.push(this.includesForPouch[i + 1]);
              includeFormsEntities.push(this.includesForPouch[i]);
              i = i + 1;
            }
          }

          for (var i = 0; i < includeForms.length; i++) {
            if (this.dataToSubmit[includeForms[i]] != null || this.dataToSubmit[includeForms[i]] != undefined) {
              for (var j = 0; j < dataPouch.length; j++) {
                if (this.dataToSubmit[includeForms[i]] == dataPouch[j].Id) {
                  this.dataToSubmit[includeFormsEntities[i]] = dataPouch[j];
                }
              }
            }
          }
        } else {
          if (this.dataToSubmit[this.includesForPouch[1]] != null || this.dataToSubmit[this.includesForPouch[1]] != undefined) {
            for (var i = 0; i < dataPouch.length; i++) {
              if (this.dataToSubmit[this.includesForPouch[1]] == dataPouch[i].Id) {
                this.dataToSubmit[this.includesForPouch[0]] = (dataPouch[i]);
              }
            }
          }
        }
      }

      this.clean(this.dataToSubmit);
      this.cleanMetadataDetails(this.dataToSubmit);

      if (backandGlobal.stateOnline === false) {
        this.dataToSubmit.Id = this.idOffline;
      } else {
        this.dataToSubmit.Id = this.id;
      }

      this.dataToSubmit.tenantId = `${sessionStorage.getItem('tenantId')}`;
      this.dataToSubmit.parameters = {
        'userId': `${sessionStorage.getItem('userId')}`,
        'appViewId': `${this.appViewIdDetails || this.route.snapshot.queryParams.appViewId}`,
        'pType': 'Guardar',
        'aType': `${!!this.route.snapshot.queryParams.appViewId || !!this.appViewIdDetails ? 'view' : 'ffija'}`,
        'environment': `${backandGlobal.environment}`
      };

      if (!nameEntity.includes('Lappiz_Users')) {
        this.proxyTxApiService.operationTxApi(`${urlCreate}`, JSON.stringify(this.dataToSubmit), 'post', backandGlobal.stateOnline, nameEntity).then((data: any) => {
          var objForm = this.dataToSubmit;

          if (backandGlobal.stateOnline === false) {
            objForm.Id = data.id;

            var entitiesChanged = localStorage.EntityNamesChanged ? JSON.parse(localStorage.EntityNamesChanged) : [];
            entitiesChanged.filter((entity) => entity === nameEntity).length == 0 ? entitiesChanged.push(nameEntity) : '';
            localStorage.EntityNamesChanged = JSON.stringify(entitiesChanged);
          } else {
            objForm = data;
          }

          this.endSubmit(objForm, action);
        }, (error: any) => { this.errorCallback(error); });
      } else {
        if (backandGlobal.stateOnline === true) {
          this.userService.getInfoUserApp(this.dataToSubmit.Email).then(async (dataUser: any) => {
            if (dataUser != null) {
              if (dataUser.length != null) {
                this.mostrarMensaje('error', this.customMessages.IsAlreadyRegistered);
              } else if (dataUser.Email) {
                let result = await this.notificationsService.confirmAlert('warning', this.customMessages.IsAlReadyUserInOtherApp);

                if (result) {
                  this.userService.addAplicationUser(dataUser.Id, sessionStorage.aplicattionuserid).then(async (response: any) => {
                    if (response == null) {
                      let responseConfirmAddApplication = await this.notificationsService.confirmAlert('warning', this.customMessages.ConfirmUpdateReplicate);
                      if (responseConfirmAddApplication) {
                        this.dataToSubmit.Id = dataUser.Id;
                        this.proxyTxApiService.operationTxApi(`${urlCreate}`, JSON.stringify(this.dataToSubmit), 'post', backandGlobal.stateOnline, nameEntity)
                          .then((data: any) => {
                            var aux: any = {
                              UserName: this.dataToSubmit.Email,
                              FullName: this.dataToSubmit.FullName || this.dataToSubmit.Email,
                              Email: this.dataToSubmit.Email,
                              PasswordHash: this.dataToSubmit.Contrasena != '' ? this.dataToSubmit.Contrasena : `${sessionStorage.getItem('workspace')}.1234`,
                              Activo: this.dataToSubmit.Activo,
                              Id: dataUser.Id,
                              Contrasena: this.dataToSubmit.Contrasena,
                              OldPass: this.oldPass,
                              PhoneNumber: this.dataToSubmit.Phone,
                              TwoFactorEnabled: this.dataToSubmit.TwoFactorEnable ? this.dataToSubmit.TwoFactorEnable : false,
                              AuthType: this.dataToSubmit.AuthType ? this.dataToSubmit.AuthType : null,
                            };

                            this.userService.replicateUpdate(aux).then(() => {
                              this.endSubmit(this.dataToSubmit, action);
                            });
                            this.mostrarMensaje('success', this.customMessages.SuccessUserRegisterAndUpdate);
                          });
                      } else {
                        this.dataToSubmit.Id = dataUser.Id;
                        this.proxyTxApiService
                          .operationTxApi(`${urlCreate}`, JSON.stringify(this.dataToSubmit), 'post', backandGlobal.stateOnline, nameEntity)
                          .then((data: any) => {
                            this.endSubmit(this.dataToSubmit, action);
                            this.mostrarMensaje('success', this.customMessages.SuccessUserRegister);
                          });
                      }
                    }
                  })
                }
              }
            } else {
              this.proxyTxApiService
                .operationTxApi(`${urlCreate}`, JSON.stringify(this.dataToSubmit), 'post', backandGlobal.stateOnline, nameEntity)
                .then((data: any) => {
                  var objForm = data;
                  var aux: any = {
                    UserName: objForm.Email,
                    FullName: objForm.FullName || objForm.Email,
                    Email: objForm.Email,
                    PasswordHash: objForm.Contrasena,
                    Activo: objForm.Activo,
                    Id: objForm.Id,
                    PhoneNumber: objForm.Phone,
                    TwoFactorEnabled: objForm.TwoFactorEnable ? objForm.TwoFactorEnable : false,
                    AuthType: objForm.AuthType ? objForm.AuthType : null,
                  };

                  this.userService.replicateUser(aux).then(() => {
                    this.endSubmit(objForm, action);
                  });
                },
                  (error: any) => {
                    this.errorCallback(error);
                  }
                );
            }
          });
        } else {
          this.proxyTxApiService.operationTxApi(`${urlCreate}`, JSON.stringify(this.dataToSubmit), 'post', backandGlobal.stateOnline, nameEntity).then((data: any) => {
            var objForm = this.dataToSubmit;
            if (backandGlobal.stateOnline === false) {
              objForm.Id = data.id;
            }

            var entitiesChanged = localStorage.EntityNamesChanged ? JSON.parse(localStorage.EntityNamesChanged) : [];
            entitiesChanged.filter((entity) => entity === nameEntity).length == 0 ? entitiesChanged.push(nameEntity) : '';
            localStorage.EntityNamesChanged = JSON.stringify(entitiesChanged);

            this.endSubmit(objForm, action);
          }, (error) => this.errorCallback(error));
        }
      }
    } else {
      if (this.isDetails) {
        var entityName = submitParams.viewName;
        this.dataToSubmit.continue = this.continuar;
        this.dataToSubmit.saveAndEdit = this.saveAndEdit;

        // detailsAdded
        this.modalDetailsComponent.detailsEdited(this.dataToSubmit);
        if (this.entityParentId) {
          this.dataToSubmit[this.parent] = this.entityParentId;
        }

        if (backandGlobal.stateOnline === false) {
          this.proxyTxApiService.operationTxApiDetails(JSON.stringify(this.dataToSubmit), 'update', backandGlobal.stateOnline, entityName).then((data: any) => {
            var arrDetails = sessionStorage.detailsAdded ? JSON.parse(sessionStorage.detailsAdded) : [];
            arrDetails.push({ Id: data.id, entityName: entityName });
            sessionStorage.detailsAdded = JSON.stringify(arrDetails);

            var entitiesChanged = localStorage.EntityNamesChanged ? JSON.parse(localStorage.EntityNamesChanged) : [];
            entitiesChanged.filter((entity) => entity === entityName).length == 0 ? entitiesChanged.push(entityName) : '';
            localStorage.EntityNamesChanged = JSON.stringify(entitiesChanged);
          }, this.errorCallback);
        }
        return;
      }

      if (this.dataToSubmitCache && this.dataToSubmitCache.Id) {
        savingEvent.dataItem.Id = this.dataToSubmitCache.Id;
      }

      if (!this.fireEvents('ItemEditing', this.configInfo.formsEvents, savingEvent)) return;

      var dataPouch = this.rowsOffline;
      var includeForms = [], includeFormsEntities = [];

      if (!backandGlobal.stateOnline) {
        if (this.includesForPouch.length / 2 != 1) {
          for (var i = 0; i < this.includesForPouch.length; i++) {
            if (this.dataToSubmit[this.includesForPouch[i + 1]] != null || this.dataToSubmit[this.includesForPouch[i + 1]] != undefined) {
              includeForms.push(this.includesForPouch[i + 1]);
              includeFormsEntities.push(this.includesForPouch[i]);
              i = i + 1;
            }
          }

          for (var i = 0; i < includeForms.length; i++) {
            if (this.dataToSubmit[includeForms[i]] != null || this.dataToSubmit[includeForms[i]] != undefined) {
              for (var j = 0; j < dataPouch.length; j++) {
                if (this.dataToSubmit[includeForms[i]] == dataPouch[j].Id) {
                  this.dataToSubmit[includeFormsEntities[i]] = dataPouch[j];
                }
              }
            }
          }
        } else {
          if (this.dataToSubmit[this.includesForPouch[1]] != null || this.dataToSubmit[this.includesForPouch[1]] != undefined) {
            for (var i = 0; i < dataPouch.length; i++) {
              if (this.dataToSubmit[this.includesForPouch[1]] == dataPouch[i].Id) {
                this.dataToSubmit[this.includesForPouch[0]] = (dataPouch[i]);
              }
            }
          }
        }
      }

      var urlUpdate = `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api/lappiz/${submitParams.viewName}/${idRowEntity}`;
      var entityName = submitParams.viewName;
      this.clean(this.dataToSubmit);
      this.cleanMetadataDetails(this.dataToSubmit);
      this.dataToSubmit.tenantId = `${sessionStorage.getItem('tenantId')}`;
      this.dataToSubmit.parameters = {
        'userId': `${sessionStorage.getItem('userId')}`,
        'appViewId': `${this.appViewIdDetails || this.route.snapshot.queryParams.appViewId}`,
        'pType': 'Editar',
        'aType': `${!!this.appViewIdDetails || !!this.route.snapshot.queryParams.appViewId ? 'view' : 'ffija'}`,
        'environment': `${backandGlobal.environment}`
      }

      if (!entityName.includes('Lappiz_Users')) {
        this.proxyTxApiService.operationTxApi(`${urlUpdate}`, JSON.stringify(this.dataToSubmit), 'update', backandGlobal.stateOnline, entityName).then((data: any) => {
          if (backandGlobal.stateOnline === false) {
            var entitiesChanged = localStorage.EntityNamesChanged ? JSON.parse(localStorage.EntityNamesChanged) : [];
            entitiesChanged.filter((entity) => entity === entityName).length == 0 ? entitiesChanged.push(entityName) : '';
            localStorage.EntityNamesChanged = JSON.stringify(entitiesChanged);
          }

          this.endSubmit(this.dataToSubmit, action);
        });
      } else {
        if (backandGlobal.stateOnline === true) {
          this.userService.getStatus(this.dataToSubmit.Email).then((dataUser: any) => {
            if (dataUser == null || !dataUser.Activo || (this.dataToSubmit.Id).toUpperCase() == (dataUser.Id).toUpperCase()) {
              this.proxyTxApiService.operationTxApi(`${urlUpdate}`, JSON.stringify(this.dataToSubmit), 'update', backandGlobal.stateOnline, entityName).then((data: any) => {
                var aux: any = {
                  UserName: this.dataToSubmit.Email,
                  FullName: this.dataToSubmit.FullName || this.dataToSubmit.Email,
                  Email: this.dataToSubmit.Email,
                  PasswordHash: this.dataToSubmit.Contrasena != '' ? this.dataToSubmit.Contrasena : `${sessionStorage.getItem('workspace')}.1234`,
                  Activo: this.dataToSubmit.Activo,
                  Id: dataUser.Id,
                  Contrasena: this.dataToSubmit.Contrasena,
                  OldPass: this.oldPass,
                  PhoneNumber: this.dataToSubmit.Phone,
                  TwoFactorEnabled: this.dataToSubmit.TwoFactorEnable ? this.dataToSubmit.TwoFactorEnable : false,
                  AuthType: this.dataToSubmit.AuthType ? this.dataToSubmit.AuthType : null,
                };

                this.userService.replicateUpdate(aux).then(() => {
                  this.endSubmit(this.dataToSubmit, action);
                });
              }, (error: any) => { this.errorCallback(error) });
            } else {
              this.mostrarMensaje('error', this.customMessages.EmailDuplicate);
            }
          });
        } else {
          this.proxyTxApiService.operationTxApi(`${urlUpdate}`, JSON.stringify(this.dataToSubmit), 'update', backandGlobal.stateOnline, nameEntity).then((data: any) => {
            var entitiesChanged = localStorage.EntityNamesChanged ? JSON.parse(localStorage.EntityNamesChanged) : [];
            entitiesChanged.filter((entity) => entity === nameEntity).length == 0 ? entitiesChanged.push(nameEntity) : '';
            localStorage.EntityNamesChanged = JSON.stringify(entitiesChanged);
            this.endSubmit(this.dataToSubmit, action);
          }, this.errorCallback);
        }
      }
    }
  }

  /**
   * Método encargado de enviar la data del formulario a la tx
   */
  submitForm() {

  }

  clean(obj: any) {
    for (var propName in obj) {
      if (obj[propName] === undefined) {
        delete obj[propName];
      }
      if (obj[propName] === "")
        this.dataToSubmit[propName] = null;
    }
  }

  async iterateFields(fn: any) {
    this.configInfo.categories.forEach(category => {
      if (category.ShowTab) {
        Object.entries(category.sections).forEach(async ([key, value]: any) => {
          value.fields.forEach(fn);
        });
      }
    });
  }

  async defaultValues() {
    if (this.isNew) {
      this.dataToSubmit.Created_by = sessionStorage.getItem('userId');
      this.dataToSubmit.EventType = 'Insertar';
      var dateNow = new Date();
      this.dataToSubmit.Created_date = dateNow;
      this.dataToSubmit.Edited_date = dateNow;
      this.dataToSubmit.Created_date = typeof (this.dataToSubmit.Created_date) == 'object' ? `${this.dataToSubmit.Created_date.toString('yyyy-MM-dd HH:mm:ss')}Z` : !this.dataToSubmit.Created_date.includes('Z') ? `${this.dataToSubmit.Created_date.toString('yyyy-MM-dd HH:mm:ss')}Z` : this.dataToSubmit.Created_date;
      this.dataToSubmit.Edited_date = typeof (this.dataToSubmit.Edited_date) == 'object' ? `${this.dataToSubmit.Edited_date.toString('yyyy-MM-dd HH:mm:ss')}Z` : !this.dataToSubmit.Edited_date.includes('Z') ? `${this.dataToSubmit.Edited_date.toString('yyyy-MM-dd HH:mm:ss')}Z` : this.dataToSubmit.Edited_date;
    } else {
      var dateNow = new Date();
      this.dataToSubmit.Edited_date = dateNow;
      this.dataToSubmit.Edited_date = typeof (this.dataToSubmit.Edited_date) == 'object' ? `${this.dataToSubmit.Edited_date.toString('yyyy-MM-dd HH:mm:ss')}Z` : !this.dataToSubmit.Edited_date.includes('Z') ? `${this.dataToSubmit.Edited_date.toString('yyyy-MM-dd HH:mm:ss')}Z` : this.dataToSubmit.Edited_date;
      this.dataToSubmit.EventType = 'Actualizar';
    }

    this.dataToSubmit.Edited_by = sessionStorage.getItem('userId');
    this.dataToSubmit.UserEmail = localStorage.getItem('userName');
    await this.userService.getIpUser()
      .then((response: any) => {
        this.dataToSubmit.IpAddress = response.ip;
      }).catch((error: any) => {
        console.log(error);
      });
  }

  setValueLogical() {
    this.dataToSubmit.RowStatus = this.dataForm.defaultValueDelete;
  }

  endSubmitEvents(data: any) {
    var savedEvent = this.formLoadedEvent;
    savedEvent.dataItem = data;

    if (this.isNew) {
      this.fireEvents('ItemCreated', this.configInfo.formsEvents, savedEvent);
    } else {
      this.fireEvents('ItemEdited', this.configInfo.formsEvents, savedEvent);
    }

    this.fireEvents('ItemSaved', this.configInfo.formsEvents, savedEvent);
  }

  endSubmitCDN(data: any) {
    for (let i = 0; i < this.externalStorage.length; i++) {
      var urlCDN = `${backandGlobal.url}/api/multimedia/UploadFileExternal/${sessionStorage.getItem('workspaceId')}/${this.viewName}/${data.Id}/${this.externalStorage[i].nameCampoMultimedia}/${this.externalStorage[i].externalStorage}`;

      this.proxyConfigApiService.configSubmitCDN(`${urlCDN}`);
    }
  }

  endSubmitWorkFlow(data: any, endAction: any) {
    if (endAction.HasWorkFlow) {
      if (this.isNew) {
        this.executeWorkFlow({ Action: endAction }, data);
      } else this.executeWorkFlow({ Action: endAction });
    }
  }

  private processElement(element): Array<string> {
    // Divide el elemento por ':', toma la segunda parte
    const part = element.split(':')[1];
    // Divide esa parte por ',', luego divide por '/', y toma la primera parte
    return part.split(',').map(y => y.split('/')[0]);
  }

  private processArray(array): Array<string> {
    // Procesa cada elemento del array y aplana el resultado en un solo array
    return array.flatMap(this.processElement);
  }

  synchronizeFile(array: string[]) {
    let arrayIds = this.processArray(array);
    this.proxyTxApiService.synchronizeFile(arrayIds, `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api/documents/synchronize`).then((response: any) => {
      console.log(response);
    }).catch((error: any) => {
      console.log(error);
    })
  }

  endSubmit(data: any, endAction: any) {
    this.waiting = false;
    this.endSubmitEvents(data);
    this.endSubmitCDN(data);
    this.endSubmitWorkFlow(data, endAction);
    if (localStorage.getItem('fieldsUploaded')) {
      var arrayLocalStorage = JSON.parse(localStorage.getItem('fieldsUploaded'));
      this.synchronizeFile(arrayLocalStorage);
      localStorage.removeItem('fieldsUploaded');
    }
    sessionStorage.removeItem('multiSelect');
    this.messages = this.isNew ? this.createMessages : this.updateMessages;
    this.mostrarMensaje('success', this.messages.success);

    if (this.isDetails) {
      this.closeDetails();
    } else if (!!this.modalDetailsComponent.appViewIdDetails) {
      this.modalDetailsComponent.detailsAdded(data);
    } else if (this.saveAndEdit) {
      var parts = location.hash.split('?')[1].split('&');
      var viewName = '';
      var entityId = '';
      var appViewId = '';
      var rowId = data.Id;

      parts.forEach(item => {
        if (item.includes('viewName')) {
          viewName = item.split('=')[1];
        }
        if (item.includes('entityId')) {
          entityId = item.split('=')[1];
        }
        if (item.includes('appViewId')) {
          appViewId = item.split('=')[1];
        }
      });

      var params: NavigationExtras = {
        queryParams: {
          rowId,
          viewName,
          entityId,
          appViewId
        }
      }

      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/forms'], params);
      });
    } else if (this.continuar) {
      var parts = location.hash.split('?')[1].split('&');
      var viewName = '';
      var entityId = '';
      var appViewId = '';

      parts.forEach(item => {
        if (item.includes('viewName')) {
          viewName = item.split('=')[1];
        }
        if (item.includes('entityId')) {
          entityId = item.split('=')[1];
        }
        if (item.includes('appViewId')) {
          appViewId = item.split('=')[1];
        }
      });

      var params: NavigationExtras = {
        queryParams: {
          viewName,
          entityId,
          appViewId
        }
      }

      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['/forms'], params);
      });
    } else {
      this.redirect(null, null, sessionStorage.getItem('workspaceId'));
    }
  }

  redirect(id: any, entityName: any, workspaceId: any) {
    var params: NavigationExtras = {
      queryParams: {
        viewName: this.viewName || this.route.snapshot.queryParams.viewName,
        entityId: this.entityId || this.route.snapshot.queryParams.entityId
      }
    }

    if (id != undefined && id != null) {
      params.queryParams.rowId = id;
    }

    if (entityName != undefined && entityName != null) {
      params.queryParams.entityName = entityName;
    }

    if (workspaceId != undefined && workspaceId != null) {
      params.queryParams.workspaceId = workspaceId;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.locationApp.back();
      });
    }
  }

  errorCallback(error: any) {
    this.load = false;
    if (error.status == 500) {
      // console.error(error.data, error);
      var messageError = '';
      if (error.data)
        if (error.data.message != undefined) {
          if (error.data.name) {
            switch (error.data.name) {
              case 'SequelizeUniqueConstraintError':
                messageError = `${this.customMessages.RecordDuplicate}'${error.data.original.message.split('(')[1].replace(').', '')}'.`;
                break;
            }
          } else if (error.data) {
            messageError = error.data;
          } else {
            messageError = error.data.message;
          }
        }
      if (error.error?.original?.message) {
        if (error.error.original.message.includes("Cannot insert duplicate")) {
          let currenFieldError = this.dataForm.fields.find(x => error.error.original.message.includes(x.Code));
          messageError = `El valor del campo ${currenFieldError.displayName} ya existe`
        }
      }

      if (messageError) {
        this.alerts = [{ type: 'danger', msg: messageError }];
        this.mostrarMensaje('error', messageError);
      } else {
        this.alerts = [{ type: 'danger', msg: this.messages.failure }];
        this.mostrarMensaje('error', this.messages.failure);
      }
    } else {
      this.alerts = [{ type: 'danger', msg: error.data }];
      this.mostrarMensaje('error', this.messages.failure);
      this.isReady = true;
    }
  }

  configureNavigationObjects(obj: any, path: any) {
    var _paths = path.split('.');
    if (!obj[_paths[0]]) {
      obj[_paths[0]] = {};
    }

    if (_paths.length > 1) {
      this.configureNavigationObjects(obj[_paths[0]], path.substring(path.indexOf('.') + 1));
    }
  }

  cleanMetadata(item: any) {
    delete item.__modified;

    if (!item || !(typeof item === 'object')) return;

    if (item.uid) {
      delete item.uid;
    }

    if (item._events) {
      delete item._events;
    }

    if (item.parent) {
      delete item.parent;
    }

    if (item._handlers) {
      delete item._handlers;
    }

    if (item.continue) {
      delete item.continue;
    }

    if (item.dirty) {
      delete item.dirty;
    }

    if (item.__metadata) {
      delete item.__metadata;
    }

    for (var key in item) {
      if (!item.hasOwnProperty(key)) continue;

      if (item[key] != undefined && item[key] != null && item[key] != "") {
        var obj = item[key];
        if (obj.__deferred) {
          delete obj.__deferred;
        }

        if (typeof obj === 'object' && !(obj instanceof Date)) {
          this.cleanMetadata(obj);
        }

        if (Object.getOwnPropertyNames(obj).length === 0 && typeof obj === 'object' && !(obj instanceof Date)) {
          delete item[key];
        }
      } else if (sessionStorage.getItem('multiSelect')) {
        if (!sessionStorage.getItem('multiSelect').includes(key))
          delete item[key];
      } else if (item[key] == undefined)
        delete item[key];
    }
  }

  cleanMetadataDetails(item: any) {
    if (item.__TempFieldsDetails) {
      for (var i in item.__TempFieldsDetails) {
        delete item[item.__TempFieldsDetails[i]];
      }

      delete item.__TempFieldsDetails;
    }

    for (var key in item) {
      if (!item.hasOwnProperty(key)) continue;

      if (item[key]) {
        var obj = item[key];
        if (typeof obj === "object" && !(obj instanceof Date)) {
          this.cleanMetadataDetails(obj);
        }
      }
    }
  }

  getFieldValueForm(fieldId: any, field?: any) {
    if (!field) {
      field = this.getCampoByIdForm(fieldId);
    }

    if (!field) return null;
    var val = field.value.val;
    if (val) {
      switch (field.type) {
        case 'map':
          return val;
        case 'datetime':
        case 'date':
          return new Date(val);
        case 'multiSelect':
          var valueMultiSelect = "";
          for (let index = 0; index < val.length; index++) {
            if (valueMultiSelect) {
              valueMultiSelect += "," + val[index].value;
            } else {
              valueMultiSelect = val[index].value;
            }
          }
          return valueMultiSelect;
        default:
          if (typeof val == 'object') {
            val = val.value;
          }

          if (val.trim === 'function') {
            return val.trim();
          } else return val;
      }
    } else if (field.type == 'checkbox') {
      return val;
    } else return null;
  }

  getFieldValueExternal(fieldId: any) {
    var field = this.getCampoByIdForm(fieldId);

    if (!field) return;

    this.$rs.$rootScope[`value${fieldId}`] = this.getFieldValueForm(fieldId, field);
  }

  setFieldValueForm(field: any, cleanMeta?: any) {
    if (field.type == 'file') {
      if (localStorage.getItem('fieldsDeleted')) {
        var arrayLocalStorage = JSON.parse(localStorage.getItem('fieldsDeleted'));
        for (let i = 0; i < arrayLocalStorage.length; i++) {
          if (field.Code == arrayLocalStorage[i].split(':')[0]) {
            field.value.val = arrayLocalStorage[i].split(':')[1] != 'null' ? arrayLocalStorage[i].split(':')[1] : null;
          }
        }
      }

      if (localStorage.getItem('fieldsUploaded')) {
        var arrayLocalStorage = JSON.parse(localStorage.getItem('fieldsUploaded'));
        for (let i = 0; i < arrayLocalStorage.length; i++) {
          if (field.Code == arrayLocalStorage[i].split(':')[0]) {
            field.value.val = arrayLocalStorage[i].split(':')[1] != 'null' ? arrayLocalStorage[i].split(':')[1] : null;
          }
        }
      }

      if (this.$rs.$rootScope.arrayFilesDeleted) {
        var arrayFilesLocal = this.$rs.$rootScope.arrayFilesDeleted;
        for (let i = 0; i < arrayFilesLocal.length; i++) {
          if (field.value.val == arrayFilesLocal[i]) {
            field.value.val = '';
          }
        }
      }
    }

    if (field.isFromOtherEntity && !field.EditInOtherEntity) {
      return;
    }

    var val = field.value.val;

    if (field.type == 'detalles') {
      if (val != '' && val != undefined && val != null) {
        var arrayFilesLocal = this.$rs.$rootScope.arrayFilesDeleted;
        let uniqueArrayFilesLocal: any = [...new Set(arrayFilesLocal)];

        for (let i = 0; i < val.length; i++) {
          for (let j = 0; j < uniqueArrayFilesLocal.length; j++) {
            var arrayDetails = Object.entries(val[i]);
            var indexValue = arrayDetails.findIndex((item: any) => {
              var typeDetail = typeof item[1];
              if (typeDetail === 'string') {
                if (item[1].includes('/')) {
                  return item[1].split('/')[0] === uniqueArrayFilesLocal[j] != undefined ? uniqueArrayFilesLocal[j].split('/')[0] : uniqueArrayFilesLocal[j] != null ? uniqueArrayFilesLocal[j].split('/')[0] : '';
                }
              }
            });

            if (indexValue > -1) {
              var indexName = arrayDetails[indexValue][0];
              val[i][`${indexName}`] = '';
            }
          }
        }
      }
    }

    var fieldName = field.name;
    if (field.EditInOtherEntity) {
      var entity = field.Ruta.substring(0, field.Ruta.lastIndexOf('.'));
      if (!this.dataToSubmit[entity]) {
        this.configureNavigationObjects(this.dataToSubmit, entity);
      }
    }

    var paths = field.Ruta ? field.Ruta.split('.') : [];
    var obj2 = this.dataToSubmit;
    var objCache = this.dataToSubmitCache;

    for (var i = 0; i < paths.length - 1; i++) {
      obj2 = obj2[paths[i]];

      if (!this.isNew && objCache) {
        objCache = objCache[`${paths[i]}Details`];
        if (objCache && objCache.Id) {
          obj2.Id = objCache.Id;
        }
      }
    }

    var numeric = false;
    if (field.type == 'numeric' || field.type == 'number' || field.type == 'numberWithSeparator' || field.type == 'percentage' || field.type == 'currency') {
      if (val != null && val != undefined) {
        numeric = true;
      }
    }

    if (field.type == 'checkbox') {
      val = field.value.val ? 1 : 2;
    }

    if (field.type == 'editor') {
      var idCampo = field.campoId;
      var myEditor = kendo.jQuery('#' + idCampo).data("kendoEditor");
      var valueEditor = myEditor.value();
      val = valueEditor;
    }

    if (val != undefined && val != '' || numeric || field.type == 'combobox' && this.isDetails || field.type == 'hyperlink' && field.value.linkText && field.value.url) {
      if (obj2 != undefined) {
        switch (field.type) {
          case 'multiSelect':
            if (obj2[fieldName])
              obj2[fieldName] = null;

            for (let index = 0; index < val.length; index++) {
              if (obj2[fieldName]) {
                obj2[fieldName] += "," + val[index].value
              } else {
                obj2[fieldName] = val[index].value
              }
            }
            break;
          case 'singleSelect':
            obj2[fieldName] = val ? val : '';
            break;
          case 'hyperlink':
            obj2[fieldName] = field.value && field.value.linkText ? `${field.value.linkText}|'_blank'|${field.value.url}` : '';
            break;
          case 'percentage':
            if (isNaN(Number(val))) {
              val = val.replace(',', '.');
            }

            var scalaField = field.scala > 0 ? field.scala : 2;
            obj2[fieldName] = obj2[fieldName] == undefined ? field.value && field.value.val ? Number(val).toFixed(scalaField) : 0 : obj2[fieldName];
            break;
          case 'currency':
            if (isNaN(Number(val))) {
              val = val.replace(',', '.');
            }

            obj2[fieldName] = obj2[fieldName] == undefined ? JSON.stringify(Number(val)) : obj2[fieldName];
            break;
          case 'numeric':
          case 'number':
          case 'numberWithSeparator':
            if (isNaN(Number(val))) {
              val = val.replace(',', '.');
            }

            obj2[fieldName] = obj2[fieldName] == undefined ? Number(val) : obj2[fieldName];
            break;
          case 'map':
            obj2[fieldName] = JSON.stringify(val);
            break;
          case 'carrousel':
            obj2[fieldName] = val;
            if (field.ExternalStorage != null && field.ExternalStorage != undefined && field.ExternalStorage != 'No') {
              var storage: any = {};
              storage.nameCampoMultimedia = field.Code;
              storage.externalStorage = field.ExternalStorage;
              this.externalStorage.push(storage);
            }
            break;
          case 'formula':
            obj2[fieldName] = val.toString();
            break;
          case 'datetime':
            obj2[fieldName] = typeof (val) == 'object' ? `${val.toString('yyyy-MM-dd HH:mm:ss')}Z` : !val.includes('Z') ? `${val.toString('yyyy-MM-dd HH:mm:ss')}Z` : val;
            break;
          case 'date':
            obj2[fieldName] = typeof (val) == 'object' ? `${val.toString('yyyy-MM-dd')}Z` : !val.includes('Z') ? `${val.toString('yyyy-MM-dd')}Z` : val;
            break;
          case 'detalles':
            fieldName = `${field.gridDetails.endPoint.view}`;
            this.detailsSaveName = `${field.gridDetails.endPoint.view}`;
            var listDetails = [];
            var tempFields = val.filter((_item: any) => {
              return _item.__TempFieldsDetails;
            });

            var tempf = [];
            if (tempFields.length > 0) {
              tempf = tempFields[0].__TempFieldsDetails;
            }

            for (var keyname in val) {
              var item = val[keyname];
              if (cleanMeta && !this.isDetails) {
                if (!item.__TempFieldsDetails && tempf.length > 0) {
                  item.__TempFieldsDetails = tempf;
                }
                this.cleanMetadata(item);
              } else {
                this.cleanMetadata(item);
              }
              for (var key in item) {
                if (item[key] === '') {
                  item[key] = null;
                }
              }
              listDetails.push(item);
            }

            if (listDetails.length > 0) {
              obj2[fieldName] = listDetails;
            }
            break;
          case 'checklist':
            if (this.isNew) {
              fieldName = `${field.gridDetails.endPoint.view.replace('Details', '')}`;
              obj2[fieldName] = val;
            }
            break;
          case 'checkbox':
            obj2[fieldName] = val == 1;
            break;
          case 'radio':
            obj2[fieldName] = val;
            break;
          case 'combobox':
            if (val) obj2[fieldName] = val;

            if (this.isDetails) {
              if (field.dataItem) {
                obj2[field.options[0].endPoint.view.replace('Details', '')] = field.dataItem;
              } else {
                obj2[field.options[0].endPoint.view.replace('Details', '')] = {};
              }

              if (!obj2.__TempFieldsDetails) {
                obj2.__TempFieldsDetails = [];
              }

              obj2.__TempFieldsDetails.push(field.options[0].endPoint.view.replace('Details', ''));
            }
            break;
          case 'QRCode':
            obj2[fieldName] = field.value.val && field.value.type ? !field.value.val.includes('|') ? `${field.value.type}|${field.value.val}` : field.value.val : '';
            break;
          default:
            obj2[fieldName] = val ? val : '';
            break;
        }
      }
    } else if (objCache && objCache[fieldName] && (field.type == 'carrousel' || field.type == 'text' || field.type == 'textarea')) {
      obj2[fieldName] = '';
    } else if (field.type != 'detalles' && field.type != 'checklist') {
      if (field.type == 'multiSelect') {
        if (Array.isArray(val) && val.length == 0) {
          obj2[fieldName] = '';
          var multiSelect = `${sessionStorage.getItem('multiSelect')}`;
          if (multiSelect) {
            if (!multiSelect.includes(fieldName)) {
              sessionStorage.setItem('multiSelect', `${multiSelect += ", " + fieldName}`);
            }
          } else {
            sessionStorage.setItem('multiSelect', `${fieldName}`);
          }
        }
      } else {
        obj2[fieldName] = obj2[fieldName] == undefined ? val ? val : '' : obj2[fieldName];
      }
    }
  }

  changeFieldValue(campoId: any, value: any, toDetails: any) {
    this.setCampoValue(campoId, value, toDetails);
  }

  setValueFieldForm(fieldId: any, fieldObj: any) {
    var field = this.getCampoByIdForm(fieldId);

    if (!field) return;

    switch (field.type) {
      case 'combobox':
        field.value.val = fieldObj.value.val;
        this.setValueCombobox(field, fieldObj.value.val);
        break;
      case 'currency':
      case 'numberWithSeparator':
        field.value.val = Number(fieldObj.value.val);
        break;
      case 'hyperlink':
        var url = '', linkText = '', target = '';
        if (field.value.val) {
          var segments = field.value.val.split('|');
          if (segments.length == 3) {
            linkText = segments[0];
            target = segments[1];
            url = segments[2];
          } else {
            url = field.value.val;
            linkText = field.value.val;
          }
        }

        field.value.url = url;
        field.value.linkText = linkText;
        field.value.target = target;
        break;
      case 'date':
      case 'datetime':
        if (fieldObj.value.val) field.value.val = new Date(fieldObj.value.val);
        break;
      default:
        field.value.val = fieldObj.value.val;
        break;
    }
  }

  async setCampoValue(fieldId: any, val: any, toDetails: any) {
    if (toDetails) {
      this.ruleToDetails(fieldId, val, toDetails, 'setFieldValueForm');
      return;
    }

    var f = this.getCampoByIdForm(fieldId);
    if (!f) return;

    switch (f.type) {
      case 'combobox':
        f.value.val = val;
        await this.setValueCombobox(f, val);
        setTimeout(() => {
          var combo = kendo.jQuery(`#${f.campoId}`).data('kendoDropDownList');
          combo.value(f.value.val);
          combo.trigger('change');
        }, 100);
        break;
      case 'currency':
      case 'number':
      case 'numberWithSeparator':
        f.value.val = Number(val);
        var num = kendo.jQuery(`#${f.campoId}`).data('kendoNumericTextBox');
        num.value(f.value.val);
        num.trigger('change');
        break;
      case 'multiSelect':
        var multiselect = kendo.jQuery(`#${f.campoId}`).data('kendoMultiSelect');
        multiselect.value(val);
        multiselect.trigger('change');
        break;
      case 'hyperlink':
        var url = '', linkText = '', target = '';
        if (val) {
          var segments = val.split('|');
          if (segments.length == 3) {
            linkText = segments[0];
            target = segments[1];
            url = segments[2];
          } else {
            url = val;
            linkText = val;
          }
        }

        f.value.url = url;
        f.value.linkText = linkText;
        f.value.target = target;
        break;
      case 'percentage':
        f.value.val = val ? val * 100 : val;
        break;
      case 'date':
      case 'datetime':
        f.value.val = (val) ? new Date(val) : null;
        $(`#${f.campoId}`).dxDateBox('instance').option('value', f.value.val);
        break;
      case 'foto':
        f.value.val = val;
        f.value.webcamImage = val ? `${backandGlobal.URL_API_UPLOAD_IMAGES}${val}` : null;
        f.value.imageFile = val ? `${backandGlobal.URL_API_UPLOAD_IMAGES}${val}` : null;
        f.value.routeImg = null;
        f.value.showimg = val ? true : false;
        f.value.showWebcam = val ? false : true;
        f.value.showbcapture = val ? false : true;
        break;
      default:
        f.value.val = val;
        break;
    }
    if(['email', 'password', 'textarea', 'text'].includes(f.type))
      document.getElementById(f.campoId.toLowerCase()).dispatchEvent(new Event('change'))
  }

  async setValueCombobox(f: any, val: any) {
    if (val != '') {
      var endPoint = f.options[0].endPoint;
      var url = `${backandGlobal.api2 + endPoint.url}lappiz/get/${endPoint.view.replace('Details', '')}`;
      var opc = {
        'take': 10, 'skip': 0, 'page': 1, 'pageSize': 10000,
        'filter': {
          'logic': 'or',
          'filters': [{
            'field': 'Id',
            'operator': 'eq',
            'value': `${val}`
          }]
        },
        'tenantId': `${sessionStorage.getItem('tenantId')}`,
        'parameters': {
          'userId': `${sessionStorage.getItem('userId')}`,
          'aType': 'combo',
          'environment': `${backandGlobal.environment}`
        }
      };

      var entityOff = endPoint.view.replace('Details', '');
      var objParam = {
        viewName: entityOff,
        id: val
      };

      return await this.proxyTxApiService.getTx(`${url}`, JSON.stringify(opc), backandGlobal.stateOnline, objParam).then((response: any) => {
        if (backandGlobal.stateOnline) {
          var dataItem = response.data.rows[0];
          f.dataItem = dataItem;
          // this.comboboxComponent.loadSelectedItem(f, dataItem, val, f);
          this.relChanged(dataItem, val, f);
          return response.data.rows;
        } else {
          var dataItem = response;
          f.dataItem = dataItem;
          // this.comboboxComponent.loadSelectedItem(f, dataItem, val, f);
          this.relChanged(dataItem, val, f);
          return response;
        }
      });
    }
  }

  getActionById(actionId: any) {
    var _action;

    this.configInfo.Actions.forEach(action => {
      if (this.isAnonymous == true) {
        if (action.ActionId.toLowerCase() == actionId.toLowerCase()) {
          _action = action;
        }
      } else if (sessionStorage.getItem('rolesId')) {
        if (sessionStorage.getItem('rolesId').includes(',')) {
          let stringRoles = sessionStorage.getItem('rolesId').split(',');
          stringRoles.forEach(rol => {
            if (action.rolesId.includes(rol)) {
              if (action.ActionId.toLowerCase() == actionId.toLowerCase()) {
                _action = action;
              }
            }
          });
        } else if (action.rolesId.includes(sessionStorage.getItem('rolesId'))) {
          if (action.ActionId.toLowerCase() == actionId.toLowerCase()) {
            _action = action;
          }
        }
      }
    });

    return _action;
  }

  getCampoByIdForm(fieldId: any) {
    var _field;
    this.iterateFields((field: any) => {
      if (field.campoId.toLowerCase() == fieldId.toLowerCase()) {
        _field = field;
      }
    });

    return _field;
  }

  getCampoByIdExternal(campoId: any) {
    this.$rs.$rootScope[`campo${campoId}`] = this.getCampoByIdForm(campoId);
  }

  getCurrentItemForm() {
    this.generateDataItem();
    return this.dataToSubmit;
  }

  getCurrentItemExternal() {
    var currentItem = this.getCurrentItemForm();
    if (this.dataToSubmitCache && currentItem) currentItem.Id = this.dataToSubmitCache.Id;
    this.getParentDataItem();
    var parent = this.formLoadedEvent.parent;
    this.$rs.$rootScope.getCurrentItem = {
      dataItem: currentItem,
      parent
    }
  }

  getParentDataItem() {
    if (this.isDetails) {
      this.getParent(this.route.snapshot.queryParams.entityId);
      this.formLoadedEvent.parent = this.$rs.$rootScope.parent;
    }
  }

  getParent(entityId: any) {
    this.getParentChild(entityId);
  }

  getParentChild(entityId: any) {
    if (this.isDetails) return;
    this.$rs.$rootScope.parent = this.getCurrentItemForm();
    if (this.dataToSubmitCache) this.$rs.$rootScope.parent.Id = this.dataToSubmitCache.Id;
  }

  generateDataItem(cleanMeta: boolean = false) {
    this.dataToSubmitCache = this.dataToSubmit;
    this.dataToSubmit = {};

    this.iterateFields((field: any) => {
      if (field.value.val == '' && (field.type == 'file' || field.type == 'fileLocal') && field.required) {
        this.mostrarMensaje('error', this.customMessagesFields.FieldUploadFileIsRequired);
        var stringCancel = "e.cancel = true; e.isReady = false;"
        eval(stringCancel);
      }
      this.setFieldValueForm(field, cleanMeta);
    });
  }

  actionCode(action: any) {
    var e: any = {
      dataItem: this.getCurrentItemForm()
    };

    if (this.dataToSubmitCache && this.dataToSubmitCache.Id) {
      e.dataItem.Id = this.dataToSubmitCache.Id;
    }

    if (this.isDetails) {
      this.getParentDataItem();
      e.parent = this.formLoadedEvent.parent;
    }

    var impl = this.transformFunction(action.FormEvent);
    var impl = this.transformFunction(action.FormEvent);
    impl += this.generalEventsOfEntity.reduce((acum, event) => {
      if (event.Implementation != action.FormEvent)
        acum += `
            ${event.Implementation}
      `;
      return acum
    }, '');

    impl += this.$rs.$rootScope.rules.reduce((acum, event) => {
      return acum += event.Implementation;
    }, '');

    this.evalEvent(impl, e);
  }

  openDirective(queryString: any, selectedItem: any) {
    var params: NavigationExtras = {
      queryParams: {}
    };

    var parts = queryString.split('?')[1].split('&');

    for (let i = 0; i < parts.length; i++) {
      var value = parts[i].split('=')[1];

      if (value.includes('selectedItem')) {
        var data = {};

        if (selectedItem.dataItem != undefined) {
          data = selectedItem.dataItem;
        } else {
          data = selectedItem.itemData;
        }

        params.queryParams[parts[i].split('=')[0]] = `${data[value.replace('selectedItem.', '')]}`;
      } else {
        params.queryParams[parts[i].split('=')[0]] = parts[i].split('=')[1];
      }
    }

    var path = queryString.split('?')[0];

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`${path}`], params);
    });
  }

  disableActionForm(actionId: string, value: boolean) {
    var action = this.configInfo.Actions.filter((e: any) => {
      return e.ActionId == actionId.toLowerCase();
    })[0];

    action.isEditable = value;
  }

  disableFieldForm(fieldId: any, value: any, toDetails?: any) {
    setTimeout(() => {
      if (toDetails) {
        this.ruleToDetails(fieldId, value, toDetails, 'disableFieldForm');
        return;
      }

      var f = this.getCampoByIdForm(fieldId);
      if (f) {
        f.disabled = value;
      
        // Función para habilitar o deshabilitar el campo Kendo
        const toggleKendoField = (selector: string, widget: string, isDisabled: boolean): void => {
          const field = kendo.jQuery(`#${selector}`).data(widget);
          if (field) {
            field.enable(!isDisabled);
          }
        };
      
        // Seleccionar la acción según el tipo de campo
        switch (f.type) {
          case "date":
          case "datetime":
            $(`#${f.campoId}`).dxDateBox({ disabled: value, type: f.type });
            break;
      
          case "combobox":
            toggleKendoField(f.campoId, "kendoDropDownList", value);
            break;
      
          case "numberWithSeparator":
          case "number":
          case "currency":
          case "percentage":
            toggleKendoField(f.campoId, "kendoNumericTextBox", value);
            break;
      
          case "multiSelect":
            toggleKendoField(f.campoId, "kendoMultiSelect", value);
            break;
        }
      }
    }, 500);
  }

  requireFieldForm(fieldId: any, value: any) {
    var f = this.getCampoByIdForm(fieldId);
    if (f) {
      f.required = value;
    }

    this.checkForm(f.campoId, f.value.val ? false : f.value.val === 0 ? false : f.required);
  }

  visibilityFieldForm(fieldId: any, value: any) {
    var f = this.getCampoByIdForm(fieldId);
    if (f) {
      f.show = value;
    }

    this.checkForm(f.campoId, f.show === false ? false : f.value.val ? false : f.value.val === 0 ? false : f.required);
  }

  visibilitySectionForm(sectionId: any, visibility: any) {
    Object.entries(this.configInfo.categoriesDictionary).forEach((tabs: any) => {
      const [, value] = tabs;
      const sections = value.sections[sectionId];
      if (sections) {
        sections.show = visibility

        const fields = sections.fields;
        fields.forEach((field: any) => {
          this.visibilityField(field.campoId, visibility)
        });
      }
    });
  }

  isDisableFieldForm(fieldId: any) {
    var field: any = {};

    this.configInfo.categories.forEach(category => {
      if (category.ShowTab) {
        Object.entries(category.sections).forEach(([key, value]: any) => {
          field = value.fields.filter((e: any) => {
            return e.campoId = fieldId.toLowerCase();
          })[0];
        });
      }
    });

    return field.IsEditable;
  }

  isDisableFieldExternal(fieldId: any) {
    this.$rs.$rootScope[`isdisabled${fieldId}`] = this.isDisableFieldForm(fieldId);
  }

  fieldHasValue(fieldId: any) {
    var val = this.getFieldValueForm(fieldId);

    return val != '' && val != null && val != undefined;
  }

  isNullOrEmpty(fieldId: any) {
    return !this.fieldHasValue(fieldId);
  }

  ruleToDetails(fieldId: any, val: any, toDetails: any, method: any) {
    var rule = `${method}("${fieldId}", ${val});`;
    if (typeof val == 'string' && val.length != undefined) {
      rule = `${method}("${fieldId}", "${val}");`;
    }

    var event = {
      EventTypeName: toDetails.EventTypeName ? toDetails.EventTypeName : 'FormLoaded',
      Implementation: rule,
      EventType: toDetails.EventType ? toDetails.EventType : 'Form',
      FieldId: toDetails.EventType == 'Control' ? fieldId : ''
    };

    var fieldDetails = this.getCampoByIdForm(toDetails.campoParentId);
    if (!fieldDetails) return;
    if (!fieldDetails.events) fieldDetails.events = [];
    fieldDetails.events.push(event);
  }

  relChanged(item: any, model: any, field: any) {
    this.configInfo.categories.forEach(category => {
      let categoryConfiguration = category.categoryConfiguration;
      try {
        if (categoryConfiguration)
          categoryConfiguration = JSON.parse(categoryConfiguration);
        else categoryConfiguration = '';
      } catch (e) {
        categoryConfiguration = '';
      }
      category.categoryConfiguration = categoryConfiguration;
      if (category.ShowTab) {
        Object.entries(category.sections).forEach(([key, value]: any) => {
          value.fields.forEach(f => {
            if (item) {
              if (field.campoId == f.campoParentId) {
                switch (f.type) {
                  case 'map':
                    f.value.val = JSON.parse(item[f.name]);
                    break;
                  case 'currency':
                  case 'number':
                  case 'numberWithSeparator':
                  case 'percentage':
                    f.value.val = Number(item[f.name]);
                    kendo.jQuery(`#${f.campoId}`).data('kendoNumericTextBox').value(f.value.val);
                    break;
                  case 'carrousel':
                    f.value.val = JSON.parse(item[f.name]);
                    break;
                  case 'date':
                  case 'datetime':
                    f.value.val = new Date(item[f.name]);
                    break;
                  case 'email':
                  case 'text':
                  case 'textarea':
                    f.value.val = item[f.name];
                    break;
                  case 'multiSelect':
                    f.value.val = item[f.name].split(',');
                    break;
                  case 'hyperlink':
                    f.value.linkUrl = item[f.name].split('|')[2]
                    f.value.url = item[f.name].split('|')[2]
                    f.value.linkText = item[f.name].split('|')[0];
                    document.getElementById(`link_${f.campoId}`).setAttribute('href', f.value.url);
                    document.getElementById(`link_${f.campoId}`).innerHTML = f.value.linkText;
                    break;
                  default:
                    if (!f.Relacion) {
                      f.value.val = item[f.name];
                    } else {
                      kendo.jQuery(`#${f.campoId}`).data('kendoDropDownList').value(item[f.name]);
                    }
                    break;
                }
              }
            } else if (field.campoId == f.campoParentId) {
              switch (f.type) {
                case 'map':
                  f.value.val = backandGlobal.MAP_DEFAULT_POSITION;
                  break;
                case 'currency':
                case 'numberWithSeparator':
                  f.value.val = null;
                  break;
                case 'carrousel':
                  this.carouselComponent.clearCarrousel();
                  break;
                case 'datetime':
                  if (item[f.name].includes('Date')) {
                    var fecha = item[f.name].replace(/[^a-zA-Z 0-9.]+/g, ' ').split(' ')[2];
                    f.value.val = new Date(parseInt(fecha));
                  }
                  break;
                default:
                  f.value.val = null;
                  break;
              }
            }
          });
        });
      }
    });
  }

  applyUIRules(fieldChanged: any, value?: any) {
    if (!this.configInfo.rules) return;

    var rules = this.configInfo.rules.filter((r: any) => {
      var definition = JSON.parse(r.RuleDefinition);
      return definition.condition.toLowerCase().indexOf(fieldChanged.campoId.toLowerCase()) != -1;
    });

    this.fieldOrActionAfecteds = [];

    rules.forEach(r => {
      var fieldType = fieldChanged.type;
      var rule = JSON.parse(r.RuleDefinition);

      if (fieldType == 'checkbox') {
        if (rule.condition.toLowerCase().search('true') != -1) {
          rule.condition = `${rule.condition.split('==')[0]} == true`;
        } else if (rule.condition.toLowerCase().search('false') != -1) {
          rule.condition = `${rule.condition.split('==')[0]} == null`;
        }
      }

      rule.condition = rule.condition.replace(/scope/g, "this");
      if (eval(rule.condition)) {
        rule.results.forEach(result => {
          var objectsName = Object.keys(result);
          var fieldOrAction;

          if (objectsName[0] == 'ActionId') {
            fieldOrAction = this.getActionById(result.ActionId);
          } else {
            fieldOrAction = this.getCampoByIdForm(result.fieldId);
          }

          if (fieldOrAction) {
            this.fieldOrActionAfecteds.push({ fieldId: result.fieldId, result: result.result });
            switch (result.result) {
              case 'hide':
                fieldOrAction.show = false;
                break;
              case 'disabled':
                if (objectsName[0] == 'ActionId') {
                  fieldOrAction.isEditable = false;
                } else {
                  fieldOrAction.disabled = true;
                }
                break;
              case 'required':
                fieldOrAction.required = true;
                this.checkForm(fieldOrAction.campoId, fieldOrAction.value.val ? false : fieldOrAction.value.val === 0 ? false : fieldOrAction.required);
                break;
            }
          }
        });
      } else {
        rule.results.forEach(result => {
          var objectsName = Object.keys(result);
          var fieldOrAction;

          if (objectsName[0] == 'ActionId') {
            fieldOrAction = this.getActionById(result.ActionId);
          } else {
            fieldOrAction = this.getCampoByIdForm(result.fieldId);
          }

          if (fieldOrAction) {
            var isAfected = this.fieldOrActionAfecteds.filter((e) => {
              return JSON.stringify(e) == JSON.stringify(result);
            });
            if (isAfected == undefined) {
              isAfected = [];
            }

            if (isAfected.length == 0) {
              switch (result.result) {
                case 'hide':
                  fieldOrAction.show = true;
                  break;
                case 'disabled':
                  if (objectsName[0] == 'ActionId') {
                    fieldOrAction.isEditable = true;
                  } else {
                    fieldOrAction.disabled = false;
                  }
                  break;
                case 'required':
                  fieldOrAction.required = false;
                  this.checkForm(fieldOrAction.campoId, fieldOrAction.required);
                  break;
              }
            }
          }
        });
      }
    });

    var categorie = this.configInfo.categories;
    var validate = 0;

    for (var i = 0; i < categorie.length; i++) {
      if (categorie[i].ShowTab) {
        var section = categorie[i].sections;

        var arraySections = $.map(section, (value: any) => {
          return [value];
        });

        for (var itemSections in arraySections) {
          var arrayControls = arraySections[itemSections].fields;

          for (var itemControls in arrayControls) {
            if (arrayControls[itemControls].show == true) {
              validate++;
            }
          }

          if (validate == 0) {
            arraySections[itemSections].show = false;
            validate = 0;
          } else {
            arraySections[itemSections].show = true;
            validate = 0;
          }
        }
      }
    }
  }

  /**
   * Método encargado de ejecutar un WF (Workflow)
   */
  executeWf(wfId: any) {
    var url = backandGlobal.urlWf + wfId;
    var token = 'YWRtaW46ZWUwNWVhYWJhN2I3NmYxNmUyODVkOTgzZDYwNWM5YmY=';

    this.proxyTxApiService.execWF(`${url}`, `${token}`).then((data: any) => {
      this.toastr.success(`Workflow ejecutado correctamente` + data);
    }).catch((error: any) => console.error("Error ejecutando WF", error));
  }

  updateFileCarrusel() {
    var actionEdit;

    this.configInfo.Actions.forEach(action => {
      if (this.isAnonymous == true) {
        if (action.Type == 'Save') {
          actionEdit = action;
        }
      } else if (sessionStorage.getItem('rolesId')) {
        if (sessionStorage.getItem('rolesId').includes(',')) {
          let stringRoles = sessionStorage.getItem('rolesId').split(',');
          stringRoles.forEach(rol => {
            if (action.rolesId.includes(rol)) {
              if (action.Type == 'Save') {
                actionEdit = action;
              }
            }
          });
        } else if (action.roles.includes(sessionStorage.getItem('rolesId'))) {
          if (action.Type == 'Save') {
            actionEdit = action;
          }
        }
      }
    });

    this.submit(3, actionEdit);
  }

  disableComboCascade() {
    this.iterateFields((fields: any) => {
      if (fields.type == 'combobox' && fields.options[0].endPoint.cascadeId != null) {
        this.iterateFields((field: any) => {
          if (fields.options[0].endPoint.cascadeId == field.campoId) {
            field.disabled = true;
          }
        });
      }
    });
  }

  cascade(item: any, field: any) {
    this.iterateFields((fields: any) => {
      if (field.options[0].endPoint.cascadeId == fields.campoId) {
        var expanded_entities = '';
        if (fields.FieldsTemplate !== null) {
          fields.FieldsTemplate.forEach(template => {
            if (template.NameEntityTemplate !== null) {
              var contains = expanded_entities.includes(template.NameEntityTemplate);
              if (!contains) {
                expanded_entities += `${template.NameEntityTemplate},`;
                expanded_entities = expanded_entities.replace('Details', '');
              }
            }
          });
        }

        var endPoint = fields.options[0].endPoint;
        endPoint.view = endPoint.view.replace('Details', '');
        var opc = {
          "take": 10000, "pageSize": 10000,
          "filter": {
            "logic": "and",
            "filters": [
              {
                "field": `${field.options[0].endPoint.nameRelation}`,
                "operator": "eq",
                "value": `${field.value.val == "" ? item.Id : field.value.val}`
              },
              {
                "logic": "or",
                "filters": []
              }]
          },
          "includeEntities": "",
          "tenantId": sessionStorage.tenantId,
          "parameters": {
            "userId": sessionStorage.userId,
            "pType": "showinmenu",
            "aType": "ffija",
            "environment": backandGlobal.environment
          }
        }

        var url;
        if (expanded_entities === '') {
          url = `${backandGlobal.api2 + endPoint.url + 'lappiz/get/' + endPoint.view.replace('Details', '')}`;
        } else {
          expanded_entities = expanded_entities.slice(0, -1).replace('Details', '');
          url = `${backandGlobal.api2 + endPoint.url + 'lappiz/get/' + endPoint.view.replace('Details', '')}`;
        }

        if (field.campoId == null) {
          this.$rs.$rootScope.ccampo = fields.campoId;
        } else {
          this.$rs.$rootScope.ccampo = '';
        }

        if (this.changeQueryFieldsCascada) {
          fields.options[0].endPoint.filter.Query = url;
        }

        fields.disabled = false;

        this.comboboxComponent.cascadeCombobox(url, fields.campoId, endPoint.view, opc, fields);
      }
    });
  }

  actionFormula(field: any, value: any) {
    if (field.type == 'numberWithSeparator' || field.type == 'numeric' || field.type == 'number' || field.type == 'combobox' || field.type == 'singleSelect' || field.type == 'text') {
      this.iterateFields((fields: any) => {
        if (fields.Formula !== null) {
          var type = fields.Formular.historyFormula[0].formula.split('=')[0].trim();
          if (type != 'conc' && fields.Formula.historyFormula[0].formula.split('=')[1].trim().indexOf(`#${field.name}#`) !== -1) {
            if (field.type == 'text' && isNaN(value) && fields.Formula.historyFormula[0].formula.split('=')[1].trim().indexOf(`#${field.name}#`) !== -1) {
              this.mostrarMensaje('error', 'Debe ingresar valores numéricos para evaluar la formula.');
              this.formulaComponent.removeFormula(fields);
              fields.value.val = '';
              field.value.val = '';
              return;
            }

            if (value === null) {
              this.formulaComponent.removeFormula(fields);
            }

            if (parseInt(value) === 0) {
              var partsFormula = fields.Formula.historyFormula[0].formula.split('=')[1].trim().split('/');

              for (var item in partsFormula) {
                if (parseInt(item) % 2 !== 0) {
                  if (partsFormula[item].indexOf(`#${field.name}#`) !== -1) {
                    this.mostrarMensaje('error', 'La división por 0 no está definida.');
                    this.formulaComponent.removeFormula(fields);
                    fields.value.val = '';
                    field.value.val = '';
                    return;
                  }
                }
              }
            }
          }

          var code = `#${field.name}#`;
          var expresion = new RegExp(code, 'g');

          if (fields.Formula.historyFormula.length > 1) {
            var newRewriteFormula = null;

            for (var item in fields.Formula.historyFormula) {
              if (fields.Formula.historyFormula[item].field == field.name) {
                var rewriteFormula = fields.Formula.historyFormula[0].formula.split('=')[1];
                newRewriteFormula = rewriteFormula.replace(expresion, value);
                fields.Formula.historyFormula[item].value = value;
                fields.Formula.historyFormula[item].formula = newRewriteFormula;
                break;
              }
            }

            if (newRewriteFormula != null) {
              for (var item in fields.Formula.historyFormula) {
                if (fields.Formula.historyFormula[item].field != field.name && parseInt(item) != 0) {
                  code = `#${fields.Formula.historyFormula[item].field}#`;
                  expresion = new RegExp(code, 'g');
                  newRewriteFormula = newRewriteFormula.replace(expresion, fields.Formula.historyFormula[item].value);
                }
              }

              fields.Formula.historyFormula[fields.Formula.historyFormula.length - 1].formula = newRewriteFormula;
              newRewriteFormula = null;
            }
          }

          var formula = fields.Formula.historyFormula[fields.Formula.historyFormula.length - 1].formula;
          var newFormula = formula.replace(expresion, value);
          var add = false;

          for (var item in fields.Formula.historyFormula) {
            if (fields.Formula.historyFormula[item].field != field.name) {
              add = true;
            } else {
              add = false;
              break;
            }
          }

          if (add) {
            fields.Formula.historyFormula.push({ field: field.name, formula: newFormula, value });
          }

          if (newFormula.indexOf('#') == -1) {
            this.formulaComponent.ShowFormula(newFormula, value, fields, type);
            if (type != 'conc') {
              fields.value.val = eval(newFormula);
            } else {
              var dataFormula = newFormula.split('+');
              fields.value.val = '';
              for (var item in dataFormula) {
                fields.value.val += `${dataFormula[item]}`;
              }
            }
          } else if (type == 'conc') {
            if (add) {
              fields.value.val += `${fields.Formula.historyFormula[fields.Formula.historyFormula.length - 1].value}`;
              fields.value.val = fields.value.val.replace('null', '').replace('+', '');
            }
          }
        }
      });
    }
  }

  /**
   * Método encargado de completar una tarea
   */
  onCompleteTask() {
    var submitAction = this.configInfo.Actions.filter((item: any) => item.Type == 'Save' && item.HasWorkFlow)[0];
    this.canCompleteTask = true;
    this.submit(3, submitAction);
  }

  executeWorkFlow(action: any, dataItem?: any) {
    var savedEvent = this.formLoadedEvent;
    savedEvent.dataItem = dataItem;

    if (this.isNew) {
      if (!this.fireEvents('ItemCreating', this.configInfo.formsEvents, savedEvent)) return;
    } else if (!this.fireEvents('ItemEditing', this.configInfo.formsEvents, savedEvent)) return;

    if (!this.fireEvents('ItemSaving', this.configInfo.formsEvents, savedEvent)) return;

    var fields = [];

    this.configInfo.fields.forEach(field => {
      if (!dataItem) {
        this.setFieldValueForm(field);
      }

      fields.push(field);
    });

    this.iterateFields((field: any) => {
      if (!dataItem) {
        this.setFieldValueForm(field);
      }

      fields.push(field);
    });

    var data: any = {};

    if (dataItem) {
      this.dataToSubmit = dataItem;
    }

    this.dataToSubmit.forEach(propName => {
      var f = fields.filter((elem: any) => {
        return elem.name == propName;
      });

      if (f.length > 0) {
        var valueWf = this.dataToSubmit[propName];
        data[propName] = { value: valueWf, type: f[0].javaType };
      }
    });

    data.token_authorization = { value: localStorage.Authorization, type: 'String' };
    if (this.taskId) {
      var variablesTask = {
        variables: data
      };
      var completeTaskCallBack = () => {
        this.workflowService.completeTask(this.taskId, variablesTask).then(() => {
          this.mostrarMensaje('info', 'Tarea completada satisfactoriamente.');
        }, (error: any) => {
          console.error(error);
          this.mostrarMensaje('error', error);
        });
      };

      if (this.canCompleteTask) {
        completeTaskCallBack();
      } else if (confirm('¿Desea marcar la tarea como completada?')) {
        completeTaskCallBack();
      }
    } else {
      this.mostrarMensaje('info', 'Inicio del flujo de trabajo');
      this.workflowService.postActionWorkFlow(action.Action.ActionId, data)
        .success((data: any) => {
          var savedEvent = this.formLoadedEvent;
          savedEvent.dataItem = dataItem;
          if (this.isNew) {
            this.fireEvents('ItemCreated', this.configInfo.formsEvents, savedEvent);
          } else {
            this.fireEvents('ItemEdited', this.configInfo.formsEvents, savedEvent);
          }
          this.fireEvents('ItemSaved', this.configInfo.formsEvents, savedEvent);
          this.mostrarMensaje('success', 'Flujo de trabajo ejecutado correctamente');
        })
        .error((data: any) => {
          this.mostrarMensaje('error', data.ExceptionMessage);
        });
    }
  }

  messageDocumentsSave(newItem: any) {
    if (newItem) {
      this.mostrarMensaje('success', this.customMessages.DocumentsSave);
    }
  }

  /**
   * Método encargado de mostrar un toastr
   */
  mostrarMensaje(type: string, message: any) {
    this.notificationsService.notificationApp(type, message)
  }

  showSections() {
    this.configInfo.categories.forEach(category => {
      if (category.ShowTab) {
        Object.entries(category.sections).forEach(([key, value]: any) => {
          value.showName = category.sections2.length > 1;
        });
      }
    });
  }

  selectTab(category: any) {
    // deactivate all tabs
    this.configInfo.categories.toArray().forEach(category => category.active = false);

    // activate the tab the user has clicked on.
    category.active = true;
  }

  tabClick(category: any) {
    category.fields.forEach(field => {
      field.relatedViewName = field.tempRelatedViewName;
    });
  }

  viewHistory() {
    const modalRef = this.modalService.open(HistoryComponent, {
      animation: true,
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    });

    modalRef.componentInstance.viewName = this.searchParams.viewName;
    modalRef.componentInstance.rowId = this.rowId || this.searchParams.rowId;

    modalRef.result.then((result: any) => {
      if (result) {
        // console.log('Result:', result);
      }
    });
  }

  checkForm(campoId: any, invalid: any) {
    if (this.formFields.length > 0) {
      const currentField = this.formFields.find(x => x.campoId == campoId);
      if (currentField) currentField.invalid = invalid;
      this.formValid = invalid ? false : !this.formFields.some(x => x.invalid);
    } else {
      this.formValid = true;
    }
  }

  //#region Lappiz Applications and Events

  evalEvent = function (implementation: any, e: any) {
    var functions = `var toastr = myService.toastr; var getUser = myService.getUser; var getEntityId = myService.getEntityId;
var getAppViewId = myService.getAppViewId; var getRowId = myService.getRowId; var getNewId = myService.getNewId;
var getCurrentItem = myService.getCurrentItem; var getCampoById = myService.getCampoById; var setFieldValue = myService.setFieldValue;
var getFieldValue = myService.getFieldValue; var disableField = myService.disableField; var requireField = myService.requireField;
var visibilityField = myService.visibilityField; var visibilitySection = myService.visibilitySection;
var visibilityLoader = myService.visibilityLoader; var disableAction = myService.disableAction; var isDisableField = myService.isDisableField;
var goLocation = myService.goLocation; var sendEmail = myService.sendEmail; var execQuery = myService.execQuery; var execSP = myService.execSP;
var openCustomModal = myService.openCustomModal; var closeModal = myService.closeModal; var sendSMS = myService.sendSMS;
var sendPush = myService.sendPush; var speakMe = myService.speakMe; var isReadyForm = myService.isReadyForm;var execLF = myService.execLF; var execTX = myService.execTX; var Swal = myService.Swal;
`;

    var functionsToAdd = `var getDate = myService.getDate; var changeEndpoint = myService.changeEndpoint; var refreshDetails = myService.refreshDetails;
    var restService = myService.restService;`;

    for (var i in this.$rs.$rootScope.rules) {
      functions += this.$rs.$rootScope.rules[i].Implementation;
    }

    var wrapper = `try { ${implementation} } catch (error) { toastr("Error: " + error); }`;

    try {

      eval(this.getImplemetationCode(functions, wrapper));
      if (e.isReady) this.isReady = true;
      if (e.cancel !== undefined) {
        return !e.cancel;
      }
      else return true;
    } catch (error) {
      this.notificationsService.notificationApp('error', this.customMessages.ErrorRules)
    }
  }

  transformFunction(implementation: any) {
    /*
      Cambiar esta forma de llamar la función, la acción al momento de crearla debe tener un campo
      Implementación y no un combo para seleccionar la regla
    */
    var methodName = implementation.split('function ');
    if (methodName.length > 1) {
      methodName = methodName[1].split('(');
      if (methodName.length > 1) {
        methodName = methodName[0];
        //Se hace esto para poder ejecutar la función que llega, validar si mejor se puede quitar la validación de function
        implementation += `${methodName}();`;
      }
    }

    return implementation;
  }

  fireEvents(eventName: any, events: any, param: any) {
    var result = true;
    if (eventName == 'ValueChanged') param.isNew = this.isNew;
    if (!events || events.length == 0) return result;
    let implementationGeneral = this.generalEventsOfEntity.reduce((implementationScriptGeneral: string, event: any) => {
      implementationScriptGeneral += ` ${event.Implementation}`;
      return implementationScriptGeneral;
    }, ' ');
    var implementations = events.filter((event: any) => {
      return event.EventTypeName == eventName;
    });
    for (var i in implementations) {
      var impl = implementations[i];
      result = this.evalEvent(`${implementationGeneral} ${impl.Implementation}`, param);
      if (!result) {
        this.isReady = true;
        break;
      }
    }
    this.isReady = true;
    return result;
  }

  getUser() {
    return JSON.parse(sessionStorage.LappizUser);
  }

  getEntityId() {
    return this.tablaId;
  }

  getAppViewId() {
    return this.appViewId;
  }

  getRowId() {
    return !this.isNew ? this.rowId : this.id;
  }

  getNewId() {
    return uuidv4();
  }

  getCurrentItem() {
    delete this.$rs.$rootScope.getCurrentItem;
    this.getCurrentItemForm();
    var currentItem = this.$rs.$rootScope.getCurrentItem;
    return currentItem;
  }

  getCampoById(campoId: any) {
    this.getCampoByIdForm(campoId);
    var campo = this.$rs.$rootScope['campo' + campoId];
    delete this.$rs.$rootScope['campo' + campoId];
    return campo;
  }

  setFieldValue(campoId: any, value: any, toDetails: any) {
    this.changeFieldValue(campoId, value, toDetails);
  }

  getFieldValue(campoId: any) {
    var fieldValue = this.getFieldValueForm(campoId);
    return fieldValue;
  }

  disableField(campoId: any, value: any, toDetails: any) {
    this.disableFieldForm(campoId, value, toDetails);
  }

  requireField(campoId: any, value: any) {
    this.requireFieldForm(campoId, value);
  }

  visibilityField(campoId: any, value: any) {
    this.visibilityFieldForm(campoId, value);
  }

  visibilitySection(sectionId: any, value: any) {
    this.visibilitySectionForm(sectionId, value);
  }

  visibilityLoader(isVisible: boolean) {
    this.loaderComponent.loader(isVisible);
  }

  disableAction(actionId: any, value: any) {
    this.disableActionForm(actionId, value);
  }

  isDisableField(fieldId: any) {
    this.isDisableFieldForm(fieldId);
    var isDisabled = this.$rs.$rootScope['isdisabled' + fieldId];
    delete this.$rs.$rootScope['isdisabled' + fieldId];
    return isDisabled;
  }

  goLocation(url: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      location.assign(url);
    });
  }

  sendEmail(smtpsender: string, to: any, subject: any, text: any, html: any, attachments: any[] = [], cc: any[] = [], bcc: any[] = []) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('Authorization')
      })
    };

    var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/utiles/sendEmail`;
    var dataEmail = JSON.stringify({ smtpsender: smtpsender, to: to, subject: subject, text: text, html: html, cc: cc, bcc: bcc, attachments: attachments, parameters: { aType: "sendMail", environment: `${backandGlobal.environment}` } });

    return this.http.post(url, dataEmail, httpOptions).toPromise();
  }

  execDB(query: string, queryNoSQL?: any) {
    if (backandGlobal.stateOnline) {
      return this.proxyTxApiService.execDB(query);
    } else {
      return this.proxyTxApiService.execDB(queryNoSQL);
    }
  }

  execQuery(query: string, queryNoSQL?: any) {
    return this.execDB(query, queryNoSQL);
  }

  execSP(spname: string, params: any[]) {
    var command = `exec ${spname}`;
    if (params && params.length > 0) {
      command += ` ${params[0]}`;
      for (var i = 1; i < params.length; i++) {
        var element = params[i];
        command += `, ${element}`;
      }
    }

    return this.execDB(command);
  }

  openCustomModal(config: any, done: any, cancel: any) {
    if (config) {
      if (!config.dataItem) config.dataItem = {};
      if (!config.parent) config.parent = {};
      var modalTemplate = '', headerTemplate = '', bodyTemplate = '', footerTemplate = '';

      if (config.htmlTemplate) {
        // Se abre una popup con el html inyectado
        if (config.headerTemplate) {
          headerTemplate = `<div class="modal-header">
            ${config.headerTemplate}
            <button type="button" id="btn-close" class="close" aria-label="Close" (click)="close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>`;
        }

        if (config.bodyTemplate) {
          let classModal = `modal-body ${config.scrollable ? 'modal-body-scrollable' : ''}`;
          bodyTemplate = `<div class="${classModal}">
            ${config.bodyTemplate}
          </div>`;
        }

        if (config.footerTemplate) {
          footerTemplate = `<div class="modal-footer">
            ${config.footerTemplate}
            <br>`;
          if (config.showBtnsFooter) {
            footerTemplate += `<div class="col-md-12 text-center">
              <button class="btn btn-default active" type="button" id="btn-ok" (click)="ok()">Aceptar</button>
              <button class="btn btn-default active-color" type="button" id="btn-cancel" (click)="cancel()">Cancelar</button>
            </div>`;
          }

          footerTemplate += `</div>`;
        }

        modalTemplate = `${headerTemplate}
        ${bodyTemplate}
        ${footerTemplate}`;
      } else {
        // Se abre una popup con un formulario dependiendo de la configura
        modalTemplate = `<div class="modal-body" style="padding: 0px !important; margin-top: -38px; margin-left: -9px; margin-right: -9px; margin-bottom: -19px; min-height:100px">
          <div *ngIf="!hasHtmlTemplate"> <app-forms [viewName]="viewName" [rowId]="rowId" [entityId]="entityId" [isDetails]="isDetails" [entityParentId]="entityParentId" [parent]="parent" [parentRules]="parentRules" [item]="dataToSubmit" ></app-forms></div>
      </div>`;
      }

      var modalInstance = this.modalService.open(PopupComponent, {
        animation: true,
        size: config.size ? config.size : 'lg',
        scrollable: config.scrollable ? config.scrollable : false,
        centered: config.centered ? config.centered : false,
        backdrop: 'static',
        keyboard: config.keyboard ? config.keyboard : true
      });

      modalInstance.componentInstance.modalTemplate = modalTemplate;
      modalInstance.componentInstance.htmlTemplate = modalTemplate;
      modalInstance.componentInstance.dataToSubmit = config.dataItem;
      modalInstance.componentInstance.entityId = config.entityId;
      modalInstance.componentInstance.entityParentId = config.parent.Id;
      modalInstance.componentInstance.parent = config.parent.fKCode;
      modalInstance.componentInstance.parentRules = config.parentRules;
      modalInstance.componentInstance.rowId = config.dataItem.Id;
      modalInstance.componentInstance.viewName = config.viewName;

      modalInstance.componentInstance.done = done;
      modalInstance.componentInstance.cancelModal = cancel;

      modalInstance.result.then(done).catch(cancel);

      return modalInstance;
    }
  }

  closeModal(data: any) {
    this.popupComponent.closeModal(this, data);
  }

  sendSMS = (destination: string, message: string) => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('Authorization')
      })
    };
    var data = {
      "message": message,
      "destination": destination
    }
    var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/utiles/sendSMS`;
    var dataSend = JSON.stringify({ data: data, parameters: { aType: "sendSMS", environment: `${backandGlobal.environment}` } });
    return this.http.post(url, dataSend, httpOptions).toPromise();
  }

  sendPush = (usersId: string, data: any) => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('Authorization')
      })
    };

    var urlTx = `${backandGlobal.url}/api/notification/getAllowNotification`;
    this.proxyConfigApiService.getSuscriptions(usersId, urlTx).then(pushSuscription => {
      var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/utiles/pushNotification`;
      var dataNotification = JSON.stringify({ pushSuscription: pushSuscription, data: data, parameters: { aType: "pushNotification", environment: `${backandGlobal.environment}` } });
      return this.http.post(url, dataNotification, httpOptions).toPromise();
    }).catch(err => {
      console.log(err);
      this.notificationsService.notificationApp('error', this.customMessages.FailNotification)
      return err;
    });
  }

  speakMe(lang: string = 'es-ES', voice: string = 'Google español', text: string = '') {
    this.stop();
    this.speech = new Speech(); // will throw an exception if not browser supported
    if (this.speech.hasBrowserSupport()) {
      // returns a boolean
      this.speech
        .init({
          volume: 1,
          lang: lang,
          rate: 1,
          pitch: 1,
          voice: voice,
          splitSentences: true,
          listeners: {
            onvoiceschanged: (voices) => {
              console.log('Event voiceschanged', voices);
            },
          },
        })
    }

    var temporalDivElement = document.createElement('div');
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = text;
    // Retrieve the text property of the element (cross-browser support)
    var result =
      temporalDivElement.textContent || temporalDivElement.innerText || '';

    this.speech
      .speak({
        text: result,
      })
      .then(() => {
        console.log('Success !');
      })
      .catch((e) => {
        console.error('An error occurred :', e);
      });
  }

  stop() {
    this.speech?.cancel();
  }

  getDate() {
    var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/utiles/date`;
    var result = this.http.get(url, { responseType: 'text' }).toPromise().then((response: any) => {
      var resultDate = response;
      return new Date(JSON.parse(resultDate).timeinmil);
    });
  }

  changeEndpoint(campoId: any, query: any) {
    if (combo) {
      var combo = this.getCampoById(campoId);
      var url = `#UrlBase#/${backandGlobal.currentApp.name}.api/api/${query}`;
      url = this.contextValueServices.transformODataQuery(url);
      combo.options[0].endPoint.filter.Query = url;
    }
    this.comboboxComponent.urlchanged(url, campoId);
  }

  refreshDetails() {
    this.detailsComponent.refreshDetails();
  }

  restService(url: string, method: any, format: any, body: any) {
    var restObject = { url, method, format, body };
    var urlConfig = `${backandGlobal.url}/Api/api/utils/restService`;

    return this.proxyConfigApiService.getConfigDataPost(`${urlConfig}`, JSON.stringify(restObject));
  }

  isReadyForm(sw: boolean) {
    this.isReadyEvent = true;
  }
  execLF(config: ILFCall) {
    if (!config.nameFunction) return { status: 400, message: 'el campo "nameFunction" es requerido' };
    if (!config.lappizFunctionId) return { status: 400, message: 'el campo "lappizFunctionId" es requerido' };
    const method = config.method || 'post';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('Authorization') || ''
      })
    };

    var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/functions/${config.nameFunction}`;

    var opt = {
      parameters: {
        userId: sessionStorage.userId,
        aType: "lappizFunction",
        pType: "Execute",
        lappizFunctionId: config.lappizFunctionId,
        environment: `${backandGlobal.environment}`
      }
    }
    if (config.body) {
      let keys = Object.keys(config.body)
      keys.forEach(key => {
        opt[key] = config.body[key];
      })
    }
    return this.http[method.toLowerCase()](url, opt, httpOptions).toPromise();
  }
  execTX(config): Promise<any> {
    const { dataItem, entity, Id } = config;
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api/lappiz/${entity}`;
        let method = 'post';
        if (Id) {
          url = `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api/lappiz/${entity}/${Id}`
          method = 'update';
        }
        let response = await this.proxyTxApiService.operationTxApi(url, JSON.stringify(dataItem), method, backandGlobal.stateOnline, entity)
        if (backandGlobal.stateOnline === false) {
          var entitiesChanged = localStorage.EntityNamesChanged ? JSON.parse(localStorage.EntityNamesChanged) : [];
          entitiesChanged.filter((entityName) => entityName === entity).length == 0 ? entitiesChanged.push(entity) : '';
          localStorage.EntityNamesChanged = JSON.stringify(entitiesChanged);
        }
        resolve(response);
      } catch (error) {
        reject(error);
      }
    })
  };

  //#endregion

  //#region Order
  returnZero() {
    return 0;
  }
  //#endregion

  collapseSection($event: any, idSection: string, expandOne: boolean) {
    if (expandOne && this.lastSectionExpand) {
      if (this.lastSectionExpand != idSection)
        this.slideStateSection(this.lastSectionExpand, true);
    }
    this.lastSectionExpand = idSection;
    this.slideStateSection(idSection);
  }

  slideStateSection(idSection: string, isHide: boolean = false) {
    if (isHide && !document.getElementById(`${idSection}_panel`).className.includes('oculto'))
      document.getElementById(`${idSection}_panel`).classList.toggle('oculto');
    else if (!isHide)
      document.getElementById(`${idSection}_panel`).classList.toggle('oculto');
    let icon = document.getElementById(`${idSection}-icon-collapse`);
    if (!isHide)
      if (icon.className.includes('fa-chevron-up')) {
        icon.className = 'fa fa-chevron-down';
      } else {
        icon.className = 'fa fa-chevron-up';
      }
    else
      icon.className = 'fa fa-chevron-down';
  }

  private getImplemetationCode(functions: string, wrapper: string): string {
    return `(async function(
        LAssert,
        LDelay,
        LExcel,
        LForm,
        LHttp,
        LScript,
        LToastr,
        LTX,
        LType,
        LUser,
        LMath
      ) { ${functions + wrapper} })
      (
        this.LAssert,
        this.LDelay,
        this.excelService,
        this.LForm,
        this.httpService,
        this.LScript,
        this.toastr,
        this.LTX,
        this.LType,
        this.LUser,
        this.mathService
      );
    `
  }

  private LType: object = {
    isArray: this.typeService.isArray.bind(this),
    isArrayOfBoolean: this.typeService.isArrayOfBoolean.bind(this),
    isArrayOfNumber: this.typeService.isArrayOfNumber.bind(this),
    isArrayOfPassedObject: this.typeService.isArrayOfPassedObject.bind(this),
    isArrayOfString: this.typeService.isArrayOfString.bind(this),
    isAsyncFunction: this.typeService.isAsyncFunction.bind(this),
    isBoolean: this.typeService.isBoolean.bind(this),
    isEmptyString: this.typeService.isEmptyString.bind(this),
    isFunction: this.typeService.isFunction.bind(this),
    isNull: this.typeService.isNull.bind(this),
    isNullOrEmptyString: this.typeService.isNullOrEmptyString.bind(this),
    isNullOrUndefined: this.typeService.isNullOrUndefined.bind(this),
    isNumber: this.typeService.isNumber.bind(this),
    isObject: this.typeService.isObject.bind(this),
    isString: this.typeService.isString.bind(this),
    isUndefined: this.typeService.isUndefined.bind(this),
  }

  private LScript: object = {
    importScript: this.scriptService.importScript.bind(this),
    isThisScriptLoaded: this.scriptService.isThisScriptLoaded.bind(this),
    isValidUrl: this.scriptService.isValidUrl.bind(this),
  }

  private LDelay: object = {
    waitAsync: this.delayService.waitAsync.bind(this),
  }

  private LAssert: object = {
    arrayContainsProps: this.assertService.arrayContainsProps.bind(this),
    equal: this.assertService.equal.bind(this),
    falsy: this.assertService.falsy.bind(this),
    notEqual: this.assertService.notEqual.bind(this),
    truthy: this.assertService.truthy.bind(this),
  }

  private LTX: object = {
    execLF: this.txService.execLF.bind(this),
    execQuery: this.txService.execQuery.bind(this),
    execSP: this.txService.execSP.bind(this),
  }

  private LForm = {
    closeModal: this.closeModal.bind(this),
    disableAction: this.disableAction.bind(this),
    disableField: this.disableField.bind(this),
    disableFieldForm: this.disableFieldForm.bind(this),
    getAppViewId: this.getAppViewId.bind(this),
    getCampoById: this.getCampoById.bind(this),
    getCampoByIdForm: this.getCampoByIdForm.bind(this),
    getCurrentItem: this.getCurrentItem.bind(this),
    getCurrentItemForm: this.getCurrentItemForm.bind(this),
    getDate: this.getDate.bind(this),
    getEntityId: this.getEntityId.bind(this),
    getFieldValue: this.getFieldValue.bind(this),
    getNewId: this.getNewId.bind(this),
    getRowId: this.getRowId.bind(this),
    goLocation: this.goLocation.bind(this),
    isDisableField: this.isDisableField.bind(this),
    isReadyForm: this.isReadyForm.bind(this),
    openCustomModal: this.openCustomModal.bind(this),
    refreshDetails: this.refreshDetails.bind(this),
    requireField: this.requireField.bind(this),
    setFieldValue: this.setFieldValue.bind(this),
    speakMe: this.speakMe.bind(this),
    stopSpeaking: this.stop.bind(this),
    visibilityField: this.visibilityField.bind(this),
    visibilityLoader: this.visibilityLoader.bind(this),
    visibilitySection: this.visibilitySection.bind(this),
  };

  private LUser = {
    addAplicationUser: this.userService.addAplicationUser.bind(this),
    confirmEmail: this.userService.confirmEmail.bind(this),
    createUser: this.userService.createUser.bind(this),
    getInfoUserApp: this.userService.getInfoUserApp.bind(this),
    getIpUser: this.userService.getIpUser.bind(this),
    getStatus: this.userService.getStatus.bind(this),
    getUser: this.userService.getUser.bind(this),
    replicateUpdate: this.userService.replicateUpdate.bind(this),
    replicateUser: this.userService.replicateUser.bind(this),
  }
}
