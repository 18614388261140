import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GridService {

  typeColumn: string

  constructor() {
    this.typeColumn = '';
  }

  getColumnType(field: any, isNew?: boolean) {
    switch (field.type) {
      case 'Entero':
        this.typeColumn = 'number';
        break;
      case 'Numeric':
        this.typeColumn = 'decimal';
        if (field.displayFormat == 'Numeric') {
          this.typeColumn = 'numeric';
        }
        if (field.displayFormat == 'Currency') {
          this.typeColumn = 'currency';
        }
        if (field.displayFormat === 'Percentage') {
          this.typeColumn = 'percentage';
        }
        break;
      case 'DateTime':
        if (field.displayFormat == 'Date_mm_dd' || field.displayFormat == 'Date_dd_mm' || field.displayFormat == 'None' || field.displayFormat == 'Date' || !field.displayFormat) {
          this.typeColumn = 'date';
        } else {
          this.typeColumn = 'datetime';
        }
        break;
      case 'LongText':
        if (field.displayFormat == 'MultiLines') {
          this.typeColumn = 'textarea';
        } else if (field.displayFormat == 'MultiLinesEditor') {
          this.typeColumn = 'editor';
        } else {
          this.typeColumn = 'text';
        }
        break;
      case 'SingleSelect':
        if (field.displayFormat == 'AutoCompleteStartWith' || field.displayFormat == 'AutoCompleteMatchAny' || field.displayFormat == 'SingleSelectRe') {
          this.typeColumn = 'combobox';
        } else {
          this.typeColumn = 'singleSelect';
        }
        break;
      case 'MultiSelect':
        if (field.displayFormat == 'Grid') {
          if (isNew) {
            this.typeColumn = 'disabledSubgrid';
          } else {
            this.typeColumn = 'subgrid';
          }
        } else if (field.displayFormat == 'CheckList') {
          this.typeColumn = 'multiSelect';
        } else {
          this.typeColumn = 'text';
        }
        break;
      case 'ShortText':
        if (field.displayFormat == 'MultiLines') {
          this.typeColumn = 'textarea';
        } else if (field.displayFormat == 'MultiLinesEditor') {
          this.typeColumn = 'editor';
        } else if (field.displayFormat == 'Hyperlink') {
          this.typeColumn = 'hyperlink';
        } else if (field.displayFormat == 'Email') {
          this.typeColumn = 'email';
        } else {
          this.typeColumn = 'text';
        }
        break;
      case 'Boolean':
        this.typeColumn = 'checkbox';
        break;
      case 'Url':
        this.typeColumn = 'hyperlink';
        break;
      case 'Email':
      case 'Image':
      case 'Map':
      case 'cronometro':
      case 'carrousel':
      case 'Detalles':
      case 'CheckList':
      case 'Password':
      case 'Formula':
      case 'FieldHtml':
      case 'Time':
      case 'Signature':
        this.typeColumn = field.type.toLowerCase();
        break;
      case 'RadioButton':
        this.typeColumn = 'radio';
        break;
      case 'Foto':
        this.typeColumn = 'Foto';
        break;
      default:
        this.typeColumn = 'text';
        break;
    }

    return this.typeColumn;
  }

}
