import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { Router, ActivatedRoute, NavigationExtras, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';
import { Constants } from '@common/constants';

import { ApplicationService } from '@services/application.service';
import { ServerConfigApiService } from '@services/server-config-api.service';
import { ContextValuesService } from '@services/context-values.service';
import { OdataFilterMapService } from '@services/odata-filter-map.service';
import { ProxyTxApiService } from '@services/proxy-tx-api.service';
import { UserService } from '@services/user.service';

import backandGlobal from '@env/env';
import { ProxyConfigApiService } from '@services/proxy-config-api.service';
import { ModalDetailsComponent } from '@components/details/modal-details/modal-details.component';
import { NotificationsService } from '@services/notifications.service';

declare var kendo: any;

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
  providers: [DatePipe],
})
export class ListComponent implements OnInit, OnChanges, OnDestroy {

  @Input() viewName: any;
  @Input() viewCode: any;
  @Input() options: any;
  @Input() filterOptions: any;
  @Input() inputStyle: any;
  @Input() buttonGroups: any;
  @Input() disableEditOnDblClick: any;
  @Input() entityId: any;

  sectionItemNav: string;
  idViewComponent: string;
  viewNameId: any;
  viewEntityName: string;
  url: any;
  load: boolean;

  customMessages: any;
  alerts = [];

  fieldToList: any;
  fieldNameDefault: string;

  configTable: any;
  mainListOptions: any;
  gridColumns: any;

  searchParams: any;

  aggregates = [];
  arrayPositions = [];
  isMobile: any;
  isAgrupable: any;
  dataEdit: any;
  editableFields: boolean;
  countChange: number;
  isAnonymous: boolean;

  actionsMenuOptions: boolean;
  globalActions = [];

  viewsToOpen: any;
  nameBack: string;
  nameEntity: string;
  showAdd: boolean;
  showDelete: boolean;
  showEdit: boolean;
  showViewItem: boolean;
  showCancel: boolean;
  showTabsMenu: boolean;
  showToolbar: boolean;
  showSearch: boolean;
  showCreate: boolean;
  logicalDelete: boolean;
  collapseFilter: any;
  dataSort: any;
  odataFilterOptions: any;
  filterGridOptions: any;

  dataItemLogicalEntity: any;
  value: any;

  navigationSubscription: any;
  sortGrid: any;
  destinations: any;
  templeteListView: any;
  fieldGroup: {};
  dataSource: any;

  constructor(
    public datePipe: DatePipe,
    private locationApp: Location,
    private route: ActivatedRoute,
    private router: Router,
    private applicationService: ApplicationService,
    private configService: ServerConfigApiService,
    private contextValuesService: ContextValuesService,
    private odataFilterMap: OdataFilterMapService,
    private proxyTxApiService: ProxyTxApiService,
    private proxyConfigApiService: ProxyConfigApiService,
    private userService: UserService,
    private modalService: NgbModal,
    private notificationsServices: NotificationsService
  ) {
    kendo.culture('es-CO');
    this.customMessages = Constants.MESSAGES;
    this.initializeVariables();
    this.navigationSubscription = this.router.events.subscribe((e: any) => this.handleNavigationEvent(e));
  }

  private handleNavigationEvent(event: any): void {
    // If it is a NavigationEnd event re-initalise the component
    if (event instanceof NavigationEnd && $(`#${this.idViewComponent}`).hasClass('k-listview')) {
      var list = kendo.jQuery(`#${this.idViewComponent}`).data('kendoListView');
      if (list) {
        list.destroy();
      }
      $(`#${this.idViewComponent}`).empty();
    }
  }


  private initializeVariables(): void {
    this.isMobile = $(window).width() < 768;
    this.dataEdit = null;
    this.editableFields = false;
    this.countChange = 0;
    this.isAnonymous = !localStorage.getItem('Authorization');

    this.fieldToList = { nameField: [], value: [], filter: [], type: [] };
    this.viewsToOpen = {};

    this.dataItemLogicalEntity = {};
    this.fieldGroup = {};
    this.idViewComponent = this.inputStyle == 'inForm' ? this.viewCode : 'listView';
  }

  ngOnInit(): void {
    this.initializeVariables();
    this.viewName = this.viewName || this.route.snapshot.queryParams.viewName;
    this.viewNameId = this.viewName;
    this.entityId = this.entityId || this.route.snapshot.queryParams.entityId;
    this.buildList(this.viewNameId, this.entityId);
  }

  ngOnChanges(): void {
    this.viewName = this.viewName || this.route.snapshot.queryParams.viewName;
    this.viewNameId = this.viewName;
    this.entityId = this.entityId || this.route.snapshot.queryParams.entityId;

    this.buildList(this.viewNameId, this.entityId);
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  /**
   * Método encargado de navegar a la vista que fue abierta antes de la lista
   */
  cancelar() {
    if (!localStorage.getItem('Authorization')) {
      this.router.navigate([
        `auth/login/${sessionStorage.getItem('workspace')}`,
      ]);
    } else {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.locationApp.back();
      });
    }
  }

  /**
   * Método encargado de refrescar/actualizar data de la grid
   */
  refresh() {
    kendo.jQuery(`#${this.idViewComponent}`).data('kendoListView').dataSource.read();
    kendo.jQuery(`#${this.idViewComponent}`).data('kendoListView').refresh();

    this.setActionsList();
  }

  /**
   * Método encargado de bindear las acciones a la grid
   */
  setActionsList() {
    setTimeout(() => {
      var actionsClick = kendo.observable({
        editClick: (e: any) => {
          var id = e.currentTarget.id;
          e.dataItem = kendo.jQuery(`#${this.idViewComponent}`)
            .data('kendoListView')
            .dataSource.data()
            .find((x) => x['Id'] == id);
          this.editSelected(e);
        },
        deleteClick: (e: any) => {
          var id = e.currentTarget.id;
          e.dataItem = kendo.jQuery(`#${this.idViewComponent}`)
            .data('kendoListView')
            .dataSource.data()
            .find((x) => x['Id'] == id);
          this.deleteSelected(e);
        },
        logicalDeleteClick: (e: any) => {
          var id = e.currentTarget.id;
          e.dataItem = kendo.jQuery(`#${this.idViewComponent}`)
            .data('kendoListView')
            .dataSource.data()
            .find((x) => x['Id'] == id);
          this.logicalDeleteSelected(e);
        },
        viewItemClick: (e: any) => {
          var id = e.currentTarget.id;
          e.dataItem = kendo.jQuery(`#${this.idViewComponent}`)
            .data('kendoListView')
            .dataSource.data()
            .find((x) => x['Id'] == id);
          this.viewSelected(e);
        },
        navigationClick: (e: any) => {
          var id = e.currentTarget.id;
          var queryString = $(e.currentTarget).data().querystring;
          e.dataItem = kendo.jQuery(`#${this.idViewComponent}`)
            .data('kendoListView')
            .dataSource.data()
            .find((x) => x['Id'] == id);
          this.openDirective(queryString, e);
        },
        actionCodeClick: (e: any) => {
          var id = e.currentTarget.id;
          var actionId = $(e.currentTarget).data().actionid;
          e.dataItem = kendo.jQuery(`#${this.idViewComponent}`)
            .data('kendoListView')
            .dataSource.data()
            .find((x) => x['Id'] == id);
          this.actionCode(actionId, e);
        },
      });

      kendo.bind($(`#${this.idViewComponent}`), actionsClick);
    }, 5500);
  }

  /**
   * Método encargado de setear estilos a la grid
   */
  setListClass() {
    var selector = this.isAgrupable ? '.cards' : `#${this.idViewComponent}`;
    $(`#${this.idViewComponent}`).removeClass('k-listview');
    $(`#${this.idViewComponent}`).append(`
    <style>
      .fil-cover-image{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
        transform: translate(-50%, -50%);
      }
      p.card-text.mb-2{
        flex: 1;
        overflow: hide;
        white-space: normal;
      }
      .card-img-overlay {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
      }
      .k-card-footer {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        height: 18%;
      }
      @media(min-width: 576px){
        .card-media-query{
          width: calc(50% - 1em);
        }
      }
      @media (max-width: 576px) {
        .card-media-query{
          width: 100%;
        }
      }
      ${selector} {
        display: flex;
        flex-wrap: wrap;
        gap: 1em;
      }

      .k-listview-item .k-content {
        width: 18rem;
        margin-bottom: 2%;
      }
    </style>`);
  }
  //Configurar List view
  async buildList(viewName: any, entityId: any) {
    this.entityId = entityId;
    this.viewEntityName = viewName;

    var appViewId = this.viewCode || this.route.snapshot.queryParams.appViewId;
    var paramsViewName: any = {
      dataType: 'list',
      id: viewName,
      entityId,
    };

    if (appViewId != '' && appViewId !== null && appViewId != undefined) {
      paramsViewName.appViewId = appViewId;
    }

    await this.proxyConfigApiService
      .getViewConfig(paramsViewName, async (data: any) => {
        var dataEditing;
        this.configTable = data;
        //Validar que la vista tenga permisos o sea anonima
        for (var i = 0; i < this.configTable.dataEditing.length; i++) {
          if (sessionStorage.getItem('rolesId')) {
            if (sessionStorage.getItem('rolesId').includes(',')) {
              let stringRoles = sessionStorage.getItem('rolesId').split(',');
              stringRoles.forEach((rol) => {
                if (this.configTable.dataEditing[i].rolesId.includes(rol)) {
                  dataEditing = this.configTable.dataEditing[i];
                }
              });
            } else if (this.configTable.dataEditing[i].rolesId.includes(sessionStorage.getItem('rolesId'))) {
              dataEditing = this.configTable.dataEditing[i];
            }
          } else if (this.isAnonymous == true) {
            dataEditing = this.configTable.dataEditing[i];
            // TODO: Poner servicio compartido para cambiar los temas de la aplicación
          }
        }

        this.collapseFilter = this.configTable.DisplayMenuTab;
        this.nameEntity = data.captionText;
        this.logicalDelete = this.configTable ? this.configTable.LogicalDelete : false;
        this.showAdd = this.configTable && dataEditing ? dataEditing.allowAdd : true;
        this.showCancel = this.configTable ? this.configTable.ShowCancelButton : true;
        this.showCreate = this.configTable ? this.configTable.ShowCreateButton : true;
        this.showDelete = this.configTable && dataEditing ? dataEditing.allowDelete : true;
        this.showEdit = this.configTable && dataEditing ? dataEditing.allowEdit : true;
        this.showSearch = this.configTable && this.configTable.design ? !this.configTable.design.hideSearchBox : true;
        this.showTabsMenu = this.configTable.DisplayMenutab;
        this.showToolbar = this.configTable && this.configTable.toolbarSettings ? !this.configTable.toolbarSettings.hideToolbar : true;
        this.showViewItem = this.configTable && dataEditing ? dataEditing.allowViewItem : false;

        if (location.hash.includes('nameBack')) {
          var parts = location.hash.split('&');
          parts.forEach((item: any) => {
            if (item.includes('nameBack')) {
              this.nameBack = (' - ' + decodeURI(item.split('=')[1])).replace(
                /_/g,
                ' '
              );
            }
          });
        }

        function isNumeric(n: any) {
          return !isNaN(parseFloat(n)) && isFinite(n);
        }

        function getBoolean(str: any) {
          if ('true'.startsWith(str)) {
            return true;
          } else if ('false'.startsWith(str)) {
            return false;
          } else {
            return null;
          }
        }

        $('#filter').on('input', (e: any) => {
          var fields = this.configTable.fields;
          var grid = kendo.jQuery(`#${this.idViewComponent}`).data('kendoListView');
          var filter = { logic: 'or', filters: [] };
          var value = e.target.value;

          fields.forEach((x: any) => {
            var field = x.RutaGrid
              ? x.RutaGrid.split('Details').join('')
              : x.Code;

            if (isNumeric(value)) {
              value = parseInt(value);
              filter.filters.push({
                field,
                operator: 'contains',
                value,
              });
            } else {
              value = value.toString();
              filter.filters.push({
                field,
                operator: 'contains',
                value,
              });
            }
          });
          grid.dataSource.filter(filter);
        });
        if (data.CustomMessages) {
          data.CustomMessages.forEach((message: any) => {
            this.customMessages[message.MessageType] = message.Message;
          });
        }
        this.viewsToOpen = {
          Create: data.ViewToOpenCreate,
          Edit: data.ViewToOpenEdit,
        };

        //Agregar acciones globales
        this.configTable.Actions.forEach((action: any) => {
          if (sessionStorage.getItem('rolesId')) {
            if (sessionStorage.getItem('rolesId').includes(',')) {
              let stringRoles = sessionStorage.getItem('rolesId').split(',');
              stringRoles.forEach((rol) => {
                if (action.rolesId.includes(rol)) {
                  if (action.GlobalAction) {
                    this.globalActions.push(action);
                  }
                }
              });
            } else if (action.rolesId.includes(sessionStorage.getItem('rolesId'))) {
              if (action.GlobalAction) {
                this.globalActions.push(action);
              }
            }
          } else if (this.isAnonymous == true) {
            if (action.GlobalAction) {
              this.globalActions.push(action);
            }
          }
        });
        const uniqueActions = this.globalActions.filter((action, index) => {
          const _action = JSON.stringify(action);
          return (
            index ===
            this.globalActions.findIndex((action) => {
              return JSON.stringify(action) === _action;
            })
          );
        });
        this.globalActions = uniqueActions;
        this.actionsMenuOptions =
          this.globalActions && this.globalActions.length > 0 ? true : false;

        if (
          data.hasOwnProperty('controlLsListView') &&
          data.controlLsListView !== null
        ) {
          if (data.controlLsListView.TimeRefreshSelect) {
            var time = data.controlLsListView.TimeRefresh * 60000;
            setInterval(this.refresh, time);
          }
        }

        //#region Kendo list create
        var aggregates = [],
          gridColumns = [],
          command = [],
          fieldsModel = {},
          permissions: any = [];
        this.gridColumns = [];

        this.configTable.fields.forEach((value: any) => {
          if (value.IsEditableInGrid) {
            this.editableFields = true;
          }
        });

        for (var i = 0; i < this.configTable.permissions.length; i++) {
          if (sessionStorage.getItem('rolesId')) {
            if (sessionStorage.getItem('rolesId').includes(',')) {
              let stringRoles = sessionStorage.getItem('rolesId').split(',');
              stringRoles.forEach((rol) => {
                if (this.configTable.permissions[i].rolesId.includes(rol)) {
                  permissions = this.configTable.permissions[i];
                }
              });
            } else if (
              this.configTable.permissions[i].rolesId.includes(sessionStorage.getItem('rolesId'))) {
              permissions = this.configTable.permissions[i];
            }
          } else if (this.isAnonymous == true) {
            permissions = this.configTable.permissions[i];
          }
        }

        //Configurar templete list view
        //#region Template Listview
        var fields = this.configTable.fields;
        this.isAgrupable = fields.find((a: any) => a.Order == 0) ? true : false;
        var isTitle = fields.find((a: any) => a.Order == 1) ? true : false;
        var isSubTitle = fields.find((a: any) => a.Order == 2) ? true : false;
        var isDescription = fields.find((a: any) => a.Order == 3) ? true : false;
        var isFooter = fields.find((a: any) => a.Order == 4) ? true : false;
        var isImagen = fields.find((a: any) => a.Order == 5) ? true : false;
        var templateListView = ``;

        if (this.isAgrupable) {
          templateListView += `<h4 class="k-group-title">#= data.value ? data.value : '' #</h4>`;
          this.fieldGroup = {
            field: fields.find((a: any) => a.Order == 0).Code,
            dir: 'desc',
            compare: function (a: any, b: any) {
              if (a.items.length === b.items.length) {
                return 0;
              } else if (a.items.length > b.items.length) {
                return 1;
              } else {
                return -1;
              }
            },
          };
        }
        let templateImage, templateTitle, templateSubTitle, templateDescription, templateActions;
        templateListView += `# for (var i = 0; i < data.items.length; i++) { # <div class="card${data.controlLsListView?.Template?.includes("horizontal") ? " card-media-query" : ""}" 
        style="height: -webkit-fill-available; 
        ${data.controlLsListView?.Template?.includes("background") ? "flex: 1;min-width: 14.7em;max-width: 24.4em; height: 14em;"
            : data.controlLsListView?.Template?.includes("vertical") ? "flex: 1;min-width: 14.7em;max-width: 18em;" : ""}">`;

        if (isImagen) {
          let currentApp = JSON.parse(sessionStorage.getItem('currentApp'));
          let url = '';
          let baseUrlImage = backandGlobal.URL_API_UPLOAD_IMAGES;
          if (currentApp.storageProvider) {
            baseUrlImage = `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api/documents/viewImage/`;
          }
          let styleImageCard = "fil-cover-image";
          if (data.controlLsListView?.Template?.includes("vertical")) styleImageCard = "";
          templateImage = ` <img src="#=data.items[i].` + fields.find((a: any) => a.Order == 5).Code + ` ? '${baseUrlImage}' + data.items[i].` + fields.find((a: any) => a.Order == 5).Code + `.replace('[','').replace(']','').split('/').join('|') : '' #" class="card-img-top ${styleImageCard}" alt="...">`;
        }

        // templateListView += `<div class="card-body">`;

        if (isTitle) {
          templateTitle = `<h5 class="card-title m-0" style="text-transform: none;">#= data.items[i].` + fields.find((a: any) => a.Order == 1).Code + ` ? data.items[i].` + fields.find((a: any) => a.Order == 1).Code + ` : ''  #</h5>`;
          if (!this.isAgrupable) {
            this.fieldGroup = {
              field: 'Id',
              dir: 'desc',
              compare: function (a: any, b: any) {
                if (a.items.length === b.items.length) {
                  return 0;
                } else if (a.items.length > b.items.length) {
                  return 1;
                } else {
                  return -1;
                }
              },
            };
          }
        }

        if (isSubTitle) {
          templateSubTitle = `<h6 class="card-subtitle m-2 text-muted">#= data.items[i].` + fields.find((a: any) => a.Order == 2).Code + ` ? data.items[i].` + fields.find((a: any) => a.Order == 2).Code + ` : '' #</h6>`;
          if (!this.isAgrupable && !isTitle) {
            this.fieldGroup = {
              field: 'Id',
              dir: 'desc',
              compare: function (a: any, b: any) {
                if (a.items.length === b.items.length) {
                  return 0;
                } else if (a.items.length > b.items.length) {
                  return 1;
                } else {
                  return -1;
                }
              },
            };
          }
        }

        if (isDescription) {
          templateDescription = `<p class="card-text mb-2">#= data.items[i].` + fields.find((a: any) => a.Order == 3).Code + `? data.items[i].` + fields.find((a: any) => a.Order == 3).Code + ` : '' #</p>`;
          if (!this.isAgrupable && !isTitle) {
            this.fieldGroup = {
              field: 'Id',
              dir: 'desc',
              compare: function (a: any, b: any) {
                if (a.items.length === b.items.length) {
                  return 0;
                } else if (a.items.length > b.items.length) {
                  return 1;
                } else {
                  return -1;
                }
              },
            };
          }
        }

        // templateListView += `</div><div class="k-card-footer">`;

        if (isFooter) {
          templateListView +=
            `<h5 class="k-card-subtitle"> #=data.items[i].` +
            fields.find((a: any) => a.Order == 4).Code +
            `? data.items[i].` +
            fields.find((a: any) => a.Order == 4).Code +
            `: '' #</h5>`;
        }

        // templateListView += `<div class="action-card">`;
        templateActions = '';
        if (this.configTable.permissions[0].allowEditFromGrid) {
          templateActions += `<a id="#= data.items[i].Id #" class="btn btn-sm" data-bind="click: editClick" data-toogle="tooltip" title="Editar"><i class="fa fa-edit"></i></a>`;
        }

        if (this.configTable.permissions[0].allowDeleteFromGrid) {
          if (this.logicalDelete) {
            templateActions += `<a id="#= data.items[i].Id #" class="btn btn-sm" data-bind="click: logicalDeleteClick" data-toogle="tooltip" title="Eliminar"><i class="fa fa-trash"></i></a>`;
          } else {
            templateActions += `<a id="#= data.items[i].Id #" class="btn btn-sm" data-bind="click: deleteClick" data-toogle="tooltip" title="Eliminar"><i class="fa fa-trash"></i></a>`;
          }
        }

        if (this.configTable.Actions.length > 0) {
          this.configTable.Actions.forEach((value: any) => {
            if (sessionStorage.getItem('rolesId')) {
              if (sessionStorage.getItem('rolesId').includes(',')) {
                let stringRoles = sessionStorage.getItem('rolesId').split(',');
                stringRoles.forEach((rol) => {
                  if (value.rolesId.includes(rol)) {
                    if (!value.GlobalAction) {
                      switch (value.Type) {
                        case 'NavegarVista':
                          templateActions += `<a id="#= data.items[i].Id #" name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm' data-bind="click: navigationClick" data-queryString="${value.QueryString}"><i class="fa ${value.IconAwesomeAction}"></i></a>`;
                          break;
                        case 'Codigo':
                          templateActions += `<a id="#= data.items[i].Id #" name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm' data-bind="click: actionCodeClick" data-actionId="${value.ActionId}"><i class="fa ${value.IconAwesomeAction}"></i></a>`;
                          break;
                        case 'Encuesta':
                          templateActions += `<a id="#= data.items[i].Id #" name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm' data-bind="click: navigationClick" data-queryString="${value.QueryString}"><i class="fa ${value.IconAwesomeAction}"></i></a>`;
                          break;
                      }
                    }
                  }
                });
              } else if (
                value.rolesId.includes(sessionStorage.getItem('rolesId'))
              ) {
                if (!value.GlobalAction) {
                  switch (value.Type) {
                    case 'NavegarVista':
                      templateActions += `<a id="#= data.items[i].Id #" name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm' data-bind="click: navigationClick" data-queryString="${value.QueryString}"><i class="fa ${value.IconAwesomeAction}"></i></a>`;
                      break;
                    case 'Codigo':
                      templateActions += `<a id="#= data.items[i].Id #" name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm' data-bind="click: actionCodeClick" data-actionId="${value.ActionId}"><i class="fa ${value.IconAwesomeAction}"></i></a>`;
                      break;
                    case 'Encuesta':
                      templateActions += `<a id="#= data.items[i].Id #" name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm' data-bind="click: navigationClick" data-queryString="${value.QueryString}"><i class="fa ${value.IconAwesomeAction}"></i></a>`;
                      break;
                  }
                }
              }
            } else if (this.isAnonymous == true) {
              if (!value.GlobalAction) {
                switch (value.Type) {
                  case 'NavegarVista':
                    templateActions += `<a id="#= data.items[i].Id #" name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm' data-bind="click: navigationClick" data-queryString="${value.QueryString}"><i class="fa ${value.IconAwesomeAction}"></i></a>`;
                    break;
                  case 'Codigo':
                    templateActions += `<a id="#= data.items[i].Id #" name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm' data-bind="click: actionCodeClick" data-actionId="${value.ActionId}"><i class="fa ${value.IconAwesomeAction}"></i></a>`;
                    break;
                }
              }
            }
          });
        }
        let templateBodyCard = `
        <div >${templateImage || ''}</div>
        <div class="card-body">
          <div>
            ${templateTitle || ''}
            ${templateSubTitle || ''}
          </div>
          ${templateDescription || ''}
          <div>
            ${templateActions || ''}
          </div>
        </div>`;
        // templateListView += `<div class="card-body">`;
        if (data.controlLsListView?.Template) {
          if (data.controlLsListView.Template == 'template-horizontal-derecha' || data.controlLsListView.Template == 'template-horizontal-izquierda') {
            templateBodyCard = `
            <div class="row no-gutters">
            ${data.controlLsListView.Template == 'template-horizontal-derecha' ? `<div class="col-md-4 col-xs-4 col-4"> ${templateImage || ''} </div>` : ''}
            <div class="col-8 col-md-8 col-xs-8">
                <div class="card-body">
                  <div>
                    ${templateTitle || ''}
                    ${templateSubTitle || ''}
                  </div>
                ${templateDescription || ''}
                  <div>
                    ${templateActions || ''}
                  </div>
                </div>
              </div>
              ${data.controlLsListView.Template == 'template-horizontal-izquierda' ? `<div class="col-md-4 col-xs-4 col-4"> ${templateImage || ''} </div>` : ''}
            </div>
            `
          } else if (data.controlLsListView.Template == 'template-background-image') {
            templateBodyCard = `
              ${templateImage || ''}
              <div class="card-img-overlay">
                <div>
                  ${templateTitle || ''}
                  ${templateSubTitle || ''}
                </div>
                ${templateDescription || ''}
                <div>
                  ${templateActions || ''}
                </div>
              </div>`;
          } else if (data.controlLsListView.Template == 'template-vertical-abajo') {
            templateBodyCard = `
            <div class="card-body">
              <div>
                ${templateTitle || ''}
                ${templateSubTitle || ''}
              </div>
            ${templateDescription || ''}
              <div>
                ${templateActions || ''}
              </div>
            </div>
            ${templateImage || ''}`;
          }
        }

        templateListView += `${templateBodyCard}</div># } #`;

        this.templeteListView = kendo.template(templateListView);
        //#endregion

        var gridColumns = [];
        this.arrayPositions = [];

        var url_grid: string = '';

        var gridFieldColumns = gridColumns;
        this.gridColumns = gridColumns;
        var fieldsColumns = [];
        var entityInclude = [];
        var stringEntity = '';
        fieldsColumns.push('Id');
        var expanded_entities = [];
        if (!this.configTable.filter) {
          // Get all expands...
          url_grid = `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api/lappiz/get/${this.viewEntityName}`;
        } else {
          url_grid = this.contextValuesService.transformODataQuery(this.configTable.filter.Query);
        }
        this.dataSort = this.odataFilterMap.getOrderBy(url_grid);

        this.odataFilterOptions = this.odataFilterMap.extractOptions(url_grid);
        this.filterGridOptions = this.odataFilterMap.getFilterOptions(this.odataFilterOptions.filterQuery);

        this.url = this.odataFilterOptions.url;

        var gridFieldColumns = gridColumns;
        entityInclude = this.odataFilterMap.getIncludeEntities(this.odataFilterOptions.filterQuery, gridFieldColumns, this.viewName);
        let uniqueField = [...new Set(entityInclude)];
        stringEntity = this.odataFilterMap.getIncludes(uniqueField);

        this.dataSource = new kendo.data.DataSource({
          transport: {
            read: async (options: any) => {
              var optionsFilter = options;
              // no funciono el split operator {...this.filterGridOptions }
              const filterGridOptionsAux = JSON.parse(JSON.stringify(this.filterGridOptions));
              if (!optionsFilter.data.filter)
                optionsFilter.data.filter = filterGridOptionsAux;
              else if (filterGridOptionsAux.filters) {
                filterGridOptionsAux.filters.push(optionsFilter.data.filter)
                optionsFilter.data.filter = filterGridOptionsAux;
              }

              if (this.dataSort != null || this.dataSort != undefined) {
                optionsFilter.data.sort = this.dataSort
                this.dataSort = null;
              }

              if (!!optionsFilter.data.sort) {
                if (!!optionsFilter.data.sort[0]) {
                  var field = optionsFilter.data.sort[0].field;
                  if (
                    optionsFilter.data.sort[0].field.split('.').length == 1
                  ) {
                    optionsFilter.data.sort[0].field = `${this.viewEntityName + '.' + field}`;
                  } else
                    optionsFilter.data.sort[0].field = `[${field}]`;
                }
              }

              var urlTx = `${backandGlobal.api2}/${sessionStorage.getItem(
                'workspace'
              )}.api/api/lappiz/get/${this.viewEntityName}`;
              var entityNameOff = this.viewEntityName;
              sessionStorage.setItem('entityname', entityNameOff);
              optionsFilter.data.includeEntities = stringEntity;
              optionsFilter.data.tenantId = sessionStorage.getItem('tenantId');
              optionsFilter.data.parameters = {
                userId: `${sessionStorage.getItem('userId')}`,
                appViewId: `${this.viewCode || this.route.snapshot.queryParams.appViewId}`,
                pType: 'showinmenu',
                aType: 'ffija',
                environment: `${backandGlobal.environment}`,
              };

              if (optionsFilter.data.sort) {
                this.sortGrid = optionsFilter.data.sort;
              }

              if (this.sortGrid && !optionsFilter.data.sort) {
                optionsFilter.data.sort = this.sortGrid;
              }
              await this.proxyTxApiService.getTxData(`${urlTx}`, JSON.stringify(optionsFilter.data), backandGlobal.stateOnline, entityNameOff)
                .then((response: any) => {
                  if (backandGlobal.stateOnline) {
                    if (response.data.count > 0) {
                      if (response.data.rows[0].Lappiz_Users) {
                        for (let j = 0; j < response.data.rows.length; j++) {
                          response.data.rows[j][
                            `${sessionStorage.getItem('workspace')}_Users`
                          ] = response.data.rows[j].Lappiz_Users;
                        }
                      }
                    }

                    options.success(response.data);
                  } else {
                    options.success(response);
                  }
                })
                .catch((error: any) => {
                  console.log('Error:', error);
                });
            },
          },
          schema: {
            model: {
              fields: (data: any) => {
                if (backandGlobal.stateOnline) {
                  return Object.keys(data.rows[0]);
                } else {
                  return Object.keys(data.rows[0]);
                }
              },
            },
            data: (data: any) => {
              if (backandGlobal.stateOnline) {
                return data.rows;
              } else {
                return data.rows;
              }
            },
            total: (data: any) => {
              if (backandGlobal.stateOnline) {
                return data.count;
              } else {
                return data.total;
              }
            },
          },
          group: this.fieldGroup,
          pageSize: 8,
          serverPaging: true,
        });

        this.mainListOptions = {
          dataSource: this.dataSource,
          dataBound: (e: any) => {
            var items = e.sender.dataSource.data();

            if (items.length == 0) {
              return;
            }

            setTimeout(() => {
              this.setActionsList();
              this.setListClass();
            }, 25);
          },
          dataBinding: () => {
            setTimeout(() => {
              this.setActionsList();
              this.setListClass();
              if (this.isMobile) {
                $('.k-current-page').hide();
                $(
                  'div.k-pager-wrap.k-listView-pager.k-widget.k-floatwrap'
                ).removeClass('k-pager-sm');
              }
            }, 25);
          },
          template: this.templeteListView,
          navigatable: true,
          pageable: true,
        };

        setTimeout(() => {
          this.setListClass();
          kendo.jQuery(`#${this.idViewComponent}`).kendoListView(this.mainListOptions);
          kendo.jQuery(`#pager_${this.idViewComponent}`).kendoPager({
            dataSource: this.dataSource,
          });
          this.setActionsList();
          this.listLoaded(this.configTable);
        }, 1500);
      })
      .catch((error: any) => {
        if (!localStorage.getItem('Authorization') && error.status == 404) {
          this.router.navigate([
            `/auth/login/${sessionStorage.getItem('workspace')}`,
          ]);
        }
      })
      .finally(() => { });
  }

  /**
   * Método encargado para ejecutar evento ListLoaded
   */
  listLoaded(config: any) {
    this.applicationService.fireEvents(
      'ListLoaded',
      config.formsEvents,
      config
    );
  }

  addRow() {
    var defaultFieldsValues = null;
    if (!this.showAdd) {
      this.notificationsServices.notificationApp('error', Constants.MESSAGES.CanNotCreate);
      return;
    }

    if (this.filterOptions) {
      defaultFieldsValues = [];
      var filterOptions = JSON.parse(this.filterOptions);
      filterOptions.forEach((filterOption: any) => {
        defaultFieldsValues.push({
          fieldName: filterOption.fieldName,
          value: filterOption.value,
        });
      });
    }

    var params: NavigationExtras = {
      queryParams: {
        viewName: this.viewNameId,
        entityId: this.entityId,
        entityName: this.viewNameId,
      },
    };

    if (defaultFieldsValues) {
      params.queryParams.defaultFieldsValues =
        JSON.stringify(defaultFieldsValues);
    }

    if (this.viewsToOpen.Create != undefined) {
      params.queryParams.appViewId = this.viewsToOpen.Create;
    }

    this.router.navigate(['/forms'], params);
  }

  async getDataListView(options: any, arrayNone: any, arrayAnd: any, arrayOr: any) {
    var optionsFilter = options;
    var filterGridOptions = this.filterGridOptions;

    if (arrayNone != undefined || arrayNone != null) {
      var nIteracion = arrayNone.length / 3;
      var inicio = 0;
      var fin = 3;
      var bandera = 0;

      for (let i = 0; i < nIteracion; i++) {
        var part = arrayNone.slice(inicio, fin);

        for (
          let j = 0;
          j < this.filterGridOptions.filters[0].filters.length;
          j++
        ) {
          if (
            this.filterGridOptions.filters[0].filters[j].field == part[0] &&
            this.filterGridOptions.filters[0].filters[j].operator == part[1] &&
            this.filterGridOptions.filters[0].filters[j].value == part[2]
          ) {
            bandera++;
          }
        }

        if (bandera == 0) {
          if (part[2] != 'null') {
            this.filterGridOptions.filters[0].filters.push({
              field: part,
              operator: part[1],
              value: part[2],
            });
          }

          inicio = fin;
          fin = fin + 3;
        }

        bandera = 0;
      }
    }

    if (arrayAnd != undefined || arrayAnd != null) {
      var nIteracion = arrayAnd.length / 3;
      var inicio = 0;
      var fin = 3;
      var bandera = 0;

      for (let i = 0; i < nIteracion; i++) {
        var part = arrayAnd.slice(inicio, fin);

        for (let j = 0; j < this.filterGridOptions.filters.length; j++) {
          if (
            this.filterGridOptions.filters[j].field == part[0] &&
            this.filterGridOptions.filters[j].operator == part[1] &&
            this.filterGridOptions.filters[j].value == part[2]
          ) {
            bandera++;
          }
        }

        if (bandera == 0) {
          if (part[2] != 'null') {
            this.filterGridOptions.filters.push({
              field: part,
              operator: part[1],
              value: part[2],
            });
          }

          inicio = fin;
          fin = fin + 3;
        }

        bandera = 0;
      }
    }

    if (arrayOr != undefined || arrayOr != null) {
      var nIteracion = arrayOr.length / 3;
      var inicio = 0;
      var fin = 3;
      var bandera = 0;

      for (let i = 0; i < nIteracion; i++) {
        var part = arrayOr.slice(inicio, fin);

        for (
          let j = 0;
          j < this.filterGridOptions.filters[0].filters.length;
          j++
        ) {
          if (
            this.filterGridOptions.filters[0].filters[j].field == part[0] &&
            this.filterGridOptions.filters[0].filters[j].operator == part[1] &&
            this.filterGridOptions.filters[0].filters[j].value == part[2]
          ) {
            bandera++;
          }
        }

        if (bandera == 0) {
          if (part[2] != 'null') {
            this.filterGridOptions.filters[0].filters.push({
              field: part,
              operator: part[1],
              value: part[2],
            });
          }

          inicio = fin;
          fin = fin + 3;
        }

        bandera = 0;
      }
    }

    if (this.filterGridOptions != undefined) {
      if (optionsFilter.data.filter == undefined) {
        optionsFilter.data.filter = filterGridOptions;
      } else {
        if (this.filterGridOptions == undefined) {
          return this.filterGridOptions;
        } else {
          this.filterGridOptions.filters[1].filters =
            optionsFilter.data.filter.filters;
          optionsFilter.data.filter = this.filterGridOptions;
        }
      }
    }

    if (this.dataSort != null || this.dataSort != undefined) {
      optionsFilter.data.sort = [
        { field: this.dataSort[0], dir: this.dataSort[1] },
      ];
      this.dataSort = null;
    }

    if (
      optionsFilter.data.sort != null ||
      optionsFilter.data.sort != undefined
    ) {
      if (
        optionsFilter.data.sort[0] != undefined ||
        optionsFilter.data.sort[0] != null
      ) {
        if (optionsFilter.data.sort[0].field.split('.').length == 1) {
          var field = optionsFilter.data.sort[0].field;
          optionsFilter.data.sort[0].field = this.viewEntityName + '.' + field;
        }
      }
    }

    var urlTx = `${backandGlobal.api2}/${sessionStorage.getItem(
      'workspace'
    )}.api/api/lappiz/get/${this.viewEntityName}`;
    var entityNameOff = this.viewEntityName;
    sessionStorage.setItem('entityname', entityNameOff);
    optionsFilter.data.tenantId = sessionStorage.getItem('tenantId');
    optionsFilter.data.parameters = {
      userId: `${sessionStorage.getItem('userId')}`,
      appViewId: `${this.viewCode || this.route.snapshot.queryParams.appViewId}`,
      pType: 'showinmenu',
      aType: 'ffija',
      environment: `${backandGlobal.environment}`,
    };

    if (optionsFilter.data.sort) {
      this.sortGrid = optionsFilter.data.sort;
    }

    if (this.sortGrid && !optionsFilter.data.sort) {
      optionsFilter.data.sort = this.sortGrid;
    }
  }

  /**
   * Método encargado de ejecutar acciones globales
   */
  globalActionCode(action: any) {
    var scriptAction = this.applicationService.transformFunction(action.FormEvent);
    this.applicationService.evalEvent(scriptAction, {});
  }

  /**
   * Método encargado de ejecutar una acción de tipo código para el registro seleccionado
   */
  actionCode(actionId: any, selectedItem: any) {
    var e = {
      dataItem: selectedItem.dataItem
        ? selectedItem.dataItem
        : selectedItem.itemData,
    };

    var action = $.grep(this.configTable.Actions, (b: any) => {
      return b.ActionId === actionId;
    })[0];

    var scriptAction = this.applicationService.transformFunction(
      action.FormEvent
    );
    this.applicationService.evalEvent(scriptAction, e);
  }

  /**
   * Método encargado de ejecutar una acción de tipo navegación para el registro seleccionado
   */
  openDirective(queryString: string, selectedItem: any) {
    var params: NavigationExtras = {
      queryParams: {},
    };

    var parts = queryString.split('?')[1].split('&');

    for (let i = 0; i < parts.length; i++) {
      var value = parts[i].split('=')[1];

      if (value.includes('selectedItem')) {
        var data = {};

        if (selectedItem.dataItem != undefined) {
          data = selectedItem.dataItem;
        } else {
          data = selectedItem.itemData;
        }

        params.queryParams[parts[i].split('=')[0]] = `${data[value.replace('selectedItem.', '')]
          }`;
      } else {
        params.queryParams[parts[i].split('=')[0]] = parts[i].split('=')[1];
      }
    }

    var path = queryString.split('?')[0];

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`${path}`], params);
    });
  }

  editSelected(e: any) {
    if (e.dataItem != undefined) {
      localStorage.setItem('IdSelectedItem', e.dataItem.Id);
    } else {
      localStorage.setItem('IdSelectedItem', e.itemData.Id._value);
    }

    if (e.dataItem != undefined) {
      var params: NavigationExtras = {
        queryParams: {
          rowId: e.dataItem.Id,
          viewName: this.viewNameId,
          entityId: this.entityId,
        },
      };
    } else {
      var params: NavigationExtras = {
        queryParams: {
          rowId: e.itemData.Id._value,
          viewName: this.viewNameId,
          entityId: this.entityId,
        },
      };
    }

    if (this.viewsToOpen.Edit != undefined) {
      params.queryParams.appViewId = this.viewsToOpen.Edit;
    }
    if (this.inputStyle == 'inForm')
      this.editSelectedModal(e);
    else
      this.router.navigate(['/forms'], params);
  }

  /**
   * Método encargado de hacer borrado lógico de un registro seleccionado
   */
  async logicalDeleteSelected(e: any) {
    if (!this.showDelete) {
      this.notificationsServices.notificationApp('error', Constants.MESSAGES.CanNotDelete);
      return;
    }
    let result = await this.notificationsServices.confirmAlert('warning', this.customMessages.confirm);
    
      if (!result) {
        return
      }
      var id;
    if (e.dataItem != undefined) {
      id = e.dataItem.Id;
    } else {
      id = e.itemData.Id._value;
    }

    if (!id) {
      this.notificationsServices.notificationApp('error', Constants.MESSAGES.failure);
      return;
    }

    var viewName = this.viewNameId;
    if (!viewName) {
      this.notificationsServices.notificationApp('error', Constants.MESSAGES.failure);
      return;
    }

    var dataLoadedEvent = {
      entityId: this.entityId,
      entityCode: this.viewNameId,
      dataItem: e.dataItem,
    };

    this.dataItemLogicalEntity = e.dataItem;

    if (
      this.applicationService.fireEvents(
        'ItemDeleting',
        this.configTable.formsEvents,
        dataLoadedEvent
      )
    ) {
      var tenantId = sessionStorage.getItem('tenantId');

      this.dataItemLogicalEntity.RowStatus =
        this.dataItemLogicalEntity.RowStatus == 'Active'
          ? (this.dataItemLogicalEntity.RowStatus = 'Deleted')
          : 'Active';
      this.dataItemLogicalEntity.tenantId = tenantId;
      this.dataItemLogicalEntity.parameters = {
        userId: `${sessionStorage.getItem('userId')}`,
        appViewId: `${this.viewCode || this.route.snapshot.queryParams.appViewId}`,
        pType: 'Editar',
        aType: `${this.viewCode || this.route.snapshot.queryParams.appViewId != undefined
          ? 'view'
          : 'ffija'
          }`,
        environment: `${backandGlobal.environment}`,
      };

      if (this.configTable.HasHistorics) {
        await this.setHistoryValues();
      }

      var urlTxLogicalDelete = `${backandGlobal.api2}/${sessionStorage.getItem(
        'workspace'
      )}.api/api/lappiz/${viewName}/${id}`;

      this.proxyTxApiService
        .operationTxApi(
          `${urlTxLogicalDelete}`,
          JSON.stringify(this.dataItemLogicalEntity),
          'update',
          backandGlobal.stateOnline,
          viewName
        )
        .then((response: any) => {
          if (backandGlobal.stateOnline === false) {
            var entitiesChanged = localStorage.EntityNamesChanged
              ? JSON.parse(localStorage.EntityNamesChanged)
              : [];
            entitiesChanged.filter((entity) => entity === viewName).length == 0
              ? entitiesChanged.push(viewName)
              : '';
            localStorage.EntityNamesChanged = JSON.stringify(entitiesChanged);
          }

          this.notificationsServices.notificationApp('success', Constants.MESSAGES.SuccessDelete);
        })
        .catch((error: any) => {
          this.errorCallback(error);
        })
        .finally(() => {
          this.refresh();
        });
    }
  }

  /**
   * Método encargado de navegar a vista modo viewItem en el formulario
   */
  viewSelected(e: any) {
    if (e.dataItem != undefined) {
      localStorage.setItem('IdSelectedItem', e.dataItem.Id);
    } else {
      localStorage.setItem('IdSelectedItem', e.itemDta.Id._value);
    }

    if (e.dataItem != undefined) {
      var params: NavigationExtras = {
        queryParams: {
          rowId: e.dataItem.Id,
          viewName: this.viewNameId,
          entityId: this.entityId,
        },
      };
    } else {
      var params: NavigationExtras = {
        queryParams: {
          rowId: e.itemData.Id._value,
          viewName: this.viewNameId,
          entity: this.entityId,
        },
      };
    }

    if (this.viewsToOpen.Edit != undefined) {
      params.queryParams.appViewId = this.viewsToOpen.Edit;
    }

    this.router.navigate(['/viewItem'], params);
  }

  /**
   * Método encargado de hacer borrado físico de un registro seleccionado
   */
  async deleteSelected(e: any) {
    if (!this.showDelete) {
      this.notificationsServices.notificationApp('error', Constants.MESSAGES.CanNotDelete);
      return;
    }
    let result = await this.notificationsServices.confirmAlert('warning', this.customMessages.confirm);
    
      if (!result) {
        return
      }
      var id;
      if (e.dataItem != undefined) {
        id = e.dataItem.Id;
      } else {
        id = e.itemData.Id._value;
      }

      if (!id) {
        this.notificationsServices.notificationApp('error', Constants.MESSAGES.failure);
        return;
      }

      var viewName = this.viewNameId;
      if (!viewName) {
        this.notificationsServices.notificationApp('error', Constants.MESSAGES.failure);
        return;
      }

      var dataLoadedEvent = {
        entityId: this.entityId,
        entityCode: this.viewNameId,
        dataItem: e.dataItem,
      };

      if (
        this.applicationService.fireEvents(
          'ItemDeleting',
          this.configTable.formsEvents,
          dataLoadedEvent
        )
      ) {
        var opts = {
          parameters: {
            userId: `${sessionStorage.getItem('userId')}`,
            appViewId: `${this.viewCode || this.route.snapshot.queryParams.appViewId}`,
            pType: 'Eliminar',
            aType: 'view',
            environment: `${backandGlobal.environment}`,
          },
        };

        var tenant = sessionStorage.getItem('tenantId');
        var urlTxDelete = `${backandGlobal.api2}/${sessionStorage.getItem(
          'workspace'
        )}.api/api/lappiz/${viewName}/${id}/${tenant}`;

        this.proxyTxApiService
          .operationTxApi(
            `${urlTxDelete}`,
            JSON.stringify(opts),
            'delete',
            backandGlobal.stateOnline,
            viewName
          )
          .then((response: any) => {
            if (backandGlobal.stateOnline === false) {
              var entitiesChanged = localStorage.EntityNamesChanged
                ? JSON.parse(localStorage.EntityNamesChanged)
                : [];
              entitiesChanged.filter((entity) => entity === viewName).length == 0
                ? entitiesChanged.push(viewName)
                : '';
              localStorage.EntityNamesChanged = JSON.stringify(entitiesChanged);
            }

            if (viewName.includes('Lappiz_Users')) {
              this.userService.replicateDelete(
                id,
                sessionStorage.aplicattionuserid
              );
            }

            this.notificationsServices.notificationApp('success', Constants.MESSAGES.SuccessDelete);
          })
          .catch((error: any) => {
            this.errorCallback(error);
          })
          .finally(() => {
            this.refresh();
          });
      }
  }

  async setHistoryValues() {
    this.dataItemLogicalEntity.EventType = 'Actualizar';
    this.dataItemLogicalEntity.Edited_by = sessionStorage.getItem('userId');
    this.dataItemLogicalEntity.UserEmail = localStorage.getItem('userName');

    await this.userService
      .getIpUser()
      .then((response: any) => {
        this.dataItemLogicalEntity.IpAddress = response.ip;
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  /**
   * Método encargado de mostrar toastr
   */
  mostrarMensaje(type: any, message: any) {
    this.notificationsServices.notificationApp(type, message);
  }

  errorCallback(error: any) {
    this.load = false;
    if (error.status == 500 || error.status == 400) {
      if (error.body.error.message != undefined) {
        var messageError = error.body.error.message.value;

        if (messageError.includes('ConstraintViolationException')) {
          messageError = Constants.MESSAGES.EntityUsed
        }
      }

      if (messageError != null) {
        this.alerts = [{ type: 'danger', msg: messageError }];
        this.notificationsServices.notificationApp('error', messageError);
      } else {
        this.alerts = [{ type: 'danger', msg: this.customMessages.failure }];
        this.notificationsServices.notificationApp('error', Constants.MESSAGES.failure);
      }
    } else {
      this.alerts = [{ type: 'danger', msg: error.body }];
      this.notificationsServices.notificationApp('error', Constants.MESSAGES.failure);
    }
  }

  async addRecordDetails() {
    let modalInstance = await this.getModalIntence();
    await modalInstance.result.then((newItem: any) => {
      if (newItem) {
        this.addRegister(newItem);
      }
    }, () => { });
  }

  private async getModalIntence(e?: any) {
    if (e)
      e.dataItem = e.dataItem || e.itemData;
    var modalInstance = this.modalService.open(await ModalDetailsComponent, {
      animation: true,
      size: 'lg',
      backdrop: 'static',
      windowClass: 'right-modal'
    });
    modalInstance.componentInstance.viewName = this.viewName;
    modalInstance.componentInstance.rowId = e?.dataItem.Id || null;
    modalInstance.componentInstance.entityId = this.entityId;
    modalInstance.componentInstance.entityParentId = null;
    modalInstance.componentInstance.parent = 'inForm';
    modalInstance.componentInstance.parentRules = null;
    modalInstance.componentInstance.detailsId = this.idViewComponent;
    modalInstance.componentInstance.dataToSubmit = e?.dataItem || null;
    modalInstance.componentInstance.item = e?.dataItem || null;
    modalInstance.componentInstance.appViewIdDetails = this.viewsToOpen.Create;
    modalInstance.componentInstance.isViewItem = false;
    modalInstance.componentInstance.isDetails = false;
    return modalInstance;
  }

  addRegister(newItem: any) {
    newItem.__modified = true;
    newItem.isNewItem = true;
    var grid: any = kendo.jQuery(`#${this.idViewComponent}`).data('kendoListView');
    grid.dataSource.add(newItem);

    if (newItem.continue) {
      this.addRecordDetails();
    }

    if (newItem.saveAndEdit) {
      var data = {
        dataItem: newItem
      }
      this.editSelected(data);
    }
  }

  async editSelectedModal(e: any) {
    let modalInstance = await this.getModalIntence(e);
    await modalInstance.result.then((newItem: any) => {
      if (newItem) {
        this.editRegister(newItem);
      }
    }, () => { });
  }

  editRegister(newItem: any) {
    var ListView: any = kendo.jQuery(`#${this.idViewComponent}`).data('kendoListView');
    var dataSource = ListView.dataSource;
    var dataItem = dataSource._data.find(x => x.Id == newItem.Id);
    if (dataItem) {
      for (const key in newItem) {
        if (newItem.hasOwnProperty(key) && dataItem[key] !== undefined) {
          dataItem.set(key, newItem[key]);
        }
      }
      // If using remote data source, sync to update the backend
      //dataSource.sync();
      ListView.refresh();
    } else {
      console.error(`Item with Id ${newItem.Id} not found.`);
    }
  }

}
