import { Injectable } from '@angular/core';
import { TypeService } from './type.service';

@Injectable({
  providedIn: 'root',
})
export class ScriptService {
  public async importScript(url: string) {
    return await ScriptService.importScript(url);
  }

  public isThisScriptLoaded(url: string) {
    return ScriptService.isThisScriptLoaded(url);
  }

  public isValidUrl(url: string): boolean {
    return ScriptService.isValidUrl(url);
  }

  public static async importScript(url: string): Promise<void> {
    const validToLoadScript = [
      TypeService.isString(url),
      ScriptService.isValidUrl(url),
      !ScriptService.isThisScriptLoaded(url),
    ].every(Boolean);

    if (validToLoadScript) {
      await ScriptService.loadScript(url);
    }
  }

  public static isThisScriptLoaded(url: string) {
    return !!document.querySelector(`script[src="${url}"]`);
  }

  public static isValidUrl(url: string): boolean {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  }

  private static async loadScript(url: string): Promise<void> {
    await new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.src = url;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  }
}
