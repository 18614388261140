import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import 'devextreme/integration/jquery';

import { ContextValuesService } from '@services/context-values.service';
import { OdataFilterMapService } from '@services/odata-filter-map.service';
import { ProxyTxApiService } from '@services/proxy-tx-api.service';

import { RootScopeService } from '@shared/root-scope.service';

import backandGlobal from '@env/env';

@Component({
  selector: 'app-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss']
})
export class TimelineComponent implements OnInit {

  @Input() value: any;
  @Input() filter: any;
  @Input() publicationsMyPerfil: any;
  @Input() listPublications: any;

  appViewId: any;
  datasort: any;
  filterGridOptions: any;
  item: string;
  odataFilterOptions: any;
  opc: any;
  refreshTimeline: any;
  ShowTabs: boolean;
  types: any[];
  urlTimeline: any;
  userEntity: any;
  viewName: any;

  constructor(
    private route: ActivatedRoute,
    private contextValuesService: ContextValuesService,
    private odataFilterMapService: OdataFilterMapService,
    private proxyTxApiService: ProxyTxApiService,
    private $rs: RootScopeService
  ) {
    this.route.queryParamMap.subscribe((params) => {
      this.appViewId = params.get('appViewId');
    });
  }

  async ngOnInit(): Promise<void> {
    if (backandGlobal.environment == "DEV" || backandGlobal.environment == "TEST") {
      this.urlTimeline = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/get/${sessionStorage.workspace}_TimeLine`;
      this.userEntity = `${sessionStorage.workspace}_UsersDetails.FullName`;
      this.viewName = sessionStorage.workspace + "_TimeLine";

      this.opc = {
        "take": 10, "skip": 0, "page": 1, "pageSize": 10,
        "includeEntities": `[{\"model\": modelo.${sessionStorage.workspace}_Users, \"as\":\"${sessionStorage.workspace}_Users\"}]`,
        "tenantId": sessionStorage.tenantId,
        "parameters": {
          "userId": sessionStorage.userId,
          "pType": "showinmenu",
          "aType": "ffija",
          "environment": backandGlobal.environment
        }
      };
    } else if (backandGlobal.environment == "PROD") {
      this.urlTimeline = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/get/Lappiz_TimeLine`;
      this.userEntity = `Lappiz_UsersDetails.FullName`;
      this.viewName = "Lappiz_TimeLine";

      this.opc = {
        "take": 10, "skip": 0, "page": 1, "pageSize": 10,
        "includeEntities": "[{\"model\": modelo.Lappiz_Users, \"as\":\"Lappiz_Users\"}]",
        "tenantId": sessionStorage.tenantId,
        "parameters": {
          "userId": sessionStorage.userId,
          "pType": "showinmenu",
          "aType": "ffija",
          "environment": backandGlobal.environment
        }
      };
    }

    this.filterGridOptions = {
      logic: 'and',
      filters: [{
        logic: 'or',
        filters: []
      }]
    }

    this.opc.filter = this.filterGridOptions;

    if (sessionStorage.filterTimeLine != "null") {
      this.urlTimeline = this.contextValuesService.transformODataQuery(sessionStorage.filterTimeLine);
      this.odataFilterOptions = this.odataFilterMapService.extractOptions(this.urlTimeline);
      let arrayAux = [];
      let arrayAnd = [];
      let arrayOr = [];
      let arrayNone = [];

      if (this.odataFilterOptions.filterQuery != undefined) {
        let filterArray = this.odataFilterOptions.filterQuery.split(" ");
        filterArray.forEach(function (value) {
          let aux = value;
          if (value.includes("*+*")) {
            aux = value.split("*+*").join(" ");
          }
          aux = aux.replace("'", "").replace("'", "");
          arrayAux.push(aux);
        });

        for (let i = 0; i < arrayAux.length; i++) {
          if (arrayAux[i] == 'and') {
            let bandera = i + 1;
            for (let j = 0; j < 3; j++) {
              arrayAnd[j] = arrayAux[bandera];
              bandera++;
            }
          } else if (arrayAux[i] == 'or') {
            let bandera = i + 1;
            for (let j = 0; j < 3; j++) {
              arrayOr[j] = arrayAux[bandera];
              bandera++;
            }
          } else if (i == 0) {
            let bandera = i;
            for (let j = 0; j < 3; j++) {
              arrayNone[j] = arrayAux[bandera];
              bandera++;
            }
          }
        }

        this.filterGridOptions = {
          logic: 'and',
          filters: [{
            logic: 'or',
            filters: []
          }]
        }

        if (arrayNone != undefined || arrayNone != null) {
          let nIteracion = arrayNone.length / 3;
          let inicio = 0;
          let fin = 3;
          for (let i = 0; i < nIteracion; i++) {
            let part = arrayNone.slice(inicio, fin);
            this.filterGridOptions.filters[0].filters.push({
              field: part[0],
              operator: part[1],
              value: part[2]
            });
            inicio = fin;
            fin = fin + 3;
          }
        }

        if (arrayAnd != undefined || arrayAnd != null) {
          let nIteracion = arrayAnd.length / 3;
          let inicio = 0;
          let fin = 3;
          for (let i = 0; i < nIteracion; i++) {
            let part = arrayAnd.slice(inicio, fin);
            this.filterGridOptions.filters.push({
              field: part[0],
              operator: part[1],
              value: part[2]
            });
            inicio = fin;
            fin = fin + 3;
          }
        }

        if (arrayOr != undefined || arrayOr != null) {
          let nIteracion = arrayOr.length / 3;
          let inicio = 0;
          let fin = 3;
          for (let i = 0; i < nIteracion; i++) {
            let part = arrayOr.slice(inicio, fin);
            this.filterGridOptions.filters[0].filters.push({
              field: part[0],
              operator: part[1],
              value: part[2]
            });
            inicio = fin;
            fin = fin + 3;
          }
        }

        this.opc.filter = this.filterGridOptions;
        this.urlTimeline = this.odataFilterOptions.url;
      }

      if (this.urlTimeline.includes("$orderby=")) {
        let datasort = this.urlTimeline.split("$orderby=")[1].split(" ");
        this.datasort = datasort;
      } else {
        this.datasort = null;
      }

      if (this.datasort != null || this.datasort != undefined) {
        this.opc.sort = [{ field: this.datasort[0], dir: this.datasort[1] }];
        this.datasort = null;
      }

      this.urlTimeline = this.urlTimeline.split("$")[0];
    }

    if (this.publicationsMyPerfil == true) {
      this.refreshTimeline = true;
    }

    await this.proxyTxApiService.getTxDatatimeline(this.urlTimeline, this.opc, this.viewName).then(async (result: any) => {
      this.listPublications = $("#listPublications").dxList({
        dataSource: {
          store: {
            type: "array",
            data: result.data.rows
          },
          searchOperation: "contains",
          searchExpr: ["Caption", "Content", this.userEntity.replace('Details', ''), "Date"],
        },
        itemTemplate: (data: any) => {
          let dateTime = this.formatDateTime(data.Date);
          let userArray = sessionStorage.workspace + "_Users";
          let user = data[userArray] == undefined ? data["Lappiz_Users"] : data[userArray];
          let userPhoto = user["Photo"];
          let userName = user["FullName"];
          let caption = data.Caption;
          if (caption == null)
            caption = '';

          if (backandGlobal.environment == "DEV" || backandGlobal.environment == "TEST") {
            this.item = "<div class='publication' style='margin-top:-3px;'><div class='publication-margen'><div class='publication-header'><img src='" + this.formatPhoto(`${userPhoto}`) + "' class='publication-photo active-border' style='cursor:pointer' ng-click=viewMyProfile('" + data.UserId + "')><div class='col-xs-10 without-padding'> <div class='col-xs-9 without-padding'> <div class='publication-user active-color' style='cursor:pointer;' ng-click=viewMyProfile('" + data.UserId + "')>" + `${userName}` + "</div><div class='publication-area color-subtitle'>" + caption + "</div></div><div class='publication-date col-xs-3 color-text'>" + dateTime + "</div></div></div><div class='publication-content'>";
          } else if (backandGlobal.environment == "PROD") {
            this.item = "<div class='publication' style='margin-top:-3px;'><div class='publication-margen'><div class='publication-header'><img src='" + this.formatPhoto(`${data.Lappiz_Users.Photo}`) + "' class='publication-photo active-border' style='cursor:pointer' ng-click=viewMyProfile('" + data.UserId + "')><div class='col-xs-10 without-padding'> <div class='col-xs-9 without-padding'> <div class='publication-user active-color' style='cursor:pointer;' ng-click=viewMyProfile('" + data.UserId + "')>" + `${data.Lappiz_Users.FullName}` + "</div><div class='publication-area color-subtitle'>" + caption + "</div></div><div class='publication-date col-xs-3 color-text'>" + dateTime + "</div></div></div><div class='publication-content'>";
          }

          if (data.Content) {
            let urlRegex = /(https?:\/\/[^\s]+)/g;
            let contentFinal = data.Content.replace(urlRegex, function (url) {
              return '<a target="_blank" href="' + url + '">' + url + '</a>';
            });
            this.item += "<p class='content-text'>" + contentFinal + "</p>";
          }

          if (data.Multimedia) {
            this.item += "<br>";

            this.types = [];
            let multimedia = data.Multimedia;
            let photo = multimedia;
            let format = multimedia.split('.');
            format = format[1].replace('"', '').replace(']', '');
            if (format == "jpg" || format == "jpeg" || format == "png") {
              this.types.push("img");

              if (data.Link != null) {
                this.item += "<a title='" + data.Link + "' href='" + data.Link + "' target='_blank'>";
                this.item += "<img src='" + this.formatPhoto(photo) + "' class='content-image' style='display:initial'/>";
                this.item += "</a>";
              } else {
                this.item += "<img src='" + this.formatPhoto(photo) + "' class='content-image' style='display:initial'/>";
              }
            }
          } else if (data.MultimediaStreamingUrl != null && data.MultimediaStreamingUrl != "" && data.MultimediaStreamingUrl != undefined) {
            this.item += "<br>";

            let types = [];
            let multimedia = data.Multimedia.split(',');
            for (let i = 0; i < multimedia.length; i++) {
              let format = multimedia[i].split('.');
              format = format[1].replace('"', '').replace(']', '');
              if (format == "jpg" || format == "jpeg" || format == "png")
                types.push("img");
              else if (format == "mp4" || format == "wmv")
                types.push("video");
            }

            let streamingUrls = data.MultimediaStreamingUrl.split("<|>");
            if (streamingUrls.length > 1) {
              this.item += "<div class='containerBtnPublication'>";
              for (let i = 0; i < streamingUrls.length; i++) {
                let active = "";
                if (i == 0)
                  active = "active-color";
                this.item += "<button class='btnPublication " + active + "' id=bm" + i + "-" + data.Id + " style='padding:4px !important;background: transparent' ng-click=currentMultimedia('" + data.Id + "'," + i + "," + streamingUrls.length + ") style='background: transparent'>" + "<i class='fa fa-circle' aria-hidden='true'></i>" + "</button>";
              }

              this.item += "</div>";
            }

            for (let i = 0; i < streamingUrls.length; i++) {
              let visible = "style='display:none'";
              if (i == 0)
                visible = "style='display:initial'";

              if (types[i] == "img") {
                this.item += "<img id=m" + i + "-" + data.Id + " class='content-image' " + visible + "' src='" + streamingUrls[i] + "'/>";
              }

              if (types[i] == "video") {
                this.item += "<video id=m" + i + "-" + data.Id + " class='content-video' " + visible + "' src='" + streamingUrls[i] + "' controls></video>";
              }
            }
          } else {
            if (data.Link != null) {
              //Aqui deberian ir los demas templates para los diferentes links
              if (data.Link.includes("youtube")) {
                let urlVideo = data.Link.split("watch?v=");
                this.item += "<br><iframe class='content-video' src='https://www.youtube.com/embed/" + urlVideo[1] + "' frameborder='0' allowfullscreen></iframe>";
              }
            }
          }

          this.item += "<div class='color-text col-xs-12'>";
          this.item += "<div id='cl" + data.Id.toString().toUpperCase() + "' class='count-likes col-xs-6'>" + this.formatCountLikes(data.CountLikes) + "</div>";
          this.item += "<div id='cc" + data.Id.toString().toUpperCase() + "' class='count-comments col-xs-6'>" + this.formatCountComments(data.CountComments) + "</div>";
          this.item += "</div></div><div class='publication-footer color-text'>";
          if (this.$rs.$rootScope.AllowLikes)
            this.item += "<div  id='l" + data.Id.toString().toUpperCase() + "' class='col-xs-6' ng-click=like('" + data.Id.toString().toUpperCase() + "')> <i class='fa fa-thumbs-up' aria-hidden='true'></i> Me gusta<div></div></div>";

          if (this.$rs.$rootScope.AllowComments)
            this.item += "<div  class='col-xs-6' ng-click=comment('" + data.Id.toString().toUpperCase() + "')  data-toggle='modal' data-target='#popupComments'> <i class='fa fa-comment' aria-hidden='true'></i> Comentar</div></div></div></div>";

          if (this.listPublications) {
            let totalItems = this.listPublications.length;

            if (this.listPublications[totalItems - 1].Id == data.Id) {
              totalItems = totalItems - 20;
              if (totalItems < 0)
                totalItems = 0;

              let opc = {
                "take": 10, "skip": 0, "page": 1, "pageSize": 10,
                "filter": {
                  "logic": "or",
                  "filters": [{
                    "field": "UserId",
                    "operator": "eq",
                    "value": sessionStorage.userId
                  }]
                },
                "tenantId": sessionStorage.tenantId,
                "parameters": {
                  "userId": sessionStorage.userId,
                  "pType": "showinmenu",
                  "aType": "ffija",
                  "environment": backandGlobal.environment
                }
              };

              let urlMyLikes = "", entityLikes = "";

              if (backandGlobal.environment == "DEV" || backandGlobal.environment == "TEST") {
                entityLikes = `${this.$rs.$rootScope.AppName}_TimeLineLike`;
                urlMyLikes = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/get/${this.$rs.$rootScope.AppName}_TimeLineLike`;
              } else if (backandGlobal.environment == "PROD") {
                entityLikes = `Lappiz_TimeLineLike`;
                urlMyLikes = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/get/Lappiz_TimeLineLike`;
              }

              this.proxyTxApiService.getTxData(urlMyLikes, opc, backandGlobal.stateOnline, entityLikes).then((response: any) => {
                if (response.length > 0) {
                  let myLikes = response.data.rows;
                  for (let i = 0; i < myLikes.length; i++) {
                    let divLike = $('#1' + myLikes[i].TimeLineId.toString().toUpperCase());
                    divLike.addClass("active-color publication-like");
                    divLike.find("div").attr('id', myLikes[i].Id.toString());
                  }
                }
              });
            }
          }

          return this.item;
        },
        searchEnabled: true,
        searchMode: "contains",
        searchExpr: ["Caption", "Content", this.userEntity.replace('Details', ''), "Date"],
        pullRefreshEnabled: this.refreshTimeline,
        noDataText: this.$rs.$rootScope.lang == 'es' ? "No se encontraron publicaciones" : this.$rs.$rootScope.lang == 'en' ? "No posts found" : "No se encontraron publicaciones"
      });

      setTimeout(() => {
        $('.dx-list-search').css({
          "width": "60%",
          "margin-left": "20%"
        })
      }, 1000);
    });

    this.ShowTabs = false;

    if (this.$rs.$rootScope.tabs != null)
      this.ShowTabs = true;
  }

  formatCountLikes(totalLikes: any) {
    if (totalLikes == undefined || totalLikes == 0) {
      return "";
    } else if (totalLikes == 1) {
      return this.$rs.$rootScope.lang == 'es' ? "1 Me gusta" : this.$rs.$rootScope.lang == 'en' ? "1 Like" : "1 Me gusta";
    } else {
      return totalLikes + this.$rs.$rootScope.lang == 'es' ? " Me gusta" : this.$rs.$rootScope.lang == 'en' ? " Likes" : " Me gusta";
    }
  }

  formatCountComments(totalComments: any) {
    if (totalComments == undefined || totalComments == 0) {
      return "";
    } else if (totalComments == 1) {
      return this.$rs.$rootScope.lang == 'es' ? "1 Comentario" : this.$rs.$rootScope.lang == 'en' ? "1 Comment" : "1 Comentario";
    } else {
      return totalComments + this.$rs.$rootScope.lang == 'es' ? " Comentarios" : this.$rs.$rootScope.lang == 'en' ? " Comments" : " Comentarios";
    }
  }

  formatDateTime(dateTime: any) {
    // Fecha dato
    dateTime = new Date(dateTime);
    let hora = dateTime.getHours();
    hora = hora + 5;
    let minutos = dateTime.getMinutes();
    let segundos = dateTime.getSeconds();
    let mes = dateTime.getMonth() + 1;
    hora = (hora > 12) ? hora % 12 : hora;

    if (hora <= 9)
      hora = "0" + hora;

    if (minutos <= 9)
      minutos = "0" + minutos;

    if (segundos <= 9)
      segundos = "0" + segundos;

    // Fecha actual
    let dt = new Date();
    let mesActual = dt.getMonth() + 1;
    let fechaActual = dt.getDate() + "/" + mesActual + "/" + dt.getFullYear();
    let fechaDato = dateTime.getDate() + "/" + mes + "/" + dateTime.getFullYear();

    (fechaActual == fechaDato) ? dateTime = this.$rs.$rootScope.lang == 'es' ? "Hoy, a las " + hora + ":" + minutos + ":" + segundos : this.$rs.$rootScope.lang == 'en' ? "Today, at as " + hora + ":" + minutos + ":" + segundos : "Hoy, a las " + hora + ":" + minutos + ":" + segundos : dateTime = fechaDato;

    return dateTime;
  }

  formatPhoto = function (files: any) {
    if (files == undefined || files == null || files == "null" || files == "")
      return "assets/images/default-user.png";

    files = files.split(',');
    let file = files[files.length - 1];

    return backandGlobal.URL_API_UPLOAD_IMAGES + file.replace('"', '').replace('"', '').replace('[', '').replace(']', '');
  }

}
