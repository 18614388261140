<form [formGroup]="innerForm">
  <div>
    <div class="form-group">
      <div class="input-group mb-3">
        <input formControlName="field" *ngIf="field.format" [type]="showPassword ? 'text' : 'password'" name="field"
          id="{{field.campoId}}" class="form-control" [required]="field.required" [(ngModel)]="value.val"
          [hidden]="!field.show" [attr.disabled]="!field.IsEditable ? true : null || field.disabled ? true : null"
          ngClass="inputClass" [minlength]="field.minimumValue" (change)="change()" [pattern]="passwordRegExp" />
        <input formControlName="field" *ngIf="!field.format" [type]="showPassword ? 'text' : 'password'" name="field"
          id="{{field.campoId}}" class="form-control" required="{{field.required}}" [(ngModel)]="value.val"
          [hidden]="!field.show" [attr.disabled]="!field.IsEditable ? true : null || field.disabled ? true : null"
          ngClass="inputClass" [minlength]="field.minimumValue" (change)="change()" [pattern]="passwordRegExp" />
        <div class="input-group-prepend">
          <span class="input-group-text input-pass" id="basic-addon1" ngHide="field.show">
            <i alt="show" class="fa fa-eye eye-show" (click)="showPassword = !showPassword"
              [class.hide]="showPassword"></i>
            <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
              [class.hide]="!showPassword"></i>
          </span>
        </div>
      </div>
    </div>
    <div *ngIf="formSubmitted || innerForm.controls.field.errors?.required && innerForm.controls.field.touched"
      class="ft-appengine-required-input" id="minlength_{{field.campoId}}" role="alert">
      {{errors.required}}
    </div>
    <div class="ft-appengine-required-input" id="minlength_{{field.campoId}}" role="alert"
      *ngIf="formSubmitted || innerForm.controls.field.errors?.minlength && innerForm.controls.field.touched">
      {{errors.minimumValue}}
    </div>
    <div class="ft-appengine-required-input" id="maxlength_{{field.campoId}}" role="alert"
      *ngIf="formSubmitted || innerForm.controls.field.errors?.maxlength && innerForm.controls.field.touched">
      {{errors.maximumValue}}
    </div>
    <div class="ft-appengine-required-input" id="maxlength_{{field.campoId}}" role="alert"
      *ngIf="formSubmitted || innerForm.controls.field.errors?.pattern && innerForm.controls.field.touched">
      La contraseña debe contener mínimo 10 caracteres, un caracter especial, una mayúscula, una minúscula y un número
    </div>
  </div>
</form>
