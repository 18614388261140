import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';
import { v4 as uuidv4 } from 'uuid';

import { EntityService } from '@services/entity.service';
import { MenuService } from '@services/menu.service';
import { NotificationsService } from '@services/notifications.service';
import { ProxyTxApiService } from '@services/proxy-tx-api.service';
import { UserService } from '@services/user.service';

import { RootScopeService } from '@shared/root-scope.service';

import backandGlobal, { environment } from '@env/env';
import { ProxyConfigApiService } from '@services/proxy-config-api.service';
import { GlobalEventsService } from '@services/global-events.service';
import { AppContextService } from '@services/app-context.service';
import { Network } from '@capacitor/network';
import { Constants } from '@common/constants';

@Component({
  selector: 'app-mobile-bar',
  templateUrl: './mobile-bar.component.html',
  styleUrls: ['./mobile-bar.component.scss']
})
export class MobileBarComponent implements OnInit {

  @Output() apps: EventEmitter<any[]>;
  @Output() userPhoto: EventEmitter<any>;

  menuItems = [];
  defaultClassIcon: string = 'fa-table';
  additionalWorkspaces: any[];
  iconApp: string;
  imageApp: string = '../../../../assets/images/app.png';
  currentWorkspaceApp: any;
  photoUser: any;
  menuItemsThird: any;
  menuItemsSecond: any;
  appName: string;
  loadedApplicationExecuted: boolean = false;

  constructor(
    private menuService: MenuService,
    private notificationsService: NotificationsService,
    private entityService: EntityService,
    private $rs: RootScopeService,
    private proxyTxApiService: ProxyTxApiService,
    private router: Router,
    private proxyConfigApiService: ProxyConfigApiService,
    private globalEvents: GlobalEventsService,
    private appContext: AppContextService,

  ) {
    this.loadMenu();
    this.apps = new EventEmitter();
  }

  ngOnInit(): void {
    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status);
      backandGlobal.stateOnline = status.connected;
      localStorage.setItem('stateOnline', `${status.connected}`);
    });
  }

  generateNewId() {
    return uuidv4();
  }

  setCurrentMenuSelectionMobile(current: any, parent: any, $event) {
    $event.stopPropagation();
    let sw = true;
    if ($event.currentTarget.className.includes('nav-item-selected')) {
      $($event.currentTarget).removeClass('nav-item-selected');
      sw = this.menuItems.findIndex(a => a.idNode == $event.currentTarget.id) < 0;
    } else {
      $($event.currentTarget).addClass('nav-item-selected');
    }
    if (this.menuItems.findIndex(a => a.idNode == $event.currentTarget.id) > -1) {
      this.menuItems.forEach(a => {
        if (a.idNode != $event.currentTarget.id) {
          $(`#${a.idNode}`).removeClass('nav-item-selected');
        } else if (a.isModule != 'ismodule') {
          this.hiddenMenu();
          this.menuService.setCurrentMenu(current);
          return;
        }
      })
      this.menuItemsSecond.forEach(a => {
        if ($(`#${a.idNode}`)[0].hidden)
          $(`#${a.idNode}`)[0].hidden = a.parentNode != $event.currentTarget.id
        else {
          $(`#${a.idNode}`)[0].hidden = true;
          $(`#${a.idNode}`).removeClass('nav-item-selected');
        }
      });
      this.menuItemsThird.forEach(a => {
        $(`#${a.idNode}`)[0].hidden = true;
      })
    } else if (this.menuItemsSecond.findIndex(a => a.idNode == $event.currentTarget.id) > -1) {
      if (this.menuItemsSecond.filter(a => a.idNode == $event.currentTarget.id && a.isModule != 'ismodule').length > 0) {
        this.hiddenMenu();
        this.menuService.setCurrentMenu(current);
        return;
      }
      this.menuItemsThird.forEach(a => {
        if ($(`#${a.idNode}`)[0].hidden && sw) {
          if (a.parentNode != $event.currentTarget.id) {
            $(`#${a.idNode}`)[0].hidden = true;
          } else
            $(`#${a.idNode}`)[0].hidden = false;
        }
        else {
          $(`#${a.idNode}`)[0].hidden = true;
        }
      });
    } else {
      this.hiddenMenu();
      this.menuService.setCurrentMenu(current);
    }
  }

  hiddenMenu() {
    this.menuItemsThird.forEach(a => {
      $(`#${a.idNode}`)[0].hidden = true;
    })
    this.menuItemsSecond.forEach(a => {
      $(`#${a.idNode}`)[0].hidden = true;
    })
  }

  private loadCurrentApp() {
    const currentAppJson = sessionStorage.getItem('currentApp');
    if (currentAppJson) {
      backandGlobal.currentApp = JSON.parse(currentAppJson);
    }
  }

  private emitApps(apps: any[]) {
    apps.forEach((app: any) => this.apps.emit(app));
  }

  async loadMenu(workspaceId?: string) {
    try {
      if (!workspaceId) {
        this.loadCurrentApp();
        this.additionalWorkspaces = await this.proxyConfigApiService.getUserApps(`${backandGlobal.url}/api/Account/userApps?$format=json`);
        if (this.additionalWorkspaces == null || (Array.isArray(this.additionalWorkspaces) && this.additionalWorkspaces.length == 0)) {
          this.notificationsService.notificationApp('warning', Constants.MESSAGES.NotActivatedRoles);
          let codeApp = sessionStorage.getItem('workspace');
          sessionStorage.clear();
          sessionStorage.setItem('workspace', codeApp);
          localStorage.clear();
          this.router.navigate([`/auth/login/${codeApp}`],);
        }
        this.$rs.$rootScope.additionalWorkspaces = this.additionalWorkspaces;
        // this.emitApps(this.additionalWorkspaces);

        const selectedWorkspaceCode = sessionStorage.getItem('workspace') ?? this.additionalWorkspaces[0].code;
        const selectedWorkspace = this.additionalWorkspaces.find(app => app.code === selectedWorkspaceCode);
        if (selectedWorkspace) {
          sessionStorage.setItem('workspaceId', selectedWorkspace.Id);
          const data = await this.proxyConfigApiService.getConfigApp(selectedWorkspace.Id);
          // await this.proxyConfigApiService.getallentitiesoffline(`${backandGlobal.url}/api/getallentitiesoffline/${selectedWorkspace.Id}`); 
          this.configApp(data);
          // if (backandGlobal.currentApp && backandGlobal.currentApp?.id === targetWorkspaceId) {
          //   const data = { workspace: backandGlobal.currentApp, versioncache:  backandGlobal.currentApp.versioncache};
          //   this.configApp(data);
          // } else {
          //   const data = await this.proxyConfigApiService.getConfigApp(selectedWorkspace.Id);
          //   this.configApp(data);
          // }
        }
      }
    } catch (error: any) {
      if (error.status === 401) {
        // this.onlogout();
      }
      this.notificationsService.notificationApp('error', Constants.MESSAGES.FailGeneral);
    }
  }

  configApp(data: any) {
    if (data.workspace.rolId) {
      sessionStorage.setItem("rolesId", data.workspace.rolId);
      localStorage.setItem("rolesId", data.workspace.rolId);
    }
    var primaryColorApp = data.workspace.colorTheme ? data.workspace.colorTheme : '#757575';
    var secondaryColorApp = data.workspace.secundaryColorTheme ? data.workspace.secundaryColorTheme : '#616161';
    // TODO: Poner servicio compartido para llamar el applyTheme

    if (data.workspace.appTitle) {
      document.title = data.workspace.appTitle;
    } else {
      document.title = data.workspace.nameApp;
    }

    if (data.workspace.tabIcon) {
      var iconApp = `data:image/PNG;base64,${data.workspace.tabIcon}`;
      $('#customIconApp').attr('href', `${iconApp}`);
    } else {
      var iconApp = '../../../../favicon.ico';
      $('#customIconApp').attr('href', `${iconApp}`);
    }

    if (data.workspace.icono) {
      this.imageApp = `data:image/PNG;base64,${data.workspace.icono}`;
    } else {
      this.imageApp = '../../../../assets/images/app.png';
    }

    $('meta[name="description"]').attr('content', data.workspace.nameApp);

    // Asignamos este código para que las configuraciones cuando la aplicación es multitenant pueda seguir funcionando
    // Siempre se debe usar esta variable cuando de consultar configuraciones se trate
    sessionStorage.configAppName = data.workspace.name;

    if (backandGlobal.CurrentLoginApp) {
      // En caso de haber un CurrentLoginApp (El cual puede ser una app o un tenant)
      // lo asignamos para que el runtime pueda seguir funcionando normalmente
      data.workspace.name = backandGlobal.CurrentLoginApp;
    }

    this.menuItems = data.workspace.pagesMobile;
    this.menuItemsSecond = [];
    this.menuItemsThird = [];
    this.menuItems.forEach(a => {
      a.idNode = this.generateNewId();
      if (a.isModule == "ismodule" && a.pages.length > 0) {
        a.pages.forEach(b => {
          b.idNode = this.generateNewId();
          b.parentNode = a.idNode;
          this.menuItemsSecond.push(b)
          if (b.isModule == "ismodule" && b.pages.length > 0) {
            b.pages.forEach(c => {
              c.idNode = this.generateNewId();
              c.parentNode = b.idNode;
              this.menuItemsThird.push(c)
            })
          }
        })
      }
    })
    this.currentWorkspaceApp = data.workspace;
    // backandGlobal.stateOnline = backandGlobal.stateOnline;
    backandGlobal.stateConfig = true;
    backandGlobal.currentApp = data.workspace;
    backandGlobal.urlAPI = data.workspace.UrlOData;
    backandGlobal.videoCall = data.workspace.videoCall;
    backandGlobal.chat = data.workspace.chat;
    data.workspace.versioncache = data.versioncache;
    sessionStorage.setItem('currentApp', JSON.stringify(data.workspace));
    sessionStorage.setItem('versionCache', data.versioncache);
    sessionStorage.setItem('workspaceId', data.workspace.id);
    sessionStorage.setItem('workspace', data.workspace.name);
    sessionStorage.setItem('DefaultPath', data.workspace.DefaultPath);
    sessionStorage.setItem('filterTimeLine', data.workspace.timeLineFilter);
    this.$rs.$rootScope.AppName = data.workspace.name;
    this.$rs.$rootScope.pages = data.workspace.pages;
    this.$rs.$rootScope.timeline = data.workspace.timeLine;
    this.$rs.$rootScope.tabs = data.workspace.tabs;
    this.$rs.$rootScope.HasNotifications = data.workspace.Notifications;
    this.$rs.$rootScope.AllowComments = data.workspace.AllowComments;
    this.$rs.$rootScope.AllowLikes = data.workspace.AllowLikes;
    this.$rs.$rootScope.workspaceIco = '../../../../assets/images/app.png';

    var userEntity = backandGlobal.environment == 'LOCAL' ||
      backandGlobal.environment == 'DEV' ||
      backandGlobal.environment == 'TEST' ? `${data.workspace.name}_Users` :
      `Lappiz_Users`;

    if (data.workspace.icono != null && data.workspace.icono != undefined && data.workspace.icono != '') {
      var ic = `data:image/PNG;base64,${data.workspace.icono}`;

      this.iconApp = ic;
      this.$rs.$rootScope.workspaceIco = ic;
    }

    // TODO: Cuando solucionen el api de notifications, ponen el getNotifications
    // this.getNotifications();

    // Si hay timeline valido si tiene action, si la hay sobreescribo profileUrl
    this.searchUserData();

    if (data.workspace.timeLine) {
      this.configTimeline(data);
    } else {
      this.configProfileUrl();
      this.entityService.getPermisosEdicion(this.currentWorkspaceApp.id, userEntity, sessionStorage.getItem('userId')).then((data: any) => {
        this.$rs.$rootScope.profileShowClass = 'ng-show';
      });
    }
    this.applicationLoaded(data.workspace.globalEvents);
    this.redirectApp();
  }

  redirectApp() {
    if (sessionStorage.getItem('DefaultPath') != 'null' && sessionStorage.getItem('DefaultPath') != undefined) {
      var parts = sessionStorage.getItem('DefaultPath').split('?');
      var variables = parts[1].split('&');

      var objNav = {};
      variables.forEach(value => {
        var part = value.split('=');
        objNav[part[0]] = part[1];
      });

      var paramsRoute: NavigationExtras = {
        queryParams: objNav
      }

      this.router.navigate([parts[0]], paramsRoute);
    } else if (this.$rs.$rootScope.timeline) {
      this.router.navigate([`/timeline`]);
    } else {
      this.router.navigate(['/']);
    }
  }

  getNotifications() {
    this.notificationsService.createSocketDesigner();

    // TODO: Ajustar el socket para la aplicación para notificaciones internas
    this.notificationsService.createSocket(function (newNotification: any) {
      // Llamar método newNotification del navbar
    });

    // TODO: Ajustar para cargar las notificaciones del usuario logueado
    var urlTx = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/notifications/user/${sessionStorage.userId}/${sessionStorage.rolesId}`;
    this.proxyTxApiService.dataGetTx(urlTx, backandGlobal.stateOnline).then((notifications: any) => {
      // Llamar método notificationsLoaded del navbar
    }).catch((error: any) => console.log(error));
  }

  async searchUserData() {
    var urlUserData = '';
    var fieldUserData = '';
    var userEntity = '';

    if (backandGlobal.environment == 'DEV' || backandGlobal.environment == 'TEST') {
      urlUserData = `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api/lappiz/get/${this.$rs.$rootScope.AppName}_Users`;
      fieldUserData = `[${this.$rs.$rootScope.AppName}_Users].[Id]`;
      userEntity = `${this.$rs.$rootScope.AppName}_Users`;
    } else if (backandGlobal.environment == 'PROD') {
      urlUserData = `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api/lappiz/get/Lappiz_Users`;
      fieldUserData = `[Lappiz_Users].[Id]`;
      userEntity = `Lappiz_Users`;
    }

    var opc = {
      'take': 10, 'skip': 0, 'page': 1, 'pageSize': 10,
      'filter': {
        'logic': 'or',
        'filters': [{
          'field': fieldUserData,
          'operator': 'eq',
          'value': sessionStorage.getItem('userId')
        }]
      },
      'tenantId': sessionStorage.getItem('tenantId'),
      'parameters': {
        'userId': sessionStorage.getItem('userId'),
        'pType': 'showinmenu',
        'aType': 'ffija',
        'environment': backandGlobal.environment
      }
    }

    await this.proxyTxApiService.getTxData(`${urlUserData}`, JSON.stringify(opc), backandGlobal.stateOnline, userEntity).then((response: any) => {
      var user: any;
      if (backandGlobal.stateOnline) {
        user = response.data.rows[0];
      } else {
        user = response.rows[0];
      }

      if (user) {
        user.Contrasena = ''; // Se limpia por seguridad
        sessionStorage.setItem('LappizUser', JSON.stringify(user));

        /**
         * Se comenta momentaneamente para compatibilidad de PouchDB
         */
        //#region Offline
        // var docUserData = {
        //   _id: 'userData',
        //   userData: user
        // };

        // this.appContext.insertUserData(docUserData);
        //#endregion

        var filesPhotos: string = user.Photo;
        if (filesPhotos != undefined) {
          var arrFilesPhotos = filesPhotos.split(',');
          var photoFile: string = arrFilesPhotos[arrFilesPhotos.length - 1];
          var photo = backandGlobal.URL_API_UPLOAD_IMAGES + photoFile
            .replace('"', '').replace('"', '')
            .replace('[', '').replace(']', '');
          this.photoUser = photo;
        } else {
          this.photoUser = '../../../../assets/images/default-user.png';
        }

        this.userPhoto.emit(this.photoUser);
      } else {
        sessionStorage.setItem('LappizUser', null);
        var message = `El usuario logueado no se encuentra registrado en ${userEntity}, tal vez algunas cosas no funcionen bien, contacta con tu administrador.`;
        this.notificationsService.notificationApp('warning', message);
      }
    });
  }

  /**
  * Método encargado para ejecutar evento applicationLoaded
  */
  applicationLoaded(config: any) {
    if (this.loadedApplicationExecuted) return;
    this.globalEvents.fireEvents('ApplicationLoaded', config, config);
    this.loadedApplicationExecuted = true;
  }

  async searchUserOfflineData() {
    await this.appContext.getUserData().then((context: any) => {
      var userData = context.userData;
      sessionStorage.setItem('LappizUser', JSON.stringify(userData));
    }).catch((error: any) => {
      console.log(`Ha ocurrido un error consultando los datos del usuario logueado`);
    })
  }

  configTimeline(data: any) {
    if (data.workspace.profileAction != null && data.workspace.profileAction != undefined) {
      this.$rs.$rootScope.profileUrl = {};
      var queryString = data.workspace.profileAction.split('?')[1].split('&');
      for (let i = 0; i < queryString.length; i++) {
        var part = queryString[i].split('=');
        this.$rs.$rootScope.profileUrl[part[0]] = part[1];

        if (part[0] == 'rowId') {
          this.$rs.$rootScope.profileFieldSearch = part[1].replace('selectedItem.', '');
        }

        if (part[0] == 'appViewId') {
          this.$rs.$rootScope.profileAppViewId = part[1];
        }
      }
    } else {
      this.configProfileUrl();
    }
    this.router.navigate(['/timeline']);
  }

  configProfileUrl() {
    var userEntity = '';
    if (backandGlobal.environment == 'DEV' || backandGlobal.environment == 'TEST') {
      userEntity = `${this.$rs.$rootScope.AppName}_Users`;
    } else if (backandGlobal.environment == 'PROD') {
      userEntity = `Lappiz_Users`;
    }

    this.entityService.getEntityIdByCodeAndAppId(`${userEntity}`, this.currentWorkspaceApp.id).then((data: any) => {
      this.$rs.$rootScope.profileUrl = {
        rowId: sessionStorage.getItem('userId'),
        viewName: `${userEntity}`,
        entityId: data
      };
    }).catch((error: any) => {
      console.log(error);
    });
  }

}
