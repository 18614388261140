import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { ProxyTxApiService } from '@services/proxy-tx-api.service';

import backandGlobal from '@env/env';

declare var kendo: any;

@Component({
  selector: 'app-rich-text-visor',
  templateUrl: './rich-text-visor.component.html',
  styleUrls: ['./rich-text-visor.component.scss']
})
export class RichTextVisorComponent implements OnInit, AfterViewInit {

  @Input() richTextId: any;
  @Input() codigo: any;
  @Input() viewNameId: any;

  tools: any;
  urlTx: string;
  titleEditorVisor: string;

  constructor(
    private route: ActivatedRoute,
    private proxyTxApiService: ProxyTxApiService
  ) {

    this.tools = {
      tools: ['pdf'],
      pdf: {
        fileName: 'NewDocument.pdf',
        paperSize: 'a4',
        margin: {
          bottom: 20,
          left: 20,
          right: 20,
          top: 20
        }
      }
    };

    this.titleEditorVisor = '';

  }

  ngAfterViewInit(): void {
    this.loadRichText();
  }

  ngOnInit(): void { }

  loadRichText() {
    this.urlTx = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/lappiz/get/${this.viewNameId}`;
    var opc = {
      'take': 10, 'skip': 0, 'page': 1, 'pageSize': 10,
      'filter': {
        'logic': 'or',
        'filters': [{
          'field': `${this.viewNameId}.[Id]`,
          'operator': 'eq',
          'value': `${this.richTextId}`
        }]
      },
      'tenantId': `${sessionStorage.getItem('tenantId')}`,
      'parameters': {
        'userId': `${sessionStorage.getItem('userId')}`,
        'appViewId': `${this.route.snapshot.queryParams.appViewId}`,
        'pType': 'showinmenu',
        'aType': 'view',
        'environment': `${backandGlobal.environment}`
      }
    };

    if (backandGlobal.stateOnline) {
      this.proxyTxApiService.getTxData(`${this.urlTx}`, JSON.stringify(opc), backandGlobal.stateOnline, `${this.viewNameId}`).then((response: any) => {
        var content = '';
        if (response.data.rows[0][this.codigo]) {
          content = `${response.data.rows[0][this.codigo].replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace('&nbsp;', ' ')}`;
        }

        this.titleEditorVisor = this.codigo;
        kendo.jQuery('#editorVisor').kendoEditor(this.tools);
        var editor = kendo.jQuery('#editorVisor').data('kendoEditor');
        editor.value(content);
      }).catch((error: any) => {
        console.error("Error en richTextVisor:", error);
      });
    } else {
      var objParam = {
        viewName: this.viewNameId,
        id: this.richTextId
      };

      this.proxyTxApiService.getTx(`${this.urlTx}`, JSON.stringify(opc), backandGlobal.stateOnline, objParam).then((response: any) => {
        var content = '';
        if (response[this.codigo]) {
          content = `${response[this.codigo].replace(/&amp;/g, '&').replace(/&lt;/g, '<').replace(/&gt;/g, '>').replace('&nbsp;', ' ')}`;
        }

        this.titleEditorVisor = this.codigo;
        kendo.jQuery('#editorVisor').kendoEditor(this.tools);
        var editor = kendo.jQuery('#editorVisor').data('kendoEditor');
        editor.value(content);
      }).catch((error: any) => {
        console.log("Error en richTextVisor:", error);
      });
    }
  }

}
