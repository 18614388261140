import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { RootScopeService } from '@shared/root-scope.service';

import backandGlobal from '@env/env';

@Component({
  selector: 'app-mapa-modal',
  templateUrl: './mapa-modal.component.html',
  styleUrls: ['./mapa-modal.component.scss']
})
export class MapaModalComponent implements OnInit {

  @Input() field: any;
  @Input() value: any;
  @Input() errors: any;
  @Input() select_location: boolean;
  @Input() showMap: boolean;

  @ViewChild('mapWrapper', { static: false }) mapElement: ElementRef;


  current_map: any;
  select_address: string;
  select_lat: number;
  select_lng: number;

  constructor(
    public activeModal: NgbActiveModal,
    private $rs: RootScopeService
  ) { }

  ngOnInit(): void {}

  ngAfterViewInit() {
    var lngLat = undefined;
    if (this.$rs.$rootScope.geolocalization) {
      this.select_address = this.$rs.$rootScope.geolocalization.address;
      this.select_lat = this.$rs.$rootScope.geolocalization.lat;
      this.select_lng = this.$rs.$rootScope.geolocalization.lng;
      lngLat = new google.maps.LatLng(
        this.$rs.$rootScope.geolocalization.lat,
        this.$rs.$rootScope.geolocalization.lng
      );
    } else {
      lngLat = new google.maps.LatLng(
        backandGlobal.MAP_DEFAULT_POSITION.pos[0],
        backandGlobal.MAP_DEFAULT_POSITION.pos[1]
      );
    }

    const mapOptions: google.maps.MapOptions = {
      center: lngLat,
      zoom: 16,
      fullscreenControl: true,
      mapTypeControl: true,
      streetViewControl: true,
    };

    this.current_map = new google.maps.Map(
      this.mapElement.nativeElement,
      mapOptions
    );

    const input = document.getElementById('search') as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);

    $('#search').val($(`#autocomplete_${this.field.campoId}`).val());
    searchBox.getPlaces();

    this.current_map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
    this.current_map.addListener('bounds_changed', () => {
      searchBox.setBounds(
        this.current_map.getBounds() as google.maps.LatLngBounds
      );
    });

    let markers: google.maps.Marker[] = [];

    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();

      if (places.length == 0) {
        return;
      }

      // Clear out the old markers.
      markers.forEach((marker) => {
        marker.setMap(null);
      });
      markers = [];

      // For each place, get the icon, name and location.
      const bounds = new google.maps.LatLngBounds();
      places.forEach((place) => {
        if (!place.geometry || !place.geometry.location) {
          // console.log("Returned place contains no geometry");
          return;
        }

        const icon = {
          url: place.icon as string,
          size: new google.maps.Size(71, 71),
          origin: new google.maps.Point(0, 0),
          anchor: new google.maps.Point(17, 34),
          scaledSize: new google.maps.Size(25, 25),
        };

        // Create a marker for each place.
        let map = this.current_map;
        markers.push(
          new google.maps.Marker({
            map,
            icon,
            title: place.name,
            position: place.geometry.location,
          })
        );

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }

        $('#search').val(place.formatted_address);
        this.select_address = place.formatted_address;
        this.select_lat = place.geometry.location.lat();
        this.select_lng = place.geometry.location.lng();

        this.select_location = true;
      });

      this.current_map.fitBounds(bounds);
    });

    var marker = new google.maps.Marker({
      position: lngLat,
    });

    marker.setMap(this.current_map);

    google.maps.event.addListener(this.current_map, 'click', (e) => {
      var positionClick = e.latLng;
      var geocoder = new google.maps.Geocoder();
      var latlng = new google.maps.LatLng(e.latLng.lat(), e.latLng.lng());
      geocoder.geocode({ location: latlng }, (results, status) => {
        if (status == 'OK') {
          $('#search').val(results[1].formatted_address);
          this.select_address = results[1].formatted_address;
          this.select_lat = results[1].geometry.location.lat();
          this.select_lng = results[1].geometry.location.lng();

          this.select_location = true;
        }
      });

      marker.setPosition(positionClick);

      searchBox.setBounds(
        this.current_map.getBounds() as google.maps.LatLngBounds
      );
      // if you don't do this, the map will zoom in
      e.domEvent.stopPropagation();
    });
  }

  saveAddress() {
    $(`#autocomplete_${this.field.campoId}`).val($('#search').val());
    var data = {
      address: this.select_address,
      lat: this.select_lat,
      lng: this.select_lng
    };
    this.activeModal.close(data);
  }

  cancelar() {
    this.activeModal.dismiss();
  }
}
