<div class="modal-header">
  <h3 class="modal-title" translate="carruselModal_visor">Visor</h3>
</div>
<div class="modal-body" style="text-align: center;">
  <div [ngSwitch]="type">
    <img [src]="fileUrl[0]" *ngSwitchCase="'Image'" class="videoOrImageStyle" />
    <video [src]="fileUrl[0]" *ngSwitchCase="'Video'" class="videoOrImageStyle" controls></video>
  </div>
</div>
<div class="modal-footer">
  <button (click)="activeModal.close('Close click')" class="btn btn-primary pull-right open-button"
    translate="carruselModal_exit">
    Cerrar
  </button>
</div>
