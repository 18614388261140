import { Component, OnInit, Input, AfterViewInit } from '@angular/core';

import backandGlobal from '@env/env';
import { DocumentsService } from '@services/documents.service';

@Component({
  selector: 'app-image-visor',
  templateUrl: './image-visor.component.html',
  styleUrls: ['./image-visor.component.scss']
})
export class ImageVisorComponent implements OnInit, AfterViewInit {

  @Input() imgId: any;
  @Input() imgExt: any

  imgSrc: any = '';
  urlTx: string;

  constructor(
    private documentsService: DocumentsService,
  ) { }

  ngAfterViewInit(): void {
    this.loadImg();
  }

  ngOnInit(): void { }

  loadImg() {
    let currentApp = JSON.parse(sessionStorage.getItem('currentApp'));
    let url = '';
    if (currentApp.storageProvider) { 
      this.documentsService.downloadFile(this.imgId).then(response => {
        url = `data:application/octet-stream;base64,${response}`;
        if(currentApp.storageProvider == "AzureBlobStorage"){
          url = `data:application/octet-stream;base64,${response}`;
        }else{
          url = JSON.parse(response).url;
        }
        this.urlTx = url;
        this.imgSrc = this.urlTx;
      }).catch((error)=>{
        console.log(error)
      });
    }else{
      url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/documents/${this.imgId}.${this.imgExt}`;
      this.urlTx = url;
      this.imgSrc = this.urlTx;
    }
  }

}
