import { Component, Input, OnInit, HostListener } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import SignaturePad from 'signature_pad';
import { FormsComponent } from '@components/forms/forms.component';
import { NotificationsService } from '@services/notifications.service';

@Component({
  selector: 'app-signature',
  templateUrl: './signature.component.html',
  styleUrls: ['./signature.component.scss'],
})
export class SignatureComponent implements OnInit {
  @Input() errors: any;
  @Input() field: any;
  @Input() value: any;

  innerForm: UntypedFormGroup;
  formSubmitted: any = false;
  errorGeneral: string;
  signaturePad: any;
  srcSignature: string;
  canvas: any;
  isDrawing = false; // Variable para rastrear si el usuario está dibujando

  constructor(
    private formsComponent: FormsComponent,
    private notificationsService: NotificationsService
  ) {
    this.errorGeneral = null;
    this.innerForm = new UntypedFormGroup({
      field: new UntypedFormControl(''),
    });
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.canvas = document.getElementById(`canva_${this.field.campoId}`);
      this.initSignaturePad();
      this.formsComponent.checkForm(this.field.campoId, this.innerForm.invalid);
    }, 200);
  }

  initSignaturePad() {
    this.signaturePad = new SignaturePad(this.canvas, {
      backgroundColor: 'rgba(255, 255, 255, 0)',
      penColor: 'rgb(0, 0, 0)',
    });

    // Ajustar el tamaño del canvas según el contenedor
    this.resizeCanvas();
  }

  // Método para cambiar el tamaño del canvas dinámicamente
  resizeCanvas() {
    const ratio = Math.max(window.devicePixelRatio || 1, 1);
    this.canvas.width = this.canvas.offsetWidth * ratio;
    this.canvas.height = this.canvas.offsetHeight * ratio;
    this.canvas.getContext('2d').scale(ratio, ratio);
  }

  @HostListener('window:resize')
  onResize() {
    this.resizeCanvas();
  }

  change() {
    if (this.field.required === true) {
      this.errorGeneral = !this.value.val || this.value.val == '' ? 'Firma acá' : null;
      this.formsComponent.checkForm(this.field.campoId, this.value.val != null && this.value.val != '' ? false : true);
    } else {
      this.errorGeneral = null;
      this.formsComponent.checkForm(this.field.campoId, false);
    }
  }

  getSignature() {
    if (this.signaturePad.isEmpty()) {
      this.notificationsService.notificationApp('warning', 'Primero debe firmar en el espacio indicado');
    } else {
      this.value.val = this.signaturePad.toDataURL();
      this.notificationsService.notificationApp('info', 'La firma ha sido cargada', 'Firma obtenida');
    }
  }

  clear() {
    this.signaturePad.clear();
    this.value.val = '';
  }
}
