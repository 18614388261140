<kendo-grid [kendoGridBinding]="gridView" kendoGridSelectBy="id" [selectedKeys]="mySelection" [pageSize]="5"
    [pageable]="true" [sortable]="true" [groupable]="false" [reorderable]="true" [resizable]="true" [height]="auto"
    [columnMenu]="{ filter: true }">

    <kendo-grid-checkbox-column [width]="45" [headerClass]="{'text-center': true}" [class]="{'text-center': true}"
        [resizable]="false" [columnMenu]="false" [showSelectAll]="true"></kendo-grid-checkbox-column>

    <kendo-grid-column field="full_name" title="Contact Name" [width]="220">

    </kendo-grid-column>
    <kendo-grid-column field="job_title" title="Job Title" [width]="220"
        [headerStyle]="{'background-color': '#666','color': '#fff','line-height': '1em'}">
    </kendo-grid-column>

</kendo-grid>