<div class="dx-viewport">
  <div class="demo-container">
    <div class="panel" *ngIf="ShowTabs" menu-tabs></div>
    <div id="listPublications" style="margin-top:20px;"></div>
  </div>
</div>

<div id="popupComments" class="modal fade" role="dialog" style="z-index:99999999">
  <div class="modal-dialog" style="width:92% !important; margin-left:5%;">
    <div class="modal-content">
      <div class="modal-header active-background active-border" style="color:white">
        <button type="button" class="align-right close" data-dismiss="modal"
          style="margin-top: -10px;text-align: right;"><i class="fa fa-times"></i></button>
        <h4 id="popupCountComments" class="modal-title" translate="comments_timeline"></h4>
      </div>
      <div class="modal-body col-xs-12">
        <form role="form" name="formComment">
          <div class="form-group col-xs-11">
            <textarea ng-model="txtComment" required class="form-control comment-input active-border"></textarea>
          </div>
        </form>
        <!--Lista de comentarios-->
        <div id="listComments" dx-list="listComments"></div>
      </div>
    </div>
  </div>
</div>
