<div class="row">
  <div class="col-md-12 stretch-card">
    <div class="card">
      <div class="card-body card-body-lappiz">
        <form class="forms-sample" [formGroup]="formHistoric">
          <div class="header-section my-2">
            <div class="title-section d-flex align-items-center" style="column-gap: 8px">
              <img class="btn-back" src="../../../assets/images/flecha-izquierda.png" (click)="cancelar()" title="Volver">
              <h3 class="card-title">Históricos</h3>
            </div>
          </div>
          <hr>
          <div id="gridHistory"></div>
        </form>
      </div>
    </div>
  </div>
</div>
