export const environment = {
  production: true,
  backAndGlobal: {
    URL_API_UPLOAD_IMAGES: 'https://designertest.lappiz.io/Api/api/Upload/UploadImages/', //Images
    url: 'https://designertest.lappiz.io/Api', //Configuration
    urlAPI: '', //Data
    api2: 'https://txtest.lappiz.io', //Nodejs
    designer: "https://designertest.lappiz.io/Admin", //Designer
    urlNotifications: 'https://notificationstest.lappiz.io', //Servicio de notificaciones
    routeCache: 'https://cdbtest.lappiz.io:6984/', //Ruta CouchDB
    environment: 'TEST', //Ambiente del Runtime;
    urlsignalr: 'http://designertest.lappiz.io/ChatServer/chat', // Ruta Chat Server
    urlWf: 'http://wftest.lappiz.io/wexflow/start?w=', // Ruta Workflows
    runtimeBeta: 'https://runtimetest.lappiz.io', // Url Runtime Alpha
    runtime: "https://runtimetestbeta.lappiz.io", // Url Runtime Beta
    stateOnline: true,
    stateConfig: true,
    videoCall: undefined,
    chat: undefined,
    defaultApp: null,
    enableBusy: true,
    timeline: false,
    CurrentLoginApp: "",
    MAP_DEFAULT_POSITION: { pos: [6.2527682, -75.5652314], address: "" } //Posicion por defecto en mapas
  }
};
