import { Component, OnInit, AfterViewInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { FormsComponent } from '@components/forms/forms.component';

declare var kendo: any;

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent implements OnInit, AfterViewInit, OnChanges {

  @Input() field: any;
  @Input() value: any;
  @Input() items: any;
  innerForm: UntypedFormGroup;

  constructor(private formsComponent: FormsComponent) {
    this.innerForm = new UntypedFormGroup({
      field: new UntypedFormControl()
    });
  }

  ngOnInit(): void {
    this.items = this.field.options;
    this.innerForm = new UntypedFormGroup({
      field: new UntypedFormControl(
        { value: this.field.value.val, disabled: !this.field.IsEditable || this.field.disabled },
        this.field.required ? Validators.required : []
      )
    });
  }

  ngAfterViewInit(): void {
    // Inicializa el MultiSelect de Kendo UI con jQuery
    kendo.jQuery(`#${this.field.campoId}`).kendoMultiSelect({
      dataTextField: "name",
      dataValueField: "value",
      dataSource: this.items,
      placeholder: "Seleccione...",
      autoClose: false,
      value: this.field.value.val?.split(','),
      change: (e) => {
        const selectedValues = e.sender.value();
        // Actualizar el valor del FormControl y la variable value
        this.innerForm.get('field')?.setValue(selectedValues);
        this.value.val = selectedValues;
        const selectedItems = e.sender.dataSource.view().filter(item => selectedValues.includes(item.value)); // objetos correspondientes

        // Llamar a change
        this.change(selectedItems);
      }
    });

    // Deshabilitar el control si es necesario
    if (!this.field.IsEditable || this.field.disabled) {
      kendo.jQuery(`#${this.field.campoId}`).data("kendoMultiSelect").enable(false);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
 
  }

  change(dataItem: any) {
    this.field.value.val = dataItem;
    this.formsComponent.setValueFieldForm(this.field.campoId, this.field);
    this.formsComponent.fireEvents('ValueChanged', this.field.events, { 
      value: dataItem, 
      field: this.field
    });
    this.formsComponent.applyUIRules(this.field, dataItem);
    this.formsComponent.checkForm(this.field.campoId, this.field.required === true ? this.value.val ? false : true : false);
  }
}
