<form [formGroup]="innerForm">
  <div>
    <div class="form-group">
      <div class="input-group mb-3">
        <input formControlName="field" type="{{field.type}}" [(ngModel)]="value.val" name="field" ngHide="field.show"
          id="{{field.campoId}}" class="form-control" (focus)="keypress($event)" (keypress)="keypress($event)"
          [required]="field.required" [attr.disabled]="!field.IsEditable ? true : null || field.disabled ? true : null"
          (change)="change()">
        <div class="input-group-prepend icons-field">
          <span (click)="onMicrophoneClick($event)" class="input-group-text icon-field icon-action" id="basic-addon0" *ngIf="field.IsSpeech && field.IsEditable">
            <i [ngClass]="classMicrophone"></i>
          </span>
          <span class="input-group-text icon-field" id="basic-addon1" ngHide="field.show">
            <i class="mdi mdi-email"></i>
          </span>
        </div>
      </div>
      <div *ngIf="formSubmitted || innerForm.controls.field.errors?.required && innerForm.controls.field.touched"
        class="ft-appengine-required-input" id="minlength_{{field.campoId}}" role="alert">
        {{errors.required}}
      </div>
      <div class="ft-appengine-required-input" id="minlength_{{field.campoId}}" role="alert"
        *ngIf="formSubmitted || innerForm.controls.field.errors?.minlength && innerForm.controls.field.touched">
        {{errors.minimumValue}}</div>
      <div class="ft-appengine-required-input" id="maxlength_{{field.campoId}}" role="alert"
        *ngIf="formSubmitted || innerForm.controls.field.errors?.maxlength && innerForm.controls.field.touched">
        {{errors.maximumValue}}</div>
      <div class="ft-appengine-required-input" id="maxlength_{{field.campoId}}" role="alert"
        *ngIf="(formSubmitted || innerForm.controls.field.touched)  && innerForm.controls.field.errors">
        {{errors.email}}</div>
    </div>
  </div>
</form>
