<div [class]="!isMobile ? 'main-wrapper' : 'main-wrapper main-wrapper-mobile'" *ngIf="configLayout" [hidden]="!isReady && !isAnonymous">
  <!-- partial:sidebar -->
  <app-sidebar (isReady)="onIsReady($event)" (layout)="onLayout($event)" (apps)="onAppsLoaded($event)" (isAdmin)="onIsAdmin($event)" (appImage)="onImageApp($event)"
    (userPhoto)="onPhotoUserLoaded($event)" (isMobile)="onDeviceLoaded($event)" (idleTime)="onIdleTime($event)" (sessionState)="onSessionState($event)"
    *ngIf="!isAnonymous" [hidden]="isMobile" (razonSocial)="onRazonSocial($event)"></app-sidebar>

  <!-- partial -->
  <div [class]="isAnonymous ? 'page-wrapper page-anonymous' : (configLayout.web.headerHide || configLayout.web.header == 'InMenu') && (configLayout.web.menu == 'HorizontalBottom') ? 'page-wrapper page-wrapper-web page-wrapper-horizontal-bottom-header' : (configLayout.web.menuHide || configLayout.web.header == 'InMenu') && (configLayout.web.menu == 'HorizontalTop') ? 'page-wrapper page-wrapper-web page-wrapper-horizontal-header' : configLayout.web.menu =='verticalRigth' ? 'page-wrapper page-wrapper-web page-wrapper-horizontalR' :  configLayout.web.menuHide ? 'page-wrapper page-wrapper-web' : !isMobile ? 'page-wrapper' : 'page-wrapper-mobile'">

    <!-- partial:navbar -->
  <app-navbar [headerWeb]="configLayout.web.header" [colorHeader]="configLayout.colorHeader" [backgroundHeader]="configLayout.backgroundHeader"  [menuWeb]="configLayout.web.menu" [hideMenu]="configLayout.web.menuHide" [isMobile]="isMobile" [isAdmin]="isAdmin" [imageApp]="imageApp" [apps]="arrApps" [photoUser]="userPhoto"
      (callMenu)="onCallMenu($event)" [hidden]="isAnonymous || ((configLayout.web.headerHide && !isMobile) || (configLayout.mobile.headerHide && isMobile)) || configLayout.web.header == 'InMenu' " *ngIf="!isAnonymous" [razonSocial]="razonSocial" ></app-navbar>
    <a href="" class="sidebar-toggler sidebar-toggler-mobile" (click)="toggleSidebar($event)" *ngIf="(configLayout.web.header == 'InMenu' || configLayout.web.headerHide) && configLayout.web.menu != 'HorizontalBottom' && configLayout.web.menu != 'HorizontalTop'"  >
      <i class="feather icon-menu"></i>
    </a>
    <!-- partial -->
    <div [class]="configLayout.web.menu == 'HorizontalBottom' ? 'page-content page-content-horizontal-bottom' : configLayout.web.headerHide || configLayout.web.header == 'InMenu' ? 'page-content page-content-web' : !isMobile ? 'page-content' : 'page-content page-content-mobile'" style="padding: 0;">

      <!-- Spinner for lazyload modules -->
      <div *ngIf="isLoading" class="spinner-wrapper">
        <div class="spinner">Loading...</div>
      </div>

      <div contentAnimate *ngIf="!isLoading" style="height: 100%;">
        <router-outlet></router-outlet>
      </div>

    </div>

    <app-footer *ngIf='!isMobile && !configLayout.web.footerHide' [backgroundFooter]="configLayout.backgroundFooter" [colorFooter]="configLayout.colorFooter"></app-footer>

    <div class="container-bottom" *ngIf='isMobile'>
      <app-mobile-bar *ngIf="!configLayout.mobile.menuHide"></app-mobile-bar>
      <!-- partial:footer -->
      <app-footer [class]="!isMobile ? 'footer-mobile':''" *ngIf="!configLayout.mobile.footerHide"></app-footer>
      <!-- partial -->
    </div>
  </div>
  <app-loader></app-loader>

</div>