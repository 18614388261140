import {
  Component, OnInit, Inject, Input,
  OnChanges, Output, EventEmitter
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { NavigationExtras, Router } from '@angular/router';

import { RootScopeService } from '@shared/root-scope.service';

import backandGlobal from '@env/env';
import { MsalService } from '@azure/msal-angular';
import { LocalDBManagerService } from '@services/local-dbmanager.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit, OnChanges {

  @Input() apps: any[];
  @Input() photoUser: any;
  @Input() imageApp: string;
  @Input() isMobile: boolean;
  @Input() isAdmin: boolean;
  @Input() hideMenu: boolean;
  @Input() menuWeb: string;
  @Input() backgroundHeader: string;
  @Input() colorHeader: string;
  @Input() razonSocial: string;
  @Output() callMenu: EventEmitter<any>;

  baseImagen: string = 'data:image/PNG;base64,';
  defaultImagen: string = '../../../../assets/images/app.png';

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private $rs: RootScopeService,
    private authService: MsalService,
    private pouchManager: LocalDBManagerService,
    private modalService: NgbModal
  ) {
    this.callMenu = new EventEmitter();
  }

  ngOnInit(): void { }

  ngOnChanges(): void { }

  goHomeApp() {
    if (sessionStorage.getItem('DefaultPath') != 'null' && sessionStorage.getItem('DefaultPath') != undefined) {
      var parts = sessionStorage.getItem('DefaultPath').split('?');
      var variables = parts[1].split('&');

      var objNav = {};
      variables.forEach(value => {
        var part = value.split('=');
        objNav[part[0]] = part[1];
      });

      var paramsRoute: NavigationExtras = {
        queryParams: objNav
      }

      this.router.navigateByUrl('/', { skipLocationChange: false }).then(() => {
        this.router.navigate([parts[0]], paramsRoute);
      });
    } else if (this.$rs.$rootScope.timeline) {
      this.router.navigateByUrl('/', { skipLocationChange: false }).then(() => {
        this.router.navigate([`/timeline`]);
      });
    } else {
      this.router.navigateByUrl('/', { skipLocationChange: false }).then(() => {
        this.router.navigate(['/']);
      });
    }
  }

  /**
   * Sidebar toggle on hamburger button click
   */
  toggleSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('sidebar-open');
  }

  openModal(content: any){
    this.modalService.open(content);
  }

  /**
   * Logout
   */

  checkLogOut(e:any, content:any){
    if(e !== true)
      e.preventDefault();

    const confirm = sessionStorage.getItem("confirmLogOutPopup");
    if(confirm){
      this.openModal(content);
    }else{
      this.onLogout(e);
    }
  }
  
  onLogout(e: any) {
    if(this.modalService.hasOpenModals())
      this.modalService.dismissAll();
    if (e !== true)
      e.preventDefault();
    localStorage.removeItem('Authorization');
    sessionStorage.removeItem('workspaceId');
    sessionStorage.removeItem('userId');

    this.destroyAllPouchDbCollection();

    if (localStorage.getItem('MicrosoftAuthentication')) {
      sessionStorage.removeItem('MicrosoftAuthentication');
      this.authService.logout();
    }
    sessionStorage.removeItem('currentApp');
    delete backandGlobal.currentApp;
    if (!localStorage.getItem('Authorization')) {
      let name = ''
      if (backandGlobal.currentApp)
        name = `/${backandGlobal.currentApp.name}`
      else if (sessionStorage.workspace)
        name = `/${sessionStorage.workspace}`
      this.router.navigate([`/auth/login${name}`]);
    }
  }

  private async destroyAllPouchDbCollection() {
    const allDbs = (!!localStorage.getItem('EntityNames')) ? JSON.parse(localStorage.getItem('EntityNames')) : [];
    this.pouchManager.removeSyncInformation();
    if (allDbs) {
      allDbs.forEach(element => {
        this.pouchManager.destroyPouchDb(element.Alias);
      });

      localStorage.removeItem('EntityNames');
    }
  }

  /**
   * Change app runtime
   */
  changeApp(workspaceId: string) {
    this.callMenu.emit(workspaceId);
  }

  /**
   * Redirect Recovery component
   */
  recoveryLink() {
    var params: NavigationExtras = {
      queryParams: {
        userId: sessionStorage.getItem('userId')
      }
    }

    this.router.navigate(['/recovery'], params);
  }

  invoices() {
    window.open(`${backandGlobal.designer}/Invoices`, "_blank");
  }

  contracts() {
    window.open(`${backandGlobal.designer}/LappizContracts`, "_blank");
  }

  designer() {
    window.open(`${backandGlobal.designer}/Tablas`, "_blank");
  }

}
