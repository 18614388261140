<div class="card">
  <div class="card-body card-body-lappiz">
    <div class="row w-100" style="flex-wrap: nowrap;">
      <div class="title-section w-100">
        <h3><i class="menu-icon fa fa-tags"></i>&nbsp;Permisos</h3>
      </div>
      <div class="d-flex align-items-center justify-content-end flex-wrap text-nowrap w-100">
        <button (click)="save()" type="button" class="btn btn-primary btn-icon-text mr-2 mb-md-0">
          <i data-feather="save" appFeatherIcon></i>
          Guardar
        </button>
      </div>
    </div>
    <hr>

    <div class="panel-body">
      <div class="header-section my-2">
        <div class="title-section">
          <h3 translate="title_entities_permissions">Entidades</h3>
        </div>
      </div>
      <div k-options="treelistOptions" id="Permisos"></div>
      <br />
      <div class="header-section my-2">
        <div class="title-section">
          <h3 translate="title_actions_permissions">Acciones</h3>
        </div>
      </div>
      <div k-options="treelistOptionsActions" id="PermisosActions"></div>
      <br />
      <div class="header-section my-2">
        <div class="title-section">
          <h3 translate="title_views_permissions">Vistas</h3>
        </div>
      </div>
      <div k-options="treelistOptionsAppViews" id="PermisosAppViews"></div>
      <br />
      <div class="header-section my-2">
        <div class="title-section">
          <h3 translate="title_lappizPages_permissions">Lappiz Pages</h3>
        </div>
      </div>
      <div k-options="treelistOptionsLappizPages" id="PermisosLappizPages"></div>
      <br />
      <div class="header-section my-2">
        <div class="title-section">
          <h3 translate="title_other_permissions">Otras funcionalidades</h3>
        </div>
      </div>
      <div k-options="treelistOptionsStaticFeatures" id="PermisosStaticFeatures"></div>
      <br />
      <div class="header-section my-2">
        <div class="title-section">
          <h3 translate="title_lappizFunctions_permissions">Lappiz Functions</h3>
        </div>
      </div>
      <div k-options="treelistOptionsLappizFunctions" id="PermisosLappizFunctions"></div>
      <br />
      <div class="header-section my-2">
        <div class="title-section">
          <h3 translate="title_ExecTX_permissions">Execute TX</h3>
        </div>
      </div>
      <div k-options="treelistOptionsExecTX" id="PermisosExecTX"></div>
      <br />
      <div class="header-section my-2">
        <div class="title-section">
          <h3 translate="title_dashboard_permissions">Dashboards</h3>
        </div>
      </div>
      <div k-options="treelistOptionsDashboards" id="PermisosDashboards"></div>

    </div>
  </div>
</div>
