import { DatePipe } from '@angular/common';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';

// import { MorrisJsModule } from 'angular-morris-js';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { WebcamModule } from 'ngx-webcam';
import { NgbActiveModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { DxDateBoxModule, DxTextAreaModule, DxCheckBoxModule, DxSelectBoxModule, 
  DxTabsModule, DxButtonModule, DxListModule } from 'devextreme-angular';

import { AuthGuard } from '@core/guard/auth.guard';

import { LoaderInterceptorService } from '@interceptors/loader-interceptor.service';

import { LoaderService } from '@services/loader.service';

import { LayoutModule } from '@components/layout/layout.module';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

// Lappiz Route Components
import { AsignarRolComponent } from '@components/asignar-rol/asignar-rol.component';
import { ChatComponent } from '@components/chat/chat.component';
import { EncuestaComponent } from '@components/encuesta/encuesta.component';
import { FormsComponent } from '@components/forms/forms.component';
import { GridsComponent } from '@components/grids/grids.component';
import { ListComponent } from '@components/list/list.component';
import { LoaderViewComponent } from '@components/loader-view/loader-view.component';
import { NavigationComponent } from '@components/navigation/navigation.component';
import { PermisosComponent } from '@components/permisos/permisos.component';
import { RecoveryComponent } from '@components/recovery/recovery.component';
import { RolComponent } from '@components/roles/rol/rol.component';
import { RolesComponent } from '@components/roles/roles.component';
import { TimelineComponent } from '@components/timeline/timeline.component';
// import { VideoCallComponent } from '@components/video-call/video-call.component';

// Lappiz Dynamic Components
// import { BarChartComponent } from '@components/charts/bar-chart/bar-chart.component';
// import { BubbleChartComponent } from '@components/charts/bubble-chart/bubble-chart.component';
import { AlertContractComponent } from '@components/layout/alert-contract/alert-contract.component';
import { AlertPayComponent } from '@components/layout/alert-pay/alert-pay.component';
import { CamaraComponent } from '@components/camara/camara.component';
import { CarouselComponent } from '@components/carousel/carousel/carousel.component';
import { CarouselModalComponent } from '@components/carousel/carousel-modal/carousel-modal.component';
import { CarouselVisorComponent } from '@components/carousel/carousel-visor/carousel-visor.component';
import { CheckboxComponent } from '@components/checkbox/checkbox.component';
import { ChecklistComponent } from '@components/checklist/checklist.component';
// import { ColumnChartComponent } from '@components/charts/column-chart/column-chart.component';
import { ComboboxComponent } from '@components/combobox/combobox.component';
import { CommentComponent } from '@components/comment/comment.component';
import { ContentComponent } from '@components/content/content.component';
import { CronometroComponent } from '@components/cronometro/cronometro.component';
// import { DashboardComponent } from '@components/dashboard/dashboard.component';
import { DateComponent } from '@components/date/date.component';
// import { DateTimeComponent } from '@components/date-time/date-time.component';
import { DetailsComponent } from '@components/details/details/details.component';
// import { DonutChartComponent } from '@components/charts/donut-chart/donut-chart.component';
import { EditorComponent } from '@components/editor/editor.component';
import { EmailComponent } from '@components/email/email.component';
import { FileComponent } from '@components/file/file.component';
import { FileGridComponent } from '@components/file-grid/file-grid.component';
import { FormulaComponent } from '@components/formula/formula.component';
// import { FriendsComponent } from '@components/friends/friends.component';
import { HistoryComponent } from '@components/history/history.component';
import { HtmlComponent } from '@components/html/html.component';
import { HtmlContentComponent } from '@components/htmlContent/html-content.component';
import { IframeContentComponent } from '@components/iframeContent/iframe-content.component';
import { ImageVisorComponent } from '@components/image-visor/image-visor.component';
import { ImageComponent } from '@components/image/image.component';
import { InputComponent } from '@components/input/input.component'
// import { LineChartComponent } from '@components/charts/line-chart/line-chart.component';
import { LinkComponent } from '@components/link/link.component';
import { LinkContentComponent } from '@components/linkContent/link-content.component';
import { MapaComponent } from '@components/mapa/mapa.component';
import { MapaModalComponent } from '@components/mapa/modal/mapa-modal/mapa-modal.component';
import { MenuTabsComponent } from '@components/menu-tabs/menu-tabs.component';
import { ModalDetailsComponent } from '@components/details/modal-details/modal-details.component';
import { MultiSelectComponent } from '@components/multi-select/multi-select.component';
import { NumericComponent } from '@components/numeric/numeric.component';
import { PasswordComponent } from '@components/password/password.component';
import { PDFVisorComponent } from '@components/pdfvisor/pdfvisor.component';
import { PercentageComponent } from '@components/percentage/percentage.component';
// import { PieChartComponent } from '@components/charts/pie-chart/pie-chart.component';
import { PopupComponent } from '@components/popup/popup.component';
// import { RadarChartComponent } from '@components/charts/radar-chart/radar-chart.component';
import { QrComponent } from '@components/qr/qr.component';
import { QrGridComponent } from '@components/qr-grid/qr-grid.component';
import { RadioComponent } from '@components/radio/radio.component';
import { RichTextVisorComponent } from '@components/rich-text-visor/rich-text-visor.component';
import { SchedulerComponent } from '@components/scheduler/scheduler.component';
import { SignatureComponent } from '@components/signature/signature.component';
import { SignatureGridComponent } from '@components/signature-grid/signature-grid.component';
import { SingleSelectComponent } from '@components/single-select/single-select.component';
import { SlideDescriptionComponent } from '@components/slide-description/slide-description.component';
import { TextareaComponent } from '@components/textarea/textarea.component';
import { TimeComponent } from '@components/time/time.component';
import { TripComponent } from '@components/trip/trip.component';

// jQuery
import $ from 'jquery';
// window['$'] = window['jQuery'] = $;
// window['$'] = window['$'], window['bootstrap'];

// kendo
import '@progress/kendo-ui';


// Moment
import moment from 'moment';

// Google maps
import { GoogleMapsModule } from '@angular/google-maps'; 

// BackandGlobal
import { environment } from '@env/environment';
import { KanbanComponent } from './components/kanban/kanban.component';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import { PageComponent } from './components/page/page.component';

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;


export function loggerCallback(logLevel: LogLevel, message: string) {
  //console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: "ceb1b349-1680-4891-b835-dd7c66bc66fa",
      // clientId: '3fba556e-5d4a-48e3-8e1a-fd57c12cb82e', // PPE testing environment
      authority: "https://login.microsoftonline.com/e419a1c7-50f0-4300-a6e6-955a5d8ffbb6",
      // authority: 'https://login.windows-ppe.net/common', // PPE testing environment
      redirectUri: 'http://localhost:4200',
      postLogoutRedirectUri: 'http://localhost:4200/#/auth/login'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11. Remove this line to use Angular Universal
    },
    system: {
      allowRedirectInIframe: true,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);
  // protectedResourceMap.set('https://graph.microsoft-ppe.com/v1.0/me', ['user.read']); // PPE testing environment

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read']
    },
    loginFailedRoute: '/login-failed'
  };
}

window['backandGlobal'] = environment.backAndGlobal;

@NgModule({
    declarations: [
        AppComponent,
        AsignarRolComponent,
        // BarChartComponent,
        // BubbleChartComponent,
        ChatComponent,
        CamaraComponent,
        CarouselComponent,
        CarouselModalComponent,
        CarouselVisorComponent,
        CheckboxComponent,
        ChecklistComponent,
        // ColumnChartComponent,
        ComboboxComponent,
        CommentComponent,
        ContentComponent,
        CronometroComponent,
        // DashboardComponent,
        DateComponent,
        // DateTimeComponent,
        DetailsComponent,
        // DonutChartComponent,
        EditorComponent,
        EmailComponent,
        EncuestaComponent,
        FileComponent,
        FileGridComponent,
        FormsComponent,
        FormulaComponent,
        // FriendsComponent,
        GridsComponent,
        HistoryComponent,
        HtmlComponent,
        HtmlContentComponent,
        IframeContentComponent,
        ImageVisorComponent,
        ImageComponent,
        InputComponent,
        // LineChartComponent,
        LinkComponent,
        LinkContentComponent,
        ListComponent,
        LoaderViewComponent,
        MapaComponent,
        MapaModalComponent,
        MenuTabsComponent,
        ModalDetailsComponent,
        MultiSelectComponent,
        NavigationComponent,
        NumericComponent,
        PasswordComponent,
        PDFVisorComponent,
        PercentageComponent,
        PermisosComponent,
        // PieChartComponent,
        PopupComponent,
        // RadarChartComponent,
        QrComponent,
        QrGridComponent,
        RadioComponent,
        RecoveryComponent,
        RichTextVisorComponent,
        RolComponent,
        RolesComponent,
        SchedulerComponent,
        SingleSelectComponent,
        SignatureComponent,
        SignatureGridComponent,
        SlideDescriptionComponent,
        TextareaComponent,
        TimeComponent,
        TimelineComponent,
        TripComponent,
        KanbanComponent,
        PageComponent,
        // VideoCallComponent
    ],
    imports: [
        AppRoutingModule,
        BrowserAnimationsModule,
        BrowserModule,
        DateInputsModule,
        DropDownsModule,
        DxButtonModule,
        DxCheckBoxModule,
        DxDateBoxModule,
        DxListModule,
        DxSelectBoxModule,
        DxTabsModule,
        DxTextAreaModule,
        FormsModule,
        GoogleMapsModule,
        GridModule,
        HttpClientModule,
        InputsModule,
        LayoutModule,
        NgbModule,
        NgSelectModule,
        ReactiveFormsModule,
        WebcamModule,
        NgxSpinnerModule,
        PdfViewerModule,
        ToastrModule.forRoot({
            closeButton: false,
            newestOnTop: false,
            progressBar: false,
            positionClass: 'toast-bottom-right',
            preventDuplicates: true,
            timeOut: 5000,
            extendedTimeOut: 1000
        }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: environment.production,
            // Register the ServiceWorker as soon as the app is stable
            // or after 30 seconds (whichever comes first).
            registrationStrategy: 'registerWhenStable:30000'
        }),
        MsalModule
    ],
    providers: [
        AuthGuard,
        DatePipe,
        ModalDetailsComponent,
        NgbActiveModal,
        PopupComponent,
        SlideDescriptionComponent,
        LoaderService, {
            provide: HTTP_INTERCEPTORS,
            useClass: LoaderInterceptorService,
            multi: true
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: MSALInstanceFactory,
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: MSALGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: MSALInterceptorConfigFactory
        }
    ],
    schemas: [
        CUSTOM_ELEMENTS_SCHEMA
    ],
    bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }