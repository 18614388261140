<nav [class]="menuWeb == 'HorizontalTop' ? 'navbar navbar-horizontal' : hideMenu && !isMobile? 'navbar navbar-web' : menuWeb == 'verticalRight' ? 'navbar navbar-web horizontalR' : isMobile ? 'navbar navbar-mobile' : 'navbar'" style="background-color: var(--white) !important;">
  <a href="" class="sidebar-toggler" (click)="toggleSidebar($event)" *ngIf="!isMobile">
    <i class="feather icon-menu"></i>
  </a>
  <!--- Logo -->
  <a class="sidebar-brand" (click)="goHomeApp()" *ngIf="isMobile"   style="height: inherit;">
    <img [src]="imageApp" class="sidebar-brand-mobile">
    <!-- <span>UI</span> -->
  </a>
  <div class="navbar-content"
  [style]="backgroundHeader && backgroundHeader != 'Default' ? 'background: ' + backgroundHeader: ''">
    <ul [class]="menuWeb == 'verticalRight' && !hideMenu ? 'navbar-nav navbar-horizontalR'  : 'navbar-nav'">
      <app-user-profile [colorHeader]="colorHeader" [razonSocial]="razonSocial"></app-user-profile>
      <li class="nav-item nav-admin" ngbDropdown *ngIf="isAdmin">
        <a class="nav-link" [style]="colorHeader && colorHeader != 'Default' ? 'color: ' + colorHeader: ''" ngbDropdownToggle id="adminDropDown">
          <i class="link-icon fa fa-tools"></i>
        </a>
        <div ngbDropdownMenu aria-labelledby="adminDropdown">
          <div class="dropdown-header d-flex align-items-center justify-content-between">
            <p class="mb-0 font-weight-medium">Admin designer</p>
          </div>
          <div class="dropdown-body">
            <ul class="profile-nav p-0 pt-3">
              <li class="nav-item">
                <button (click)="invoices()" class="nav-link" [style]="colorHeader && colorHeader != 'Default' ? 'color: ' + colorHeader: ''">
                  <i class="fa fa-file-invoice-dollar"></i>
                  <span>Mis facturas</span>
                </button>
              </li>
              <li class="nav-item">
                <button (click)="contracts()" class="nav-link" [style]="colorHeader && colorHeader != 'Default' ? 'color: ' + colorHeader: ''">
                  <i class="fa fa-file-contract"></i>
                  <span>Contratos</span>
                </button>
              </li>
              <li class="nav-item">
                <button (click)="designer()" class="nav-link" [style]="colorHeader && colorHeader != 'Default' ? 'color: ' + colorHeader: ''">
                  <i class="fa fa-pencil"></i>
                  <span>Diseñar</span>
                </button>
              </li>
            </ul>
          </div>
        </div>
      </li>
      <li class="nav-item nav-apps" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="appsDropdown" [style]="colorHeader && colorHeader != 'Default' ? 'color: ' + colorHeader: ''">
          <i class="link-icon feather icon-grid"></i>
        </a>
        <div ngbDropdownMenu aria-labelledby="appsDropdown">
          <div class="dropdown-header d-flex align-items-center justify-content-between">
            <p class="mb-0 font-weight-medium">Apps</p>
          </div>
          <div class="dropdown-body">
            <div class="d-flex align-items-center apps">
              <ul class="app-list">
                <li *ngFor="let app of apps">

                  <a (click)="changeApp(app.Id)" class="a-apps">
                    <div class="container-apps">
                      <div class="container-img-apps">
                        <img *ngIf="app.iconApp != null && app.iconApp != ''" src="{{baseImagen + app.iconApp}}"
                          style="width: 50px; height: 50px;">
                        <img *ngIf="app.iconApp == null || app.iconApp == ''" [src]="defaultImagen"
                          style="width: 50px; height: 50px;">
                        <p>{{ app.name }}</p>
                      </div>
                    </div>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </li>
      <li class="nav-item nav-profile" ngbDropdown>
        <a class="nav-link" ngbDropdownToggle id="profileDropdown">
          <img [src]="photoUser" alt="">
        </a>
        <div ngbDropdownMenu aria-labelledby="profileDropdown">
          <div class="dropdown-header d-flex flex-column align-items-center">
            <div class="figure mb-3">
              <img [src]="photoUser" alt="">
            </div>
            <app-user-profile></app-user-profile>
          </div>
          <div class="dropdown-body">
            <ul class="profile-nav p-0 pt-3">
              <li class="nav-item">
                <a (click)="recoveryLink()" class="nav-link">
                  <i class="feather icon-lock"></i>
                  <span>Cambiar contraseña</span>
                </a>
              </li>
              <li class="nav-item">
                <a href="" (click)="checkLogOut($event, LogOutConfirm)" class="nav-link">
                  <i class="feather icon-log-out"></i>
                  <span>Cerrar sesión</span>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </li>
    </ul>
  </div>
</nav>
<ng-template #LogOutConfirm let-modal>
    <div class="modal-body">
      <button class="btn close-btn" (click)="modal.dismiss('Cross click')"><i class="fa fa-times"></i></button>
      <br>
      <hr>
      <div class="modal-icon">
        <i class="exclamation-icon fa fa-exclamation"></i>
      </div>
      <div class="text-muted text-center">
        <h4>¿Está seguro de cerrar la sesión?</h4>
      </div>
      <hr>
      <div class="modal-actions">
        <button class="btn btn-cancel" (click)="modal.dismiss('Cross click')">Cancelar</button>
        <button class="btn btn-accept" (click)="onLogout($event)">Aceptar</button>
      </div>
    </div>
</ng-template>