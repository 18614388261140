import { Component, OnInit, ViewChild, ElementRef, AfterViewInit, Inject, Output, EventEmitter, Input } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Router, NavigationEnd, NavigationExtras } from '@angular/router';
import { SwPush } from '@angular/service-worker';
import MetisMenu from 'metismenujs';

import { LappizMenu } from './menu.model';

import { AppContextService } from '@services/app-context.service';
import { MenuService } from '@services/menu.service';
import { NotificationsService } from '@services/notifications.service';
import { ProxyConfigApiService } from '@services/proxy-config-api.service';
import { ProxyTxApiService } from '@services/proxy-tx-api.service';

import { RootScopeService } from '@shared/root-scope.service';

import backandGlobal from '@env/env';
// import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
// import { AlertContractComponent } from '../alert-contract/alert-contract.component';
// import { AlertPayComponent } from '../alert-pay/alert-pay.component';
import { GlobalEventsService } from '@services/global-events.service';
import { Network } from '@capacitor/network';
import { EnumFontsFamily } from 'src/app/enums/EnumFontsFamily';
import { Constants } from '@common/constants';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit, AfterViewInit {
  @Input() callMenu: any;

  @Output() apps: EventEmitter<any[]>;
  @Output() userPhoto: EventEmitter<any>;
  @Output() appImage: EventEmitter<string>;
  @Output() isMobile: EventEmitter<boolean>;
  @Output() isAdmin: EventEmitter<boolean>;
  @Output() sessionState: EventEmitter<boolean>;
  @Output() layout: EventEmitter<any>;
  @Output() isReady: EventEmitter<boolean>;
  @Output() idleTime: EventEmitter<number>;
  @Output() razonSocial: EventEmitter<string>;

  @ViewChild('sidebarToggler') sidebarToggler: ElementRef;
  @ViewChild('sidebarMenu') sidebarMenu: ElementRef;

  iconApp: string;
  defaultClassIcon: string = 'fa-table';
  additionalWorkspaces: any[];
  currentWorkspaceApp: any;
  photoUser: any;
  imageApp: string = '../../../../assets/images/app.png';
  baseImagen: string = 'data:image/PNG;base64,';
  menuItems = [];
  configLayout: any;
  layoutMobile: boolean = false;
  enableMenuDrag: boolean = false;
  nameApp: string = '';
  userName: string = '';
  loadedApplicationExecuted: boolean = false;

  countdown?: number = null;
  lastPing?: Date = null;

  public readonly KEYS = {
    Test: {
      publicKey:
        'BL7zekUQR_QMB_NQdoe73GAQLGYLuZ3d2IS1zWbTFtdLM9v4Gjw3Fv302Hq1Ot-OX6Hot9bJuPk5jY-plI1SKaE',
      privateKey: 'S1hNkJ7zkM7VPats2ctgCJrtvvhygoOcVwGlkhDMw0I',
    },
    Prod: {
      publicKey:
        'BF9jM5r1iKtP5_JUoOId73k-ImTNJLEbNUDRcqIppnXOalxbSEIbgY5UuDl8iKxkut0YrRH8qyZc5f_QKCgYj5U',
      privateKey: 'yjXrWPkIhh50SFX-AmpDhxdWAy2jk-PlL6jLJrCbJUU',
    },
  };
  public readonly VAPID_PUBLIC_KEY = backandGlobal.environment == 'PROD' ? this.KEYS.Prod.publicKey : this.KEYS.Test.publicKey;

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private router: Router,
    private swPush: SwPush,
    private appContext: AppContextService,
    private menuService: MenuService,
    private notificationsService: NotificationsService,
    private proxyConfigApiService: ProxyConfigApiService,
    private proxyTxApiService: ProxyTxApiService,
    private $rs: RootScopeService,
    private globalEvents: GlobalEventsService,
  ) {

    this.currentWorkspaceApp = {};
    this.additionalWorkspaces = [];
    this.$rs.$rootScope.pages = [];
    this.$rs.$rootScope.additionalWorkspaces = [];
    this.$rs.$rootScope.onLine = false;
    localStorage.setItem('runtimeOnline', this.$rs.$rootScope.onLine);

    router.events.forEach((event) => {
      if (event instanceof NavigationEnd) {
        /**
         * Activating the current active item dropdown
         */
        this._activateMenuDropdown();

        /**
         * closing the sidebar
         */
        if (window.matchMedia('(max-width: 991px)').matches) {
          this.document.body.classList.remove('sidebar-open');
        }
      }
    });

    this.configLayout = {
      colorMenu: 'Default',
      colorFooter: 'Default',
      colorHeader: 'Default',
      backgroundMenu: 'Default',
      backgroundFooter: 'Default',
      backgroundHeader: 'Default',
      web: {
        footerHide: false,
        headerHide: false,
        menuHide: false,
        footer: 'Default',
        header: 'Default',
        menu: 'Default',
        footerTemplate: '',
        headerTemplate: '',
        menuTemplate: '',
      },
      mobile: {
        footerHide: false,
        headerHide: false,
        menuHide: false,
        footer: 'Default',
        header: 'Default',
        menu: 'Default',
        footerTemplate: '',
        headerTemplate: '',
        menuTemplate: '',
      },
    };

    this.apps = new EventEmitter();
    this.userPhoto = new EventEmitter();
    this.appImage = new EventEmitter();
    this.isMobile = new EventEmitter();
    this.isAdmin = new EventEmitter();
    this.sessionState = new EventEmitter();
    this.layout = new EventEmitter();
    this.isReady = new EventEmitter();
    this.idleTime = new EventEmitter();
    this.razonSocial = new EventEmitter();

    this.loadMenu();
    this.subscribeToNotifications();
  }

  ngOnInit() {
    /**
     * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
     */
    // const desktopMedium = window.matchMedia(
    //   '(min-width:992px) and (max-width: 1199px)'
    // );
    // desktopMedium.addListener((e) => {
    //   this.iconSidebar(e, this.configLayout);
    // });
    // this.iconSidebar(desktopMedium, this.configLayout);
    Network.addListener('networkStatusChange', status => {
      console.log('Network status changed', status);
      backandGlobal.stateOnline = status.connected;
      localStorage.setItem('stateOnline', `${status.connected}`);
    });
  }

  ngAfterViewInit() {
    // activate menu item
    new MetisMenu(this.sidebarMenu.nativeElement);

    this._activateMenuDropdown();
  }

  /**
   * Toggle sidebar on hamburger button click
   */
  toggleSidebar(e) {
    if (
      this.configLayout.web.menu == 'HorizontalTop' ||
      this.configLayout.web.menu == 'HorizontalBottom'
    ) {
      if (!this.document.getElementById('sidebar-menu').classList.contains('sidebar-header-horizontal-inactive')) {
        this.document.getElementById('sidebar-menu').classList.add('sidebar-header-horizontal-inactive');
        this.document.getElementById('sidebar-menu').style.background = this.configLayout.backgroundMenu;
        if (this.configLayout.web.menu == 'HorizontalBottom')
          this.document.getElementById('sidebar-menu').classList.add('sidebar-header-horizontal-inactive-bottom');
      }
      else {
        if (this.configLayout.web.menu == 'HorizontalBottom')
          this.document.getElementById('sidebar-menu').classList.remove('sidebar-header-horizontal-inactive-bottom');
        this.document.getElementById('sidebar-menu').classList.remove('sidebar-header-horizontal-inactive');
        this.document.getElementById('sidebar-menu').style.background = 'ffffff00';
      }

      //      $('.sidebar-nav-horizontal').slideToggle(250);
      return;
    }
    $('#sidebar-menu > li > ul').slideToggle(250);
    $('#sidebar-menu > li > ul > li').slideToggle(250);
    if (
      (this.configLayout.web.header == 'InMenu' ||
        this.configLayout.web.headerHide) &&
      $(window).width() > 991
    ) {
      $('.profile').slideToggle(250);
      $('.other-link').slideToggle(250);
    }
    this.sidebarToggler.nativeElement.classList.toggle('active');
    this.sidebarToggler.nativeElement.classList.toggle('not-active');
    if (window.matchMedia('(min-width: 992px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-folded');
    } else if (window.matchMedia('(max-width: 991px)').matches) {
      e.preventDefault();
      this.document.body.classList.toggle('sidebar-open');
    }
  }

  /**
   * Toggle settings-sidebar
   */
  toggleSettingsSidebar(e) {
    e.preventDefault();
    this.document.body.classList.toggle('settings-open');
  }

  /**
   * Open sidebar when hover (in folded folded state)
   */
  operSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')) {
      $('#sidebar-menu > li > ul').slideToggle(250);
      $('#sidebar-menu > li > ul > li').slideToggle(250);
      if (
        this.configLayout.web.header == 'InMenu' ||
        this.configLayout.web.headerHide
      ) {
        document.documentElement.style.setProperty('--height-header',  '65px');
        $('.profile').slideToggle(250);
        $('.other-link').slideToggle(250);
      }
      this.document.body.classList.add('open-sidebar-folded');
    }
  }

  /**
   * Fold sidebar after mouse leave (in folded state)
   */
  closeSidebarFolded() {
    if (this.document.body.classList.contains('sidebar-folded')) {
      $('#sidebar-menu > li > ul').slideToggle(250);
      $('#sidebar-menu > li > ul > li').slideToggle(250);
      if (this.configLayout.web.header == 'InMenu') {
        $('.profile').slideToggle(250);
        $('.other-link').slideToggle(250);
      }
      this.document.body.classList.remove('open-sidebar-folded');
    }
  }

  /**
   * Sidebar-folded on desktop (min-width:992px and max-width: 1199px)
   */
  iconSidebar(e, configLayout = null) {
    if (
      configLayout?.web?.menu == 'HorizontalTop' ||
      configLayout?.web?.headerHide
    )
      return;
    if (e.matches) {
      this.document.body.classList.add('sidebar-folded');
    } else {
      this.document.body.classList.remove('sidebar-folded');
    }
    if (
      this.configLayout.web.header == 'InMenu' ||
      this.configLayout.web.headerHide
    ) {
      document.documentElement.style.setProperty('--height-header',  '65px');
      $('.profile').slideToggle(250);
      $('.other-link').slideToggle(250);
    }
  }

  /**
   * Switching sidebar light/dark
   */
  onSidebarThemeChange(event) {
    this.document.body.classList.remove('sidebar-light', 'sidebar-dark');
    this.document.body.classList.add(event.target.value);
    this.document.body.classList.remove('settings-open');
  }

  /**
   * Returns true or false if given menu item has child or not
   * @param item menuItem
   */
  hasItems(item: LappizMenu) {
    return item.pages !== null ? item.pages.length > 0 : false;
  }

  /**
   * Reset the menus then hilight current active menu item
   */
  _activateMenuDropdown() {
    this.resetMenuItems();
    this.activateMenuItems();
  }

  /**
   * Resets the menus
   */
  resetMenuItems() {
    const links = document.getElementsByClassName('nav-link-ref');

    for (let i = 0; i < links.length; i++) {
      const menuItemEl = links[i];
      menuItemEl.classList.remove('mm-active');
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.remove('mm-active');
        const parent2El = parentEl.parentElement;

        if (parent2El) {
          parent2El.classList.remove('mm-show');
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.remove('mm-active');

          if (parent3El.classList.contains('side-nav-item')) {
            const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

            if (firstAnchor) {
              firstAnchor.classList.remove('mm-active');
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.remove('mm-show');

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.remove('mm-active');
            }
          }
        }
      }
    }
  }

  /**
   * Toggles the menu items
   */
  activateMenuItems() {
    const links = document.getElementsByClassName('nav-link-ref');

    let menuItemEl = null;

    for (let i = 0; i < links.length; i++) {
      if (window.location.hash === links[i]['hash']) {
        menuItemEl = links[i];
        break;
      }
    }

    if (menuItemEl) {
      menuItemEl.classList.add('mm-active');
      const parentEl = menuItemEl.parentElement;

      if (parentEl) {
        parentEl.classList.add('mm-active');

        const parent2El = parentEl.parentElement;
        if (parent2El) {
          parent2El.classList.add('mm-show');
        }

        const parent3El = parent2El.parentElement;
        if (parent3El) {
          parent3El.classList.add('mm-active');

          if (parent3El.classList.contains('side-nav-item')) {
            const firstAnchor = parent3El.querySelector('.side-nav-link-a-ref');

            if (firstAnchor) {
              firstAnchor.classList.add('mm-active');
            }
          }

          const parent4El = parent3El.parentElement;
          if (parent4El) {
            parent4El.classList.add('mm-show');

            const parent5El = parent4El.parentElement;
            if (parent5El) {
              parent5El.classList.add('mm-active');
            }
          }
        }
      }
    }
  }

  goHomeApp() {
    if (
      sessionStorage.getItem('DefaultPath') != 'null' &&
      sessionStorage.getItem('DefaultPath') != undefined
    ) {
      var parts = sessionStorage.getItem('DefaultPath').split('?');
      var variables = parts[1].split('&');

      var objNav = {};
      variables.forEach((value) => {
        var part = value.split('=');
        objNav[part[0]] = part[1];
      });

      var paramsRoute: NavigationExtras = {
        queryParams: objNav,
      };

      this.router.navigateByUrl('/', { skipLocationChange: false }).then(() => {
        this.router.navigate([parts[0]], paramsRoute);
      });
    } else if (this.$rs.$rootScope.timeline) {
      this.router.navigateByUrl('/', { skipLocationChange: false }).then(() => {
        this.router.navigate([`/timeline`]);
      });
    } else {
      this.router.navigateByUrl('/', { skipLocationChange: false }).then(() => {
        this.router.navigate(['/']);
      });
    }
  }

  applyColorApp({ primaryColor = '#757575', secundaryColor = '#616161' }) {
    setTimeout(() => {
      $('body').get(0).style.setProperty('--primary-color', primaryColor);
      $('body').get(0).style.setProperty('--light-primary-color', primaryColor);
      $('body').get(0).style.setProperty('--accent-color', secundaryColor);
      $('body')
        .get(0)
        .style.setProperty('--light-accent-color', secundaryColor);
    }, 1000);
  }

  async loadMenu(workspaceId?: string) {
    try {
      if (!workspaceId) {
        this.loadCurrentApp();
        this.additionalWorkspaces = await this.proxyConfigApiService.getUserApps(`${backandGlobal.url}/api/Account/userApps?$format=json`);
        if (this.additionalWorkspaces == null || (Array.isArray(this.additionalWorkspaces) && this.additionalWorkspaces.length == 0)) {
          this.notificationsService.notificationApp('warning',Constants.MESSAGES.NotActivatedRoles);
          let codeApp = sessionStorage.getItem('workspace');
          sessionStorage.clear();
          sessionStorage.setItem('workspace', codeApp);
          localStorage.clear();
          this.router.navigate([`/auth/login/${codeApp}`],);
        }
        this.$rs.$rootScope.additionalWorkspaces = this.additionalWorkspaces;
        this.emitApps(this.additionalWorkspaces);

        const selectedWorkspaceCode = sessionStorage.getItem('workspace') ?? this.additionalWorkspaces[0].code;
        const selectedWorkspace = this.additionalWorkspaces.find(app => app.code === selectedWorkspaceCode);
        if (selectedWorkspace) {
          sessionStorage.setItem('workspaceId', selectedWorkspace.Id);
          const data = await this.proxyConfigApiService.getConfigApp(selectedWorkspace.Id);
          // await this.proxyConfigApiService.getallentitiesoffline(`${backandGlobal.url}/api/getallentitiesoffline/${selectedWorkspace.Id}`); 
          this.configApp(data);
          // if (backandGlobal.currentApp && backandGlobal.currentApp?.id === targetWorkspaceId) {
          //   const data = { workspace: backandGlobal.currentApp, versioncache:  backandGlobal.currentApp.versioncache};
          //   this.configApp(data);
          // } else {
          //   const data = await this.proxyConfigApiService.getConfigApp(selectedWorkspace.Id);
          //   this.configApp(data);
          // }
        }
      }else if(sessionStorage.workspaceId != workspaceId){
        this.additionalWorkspaces = await this.proxyConfigApiService.getUserApps(`${backandGlobal.url}/api/Account/userApps?$format=json`);
        if (this.additionalWorkspaces == null || (Array.isArray(this.additionalWorkspaces) && this.additionalWorkspaces.length == 0)) {
          this.notificationsService.notificationApp('warning',Constants.MESSAGES.NotActivatedRoles);
          let codeApp = sessionStorage.getItem('workspace');
          sessionStorage.clear();
          sessionStorage.setItem('workspace', codeApp);
          localStorage.clear();
          this.router.navigate([`/auth/login/${codeApp}`],);
        }
        const selectedWorkspace =  this.additionalWorkspaces.find(x => x.Id == workspaceId);
        sessionStorage.workspace = selectedWorkspace.Code;
        this.razonSocial.emit(selectedWorkspace.RazonSocial);
        sessionStorage.setItem('workspaceId', selectedWorkspace.Id);
        const data = await this.proxyConfigApiService.getConfigApp(selectedWorkspace.Id);
        this.configApp(data);
        this.goHomeApp();

      }
    } catch (error: any) {
      if (error.status === 401) {
        this.onlogout();
      }
      this.notificationsService.notificationApp('error', Constants.MESSAGES.FailGeneral);
      this.isReady.emit(true);
    }
  }

  configApp(data: any) {
    var primaryColorApp = data.workspace.colorTheme ? data.workspace.colorTheme : '#757575';
    var secundaryColorApp = data.workspace.secundaryColorTheme ? data.workspace.secundaryColorTheme : '#616161';
    // TODO: Poner servicio compartido para llamar el applyTheme
    this.applyColorApp({ primaryColor: primaryColorApp, secundaryColor: secundaryColorApp });
    if(data.workspace.FontFamilyDefault){
      document.documentElement.style.setProperty('--default-font-family',  EnumFontsFamily[data.workspace.FontFamilyDefault]);
    }
    if(data.workspace.rolId){
      sessionStorage.setItem("rolesId", data.workspace.rolId);
      localStorage.setItem("rolesId", data.workspace.rolId);
    } 
    
    //Layout
    document.getElementById('appsDropdown').classList.remove('dropdown-toggle');
    if (data.workspace.Layout != '' && data.workspace.Layout != null) {
      this.configLayout = data.workspace.Layout;
      this.layout.emit(this.configLayout);
    }

    if (data.workspace.appTitle) {
      document.title = data.workspace.appTitle;
    } else {
      document.title = data.workspace.nameApp;
    }
    this.razonSocial.emit(this.document.title);

    this.nameApp = document.title;
    this.userName = localStorage.userName;

    if (data.workspace.tabIcon) {
      var iconApp = `data:image/PNG;base64,${data.workspace.tabIcon}`;
      $('#customIconApp').attr('href', `${iconApp}`);
    } else {
      var iconApp = '../../../../favicon.ico';
      $('#customIconApp').attr('href', `${iconApp}`);
    }

    if (data.workspace.icono) {
      this.imageApp = `data:image/PNG;base64,${data.workspace.icono}`;
    } else {
      this.imageApp = '../../../../assets/images/app.png';
    }
    this.appImage.emit(this.imageApp);

    $('meta[name="description"]').attr('content', data.workspace.nameApp);

    // Asignamos este código para que las configuraciones cuando la aplicación es multitenant pueda seguir funcionando
    // Siempre se debe usar esta variable cuando de consultar configuraciones se trate
    sessionStorage.configAppName = data.workspace.name;

    if (backandGlobal.CurrentLoginApp) {
      // En caso de haber un CurrentLoginApp (El cual puede ser una app o un tenant)
      // lo asignamos para que el runtime pueda seguir funcionando normalmente
      data.workspace.name = backandGlobal.CurrentLoginApp;
    }
    var mobile =/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    const isMobile = data.workspace.pagesMobile && data.workspace.pagesMobile.length > 0 && mobile === true;
    this.menuItems = isMobile ? data.workspace.pagesMobile : data.workspace.pages;
    this.isMobile.emit(isMobile);
    this.currentWorkspaceApp = data.workspace;
    // backandGlobal.stateOnline = backandGlobal.stateOnline;
    backandGlobal.stateConfig = true;
    backandGlobal.currentApp = data.workspace;
    backandGlobal.urlAPI = data.workspace.UrlOData;
    backandGlobal.videoCall = data.workspace.videoCall;
    backandGlobal.chat = data.workspace.chat;
    data.workspace.versioncache = data.versioncache;
    sessionStorage.setItem('currentApp', JSON.stringify(data.workspace));
    sessionStorage.setItem('versionCache', data.versioncache);
    sessionStorage.setItem('workspaceId', data.workspace.id);
    sessionStorage.setItem('workspace', data.workspace.name);
    sessionStorage.setItem('DefaultPath', data.workspace.DefaultPath);
    sessionStorage.setItem('filterTimeLine', data.workspace.timeLineFilter);
    this.$rs.$rootScope.AppName = data.workspace.name;
    this.$rs.$rootScope.pages = data.workspace.pages;
    this.$rs.$rootScope.timeline = data.workspace.timeLine;
    this.$rs.$rootScope.tabs = data.workspace.tabs;
    this.$rs.$rootScope.HasNotifications = data.workspace.Notifications;
    this.$rs.$rootScope.AllowComments = data.workspace.AllowComments;
    this.$rs.$rootScope.AllowLikes = data.workspace.AllowLikes;
    this.$rs.$rootScope.workspaceIco = '../../../../assets/images/app.png';

    var userEntity = backandGlobal.environment == 'LOCAL' || backandGlobal.environment == 'DEV' || backandGlobal.environment == 'TEST' ? `${data.workspace.name}_Users` : `Lappiz_Users`;

    if (data.workspace.icono != null && data.workspace.icono != undefined && data.workspace.icono != '') {
      var ic = `data:image/PNG;base64,${data.workspace.icono}`;
      this.iconApp = ic;
      this.$rs.$rootScope.workspaceIco = ic;
    }

    // TODO: Cuando solucionen el api de notifications, ponen el getNotifications
    // this.getNotifications();

    // Si hay timeline valido si tiene action, si la hay sobreescribo profileUrl
    this.searchUserData();

    if (data.workspace.timeLine) {
      this.configTimeline(data);
    } else {
      this.configProfileUrl();
      // this.entityService
      //   .getPermisosEdicion(
      //     this.currentWorkspaceApp.id,
      //     userEntity,
      //     sessionStorage.getItem('userId')
      //   )
      //   .then((data: any) => {
      //     this.$rs.$rootScope.profileShowClass = 'ng-show';
      //   });
    }

    if (data.workspace.isIdle) {
      this.enableIdleActivity(data.workspace.timeIdle);
    }

    /**
     * Se comenta momentaneamente para compatibilidad de PouchDB
     */
    //#region Offline
    this.appContext.getAppState().then((context: any) => {
      var stateAppContext = context.stateApp;
      if (stateAppContext === true) {
        this.searchUserData();

        if (data.workspace.timeLine) {
          this.configTimeline(data);
        } else {
          this.configProfileUrl();
          // this.entityService.getPermisosEdicion(this.currentWorkspaceApp.id, userEntity, sessionStorage.getItem('userId')).then((data: any) => {
          //   this.$rs.$rootScope.profileShowClass = 'ng-show';
          // });
        }
      } else {
        this.searchUserOfflineData();
      }
    }).catch((error: any) => {
      console.log(`Ha ocurrido un error consultando el estado de la aplicación`);
    });
    //#endregion

    // this.getStatusContract();
    // this.getAdminProfiles(localStorage.userName);
    this.applicationLoaded(data.workspace.globalEvents);
    this.isReady.emit(true);
    this.redirectApp();
  }

  // async getStatusContract() {
  //   await this.userService
  //     .getStatusContract(sessionStorage.aplicattionuserid)
  //     .then(async (data: any) => {
  //       if (data) this.getStatusPay(sessionStorage.aplicattionuserid);
  //       else {
  //         const modalRef = this.modalService.open(AlertContractComponent, {
  //           animation: true,
  //           size: 'lg',
  //           keyboard: false,
  //           backdrop: false,
  //           centered: true,
  //         });
  //       }
  //     })
  //     .catch((error: any) => {
  //       var message = `Ha ocurrido un error, por favor contacta a tu administrador`;
  //       this.notificationsService.notificationApp('error',message);
  //       this.onlogout();
  //       // console.log(error);
  //     });
  // }

  // async getStatusPay(param: string) {
  //   await this.userService['getStatusPay'](param)
  //     .then(async (data: any) => {
  //       if (!data) {
  //         const modalRef = this.modalService.open(AlertPayComponent, {
  //           animation: true,
  //           size: 'lg',
  //           keyboard: false,
  //           backdrop: false,
  //           centered: true,
  //         });
  //       }
  //     })
  //     .catch((error: any) => {
  //       if (error.status == 401)
  //         this.onlogout();
  //       var message = `Ha ocurrido un error, por favor contacta a tu administrador`;
  //       this.notificationsService.notificationApp('error',message);
  //       // console.log(error);
  //     });
  // }

  // async getAdminProfiles(email: string) {
  //   // this.isAdmin.emit(true);
  //   await this.userService
  //     .getAdminProfiles(email)
  //     .then(async (data: any) => {
  //       this.isAdmin.emit(data);
  //     })
  //     .catch((error: any) => {
  //       this.isAdmin.emit(true);
  //       if (error.status == 401)
  //         this.onlogout();
  //       var message = `Ha ocurrido un error, por favor contacta a tu administrador`;
  //       this.notificationsService.notificationApp('error',message);
  //     });
  // }

  private loadCurrentApp() {
    const currentAppJson = sessionStorage.getItem('currentApp');
    if (currentAppJson) {
      backandGlobal.currentApp = JSON.parse(currentAppJson);
    }
  }

  private emitApps(apps: any[]) {
    apps.forEach((app: any) => this.apps.emit(app));
  }


  redirectApp() {
    if (sessionStorage.getItem('DefaultPath') != 'null' && sessionStorage.getItem('DefaultPath') != undefined) {
      var parts = sessionStorage.getItem('DefaultPath').split('?');
      var variables = parts[1].split('&');

      var objNav = {};
      variables.forEach((value) => {
        var part = value.split('=');
        objNav[part[0]] = part[1];
      });

      var paramsRoute: NavigationExtras = {
        queryParams: objNav,
      };

      this.router.navigate([parts[0]], paramsRoute);
    } else if (this.$rs.$rootScope.timeline) {
      this.router.navigate([`/timeline`]);
    } else {
      this.router.navigate(['/']);
    }
  }

  getNotifications() {
    this.notificationsService.createSocketDesigner();

    // TODO: Ajustar el socket para la aplicación para notificaciones internas
    this.notificationsService.createSocket(function (newNotification: any) {
      // Llamar método newNotification del navbar
    });

    // TODO: Ajustar para cargar las notificaciones del usuario logueado
    var urlTx = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/notifications/user/${sessionStorage.userId}/${sessionStorage.rolesId}`;
    this.proxyTxApiService.dataGetTx(urlTx, backandGlobal.stateOnline).then((notifications: any) => {
      // Llamar método notificationsLoaded del navbar
    }).catch((error: any) => {
      //console.log(error)
    });
  }

  async searchUserData() {
    var urlUserData = '';
    var fieldUserData = '';
    var userEntity = '';

    if (
      backandGlobal.environment == 'DEV' ||
      backandGlobal.environment == 'TEST'
    ) {
      urlUserData = `${backandGlobal.api2}/${sessionStorage.getItem(
        'workspace'
      )}.api/api/lappiz/get/${this.$rs.$rootScope.AppName}_Users`;
      fieldUserData = `[${this.$rs.$rootScope.AppName}_Users].[Id]`;
      userEntity = `${this.$rs.$rootScope.AppName}_Users`;
    } else if (backandGlobal.environment == 'PROD') {
      urlUserData = `${backandGlobal.api2}/${sessionStorage.getItem(
        'workspace'
      )}.api/api/lappiz/get/Lappiz_Users`;
      fieldUserData = `[Lappiz_Users].[Id]`;
      userEntity = `Lappiz_Users`;
    }

    var opc = {
      take: 10,
      skip: 0,
      page: 1,
      pageSize: 10,
      filter: {
        logic: 'or',
        filters: [{ field: fieldUserData, operator: 'eq', value: sessionStorage.getItem('userId'), },],
      },
      tenantId: sessionStorage.getItem('tenantId'),
      parameters: { userId: sessionStorage.getItem('userId'), pType: 'showinmenu', aType: 'ffija', environment: backandGlobal.environment, },
    };

    await this.proxyTxApiService.getTxData(
      `${urlUserData}`,
      JSON.stringify(opc),
      backandGlobal.stateOnline,
      userEntity
    ).then((response: any) => {
      var user: any;
      if (backandGlobal.stateOnline) {
        user = response.data.rows[0];
      } else {
        user = response.rows[0];
      }

      if (user) {
        user.Contrasena = ''; // Se limpia por seguridad
        sessionStorage.setItem('LappizUser', JSON.stringify(user));

        /**
         * Se comenta momentaneamente para compatibilidad de PouchDB
         */
        //#region Offline
        var docUserData = {
          _id: 'userData',
          userData: user
        };

        // this.appContext.insertUserData(docUserData);
        //#endregion

        var filesPhotos: string = user.Photo;
        if (filesPhotos != undefined) {
          var arrFilesPhotos = filesPhotos.split(',');
          var photoFile: string = arrFilesPhotos[arrFilesPhotos.length - 1];
          var photo =
            backandGlobal.URL_API_UPLOAD_IMAGES +
            photoFile
              .replace('"', '')
              .replace('"', '')
              .replace('[', '')
              .replace(']', '');
          this.photoUser = photo;
        } else {
          this.photoUser = '../../../../assets/images/default-user.png';
        }

        this.userPhoto.emit(this.photoUser);
      } else {
        sessionStorage.setItem('LappizUser', null);
        var message = `El usuario logueado no se encuentra registrado en ${userEntity}, tal vez algunas cosas no funcionen bien, contacta con tu administrador.`;
        this.notificationsService.notificationApp('warning',message);
      }
    });
  }

  enableIdleActivity(timeIdle: number) {
    this.idleTime.emit(timeIdle * 60);
  }

  /**
   * Se comenta momentaneamente para compatibilidad de PouchDB
   */
  //#region Offline
  async searchUserOfflineData() {
    await this.appContext.getUserData().then((context: any) => {
      var userData = context.userData;
      sessionStorage.setItem('LappizUser', JSON.stringify(userData));
    }).catch((error: any) => {
      console.log(`Ha ocurrido un error consultando los datos del usuario logueado`);
    })
  }
  //#endregion

  configTimeline(data: any) {
    if (
      data.workspace.profileAction != null &&
      data.workspace.profileAction != undefined
    ) {
      this.$rs.$rootScope.profileUrl = {};
      var queryString = data.workspace.profileAction.split('?')[1].split('&');
      for (let i = 0; i < queryString.length; i++) {
        var part = queryString[i].split('=');
        this.$rs.$rootScope.profileUrl[part[0]] = part[1];

        if (part[0] == 'rowId') {
          this.$rs.$rootScope.profileFieldSearch = part[1].replace(
            'selectedItem.',
            ''
          );
        }

        if (part[0] == 'appViewId') {
          this.$rs.$rootScope.profileAppViewId = part[1];
        }
      }
    } else {
      this.configProfileUrl();
    }
    this.router.navigate(['/timeline']);
  }

  configProfileUrl() {
    // var userEntity = '';
    // if (backandGlobal.environment == 'DEV' || backandGlobal.environment == 'TEST') {
    //   userEntity = `${this.$rs.$rootScope.AppName}_Users`;
    // } else if (backandGlobal.environment == 'PROD') {
    //   userEntity = `Lappiz_Users`;
    // }

    // this.entityService
    //   .getEntityIdByCodeAndAppId(`${userEntity}`, this.currentWorkspaceApp.id)
    //   .then((data: any) => {
    //     this.$rs.$rootScope.profileUrl = {
    //       rowId: sessionStorage.getItem('userId'),
    //       viewName: `${userEntity}`,
    //       entityId: data,
    //     };
    //   })
    //   .catch((error: any) => {
    //     // console.log(error);
    //   });
  }

  setCurrentMenuSelection(current: any, parent: any, $event) {
    $event.stopPropagation();
    this.enableMenuDrag = ((this.configLayout.web.menu == 'HorizontalTop' || this.configLayout.web.menu == 'HorizontalBottom') && !this.enableMenuDrag) ? true : this.enableMenuDrag;
    let $li = $($event.currentTarget).parent();
    let sw = true;
    let className = ($('ul:first', $event.currentTarget).hasClass('nav-third-level') && current.isModule) ? 'd-show-third' : 'd-show';

    if ($li.is('.mm-active'))
      $li.removeClass('mm-active');
    else
      $li.addClass('mm-active');

    $('ul:first', $event.currentTarget).slideToggle(250);
    if ($('ul:first', $event.currentTarget).hasClass(className)) {
      $('ul:first', $event.currentTarget).removeClass(className);
      sw = false;
    }
    $(`.${className}`).slideToggle(250);
    $(`.${className}`).removeClass(className);
    if (sw)
      $('ul:first', $event.currentTarget).addClass(className);
    if (parent && current.isModule) {
      if ($(`#${current.entityId} > ul`).is(':visible'))
        $(`#${current.entityId} > ul`).hide(250);
      else
        $(`#${current.entityId} > ul`).show(250);
    }

    this.menuService.setCurrentMenu(current);
  }

  subscribeToNotifications() {
    this.swPush.requestSubscription({ serverPublicKey: this.VAPID_PUBLIC_KEY }).then((sub) => {
      this.proxyConfigApiService.addPushSubscriber(sub, sessionStorage.userId, `${backandGlobal.url}/api/notification/allowNotification`);
    }).catch((err) =>
      console.error('Could not subscribe to notifications', err)
    );
  }

  onlogout() {
    this.sessionState.emit(true);
  }

  recoveryLink() {
    var params: NavigationExtras = {
      queryParams: {
        userId: sessionStorage.getItem('userId'),
      },
    };

    this.router.navigate(['/recovery'], params);
  }

  designer() {
    window.open(`${backandGlobal.designer}/Tablas`, '_blank');
  }

  viewActions() {
    if (
      this.configLayout.web.headerHide ||
      (this.configLayout.web.header == 'InMenu' &&
        (this.configLayout.web.menu == 'HorizontalTop' ||
          this.configLayout.web.menu == 'HorizontalBottom'))
    )
      if (!$('.action-users-header-hidden').attr('hidden'))
        $('.action-users-header-hidden').attr('hidden', 'hidden');
      else $('.action-users-header-hidden').removeAttr('hidden');
  }
  /**
   * Método encargado para ejecutar evento applicationLoaded
   */
  applicationLoaded(config: any) {
    if (this.loadedApplicationExecuted) return;
    this.globalEvents.fireEvents('ApplicationLoaded', config, config);
    this.loadedApplicationExecuted = true;
  }

}
