import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FormsComponent } from '@components/forms/forms.component';

declare var kendo: any;

@Component({
  selector: 'app-qr',
  templateUrl: './qr.component.html',
  styleUrls: ['./qr.component.scss'],
})
export class QrComponent implements OnInit {
  @Input() errors: any;
  @Input() field: any;
  @Input() showQr: boolean;
  @Input() value: any;

  errorGeneral: string;
  formSubmitted: any = false;
  innerForm: UntypedFormGroup;
  options: any[];
  typeInput: string;
  textQr: string;

  constructor(
    private modalService: NgbModal,
    private formsComponent: FormsComponent
  ) {
    this.innerForm = new UntypedFormGroup({
      field: new UntypedFormControl(''),
      typeQR: new UntypedFormControl(),
    });
    this.typeInput = 'text';
    this.errorGeneral = null;
  }

  ngOnInit(): void {
    if (this.value.val.includes('|')) {
      [this.typeInput, this.textQr] = this.value.val.split('|');
    } else if (this.value.val && this.value.type) {
      this.typeInput = this.value.type;
      this.textQr = this.value.val;
    }

    this.innerForm.controls.field.setValidators([
      Validators.maxLength(this.field.maximumValue),
    ]);
    this.formsComponent.checkForm(this.field.campoId, this.innerForm.invalid);
  }

  async open(content: any) {
    const TYPE = {
      email: (textValue: string) => {
        return 'mailto:' + textValue;
      },
      url: (textValue: string) => {
        return textValue;
      },
      text: (textValue: string) => {
        return textValue;
      },
      number: (textValue: string) => {
        return 'tel:' + textValue;
      },
    };
    this.modalService.open(content);
    setTimeout(() => {
      $('#qrImage').ready(() => {
        kendo.jQuery('#qrImage').kendoQRCode({
          value: TYPE[this.innerForm.controls.typeQR.value](
            this.innerForm.controls.field.value
          ),
          errorCorrection: 'M',
          size: 250,
          border: {
            color: '#000000',
            width: 5,
          },
        });
      });
    }, 1000);
  }

  change() {
    if (this.value.val.includes('|')) {
      [this.typeInput, this.textQr] = this.value.val.split('|');
      this.value.type = this.typeInput;
      this.value.text = this.textQr;
    }
    this.onBlur();
  }

  onBlur() {
    this.errorGeneral = null;
    if (this.typeInput == 'email') {
      if (
        !this.innerForm.controls.field.errors &&
        this.innerForm.controls.field.value.includes('@')
      ) {
        if (
          this.innerForm.controls.field.value.split('@')[1].includes('.') &&
          this.innerForm.controls.field.value.split('@')[1].length > 1
        ) {
          let splitDomain = this.innerForm.controls.field.value
            .split('@')[1]
            .split('.');
          if (splitDomain[splitDomain.length - 1].length < 2)
            this.errorGeneral = 'Error en el formato. aa@bb.cc';
        } else this.errorGeneral = 'Error en el formato. aa@bb.cc';
      } else this.errorGeneral = 'Error en el formato. aa@bb.cc';
    } else if (this.typeInput == 'url') {
      if (
        !this.innerForm.controls.field.value.includes('http://') &&
        !this.innerForm.controls.field.value.includes('https://')
      )
        this.errorGeneral = 'Error en el formato. http:// | https://';
    }
    this.ngOnChanges(null);
  }

  changeType() {
    this.innerForm.controls.field.clearValidators();
    switch (this.innerForm.controls.typeQR.value) {
      case 'email':
        this.typeInput = 'email';
        this.innerForm.controls.field.setValidators([Validators.email]);
        break;
      case 'url':
        this.typeInput = 'url';
        this.innerForm.controls.field.setValidators([
          Validators.pattern(
            '(https?://)?([\\da-z.-]+)\\.([a-z.]{2,6})[/\\w .-]*/?'
          ),
        ]);
        break;
      case 'number':
        this.typeInput = 'number';
        this.innerForm.controls.field.setValidators([
          Validators.pattern('-?[0-9]+(.[0-9][0-9]?)?'),
        ]);
        break;
      default:
        this.typeInput = 'text';
        break;
    }
    this.innerForm.controls.field.setValidators([
      Validators.maxLength(this.field.maximumValue),
    ]);
    this.onBlur();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.innerForm) {
      if (this.value.val.includes('|')) {
        this.value.val = this.innerForm.controls.field.value;
        this.field.value.val = this.value.val;
        this.value.type = this.typeInput;
      } else if (
        this.innerForm.controls.typeQR.value &&
        this.innerForm.controls.field.value
      ) {
        this.value.val = `${this.innerForm.controls.typeQR.value}|${this.innerForm.controls.field.value}`;
        this.field.value.val = this.value.val;
        this.value.type = this.typeInput;
      }
    }
    this.formsComponent.setValueFieldForm(this.field.campoId, this.field);
    this.formsComponent.applyUIRules(this.field, this.value);
    var exec = 0;

    if (exec == 0) {
      exec = 1;
      this.formsComponent.actionFormula(this.field, this.value.val);
      this.formsComponent.fireEvents('ValueChanged', this.field.events, {
        value: this.value.val,
        field: this.field,
      });
    }

    this.formsComponent.checkForm(
      this.field.campoId,
      this.value.val ? false : this.innerForm.invalid
    );
  }

}
