import { Injectable } from '@angular/core';
import { ISortTX } from '../interfaces/ISortTX';
import { IFilterTX } from '../interfaces/IFilterTX';
import backandGlobal from '@env/env';
import { IValueFilter } from '../interfaces/IValueFilter';

@Injectable({
  providedIn: 'root'
})
export class OdataFilterMapService {

  arrayAux: Array<string>;
  generalFilter: IFilterTX;
  queryFilterOr: IFilterTX;
  queryFilterAnd: IFilterTX;
  constructor() {
    this.init();
  }

  private init(){
    this.generalFilter = { logic: 'or', filters: [] };
    this.queryFilterOr = { logic: 'or', filters: [] };
    this.queryFilterAnd = { logic: 'and', filters: [] };
    this.arrayAux = [];
  }
  extractOptions(url: string): any {
    let opts: any = {};
    let parths = url.split('?');
    if (parths.length <= 1) return { url };
    let options = parths[1].split('$');
    url = `${parths[0]}`;
    for (let i in options) {
      let opt = options[i];
      if (!opt) continue;
      if (opt.startsWith('filter=')) {
        opts.filterQuery = opt.split('=')[1]
          .split('&')[0]
          .split('%27').join('\'')
          .split('%20')
          .join(' ');
      } else if (opt.startsWith('top=')) {
        opts.top = opt.split('=')[1].split('&')[0];
      } else if (opt.startsWith('inlinecount=allpages')) {
        // Implementar en caso de necesidad
        // se remueve de la url para que al momento de filtrar en el grid no ponga problema
      } else {
        url += `$${opt}`;
      }
    }
    if (url.substring(url.length - 1, url.length) == '&') {
      url = url.substring(0, url.length - 1);
    }
    opts.url = url;
    return opts;
  }

  getOrderBy(query: string): Array<ISortTX> {
    const dataSortResult: Array<ISortTX> = [];
    if (query.includes('$orderby=')) {
      var dataSort = query.split('$orderby=')[1].split(' ');
      for (let i = 0; i < dataSort.length; i++) {
        if (i % 2 == 0)
          dataSortResult.push({ field: dataSort[i], dir: dataSort[i + 1] })
        i++
      }
    } else {
      return null;
    }
    return dataSortResult;
  }

  getFilterOptions(filterQuery: string): IFilterTX {
    this.init();
    this.processODataFilterOptions(filterQuery);
    this.buildFilter();
    return this.initOptions()
  }

  private initOptions(): IFilterTX {
    return {
      logic: 'and',
      filters: [
        {
          logic: 'and',
          filters: [
            this.queryFilterAnd,
            this.queryFilterOr,
          ],
        },
        this.generalFilter,
      ],
    };
  }

  private processODataFilterOptions(filterQuery: string) {
    if (filterQuery) {
      var filterArray = filterQuery.split(' ');
      filterArray.forEach((value: any) => {
        var aux = value;
        if (value.includes('*+*')) {
          aux = value.split('*+*').join(' ');
        }
        aux = aux.replaceAll("'", '');
        this.arrayAux.push(aux);
      });
    }
  }
  private getItem(bandera: number): IValueFilter {
    return {
      field: this.arrayAux[bandera],
      operator: this.arrayAux[bandera + 1],
      value: !this.arrayAux[bandera + 2].includes("'") && this.arrayAux[bandera + 2].toLowerCase() == 'null' ? null : this.arrayAux[bandera + 2],
    };
  }
  private buildFilter() {
    var initialLogic = this.arrayAux.length > 4 ? this.arrayAux[3] : 'and';
    for (let i = 0; i < this.arrayAux.length; i++) {
      let first = i == 0;
      if ((i == 0 && initialLogic == 'and') || this.arrayAux[i] == 'and') {
        var bandera = !first ? i + 1 : 0;
        let newitem: IValueFilter = this.getItem(bandera);
        this.queryFilterAnd.filters.push(newitem);
      } else if ((i == 0 && initialLogic == 'or') || this.arrayAux[i] == 'or') {
        var bandera = !first ? i + 1 : 0;
        let newitem: IValueFilter = this.getItem(bandera);
        this.queryFilterOr.filters.push(newitem);
      }
    }
  }

  getIncludeEntities(filterQuery: string, viewColumns: Array<string>, viewName: string): Array<string> {
    const entityInclude: Array<string> = [];
    const expanded_entities = filterQuery ? filterQuery.split(' ') : [];
    expanded_entities.forEach((value: string) => {
      if ((value.includes('.') || value.includes('Details')) && !value.includes(viewName) && value.includes(sessionStorage.workspace)) {
        const arrayField = value.substr(0, value.lastIndexOf('.'));
        entityInclude.push(arrayField);
      }
    });
    viewColumns.forEach((value: any) => {
      if (!value.command) {
        if (value.field) {
          if (value.field.includes(viewName)) {
          } else {
            if (value.field.includes('.')) {
              value.field.split('Details').join('.');
              var arrayField = value.field.substr(0, value.field.lastIndexOf('.'));
              entityInclude.push(arrayField);
            }
          }
        }
      }
    });
    return entityInclude;
  }

  private uncompressIncludeEntities(entities: any[]) {
    var arr = entities;
    let arr2 = [...arr];

    for (let i = 0; i < arr.length; i++) {
      for (let j = 0; j < arr.length; j++) {
        if (i != j) {
          var strOr = arr[i];
          var strDes = arr[j];

          if (strDes.startsWith(strOr)) {
            delete arr2[i];
            break;
          }
        }
      }
    }

    var filtered = arr2.filter((el: any) => {
      return el != null;
    });

    let unique = [...new Set(filtered)];
    return unique;
  }

  getIncludes(entities: Array<string>): string {
    var aEntities = this.uncompressIncludeEntities(entities);
    var stringInclude = '';
    var cierre = '}]';

    for (let j = 0; j < aEntities.length; j++) {
      if (j != 0) stringInclude += ', ';
      if (!aEntities[j].includes('.')) {
        var auxInclude = '';

        if (
          aEntities[j].includes(
            `${sessionStorage.getItem('workspace')}_Users`
          )
        ) {
          if (
            backandGlobal.environment == 'DEV' ||
            backandGlobal.environment == 'TEST'
          ) {
            auxInclude = aEntities[j];
          } else if (backandGlobal.environment == 'PROD') {
            auxInclude = 'Lappiz_Users';
          }
        } else {
          auxInclude = aEntities[j];
        }

        stringInclude += `{"model": modelo.${auxInclude}, "as": "${auxInclude}"`;
      } else {
        var auxEntityArray = aEntities[j].split('.');

        for (let i = 0; i < auxEntityArray.length; i++) {
          var auxInclude = '';

          if (
            auxEntityArray[i].includes(
              `${sessionStorage.getItem('workspace')}_Users`
            )
          ) {
            if (
              backandGlobal.environment == 'DEV' ||
              backandGlobal.environment == 'TEST'
            ) {
              auxInclude = auxEntityArray[i];
            } else if (backandGlobal.environment == 'PROD') {
              auxInclude = 'Lappiz_Users';
            }
          } else {
            auxInclude = auxEntityArray[i];
          }

          if (i == 0) {
            stringInclude += `{"model": modelo.${auxInclude}, "as": "${auxInclude}"`;
          } else if (i == auxEntityArray.length - 1) {
            stringInclude += `, include: [{"model": modelo.${auxInclude}, "as": "${auxInclude}"`;
            stringInclude += cierre.repeat(i);
          } else {
            stringInclude += `, include: [{"model": modelo.${auxInclude}, "as": "${auxInclude}"`;
          }
        }
      }

      stringInclude += `}`;
    }

    return '[' + stringInclude + ']';
  }
}
