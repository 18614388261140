import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

import { LoaderService } from '@services/loader.service';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService,
    private loaderService: LoaderService
  ) {

    this.loaderService.isLoading.subscribe((load) => {
      if (load === true) {
        this.spinner.show('loader');
      } else {
        this.spinner.hide('loader');
      }
    });

  }

  ngOnInit(): void { }

}
