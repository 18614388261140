import { Injectable, Type } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class TypeService {

  public isString(value: unknown): boolean {
    return TypeService.isString(value)
  }
  
  public isNumber(value: unknown): boolean {
    return TypeService.isNumber(value)
  }
  
  public isBoolean(value: unknown): boolean {
    return TypeService.isBoolean(value)
  }
  
  public isObject(value: unknown): boolean {
    return TypeService.isObject(value)
  }
  
  public isFunction(value: unknown): boolean {
    return TypeService.isFunction(value)
  }
  
  public isAsyncFunction(value: unknown): boolean {
    return TypeService.isAsyncFunction(value)
  }
  
  public isUndefined(value: unknown): boolean {
    return TypeService.isUndefined(value)
  }
  
  public isNull(value: unknown): boolean {
    return TypeService.isNull(value)
  }
  
  public isEmptyString(value: unknown): boolean {
    return TypeService.isEmptyString(value)
  }
  
  public isNullOrEmptyString(value: unknown): boolean {
    return TypeService.isNullOrEmptyString(value)
  }
  
  public isNullOrUndefined(value: unknown): boolean {
    return TypeService.isNullOrUndefined(value)
  }
  
  public isArray(value: unknown): boolean {
    return TypeService.isArray(value)
  }
  
  public isArrayOfString(value: unknown): boolean {
    return TypeService.isArrayOfString(value)
  }
  
  public isArrayOfBoolean(value: unknown): boolean {
    return TypeService.isArrayOfBoolean(value)
  }
  
  public isArrayOfNumber(value: unknown): boolean {
    return TypeService.isArrayOfNumber(value)
  }
  
  public isArrayOfPassedObject(array: object[], propKeys: string[]): boolean {
    return TypeService.isArrayOfPassedObject(array, propKeys)
  }

  public static isString(value: unknown): boolean {
    return typeof value === 'string';
  }

  public static isNumber(value: unknown): boolean {
    return typeof value === 'number' && !isNaN(value);
  }

  public static isBoolean(value: unknown): boolean {
    return typeof value === 'boolean';
  }

  public static isObject(value: unknown): boolean {
    return (
      typeof value === 'object' &&
      !TypeService.isNull(value) &&
      !TypeService.isArray(value)
    );
  }

  public static isFunction(value: unknown): boolean {
    return typeof value === 'function';
  }

  public static isAsyncFunction(value: unknown): boolean {
    return value.constructor.name === 'AsyncFunction';
  }

  public static isUndefined(value: unknown): boolean {
    return typeof value === 'undefined';
  }

  public static isNull(value: unknown): boolean {
    return value === null;
  }

  public static isEmptyString(value: unknown): boolean {
    return value === '';
  }

  public static isNullOrEmptyString(value: unknown): boolean {
    return TypeService.isNull(value) || TypeService.isEmptyString(value);
  }

  public static isNullOrUndefined(value: unknown): boolean {
    return TypeService.isNull(value) || TypeService.isUndefined(value);
  }

  public static isArray(value: unknown): boolean {
    return Array.isArray(value);
  }

  public static isArrayOfString(value: unknown): boolean {
    return (
      TypeService.isArray(value) &&
      (value as string[]).every((item: unknown) => TypeService.isString(item))
    );
  }

  public static isArrayOfBoolean(value: unknown): boolean {
    return (
      TypeService.isArray(value) &&
      (value as boolean[]).every((item: unknown) => TypeService.isBoolean(item))
    );
  }

  public static isArrayOfNumber(value: unknown): boolean {
    return (
      TypeService.isArray(value) &&
      (value as number[]).every((item: unknown) => TypeService.isNumber(item))
    );
  }

  public static isArrayOfPassedObject(array: object[], propKeys: string[]): boolean {
    const validArray: boolean = TypeService.isArray(array);
    const validObjects: boolean = TypeService.allElementsAreObjects(array);
    const allObjectsContainsProps: boolean = TypeService.allObjectsContainsProps(array, propKeys);
    return [validArray, validObjects, allObjectsContainsProps].every(Boolean);
  }

  private static allElementsAreObjects(array: object[]): boolean {
    return array.every((currentObject) => TypeService.isObject(currentObject));
  }

  private static allObjectsContainsProps(array: object[], propKeys: string[]): boolean {
    return array.every((currentObject: any) =>
      propKeys.every((prop) => currentObject.hasOwnProperty(prop))
    );
  }
}
