import { Component, OnInit, Input, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { FormsComponent } from '@components/forms/forms.component';

import { VoiceRercognitionService } from '@services/voice-rercognition.service';

@Component({
  selector: 'app-email',
  templateUrl: './email.component.html',
  styleUrls: ['./email.component.scss']
})
export class EmailComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() field: any;
  @Input() value: any;
  @Input() form: any;
  @Input() inputClass: string;
  @Input() errors: any;

  formSubmitted = false;

  innerForm = new UntypedFormGroup({
    field: new UntypedFormControl(null, [
      Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) 
    ]),
  });

  withTitleVisible: boolean;
  withAnimationOptionsVisible: boolean;
  withShadingOptionsVisible: boolean;

  microphoneActive: boolean;
  classMicrophone: string;

  constructor(
    private formsComponent: FormsComponent,
    private voiceRecognitionService: VoiceRercognitionService
  ) { 
    this.classMicrophone ="fa fa-solid fa-microphone";
    this.microphoneActive = false;    
  }

  ngOnInit(): void {
    if (this.field.required == true) {
      this.innerForm = new UntypedFormGroup({
        field: new UntypedFormControl(this.field.value.val, [
          Validators.required,
          Validators.minLength(this.field.minimumValue),
          Validators.maxLength(this.field.maximumValue),
          Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) 
        ])
      });
    }

    if (!this.value) {
      this.value = this.field.defaultValue;
    }

    this.formsComponent.checkForm(this.field.campoId, this.innerForm.invalid);
    if (this.field.IsSpeech)
      this.voiceRecognitionService.serviceAvailable.subscribe();
  }

  ngAfterViewInit() {
    if (this.field.required == false) {
      this.innerForm = new UntypedFormGroup({
        field: new UntypedFormControl(this.field.value.val, [
          Validators.minLength(this.field.minimumValue),
          Validators.maxLength(this.field.maximumValue),
          Validators.pattern(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/) 
        ])
      });
    }
  }

  keypress(e) {

  }

  change() {
    this.formsComponent.setValueFieldForm(this.field.campoId, this.field);
    this.innerForm.setValue({field: this.field.value.val})
    setTimeout(() => {
      this.formsComponent.checkForm(this.field.campoId, this.innerForm.invalid);
    }, 100);
    this.formsComponent.actionFormula(this.field, this.value.val);
    this.formsComponent.fireEvents('ValueChanged', this.field.events, { 
      value: this.value.val, 
      field: this.field
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.formsComponent.setValueFieldForm(this.field.campoId, this.field);
    this.formsComponent.checkForm(this.field.campoId, this.innerForm.invalid);
    this.formsComponent.actionFormula(this.field, this.value.val);
    // this.formsComponent.fireEvents('ValueChanged', this.field.events, { 
    //   value: this.value.val, 
    //   field: this.field
    // });
  }

  onMicrophoneClick($event:any) {
    if (this.voiceRecognitionService.init('es-CO', this.field.campoId)){
      if (!this.microphoneActive){
        this.classMicrophone ="fa fa-solid fa-microphone microphone-active";
        this.voiceRecognitionService.start(this.field.campoId)
      }
      else {
        this.classMicrophone ="fa fa-solid fa-microphone";
        this.value.val = this.voiceRecognitionService.stop(this.field.campoId);
        this.formsComponent.actionFormula(this.field, this.value.val);
        this.formsComponent.fireEvents("ValueChanged", this.field.events, { value: this.value.val, field: this.field });
        this.formsComponent.checkForm(this.field.campoId, this.innerForm.invalid);
      }
      this.microphoneActive = !this.microphoneActive;
    }
  }

}
