import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OfflineWorkerService {

  syncWorker = function (event) {
    // @ts-ignore
    importScripts('https://cdn.jsdelivr.net/npm/pouchdb@7.2.1/dist/pouchdb.min.js');
    this.addEventListener('message', function (e) {
      var backandGlobal = JSON.parse(e.data.functionGlobal);
      let entityNames = JSON.parse(e.data.entityNamesChanged);
      let token = e.data.Token;
      let dbAttachments = new PouchDB('bd_AllAttachments');
      entityNames = entityNames.map(x => x = { 'entityName': x, 'stateSync': 'done' });
      const dbEntities = [];
      const changesEntities = [];
      const entitiesResolved = [];
      const ajaxSync = (url, transaction, params, callback, entity = null) => {
        var req = new XMLHttpRequest();
        req.open(transaction, url, false);
        req.setRequestHeader('Content-Type', 'application/json');
        req.setRequestHeader('Authorization', token);
        req.addEventListener('load', () => {
          // Llamada a la función callback pasándole la respuesta
          callback(req.responseText);
        });
        req.addEventListener('error', () => {
          console.error("Error de red");
          if (entity)
            entity.stateSync = 'pending';
        });
        req.send(params);
      }
      const fetchUploadFile = (formdata, callback) => {
        var requestOptions = {
          method: 'POST',
          body: formdata
        };

        fetch(`${backandGlobal.url}/api/Upload/OfflineUpload`, requestOptions)
          .then(response => response.text())
          .then(result => callback(result))
          .catch(error => callback(error));
      }
      const deleteSynchronizedEntity = (entitySyncronized) => {
        entitiesResolved.push(entitySyncronized.entityName);
        // if(entitySyncronized.stateSync == 'done'){
        //   var entityNamesAux = JSON.parse(localStorage.getItem("EntityNamesChanged"));
        //   entityNamesAux = entityNamesAux.filter(x => x != entitySyncronized.entityName);
        //   localStorage.setItem("EntityNamesChanged", JSON.stringify(entityNamesAux));
        // }
      }
      const dataURItoBlob = (dataURI: any) => {
        var byteString = atob(dataURI.split(',')[1]);
        var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
        var ab = new ArrayBuffer(byteString.length);
        var ia = new Uint8Array(ab);

        for (var i = 0; i < byteString.length; i++) {
          ia[i] = byteString.charCodeAt(i);
        }

        var bb = new Blob([ab], { type: mimeString });

        return bb;
      }
      const syncAttachments = (): Promise<boolean> => {
        return new Promise((resolve) => {
          dbAttachments.info().then((info) => {
            let attachmentChanged = dbAttachments.changes({
              since: 0,
              include_docs: true,
              live: false
            })
            attachmentChanged.on("change", (change: any) => {
              var doc = change.doc;
              if (doc.typeAttachment == 'photo') {
                // Acá debo poner la lógica para enviar la información de las fotos
                var arrPhoto = [];
                var attachment = Object.keys(doc.extraAttachments)[0];

                var base64Photo = doc.extraAttachments[attachment].base64Photo;
                var blobPhoto = dataURItoBlob(base64Photo);
                var idPhoto = doc.extraAttachments[attachment].idPhoto;
                var namePhoto = doc.extraAttachments[attachment].namePhoto;
                var typePhoto = doc.extraAttachments[attachment].typePhoto;
                var extensionPhoto = doc.extraAttachments[attachment].extensionPhoto;

                arrPhoto.push(blobPhoto);
                arrPhoto[0].Id = idPhoto;
                arrPhoto[0].name = namePhoto;
                arrPhoto[0].typePhoto = typePhoto;
                arrPhoto[0].extensionPhoto = extensionPhoto;
                arrPhoto[0].lastModified = arrPhoto[0].size;
                arrPhoto[0].lastModifiedDate = "";
                arrPhoto[0].webkitRelativePath = "";

                const formData = new FormData();
                for (let i = 0; i < arrPhoto.length; i++) {
                  formData.append(`file[${i}]`, arrPhoto[i], arrPhoto[i].name);
                }
                const url = `${backandGlobal.url}/api/Upload/OfflineUpload`;
                // this.uploadService.uploadOffline(arrPhoto).then(() => {
                //   console.log(`Foto sincronizada correctamente`);
                // }).catch((error: any) => {
                //   console.log(`Ha ocurrido un error subiendo la foto offline --> ${JSON.stringify(error)}`);
                // });
                fetchUploadFile(formData, () => {
                  dbAttachments.destroy().then(() => {
                    dbAttachments = new PouchDB('bd_AllAttachments');
                  });
                });
              } else if (doc.typeAttachment == 'file') {
                // Acá debo poner la lógica para realizar el insert a Lappiz_AllDocuments mediante query y no por la tx
                var attachments = Object.keys(doc.extraAttachments);
                for (var i = 0; i < attachments.length; i++) {
                  var attachment = attachments[i];
                  var attachmentData = doc.extraAttachments[attachment];
                  var idAttachment = attachmentData.idFile, extAttachment = attachmentData.extensionFile,
                    nameAttachment = attachmentData.nameFile, typeAttachment = attachmentData.typeFile,
                    sizeAttachment = attachmentData.sizeFile, encodingAttachment = '7bit';
                  var base64Attachment = doc._attachments[`${idAttachment}.${extAttachment}`].data;

                  var queryInsertAttachment = `INSERT INTO Lappiz_AllDocuments ([Id], [Name], [Encoding], [MimeType], [Size], [Buffer]) VALUES ('${idAttachment}','${nameAttachment}','${encodingAttachment}','${typeAttachment}',${sizeAttachment},'${base64Attachment}')`;

                  var tenantId = sessionStorage.tenantId;
                  var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/lappiz/sp/query`;

                  var opt = {
                    query: queryInsertAttachment,
                    tenantId,
                    parameters: {
                      aType: "execTx",
                      environment: backandGlobal.environment
                    }
                  }
                  ajaxSync(url, 'POST', opt, (data) => {
                  }, null)
                }
              }
            })
            attachmentChanged.on("complete", function (info) {
              resolve(true);
            })
          })
          resolve(true);
        });
      }
      for (let i = 0; i < entityNames.length; i++) {
        var entity = entityNames[i].entityName;
        if (i != 0) {
          deleteSynchronizedEntity(entityNames[i - 1]);
        }
        // PouchDB.plugin(cordovaSqlitePlugin);
        dbEntities[i] = new PouchDB(`bd_${entity}`);
        dbEntities[i].info().then(function (info) {
          changesEntities[i] = dbEntities[i].changes({
            since: 0,
            include_docs: true,
            live: false,
            filter: function (doc) {
              return (
                doc.syncDate === null
              );
            }
          });

          changesEntities[i].on("change", (change) => {

            function cleanMetadata(item) {
              if(item == null || item == undefined) return;
              delete item.__modified;

              if (item.TempFieldsDetails) {
                for (var i in item.TempFieldsDetails) {
                  delete item[item.TempFieldsDetails[i]];
                }
                delete item.TempFieldsDetails;
              }

              if (!item || !(typeof item === 'object')) return;

              if (item.uid) {
                delete item.uid;
              }
              if (item._events) {
                delete item._events;
              }
              if (item.parent) {
                delete item.parent;
              }
              if (item._handlers) {
                delete item._handlers;
              }
              if (item.continue) {
                delete item.continue;
              }
              if (item.dirty) {
                delete item.dirty;
              }
              if (item.__metadata) {
                delete item.__metadata;
              }

              for (var key in item) {
                if (!item.hasOwnProperty(key)) continue;

                if (item[key]) {
                  var obj = item[key];
                  if (obj.__deferred) {
                    delete obj.__deferred;
                  }

                  if (typeof obj === 'object' && !(obj instanceof Date)) {
                    cleanMetadata(obj);
                  }

                  if (Object.getOwnPropertyNames(obj).length === 0 && typeof obj === 'object' && !(obj instanceof Date)) {
                    delete item[key];
                  }
                } else delete item[key];
              }
            }
            let currentDocumentAux = JSON.parse(JSON.stringify(change))
            change.doc.syncDate = new Date()
            var urlSync = change.doc.urlTransaction;
            var txSync = change.doc.txTransaction;
            delete currentDocumentAux.doc._id;
            delete currentDocumentAux.doc._rev;
            // delete change.doc.syncDate;
            delete currentDocumentAux.doc.createdDate;
            // delete change.doc.modifiedDate;
            delete currentDocumentAux.doc.deletedDate;
            delete currentDocumentAux.doc.entityName;
            delete currentDocumentAux.doc.urlTransaction;
            delete currentDocumentAux.doc.txTransaction;

            if (change.deleted) {
              // document was deleted
              if (change.doc.syncDate != null) {
                delete currentDocumentAux.doc.syncDate;
                ajaxSync(urlSync, txSync, JSON.stringify(currentDocumentAux.doc), function (response) {
                  console.log(`Tx done: ${response}`);
                  dbEntities[i].put(change.doc)
                }, entityNames[i]);
              }
            } else {
              delete currentDocumentAux.doc.syncDate;
              // document was added/modified
              if (change.doc.modifiedDate) {
                delete currentDocumentAux.doc.modifiedDate;
                // document was modified
                for (var keyname in change.doc) {
                  var item = change.doc[keyname];
                  cleanMetadata(item);
                }

                ajaxSync(urlSync, txSync, JSON.stringify(currentDocumentAux.doc), function (response) {
                  console.log(`Tx done: ${response}`);
                  dbEntities[i].put(change.doc);
                }, entityNames[i]);
              } else {
                delete change.doc.modifiedDate;
                // document was added
                for (var keyname in currentDocumentAux.doc) {
                  var item = currentDocumentAux.doc[keyname];
                  cleanMetadata(item);
                }

                ajaxSync(urlSync, txSync, JSON.stringify(currentDocumentAux.doc), function (response) {
                  console.log(`Tx done: ${response}`);
                  dbEntities[i].put(change.doc);
                }, entityNames[i]);
              }
            }
          });

        }).catch(function (err) {

        });
      }
      syncAttachments();
      deleteSynchronizedEntity(entityNames[entityNames.length - 1]);
      // @ts-ignore
      self.postMessage(entitiesResolved);
    }, false);
  }
  myOfflineWorker = new Worker(URL.createObjectURL(new Blob(["(" + this.syncWorker.toString() + ")()"], { type: 'text/javascript' })));

  constructor() {
  }

  _getWorker(entityNamesChanged: any, functionGlobal: any, Token: any, TenantId: any): any {
    var promise = new Promise((resolve, reject) => {
      this.myOfflineWorker.onmessage = function onmessage(oEvent) {
        resolve(oEvent.data);
      };
    });

    // @ts-ignore
    this.myOfflineWorker.postMessage({
      'entityNamesChanged': entityNamesChanged,
      'functionGlobal': functionGlobal,
      'Token': Token,
      'TenantId': TenantId
    });

    return promise;
  }

  executeOfflineWorker(entityNamesChanged: any, functionGlobal: any, Token: any, TenantId: any): Promise<any[]> {
    return this._getWorker(entityNamesChanged, functionGlobal, Token, TenantId).then((response: any) => {
      if (response == 'Error sync') {
        return false;
      }
      return response;
    });
  }
}
