import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

declare var webkitSpeechRecognition: any;
@Injectable({
  providedIn: 'root'
})
export class VoiceRercognitionService {

  recognition =  new webkitSpeechRecognition();
  isStoppedSpeechRecog = false;
  public text = '';
  tempWords:any ;
  isSpeech: boolean = false;
  campoId: string;
  started: boolean = false;

  private speech = new Subject<boolean>();
  serviceAvailable = this.speech.asObservable();

  constructor() { }

  init(lang: string ='es-CO', campoId: string): boolean {
    if(!this.isSpeech){
      this.isSpeech = true;
      if (!this.started){
        this.started = true;
        this.recognition.interimResults = true;
        this.recognition.lang = lang;
    
        this.recognition.addEventListener('result', (e) => {
          const transcript = Array.from(e.results)
            .map((result) => result[0])
            .map((result) => result.transcript)
            .join('');
          this.tempWords = transcript;
        });
      }
      return this.isSpeech;
    }
    return (!this.isSpeech) || this.campoId == campoId;
  }

  start(campoId: string) {
    this.campoId = campoId;
    this.text = '';
    this.isStoppedSpeechRecog = false;
    this.recognition.start();
    this.recognition.addEventListener('end', (condition) => {
      if (this.isStoppedSpeechRecog) {
        this.recognition.stop();
      } else {
        this.wordConcat()
        this.recognition.start();
      }
    });
  }

  stop(campoId:string): string {
    if (this.campoId == campoId){
      this.isStoppedSpeechRecog = true;
      this.wordConcat()
      this.recognition.stop();
      this.isSpeech = false;
      return this.text;
    }
    return '';
  }

  wordConcat() {
    this.text = this.text + ' ' + this.tempWords + '.';
    this.tempWords = '';
  }
}
