<div class="card">
  <div class="card-body card-body-lappiz">
    <div class="row" style="justify-content: space-between">
      <div class="col-xl-9 main-content pl-xl-4 pr-xl-5 d-flex align-items-center" style="column-gap: 8px">
        <img class="btn-back" src="../../../assets/images/flecha-izquierda.png" (click)="Volver()" title="Volver">
        <h1 class="page-title" style="margin: 0;">Rol</h1>
      </div>
      <div class="d-flex">
        <div class="d-flex align-items-center flex-wrap text-nowrap">
          <button (click)="SaveChanges()" type="submit" class="btn btn-outline-primary mr-2" [disabled]="!formRole.valid">
            <i class="fa fa-floppy-o"></i>
          </button>
        </div>
      </div>
      
    </div>
    <hr>
    <form [formGroup]="formRole" (ngSubmit)="SaveChanges()">
      <div class="form-group">
        <label for="nombreRole">Nombre rol</label>
        <span class="lappiz-field-required">*</span>
        <input type="text" class="form-control" id="txtNombreRol" placeholder="Nombre rol"
          formControlName="nombreRol" />
        <div class="text-danger mt-1"
          *ngIf="formRole.controls.nombreRol.errors?.required && formRole.controls.nombreRol.touched">
          Debe diligenciar el campo
        </div>
      </div>
      <div class="form-group">
        <label for="descripcionRol">Descripción</label>
        <input type="text" class="form-control" id="txtDespRole" placeholder="Descripción rol"
          formControlName="descripcionRol" />
      </div>
    </form>
  </div>
</div>
