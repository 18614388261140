import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Constants } from '@common/constants';
import { NotificationsService } from '@services/notifications.service';
import { UserInfoService } from '@services/user-info.service';

@Component({
  selector: 'app-user-profile',
  templateUrl: './user-profile.component.html',
  styleUrls: ['./user-profile.component.scss']
})
export class UserProfileComponent implements OnInit {

  profile: any;
  @Input() razonSocial: string = null;
  @Input() colorHeader: string;

  constructor(
    private userInfoService: UserInfoService,
    private router: Router,
    private notificationsService: NotificationsService
  ) {
    this.profile = {};
  }

  ngOnInit(): void {
    this.userInfoService.getInfoUser().then((data: any) => {
      if(data.rolesId.length == 0){
        this.notificationsService.notificationApp('warning',Constants.MESSAGES.NotActivatedRoles);
        let codeApp = sessionStorage.getItem('workspace');
        sessionStorage.clear();
        sessionStorage.setItem('workspace', codeApp);
        localStorage.clear();
        this.router.navigate([`/auth/login/${codeApp}`], );
      }
      this.profile.razonSocial = data.RazonSocialEmpresa;
      this.profile.username = data.Email;
    });

    /**
     * Se comenta momentaneamente para compatibilidad de PouchDB
     */
    //#region Offline
    // this.appContext.getAppState().then((context: any) => {
    //   var stateAppContext = context.stateApp;
    //   if (stateAppContext === true) {
    //     this.userInfoService.getInfoUser().then((data: any) => {
    //       var docUserInfo = {
    //         _id: 'userInfo',
    //         userInfo: data
    //       };
    //       this.appContext.insertUserInfo(docUserInfo);

    //       this.profile.razonSocial = data.RazonSocialEmpresa;
    //       this.profile.username = data.Email;

    //       let userId = data.UserId;
    //       sessionStorage.setItem('userId', userId);

    //       let empresaId = data.EmpresaId;
    //       sessionStorage.setItem('empresaId', empresaId);

    //       let rolesId = data.rolesId;
    //       sessionStorage.setItem('rolesId', rolesId);

    //       let tenantId = data.tenantId == '00000000-0000-0000-0000-000000000000' || data.tenantId === undefined ? null : data.tenantId;
    //       sessionStorage.setItem('tenantId', tenantId);
    //     });
    //   } else {
    //     this.appContext.getUserInfo().then((context: any) => {
    //       var userInfo = context.userInfo;

    //       this.profile.razonSocial = userInfo.RazonSocialEmpresa;
    //       this.profile.username = userInfo.Email;

    //       let userId = userInfo.UserId;
    //       sessionStorage.setItem('userId', userId);

    //       let empresaId = userInfo.EmpresaId;
    //       sessionStorage.setItem('empresaId', empresaId);

    //       let rolesId = userInfo.rolesId;
    //       sessionStorage.setItem('rolesId', rolesId);

    //       let tenantId = userInfo.tenantId == '00000000-0000-0000-0000-000000000000' || userInfo.tenantId === undefined ? null : userInfo.tenantId;
    //       sessionStorage.setItem('tenantId', tenantId);
    //     }).catch((error: any) => {
    //       console.log(`Ha ocurrido un error consultando la información del usuario de la aplicación`);
    //     });
    //   }
    // }).catch((error: any) => {
    //   console.log(`Ha ocurrido un error consultando el estado de la aplicación`);
    // });
    //#endregion
  }

}
