import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class RootScopeService {
  $rootScope: any;

  constructor() {
    this.$rootScope = {};
  }
}
