<form [formGroup]="innerForm">
  <div class="form-group">
    <div style="display: flex !important; align-items: stretch !important; width: 100% !important; height:2.5rem">
      <input [attr.disabled]="!field.IsEditable ? true : null || field.disabled ? true : null" (change)="change()"
        [required]="field.required" formControlName="field" [id]="field.campoId" [(ngModel)]="value.val"
        style="width: 100%;" />
      <div class="input-group-append icons-field" style="right: 1em; bottom: 1.5em">
        <span class="input-group-text icon-field">%</span>
      </div>
    </div>
  </div>
  <div
    *ngIf="formSubmitted || innerForm.controls.field.errors?.required && innerForm.controls.field.touched && !value.val"
    class="ft-appengine-required-input" id="required_{{field.campoId}}" role="alert">
    {{errors.required}}
  </div>
  <div class="ft-appengine-required-input" id="minlength_{{field.campoId}}" role="alert"
    *ngIf="formSubmitted || innerForm.controls.field.errors?.minlength && innerForm.controls.field.touched">
    {{errors.minimumValue}}
  </div>
  <div class="ft-appengine-required-input" id="maxlength_{{field.campoId}}" role="alert"
    *ngIf="formSubmitted || innerForm.controls.field.errors?.maxlength && innerForm.controls.field.touched">
    {{errors.maximumValue}}
  </div>
</form>
