<div class="row">
  <div class="col-md-12 stretch-card">
    <div class="card">
      <div class="card-body card-body-lappiz">
        <h3 class="card-title">{{ titleEditorVisor }}</h3>
        <hr>
        <textarea id="editorVisor"></textarea>
      </div>
    </div>
  </div>
</div>
