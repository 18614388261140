import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-formula',
  templateUrl: './formula.component.html',
  styleUrls: ['./formula.component.scss']
})
export class FormulaComponent implements OnInit {

  @Input() field: any; //required, field configuration and data
  @Input() value: any; // optional, value of the field, could be null 
  @Input() form: any; // required, the form that contains the field
  @Input() inputClass: string; // optional, optional css class
  @Input() errors: any; //optional, error messages 

  constructor() { }

  ngOnInit(): void {
    if (!this.value) {
      this.value = this.field.defaultValue;
    }
  }

  ShowFormula(data: any, value: any, fields: any, type: any) {

    if (type != "conc") {
      if (value == "" || value == null) {
        fields.formula = "";
        fields.showDiv = false;
      } else {
        var codeMath = "Math.";
        var expresionMath = new RegExp(codeMath, 'g');
        var newRewriteFormula = null;
        var rewriteFormula = data;

        newRewriteFormula = rewriteFormula.replace(expresionMath, "");
        fields.formula = newRewriteFormula + " = " + eval(data);
        fields.showDiv = true;
      }
    }
  }

  removeFormula(event?: any, fields?: any, type?: any) {
    if (type == "conc") {
      fields.formula = "";
      fields.showDiv = false;
    }
  }

}
