<div class="row" [hidden]="!isReady">
  <div class="col-md-12 stretch-card">
    <div class="card">
      <div class="card-body card-body-lappiz">
        <div class="header-section my-2">
          <div class="title-section d-flex align-items-center" style="column-gap: 8px">
            <img class="btn-back" src="../../../assets/images/flecha-izquierda.png" title="Volver">
            <h3>{{ project.name }} {{ nameBack }}</h3>
          </div>
        </div>
        <div>
          <div class="demo-section wide">
            <div id="taskBoard"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
