import { Injectable } from '@angular/core';

import { ProxyConfigApiService } from '@services/proxy-config-api.service';
import { EndPointService } from '@services/end-point.service';

@Injectable({
  providedIn: 'root'
})
export class UserInfoService {

  constructor(
    private proxyConfigApiService: ProxyConfigApiService,
    private endPointService: EndPointService
  ) { }

  async getInfoUser(): Promise<any> {
    let data = await this.proxyConfigApiService.getUserInfo(this.endPointService.userInfo());
    sessionStorage.aplicattionuserid = data['AplicationId'];
    let userId = data.UserId;
    sessionStorage.setItem('userId', userId);

    let empresaId = data.EmpresaId;
    sessionStorage.setItem('empresaId', empresaId);

    let rolesId = data.rolesId;
      sessionStorage.setItem('userRoles', rolesId);
    let tenantId = data.tenantId == '00000000-0000-0000-0000-000000000000' || data.tenantId === undefined ? null : data.tenantId;
    sessionStorage.setItem('tenantId', tenantId);
    return data;
  }
}
