import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { FormsComponent } from '@components/forms/forms.component';

@Component({
  selector: 'app-date',
  templateUrl: './date.component.html',
  styleUrls: ['./date.component.scss']
})
export class DateComponent implements OnInit {

  @Input() field: any;
  @Input() value: any;
  @Input() form: any;
  @Input() inputClass: string;
  @Input() errors: string;
  @Input() format: any;

  id: any;
  dateFormat: any;
  displayFormat: any;
  
  dateBox: any;
  innerForm: UntypedFormGroup;

  constructor(
    private formsComponent: FormsComponent
  ) { 
    this.dateBox = {};
    this.innerForm = new UntypedFormGroup({
      field: new UntypedFormControl(),
    });
  }

  ngOnInit(): void {
    if (this.value.val) {
      if (typeof (this.value.val) == 'string') {
        if (this.value.val.toLowerCase() == "now")
          this.value.val = new Date();
        else if (this.value.val.includes('Z'))
          this.value.val = this.value.val.substring(0, this.value.val.length - 1);
      }
    } else {
      this.value.val = null;
    }

    if (this.field.required == true) {
      this.innerForm = new UntypedFormGroup({
        field: new UntypedFormControl(this.field.value.val, [
          Validators.required,
        ])    
      });
    }

    this.formsComponent.checkForm(this.field.campoId, this.innerForm.invalid);

    this.id = this.field.campoId;
    this.displayFormat = this.format == 'date' ? "dd/MM/yyyy" : null;

    this.dateBox = {
      dateOutOfRangeMessage: 'Valor fuera de rango',
      disabled: this.field.disabled ? this.field.disabled : false || !this.field.IsEditable,
      displayFormat: this.displayFormat,
      invalidDateMessage: 'El valor debe ser fecha',
      placeholder: this.field.displayName,
      showClearButton: true,
      type: this.format,
      useMaskBehavior: true,
      validationMessageMode: 'always',
      value: this.value.val,
      onValueChanged: (e: any) => {
        this.field.value.val = e.value;
        this.change(e);
      }
    }

    setTimeout(() => {
      $(`#${this.field.campoId}`).dxDateBox(this.dateBox);
    }, 25);
  }

  change(e: any) {
    this.formsComponent.setValueFieldForm(this.field.campoId, this.field);
    if (this.value.val) {
      this.formsComponent.fireEvents('ValueChanged', this.field.events, { 
        value: this.value.val, 
        field: this.field
      });
    }
    if (this.field.required === true) {
      this.formsComponent.checkForm(this.field.campoId, this.value.val != null ? false : true);
    } else {
      this.formsComponent.checkForm(this.field.campoId, this.value.val ? false : this.innerForm.invalid);
    }
  }

}
