import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss']
})
export class ImageComponent implements OnInit {

  @Input() field: any;
  @Input() value: any;
  @Input() form: any;
  @Input() inputClass: string;
  @Input() errors: string;

  constructor() { }

  ngOnInit(): void {
    if (!this.value && this.field.defaultValue) {
      this.value = this.field.defaultValue;
    }
  }

}
