import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';

import { FormsComponent } from '@components/forms/forms.component';

import { ContextValuesService } from '@services/context-values.service';
import { ProxyTxApiService } from '@services/proxy-tx-api.service';

import { RootScopeService } from '@shared/root-scope.service';

import backandGlobal from '@env/env';

declare var kendo: any;

@Component({
  selector: 'app-combobox',
  templateUrl: './combobox.component.html',
  styleUrls: ['./combobox.component.scss']
})
export class ComboboxComponent implements OnInit, AfterViewInit {

  @Input() field: any;
  @Input() errors: any;
  @Input() Id: any;
  @Input() inputClass: any;
  @Input() form: any;
  @Input() value: any;
  @Input() noResults: any;
  @Input() noData: any;

  formSubmitted = false;
  innerForm: UntypedFormGroup;

  combobox: any;
  textField: string;
  dataSource: any;
  optionLabel: any;
  changed: any;
  onFilter: any;
  onOpen: any;
  dataItemloaded: any;
  options: any;
  filterGridOptions: any;
  loadingItems: boolean;
  placeholder: string;
  expanded_entities: string;
  endPoint: any;
  url: string;
  viewEntityName: string;
  datasort: string[];
  arrayAnd: any[];
  arrayOr: any[];
  arrayNone: any[];
  arrayFilter: any;
  appViewId: string;
  campocascadeid: any;
  optionscascade: any;
  totalRows: any;
  originalUrlBeforeFilter: any;
  dataCascadeCombobox: any;
  optionsFilter: any;
  optionsTemplate: any;
  itemsloaded: any;
  items: any;
  itemsCascade: any;
  filtering: boolean = false;

  constructor(
    private $rs: RootScopeService,
    private route: ActivatedRoute,
    private contextValuesService: ContextValuesService,
    private proxyTxApiService: ProxyTxApiService,
    private formsComponent: FormsComponent
  ) {

    this.route.queryParamMap.subscribe((params) => {
      this.appViewId = params.get('appViewId');
    });

    setTimeout(() => {
      var opc = {
        dataTextField: this.textField,
        dataValueField: "Id",
        filter: "contains",
        optionLabel: this.optionLabel,
        autoBind: false,
        valuePrimitive: true,
        dataSource: this.dataSource,
        filtering: this.onFilter,
        open: this.onOpen,
        enable: this.field.disabled,
        headerTemplate: this.optionsTemplate.headerTemplate,
        template: this.optionsTemplate.template,
        valueTemplate: this.optionsTemplate.valueTemplate,
        value: this.value.val,
        change: (change: any) => {
          this.changed(change, this.field);
        }
      };

      this.combobox = kendo.jQuery(`#${this.Id}`).kendoDropDownList(opc);

      kendo.jQuery(`#${this.Id}`).data('kendoDropDownList').value(this.value.val);

      this.formsComponent.applyUIRules(this.field, this.value);
    }, 1000);

    this.innerForm = new UntypedFormGroup({
      field: new UntypedFormControl(),
    });

  }

  ngAfterViewInit() {
    if (this.field.required == false) {
      this.innerForm = new UntypedFormGroup({
        field: new UntypedFormControl(this.field.value.val, [
          Validators.minLength(this.field.minimumValue),
          Validators.maxLength(this.field.maximumValue)
        ])
      });
    }
  }

  ngOnInit(): void {

    this.viewEntityName = this.field.options[0].endPoint.view;

    if (this.field.required == true) {
      this.innerForm = new UntypedFormGroup({
        field: new UntypedFormControl(this.field.value.val, [
          Validators.required,
          Validators.minLength(this.field.minimumValue),
          Validators.maxLength(this.field.maximumValue)
        ])
      });
    }

    this.formsComponent.checkForm(this.field.campoId, this.value.val ? false : this.innerForm.invalid);

    this.options = {
      Items: []
    };

    this.filterGridOptions = {
      logic: 'and',
      filters: [{
        logic: 'or',
        filters: []
      }, {
        logic: "or",
        filters: []
      }]
    };

    this.noData = this.$rs.$rootScope.lang == 'es' ? 'No se encontraron resultados' : this.$rs.$rootScope.lang == 'en' ? 'No results found' : 'No se encontraron resultados';
    this.optionLabel = this.$rs.$rootScope.lang == 'es' ? 'Seleccione un registro...' : this.$rs.$rootScope.lang == 'en' ? 'Select a record...' : 'Seleccione un registro...';
    this.placeholder = this.$rs.$rootScope.lang == 'es' ? "Seleccione" : this.$rs.$rootScope.lang == 'en' ? "Select" : "Seleccione";
    this.textField = this.field.options[0].endPoint.defaultField;
    this.Id = this.field.campoId;
    this.loadingItems = true;
    this.noResults = false;
    this.expanded_entities = '';

    this.getIncludesEntities(this.field);

    this.endPoint = this.field.options[0].endPoint;
    this.url = '';

    if (this.endPoint.filter.Query) {
      let NewQuery = '';
      if (this.endPoint.filter.Query.includes('@')) {
        let parts = this.endPoint.filter.Query.split('*');
        for (let i = 0; i < parts.length; i++) {
          if (parts[i].includes('@')) {
            parts[i] = parts[i].replace('@', '');
            this.$rs.$rootScope.$broadcast('getFieldValue', parts[i]);
            parts[i] = `'${this.$rs.$rootScope.getFieldValue}'`;
          }
        }

        for (let k = 0; k < parts.length; k++) {
          NewQuery += parts[k];
        }
      }

      this.url = this.contextValuesService.transformODataQuery(NewQuery != '' ? NewQuery : this.endPoint.filter.Query);
      if (this.url.includes("$orderby=")) {
        this.datasort = this.url.split("$orderby=")[1].split(" ");
        if (this.datasort[0].split(".").length == 1) {
          this.datasort[0] = this.viewEntityName.replace('Details', '') + "." + this.datasort[0];
        }
        this.datasort = this.datasort;
      } else {
        this.datasort = null;
      }

      if (this.url != null || this.url != undefined) {
        let filterQuery = this.url.split("$filter=")[1];
        let arrayAux = [];
        let arrayAnd = [];
        let arrayOr = [];
        let arrayNone = [];
        this.arrayAnd = [];
        this.arrayOr = [];
        this.arrayNone = [];

        if (filterQuery != undefined) {
          let filterArray = filterQuery.split(" ");
          filterArray.forEach(function (value) {
            let aux = value;
            if (value.includes("*+*")) {
              aux = value.split("*+*").join(" ");
            }

            aux = aux.replace("'", "").replace("'", "");
            arrayAux.push(aux);
          });

          for (let i = 0; i < arrayAux.length; i++) {
            if (arrayAux[i] == 'and') {
              let bandera = i + 1;
              for (let j = 0; j < 3; j++) {
                arrayAnd[j] = arrayAux[bandera];

                bandera++;
              }
            } else if (arrayAux[i] == 'or') {
              let bandera = i + 1;
              for (let j = 0; j < 3; j++) {
                arrayOr[j] = arrayAux[bandera];
                bandera++;
              }
            } else if (i == 0) {
              let bandera = i;
              for (let j = 0; j < 3; j++) {
                arrayNone[j] = arrayAux[bandera];
                bandera++;
              }
            }
          }

          this.filterGridOptions = {
            logic: 'and',
            filters: [{
              logic: 'or',
              filters: []
            }, {
              logic: "or",
              filters: []
            }]
          }

          if (arrayNone != undefined || arrayNone != null) {
            let nIteracion = arrayNone.length / 3;
            let inicio = 0;
            let fin = 3;
            for (let i = 0; i < nIteracion; i++) {
              let part = arrayNone.slice(inicio, fin);

              this.filterGridOptions.filters.push({
                field: part[0],
                operator: part[1],
                value: part[2]
              });

              this.arrayNone.push({
                field: part[0],
                operator: part[1],
                value: part[2]
              });

              inicio = fin;
              fin = fin + 3;
            }
          }

          if (arrayAnd != undefined || arrayAnd != null) {
            let nIteracion = arrayAnd.length / 3;
            let inicio = 0;
            let fin = 3;
            for (let i = 0; i < nIteracion; i++) {
              let part = arrayAnd.slice(inicio, fin);
              this.filterGridOptions.filters.push({
                field: part[0],
                operator: part[1],
                value: part[2]
              });

              this.arrayAnd.push({
                field: part[0],
                operator: part[1],
                value: part[2]
              });

              inicio = fin;
              fin = fin + 3;
            }
          }

          if (arrayOr != undefined || arrayOr != null) {
            let nIteracion = arrayOr.length / 3;
            let inicio = 0;
            let fin = 3;
            for (let i = 0; i < nIteracion; i++) {
              let part = arrayOr.slice(inicio, fin);
              this.filterGridOptions.filters[0].filters.push({
                field: part[0],
                operator: part[1],
                value: part[2]
              });

              this.arrayOr.push({
                field: part[0],
                operator: part[1],
                value: part[2]
              });

              inicio = fin;
              fin = fin + 3;
            }
          }
        }
      }
    } else if (this.expanded_entities === '') {
      this.url = backandGlobal.api2 + this.endPoint.url + 'lappiz/get/' + this.endPoint.view.replace('Details', '');
    } else {
      this.expanded_entities = this.expanded_entities.slice(0, -1);
      this.url = `${backandGlobal.api2 + this.endPoint.url + 'lappiz/get/' + this.endPoint.view.replace('Details', '')}`; //+ params;
    }

    if (!this.field.options[0].cascadeId) {
      this.dataSource = {
        serverFiltering: backandGlobal.stateOnline ? true : false,
        serverPaging: backandGlobal.stateOnline ? true : false,
        serverSorting: backandGlobal.stateOnline ? true : false,
        transport: {
          read: async (options: any) => {
            this.optionsFilter = options;
            let arrayFilter: any = [];
            let bandera = false;

            if (this.optionsFilter.data.filter == undefined || this.optionsFilter.data.filter == null) {
              this.dataSource.pageSize = 10000;
              this.optionsFilter.data.filter = {
                logic: 'and',
                filters: [{
                  logic: 'or',
                  filters: []
                }]
              }
            }

            if (this.optionsFilter.data.filter.filters != undefined || this.optionsFilter.data.filter.filters != null) {
              this.dataSource.pageSize = 10000;
              for (let i = 0; i < options.data.filter.filters.length; i++) {
                arrayFilter = options.data.filter.filters[i];
                bandera = true;
                if (this.filterGridOptions == undefined || this.filterGridOptions == null) {
                  this.filterGridOptions = {
                    logic: 'and',
                    filters: [{
                      logic: 'or',
                      filters: []
                    }, {
                      logic: "or",
                      filters: []
                    }]
                  }
                }
              }
            }

            if (bandera == true) {
              this.dataSource.pageSize = 10000;
              this.arrayFilter = arrayFilter.value;
              if (this.arrayNone != undefined || this.arrayNone != null) {
                for (let index = 0; index < this.arrayNone.length; index++) {
                  this.filterGridOptions.filters.push(this.arrayNone[index]);
                }
              }

              if (this.arrayAnd != undefined || this.arrayAnd != null) {
                for (let index = 0; index < this.arrayAnd.length; index++) {
                  this.filterGridOptions.filters.push(this.arrayAnd[index]);
                }
              }

              if (this.arrayOr != undefined || this.arrayOr != null) {
                for (let index = 0; index < this.arrayOr.length; index++) {
                  this.filterGridOptions.filters[0].filters.push(this.arrayOr[index]);
                }
              }

              bandera = false;
            }

            var gridFieldColumns = this.field.FieldsTemplate;
            var fieldsColumns = [];
            var entityInclude = [];
            var stringEntity = "";
            fieldsColumns.push("Id");
            if (gridFieldColumns != null) {
              gridFieldColumns.forEach((value: any) => {
                if (!value.command) {
                  if (value.CodeCampoTemplate.includes(this.field.viewName)) {
                    fieldsColumns.push(value.CodeCampoTemplate.replace("[", "").replace("]", "").replace("[", "").replace("]", "").replace(".", "").replace(this.viewEntityName, ""))
                  } else {
                    // if (this.expanded_entities != null) {
                    //   entityInclude.push(this.expanded_entities.replace(',', ''));
                    // }
                    if (value.NameEntityTemplate?.includes('Details')) {
                      entityInclude.push(value.NameEntityTemplate.replaceAll('Details', ''));
                    }

                    fieldsColumns.push(value.NameEntityTemplate == null ? value.CodeCampoTemplate : value.NameEntityTemplate.replace('Details', '') + "." + value.CodeCampoTemplate);
                  }
                }
              });
            }

            let uniqueField = [...new Set(entityInclude)];
            stringEntity = this.getIncludes(uniqueField);

            if (this.datasort != null || this.datasort != undefined) {
              this.optionsFilter.data.sort = [{ field: this.datasort[0], dir: this.datasort[1] }]
              this.datasort = null;
            }

            this.optionsFilter.data.tenantId = sessionStorage.tenantId;
            this.optionsFilter.data.parameters = {
              "userId": sessionStorage.userId,
              "appViewId": "00000000-0000-0000-0000-000000000000",
              "pType": "showinmenu",
              "aType": "ffija",
              "environment": backandGlobal.environment
            };

            this.optionsFilter.data.filter = this.filterGridOptions;

            if (this.field.campoId == this.campocascadeid) {
              if (this.optionsFilter.data.filter == undefined) {
                this.optionsFilter.data.filter = this.optionscascade.filter
              } else {
                this.optionsFilter.data.filter.filters[0] = this.optionscascade.filter.filters[0];
              }
            }

            this.optionsFilter.data.includeEntities = stringEntity == '[{"model": modelo., "as":""}]' ? '' : stringEntity;
            var entityOff = this.endPoint.view.replace('Details', '');
            this.optionsFilter.data.take = this.dataSource.pageSize;
            this.optionsFilter.data.pageSize = this.dataSource.pageSize;
            var urlTx = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/get/${this.endPoint.view.replace('Details', '')}`;

            await this.proxyTxApiService.getTxData(urlTx, JSON.stringify(this.optionsFilter.data), backandGlobal.stateOnline, entityOff).then(async (result: any) => {
              if (backandGlobal.stateOnline) {
                this.items = result.data.rows;
                if (this.field.dataItem && !this.filtering) {
                  //En caso de tener un valor asignado y dicho valor no este en la consulta lo agregamos
                  //Por ejemplo cuando la paginación es de 50 y el registro seleccionado esta en la pocisión 90
                  if (!this.items.some(item => item.Id == this.field.dataItem.Id)) {
                    setTimeout(() => {
                      var combo = kendo.jQuery('#' + this.field.campoId).data('kendoDropDownList');
                      //Agregamos el registro al combo del datasource
                      combo.dataSource.add(this.field.dataItem);

                      combo.value(this.field.dataItem.Id);
                      combo.trigger('change');

                      if (this.formsComponent.isViewItem) {
                        combo.enable(false);
                      }
                    }, 100);
                  }
                }

                this.optionsFilter.data.filter = undefined;
                if (this.optionsFilter.data.filter != undefined) {
                  if (this.optionsFilter.data.filter.filters.length == 0) {

                  } else {
                    await options.success(result.data.rows);
                    this.totalRows = result.total;
                    this.optionsFilter.data.filter = undefined;
                  }
                } else {
                  await options.success(result.data.rows);
                  this.totalRows = result.total;
                  this.optionsFilter.data.filter = undefined;
                }
              } else {
                await options.success(result.rows);
                this.totalRows = result.length;
              }
            });
          },
          schema: {
            data: async (data: any) => {
              if (backandGlobal.stateOnline) {
                this.items = data.data.data.rows;
                this.itemsloaded = this.items;

                return data.data.data.rows;
              } else {
                this.items = data;
                this.itemsloaded = this.items;
                return data;
              }
            },
            total: async (data: any) => {
              if (backandGlobal.stateOnline) {
                return data.data.data.count;
              } else {
                return data.length;
              }
            }
          }
        }
      }
    }

    this.onOpen = (e: any) => {
      /*
        Al momento de abrir el combo en caso que la url haya sido modificada para ajustar
        el filtro, entonces debe volver a ser asignada la url original de la configuración
      */
      let cascade = this.field.options[0].endPoint.cascadeId;
      if (cascade) {
        if (this.originalUrlBeforeFilter) {
          if (e.sender.dataSource.transport.options != undefined) {
            e.sender.dataSource.transport.options.read.url = this.originalUrlBeforeFilter;
          }
          // Vaciamos la variable para que al momento de refrescar no vuelva a entrar a esta parte del if
          this.originalUrlBeforeFilter = '';
          // Refrescamos el combo
          // refrescarCombo(e.sender);
        }
      }

      if (this.filterGridOptions.filters[1].filters.length != 0) {
        this.filterGridOptions.filters[1].filters = [];
        e.sender.dataSource.read();
        e.sender.refresh();
      }
    }

    this.onFilter = (e: any) => {
      this.filtering = true;
      if (e.filter) {
        let filters = [];
        if (this.field.FieldsTemplate)
          for (var [i, currentField] of this.field.FieldsTemplate.entries()) {
            let currentEntity = (!!currentField.NameEntityTemplate ? currentField.NameEntityTemplate : this.viewEntityName).replace('Details', '');
            filters[i] = {
              field: `${currentEntity}.${currentField.CodeCampoTemplate}`,
              ignoreCase: true,
              operator: "contains",
              value: e.filter.value
            }
          }
        else
          filters[0] = {
            field: this.textField,
            ignoreCase: true,
            operator: "contains",
            value: e.filter.value
          }
        this.filterGridOptions.filters[1].filters = filters;
        e.filter = this.filterGridOptions;
      }
    }

    if (this.field.FieldsTemplate) {
      var Templates = '';
      var Template = '';
      var HeaderTemplate = '<div class="dropdown-header" style="width:98.5%; padding:0px; display:flex; justify-content: space-between; font-size: medium;">';
      var width = Math.round(100 / this.field.FieldsTemplate.length);
      var height = ''
      //Seleccionamos el primer campo de los templates como el filtro y como el campo que mostrará la info
      this.textField = this.field.FieldsTemplate[0].CodeCampoTemplate;
      this.textField = (this.field.FieldsTemplate[0].NameEntityTemplate ? `${this.field.FieldsTemplate[0].NameEntityTemplate.replace('Details', '')}.` : '') + this.textField;

      this.field.FieldsTemplate.forEach((value: any) => {
        var nameValue = `${value.NameEntityTemplate ? `${value.NameEntityTemplate.replace('Details', '')}.` : ''}${value.CodeCampoTemplate.charAt(0).toUpperCase()}${value.CodeCampoTemplate.slice(1)}`;

        switch (value.Type) {
          case 'DateTime':
            Templates += `<span class="k-state-default"  style="width:${width}% !important ; font-size: small;${height}"><p>#: ${nameValue} ? new Date(${nameValue}).toLocaleString('es-CO',{timeZone:'UTC'}) : '' #</p></span>`;
            Template = `#: ${nameValue} ? new Date(${nameValue}).toLocaleString('es-CO',{timeZone:'UTC'}) : '' #`
            break;
          case 'Date':
            Templates += `<span class="k-state-default"  style="width:${width}% !important ; font-size: small;${height}"><p>#:new Date(parseInt(${nameValue}.substring(6,${nameValue}.length - 2))).toLocaleDateString('es-CO',{timeZone:'UTC'}) #</p></span>`;
            break;
          case 'carrousel':
            this.optionLabel = '';
            Templates += `<div class='imagenCombo' style=' background-image: url(${backandGlobal.URL_API_UPLOAD_IMAGES}#:(${nameValue}||"").replace("[","").replace("]","")#);'></div>`;
            height = 'height: 50px !important;';
            this.textField = this.field.FieldsTemplate[1].CodeCampoTemplate;
            break;
          case 'Foto':
            this.optionLabel = '';
            Templates += `<div class='imagenCombo' style=' background-image: url(${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/documents/#:${nameValue}#.png);'></div>`
            height = 'height: 50px !important;';
            this.textField = this.field.FieldsTemplate[1].CodeCampoTemplate;
            break;
          case 'Map':
            Templates += `<span class="k-state-default"  style="width:${width}% !important ; font-size: small;${height}"><p>#= ${nameValue}? JSON.parse(${nameValue}).address: '' #</p></span>`;
            break;
          default:
            Templates += `<span class="k-state-default"  style="width:${width}% !important ; font-size: small;${height}"><p>#: ${nameValue} #</p></span>`;
        }
      });

      this.field.FieldsTemplate.forEach(function (value: any) {
        switch (value.Type) {
          case 'carrousel':
            HeaderTemplate = '<div class="dropdown-header" style="width:98.5%; padding:0px ">';
            break;
          case 'Foto':
            HeaderTemplate = '<div class="dropdown-header" style="width:98.5%; padding:0px ">';
            break;
          default:
            HeaderTemplate += `<span class="k-widget k-header"  style="width:${width}% ; font-size: medium">${value.NameCampoTemplate}</span>`;
        }
      });
    }

    this.optionsTemplate = {
      headerTemplate: HeaderTemplate,
      template: Templates,
      valueTemplate: Template
    };

    this.changed = (e: any, fieldc?: any) => {
      if (fieldc.campoId == this.field.campoId) {
        var combobox = kendo.jQuery(`#${this.field.campoId}`).data("kendoDropDownList");
        var dataItem = combobox.dataItem();
        this.value.val = combobox.value();
        this.field.value.val = this.value.val;
        this.formsComponent.applyUIRules(this.field, this.value);
        if (!this.value.val && this.value.val != '') {
          this.field.dataItem = null;
          this.formsComponent.relChanged('', this.value.val, this.field);
          this.formsComponent.fireEvents('ValueChanged', this.field.events, {
            field: this.field
          });
        }

        if (this.field.options[0].endPoint.cascadeId != null && this.field.options[0].endPoint.cascadeId != undefined && fieldc == null || fieldc == undefined) {
          this.formsComponent.cascade(dataItem, this.field);
        } else {
          this.formsComponent.cascade(dataItem, fieldc);
        }

        this.formsComponent.relChanged(dataItem, this.value.val, this.field);

        this.field.dataItem = null;
        var params = {
          value: this.value.val,
          dataItem,
          field: this.field,
          isNew: this.formsComponent.isNew
        };

        this.field.dataItem = params.dataItem;
        this.formsComponent.fireEvents('ValueChanged', this.field.events, params);

        if (this.value.val == '') {
          if (this.field.required) {
            this.formsComponent.checkForm(this.field.campoId, true);
          } else {
            this.formsComponent.checkForm(this.field.campoId, false);
          }
        } else {
          this.formsComponent.checkForm(this.field.campoId, false);
        }

        setTimeout(() => {
          if (this.formsComponent.isViewItem) {
            combobox.enable(false);
          }
        }, 100);
      }
    }
  }

  getIncludesEntities(field: any) {
    if (field.FieldsTemplate) {
      field.FieldsTemplate.forEach((Template: any) => {
        if (Template.NameEntityTemplate) {
          let contains = this.expanded_entities.includes(Template.NameEntityTemplate.replace('Details', ''));
          if (!contains) {
            this.expanded_entities += Template.NameEntityTemplate.replace('Details', '') + ',';
          }
        } else {
          this.expanded_entities = '';
        }
      });
    }
  }

  getTemplateMarkup(data: any, containerClass: any) {
    var text = '';
    if (this.field.FieldsTemplate) {
      this.field.FieldsTemplate.forEach((value: any) => {
        if (text != '') {
          text += '\n';
        }

        text += data[value.CodeCampoTemplate];
      });
    } else {
      text = data[this.textField];
    }

    return `<div class='${containerClass}'><div><PRE class="Templatedx"> ${text}</PRE></div></div>`;
  }

  refrescarCombo(combo: any) {
    combo.dataSource.read();
    combo.refresh();
  }

  async applyCascade(urlTx: any, nameEntity: any, options: any): Promise<any> {
    this.optionscascade = options;
    this.optionsFilter.data.parameters = {
      "userId": sessionStorage.userId,
      "appViewId": "00000000-0000-0000-0000-000000000000",
      "pType": "",
      "aType": "ffija",
      "environment": backandGlobal.environment
    };

    await this.proxyTxApiService.getTxData(urlTx, JSON.stringify(options.filter), backandGlobal.stateOnline, nameEntity).then((response: any) => {
      if (backandGlobal.stateOnline) {
        return this.dataCascadeCombobox = response.data.rows;
      } else {
        return this.dataCascadeCombobox = response;
      }
    });
  }

  async cascadeCombobox(newurl: any, campoId: any, entityName: any, options: any, fields: any) {
    this.getIncludesEntities(fields);
    var endPoint = fields.options[0].endPoint;
    // var url = '';

    // url = endPoint.filter.Query ? this.contextValuesService.transformODataQuery(endPoint.filter.Query) : '';

    if (endPoint.filter.Options.includes("$orderby=")) {
      var datasort = endPoint.filter.Options.split("$orderby=")[1].split(" ");
      if (datasort[0].split(".").length == 1) {
        datasort[0] = entityName + "." + datasort[0];
      }
      datasort = datasort;
    } else {
      datasort = null;
    }

    if (datasort != null || datasort != undefined) {
      options.sort = [{ field: datasort[0], dir: datasort[1] }]
      datasort = null;
    }

    this.originalUrlBeforeFilter = newurl;
    this.dataSource.transport.read.url = newurl;

    //Limpiar valor para que al momento de cambiar un padre limpie los hijos seleccionados
    if (!this.value) return;

    var entity = entityName;
    this.campocascadeid = fields.campoId;
    this.optionscascade = options
    if (this.optionscascade.filter.filters.find(a => a.value == 'null'))
      this.optionscascade.filter.filters.find(a => a.value == 'null').value = this.field.selected
    if (fields.FieldsTemplate) {
      fields.FieldsTemplate.forEach((Template: any) => {
        if (Template.NameEntityTemplate) {
          let contains = this.expanded_entities.includes(Template.NameEntityTemplate.replace('Details', ''));
          if (!contains) {
            this.expanded_entities += Template.NameEntityTemplate.replace('Details', '') + ',';
          }
        }
      });
    }
    var gridFieldColumns = fields.FieldsTemplate;
    var fieldsColumns = [];
    var entityInclude = [];
    var stringEntity = "";
    fieldsColumns.push("Id");
    if (gridFieldColumns != null) {
      gridFieldColumns.forEach((value: any) => {
        if (!value.command) {
          if (value.CodeCampoTemplate.includes(fields.viewName)) {
            fieldsColumns.push(value.CodeCampoTemplate.replace("[", "").replace("]", "").replace("[", "").replace("]", "").replace(".", "").replace(this.viewEntityName, ""))
          } else {
            // if (this.expanded_entities != null) {
            //   entityInclude.push(this.expanded_entities.replace(',', ''));
            // }
            if (value.NameEntityTemplate?.includes('Details')) {
              entityInclude.push(value.NameEntityTemplate.replaceAll('Details', ''));
            }

            fieldsColumns.push(value.NameEntityTemplate == null ? value.CodeCampoTemplate : value.NameEntityTemplate.replace('Details', '') + "." + value.CodeCampoTemplate);
          }
        }
      });
    }

    let uniqueField = [...new Set(entityInclude)];
    stringEntity = this.getIncludes(uniqueField);
    options.includeEntities = stringEntity == '[{"model": modelo., "as":""}]' ? '' : stringEntity;
    // var entityOff = this.endPoint.view.replace('Details', '');
    var urlTxCascade = newurl;

    if (!this.optionscascade.filter.filters.find((a: any) => a.value == 'null' || a.value == '')) {
      await this.proxyTxApiService.getTxData(urlTxCascade, JSON.stringify(options), backandGlobal.stateOnline, entity).then((response: any) => {
        var data = [];
        if (backandGlobal.stateOnline === true) {
          data = response.data.rows;
        } else {
          data = response.rows;
        }
        this.itemsCascade = data;

        var cascadeDataSource = new kendo.data.DataSource({ data });
        var combobox = kendo.jQuery(`#${fields.campoId}`).data("kendoDropDownList");
        combobox.setDataSource(cascadeDataSource);
        combobox.enable(true);

        var isNew = this.formsComponent.isNew;
        if (!isNew) {
          var valueCombobox = combobox.value();
          if (data.filter(x => x.Id === valueCombobox).length > 0) {
            combobox.value(valueCombobox);
          } else {
            combobox.value('')
          }
        } else {
          combobox.value('');
        }

        combobox.trigger('change');

        if (this.formsComponent.isViewItem) {
          combobox.enable(false);
        }
      });
    } else {
      var combobox = kendo.jQuery(`#${fields.campoId}`).data("kendoDropDownList");
      combobox.enable(false);
      combobox.value('');
      combobox.trigger('change');

      if (this.formsComponent.isViewItem) {
        combobox.enable(false);
      }
    }
  }

  urlchanged(newurl: any, campoId: any) {
    if (campoId != this.field.campoId) return;
    this.originalUrlBeforeFilter = newurl;
    this.dataSource.transport.read.url = newurl;
    //Limpiar valor para que al momento de cambiar un padre limpie los hijos seleccionados
    if (!this.value) return;

    this.value = null;
    this.field.dataItem = null;

    var combo = kendo.jQuery(`#${this.field.campoId}`).data('kendoDropDownList');
    this.refrescarCombo(combo);
    combo.trigger('change');
  }

  buildNewFilter(segmentos: any, field: any, value: any) {
    let seg = segmentos.filter((s: any) => {
      return s.includes('filter=');
    });

    var oldfilter = seg[0];
    var newfilter: any;
    if (!oldfilter) {
      //En caso que la url no tenga filter (sucede cuando hay un template sin filtro) se lo predefinimos
      oldfilter = 'filter=';
      newfilter = '(';
    } else {
      newfilter = ' and (';
      //Encapsular filtro anterior para que la clausula AND funcione correctamente eg: filter= (algun_filtro) and (new_filter)
      var splitOldFilter = oldfilter.split('=');
      oldfilter = `${splitOldFilter[0]}=(${splitOldFilter[1].replace('&', '')})`;
    }

    if (this.field.FieldsTemplate) {
      //En caso de tener templates hacemos la busqueda por cada uno de los campos definidos
      let i: any;
      for (i in this.field.FieldsTemplate) {
        var orOperator = ' or ';
        if (i == 0) orOperator = '';
        var template = this.field.FieldsTemplate[i];
        var fieldName = (template.NameEntityTemplate ? `${template.NameEntityTemplate.replace('Details', '')}/` : '') + template.CodeCampoTemplate;
        switch (template.Type) {
          case 'Entero':
          case 'Numeric':
          case 'Boolean':
          case 'DateTime': //Pendiente por validar la forma sí es con o sin comillas
            try {
              var numberValue = parseInt(value);
              if (!isNaN(numberValue)) newfilter += `${orOperator}${fieldName} eq ${value}`;
            } catch (ex) {
              // // console.log('Valor no soportado para el template seleccionado, nose filtrará por este campo');
            }
            break;
          default:
            newfilter += `${orOperator}substringof(%27${value}%27,${fieldName})`;
            break;
        }
      }
    } else {
      newfilter += `substringof(%27${value}%27,${field})`;
    }

    newfilter += ')';

    if (oldfilter.substring(oldfilter.length - 1) == '&') {
      oldfilter = oldfilter.substring(0, oldfilter.length - 1);
    }

    newfilter = `$${oldfilter}${newfilter}`;
    return newfilter;
  }

  uncompressIncludeEntities(entities: any) {
    var arr = entities;
    let arr2 = [...arr];

    // var arrFin = [];
    for (let i = 0; i < arr.length; i++) {
      for (let j = 0; j < arr.length; j++) {
        if (i != j) {
          var strOr = arr[i];
          var strDes = arr[j];
          if (strDes.startsWith(strOr)) {
            delete arr2[i];
            break;
          }
        }
      }
    }

    var filtered = arr2.filter((el: any) => {
      return el != null;
    });

    let unique = [...new Set(filtered)];
    return unique;
  }

  getIncludes(entities: any) {
    var aEntities = this.uncompressIncludeEntities(entities);
    var stringInclude = "";
    var cierre = "}]";
    for (let j = 0; j < aEntities.length; j++) {
      if (j != 0) stringInclude += ', ';
      if (!aEntities[j].includes(".")) {
        stringInclude += `{"model": modelo.${aEntities[j]}, "as":"${aEntities[j]}"`;
      } else {
        var auxEntityArray = aEntities[j].split(".");
        for (let i = 0; i < auxEntityArray.length; i++) {
          if (i == 0) {
            stringInclude += `{"model": modelo.${auxEntityArray[i]}, "as": "${auxEntityArray[i]}"`;
          } else if (i == auxEntityArray.length - 1) {
            stringInclude += `, include: [{"model": modelo.${auxEntityArray[i]}, "as": "${auxEntityArray[i]}"`;
            stringInclude += cierre.repeat(i);
          } else {
            stringInclude += `, include: [{"model": modelo.${auxEntityArray[i]}, "as": "${auxEntityArray[i]}"`;
          }
        }
      }
      stringInclude += `}`;
    }

    return "[" + stringInclude + "]";
  }

  loadSelectedItem(e: any, dataItem: any, val: any, f: any) {
    if (e.campoId != f.campoId || !dataItem) return;
    // this.dataItemloaded = Promise.resolve(dataItem);
    this.dataItemloaded = dataItem;
  }

  change() {

  }
}
