<div class="card-container">
  <div class="header-section my-2">
    <div class="title-section">
      <h1>{{ NameEntity }}</h1>
      <h5 translate="lappiz_chat"></h5>
    </div>
  </div>
  <div class="header-section my-2">
    <div class="title-section">
      <h4 translate="my_chats"></h4>
    </div>
  </div>
  <span *ngIf="noRecords">{{ noRecords }}</span>
  <li *ngFor="let lChat of Contactsuser" style="list-style: none;">
    <div class="chats">
      <span>
        <div class='publication' style='margin-top:-3px;'>
          <div class='publication-margen'>
            <div class='publication-header'><img src='{{ lChat.Contact.Photo }}' class='publication-photo active-border'
                style='cursor:pointer' (click)="initChat(lChat,lChat.Contact.Id)">
              <div class='col-xs-10 without-padding'>
                <div class='col-xs-9 without-padding'>
                  <div class='publication-user active-color' style='cursor:pointer;'
                    (click)="initChat(lChat,lChat.Contact.Id)">
                    {{ lChat.Contact.FullName }}</div>
                  <div class='publication-area color-subtitle'></div>
                </div>
                <div class='publication-date col-xs-3 color-text'></div>
              </div>
            </div>
            <div class='publication-content'></div>
          </div>
        </div>
      </span>
      <button (click)="initChat(lChat,lChat.Contact.Id)" class="btn btn-primary mt-4 mr-2" translate="init_chat"><i
          data-feather="send" appFeatherIcon class="icon-md"></i></button>
    </div>
    <hr>
  </li>

  <div id="windowContainer"></div>
</div>
