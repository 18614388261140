<form [formGroup]="innerForm">
  <div>
    <select id="{{Id}}" style="width: 100%" formControlName="field" [(ngModel)]="value.val" class="{{inputClass}}"
      [required]="field.required" k-options="optionsTemplate" [hidden]="!field.show"
      [attr.disabled]="!field.IsEditable ? true : null || field.disabled ? true : null"></select>
    <div [hidden]="!noResults" class="select-combox">
      <i class="glyphicon glyphicon-remove"></i> {{noData}}
    </div>
    <div *ngIf="formSubmitted || innerForm.controls.field.errors?.required && innerForm.controls.field.touched"
      id="required_{{field.campoId}}" class="alert alert-danger" role="alert">{{errors.required}}</div>
  </div>
</form>
