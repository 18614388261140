import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-carousel-modal',
  templateUrl: './carousel-modal.component.html',
  styleUrls: ['./carousel-modal.component.scss']
})
export class CarouselModalComponent implements OnInit {

  @Input() field: any;
  @Input() type: any;

  fileUrl = "=";
  isMultiple = false;
  
  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
