import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { CarouselModalComponent } from '@components/carousel/carousel-modal/carousel-modal.component';

import { UploadService } from '@services/upload.service';

import backandGlobal from '@env/env';
import { DocumentsService } from '@services/documents.service';
import { NotificationsService } from '@services/notifications.service';
import { Constants } from '@common/constants';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements OnInit {

  @Input() field: any;
  @Input() value: any;
  @Input() form: any;
  @Input() inputClass: string;
  @Input() errors: string;

  loop = true;
  slideShow = true;
  showNavButtons = true;
  showIndicator = true;
  Video: any = null;
  Image: any = null;
  slideshowDelay = 6000;
  showCarrusel = false;
  minFiles = false;
  imgSelectedShow = false;
  filesMemory = [];
  isFistGallery = false;
  isNew = true;
  itemSelect = null;
  extensions = '';
  galleryFiles = [];
  dataMinFiles = [];
  galleryOptions: any;
  imgSelected = '';

  constructor(
    private modalService: NgbModal,
    private uploadService: UploadService,
    private documentsService: DocumentsService,
    private notificationsService: NotificationsService
  ) { }

  ngOnInit(): void {
    if (this.field.Format == 'Image' || this.field.Format === null) {
      this.extensions = '.png,.jpg,.jpeg';
    } else if (this.field.Format == 'Video') {
      this.extensions = '.mp4,.wmv';
    } else if (this.field.Format == 'Image/Video') {
      this.extensions = '.png,.jpg,.jpeg,.mp4,.wmv';
    }

    this.ValidatePermissInForm();

    if (this.field.value.val) {
      var arrayImage = this.field.value.val.replace(']', '').replace('[', '').split(',');
      arrayImage.forEach((img, i) => {
        arrayImage[i] = img.replace('"', '').replace('"', '');
      });
      this.filesMemory = arrayImage;
      this.buildMinFiles();
      this.showCarrusel = true;
      this.minFiles = true;
      this.isNew = false;
      this.itemSelect = this.galleryFiles[0];
    } else {
      this.galleryOptions = {
        dataSource: this.galleryFiles,
        height: 250,
        bindingOptions: {
          slideshowDelay: 'slideshowDelay',
          loop: 'loop',
          showNavButtons: 'showNavButtons',
          showIndicator: 'showIndicator',
        },
        itemTemplate(data) {
          var extension = data.split('.')[data.split('.').length - 1];
          var result;
          if (extension === 'mp4' || extension === 'wmv') {
            result = `<div><video src="${data}" class="carrosuel-height img-fluid" controls></video></div>`;
          } else {
            result = `<div style="height: inherit;"><img style="height: inherit;" src="${data}" class="carrosuel-height img-fluid" /></div>`;
          }
          return result;
        },
        selectedItem: this.itemSelect,
      };

      setTimeout(() => {
        $(`#gallery_${this.field.campoId}`).dxGallery(this.galleryOptions);
      }, 100);
    }
  }

  buildValue() {
    if (this.filesMemory.length == 0) this.field.value.val = '';
    else {
      this.field.value.val = '[';
      this.filesMemory.forEach((fileInMemory, index) => {
        this.field.value.val += fileInMemory;
        if (index + 1 != this.filesMemory.length) this.field.value.val += ',';
      });

      this.field.value.val += ']';
    }
  }


  async pushDataCarrusel(name, type, url) {
    let currentApp = JSON.parse(sessionStorage.getItem('currentApp'));
    if (currentApp.storageProvider && currentApp.storageProvider == 'AzureBlobStorage')
      url = `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api/documents/viewImage/${url.replaceAll("/", "|")}`;
    else if(currentApp.storageProvider) {
      let data = await this.documentsService.downloadFile(url.replaceAll("/", "|"));
      url = JSON.parse(data).url;
    } else url = backandGlobal.URL_API_UPLOAD_IMAGES + url;
    let isEdited = false;
    this.dataMinFiles.forEach(x => {
      if (x.name == name) {
        x.type = type;
        x.url = url;
        isEdited = true;
        return;
      }
    });
    if (!isEdited) {
      this.dataMinFiles.push({ name, type, url });
    }
    this.galleryFiles.push(url);
    this.loadCarrusel();
  }

  loadCarrusel() {
    this.galleryOptions = {
      dataSource: this.galleryFiles,
      height: 250,
      bindingOptions: {
        slideshowDelay: 'slideshowDelay',
        loop: 'loop',
        showNavButtons: 'showNavButtons',
        showIndicator: 'showIndicator',
      },
      itemTemplate(data) {
        var extension = data.split('.')[data.split('.').length - 1];
        var result;
        if (extension === 'mp4' || extension === 'wmv') {
          result = `<div><video src="${data}" class="carrosuel-height img-fluid" controls></video></div>`;
        } else {
          result = `<div style="height: inherit;"><img style="height: inherit;" src="${data}" class="carrosuel-height img-fluid" /></div>`;
        }
        return result;
      },
      selectedItem: this.itemSelect,
    };
    setTimeout(() => {
      $(`#gallery_${this.field.campoId}`).dxGallery(this.galleryOptions);
    }, 100);
  }

  buildMinFiles() {
    this.galleryFiles = [];
    // this.dataMinFiles = [];
    this.showCarrusel = false;
    this.minFiles = false;

    if (this.filesMemory.length > 0) {
      this.showCarrusel = true;
      this.minFiles = true;
    }

    var type = 'Image';
    this.filesMemory.forEach(async (img, i) => {
      var ext = this.filesMemory[i].split('.')[this.filesMemory[i].split('.').length - 1];
      if (ext === 'mp4' || ext === 'wmv') type = 'Video';
      let name = img.split("/")[img.split("/").length - 1];
      this.pushDataCarrusel(name, type, img);
    })
  }

  ValidatePermissInForm() {
    if (this.field.permissions.allowCreate === '' && this.field.permissions.allowEdit === '' && this.field.permissions.allowRead === '') {
      this.field.show = false;
      this.showCarrusel = false;
      this.minFiles = false;
    } else if (this.field.permissions.allowCreate === '' && this.field.permissions.allowEdit === '' && this.field.permissions.allowRead != '') {
      this.field.show = false;
      this.showCarrusel = true;
      this.minFiles = true;
    } else if (this.field.permissions.allowCreate != '' || (this.field.permissions.allowEdit != '' && this.field.permissions.allowRead != '')) {
      this.field.show = true;
      this.showCarrusel = true;
      this.minFiles = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.slideshowDelay = changes.slideShow ? 7000 : 0;
    // changes.prop contains the old and the new value...
  }

  ContinueCarousel() {
    this.imgSelectedShow = false;
    this.showNavButtons = true;
    this.slideshowDelay = 7000;
  }

  onFileSelect($files: any) {
    $files = $files.files;
    var size = 10000000;
    var maxsize = false;
    for (let i = 0; i < $files.length; i++) {
      if ($files[i].size > size) {
        maxsize = true;
        break;
      }
    }

    if (maxsize == false) {
      if (!this.field.IsMultiple && this.filesMemory.length == 1) {
        this.notificationsService.notificationApp('warning', Constants.MESSAGES.OnlyOneUpload);
        return;
      }
      let currentApp = JSON.parse(sessionStorage.getItem('currentApp'));
      if (currentApp.storageProvider) {
          var form = new FormData();
        for (var i in $files) {
          form.append('data', $files[i]);
        }
        let entity = this.field.viewName;
        let url = `/${entity}/${this.field.Code}`        
        this.documentsService.save(form, url).then((response: any) => {
          var data = response;
          var fields = localStorage.fieldsUploaded == undefined ? [] : JSON.parse(localStorage.fieldsUploaded);
          data.forEach((value: any) => {
            let name = value.Link.split("/")[value.Link.split("/").length - 1];
            var field =  `${this.field.Code}:${value.Id}/${value.Link}`;
            fields.push(field);
            this.dataMinFiles.push({id: value.Id,  name, type: null, url: value.Link });
            this.filesMemory.push(value.Link);
          });
          localStorage.fieldsUploaded = JSON.stringify(fields);
          this.buildValue();
          this.buildMinFiles();
        })
      } else {
        this.uploadService.upload($files).then((result: any) => {
          for (var j = 0; j < result.fileNames.length; j++) {
            this.filesMemory.push(result.fileNames[j]);
          }
          this.buildValue();
          this.buildMinFiles();
        });
      }
    } else {
      this.notificationsService.notificationApp('error', Constants.MESSAGES.MaxSizeUploadFile)
    }
  }

  selectItem(file: any) {
    this.galleryFiles.forEach((fileImg, index) => {
      if (fileImg == file) {
        $(`#gallery_${this.field.campoId}`)
          .dxGallery('instance')
          .option('selectedItem', this.galleryFiles[index]);
        this.imgSelectedShow = true;
        this.imgSelected = this.galleryFiles[index];
        this.slideShow = false;
        this.slideshowDelay = 0;
      }
    });
  }

  deleteSelected(imagenDelete: string, name: string) {
    var imgToDel = imagenDelete.split('/')[imagenDelete.split('/').length - 1];
    var filesMemoryTemp = this.filesMemory;
    this.filesMemory = [];
    let index = this.dataMinFiles.findIndex(x => x.name == name);
    let Id = this.dataMinFiles[index].id;
    console.log(Id);
    this.dataMinFiles.splice(index, 1);
    for (var i = 0; i < filesMemoryTemp.length; i++) {
      if (!filesMemoryTemp[i].includes(name)) {
        this.filesMemory.push(filesMemoryTemp[i]);
      }
    }

    this.buildValue();
    this.buildMinFiles();
    this.loadCarrusel();
    this.imgSelectedShow = false;
    this.showNavButtons = true;
    this.slideshowDelay = 7000;
    if (this.galleryFiles.length < 1) {
      this.showCarrusel = false;
    }

    this.ValidatePermissInForm();
    let currentApp = JSON.parse(sessionStorage.getItem('currentApp'));
    if (currentApp.storageProvider) {
      let entity = this.field.viewName;
      this.documentsService.delete(this.changeName(`${entity}|${this.field.Code}|${name}`));
      if(Id)
        this.documentsService.destroy(Id);
    } else {
      this.uploadService.deleteFile(name);
    }
  }

  changeName(name) {
    return name.toLowerCase().split('_').join('-').split('/').join('-');
  }

  clearCarrousel() {
    //afgv todo recorrer filesarray y llamar delete
    var files = JSON.stringify(this.filesMemory);
    this.filesMemory = [];
    this.buildValue();
    this.buildMinFiles();
    this.imgSelectedShow = false;
    this.isFistGallery = true;
    let currentApp = JSON.parse(sessionStorage.getItem('currentApp'));
    if (currentApp.storageProvider) {
      this.filesMemory.forEach(x => {
        this.documentsService.delete(x.replaceAll('/', '|'));
      })
    }else{
      this.uploadService.deleteFile(files);
    }
  }

  translateErrors(errors) {
    errors.forEach((v, k) => {
      var messageFile;
      messageFile = `<ul style='padding-left:0px;'><li><span>${v.name}<span></li><li><ul style='padding-left:0px;'>`;
      if (v.$errorMessages.pattern) {
        messageFile += '<li>Tipo de archivo invalido</li>';
      }

      if (v.$errorMessages.maxSize) {
        messageFile += '<li>El tamaño no debe exceder los 100MB</li>';
      }

      messageFile += '</ul></li></ul>';
      this.notificationsService.notificationApp('warning', messageFile);

    });
  }

  async ViewModelImagen(file: any) {
    var fileUrl = [];
    fileUrl.push(file.url);
    var modalInstance = this.modalService.open(await CarouselModalComponent, {
      animation: true,
      size: 'lg',
    });

    modalInstance.componentInstance.fileUrl = fileUrl;
    modalInstance.componentInstance.isMultiple = false;
    modalInstance.componentInstance.field = false;
    modalInstance.componentInstance.type = file.type;

    await modalInstance.result.then(
      () => { },
      () => { }
    );
  }
}
