<div class="card">
  <div class="card-body card-body-lappiz">
    <div class="row w-100" style="flex-wrap: nowrap;">
      <div class="title-section  d-flex align-items-center" style="column-gap: 8px">
        <img class="btn-back" src="../../../assets/images/flecha-izquierda.png" (click)="Volver()" title="Volver">
        <h3 class="page-title">Asignar roles</h3>
      </div>
      <div class="d-flex justify-content-end">
        <div class="d-flex align-items-center flex-wrap text-nowrap">
          <button (click)="SaveChanges()" type="button" class="btn btn-primary mr-2">
            <i class="feather icon-save"></i>
          </button>
        </div>
        
      </div>
    </div>
    <hr>
    <form [formGroup]="formAssignRole" (ngSubmit)="SaveChanges()">
      <div class="form-container">
        <div class="form-item col-12 col-sm-12 col-md-6 p-1">
          <label class="control-label">
            <span class="ng-binding">Usuarios</span>
          </label>
          <kendo-dropdownlist [valueField]="'Id'" [defaultItem]="defaultValue" [textField]="'Email'" [filterable]="true"
            (filterChange)="filter($event)" [data]="groupedData" style="width: 100%" (valueChange)="getRoles($event)" [virtual]="virtual">
          </kendo-dropdownlist>
        </div>
        <div class="form-item col-12 col-sm-12 col-md-6 p-1">
          <label class="control-label">
            <span class="ng-binding">Roles</span>
          </label>
          <select id='multiselect'></select>
        </div>
      </div>
    </form>
  </div>
</div>
