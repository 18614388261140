import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { ProxyConfigApiService } from '@services/proxy-config-api.service';

import backandGlobal from '@env/env';
import { GlobalEventsService } from '@services/global-events.service';
import { IRol } from 'src/app/interfaces/IRol';
import { NotificationsService } from '@services/notifications.service';
import { Constants } from '@common/constants';

@Component({
  selector: 'app-roles',
  templateUrl: './roles.component.html',
  styleUrls: ['./roles.component.scss'],
})
export class RolesComponent implements OnInit {

  appViewId: any;
  appId: String;

  public gridView: any[] = [];
  public gridViewTotla: any[] = [];

  constructor(
    private proxyConfigApiService: ProxyConfigApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private globalEvents: GlobalEventsService,
    private notificationsServices: NotificationsService
  ) {
    this.activatedRoute.paramMap.subscribe((params) => {
      this.appViewId = params.get('appViewId');
    });
  }

  public ngOnInit(): void {
    this.proxyConfigApiService.getEvents('RolesLoaded').then((config: any) => {
      this.rolesdLoaded(config);
    })
    this.getRoles();
    $('#filter').on('input', (e: any) => {
      var value = e.target.value?.toLowerCase();
      this.gridView = this.gridViewTotla.filter(x => x.Nombre?.toLowerCase().includes(value) || x.Descripcion?.toLowerCase().includes(value) || ("inactivo".includes(value) && !x.Status) || ("activo".includes(value) && x.Status));
    });
  }

  rolesdLoaded(config) {
    let params = {
      cancel: false,
    }
    this.globalEvents.fireEvents('RolesLoaded', config, params);
  }

  getRoles(): void {
    this.appId = sessionStorage.workspaceId;
    let UrlService = backandGlobal.url + '/api/Roles/getRoles/' + this.appId;

    this.proxyConfigApiService
      .getConfigDataGet(UrlService)
      .then((roleResult) => {
        this.gridView = roleResult.sort(function (a, b) {
          if (a.Nombre > b.Nombre) {
            return 1;
          }
          if (a.Nombre < b.Nombre) {
            return -1;
          }

          return 0;
        });
        this.gridViewTotla = this.gridView;
      })
      .catch((error) => {
        this.notificationsServices.notificationApp('info', Constants.MESSAGES.FailLoadRoles);
      })
      .finally(() => {
        document
          .querySelectorAll(
            '#GridRoles > div > div > div > table > thead > tr > th:nth-child(n) > span.k-link.ng-star-inserted'
          )
          .forEach(
            (col: any) => (col.style.color = $(':root').css('--panel-color'))
          );
      });
  }

  createRole(): void {
    this.router.navigateByUrl('/createrol');
  }

  RedirectEvent(args: any): void {
    let auxLink = '/permisos?idRol=';
    let idRol = args.id;
    this.router.navigateByUrl(auxLink + idRol);
  }

  redirectToEdit($event: any) {
    let auxLink = '/createrol?idRol=';
    let idRol = $event.dataItem.id;
    this.router.navigateByUrl(auxLink + idRol);
  }

  async Delete($event: any) {
    let message = '¿Está seguro que desea eliminar este registro?';
    let result = await this.notificationsServices.confirmAlert('warning', Constants.MESSAGES.confirm,);
    if (!result) {
      return
    }

    let idRol = $event.dataItem.id;
    let url = backandGlobal.url + '/api/Roles/deleteRol/' + idRol;
    await this.proxyConfigApiService.deleteConfigRol(url).then(() => {
      var message = 'Eliminado correcto';
      this.ngOnInit();
      this.notificationsServices.notificationApp('success', Constants.MESSAGES.SuccessDelete);
    }).catch((error: any) => {
      if (error.error.ExceptionMessage == "Rol con usuarios asignados.") {
        this.notificationsServices.notificationApp('error', Constants.MESSAGES.UserRolAlReady);
      }
    });
  }

  asignarRol(): void {
    this.router.navigateByUrl('/rolesxusers');
  }

  async ToggleStatus(args: IRol) {
    try {
      // Check for user association with role
      if(args.Status){
        const responseRoleWithUser = await this.proxyConfigApiService.RoleWithUser(args.id);
        if (responseRoleWithUser > 0) {
          this.notificationsServices.notificationAppWithButton('error', `No se permite inactivar el rol ${args.Nombre} ya que tiene usuarios vinculados`);
          return;
        }
      }
  
      // Confirmation prompt
      const confirmation = await this.notificationsServices.confirmAlert('warning', `¿Está seguro de ${args.Status ? "inactivar" : "activar"} el rol ${args.Nombre}?`);
      if (!confirmation) return;
  
      // Toggle status and update UI
      const updatedRol = await this.proxyConfigApiService.ToggleStatusRol(args.id);
      this.gridView.find(x => x.id === args.id).Status = updatedRol.Status;
  
      const successMessage = updatedRol.Status === true
        ? Constants.MESSAGES.ActivateRol
        : Constants.MESSAGES.InactivateRol;
      this.notificationsServices.notificationApp('success', successMessage);
    } catch (error) {
      this.notificationsServices.notificationApp('error', Constants.MESSAGES.FailToggleStatusRol);
      console.error('Error toggling status:', error); // Log the error for debugging
    }
  }
}
