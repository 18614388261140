import { Injectable } from '@angular/core';

import { ServerConfigApiService } from '@services/server-config-api.service';

import backandGlobal from '@env/env';
import { LocalConfigApiService } from './local-config-api.service';
import { HttpCacheService } from './http-cache.service';
import PouchDB from 'pouchdb';
import { IGlobalEvent } from '../interfaces/IGlobalEvent';
import { ILappizPage } from '../interfaces/ILappizPage';
import { EndPointService } from './end-point.service';
import { IRol } from '../interfaces/IRol';

@Injectable({
  providedIn: 'root'
})
export class ProxyConfigApiService {

  dbAppContext: any;
  constructor(
    private serverConfigApiService: ServerConfigApiService,
    private localConfigApiService: LocalConfigApiService,
    private configService: ServerConfigApiService,
    private httpCacheService: HttpCacheService,
    private endPointService: EndPointService
  ) {
    this.dbAppContext = new PouchDB('bd_AppContext');
  }

  getConfigIpUser(): Promise<any> {
    return this.serverConfigApiService.getConfigIpUser();
  }

  getConfigDataPost(url: string, data: any): Promise<any> {
    if (backandGlobal.stateOnline) {
      return this.serverConfigApiService.makeHttpPostRequest(url, data);
    }
  }

  getConfigDataGetAnonymous(url: string): Promise<any[]> {
    return this.serverConfigApiService.makeHttpGetRequest(url);
  }

  confirmEmail(url: string): Promise<any[]> {
    if (backandGlobal.stateOnline) {
      return this.serverConfigApiService.makeHttpGetRequest(url);
    }
  }

  getConfigLoginApp(url: string): Promise<any> {
    if (backandGlobal.stateOnline) {
      return this.serverConfigApiService.makeHttpGetRequest(url);
    }
  }

  getConfigRegisterApp(url: string): Promise<any[]> {
    if (backandGlobal.stateOnline) {
      return this.serverConfigApiService.makeHttpGetRequest(url);
    }
  }

  getConfigUserLogged(url: string): Promise<any[]> {
    if (backandGlobal.stateOnline) {
      return this.serverConfigApiService.makeHttpGetRequest(url);
    }
  }

  deleteConfigRol(url: string): Promise<any> {
    if (backandGlobal.stateOnline) {
      return this.serverConfigApiService.makeHttpDeleteRequest(url);
    }
  }

  configReplicateDelete(url: string): Promise<any> {
    if (backandGlobal.stateOnline) {
      return this.serverConfigApiService.makeHttpDeleteRequest(url);
    }
  }

  addAplicationUser(url: string): Promise<any> {
    if (backandGlobal.stateOnline) {
      return this.serverConfigApiService.makeHttpPostRequest(url);
    }
  }

  configReplicateUpdate(url: string, dataSend: any): Promise<any> {
    if (backandGlobal.stateOnline) {
      return this.serverConfigApiService.makeHttpPutRequest(url, JSON.stringify(dataSend));
    }
  }

  configUserInfo(url: string): Promise<any[]> {
    if (backandGlobal.stateOnline) {
      return this.serverConfigApiService.makeHttpGetRequest(url);
    }
  }

  configGetLoginLdap(url: string, dataSend: any): Promise<any> {
    if (backandGlobal.stateOnline) {
      return this.serverConfigApiService.makeHttpPostRequest(url, dataSend);
    }
  }

  configSaveRoles(url: string, data: any): Promise<any> {
    if (backandGlobal.stateOnline) {
      return this.serverConfigApiService.makeHttpPostRequest(url, data);
    }
  }

  configPutRoles(url: string, data: any): Promise<any> {
    if (backandGlobal.stateOnline) {
      return this.serverConfigApiService.makeHttpPutRequest(url, data);
    }
  }

  configSendEmail(url: string, dataSend: any): Promise<any> {
    if (backandGlobal.stateOnline) {
      return this.serverConfigApiService.makeHttpPostRequest(url, dataSend);
    }
  }

  configRecovery(url: string, dataSend: any): Promise<any> {
    if (backandGlobal.stateOnline) {
      return this.serverConfigApiService.makeHttpPostRequest(url, dataSend);
    }
  }

  configSubmitCDN(url: string): Promise<any> {
    if (backandGlobal.stateOnline) {
      return this.serverConfigApiService.makeHttpPostRequest(url);
    }
  }

  configRecoverySave(url: string): Promise<any> {
    if (backandGlobal.stateOnline) {
      return this.serverConfigApiService.makeHttpPostRequest(url);
    }
  }

  configSignIn(url: string, userName: string, password: string, appName: string, successCallback: any, errorCallback: any, twoFactor: boolean): Promise<any> {
    if (backandGlobal.stateOnline) {
      return this.serverConfigApiService.configSignIn(url, userName, password, appName, successCallback, errorCallback, twoFactor);
    }
  }

  sendOTP(url: string): Promise<any> {
    if (backandGlobal.stateOnline) {
      return this.serverConfigApiService.makeHttpGetRequest(url);
    }
  }

  configReplicateUser(url: string, dataItem: any): Promise<any> {
    if (backandGlobal.stateOnline) {
      return this.serverConfigApiService.makeHttpPostRequest(url, dataItem);
    }
  }

  configRegisterExternalLogin(url: string, dataItem: any): Promise<any> {
    if (backandGlobal.stateOnline) {
      return this.serverConfigApiService.makeHttpPostRequest(url, dataItem);
    }
  }

  addPushSubscriber(sub: any, userId: string, url: string) {
    var jsonSub = sub.toJSON();
    var data = {
      "UserId": userId,
      "EndPoint": jsonSub.endpoint,
      "ExpirationTime": jsonSub.expirationTime,
      "P256dh": jsonSub.keys.p256dh,
      "Auth": jsonSub.keys.auth
    }

    return this.serverConfigApiService.makeHttpPostRequest(url, data);
  }

  async getSuscriptions(users: string, url: string) {
    url += `/${users}`;

    return await this.serverConfigApiService.makeHttpGetRequest(url);
  }

  getAdminProfiles(url: string): Promise<any[]> {
    return this.serverConfigApiService.makeHttpGetRequest(url);
  }

  getStatusContract(url: string): Promise<any[]> {
    return this.serverConfigApiService.makeHttpGetRequest(url);
  }

  getStatusPay(url: string): Promise<any[]> {
    return this.serverConfigApiService.makeHttpGetRequest(url);
  }

  getAuthTypeUser(url: string): Promise<any[]> {
    return this.serverConfigApiService.makeHttpGetRequest(url)
  }

  getEvents(typeEvent: string): Promise<IGlobalEvent[]> {
    let newUrl = `${backandGlobal.url}/api/Aplicacions/getEvents/${typeEvent}/${sessionStorage.aplicattionuserid}`
    return this.serverConfigApiService.makeHttpGetRequest(newUrl);
  }

  async getViewConfig(params: any, callback: any) {
    if (backandGlobal.stateOnline) {
      return this.configService.read(params, callback);
    } else {
      return this.localConfigApiService.getViewConfig(params, callback);
    }
  }

  async getVersionCache(applicationId: string) {
    if (backandGlobal.stateOnline) {
      const versionCache: any = await this.serverConfigApiService.makeHttpGetRequest(`${backandGlobal.url}/api/aplicaciones/version/${applicationId}`);
      return versionCache;
    } else {
      return sessionStorage.versionCache;
    }
  }

  async getConfigApp(applicationId: string) {
    const url = `${backandGlobal.url}/api/app/config?workspaceId=${applicationId}`;
    const versionCache: any = await this.getVersionCache(applicationId);
    let response = await this.httpCacheService.get(applicationId, versionCache);
    if (response) return response;
    if (backandGlobal.currentApp && backandGlobal.currentApp?.id === applicationId) {
      response = { workspace: backandGlobal.currentApp, versioncache: backandGlobal.currentApp.versioncache };
      this.httpCacheService.succesMessage();
    }
    if (!backandGlobal.stateOnline) {
      response = await this.localConfigApiService.getConfigDataGet('currentApp');
      this.httpCacheService.succesMessage();
    } else if (!response || versionCache != response?.versioncache) {
      response = await this.serverConfigApiService.makeHttpGetRequest(url);
      response = await this.httpCacheService.requestToApi(url, applicationId, response);
    }
    return response;
  }

  async getUserApps(url: string): Promise<any> {
    let response = [];
    response = await this.localConfigApiService.getUserApps();
    if (response) {
      if (response.find(x => x.Id == sessionStorage.getItem('workspaceId')))
        return response;
    }
    if (backandGlobal.stateOnline) {
      response = await this.serverConfigApiService.makeHttpGetRequest(url);
    };
    const userAppsDoc = {
      _id: 'userApps',
      data: response,
      _rev: null
    };
    try {
      const existingDoc = await this.dbAppContext.get('userApps');
      let responseDB = { ok: false };
      if (existingDoc._rev)
        this.dbAppContext.remove(existingDoc);
      responseDB = await this.dbAppContext.put(userAppsDoc);
      console.log('Se actualizo el documento userApps con exito');
      if (!responseDB.ok) console.log('Error')
    } catch (error) {
      await this.dbAppContext.put(userAppsDoc);
      console.log('Se creo el documento userApps con exito');
    }
    return response;
  }

  async getallentitiesoffline(url) {
    let response = null;
    if (backandGlobal.stateOnline) {
      const versionCache = await await this.getVersionCache(sessionStorage.workspaceId);
      if (versionCache != sessionStorage.versionCache || !localStorage.EntityNames)
        response = await this.serverConfigApiService.makeHttpGetRequest(url);
      else
        response = JSON.parse(localStorage.EntityNames);
      localStorage.EntityNames = JSON.stringify(response);
      return response;
    } else {
      response = localStorage.EntityNames;
      if (!response) return [];
      return JSON.parse(response);
    }
  }

  async getConfigDataGet(url: string): Promise<any[]> {
    if (backandGlobal.stateOnline) {
      let response = await this.serverConfigApiService.makeHttpGetRequest(url);
      return response;
    } else {
      // const urlCahce = `${backandGlobal.routeCache}/${dbName}config/`
      let response = await this.localConfigApiService.getConfigDataGet('currentApp');
      return response;
    }
  }

  async getUserInfo(url: string) {
    let response = await this.localConfigApiService.getUserInfo();
    if (!response && backandGlobal.stateOnline) {
      response = await this.serverConfigApiService.makeHttpGetRequest(url);
    }
    const userId = sessionStorage.getItem('userId');
    // const aplicationId = sessionStorage.getItem('workspaceId');
    const empresaId = sessionStorage.getItem('empresaId');
    if ((response.UserId != userId || response.EmpresaId != empresaId) && backandGlobal.stateOnline) {
      response = await this.serverConfigApiService.makeHttpGetRequest(url);
    }
    const userInfoDoc = {
      _id: 'userInfo',
      data: response,
      _rev: null
    };
    try {
      const existingDoc = await this.dbAppContext.get('userInfo');
      let responseDB = { ok: false };
      if (existingDoc._rev)
        this.dbAppContext.remove(existingDoc);
      responseDB = await this.dbAppContext.put(userInfoDoc);
      console.log('Se actualizo el documento userInfo con exito');
      if (!responseDB.ok) console.log('Error')
    } catch (error) {
      await this.dbAppContext.put(userInfoDoc);
      console.log('Se creo el documento userInfo con exito');
    }
    return response;
  }

  async getConfigLappizPages(lappizPage: ILappizPage): Promise<ILappizPage> {
    if (backandGlobal.stateOnline) {
      let url = this.endPointService.getLappizPage(lappizPage.Slug, lappizPage.AppName) 
      return this.serverConfigApiService.makeHttpPostRequest(url, lappizPage);
    } else {
      this.localConfigApiService.getConfigLappizPages(lappizPage, (response: ILappizPage)=>{
        return response;
      });
    }
    return lappizPage;
  }

  public ToggleStatusRol(id: string):Promise<IRol> {
    return this.serverConfigApiService.makeHttpPostRequest(this.endPointService.ToggleStatusRol(id));
  }
  
  public RoleWithUser(id: string):Promise<number> {
    return this.serverConfigApiService.makeHttpPostRequest(this.endPointService.RoleWithUser(id));
  } 

  public NotificationChangedPassword(url): Promise<void>{
    return this.serverConfigApiService.makeHttpPostRequest(url);
  }
}
