import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import backandGlobal from '@env/env';
import { HttpCacheService } from './http-cache.service';

@Injectable({
  providedIn: 'root'
})
export class ServerConfigApiService {
  private readonly jsonip: string = 'https://jsonip.com/'

  constructor(private http: HttpClient,
    private httpCacheService: HttpCacheService ) {}

  private loadToken = (): string => localStorage.getItem('Authorization') ?? '';

  private getHeaders = (): HttpHeaders =>
    new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: this.loadToken()
    });

  public async getConfigIpUser(): Promise<any> {
    return this.http.get(this.jsonip).toPromise();
  }

  public async makeHttpGetRequest(url: string): Promise<any[]> {
    return this.http.get<any[]>(url, { headers: this.getHeaders() }).toPromise();
  }

  public async makeHttpPutRequest(url: string, data: any): Promise<any> {
    return this.http.put(url, data, { headers: this.getHeaders() }).toPromise();
  }

  public async makeHttpPatchRequest(url: string, data?: any): Promise<object> {
    if (!data) {
      return this.http.patch<object>(url, null, { headers: this.getHeaders() }).toPromise();
    } else {
      return this.http.patch<object>(url, data, { headers: this.getHeaders() }).toPromise();
    }
  }

  public async makeHttpOptionsRequest(url: string): Promise<any> {
    const httpHeaders: HttpHeaders = new HttpHeaders({
      'Content-Type': 'application/json',
    })
    return this.http.options(url, { headers: httpHeaders }).toPromise();
  }

  public async makeHttpDeleteRequest(url: string): Promise<any> {
    return this.http.delete(url, { headers: this.getHeaders() }).toPromise();
  }

  public async makeHttpPostRequest(url: string, data?: any): Promise<any> {
    if(!data) {
      return this.http.post<any>(url, { headers: this.getHeaders() }).toPromise();
    }
    else {
      return this.http.post<any>(url, data, { headers: this.getHeaders() }).toPromise();
    }
  }

  public async configSignIn(url: string, userName: string, password: string, appName: string, successCallback: any, errorCallback: any, twoFacto: boolean): Promise<any> {
    const headers = new HttpHeaders({ 'Content-Type': 'application/x-www-form-urlencoded' });
    appName = twoFacto ? `${appName},twoFactor` : appName;
    // Usamos encodeURIComponent para codificar cada parámetro
    const bodyLogin = new HttpParams()
      .set('username', userName)
      .set('password', encodeURIComponent(password))
      .set('grant_type', 'password')
      .set('scope',appName);
  
    let request = this.http.post<any>(url, bodyLogin.toString(), {
      headers,
      observe: 'response'
    }).toPromise();
    request.then((resolve) => {
      let data: any = resolve.body;
      let status: any = resolve.status;
      let headers: any = resolve.headers;
      let config: any = resolve;
      successCallback(data, status, headers, config);
    }).catch(error => {
      errorCallback(error);
    });
  
    return request;
  }

  async read(params: any, callback: any) {
    let url = `${backandGlobal.url}/api/:dataType/config/:id/:entityId/:appViewId`;
    if (localStorage.getItem('Authorization') == null) {
      url = `${backandGlobal.url}/api/:dataType/confanonym/:id/:entityId/:appViewId`;
    }
    url = url.replace(':dataType', params.dataType ? params.dataType : 'view');
    url = url.replace(':id', params.id ? params.id : '');
    url = url.replace(':entityId', params.entityId ? params.entityId : '');
    url = url.replace(':appViewId', params.appViewId ? params.appViewId : '');

    let cacheId = params.id + params.dataType + params.entityId + params.appViewId;
    const versionCache = sessionStorage.versionCache;
    if (!this.loadToken()) {
      let response = await this.makeHttpGetRequest(url);
      return callback(response);
    } else {
      let response = await this.httpCacheService.get(cacheId, versionCache);
      if(response) return callback(response);
      response = await this.makeHttpGetRequest(url);
      return callback(response);
    }
  }
}
