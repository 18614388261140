<div class="card py-2">
  <div class="card-body card-body-lappiz">
    <div class="row" style="flex-wrap: nowrap;">
      <div class="title-section">
        <h3 class="page-title">Roles</h3>
      </div>
      <div class="d-flex justify-content-end w-100">
        <div class="d-flex align-items-center flex-wrap text-nowrap">
          <button (click)="createRole()" type="button" class="btn btn-outline-primary btn-icon-text mr-2 mb-md-0">
            <i class="feather icon-user" data-feather="users" appFeatherIcon></i>
            Crear rol
          </button>
        </div>
        <div class="d-flex align-items-center flex-wrap text-nowrap">
          <button (click)="asignarRol()" type="button" class="btn btn-outline-primary btn-icon-text mr-2 mb-md-0">
            <i class="feather icon-navigation" data-feather="navigation" appFeatherIcon></i>
            Asignar rol
          </button>
        </div>
      </div>
      <hr>
    </div>
    <div>
      <div class="input-container">
        <i class="fa fa-search input-icon"></i>
        <input type="search" class="input-basic input-expand" id="filter"
          placeholder="Filtrar roles" />
      </div>
    </div>
    <br><br>
    <kendo-grid [selectable]="true" [kendoGridBinding]="gridView" id='GridRoles' (edit)="redirectToEdit($event)"
      (remove)="Delete($event)" [pageSize]="10" [pageable]="true" [sortable]="true" [groupable]="false"
      [reorderable]="true" [columnMenu]="{ filter: true }" [resizable]="true">
      <kendo-grid-messages  groupPanelEmpty="Arrastre el título de una columna y suéltelo aquí para agrupar por ese criterio"
      noRecords="No hay datos disponibles."
      pagerFirstPage="Ir a la primera página"
      pagerPreviousPage="Ir a la página anterior"
      pagerNextPage="Ir a la página siguiente"
      pagerLastPage="Ir a la última página"
      pagerPage="Página"
      pagerOf="de"
      pagerItems="ítems"
      pagerItemsPerPage="ítems por página"
      pagerPageNumberInputTitle="Número de Página"
      filter="Filtrar"
      filterEqOperator="Es igual a"
      filterNotEqOperator="No es igual a"
      filterIsNullOperator="Es nulo"
      filterIsNotNullOperator="No es nulo"
      filterIsEmptyOperator="Está vacío"
      filterIsNotEmptyOperator="No está vacío"
      filterStartsWithOperator="Comienza con"
      filterContainsOperator="Contiene"
      filterNotContainsOperator="No contiene"
      filterEndsWithOperator="Termina en"
      filterGteOperator="Es mayor o igual que"
      filterGtOperator="Es mayor que"
      filterLteOperator="Es menor o igual que"
      filterLtOperator="Es menor o igual que"
      filterIsTrue="Activo"
      filterIsFalse="Inactivo"
      filterBooleanAll="(Todas)"
      filterAfterOrEqualOperator="Es posterior o igual a"
      filterAfterOperator="Es posterior"
      filterBeforeOperator="Es anterior"
      filterBeforeOrEqualOperator="Es anterior o igual a"
      filterFilterButton="Filtrar"
      filterClearButton="Limpiar filtros"
      filterAndLogic="Y"
      filterOrLogic="O"
      filterDateToggle="Mostrar calendario"
      filterDateToday="Hoy"
      filterNumericDecrement="Disminuir valor"
      filterNumericIncrement="Incrementar valor"
      loading="Cargando"
      columns="Columnas"
      lock="Bloquear"
      unlock="Desbloquear"
      stick="Stick"
      unstick="Unstick"
      setColumnPosition="Set Column Position"
      sortAscending="Orden ascendente"
      sortDescending="Orden descendente"
      columnsApply="Aplicar"
      columnsReset="Defecto"
      sortable="Ordenable"
      sortedAscending="Ordenado ascendentemente"
      sortedDescending="Ordenado descendentemente"
      sortedDefault="No ordenado"
      filterInputLabel="'{columnName} Filtro'"
      filterMenuTitle="'{columnName} Menú de Filtro'"
      filterMenuOperatorsDropDownLabel="'{columnName} Operadores de Filtro'"
      filterMenuLogicDropDownLabel="'{columnName} Lógica de Filtro'"
      columnMenu="'{columnName} Menú de Columna'"
      selectionCheckboxLabel="Seleccionar Fila"
      selectAllCheckboxLabel="Seleccionar Todas las Filas"
      pagerLabel="'Navegación de página, página {currentPage} de {totalPages}'"
      gridLabel="Tabla de datos"
      groupCollapse="Contraer Grupo"
      groupExpand="Expandir Grupo"
      detailCollapse="Contraer Detalles"
      detailExpand="Expandir Detalles"
      autosizeThisColumn="Ajustar tamaño de esta columna"
      autosizeAllColumns="Ajustar tamaño de todas las columnas"
      selectPage="Seleccionar página"
      topToolbarLabel="Barra de herramientas superior"
      bottomToolbarLabel="Barra de herramientas inferior"
      groupPanelLabel="Panel de grupo"
      dragRowHandleLabel="Arrastrar fila"
      selectRowCheckboxLabel="Seleccionar fila"
      selectAllRowsCheckboxLabel="Seleccionar todas las filas"
      filterCellOperatorLabel="'Operadores de celda de filtro para {columnName}'"
      booleanFilterCellLabel="'Celda de filtro booleano para {columnName}'"
      columnMenuFilterTabTitle="Filtro"
      columnMenuGeneralTabTitle="General"
      columnMenuColumnsTabTitle="Columnas"
      groupChipMenuPrevious="Mover antes"
      groupChipMenuNext="Mover después"></kendo-grid-messages>
      <kendo-grid-command-column field="id" title="Acciones" [minResizableWidth]="50" [width]="150">
        <ng-template kendoGridCellTemplate let-dataItem>
          <div class="btn-group">
            <a class="btn btn-xs k-grid-remove-command" style="padding: 0 !important;" [title]="dataItem.Status ? 'Inactivar' : 'Activar'" (click)="ToggleStatus(dataItem)">
              <i class="fa fa-check" *ngIf="!dataItem.Status"></i>
              <i class="fa fa-ban" *ngIf="dataItem.Status"></i>
            </a>
            <a class="btn btn-xs" style="padding: 0 !important;" kendoGridEditCommand title="Editar">
              <i class="feather icon-edit"></i>
            </a>
            <a class="btn btn-xs" style="padding: 0 !important;" kendoGridRemoveCommand title="Eliminar">
              <i class="feather icon-trash"></i>
            </a>
            <a class="btn btn-xs k-grid-remove-command" style="padding: 0 !important;" (click)="RedirectEvent(dataItem)">
              Permisos
            </a>
          </div>
        </ng-template>
      </kendo-grid-command-column>
      <kendo-grid-column [hidden]="true" field="id" title="idRol"></kendo-grid-column>
      <kendo-grid-column field="Nombre" title="Nombre rol" [width]="220"></kendo-grid-column>
      <kendo-grid-column field="Descripcion" title="Descripcion" [width]="220"></kendo-grid-column>
      <kendo-grid-column field="Status" title="Estado" [width]="220" filter="boolean">
        <ng-template kendoGridCellTemplate let-dataItem>
          {{dataItem.Status == true ? "Activo" : "Inactivo"}}
        </ng-template>
      </kendo-grid-column>
    </kendo-grid>
  </div>
</div>
