import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { ApplicationService } from './services/application.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent implements OnInit {
  title = 'Lappiz Runtime';

  constructor(private applicationService: ApplicationService, private router: Router, private zone: NgZone) {
    this.initializeApp()
  }

  initializeApp() {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
        this.zone.run(() => {
          //cuando se quite el hash de las rutas se debe cambiar el parámetro del split por .io que es la 
          //terminación del dominio
            const slug = event.url.split("/#/").pop();
            if (slug) {
                this.router.navigate([slug]);
            }
        });
    });
}

  public ngOnInit(): void {
  }

  private preventBeforeunload(): void {
    window.addEventListener('beforeunload', (event: Event) => {
      event.preventDefault()
      if (!navigator.onLine) {
        this.openCustomModal()
      }
    });
  }

  private listenerWhenOffline(): void {
    window.addEventListener('offline', () => {
      if (!navigator.onLine) {
        try {
          console.log("You are off");
          // TODO: show component offline
        } catch (error) {
          console.log(error);
        }
      }
    });
  }

  private listenerWhenOnline(): void {
    window.addEventListener('online', () => {
      console.log("you're online");

    })
  }

  private openCustomModal(): void {
    const headerTemplate: string = "";
    const bodyTemplate: string = `<h4 class='modal-title text-center mx-auto'>Estás offLine, si recargas, perderás la sesión actual</h4>`;
    const footerTemplate: string = "<p class='text-muted'></p>";
    const showBtnsFooter: boolean = true;
    const config = {
      htmlTemplate: true,
      headerTemplate,
      bodyTemplate,
      footerTemplate,
      showBtnsFooter,
      size: "xl",
      scrollable: true,
      centered: true,
      keyboard: true,
    }
    const done = () => window.location.reload();
    const cancel = () => {};
    this.applicationService.openCustomModal(config, done, cancel);
  }
}
