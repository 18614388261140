import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

import backandGlobal from '@env/env';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DocumentsService {
  urlTx: string;
  tokenAuth: string;

  constructor(private http: HttpClient) {
    this.urlTx = `${backandGlobal.api2}/${sessionStorage.getItem(
      'workspace'
    )}.api/api`;
    this.tokenAuth = localStorage.getItem('Authorization') || '';
  }

  async downloadFile(nameBlob): Promise<any> {
    const url = `${this.urlTx}/documents/downloadFile/${nameBlob}`;
    const headers = new HttpHeaders({ Authorization: this.tokenAuth });
    return this.http.post(url, {}, { headers, responseType: 'text',}).toPromise();
  }
  
  get(id: string): Promise<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.tokenAuth,
      }),
    };

    return this.http
      .get<any[]>(`${this.urlTx}/documents/${id}`, httpOptions)
      .toPromise();
  }
  
  destroy(param: string): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.tokenAuth,
      }),
    };
    let url = `${this.urlTx}/documents/${param}`;
    return this.http.delete(url).toPromise();
  }

  delete(param: string): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.tokenAuth,
      }),
    };
    let currentApp = JSON.parse(sessionStorage.getItem('currentApp'))
    let url = '';
    if(!currentApp.storageProvider){
      return this.destroy(param);
    }else{
      url = `${this.urlTx}/documents/delete/${param}`;
      return this.http.post(url,null, httpOptions).toPromise();
    }
  }

  save(form: FormData, url: string): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        Authorization: this.tokenAuth,
      }),
    };
    let currentApp = null;
      if(sessionStorage.getItem('currentApp'))
        currentApp = JSON.parse(sessionStorage.getItem('currentApp'));
    
    if(!currentApp?.storageProvider){
      url = `${this.urlTx}/documents`;
    }else{
      url = `${this.urlTx}/documents/upload` + url;
    }
    if(sessionStorage.getItem('AnonimousURL')){
      url = sessionStorage.getItem('AnonimousURL');
    }
    return this.http
      .post(url, form, httpOptions)
      .toPromise();
  }
}
