import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  date: Date;
  @Input() colorFooter: string;
  @Input() backgroundFooter: string;

  constructor() {
    this.date = new Date();
    this.colorFooter = 'Default';
    this.backgroundFooter = 'Default';
  }

  ngOnInit(): void { }

}
