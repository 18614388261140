import { Injectable, Renderer2, Type } from '@angular/core';
import { TypeService } from './type.service';

@Injectable({
  providedIn: 'root',
})
export class DelayService {
  constructor() {}

  public async waitAsync(milliseconds: number): Promise<void> {
    await DelayService.wait(milliseconds);
  }

  public static wait(milliseconds: number): Promise<void> {
    if (TypeService.isNumber(milliseconds)) {
      return new Promise((resolve) => setTimeout(resolve, milliseconds));
    } else {
      throw new Error(`Delay.wait expect a number as parameter`);
    }
  }
}
