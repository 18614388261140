<div [id]="field.campoId">
  <!-- Button trigger modal -->
  <button style="width: 100%;" class="btn btn-primary btn-lappiz" (click)="addFiles(lgModal)">Seleccionar
    archivos</button>

  <!-- Modal -->
  <ng-template #lgModal>
    <div class="modal-header">
      <div class="col-xs-6 active-color">
        <h4>{{field.displayName}}</h4>
      </div>
      <div class="col-xs-6" style="text-align:right">
        <i id="btnUpload" class="mdi mdi-upload button-header active-color icon" title="Subir archivo"
          (click)="saveFiles()" [hidden]="!canUpload || isViewItem" style="margin-right:1em;"></i>
        <i class="mdi mdi-undo button-header active-color icon" (click)="closeDocuments()" title="Cerrar"></i>
      </div>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12">
          <div id="grid"></div>
          <label class="control-label" id='labelSelect' style="margin-top:2em;"
            translate="selectFiles_uploadFiles"></label>
          <div class="file-input-new" id='btnFind' [hidden]="isViewItem">
            <div class="input-group" style="width:100%">
              <div tabindex="500" class="form-control file-caption file-input">
                <div class="file-caption-name" id="NombreArchivo" [hidden]="!visibleUnload">
                  <span class='mdi mdi-file kv-caption-icon' style='display:inline-block'></span>
                  {{files.length}} Documento(s) pendientes de subir
                </div>
              </div>
              <div class="input-group-btn" style="height: auto">
                <div tabindex="500" class="btn btn-primary btn-file btn-lappiz active active-border" style="height: 2.5rem;">
                  <i class="mdi mdi-folder-open"></i>
                  Buscar archivo
                  <input id="files" type="file" class="file" multiple (change)="handleFilesSelect($event)">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </ng-template>
  <!-- Close result -->
  <p *ngIf="basicModalCloseResult != ''" class="mt-2">{{basicModalCloseResult}}</p>
</div>
