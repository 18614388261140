import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
@Component({
  selector: 'app-slide-description',
  templateUrl: './slide-description.component.html',
  styleUrls: ['./slide-description.component.scss']
})
export class SlideDescriptionComponent implements OnInit {

  @Input() slideData: any[];
  @Input() interval: any;
  @Input() noWrapSlides: any;
  @Input() active: any;
  @Input() slides: any;
  @Input() field: any;
  @Input() value: any;
  @Input() form: any;
  @Input() inputClass: any;
  @Input() errors: any;
  @Input() slide: any;

  constructor(private activeRouter: ActivatedRoute) { }

  ngOnInit(): void {
    this.slideData = [];
    this.interval = 3000;
    this.noWrapSlides = false;
    this.active = 0;
  }

  refreshSlide(event: any, slides: any) {
    if (slides.length <= 0) {
      $("#grid-general-slide").hide();
      return;
    }
    this.slideData = slides;
  }

}
