<div class="row">
  <div class="col-md-12 stretch-card">
    <div class="card card-lappiz">
      <div class="card-body card-body-lappiz" [style]="inputStyle == 'inForm' ? 'padding: 0px;' : ''">
        <div class="header-section my-2">
          <div class="title-section d-flex align-items-center" style="column-gap: 8px">
            <img class="btn-back" src="../../../assets/images/flecha-izquierda.png" (click)="cancelar()" *ngIf="showCancel && inputStyle != 'inForm'" title="Volver" [id]="entityId + '_back'">
            <h3 *ngIf="inputStyle != 'inForm'">{{ nameEntity }} {{ nameBack }}</h3>
            <div class="form-section-title" *ngIf="inputStyle == 'inForm'">{{ nameEntity }} {{ nameBack }}</div>
          </div>
          <div class="header-actions-section">
            <div class="container">
              <div class="row d-flex justify-content-center">
                <button class="btn btn-outline-primary crear" (click)="addRecordDetails()" *ngIf="showCreate && inputStyle == 'inForm'" title="Crear" [id]="entityId + '_create'">
                  <i class="fa fa-plus i-icon"></i> Crear
                </button>
                <button class="btn btn-outline-primary crear" (click)="addRow()" *ngIf="showCreate && inputStyle != 'inForm'" title="Crear" [id]="entityId + '_create'">
                  <i class="fa fa-plus i-icon"></i> Crear
                </button>
              </div>
            </div>
            <div class="container">
              <div class="row d-flex justify-content-center">
                <div *ngFor="let action of globalActions" style="float:right;" class="sub_menu">
                  <div [ngSwitch]="action.Type" style="z-index: 1;">
                    <button *ngSwitchCase="'Codigo'" name="{{ action.ActionId }}" (click)="globalActionCode(action)" title="{{ action.Name }}">
                      <i class="fa {{ action.IconAwesomeAction }}"></i>
                      {{ action.Name }}
                    </button>
                    <button *ngSwitchCase="'NavegarVista'" name="{{ action.ActionId }}" (click)="openDirective(action.QueryString, action)" title="{{ action.Name }}">
                      <i class="fa {{ action.IconAwesomeAction }}"></i>
                      {{ action.Name }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <hr>
        <div class="row" id="listView-general">
          <div class="col-md-12 pb-2">
            <div class="input-container">
              <i class="fa fa-search input-icon"></i>
              <input type="search" class="input-basic input-expand" id="filter"
                placeholder="Filtrar" />
            </div>
          </div>
          <div class="col-xs-12" id="add">
            <div [id]="idViewComponent" class="cards"></div>
            <div [id]="'pager_' + idViewComponent"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
