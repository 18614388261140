import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import backandGlobal from '@env/env';
import { ProxyTxApiService } from '@services/proxy-tx-api.service';
import { ToastrService } from 'ngx-toastr';
import { ServerConfigApiService } from '@services/server-config-api.service';
declare var kendo: any;
@Component({
  selector: 'app-kanban',
  templateUrl: './kanban.component.html',
  styleUrls: ['./kanban.component.scss']
})
export class KanbanComponent implements OnInit {

  project: any;
  staged: any;
  item: any;

  nameBack: string;
  isReady: boolean;
  statusProject: string;

  dataProject: any;
  dataStaged: any;
  dataItems: any;

  dataSource: DataSource;
  columnSettings: any;
  configStaged: any;
  configItem: any;
  dataCategoryField: string;
  dataDescriptionField: string;
  dataTitleField: string;
  dataStatusField: string;
  dataOrderField: string;

  appViewId: string;
  viewEntityName: string;
  entityId: string;

  filter: any;
  sort: any;

  constructor(private route: ActivatedRoute,
    private proxyTxApiService: ProxyTxApiService,
    private toastr: ToastrService,
   private configService: ServerConfigApiService) {

    this.project = { title: '', id: '', appViewId: '', name: `${sessionStorage.getItem('workspace')}_Kanban_Project` };
    this.staged = { title: '', id: '', appViewId: '', name: `${sessionStorage.getItem('workspace')}_Kanban_Staged` };
    this.item = { title: '', id: '', appViewId: '', name: `${sessionStorage.getItem('workspace')}_Kanban_Item` };
    this.columnSettings = {
      dataTextField: "CENombre",
      dataStatusField: "Activo",
      dataOrderField: "Order"
    };
    this.dataCategoryField = "Color";
    this.dataDescriptionField = "Description";
    this.dataTitleField = "CENombre";
    this.dataStatusField = "StagedFK";
    this.dataOrderField = "Order";
    this.route.queryParamMap.subscribe((params) => {
      //http://localhost:4200/#/kanban?appViewId=077da113-aa99-4de1-9c76-0fb1f983b660&idProject=F49B79EA-EC1D-4978-AD94-FCED0B8E8EC8&idStaged=123123&idItem=098374&appViewIdStaged=po6574930&appViewIdItem=as1231231
      this.project.appViewId = params.get('appViewId');
      this.project.id = params.get('idProject');
      this.staged.id = params.get('idStaged');
      this.item.id = params.get('idItem')
      this.staged.appViewId = params.get('appViewIdStaged');
      this.item.appViewId = params.get('appViewIdItem')
      this.nameBack = params.get('nameBack');
    });
    this.filter = {
      "logic": "or",
      "filters": [
        {
          "field": "Id",
          "operator": "eq",
          "value": this.project.id
        }
      ]
    };

    this.sort = [
      {
        field: "Order",
        dir: "asc"
      },
      {
        field: "Created_date",
        dir: "asc"
      }
    ]
  }

  ngOnInit(): void {
    this.buildKanban();
  }

  async buildKanban() {
    this.entityId = this.entityId;
    this.viewEntityName = this.viewEntityName;

    await this.getConfigView(this.staged.name, this.staged.id, this.staged.appViewId, 1);
    await this.getConfigView(this.item.name, this.item.id, this.item.appViewId, 2);

    let entity = this.project.name;
    this.appViewId = this.project.appViewId;
    await this.getDataEntitie(entity, 1);
    if(this.dataProject){
      this.appViewId = this.staged.appViewId;
      this.filter.filters[0].field = 'ProjectFK';
      entity = this.staged.name;
      await this.getDataEntitie(entity, 2);
      if(this.dataStaged){
        this.appViewId = this.item.appViewId;
        this.filter.filters = [];
        entity = this.item.name;
        this.dataStaged.forEach(element => {
          this.filter.filters.push({field: 'StagedFK', operator: 'eq', value: element.Id});
        });
        await this.getDataEntitie(entity, 3);
      }else{
        this.toastr.error('No se puedo obetener todos los datos, revisar la configuración.')
      }
    }else{
      this.toastr.error('No se puedo obetener los datos, revisar la configuración.')
    }
    this.createKendo();
  }

  createKendo(){
    // var crudServiceBaseUrl = "https://demos.telerik.com/kendo-ui/service";
    let schema_field_columns = {StagedFK:{}};
    let schema_field_items = {};

    Object.keys(this.dataStaged[0]).forEach(element => {
        schema_field_columns[element] = {};
    })

    Object.keys(this.dataItems[0]).forEach(element => {
      schema_field_items[element] = {};
    });

    this.dataStaged.map(element => {
      element.StagedFK = element.Id;
    })
    this.dataSource = new kendo.data.DataSource({
        data: this.dataStaged
    });

    kendo.jQuery("#taskBoard").kendoTaskBoard({
        columnSettings: this.columnSettings,
        columns: {
           data: this.dataStaged,
           schema:{
            model: {
              id: 'Id',
              fields: schema_field_columns
            }
          }
        },
        dataCategoryField: this.dataCategoryField,
        dataDescriptionField: this.dataDescriptionField,
        dataTitleField: this.dataTitleField,
        dataStatusField: this.dataStatusField,
        dataOrderField: this.dataOrderField,
        dataSource: {
            data: this.dataItems,
            schema: {
                model: {
                    id: "Id",
                    fields: schema_field_items,
                }
            }
        },
        // previewPane: {
        //     template: "<p>#:Description#</p>" +
        //         "<p>#:resources.Category.title#: <span style='background-color: #:resources.Category.color#'>&nbsp;</span> #:resources.Category.text#</p>"
        // },
        // editable: {
        //     form: {
        //         buttonsTemplate: "",
        //         items: [{
        //             field: "Title",
        //             label: "Title:"
        //         }, {
        //             field: "Description",
        //             label: "Description:"
        //         }, {
        //             field: "Category",
        //             label: "Priority:",
        //             editor: "DropDownList",
        //             // editorOptions: {
        //             //     dataTextField: "text",
        //             //     dataValueField: "value",
        //             //     dataSource: this.dataSource,
        //             //     template: "<span style='background-color: #:color#'>&nbsp;</span> #:text#",
        //             //     valueTemplate: "<span style='background-color: #:color#'>&nbsp;</span> #:text#"
        //             // }
        //         }]
        //     }
        // },
        height: 750
    });
  }

  async getDataEntitie(entity: string, sw: number) {
    let urlTx = `${backandGlobal.api2}/${sessionStorage.getItem(
      'workspace'
    )}.api/api/lappiz/get/${entity}`;
    let params = {
        filter: this.filter,
        sort: this.sort,
        parameters: {
        userId: `${sessionStorage.getItem('userId')}`,
        appViewId: `${this.appViewId}`,
        pType: 'showinmenu',
        aType: 'view',
        environment: `${backandGlobal.environment}`,
      },
    };
    await this.proxyTxApiService
      .getTxData(
        `${urlTx}`,
        JSON.stringify(params),
        backandGlobal.stateOnline,
        entity
      ).then((response: any) => {
        if(sw == 1)
          this.dataProject = response.data.rows;
        else if(sw == 2)
          this.dataStaged = response.data.rows;
        else
          this.dataItems = response.data.rows;
      }).catch((error: any) => {
        console.log(error.message);
        this.toastr.error('No se puedo obetener los datos, revisar la configuración.')
      });
  }

  async getConfigView(id: string, entityId: string, appViewId: string, sw: number){
    var paramsViewName: any = {
      dataType: 'list',
      id,
      entityId,
      appViewId
    };

    await this.configService
      .read(paramsViewName, async (data: any) => {
        if(sw == 1){
          this.configStaged.ViewToOpenCreate = data.ViewToOpenCreate;
          this.configStaged.ViewToOpenEdit = data.ViewToOpenEdit;
        }else{
          this.configItem.ViewToOpenCreate = data.ViewToOpenCreate;
          this.configItem.ViewToOpenEdit = data.ViewToOpenEdit;
        }
      }).catch((error: any) => {
        console.log(error.message);
        this.toastr.error('No se pudo obtner la configuración de la vista.');
      });
  }
}
