import { Injectable } from '@angular/core';
import { Router, NavigationExtras } from '@angular/router';

import { HttpCacheService } from '@services/http-cache.service';

import backandGlobal from '@env/env';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  urlConfigApi: string;

  constructor(
    private router: Router,
    private httpCacheService: HttpCacheService
  ) {
    this.urlConfigApi = `${backandGlobal.url}`;
  }

  setCurrentMenu(current: any) {
    if (!current.isModule) {
      switch (current.partType.toLowerCase()) {
        case 'table':
          if (current.appViewId) {
            var params: NavigationExtras = {
              queryParams: {
                viewName: current.partId,
                workspaceId: JSON.parse(sessionStorage.currentApp).id,
                entityId: current.entityId,
                dato: current.name,
                appViewId: current.appViewId
              }
            }
          } else {
            var params: NavigationExtras = {
              queryParams: {
                viewName: current.partId,
                workspaceId: JSON.parse(sessionStorage.currentApp).id,
                entityId: current.entityId,
                dato: current.name
              }
            }
          }

          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/grids'], params);
          });
          break;
        case 'list': 
          if (current.appViewId) {
            var params: NavigationExtras = {
              queryParams: {
                viewName: current.partId,
                workspaceId: JSON.parse(sessionStorage.currentApp).id,
                entityId: current.entityId,
                dato: current.name,
                appViewId: current.appViewId
              }
            }
          } else {
            var params: NavigationExtras = {
              queryParams: {
                viewName: current.partId,
                workspaceId: JSON.parse(sessionStorage.currentApp).id,
                entityId: current.entityId,
                dato: current.name
              }
            }
          }

          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/list'], params);
          });
          break;
        case 'scheduler':
          if (current.appViewId) {
            var params: NavigationExtras = {
              queryParams: {
                viewName: current.partId,
                workspaceId: JSON.parse(sessionStorage.currentApp).id,
                entityId: current.entityId,
                dato: current.name,
                appViewId: current.appViewId
              }
            }
          } else {
            var params: NavigationExtras = {
              queryParams: {
                viewName: current.partId,
                workspaceId: JSON.parse(sessionStorage.currentApp).id,
                entityId: current.entityId,
                dato: current.name
              }
            }
          }

          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/scheduler'], params);
          });
          break;
        case 'form':
          var params: NavigationExtras = {
            queryParams: {
              viewName: current.partId,
              entityId: current.entityId,
              entityName: current.workspaceName,
              viewMenu: true
            }
          }

          if (current.appViewId) {
            params.queryParams.appViewId = current.appViewId
          }

          if (current.viewMode === 'Edit' && current.hasOwnProperty('expresionId')) {
            params.queryParams.rowId = eval(current.expresionId);
            params.queryParams.type = 'edit';
          }

          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/forms'], params);
          });
          break;
        case 'pivot':
          var params: NavigationExtras = {
            queryParams: {
              viewName: current.partId,
              workspaceId: JSON.parse(sessionStorage.currentApp).id,
              entityId: current.entityId,
              dato: current.name,
              appViewId: current.appViewId
            }
          }

          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/pivot'], params);
          });
          break;
        case 'viewtimeLine':
          var params: NavigationExtras = {
            queryParams: {
              contentId: current.partId
            }
          }

          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/timeline'], params);
          });
          break;
        case 'videocall':
          var params: NavigationExtras = {
            queryParams: {
              contentId: current.partId
            }
          }

          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/videoCall'], params);
          });
          break;
        case 'chat':
          var params: NavigationExtras = {
            queryParams: {
              contentId: current.partId
            }
          }

          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/chat'], params);
          });
          break;
        case 'dashboard':
          var params: NavigationExtras = {
            queryParams: {
              dashboardId: current.partId
            }
          }

          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/dashboard'], params);
          });
          break;
        case 'tasklist':
          var params: NavigationExtras = {
            queryParams: {
              processDefinitionKey: current.partId
            }
          }

          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate(['/tasklist'], params);
          });
          break;
        case 'lappizpage':
          if (current.LappizPageId) {
            // var params: NavigationExtras = {
            //   queryParams: {
            //     LappizPageId: current.LappizPageId,
            //     ApplicationId: current.entityId,
            //     Nombre: current.name
            //   }
            // }
          }
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([`/page/${current.apiPath}/${sessionStorage.appName}`]);
          });
          break;
        case 'content':
        case 'roles':
        case 'permisos':
        case 'rolesxusers':
        default:
          var params: NavigationExtras = {
            queryParams: {
              contentId: current.partId
            }
          }

          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
            this.router.navigate([`/${current.partType}`], params);
          });
          break;
      }
    }
  }

  async queryJsonp(workspace: any) {
    let workspaceId = workspace;
    let url = `${this.urlConfigApi}/api/app/config?workspaceId=${workspaceId}`;
    const response = await this.httpCacheService.get(url, workspaceId);
    return response;
  }

}
