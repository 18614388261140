<div style="height:250px">
  <div class="slides_control">
    <div>
      <ngb-carousel [interval]="interval" [activeId]="active" [wrap]="!noWrapSlides">
        <div *ngFor="let slide of $any(slideData | keyvalue)">
          <ng-template ngbSlide>
            <img class="img-responsive ft-appengine-carrousel-img" src="{{slide.value.image}}">
            <div class="carousel-caption">
              <h4 style="color:black;">{{slide.value.name}}</h4>
            </div>
            <p style="font-size:16px; color:darkcyan; text-decoration:double; text-decoration-color:black;">
              {{slide.description}}</p>
          </ng-template>
        </div>
      </ngb-carousel>
    </div>
  </div>
</div>
