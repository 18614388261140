import { Injectable } from '@angular/core';
import PouchDB from 'pouchdb';
import { ILappizPage } from '../interfaces/ILappizPage';
@Injectable({
  providedIn: 'root'
})
export class LocalConfigApiService {
  dbConfig;
  dbAppContext;
  constructor() {
    this.dbConfig = new PouchDB('appConfigurations');
    this.dbAppContext = new PouchDB('bd_AppContext');
  }


  public async getUserApps(): Promise<any> {
    this.dbConfig = new PouchDB('appConfigurations');
    try {
      let responseDB = await this.dbAppContext.info();
      if (!responseDB) return;
      let responseData = await this.dbAppContext.get('userApps');
      return responseData.data;
    } catch (err) {
      //console.log(err);
      //console.log('No fue posible obtener la configuración');
    }
  }

  public async getConfigDataGet(key: string): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve(sessionStorage.getItem(key));
    });
    // let responseDB = await this.dbConfig.info();
    // if (!responseDB) return;
    // let responseData =  await this.dbConfig.get(key);
    // return responseData;
  }

  public async getViewConfig(params: any, callback) {
    this.dbConfig = new PouchDB('appConfigurations');
    const keyDoc = `${params.id}${params.dataType}${params.entityId}${params.appViewId}`
    try{
      let responseDB = await this.dbConfig.info();
      if (!responseDB) return;
      let responseData = await this.dbConfig.get(keyDoc);
      return callback(responseData);
    }catch(error){
      return callback();
    }
  }

  public async getUserInfo(){
    this.dbConfig = new PouchDB('bd_AppContext');
    try {
      let responseDB = await this.dbAppContext.info();
      if (!responseDB) return;
      let responseData = await this.dbAppContext.get('userInfo');
      return responseData.data;
    } catch (err) {
      //console.log(err);
      //console.log('No fue posible obtener la configuración');
    }
    return null;
  }

  public async getConfigLappizPages(lappizPage: ILappizPage, callback: Function){
    this.dbConfig = new PouchDB('bd_AppContext');
    const keyDoc = `${lappizPage.LappizPageId}`
    try{
      let responseDB = await this.dbConfig.info();
      if (!responseDB) return;
      let responseData = await this.dbConfig.get(keyDoc);
      return callback(responseData);
    }catch(error){
      return callback();
    }
  }
}