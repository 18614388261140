import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { ProxyConfigApiService } from '@services/proxy-config-api.service';

import backandGlobal from '@env/env';
import { GlobalEventsService } from '@services/global-events.service';
import { NotificationsService } from '@services/notifications.service';
import { Constants } from '@common/constants';

declare var kendo: any;

@Component({
  selector: 'app-permisos',
  templateUrl: './permisos.component.html',
  styleUrls: ['./permisos.component.scss']
})
export class PermisosComponent implements OnInit {

  window: any;
  appViewId: any;
  Contactsuser: any;
  LappizUser: any;
  treelistOptions: any;
  treelistOptionsActions: any;
  treelistOptionsAppViews: any;
  treelistOptionsStaticFeatures: any;
  treelistOptionsLappizFunctions: any;
  treelistOptionsLappizPages: any;
  treelistOptionsDashboards: any;
  treelistOptionsExecTX: any;
  treeListData: any;
  treeListDataActions: any;
  treeListDataAppViews: any;
  treeListDataStaticFeatures: any;
  treeListDataLappizFunctions: any;
  treeListDataLappizPages: any;
  treeListDataExecTX: any;
  RolId: any;
  Expanded: any = [];
  changeDataListAsegurablesViewModel: any = false;
  changeDataListAsegurablesViewModelActions: any = false;
  changeDataListAsegurablesViewModelAppViews: any = false;
  changeDataListAsegurablesViewModelStaticFeatures: any = false;
  changeDataListAsegurablesDashboards: any = false;
  changeDataListAsegurablesLappizFunctions: any = false;
  changeDataListAsegurablesLappizPages: any = false;
  changeDataListAsegurablesExecTX: any = false;
  ListAsegurablesViewModelStaticFeatures: any;
  ListAsegurablesViewModelAppViews: any;
  ListAsegurablesViewModel: any;
  ListAsegurablesViewModelDashboards: any;
  treeListDataDashboards: any;
  ListAsegurablesViewModelLappizFunctions: any;
  ListAsegurablesViewModelLappizPages: any;
  ListAsegurablesViewModelActions: any;
  ListAsegurablesViewModelExecTX: any;
  selectedItem: any;
  value: boolean;
  Permiso: any;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private proxyConfigApiService: ProxyConfigApiService,
    private globalEvents: GlobalEventsService,
    private notificationsServices: NotificationsService
  ) {

    this.activatedRoute.paramMap.subscribe(params => {
      this.appViewId = params.get('appViewId');
    });

    this.RolId = this.activatedRoute.snapshot.queryParams.idRol;
    this.Expanded = [];

  }

  async ngOnInit(): Promise<void> {

    this.proxyConfigApiService.getEvents('PermisosLoaded').then((config: any) => {
      this.permisosLoaded(config);
    })
    let ApplicationId = sessionStorage.workspaceId;

    var url = `${backandGlobal.url}/api/permisos/getEntities/${ApplicationId}/${this.RolId}`;

    await this.proxyConfigApiService.getConfigDataGet(url).then((dt: any) => {
      this.ListAsegurablesViewModel = dt[0].Data;
      this.ListAsegurablesViewModelActions = dt[1].Data;
      this.ListAsegurablesViewModelAppViews = dt[2].Data;
      this.ListAsegurablesViewModelStaticFeatures = dt[3].Data;

      if (!backandGlobal.chat) {
        var iteratorChat = 0;
        var posChat = 0;

        this.ListAsegurablesViewModelStaticFeatures.forEach(itemChat => {
          iteratorChat = iteratorChat + 1;

          if (itemChat.NombreAsegurable == "Chat") {
            posChat = iteratorChat - 1;
          }
        });

        this.ListAsegurablesViewModelStaticFeatures.splice(posChat, 1);
      }

      if (!backandGlobal.videoCall) {
        var iteratorVideoCall = 0;
        var posVideoCall = 0;

        this.ListAsegurablesViewModelStaticFeatures.forEach(itemVideoCall => {
          iteratorVideoCall = iteratorVideoCall + 1;

          if (itemVideoCall.NombreAsegurable == "Video Call") {
            posVideoCall = iteratorVideoCall - 1;
          }
        });

        this.ListAsegurablesViewModelStaticFeatures.splice(posVideoCall, 1);
      }

      this.ListAsegurablesViewModelDashboards = dt[4].Data;
      this.ListAsegurablesViewModelLappizFunctions = dt[5].Data;
      this.ListAsegurablesViewModelExecTX = dt[6].Data;
      this.ListAsegurablesViewModelLappizPages = dt[7].Data;
      this.treeListData = dt[0];
      this.treeListDataActions = dt[1];
      this.treeListDataAppViews = dt[2];
      this.treeListDataStaticFeatures = dt[3];
      this.treeListDataDashboards = dt[4];
      this.treeListDataLappizFunctions = dt[5];
      this.treeListDataExecTX = dt[6];
      this.treeListDataLappizPages = dt[7];
      this.treelistOptions = {
        dataSource: {
          data: this.ListAsegurablesViewModel,
          schema: {
            model: {
              id: 'AsegurableId',
              parentId: 'Padre',
              fields: {
                AsegurableId: { filed: 'AsegurableId', nullable: true },
                Padre: { field: 'Padre', nullable: true }
              }
            }
          }
        },
        name: 'Permisos',
        expand: (e: any, i: any) => {
          var index = $(`[data-uid=${e.model.uid}]`).index();
          var d = { Index: index, AsegurableId: e.model.AsegurableId };
          this.Expanded.push(d);
        },
        collapse: (e: any) => {
          $(this.Expanded).each((i, v) => {
            if (e.model.AsegurableId == v.AsegurableId) {
              this.Expanded.splice(i, 1);
            }
          });
        },
        columns: [
          {
            field: 'NombreAsegurable',
            title: 'Nombre',
            width: 8
          },
          {
            field: 'Crear',
            template: '#if(TipoAsegurable != \'Modulo\')' + '{#' + '<input class=\'checkbox\' ' + '#if(Crear){#' + 'checked' + '#}#' + ' data-bind="click: AsignarPermisosClickc" type=\'checkbox\' id=\'#: AsegurableId#1\' />' + '#}#',
            width: 3,
            attributes: { style: "text-align: center" }
          },
          {
            field: 'Editar',
            template: '#if(TipoAsegurable != \'Modulo\'){#' + '<input class=\'checkbox\'' + '#if(Editar){#' + 'checked' + '#}#' + ' data-bind="click: AsignarPermisosClickc"   type=\'checkbox\' id=\'#: AsegurableId#2\' />' + '#}#',
            width: 3,
            attributes: { style: "text-align: center" }
          },
          {
            field: 'Consultar',
            template: '#if(TipoAsegurable == \'Tabla\'){#' + '<input class=\'checkbox\' ' + '#if(Consultar){#' + 'checked' + '#}#' + ' data-bind="click: AsignarPermisosClickc"  type=\'checkbox\' id=\'#: AsegurableId#3\' />' + '#}#',
            width: 4,
            attributes: { style: "text-align: center" }
          },
          {
            field: 'Eliminar',
            template: '#if(TipoAsegurable != \'Modulo\'){#' + '<input class=\'checkbox\' ' + '#if(Eliminar){#' + 'checked' + '#}#' + ' data-bind="click: AsignarPermisosClickc"  type=\'checkbox\' id=\'#: AsegurableId#4\' />' + '#}#',
            width: 3,
            attributes: { style: "text-align: center" }
          },
          {
            field: 'ShowInMenu',
            template: '#if(TipoAsegurable != \'Campo\'){#' + '<input class=\'checkbox\' ' + '#if(ShowInMenu){#' + 'checked' + '#}#' + ' data-bind="click: AsignarPermisosClickc"  type=\'checkbox\' id=\'#: AsegurableId#6\' />' + '#}#',
            title: 'Mostrar',
            width: 3,
            attributes: { style: "text-align: center" }
          },
          {
            field: 'ViewItem',
            template: '#if(TipoAsegurable == \'Tabla\'){#' + '<input class=\'checkbox\' ' + '#if(VerItem){#' + 'checked' + '#}#' + ' data-bind="click: AsignarPermisosClickc"  type=\'checkbox\' id=\'#: AsegurableId#7\' />' + '#}#',
            title: 'Ver Item',
            width: 3,
            attributes: { style: "text-align: center" }
          }
        ]
      };

      this.setActionsPermissions();
      kendo.jQuery("#Permisos").kendoTreeList(this.treelistOptions);

      this.treelistOptionsActions = {
        dataSource: {
          data: this.ListAsegurablesViewModelActions,
          schema: {
            model: {
              id: 'AsegurableId',
              parentId: 'Padre',
              fields: {
                AsegurableId: { filed: 'AsegurableId', nullable: true },
                Padre: { field: 'Padre', nullable: true }
              }
            }
          }
        },
        name: 'Permisos',
        expand: (e: any, i: any) => {
          var index = $(`[data-uid=${e.model.uid}]`).index();
          var d = { Index: index, AsegurableId: e.model.AsegurableId };
          this.Expanded.push(d);
        },
        collapse: (e: any) => {
          $(this.Expanded).each((i, v) => {
            if (e.model.AsegurableId == v.AsegurableId) {
              this.Expanded.splice(i, 1);
            }
          });
        },
        columns: [
          {
            field: 'NombreAsegurable',
            title: 'Nombre',
            width: 5
          }, {
            field: 'Crear',
            template: '#if(TipoAsegurable != \'EntityActions\')' + '{#' + '<input class=\'checkbox\' ' + '#if(Crear){#' + 'checked' + '#}#' + ' data-bind="click: AsignarPermisosActionsClick" (click)=\'AsignarPermisosActions($event)\' type=\'checkbox\' id=\'#: AsegurableId#1\' />' + '#}#',
            width: 6,
            attributes: { style: "text-align: center" }
          }, {
            field: 'Editar',
            template: '#if(TipoAsegurable != \'EntityActions\'){#' + '<input class=\'checkbox\'' + '#if(Editar){#' + 'checked' + '#}#' + ' data-bind="click: AsignarPermisosActionsClick" (click)=\'AsignarPermisosActions($event)\' type=\'checkbox\' id=\'#: AsegurableId#2\' />' + '#}#',
            width: 6,
            attributes: { style: "text-align: center" }
          }]
      };

      this.setActionsPermissionsActions();
      kendo.jQuery("#PermisosActions").kendoTreeList(this.treelistOptionsActions);

      this.treelistOptionsAppViews = {
        dataSource: {
          data: this.ListAsegurablesViewModelAppViews,
          schema: {
            model: {
              id: 'AsegurableId',
              parentId: 'Padre',
              fields: {
                AsegurableId: { filed: 'AsegurableId', nullable: true },
                Padre: { field: 'Padre', nullable: true }
              }
            }
          }
        },
        name: 'Permisos',
        expand: (e: any, i: any) => {
          var index = $(`[data-uid=${e.model.uid}]`).index();
          var d = { Index: index, AsegurableId: e.model.AsegurableId };
          this.Expanded.push(d);
        },
        collapse: (e: any) => {
          $(this.Expanded).each((i: any, v: any) => {
            if (e.model.AsegurableId == v.AsegurableId) {
              this.Expanded.splice(i, 1);
            }
          });
        },
        columns: [
          {
            field: 'NombreAsegurable',
            title: 'Nombre',
            width: 8
          },
          {
            field: 'Crear',
            template: '#if(TipoAsegurable != \'EntityAppViews\' && TipoAsegurable != \'Tabs\')' + '{#' + '<input class=\'checkbox\' ' + '#if(Crear){#' + 'checked' + '#}#' + ' data-bind="click: AsignarPermisosAppViewsClick" (click)=\'AsignarPermisosAppViews($event)\' type=\'checkbox\' id=\'#: AsegurableId#1\' />' + '#}#',
            width: 5,
            attributes: { style: "text-align: center" }
          },
          {
            field: 'Editar',
            template: '#if(TipoAsegurable != \'EntityAppViews\' && TipoAsegurable != \'Tabs\'){#' + '<input class=\'checkbox\'' + '#if(Editar){#' + 'checked' + '#}#' + ' data-bind="click: AsignarPermisosAppViewsClick" (click)=\'AsignarPermisosAppViews($event)\' type=\'checkbox\' id=\'#: AsegurableId#2\' />' + '#}#',
            width: 5,
            attributes: { style: "text-align: center" }
          },
          {
            field: 'Eliminar',
            template: '#if(TipoAsegurable != \'EntityAppViews\' && TipoAsegurable != \'Tabs\'){#' + '<input class=\'checkbox\'' + '#if(Eliminar){#' + 'checked' + '#}#' + ' data-bind="click: AsignarPermisosAppViewsClick" (click)=\'AsignarPermisosAppViews($event)\' type=\'checkbox\' id=\'#: AsegurableId#4\' />' + '#}#',
            width: 5,
            attributes: { style: "text-align: center" }
          },
          {
            field: 'Mostrar en el menu',
            template: '#if(TipoAsegurable != \'EntityAppViews\'){#' + '<input class=\'checkbox\'' + '#if(ShowInMenu){#' + 'checked' + '#}#' + ' data-bind="click: AsignarPermisosAppViewsClick" (click)=\'AsignarPermisosAppViews($event)\' type=\'checkbox\' id=\'#: AsegurableId#6\' />' + '#}#',
            title: 'Mostrar',
            width: 5,
            attributes: { style: "text-align: center" }
          },
          {
            field: 'ViewItem',
            template: '#if(TipoAsegurable != \'EntityAppViews\' && TipoAsegurable != \'Tabs\'){#' + '<input class=\'checkbox\'' + '#if(VerItem){#' + 'checked' + '#}#' + ' data-bind="click: AsignarPermisosAppViewsClick" (click)=\'AsignarPermisosAppViews($event)\' type=\'checkbox\' id=\'#: AsegurableId#7\' />' + '#}#',
            title: 'Ver Item',
            width: 5,
            attributes: { style: "text-align: center" }
          }
        ]
      };

      this.setActionsPermissionsAppViews();
      kendo.jQuery("#PermisosAppViews").kendoTreeList(this.treelistOptionsAppViews);

      this.treelistOptionsStaticFeatures = {
        dataSource: {
          data: this.ListAsegurablesViewModelStaticFeatures,
          schema: {
            model: {
              id: 'AsegurableId',
              parentId: 'Padre',
              fields: {
                AsegurableId: { filed: 'AsegurableId', nullable: true },
                Padre: { field: 'Padre', nullable: true }
              }
            }
          }
        },
        name: 'Permisos',
        expand: (e: any, i: any) => {
          var index = $(`[data-uid=${e.model.uid}]`).index();
          var d = { Index: index, AsegurableId: e.model.AsegurableId };
          this.Expanded.push(d);
        },
        collapse: (e: any) => {
          $(this.Expanded).each((i: any, v: any) => {
            if (e.model.AsegurableId == v.AsegurableId) {
              this.Expanded.splice(i, 1);
            }
          });
        },
        columns: [
          {
            field: 'NombreAsegurable',
            title: 'Nombre',
            width: 8
          },
          {
            field: 'Crear',
            template: '#if(TipoAsegurable != \'EntityAppViews\'){#' + '<input class=\'checkbox\' ' + '#if(Crear){#' + 'checked' + '#}#' + ' data-bind="click: AsignarPermisosStaticFeaturesClick" (click)=\'AsignarPermisosStaticFeatures($event)\' type=\'checkbox\' id=\'#: AsegurableId#1\' />' + '#}#',
            width: 5,
            attributes: { style: "text-align: center" }
          },
          {
            field: 'Editar',
            template: '#if(TipoAsegurable != \'EntityAppViews\'){#' + '<input class=\'checkbox\'' + '#if(Editar){#' + 'checked' + '#}#' + ' data-bind="click: AsignarPermisosStaticFeaturesClick" (click)=\'AsignarPermisosStaticFeatures($event)\' type=\'checkbox\' id=\'#: AsegurableId#2\' />' + '#}#',
            width: 5,
            attributes: { style: "text-align: center" }
          },
          {
            field: 'Eliminar',
            template: '#if(TipoAsegurable != \'EntityAppViews\'){#' + '<input class=\'checkbox\'' + '#if(Eliminar){#' + 'checked' + '#}#' + ' data-bind="click: AsignarPermisosStaticFeaturesClick" (click)=\'AsignarPermisosStaticFeatures($event)\' type=\'checkbox\' id=\'#: AsegurableId#4\' />' + '#}#',
            width: 5,
            attributes: { style: "text-align: center" }
          },
          {
            field: 'Mostrar en el menu',
            template: '#if(TipoAsegurable != \'EntityAppViews\'){#' + '<input class=\'checkbox\'' + '#if(ShowInMenu){#' + 'checked' + '#}#' + ' data-bind="click: AsignarPermisosStaticFeaturesClick" (click)=\'AsignarPermisosStaticFeatures($event)\' type=\'checkbox\' id=\'#: AsegurableId#6\' />' + '#}#',
            title: 'Mostrar',
            width: 5,
            attributes: { style: "text-align: center" }
          }
        ]
      };

      kendo.jQuery("#PermisosStaticFeatures").kendoTreeList(this.treelistOptionsStaticFeatures);
      this.setActionsPermissionsStaticFeatures();

      this.treelistOptionsLappizPages = {
        dataSource: {
          data: this.ListAsegurablesViewModelLappizPages,
          schema: {
            model: {
              id: 'AsegurableId',
              parentId: 'Padre',
              fields: {
                AsegurableId: { filed: 'AsegurableId', nullable: true },
                Padre: { field: 'Padre', nullable: true }
              }
            }
          }
        },
        name: 'Permisos',
        expand: (e: any, i: any) => {
          var index = $(`[data-uid=${e.model.uid}]`).index();
          var d = { Index: index, AsegurableId: e.model.AsegurableId };
          this.Expanded.push(d);
        },
        collapse(e: any) {
          $(this.Expanded).each((i, v) => {
            if (e.model.AsegurableId == v.AsegurableId) {
              this.Expanded.splice(i, 1);
            }
          });
        },
        columns: [
          {
            field: 'NombreAsegurable',
            title: 'Nombre',
            width: 8
          },
          {
            field: 'Mostrar en el menu',
            template: '#if(TipoAsegurable != \'EntityLappizPages\'){#' + '<input class=\'checkbox\' ' + '#if(ShowInMenu){#' + 'checked' + '#}#' + ' data-bind="click: AsignarPermisosLappizPagesClick" (click)=\'AsignarPermisosLappizPages($event)\' type=\'checkbox\' id=\'#: AsegurableId#6\' />' + '#}#',
            title: 'Mostrar',
            width: 5,
            attributes: { style: "text-align: center" }
          }
        ]
      }
      kendo.jQuery("#PermisosLappizPages").kendoTreeList(this.treelistOptionsLappizPages);
      this.setActionsPermissionsLappizPages();

      this.treelistOptionsDashboards = {
        dataSource: {
          data: this.ListAsegurablesViewModelDashboards,
          schema: {
            model: {
              id: 'AsegurableId',
              parentId: 'Padre',
              fields: {
                AsegurableId: { filed: 'AsegurableId', nullable: true },
                Padre: { field: 'Padre', nullable: true }
              }
            }
          }
        },
        name: 'Permisos',
        expand: (e: any, i: any) => {
          var index = $(`[data-uid=${e.model.uid}]`).index();
          var d = { Index: index, AsegurableId: e.model.AsegurableId };
          this.Expanded.push(d);
        },
        collapse: (e: any) => {
          $(this.Expanded).each((i: any, v: any) => {
            if (e.model.AsegurableId == v.AsegurableId) {
              this.Expanded.splice(i, 1);
            }
          });
        },
        columns: [
          {
            field: 'NombreAsegurable',
            title: 'Nombre',
            width: 6
          },
          {
            field: 'Mostrar en el menu',
            template: '#if(TipoAsegurable != \'EntityAppViews\'){#' + '<input class=\'checkbox\'' + '#if(ShowInMenu){#' + 'checked' + '#}#' + ' data-bind="click: AsignarPermisosDashboardsClick"  (click)=\'AsignarPermisosDashboards($event)\' type=\'checkbox\' id=\'#: AsegurableId#6\' />' + '#}#',
            title: 'Mostrar',
            width: 5,
            attributes: { style: "text-align: center" }
          }
        ]
      };

      this.setActionsPermissionsDashboards();
      kendo.jQuery("#PermisosDashboards").kendoTreeList(this.treelistOptionsDashboards);

      this.treelistOptionsLappizFunctions = {
        dataSource: {
          data: this.ListAsegurablesViewModelLappizFunctions,
          schema: {
            model: {
              id: 'AsegurableId',
              parentId: 'Padre',
              fields: {
                AsegurableId: { filed: 'AsegurableId', nullable: true },
                Padre: { field: 'Padre', nullable: true }
              }
            }
          }
        },
        name: 'Permisos',
        expand: (e: any, i: any) => {
          var index = $(`[data-uid=${e.model.uid}]`).index();
          var d = { Index: index, AsegurableId: e.model.AsegurableId };
          this.Expanded.push(d);
        },
        collapse(e: any) {
          $(this.Expanded).each((i, v) => {
            if (e.model.AsegurableId == v.AsegurableId) {
              this.Expanded.splice(i, 1);
            }
          });
        },
        columns: [
          {
            field: 'NombreAsegurable',
            title: 'Nombre',
            width: 8
          },
          {
            field: 'Execute',
            template: '#if(TipoAsegurable != \'EntityLappizFunctions\'){#' + '<input class=\'checkbox\' ' + '#if(Execute){#' + 'checked' + '#}#' + ' data-bind="click: AsignarPermisosLappizFunctionsClick" (click)=\'AsignarPermisosLappizFunctions($event)\' type=\'checkbox\' id=\'#: AsegurableId#8\' />' + '#}#',
            title: 'Ejecutar',
            width: 5,
            attributes: { style: "text-align: center" }
          }
        ]
      }

      this.setActionsLappizFunctions();
      kendo.jQuery("#PermisosLappizFunctions").kendoTreeList(this.treelistOptionsLappizFunctions);

      this.treelistOptionsExecTX = {
        dataSource: {
          data: this.ListAsegurablesViewModelExecTX,
          schema: {
            model: {
              id: 'AsegurableId',
              parentId: 'Padre',
              fields: {
                AsegurableId: { filed: 'AsegurableId', nullable: true },
                Padre: { field: 'Padre', nullable: true }
              }
            }
          }
        },
        name: 'Permisos',
        expand: (e: any, i: any) => {
          var index = $(`[data-uid=${e.model.uid}]`).index();
          var d = { Index: index, AsegurableId: e.model.AsegurableId };
          this.Expanded.push(d);
        },
        collapse(e: any) {
          $(this.Expanded).each((i, v) => {
            if (e.model.AsegurableId == v.AsegurableId) {
              this.Expanded.splice(i, 1);
            }
          });
        },
        columns: [
          {
            field: 'NombreAsegurable',
            title: 'Nombre',
            width: 8
          },
          {
            field: 'Execute',
            template: '#if(TipoAsegurable != \'Permisos\'){#' + '<input class=\'checkbox\' ' + '#if(Execute){#' + 'checked' + '#}#' + ' data-bind="click: AsignarPermisosExecTXClick" (click)=\'AsignarPermisosExecTX($event)\' type=\'checkbox\' id=\'#: AsegurableId#8\' />' + '#}#',
            title: 'Ejecutar',
            width: 5,
            attributes: { style: "text-align: center" }
          }
        ]
      }
      this.setActionsExecTX();
      kendo.jQuery("#PermisosExecTX").kendoTreeList(this.treelistOptionsExecTX);
    });

  }

  permisosLoaded(config){
    let params = {
      cancel: false,
    }
    this.globalEvents.fireEvents('PermisosLoaded', config, params);
  }

  async AsignarPermisos($event: any) {
    this.changeDataListAsegurablesViewModel = true;
    var Valor = $($event.currentTarget).is(':checked');
    var parameters = $event.currentTarget.id.substring(0, $event.currentTarget.id.length - 1);
    var id = null;
    var Permiso = $event.currentTarget.id.substr(-1);
    var Tipo = '';
    var grid = kendo.jQuery('#Permisos').data('kendoPermisos');

    let pos = grid.dataItems().map((e: any) => {
      return e.id;
    }).indexOf(parameters);
    id = grid.dataItems()[pos].AsegurableId;
    Tipo = grid.dataItems()[pos].TipoAsegurable;

    this.selectedItem = id;
    this.value = Valor;
    this.Permiso = Permiso;

    this.AsignarPermiso(this.ListAsegurablesViewModel, this.Permiso, this.selectedItem, this.value);

    $(this.ListAsegurablesViewModel).each((i: any, e: any) => {
      if (e.AsegurableId == this.selectedItem && e.TipoAsegurable == 'Modulo') {
        $(this.ListAsegurablesViewModel).each((ind: any, elem: any) => {
          var elementAct = elem;
          if (elem.Padre == this.selectedItem) {
            $(this.ListAsegurablesViewModel).each((i: any, e: any) => {
              if (e.AsegurableId == elementAct.AsegurableId) {
                e.ShowInMenu = this.value;
              }
            });
          }
        });
      }
    });

    grid.dataSource.data(this.ListAsegurablesViewModel);
    this.setActionsPermissions();
    await this.Expanded.forEach((e: any) => {
      $('#Permisos [role=\'treegrid\'] tr').each((ind: any, elem: any) => {
        if (ind == e.Index) {
          grid.expand(elem);
        }
      });
    });
  }

  AsignarPermisosActions($event: any) {
    this.changeDataListAsegurablesViewModelActions = true;
    var Valor = $($event.currentTarget).is(':checked');
    var parameters = $event.currentTarget.id.substring(0, $event.currentTarget.id.length - 1);
    var id = null;
    var Permiso = $event.currentTarget.id.substr(-1);
    var Tipo = '';
    var grid = kendo.jQuery('#PermisosActions').data('kendoPermisos');

    for (var i = 0; i < grid.dataItems().length; i++) {
      if (grid.dataItems()[i].id == parameters) {
        id = grid.dataItems()[i].AsegurableId;
        Tipo = grid.dataItems()[i].TipoAsegurable;
        break;
      }
    }

    this.selectedItem = id;
    this.value = Valor;
    this.Permiso = Permiso;

    this.AsignarPermiso(this.ListAsegurablesViewModelActions, this.Permiso, this.selectedItem, this.value);

    grid.dataSource.data(this.ListAsegurablesViewModelActions);
    this.setActionsPermissionsActions();

    $(this.Expanded).each((i: any, e: any) => {
      $('#PermisosActions [role=\'treegrid\'] tr').each((ind: any, elem: any) => {
        if (ind == e.Index) {
          grid.expand(elem);
        }
      });
    });
  }

  AsignarPermisosAppViews($event: any) {
    this.changeDataListAsegurablesViewModelAppViews = true;
    var Valor = $($event.currentTarget).is(':checked');
    var parameters = $event.currentTarget.id.substring(0, $event.currentTarget.id.length - 1);
    var id = null;
    var Permiso = $event.currentTarget.id.substr(-1);
    var Tipo = '';
    var grid = kendo.jQuery('#PermisosAppViews').data('kendoPermisos');

    let pos = grid.dataItems().map((e: any) => { return e.id; }).indexOf(parameters);
    id = grid.dataItems()[pos].AsegurableId;
    Tipo = grid.dataItems()[pos].TipoAsegurable;

    this.selectedItem = id;
    this.value = Valor;
    this.Permiso = Permiso;

    this.AsignarPermiso(this.ListAsegurablesViewModelAppViews, this.Permiso, this.selectedItem, this.value);

    grid.dataSource.data(this.ListAsegurablesViewModelAppViews);
    this.setActionsPermissionsAppViews();
    $(this.Expanded).each((i: any, e: any) => {
      $('#PermisosAppViews [role=\'treegrid\'] tr').each((ind: any, elem: any) => {
        if (ind == e.Index) {
          grid.expand(elem);
        }
      });
    });
  }

  AsignarPermisosStaticFeatures($event: any) {
    this.changeDataListAsegurablesViewModelStaticFeatures = true;
    var Valor = $($event.currentTarget).is(':checked');
    var parameters = $event.currentTarget.id.substring(0, $event.currentTarget.id.length - 1);
    var id = null;
    var Permiso = $event.currentTarget.id.substr(-1);
    var Tipo = '';
    var grid = kendo.jQuery('#PermisosStaticFeatures').data('kendoPermisos');

    for (var i = 0; i < grid.dataItems().length; i++) {
      if (grid.dataItems()[i].id == parameters) {
        id = grid.dataItems()[i].AsegurableId;
        Tipo = grid.dataItems()[i].TipoAsegurable;
        break;
      }
    }

    this.selectedItem = id;
    this.value = Valor;
    this.Permiso = Permiso;

    this.AsignarPermiso(this.ListAsegurablesViewModelStaticFeatures, this.Permiso, this.selectedItem, this.value);
    grid.dataSource.data(this.ListAsegurablesViewModelStaticFeatures);
    this.setActionsPermissionsStaticFeatures();
    $(this.Expanded).each((i: any, e: any) => {
      $('#PermisosStaticFeatures [role=\'treegrid\'] tr').each((ind: any, elem: any) => {
        if (ind == e.Index) {
          grid.expand(elem);
        }
      });
    });
  }

  AsignarPermisosLappizFunctions($event: any) {
    this.changeDataListAsegurablesLappizFunctions = true;
    var Valor = $($event.currentTarget).is(':checked');
    var parameters = $event.currentTarget.id.substring(0, $event.currentTarget.id.length - 1);
    var id = null;
    var Permiso = $event.currentTarget.id.substr(-1);
    var Tipo = '';
    var grid = kendo.jQuery('#PermisosLappizFunctions').data('kendoPermisos');

    for (var i = 0; i < grid.dataItems().length; i++) {
      if (grid.dataItems()[i].id == parameters) {
        id = grid.dataItems()[i].AsegurableId;
        Tipo = grid.dataItems()[i].TipoAsegurable;
        break;
      }
    }

    this.selectedItem = id;
    this.value = Valor;
    this.Permiso = Permiso;

    this.AsignarPermiso(this.ListAsegurablesViewModelLappizFunctions, this.Permiso, this.selectedItem, this.value);
    grid.dataSource.data(this.ListAsegurablesViewModelLappizFunctions);
    this.setActionsLappizFunctions();
    $(this.Expanded).each((i: any, e: any) => {
      $('#PermisosLappizFunctions [role=\'treegrid\'] tr').each((ind: any, elem: any) => {
        if (ind == e.index) {
          grid.expand(elem);
        }
      });
    });
  }

  AsignarPermisosExecTX($event: any) {
    this.changeDataListAsegurablesExecTX = true;
    var Valor = $($event.currentTarget).is(':checked');
    var parameters = $event.currentTarget.id.substring(0, $event.currentTarget.id.length - 1);
    var id = null;
    var Permiso = $event.currentTarget.id.substr(-1);
    var Tipo = '';
    var grid = kendo.jQuery('#PermisosExecTX').data('kendoPermisos');

    for (var i = 0; i < grid.dataItems().length; i++) {
      if (grid.dataItems()[i].id == parameters) {
        id = grid.dataItems()[i].AsegurableId;
        Tipo = grid.dataItems()[i].TipoAsegurable;
        break;
      }
    }

    this.selectedItem = id;
    this.value = Valor;
    this.Permiso = Permiso;

    this.AsignarPermiso(this.ListAsegurablesViewModelExecTX, this.Permiso, this.selectedItem, this.value);
    grid.dataSource.data(this.ListAsegurablesViewModelExecTX);
    this.setActionsExecTX();
    $(this.Expanded).each((i: any, e: any) => {
      $('#PermisosExecTX [role=\'treegrid\'] tr').each((ind: any, elem: any) => {
        if (ind == e.index) {
          grid.expand(elem);
        }
      });
    });
  }

  AsignarPermisosDashboards($event: any) {
    this.changeDataListAsegurablesDashboards = true;
    var Valor = $($event.currentTarget).is(':checked');
    var parameters = $event.currentTarget.id.substring(0, $event.currentTarget.id.length - 1);
    var id = null;
    var Permiso = $event.currentTarget.id.substr(-1);
    var Tipo = '';
    var grid = kendo.jQuery('#PermisosDashboards').data('kendoPermisos');

    for (var i = 0; i < grid.dataItems().length; i++) {
      if (grid.dataItems()[i].id == parameters) {
        id = grid.dataItems()[i].AsegurableId;
        Tipo = grid.dataItems()[i].TipoAsegurable;
        break;
      }
    }

    this.selectedItem = id;
    this.value = Valor;
    this.Permiso = Permiso;

    this.AsignarPermiso(this.ListAsegurablesViewModelDashboards, this.Permiso, this.selectedItem, this.value);
    grid.dataSource.data(this.ListAsegurablesViewModelDashboards);
    this.setActionsPermissionsDashboards();
    $(this.Expanded).each((i: any, e: any) => {
      $('#PermisosDashboards [role=\'treegrid\'] tr').each((ind: any, elem: any) => {
        if (ind == e.Index) {
          grid.expand(elem);
        }
      });
    });
  };

  AsignarPermiso(list: any, Permiso: any, AsegurableId: any, value: any) {
    var PosList = list.findIndex(e => e.AsegurableId === AsegurableId)
    if (Permiso == 1) {
      list[PosList].Crear = value;
    } else if (Permiso == 2) {
      list[PosList].Editar = value;
    } else if (Permiso == 3) {
      list[PosList].Consultar = value;
    } else if (Permiso == 4) {
      list[PosList].Eliminar = value;
    } else if (Permiso == 5) {
      list[PosList].Obligatorio = value;
    } else if (Permiso == 6) {
      list[PosList].ShowInMenu = value;
    } else if (Permiso == 7) {
      list[PosList].VerItem = value;
    } else if (Permiso == 8) {
      list[PosList].Execute = value;
    }
    return PosList;
  }

  AsignarPermisosLappizPages($event: any) {
    this.changeDataListAsegurablesLappizPages = true;
    var Valor = $($event.currentTarget).is(':checked');
    var parameters = $event.currentTarget.id.substring(0, $event.currentTarget.id.length - 1);
    var id = null;
    var Permiso = $event.currentTarget.id.substr(-1); 
    var Tipo = '';
    var grid = kendo.jQuery('#PermisosLappizPages').data('kendoPermisos');

    for (var i = 0; i < grid.dataItems().length; i++) {
      if (grid.dataItems()[i].id == parameters) {
        id = grid.dataItems()[i].AsegurableId;
        Tipo = grid.dataItems()[i].TipoAsegurable;
        break;
      }
    }

    this.selectedItem = id;
    this.value = Valor;
    this.Permiso = Permiso;

    this.AsignarPermiso(this.ListAsegurablesViewModelLappizPages, this.Permiso, this.selectedItem, this.value);
    grid.dataSource.data(this.ListAsegurablesViewModelLappizPages);
    this.setActionsLappizPages();
    $(this.Expanded).each((i: any, e: any) => {
      $('#PermisosLappizPages [role=\'treegrid\'] tr').each((ind: any, elem: any) => {
        if (ind == e.index) {
          grid.expand(elem);
        }
      });
    });
  }

  save() {
    var itemsAsegurables = [];

    if (!this.changeDataListAsegurablesViewModelActions && !this.changeDataListAsegurablesViewModel
      && !this.changeDataListAsegurablesViewModelAppViews && !this.changeDataListAsegurablesViewModelStaticFeatures
      && !this.changeDataListAsegurablesDashboards && !this.changeDataListAsegurablesLappizFunctions && !this.changeDataListAsegurablesExecTX && !this.changeDataListAsegurablesLappizPages) {
    
      this.notificationsServices.notificationApp('info', Constants.MESSAGES.NotChangesPermits)
      return;
    }

    if (this.changeDataListAsegurablesViewModel) {
      Array.prototype.push.apply(itemsAsegurables, this.ListAsegurablesViewModel);
    }

    if (this.changeDataListAsegurablesViewModelActions) {
      Array.prototype.push.apply(itemsAsegurables, this.ListAsegurablesViewModelActions);
    }

    if (this.changeDataListAsegurablesViewModelAppViews) {
      Array.prototype.push.apply(itemsAsegurables, this.ListAsegurablesViewModelAppViews);
    }

    if (this.changeDataListAsegurablesViewModelStaticFeatures) {
      Array.prototype.push.apply(itemsAsegurables, this.ListAsegurablesViewModelStaticFeatures);
    }

    if (this.changeDataListAsegurablesDashboards) {
      Array.prototype.push.apply(itemsAsegurables, this.ListAsegurablesViewModelDashboards);
    }

    if (this.changeDataListAsegurablesLappizFunctions) {
      Array.prototype.push.apply(itemsAsegurables, this.ListAsegurablesViewModelLappizFunctions);
    }

    if (this.changeDataListAsegurablesLappizPages) {
      Array.prototype.push.apply(itemsAsegurables, this.ListAsegurablesViewModelLappizPages);
    }

    if (this.changeDataListAsegurablesExecTX){
      Array.prototype.push.apply(itemsAsegurables, this.ListAsegurablesViewModelExecTX)
    }

    if (this.changeDataListAsegurablesLappizPages) {
      Array.prototype.push.apply(itemsAsegurables, this.ListAsegurablesViewModelLappizPages);
    }
    this.saveEvent(itemsAsegurables);
  }

  RedirecRoles() {
    this.notificationsServices.notificationApp('success', Constants.MESSAGES.SuccessEdit)
    this.router.navigateByUrl('/roles');
  };

  saveEvent(List: any) {
    var url = `${backandGlobal.url}/api/permisos/create?IdRol=${this.RolId}`;
    this.proxyConfigApiService.getConfigDataPost(url, List).then(() => {
      this.notificationsServices.notificationApp('success', Constants.MESSAGES.SuccessEdit)
      this.router.navigateByUrl('/roles');
    }, (data: any) => {
      this.router.navigateByUrl('/roles');
      this.notificationsServices.notificationApp('success', data.ExceptionMessage)
    });
  };

  setActionsPermissions() {
    setTimeout(() => {
      var actionsClick = kendo.observable({
        AsignarPermisosClickc: (e: any) => {
          this.AsignarPermisos(e);
        }
      });

      kendo.bind($('#Permisos'), actionsClick)
      $('.k-grid td:nth-child(1)').attr('style', 'text-align: left !important;')
    }, 1000);
  }

  setActionsPermissionsActions() {
    setTimeout(() => {
      var actionsClickActions = kendo.observable({
        AsignarPermisosActionsClick: (e: any) => {
          this.AsignarPermisosActions(e);
        }
      });

      kendo.bind($('#PermisosActions'), actionsClickActions)
      $('.k-grid td:nth-child(1)').attr('style', 'text-align: left !important;')
    }, 1000);
  }

  setActionsPermissionsAppViews() {
    setTimeout(() => {
      var actionsClick = kendo.observable({
        AsignarPermisosAppViewsClick: (e: any) => {
          this.AsignarPermisosAppViews(e);
        }
      });

      kendo.bind($('#PermisosAppViews'), actionsClick)
      $('.k-grid td:nth-child(1)').attr('style', 'text-align: left !important;')
    }, 1000);
  }

  setActionsPermissionsStaticFeatures() {
    setTimeout(() => {
      var actionsClick = kendo.observable({
        AsignarPermisosStaticFeaturesClick: (e: any) => {
          this.AsignarPermisosStaticFeatures(e);
        }
      });

      kendo.bind($('#PermisosStaticFeatures'), actionsClick)
      $('.k-grid td:nth-child(1)').attr('style', 'text-align: left !important;')
    }, 1000);
  }

  setActionsLappizPages(){
    setTimeout(() => {
      var actionsClick = kendo.observable({
        AsignarPermisosLappizPagesClick: (e: any) => {
          this.AsignarPermisosLappizPages(e);
        }
      });

      kendo.bind($('#PermisosLappizPages'), actionsClick)
    }, 1000);
    $('.k-grid td:nth-child(1)').attr('style', 'text-align: left !important;')
  }

  setActionsLappizFunctions() {
    setTimeout(() => {
      var actionsClick = kendo.observable({
        AsignarPermisosLappizFunctionsClick: (e: any) => {
          this.AsignarPermisosLappizFunctions(e);
        }
      });

      kendo.bind($('#PermisosLappizFunctions'), actionsClick)
    }, 1000);
    $('.k-grid td:nth-child(1)').attr('style', 'text-align: left !important;')
  }

  setActionsPermissionsLappizPages() {
    setTimeout(() => {
      var actionsClick = kendo.observable({
        AsignarPermisosLappizPagesClick: (e: any) => {
          this.AsignarPermisosLappizPages(e);
        }
      });

      kendo.bind($('#PermisosLappizPages'), actionsClick)
    }, 1000);
    $('.k-grid td:nth-child(1)').attr('style', 'text-align: left !important;')
  }

  setActionsExecTX() {
    setTimeout(() => {
      var actionsClick = kendo.observable({
        AsignarPermisosExecTXClick: (e: any) => {
          this.AsignarPermisosExecTX(e);
        }
      });

      kendo.bind($('#PermisosExecTX'), actionsClick)
    }, 1000);
    $('.k-grid td:nth-child(1)').attr('style', 'text-align: left !important;')
  }

  setActionsPermissionsDashboards() {
    setTimeout(() => {
      var actionsClick = kendo.observable({
        AsignarPermisosDashboardsClick: (e: any) => {

          this.AsignarPermisosDashboards(e);
        }
      });

      kendo.bind($('#PermisosDashboards'), actionsClick)
      $('.k-grid td:nth-child(1)').attr('style', 'text-align: left !important;')
    }, 1000);
  }

}
