<div>
  <!-- Modal -->
  <!-- <ng-template #lgModal> -->
  <div class="modal-header">
    <div class="col-xs-6 active-color">
      <h3>{{field.displayName ? field.displayName : field.name}}</h3>
    </div>
    <div class="col-xs-6" style="text-align:right">
      <i class="mdi mdi-undo button-header active-color icon" (click)="closeDocuments()" title="Cerrar"></i>
    </div>
  </div>
  <div class="modal-body">
    <div class="row">
      <div class="col-md-12">
        <div id="gridFiles"></div>
      </div>
    </div>
  </div>
  <!-- </ng-template> -->
  <!-- Close result -->
  <p *ngIf="basicModalCloseResult != ''" class="mt-2">{{basicModalCloseResult}}</p>
</div>
