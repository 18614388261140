<form [formGroup]="innerForm">
  <div class="form-group">
    <div class="left-content">
      <textarea formControlName="field" style="resize: vertical;" class="form-control"
        [maxlength]="field.maximumValue == -1 ? '' : field.maximumValue" [required]="field.required"
        [(ngModel)]="value.val" [hidden]="!field.show" placeholder="{{field.displayName}}"
        [attr.disabled]="!field.IsEditable ? true : null || field.disabled ? true : null" (ngModelChange)="change()"
        (change)="change()" [name]="field.Code" [id]="field.campoId" (valueChange)="onValueChange($event)"
        kendoTextArea>
      </textarea>
      <div class="input-group-prepend  icons-field" *ngIf="field.IsEditable">
        <span (click)="onMicrophoneClick($event)" class="input-group-text icon-field icon-action" id="basic-addon0" *ngIf="field.IsSpeech" >
            <i [ngClass]="classMicrophone"></i>
        </span>
    </div>
      <span class="counter" *ngIf="field.maximumValue != -1">{{ textareaCounter }}</span>
    </div>
  </div>
  <div *ngIf="formSubmitted || innerForm.controls.field.errors?.required && innerForm.controls.field.touched"
    class="ft-appengine-required-input" id="minlength_{{field.campoId}}" role="alert">
    {{errors.required}}
  </div>
  <div class="ft-appengine-required-input" id="maxlength_{{field.campoId}}" role="alert"
    *ngIf="formSubmitted || innerForm.controls.field.errors?.maxlength && innerForm.controls.field.touched">
    {{errors.maximumValue}}
  </div>
</form>
