<nav class="mobile">
  <div class="sidebar-nav-mobile metismenu-mobile" id="sidebar-menu" #sidebarMenu>
    <ng-container *ngFor="let item of menuItemsThird">
      <div class="nav-item nav-link-ref" [id]="item.idNode" hidden
        (click)="setCurrentMenuSelectionMobile({ isModule: item.isModule, partId: item.partId, name: item.name, partType: item.partType, entityId: item.id, appViewId: item.AppViewId, expresionId: item.ExpresionId, viewMode: item.ViewMode }, null, $event)">
        <a class="nav-link link-mobile" name="{{ item.partId != null ? item.partId : item.name }}">
          <i
            class="mobile-faicon fa {{ item.IconoAweModul != 'empty' && item.IconoAweModul != undefined && item.IconoAweModul != '' && item.IconoAweModul != null && item.IconoAweModul != 'System.String[]' ? item.IconoAweModul : item.IconoAwe != 'empty' && item.IconoAwe != undefined && item.IconoAwe != '' && item.IconoAwe != null && item.IconoAwe != 'System.String[]' ? item.IconoAwe : defaultClassIcon }} link-icon"></i>
          <span class="link-title"> {{ item.name }}</span>
        </a>
      </div>
    </ng-container>
  </div>
  <div class="sidebar-nav-mobile metismenu-mobile" id="sidebar-menu" #sidebarMenu>
    <ng-container *ngFor="let item of menuItemsSecond">
      <div class="nav-item nav-link-ref" [id]="item.idNode" hidden
        (click)="setCurrentMenuSelectionMobile({ isModule: item.isModule, partId: item.partId, name: item.name, partType: item.partType, entityId: item.id, appViewId: item.AppViewId, expresionId: item.ExpresionId, viewMode: item.ViewMode }, null, $event)">
        <a class="nav-link link-mobile" name="{{ item.partId != null ? item.partId : item.name }}">
          <i
            class="mobile-faicon fa {{ item.IconoAweModul != 'empty' && item.IconoAweModul != undefined && item.IconoAweModul != '' && item.IconoAweModul != null && item.IconoAweModul != 'System.String[]' ? item.IconoAweModul : item.IconoAwe != 'empty' && item.IconoAwe != undefined && item.IconoAwe != '' && item.IconoAwe != null && item.IconoAwe != 'System.String[]' ? item.IconoAwe : defaultClassIcon }} link-icon"></i>
          <span class="link-title"> {{ item.name }}</span>
        </a>
      </div>
    </ng-container>
  </div>
  <div class="sidebar-nav-mobile metismenu-mobile" id="sidebar-menu" #sidebarMenu>
    <ng-container *ngFor="let item of menuItems">
      <div class="nav-item nav-link-ref" [id]="item.idNode" *ngIf="appName != 'MercaPay'"
        (click)="setCurrentMenuSelectionMobile({ isModule: item.isModule, partId: item.partId, name: item.name, partType: item.partType, entityId: item.id, appViewId: item.AppViewId, expresionId: item.ExpresionId, viewMode: item.ViewMode }, null, $event)">
        <a class="nav-link link-mobile" name="{{ item.partId != null ? item.partId : item.name }}">
          <i
            class="mobile-faicon fa {{ item.IconoAweModul != 'empty' && item.IconoAweModul != undefined && item.IconoAweModul != '' && item.IconoAweModul != null && item.IconoAweModul != 'System.String[]' ? item.IconoAweModul : item.IconoAwe != 'empty' && item.IconoAwe != undefined && item.IconoAwe != '' && item.IconoAwe != null && item.IconoAwe != 'System.String[]' ? item.IconoAwe : defaultClassIcon }} link-icon"></i>
          <span class="link-title" hidden> {{ item.name }}</span>
        </a>
        <span class="link-title icon-mercapp"> {{ item.name }}</span>
      </div>
      <div class="nav-item nav-link-ref text-center" [id]="item.idNode" *ngIf="appName == 'MercaPay'"
        (click)="setCurrentMenuSelectionMobile({ isModule: item.isModule, partId: item.partId, name: item.name, partType: item.partType, entityId: item.id, appViewId: item.AppViewId, expresionId: item.ExpresionId, viewMode: item.ViewMode }, null, $event)">
        <a class="nav-link link-mobile" name="{{ item.partId != null ? item.partId : item.name }}">
          <i
            class="mobile-faicon fa {{ item.IconoAweModul != 'empty' && item.IconoAweModul != undefined && item.IconoAweModul != '' && item.IconoAweModul != null && item.IconoAweModul != 'System.String[]' ? item.IconoAweModul : item.IconoAwe != 'empty' && item.IconoAwe != undefined && item.IconoAwe != '' && item.IconoAwe != null && item.IconoAwe != 'System.String[]' ? item.IconoAwe : defaultClassIcon }} link-icon"></i>
        </a>
        <span class="link-title icon-mercapp"> {{ item.name }}</span>
      </div>
    </ng-container>
  </div>
</nav>
