import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { FormsComponent } from '@components/forms/forms.component';

import { VoiceRercognitionService } from '@services/voice-rercognition.service';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
})
export class InputComponent implements OnInit, AfterViewInit {

  @Input() field: any;
  @Input() value: any;
  @Input() form: any;
  @Input() inputClass: any;
  @Input() errors: any;

  formSubmitted = false;

  innerForm = new UntypedFormGroup({
    field: new UntypedFormControl(),
  });

  showErrorMaximumLength: boolean;
  showErrorMinimumLength: boolean;

  microphoneActive: boolean;
  classMicrophone: string;

  constructor(
    private formsComponent: FormsComponent,
    private voiceRecognitionService: VoiceRercognitionService
  ) {
    this.showErrorMaximumLength = false;
    this.showErrorMinimumLength = false;
    this.classMicrophone ="fa fa-solid fa-microphone";
    this.microphoneActive = false;
  }

  ngAfterViewInit() {
    if (this.field.required == false) {
      this.innerForm = new UntypedFormGroup({
        field: new UntypedFormControl(this.field.value.val,
          this.field.maximumValue != -1 ?
            [
              Validators.minLength(this.field.minimumValue),
              Validators.maxLength(this.field.maximumValue)
            ]
            : [Validators.minLength(this.field.minimumValue)]
        )
      });
    }
  }

  ngOnInit(): void {
    if (this.field.required == true) {
      this.innerForm = new UntypedFormGroup({
        field: new UntypedFormControl(this.field.value.val,
          this.field.maximumValue != -1 ?
            [
              Validators.required,
              Validators.minLength(this.field.minimumValue),
              Validators.maxLength(this.field.maximumValue)
            ]
            : [Validators.required, Validators.minLength(this.field.minimumValue)]
        )
      });
    }

    if (this.field.InMemory) {
      this.field.maximumValue = null;
      this.field.minimumValue = null;
    }

    if (!this.value.val) {
      this.value.val = this.field.defaultValue;
    }

    this.formsComponent.checkForm(this.field.campoId, this.innerForm.invalid);
    if (this.field.IsSpeech)
      this.voiceRecognitionService.serviceAvailable.subscribe();
  }

  change() {
    this.formsComponent.setValueFieldForm(this.field.campoId, this.field);
    this.formsComponent.applyUIRules(this.field, this.value);
    this.formsComponent.actionFormula(this.field, this.value.val);
    this.formsComponent.fireEvents('ValueChanged', this.field.events, { 
      value: this.value.val, 
      field: this.field
    });
    this.innerForm.setValue({field: this.field.value.val})
    setTimeout(() => {
      this.formsComponent.checkForm(this.field.campoId, this.innerForm.invalid);
    }, 100);
  }
  
  onMicrophoneClick($event:any) {
    if (this.voiceRecognitionService.init('es-CO', this.field.campoId)){
      if (!this.microphoneActive){
        this.classMicrophone ="fa fa-solid fa-microphone microphone-active";
        this.voiceRecognitionService.start(this.field.campoId)
      }
      else {
        this.classMicrophone ="fa fa-solid fa-microphone";
        this.value.val = this.voiceRecognitionService.stop(this.field.campoId);
        this.change();
        this.formsComponent.actionFormula(this.field, this.value.val);
        this.formsComponent.fireEvents("ValueChanged", this.field.events, { value: this.value.val, field: this.field });
        this.formsComponent.checkForm(this.field.campoId, this.innerForm.invalid);
      }
      this.microphoneActive = !this.microphoneActive;
    }
  }
}
