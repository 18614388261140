import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class HttpService {
  constructor(private readonly http: HttpClient) {}

  public async get<T>(url: string, headers: HttpHeaders): Promise<T> {
    const response = await this.http.get<T>(url, { headers }).toPromise();
    return response;
  }

  public async post<T>(
    url: string,
    data: object,
    headers: HttpHeaders
  ): Promise<T> {
    const response = await this.http
      .post<T>(url, data, { headers })
      .toPromise();
    return response;
  }

  public async put<T>(
    url: string,
    data: object,
    headers: HttpHeaders
  ): Promise<T> {
    const response = await this.http.put<T>(url, data, { headers }).toPromise();
    return response;
  }

  public async patch<T>(
    url: string,
    data: object,
    headers: HttpHeaders
  ): Promise<T> {
    const response = await this.http
      .patch<T>(url, data, { headers })
      .toPromise();
    return response;
  }

  public async delete<T>(url: string, headers: HttpHeaders): Promise<T> {
    const response = await this.http.delete<T>(url, { headers }).toPromise();
    return response;
  }

  public async options<T>(url: string, headers: HttpHeaders): Promise<T> {
    const response = await this.http.options<T>(url, { headers }).toPromise();
    return response;
  }
}
