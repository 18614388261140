<form [formGroup]="innerForm">
  <div class="input-group input-qr" style="width:100%">
    <select name="" id="typeQr_{{field.campoId}}" [(ngModel)]="typeInput" class="form-control type-qr"
      [attr.disabled]="!field.IsEditable ? true : null || field.disabled ? true : null" formControlName="typeQR"
      [hidden]="showQr == true" id="typeQR" (change)="changeType()" (ngModelChange)="changeType()" autocorrect="off"
      [hidden]="showQr ==true" style="max-width: 100px !important; height:2.5rem;">
      <option value="text">Texto</option>
      <option value="url">Link</option>
      <option value="email">Email</option>
      <option value="number">Teléfono</option>
    </select>
    <input [attr.disabled]="!field.IsEditable ? true : null || field.disabled ? true : null" formControlName="field"
      [hidden]="showQr == true" id="{{field.campoId}}" placeholder="{{field.displayName}}" [(ngModel)]="textQr"
      (ngModelChange)="change()" autocorrect="off" [hidden]="showQr ==true" autocapitalize="off" spellcheck="off"
      type="{{typeInput}}" class="form-control" (blur)="onBlur()" style="height: 2.5rem;">
        <div class="icons-field">
        <button type="button" class="btn btn-lg btn-qr-lappiz icon-field" [hidden]="showQr ==true"
      (click)="open(mymodal)">
          <i class="fa fa-light fa-qrcode"></i>
        </button>
      </div>
    </div>
  <div *ngIf="(errorGeneral != innerForm.controls.field.errors?.required) && innerForm.controls.field.touched"
    class="ft-appengine-required-input" id="error_{{field.campoId}}" role="alert">
    {{errorGeneral}}
  </div>
  <div class="ft-appengine-required-input" id="maxlength_{{field.campoId}}" role="alert"
    *ngIf="formSubmitted || innerForm.controls.field.errors?.maxlength && innerForm.controls.field.touched">
    {{errors.maximumValue}}
  </div>
</form>
<ng-template #mymodal let-modal>
  <div class="modal-content">
    <div class="modal-body modal-qr">
      <div id="qrImage"></div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')" translate="closeLink_link">
        Cerrar
      </button>
    </div>
  </div>
</ng-template>
