<div [id]="field.campoId">
  <div>
    <div class="snapshot" *ngIf="webcamImage || value.webcamImage">
      <img style="height: 240px;width: 320px;" [src]="webcamImage?.imageAsDataUrl ? webcamImage?.imageAsDataUrl : value.imageFile" />
    </div>
    <div class="snapshop" *ngIf="imageFile && value.imageFile">
      <img style="height: 240px;width: 320px;" [src]="imageFile ? imageFile : value.imageFile" />
    </div>
    <img [id]="field.campoId + '_btnTomarFoto'" style="height: 240px; width: 320px;" [src]="routeImg ? routeImg : value.routeImg" [hidden]="!showimg || !value.showimg" />
    <webcam [width]="240" [height]="320" [trigger]="triggerObservable" (imageCapture)="handleImage($event)"
      *ngIf="showWebcam && value.showWebcam" [allowCameraSwitch]="allowCameraSwitch" [switchCamera]="nextWebcamObservable"
      [videoOptions]="videoOptions" [imageQuality]="1" (cameraSwitched)="cameraWasSwitched($event)"
      (initError)="handleInitError($event)"></webcam>
    <br />
    <div class="container-btn">
      <button [hidden]="!showbcapture || !value.showbcapture" [id]="field.campoId + '_btn_capture'" class="btn btn-primary-lappiz p-btn-lappiz d-flex" (click)="triggerSnapshot()"
        style="text-align: center; margin: 10px 1rem;" title="Capturar">
        <span style="color: white; text-align: center;" translate="carrusel_uploadFiles">
          <i class="fa fa-camera" style="color: white; text-align: center;"></i>
        </span>
      </button>
      <button [hidden]="!showbcapture || !value.showbcapture" [disabled]="webcamImage || value.webcamImage" [id]="field.campoId + '_btn_switch'" class="btn btn-primary-lappiz d-flex" (click)="toggleWebcam()"
        style="text-align: center; margin: 10px 1rem; color: white; padding-bottom: 9px; padding-top: 9px;" title="Hablitar | Inhabilitar">
        <i class="fa fa-video"></i><span style="font-weight: 700; padding: 0 0.5rem;">|</span>
        <i class="fa fa-video-slash"></i>
      </button>
      <div [hidden]="!showbcapture || !value.showbcapture" [id]="field.campoId + '_btn_gallery'" class="input-group" style="text-align: center; margin: 10px 1rem" title="Galería" (click)="openGallery()">
        <span class="btn btn-primary-lappiz p-btn-lappiz" style="color: white; text-align: center;">
          <i class="fa fa-picture-o"></i>
          <input type="file" [id]="field.campoId + '_input_file'" style="display: none;" class="btnImg" accept="image/*"
            (change)="getGalery($event)">
        </span>
        <input style="display: none;" type="text" class="form-control" readonly>
      </div>
      <button [hidden]="showbcapture && value.showbcapture" [id]="field.campoId + '_btn_trash'" type="button" class="btn btn-primary-lappiz p-btn-lappiz" (click)="clearImage()" title="Eliminar"
        style="text-align: center; margin: 10px 1rem">
        <i class="fa fa-trash" style="color: white"></i>
        <span style="color: white; text-align: center;" translate="carrusel_uploadFiles"></span>
      </button>
    </div>
  </div>
</div>
