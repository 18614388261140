import { Component, OnInit, Input, AfterViewInit } from '@angular/core';

import { ProxyTxApiService } from '@services/proxy-tx-api.service';

import backandGlobal from '@env/env';
import { DocumentsService } from '@services/documents.service';

@Component({
  selector: 'app-pdfvisor',
  templateUrl: './pdfvisor.component.html',
  styleUrls: ['./pdfvisor.component.scss']
})
export class PDFVisorComponent implements OnInit, AfterViewInit {

  @Input() fileId: any;

  pdfSrc: any;
  urlTx: string;
  zoom_to: number = 1.0;
  originalSize: boolean = true;

  constructor(
    private proxyTxApiService: ProxyTxApiService,
    private documentsService: DocumentsService,
  ) { }

  ngAfterViewInit(): void {
    this.loadPDF();
  }

  ngOnInit(): void { }

  loadPDF() {
    let currentApp = JSON.parse(sessionStorage.getItem('currentApp'));
    let url = '';
    if (currentApp.storageProvider) { 
      this.documentsService.downloadFile(this.fileId).then(response => {
        if(currentApp.storageProvider == "AzureBlobStorage"){
          url = `data:application/octet-stream;base64,${response}`;
        }else{
          url = JSON.parse(response).url;
        }
        this.pdfSrc = url;
        this.fileId = this.urlTx;
      }).catch((error)=>{
        console.log(error)
      });
    }else{
      this.urlTx = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/documents/${this.fileId}.pdf`;
      this.proxyTxApiService.getTxFiles(`${this.urlTx}`, backandGlobal.stateOnline).then((response: any) => {
        this.pdfSrc = response;
      }).catch((error: any) => {
        console.log(error);
      });
    }
  }

  zoom_in() {
    this.zoom_to = this.zoom_to + 0.25;
  }

  zoom_out() {
    if (this.zoom_to > 1) {
       this.zoom_to = this.zoom_to - 0.25;
    }
  }
  
  
  incrementZoom(amount: number) {
    this.zoom_to += amount;   
  }
}
