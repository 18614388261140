<div class="row">
  <div class="col-md-12 stretch-card">
    <div class="card">
      <div class="card-body card-body-lappiz">
        <form class="forms-sample" [formGroup]="formRecovery" (ngSubmit)="onRecovery($event)">
          <div class="d-flex" style="justify-content: space-between">
            <h3 class="card-title" style="margin-bottom: 0;">Cambiar contraseña</h3>
            <button type="submit" class="btn btn-primary mr-2 mb-2 mb-md-0" (click)="onRecovery($event)"
              [disabled]="!formRecovery.valid || passwordFaild">
              <i class="feather icon-save"></i>
            </button>
          </div>
          <hr>
          <div class="form-group">
            <label for="password">Contraseña actual*</label>
            <div class="input-group mb-3">
              <input [type]="showPassword ? 'text' : 'password'" class="form-control" id="password"
                placeholder="Contraseña actual" formControlName="password" (change)="validatePassword()" />
              <div class="input-group-prepend">
                <span class="input-group-text input-pass" id="basic-addon1">
                  <i alt="show" class="fa fa-eye eye-show" (click)="showPassword = !showPassword"
                    [class.hide]="showPassword"></i>
                  <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showPassword = !showPassword"
                    [class.hide]="!showPassword"></i>
                </span>
              </div>
            </div>
            <div class="error"
              *ngIf="formRecovery.controls.password.errors?.required && formRecovery.controls.password.touched">
              El campo contraseña actual es requerido
            </div>
            <div class="error"
              *ngIf="formRecovery.controls.password.errors?.pattern && formRecovery.controls.password.touched">
              La contraseña debe contener mínimo 10 caracteres, un caracter especial, una mayúscula,
              una minúscula y un número
            </div>
            <div class="error" *ngIf="passwordFaild">
              La contraseña actual es incorrecta. Inténtalo nuevamente
            </div>
          </div>
          <div class="form-group">
            <label for="newPassword">Nueva contraseña*</label>
            <div class="input-group mb-3">
              <input [type]="showNewPassword ? 'text' : 'password'" class="form-control" id="newPassword"
                placeholder="Nueva contraseña" formControlName="newPassword" (change)="validatePassword()" />
              <div class="input-group-prepend">
                <span class="input-group-text input-pass" id="basic-addon1">
                  <i alt="show" class="fa fa-eye eye-show" (click)="showNewPassword = !showNewPassword"
                    [class.hide]="showNewPassword"></i>
                  <i alt="hide" class="fa fa-eye-slash eye-hide" (click)="showNewPassword = !showNewPassword"
                    [class.hide]="!showNewPassword"></i>
                </span>
              </div>
            </div>
            <div class="error"
              *ngIf="formRecovery.controls.newPassword.errors?.required && formRecovery.controls.newPassword.touched">
              El campo nueva contraseña es requerido
            </div>
            <div class="error"
              *ngIf="formRecovery.controls.newPassword.errors?.pattern && formRecovery.controls.newPassword.touched">
              La contraseña debe contener mínimo 10 caracteres, un caracter especial, una mayúscula,
              una minúscula y un número
            </div>
          </div>
          <div class="form-group">
            <label for="confirmNewPassword">Confirmar nueva contraseña*</label>
            <div class="input-group mb-3">
              <input [type]="showConfirmNewPassword ? 'text' : 'password'" class="form-control" id="confirmNewPassword"
                placeholder="Confirmar nueva contraseña" formControlName="confirmNewPassword"
                (change)="validatePassword()" />
              <div class="input-group-prepend">
                <span class="input-group-text input-pass" id="basic-addon1">
                  <i alt="show" class="fa fa-eye eye-show" (click)="showConfirmNewPassword = !showConfirmNewPassword"
                    [class.hide]="showConfirmNewPassword"></i>
                  <i alt="hide" class="fa fa-eye-slash eye-hide"
                    (click)="showConfirmNewPassword = !showConfirmNewPassword"
                    [class.hide]="!showConfirmNewPassword"></i>
                </span>
              </div>
            </div>
            <div class="error"
              *ngIf="formRecovery.controls.confirmNewPassword.errors?.required && formRecovery.controls.confirmNewPassword.touched">
              El campo confirmar nueva contraseña es requerido
            </div>
            <div class="error"
              *ngIf="formRecovery.controls.confirmNewPassword.errors?.pattern && formRecovery.controls.confirmNewPassword.touched">
              La contraseña debe contener mínimo 10 caracteres, un caracter especial, una mayúscula,
              una minúscula y un número
            </div>
          </div>
          <div class="error-recovery" *ngIf="errorMessage != ''">
            <div class="form-group has-error text-left">
              <span class="help-block">{{ errorMessage }}</span>
            </div>
          </div>
          <div class="error-recovery" *ngIf="errorMessagePassword != ''">
            <div class="form-group has-error text-left">
              <span class="help-block">{{ errorMessagePassword }}</span>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
