import { Injectable, ViewChild } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import Speech from 'speak-tts';
import { v4 as uuidv4 } from 'uuid';

import  swal from 'sweetalert2'
import { LoaderViewComponent } from '@components/loader-view/loader-view.component';
import { PopupComponent } from '@components/popup/popup.component';

import { ProxyConfigApiService } from '@services/proxy-config-api.service';

import { RootScopeService } from '@shared/root-scope.service';

import backandGlobal from '@env/env';
import { ILFCall } from '../interfaces/ILFCall';
import { NotificationsService } from './notifications.service';
import { Constants } from '@common/constants';

@Injectable({
  providedIn: 'root'
})
export class ApplicationService {

  @ViewChild(LoaderViewComponent)
  private loaderComponent!: LoaderViewComponent;

  @ViewChild(PopupComponent)
  private popupComponent!: PopupComponent;
  speech: any;
  Swal: any;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private http: HttpClient,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private proxyConfigApiService: ProxyConfigApiService,
    private $rs: RootScopeService,
    private notificationService: NotificationsService
  ) {
    this.Swal = swal;
    var myService = this;

    myService.evalEvent = (implementation: any, e: any) => {
      var functions = `var toastr = myService.toastr; var getUser = myService.getUser; var getEntityId = myService.getEntityId;
var getAppViewId = myService.getAppViewId; var getNewId = myService.getNewId; var visibilityLoader = myService.visibilityLoader;
var goLocation = myService.goLocation; var sendEmail = myService.sendEmail; var execQuery = myService.execQuery; var execSP = myService.execSP;
var openCustomModal = myService.openCustomModal; var closeModal = myService.closeModal; var sendSMS = myService.sendSMS;
var sendPush = myService.sendPush; var speakMe = myService.speakMe;var execLF = myService.execLF; var Swal = myService.Swal;`;

      var functionsToAdd = `var getDate = myService.getDate;
      var restService = myService.restService;`;

      for (var i in this.$rs.$rootScope.rules) {
        functions += this.$rs.$rootScope.rules[i].Implementation;
      }

      var wrapper = `try { ${implementation} } catch (error) { console.error("Error: " + error); }`;

      try {
        eval(functions + wrapper);
        if (e.cancel !== undefined) return !e.cancel;
        else return true;
      } catch (error) {
        var message =  Constants.MESSAGES.ErrorRules;
        this.notificationService.notificationApp('error', `${message} ${error}`);
      }
    }

    myService.transformFunction = (implementation: any) => {
      /*
        Cambiar esta forma de llamar la función, la acción al momento de crearla debe tener un campo
        Implementación y no un combo para seleccionar la regla
      */
      var methodName = implementation.split('function ');
      if (methodName.length > 1) {
        methodName = methodName[1].split('(');
        if (methodName.length > 1) {
          methodName = methodName[0];
          //Se hace esto para poder ejecutar la función que llega, validar si mejor se puede quitar la validación de function
          implementation += `${methodName}();`;
        }
      }

      return implementation;
    }

    myService.fireEvents = (eventName: any, events: any, param: any) => {
      var result = true;
      if (!events) return result;
      let generalEvents = events.filter((event: any) => {
        return event.EventTypeName == 'General' || event.EventTypeName == 'GlobalGeneral';
      });
      var implementations = events.filter((event: any) => {
        return event.EventTypeName == eventName;
      });
      if (implementations.length == 0) return result;
      implementations = generalEvents.concat(implementations)
      let stringImplementtions = '';
      for (var i in implementations) {
        var impl = implementations[i];
        stringImplementtions += impl.Implementation;
      }
      try {
        result = this.evalEvent(stringImplementtions, param);
      } catch (error) {
        var message = Constants.MESSAGES.ErrorRules;
        this.notificationService.notificationApp('error', `${message} ${error}`);
      }
      return result;
    }

    myService.getUser = () => {
      return JSON.parse(sessionStorage.LappizUser);
    }

    myService.getEntityId = () => {
      return this.route.snapshot.queryParams.entityId;
    }

    myService.getAppViewId = () => {
      return this.route.snapshot.queryParams.appViewId;
    }

    myService.getNewId = () => {
      return uuidv4();
    }

    myService.visibilityLoader = (isVisible: any) => {
      this.loaderComponent.loader(isVisible);
    }

    myService.goLocation = (url: string) => {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        location.assign(url);
      });
    }

    myService.sendEmail = (smtpsender: string, to: any, subject: any, text: any, html: any, attachments: any[] = [], cc: any[] = [], bcc: any[] = []) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('Authorization')
        })
      };

      var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/utiles/sendEmail`;
      var dataEmail = JSON.stringify({ smtpsender: smtpsender, to: to, subject: subject, text: text, html: html, attachments: attachments, cc: cc, bcc: bcc, parameters: { aType: "sendMail", environment: `${backandGlobal.environment}` } });

      return this.http.post(url, dataEmail, httpOptions).toPromise();
    }

    myService.execDB = (query: string) => {
      var tenantId = sessionStorage.tenantId;
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('Authorization')
        })
      };

      var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/lappiz/sp/query`;

      var opt = {
        query,
        tenantId,
        parameters: {
          aType: "execTx",
          environment: backandGlobal.environment,
          userId: sessionStorage.userId
        }
      }

      return this.http.post(url, opt, httpOptions).toPromise();
    }

    myService.execQuery = (query: string) => {
      return myService.execDB(query);
    }

    myService.execSP = (spname: string, params: any[]) => {
      var command = `exec ${spname}`;
      if (params && params.length > 0) {
        command += ` ${params[0]}`;
        for (var i = 1; i < params.length; i++) {
          var element = params[i];
          command += `, ${element}`;
        }
      }

      return myService.execDB(command);
    }

    myService.openCustomModal = (config: any, done: any, cancel: any) => {
      if (config) {
        if (!config.dataItem) config.dataItem = {};
        if (!config.parent) config.parent = {};
        var modalTemplate = '', headerTemplate = '', bodyTemplate = '', footerTemplate = '';

        if (config.htmlTemplate) {
          // Se abre una popup con el html inyectado
          if (config.headerTemplate) {
            headerTemplate = `<div class="modal-header">
              ${config.headerTemplate}
              <button type="button" id="btn-close" class="close" aria-label="Close" (click)="close()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>`;
          }

          if (config.bodyTemplate) {
            let classModal = `modal-body ${config.scrollable ? 'modal-body-scrollable' : ''}`;
            bodyTemplate = `<div class="${classModal}">
              ${config.bodyTemplate}
            </div>`;
          }

          if (config.footerTemplate) {
            footerTemplate = `<div class="modal-footer">
              ${config.footerTemplate}
              <br>`;
            if (config.showBtnsFooter) {
              footerTemplate += `<div class="col-md-12 text-center">
                <button class="btn btn-default active" type="button" id="btn-ok" (click)="ok()">Aceptar</button>
                <button class="btn btn-default active-color" type="button" id="btn-cancel" (click)="cancel()">Cancelar</button>
              </div>`;
            }

            footerTemplate += `</div>`;
          }

          modalTemplate = `${headerTemplate}
          ${bodyTemplate}
          ${footerTemplate}`;
        }

        var modalInstance = this.modalService.open(PopupComponent, {
          animation: true,
          size: config.size ? config.size : 'lg',
          scrollable: config.scrollable ? config.scrollable : false,
          centered: config.centered ? config.centered : false,
          backdrop: 'static',
          keyboard: config.keyboard ? config.keyboard : true
        });

        modalInstance.componentInstance.modalTemplate = modalTemplate;
        modalInstance.componentInstance.htmlTemplate = modalTemplate;
        modalInstance.componentInstance.dataToSubmit = config.dataItem;
        modalInstance.componentInstance.entityId = config.entityId;
        modalInstance.componentInstance.entityParentId = config.parent.Id;
        modalInstance.componentInstance.parent = config.parent.fkCode;
        modalInstance.componentInstance.parentRules = config.parentRules;
        modalInstance.componentInstance.rowId = config.dataItem.Id;
        modalInstance.componentInstance.viewName = config.viewName;

        modalInstance.componentInstance.done = done;
        modalInstance.componentInstance.cancelModal = cancel;

        modalInstance.result.then(done).catch(cancel);

        return modalInstance;
      }
    }

    myService.closeModal = (data: any) => {
      this.popupComponent.closeModal(this, data);
    }

    myService.sendSMS = (destination: string, message: string) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('Authorization')
        })
      };
      var data = {
        "message": message,
        "destination": destination
      }
      var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/utiles/sendSMS`;
      var dataSend = JSON.stringify({ data: data, parameters: { aType: "sendSMS", environment: `${backandGlobal.environment}` } });
      return this.http.post(url, dataSend, httpOptions).toPromise();
    }

    myService.sendPush = (usersId: string, data: any) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('Authorization')
        })
      };
      var urlTx = `${backandGlobal.url}/api/notification/getAllowNotification`;
      this.proxyConfigApiService.getSuscriptions(usersId, urlTx).then(pushSuscription => {
        var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/utiles/pushNotification`;
        var dataNotification = JSON.stringify({ pushSuscription: pushSuscription, data: data, parameters: { aType: "pushNotification", environment: `${backandGlobal.environment}` } });
        return this.http.post(url, dataNotification, httpOptions).toPromise();
      }).catch(err => {
        console.log(err);
        this.notificationService.notificationApp('error', Constants.MESSAGES.FailNotification);
        return err;
      });
    }

    myService.speakMe = (lang: string = 'es-ES', voice: string = 'Google español', text: string = '') => {
      this.stop();
      this.speech = null;
      this.speech = new Speech(); // will throw an exception if not browser supported
      if (this.speech.hasBrowserSupport()) {
        // returns a boolean
        this.speech
          .init({
            volume: 1,
            lang: lang,
            rate: 1,
            pitch: 1,
            voice: voice,
            splitSentences: true,
            listeners: {
              onvoiceschanged: (voices) => {
                console.log('Event voiceschanged', voices);
              },
            },
          })
      }

      var temporalDivElement = document.createElement('div');
      // Set the HTML content with the providen
      temporalDivElement.innerHTML = text;
      // Retrieve the text property of the element (cross-browser support)
      var result =
        temporalDivElement.textContent || temporalDivElement.innerText || '';

      this.speech
        .speak({
          text: result,
        })
        .then(() => {
          console.log('Success !');
        })
        .catch((e) => {
          console.error('An error occurred :', e);
        });
    }

    myService.stop = () => {
      this.speech?.cancel();
    }

    myService.getDate = () => {
      var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/utiles/date`;
      this.http.get(url, { responseType: 'text' }).toPromise().then((response: any) => {
        var resultDate = response;
        return new Date(JSON.parse(resultDate).timeinmil);
      });
    }

    myService.restService = (url: string, method: any, format: any, body: any) => {
      var restObject = { url, method, format, body };
      var urlConfig = `${backandGlobal.url}/Api/api/utils/restService`;

      return this.proxyConfigApiService.getConfigDataPost(`${urlConfig}`, JSON.stringify(restObject));
    }

    myService.execLF = (config: ILFCall) => {
      if (!config.nameFunction) return { status: 400, message: 'el campo "nameFunction" es requerido' };
      if (!config.lappizFunctionId) return { status: 400, message: 'el campo "lappizFunctionId" es requerido' };
      const method = config.method || 'post';
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('Authorization') || ''
        })
      };

      var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/functions/${config.nameFunction}`;

      var opt = {
        parameters: {
          userId: sessionStorage.userId,
          aType: "lappizFunction",
          pType: "Execute",
          lappizFunctionId: config.lappizFunctionId,
          environment: `${backandGlobal.environment}`
        }
      }
      if (config.body) {
        let keys = Object.keys(config.body)
        keys.forEach(key => {
          opt[key] = config.body[key];
        })
      }
      return this.http[method.toLowerCase()](url, opt, httpOptions).toPromise();
    }

  }

  evalEvent(implementation: any, e: any) {
    var functions = `var toastr = myService.toastr; var getUser = myService.getUser; var getEntityId = myService.getEntityId;
var getAppViewId = myService.getAppViewId; var getNewId = myService.getNewId; var visibilityLoader = myService.visibilityLoader;
var goLocation = myService.goLocation; var sendEmail = myService.sendEmail; var execQuery = myService.execQuery; var execSP = myService.execSP;
var openCustomModal = myService.openCustomModal; var closeModal = myService.closeModal; var sendSMS = myService.sendSMS;
var sendPush = myService.sendPush; var speakMe = myService.speakMe;var execLF = myService.execLF; var Swal = myService.Swal;;

`;

    var functionsToAdd = `var getDate = myService.getDate;
    var restService = myService.restService;`;

    for (var i in this.$rs.$rootScope.rules) {
      functions += this.$rs.$rootScope.rules[i].Implementation;
    }

    var wrapper = `try { ${implementation} } catch (error) { console.error("Error: " + error); }`;

    try {
      eval(functions + wrapper);
      if (e.cancel !== undefined) return !e.cancel;
      else return true;
    } catch (error) {
      var message = Constants.MESSAGES.ErrorRules;
      this.notificationService.notificationApp('error', `${message} ${error}`);
    }
  }

  transformFunction(implementation: any) {
    /*
      Cambiar esta forma de llamar la función, la acción al momento de crearla debe tener un campo
      Implementación y no un combo para seleccionar la regla
    */
    var methodName = implementation.split('function ');
    if (methodName.length > 1) {
      methodName = methodName[1].split('(');
      if (methodName.length > 1) {
        methodName = methodName[0];
        //Se hace esto para poder ejecutar la función que llega, validar si mejor se puede quitar la validación de function
        implementation += `${methodName}();`;
      }
    }

    return implementation;
  }

  fireEvents(eventName: any, events: any, param: any) {
    var result = true;
    if (!events) return result;
    let generalEvents = events.filter((event: any) => {
      return event.EventTypeName == 'General' || event.EventTypeName == 'GlobalGeneral';
    });
    var implementations = events.filter((event: any) => {
      return event.EventTypeName == eventName;
    });
    if (implementations.length == 0) return result;
    implementations = generalEvents.concat(implementations)
    let stringImplementtions = '';
    for (var i in implementations) {
      var impl = implementations[i];
      stringImplementtions += impl.Implementation;
    }
    try {
      result = this.evalEvent(stringImplementtions, param);
    } catch (error) {
      var message = Constants.MESSAGES.ErrorRules;
      this.notificationService.notificationApp('error', `${message} ${error}`);
    }
    return result;
  }

  getUser() {
    return JSON.parse(sessionStorage.LappizUser);
  }

  getEntityId() {
    return this.route.snapshot.queryParams.entityId;
  }

  getAppViewId() {
    return this.route.snapshot.queryParams.appViewId;
  }

  getNewId() {
    return uuidv4();
  }

  visibilityLoader(isVisible: boolean) {
    this.loaderComponent.loader(isVisible);
  }

  goLocation(url: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      location.assign(url);
    });
  }

  sendEmail(smtpsender: string, to: any, subject: any, text: any, html: any, attachments: any[] = [], cc: any[] = [], bcc: any[] = []) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('Authorization')
      })
    };

    var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/utiles/sendEmail`;
    var dataEmail = JSON.stringify({ smtpsender: smtpsender, to: to, subject: subject, text: text, html: html, attachments: attachments, cc: cc, bcc: bcc, parameters: { aType: "sendMail", environment: `${backandGlobal.environment}` } });

    return this.http.post(url, dataEmail, httpOptions).toPromise();
  }

  execDB(query: string) {
    var tenantId = sessionStorage.tenantId;
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('Authorization')
      })
    };

    var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/lappiz/sp/query`;

    var opt = {
      query,
      tenantId,
      parameters: {
        aType: "execTx",
        environment: backandGlobal.environment,
        userId: sessionStorage.userId
      }
    }

    return this.http.post(url, opt, httpOptions).toPromise();
  }

  execQuery(query: string) {
    return this.execDB(query);
  }

  execSP(spname: string, params: any[]) {
    var command = `exec ${spname}`;
    if (params && params.length > 0) {
      command += ` ${params[0]}`;
      for (var i = 1; i < params.length; i++) {
        var element = params[i];
        command += `, ${element}`;
      }
    }

    return this.execDB(command);
  }

  openCustomModal(config: any, done: any, cancel: any) {
    if (config) {
      if (!config.dataItem) config.dataItem = {};
      if (!config.parent) config.parent = {};
      var modalTemplate = '', headerTemplate = '', bodyTemplate = '', footerTemplate = '';

      if (config.htmlTemplate) {
        // Se abre una popup con el html inyectado
        if (config.headerTemplate) {
          headerTemplate = `<div class="modal-header">
            ${config.headerTemplate}
            <button type="button" id="btn-close" class="close" aria-label="Close" (click)="close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>`;
        }

        if (config.bodyTemplate) {
          let classModal = `modal-body ${config.scrollable ? 'modal-body-scrollable' : ''}`;
          bodyTemplate = `<div class="${classModal}">
            ${config.bodyTemplate}
          </div>`;
        }

        if (config.footerTemplate) {
          footerTemplate = `<div class="modal-footer">
            ${config.footerTemplate}
            <br>`;
          if (config.showBtnsFooter) {
            footerTemplate += `<div class="col-md-12 text-center">
              <button class="btn btn-default active" type="button" id="btn-ok" (click)="ok()">Aceptar</button>
              <button class="btn btn-default active-color" type="button" id="btn-cancel" (click)="cancel()">Cancelar</button>
            </div>`;
          }

          footerTemplate += `</div>`;
        }

        modalTemplate = `${headerTemplate}
        ${bodyTemplate}
        ${footerTemplate}`;
      }

      var modalInstance = this.modalService.open(PopupComponent, {
        animation: true,
        size: config.size ? config.size : 'lg',
        scrollable: config.scrollable ? config.scrollable : false,
        centered: config.centered ? config.centered : false,
        backdrop: 'static',
        keyboard: config.keyboard ? config.keyboard : true
      });

      modalInstance.componentInstance.modalTemplate = modalTemplate;
      modalInstance.componentInstance.htmlTemplate = modalTemplate;
      modalInstance.componentInstance.dataToSubmit = config.dataItem;
      modalInstance.componentInstance.entityId = config.entityId;
      modalInstance.componentInstance.entityParentId = config.parent.Id;
      modalInstance.componentInstance.parent = config.parent.fkCode;
      modalInstance.componentInstance.parentRules = config.parentRules;
      modalInstance.componentInstance.rowId = config.dataItem.Id;
      modalInstance.componentInstance.viewName = config.viewName;

      modalInstance.componentInstance.done = done;
      modalInstance.componentInstance.cancelModal = cancel;

      modalInstance.result.then(done).catch(cancel);

      return modalInstance;
    }
  }

  closeModal(data: any) {
    this.popupComponent.closeModal(this, data);
  }

  sendSMS = (destination: string, message: string) => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('Authorization')
      })
    };
    var data = {
      "message": message,
      "destination": destination
    }
    var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/utiles/sendSMS`;
    var dataSend = JSON.stringify({ data: data, parameters: { aType: "sendSMS", environment: `${backandGlobal.environment}` } });
    return this.http.post(url, dataSend, httpOptions).toPromise();
  }

  sendPush = (usersId: string, data: any) => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('Authorization')
      })
    };
    var urlTx = `${backandGlobal.url}/api/notification/getAllowNotification`;
    this.proxyConfigApiService.getSuscriptions(usersId, urlTx).then(pushSuscription => {
      var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/utiles/pushNotification`;
      var dataNotification = JSON.stringify({ pushSuscription: pushSuscription, data: data, parameters: { aType: "pushNotification", environment: `${backandGlobal.environment}` } });
      return this.http.post(url, dataNotification, httpOptions).toPromise();
    }).catch(err => {
      console.log(err);
      this.notificationService.notificationApp('error', Constants.MESSAGES.FailNotification);
      return err;
    });
  }

  speakMe(lang: string = 'es-ES', voice: string = 'Google español', text: string = '') {
    this.stop();
    this.speech = new Speech(); // will throw an exception if not browser supported
    if (this.speech.hasBrowserSupport()) {
      // returns a boolean
      this.speech
        .init({
          volume: 1,
          lang: lang,
          rate: 1,
          pitch: 1,
          voice: voice,
          splitSentences: true,
          listeners: {
            onvoiceschanged: (voices) => {
              console.log('Event voiceschanged', voices);
            },
          },
        })
    }

    var temporalDivElement = document.createElement('div');
    // Set the HTML content with the providen
    temporalDivElement.innerHTML = text;
    // Retrieve the text property of the element (cross-browser support)
    var result =
      temporalDivElement.textContent || temporalDivElement.innerText || '';

    this.speech
      .speak({
        text: result,
      })
      .then(() => {
        console.log('Success !');
      })
      .catch((e) => {
        console.error('An error occurred :', e);
      });
  }

  stop() {
    this.speech?.cancel();
  }

  getDate() {
    var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/utiles/date`;
    this.http.get(url, { responseType: 'text' }).toPromise().then((response: any) => {
      var resultDate = response;
      return new Date(JSON.parse(resultDate).timeinmil);
    });
  }

  restService(url: string, method: any, format: any, body: any) {
    var restObject = { url, method, format, body };
    var urlConfig = `${backandGlobal.url}/Api/api/utils/restService`;

    return this.proxyConfigApiService.getConfigDataPost(`${urlConfig}`, JSON.stringify(restObject));
  }

  execLF(config: ILFCall) {
    if (!config.nameFunction) return { status: 400, message: 'el campo "nameFunction" es requerido' };
    if (!config.lappizFunctionId) return { status: 400, message: 'el campo "lappizFunctionId" es requerido' };
    const method = config.method || 'post';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('Authorization') || ''
      })
    };

    var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/functions/${config.nameFunction}`;

    var opt = {
      parameters: {
        userId: sessionStorage.userId,
        aType: "lappizFunction",
        pType: "Execute",
        lappizFunctionId: config.lappizFunctionId,
        environment: `${backandGlobal.environment}`
      }
    }
    if (config.body) {
      let keys = Object.keys(config.body)
      keys.forEach(key => {
        opt[key] = config.body[key];
      })
    }
    return this.http[method.toLowerCase()](url, opt, httpOptions).toPromise();
  }

}
