import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import { ProxyConfigApiService } from '@services/proxy-config-api.service';

import backandGlobal from '@env/env';
import { NotificationsService } from '@services/notifications.service';
import { Constants } from '@common/constants';

@Component({
  selector: 'app-rol',
  templateUrl: './rol.component.html',
  styleUrls: ['./rol.component.scss']
})
export class RolComponent implements OnInit {

  idRol: string;

  appViewId: any;
  dt: any;

  formRole: UntypedFormGroup;
  nombreRol : UntypedFormControl;
  descripcionRol: UntypedFormControl;

  constructor(
    private proxyConfigApiService: ProxyConfigApiService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private notificationService: NotificationsService
  ) {
    this.formRole = new UntypedFormGroup({
      nombreRol: new UntypedFormControl('',[
        Validators.required
      ]),
      descripcionRol: new UntypedFormControl('')
    });
  }

  ngOnInit(): void {
    this.idRol = this.activatedRoute.snapshot.queryParams.idRol;
    if (this.idRol != undefined || this.idRol != null) {
      this.loadInfo();
    }else{
      this.formRole.setValue({nombreRol : "", descripcionRol:""})
    }
  }

  SaveChanges(): void {
    if (this.idRol == undefined || this.idRol == null) {
      this.SaveRole();
    } else {
      this.EditRole();
    }
  }

  async SaveRole(): Promise<void> {
    var data = {
      'Name': `${backandGlobal.currentApp.name} ${this.formRole.value.nombreRol}`,
      'Description': this.formRole.value.descripcionRol,
      'AplicacionId': sessionStorage.workspaceId,
      'EmpresaId': sessionStorage.empresaId
    };

    let url = backandGlobal.url + '/api/Roles/CreateRol';
    await this.proxyConfigApiService.configSaveRoles(url, JSON.stringify(data)).then(result => {
      this.notificationService.notificationApp('success', Constants.MESSAGES.SuccessCreateRol);
      this.router.navigateByUrl('/roles');
    }).catch(error => {
      this.notificationService.notificationApp('error', Constants.MESSAGES.ExistsRol);
    });
  }

  EditRole(): void {
    var data = {
      'Id': this.idRol,
      'Name': this.formRole.value.nombreRol,
      'Description': this.formRole.value.descripcionRol
    };

    let url = backandGlobal.url + '/api/Roles/editRol';
    this.proxyConfigApiService.configPutRoles(url, JSON.stringify(data)).then(result => {
      this.notificationService.notificationApp('success', Constants.MESSAGES.SuccessChanges);
      this.router.navigateByUrl('/roles')
    }).catch(error => {
      this.notificationService.notificationApp('error', Constants.MESSAGES.FailChanges);
    });
  }

  loadInfo(): void {
    var url = backandGlobal.url + '/api/Roles/getRolById/' + this.idRol
    this.proxyConfigApiService.getConfigDataGet(url).then(result => {
      this.dt = result;
      this.formRole.setValue({nombreRol:this.dt.Nombre, descripcionRol:this.dt.Descripcion});
    });
  }

  Volver(): void {
    this.router.navigateByUrl('/roles');
  }

}
