import { Component, OnInit, Input, OnChanges, OnDestroy } from '@angular/core';
import { DatePipe, Location } from '@angular/common';
import { Router, ActivatedRoute, NavigationExtras, NavigationEnd } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as XLSX from 'xlsx';
import Swal from 'sweetalert2';
import JSZip from 'jszip';
window['JSZip'] = JSZip;

import { Constants } from '@common/constants';

import { CarouselModalComponent } from '@components/carousel/carousel-modal/carousel-modal.component';
import { SlideDescriptionComponent } from '@components/slide-description/slide-description.component';
import { RichTextVisorComponent } from '@components/rich-text-visor/rich-text-visor.component';
// import { SchedulerComponent } from '@components/scheduler/scheduler.component';
import { TripComponent } from '@components/trip/trip.component';
import { FileComponent } from '@components/file/file.component';
import { FileGridComponent } from '@components/file-grid/file-grid.component';
import { QrGridComponent } from '@components/qr-grid/qr-grid.component';
import { SignatureGridComponent } from '@components/signature-grid/signature-grid.component';

import { ApplicationService } from '@services/application.service';
import { ServerConfigApiService } from '@services/server-config-api.service';
import { ContextValuesService } from '@services/context-values.service';
import { GridService } from '@services/grid.service';
import { OdataFilterMapService } from '@services/odata-filter-map.service';
import { ProxyTxApiService } from '@services/proxy-tx-api.service';
import { UserService } from '@services/user.service';

import backandGlobal from '@env/env';
import { ProxyConfigApiService } from '@services/proxy-config-api.service';
import { ModalDetailsComponent } from '@components/details/modal-details/modal-details.component';
import { NotificationsService } from '@services/notifications.service';

declare var kendo: any;

@Component({
  selector: 'app-grids',
  templateUrl: './grids.component.html',
  styleUrls: ['./grids.component.scss'],
  providers: [DatePipe],
})
export class GridsComponent implements OnInit, OnChanges, OnDestroy {
  @Input() viewName: any;
  @Input() viewCode: any;
  @Input() options: any;
  @Input() filterOptions: any;
  @Input() inputStyle: any;
  @Input() buttonGroups: any;
  @Input() disableEditOnDblClick: any;
  @Input() entityId: any;

  sectionItemNav: string;
  idViewComponent: string;
  isDestroyOnHide: boolean = true;
  viewNameId: any;
  viewEntityName: string;

  url: any;

  showGrid: boolean;
  showMap: boolean;
  showSlide: boolean;
  showSchedulerView: boolean;

  waiting: boolean;
  load: boolean;

  customMessages: any;
  alerts = [];

  fieldsToMap: any[];
  fieldToSlide: any;
  fieldToScheduler: any;
  fieldToList: any;
  fieldMobile: string;
  fieldMobileTemplate: string;
  fieldNameDefault: string;

  configTable: any;
  mainGridOptions: any;
  gridColumns: any;

  searchParams: any;
  X: typeof XLSX;

  slideData = [];
  mapData = [];
  aggregates = [];
  arrayPositions = [];
  isMobile: any;
  dataEdit: any;
  editableFields: boolean;
  countChange: number;
  isAnonymous: boolean;
  showImportData: boolean = false;

  actionsMenuOptions: boolean;
  globalActions = [];

  viewsToOpen: any;

  nameBack: string;
  nameEntity: string;
  showAdd: boolean;
  showDelete: boolean;
  showEdit: boolean;
  showViewItem: boolean;
  showCancel: boolean;
  showTabsMenu: boolean;
  showToolbar: boolean;
  showSearch: boolean;
  showCreate: boolean;
  logicalDelete: boolean;
  collapseFilter: any;
  dataSort: any;
  odataFilterOptions: any;
  filterGridOptions: any;
  placeholderSearch: string = 'Filtrar lista';

  dataItemLogicalEntity: any;
  value: any;

  navigationSubscription: any;
  sortGrid: any;
  private isAinputTypeSearchFilter: boolean;
  navigationSuscription: any;

  constructor(
    public datePipe: DatePipe,
    private locationApp: Location,
    private route: ActivatedRoute,
    private router: Router,
    private modalService: NgbModal,
    private applicationService: ApplicationService,
    private configService: ServerConfigApiService,
    private contextValuesService: ContextValuesService,
    private gridService: GridService,
    private odataFilterMap: OdataFilterMapService,
    private proxyTxApiService: ProxyTxApiService,
    private proxyConfigApiService: ProxyConfigApiService,
    private userService: UserService,
    private tripComponent: TripComponent,
    private slideDescriptionComponent: SlideDescriptionComponent,
    private notificationsService: NotificationsService
  ) {
    kendo.culture('es-CO');
    this.customMessages = Constants.MESSAGES;
    this.initializeVariables();

    this.navigationSubscription = this.router.events.subscribe((e: any) => this.handleNavigationEvent(e));
  }

  private handleNavigationEvent(event: any) {
    if (event instanceof NavigationEnd && $(`#${this.idViewComponent}`).hasClass('k-grid')) {
      const grid = kendo.jQuery(`#${this.idViewComponent}`).data('kendoGrid');
      if (grid) {
        grid.destroy();
      }
      $(`#${this.idViewComponent}`).empty();
    }
    this.isAinputTypeSearchFilter = false;
    this.navigationSuscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if ($(`#${this.idViewComponent}`).hasClass('k-grid')) {
          var grid = kendo.jQuery(`#${this.idViewComponent}`).data('kendoGrid');
          if (grid) {
            grid.destroy();
          }

          $(`#${this.idViewComponent}`).empty();
        }
      }
    });
  }

  private initializeVariables() {
    this.isMobile = $(window).width() < 768;
    this.dataEdit = null;
    this.editableFields = false;
    this.countChange = 0;
    this.isAnonymous = !localStorage.getItem('Authorization');

    this.fieldsToMap = [];
    this.fieldToSlide = { images: [], names: [], description: [] };
    this.fieldToScheduler = {
      title: { field: [], fieldName: [] },
      start: [],
      end: [],
      description: { field: [], fieldName: [] },
    };
    this.fieldToList = { nameField: [], value: [], filter: [], type: [] };
    this.fieldMobile = 'var fieldsMobile = {';
    this.fieldMobileTemplate = '';

    this.viewsToOpen = {};
    this.dataItemLogicalEntity = {};
    this.X = XLSX;
    this.idViewComponent = this.inputStyle == 'inForm' ? this.viewCode : 'grid1';
  }


  ngOnInit(): void {
    this.initializeVariables();

    this.viewName = this.viewName || this.route.snapshot.queryParams.viewName;
    this.viewNameId = this.viewName;
    this.entityId = this.entityId || this.route.snapshot.queryParams.entityId;

    kendo.ui.progress($(`#${this.idViewComponent}`), true);
    var baseUrl = 'https://kendo.cdn.telerik.com/2022.2.510/js/messages/kendo.messages.';
    $.getScript(`${baseUrl}es-CO.min.js`, () => {
      kendo.ui.progress($(`#${this.idViewComponent}`), false);
    });

    this.buildGrid(this.viewNameId, this.entityId);
  }

  ngOnChanges(): void {
    this.viewName = this.viewName || this.route.snapshot.queryParams.viewName;
    this.viewNameId = this.viewName;
    this.entityId = this.entityId || this.route.snapshot.queryParams.entityId;

    this.buildGrid(this.viewNameId, this.entityId);
  }

  ngOnDestroy() {
    if (this.navigationSubscription) {
      this.navigationSubscription.unsubscribe();
    }
  }

  /**
   * Método encargado de navegar a la vista que fue abierta antes de la grid
   */
  cancelar() {
    if (!localStorage.getItem('Authorization')) {
      this.router.navigate([
        `auth/login/${sessionStorage.getItem('workspace')}`,
      ]);
    } else {
      // window.history.back();
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.locationApp.back();
      });
    }
  }

  /**
   * Método encargado de refrescar/actualizar data de la grid
   */
  refresh() {
    kendo.jQuery(`#${this.idViewComponent}`).data('kendoGrid').dataSource.read();
    kendo.jQuery(`#${this.idViewComponent}`).data('kendoGrid').refresh();

    this.setActionsGrid();
    this.setGridClass();
  }

  /**
   * Método encargado de bindear las acciones a la grid
   */
  setActionsGrid() {
    setTimeout(() => {
      var actionsClick = kendo.observable({
        editClick: (e: any) => {
          var params = $(e.currentTarget).data();
          e.dataItem = kendo
            .jQuery(`#${this.idViewComponent}`)
            .data('kendoGrid')
            .dataItem($(e.currentTarget).closest('tr'));
          this.editSelected(e);
        },
        deleteClick: (e: any) => {
          var params = $(e.currentTarget).data();
          e.dataItem = kendo
            .jQuery(`#${this.idViewComponent}`)
            .data('kendoGrid')
            .dataItem($(e.currentTarget).closest('tr'));
          this.deleteSelected(e);
        },
        logicalDeleteClick: (e: any) => {
          var params = $(e.currentTarget).data();
          e.dataItem = kendo
            .jQuery(`#${this.idViewComponent}`)
            .data('kendoGrid')
            .dataItem($(e.currentTarget).closest('tr'));
          this.logicalDeleteSelected(e);
        },
        viewItemClick: (e: any) => {
          var params = $(e.currentTarget).data();
          e.dataItem = kendo
            .jQuery(`#${this.idViewComponent}`)
            .data('kendoGrid')
            .dataItem($(e.currentTarget).closest('tr'));
          this.viewSelected(e);
        },
        saveRowClick: (e: any) => {
          var params = $(e.currentTarget).data();
          e.dataItem = kendo
            .jQuery(`#${this.idViewComponent}`)
            .data('kendoGrid')
            .dataItem($(e.currentTarget).closest('tr'));
          this.saveSelected(e);
        },
        navigationClick: (e: any) => {
          var params = $(e.currentTarget).data();
          var queryString = params.querystring;
          e.dataItem = kendo
            .jQuery(`#${this.idViewComponent}`)
            .data('kendoGrid')
            .dataItem($(e.currentTarget).closest('tr'));
          this.openDirective(queryString, e);
        },
        actionCodeClick: (e: any) => {
          var params = $(e.currentTarget).data();
          var actionId = params.actionid;
          e.dataItem = kendo
            .jQuery(`#${this.idViewComponent}`)
            .data('kendoGrid')
            .dataItem($(e.currentTarget).closest('tr'));
          this.actionCode(actionId, e);
        },
        openDocumentsClick: (e: any) => {
          var params = $(e.currentTarget).data();
          var codeDocument = params.code;
          e.dataItem = kendo
            .jQuery(`#${this.idViewComponent}`)
            .data('kendoGrid')
            .dataItem($(e.currentTarget).closest('tr'));
          this.openDocumentsModal(e, codeDocument);
        },
        openQRCodeClick: (e: any) => {
          var params = $(e.currentTarget).data();
          var codeDocument = params.code;
          e.dataItem = kendo
            .jQuery(`#${this.idViewComponent}`)
            .data('kendoGrid')
            .dataItem($(e.currentTarget).closest('tr'));
          this.openQRCodeModal(e, codeDocument);
        },
        openSignatureClick: (e: any) => {
          var params = $(e.currentTarget).data();
          var codeDocument = params.code;
          e.dataItem = kendo
            .jQuery(`#${this.idViewComponent}`)
            .data('kendoGrid')
            .dataItem($(e.currentTarget).closest('tr'));
          this.openSignatureModal(e, codeDocument);
        },
        openRichTextClick: (e: any) => {
          var params = $(e.currentTarget).data();
          var codeRichText = params.code;
          e.dataItem = kendo
            .jQuery(`#${this.idViewComponent}`)
            .data('kendoGrid')
            .dataItem($(e.currentTarget).closest('tr'));
          this.openRichText(e, codeRichText);
        },
        selectImageClick: (e: any) => {
          var params = $(e.currentTarget).data();
          this.selectImage(params.position);
        },
      });

      kendo.bind($(`#${this.idViewComponent}`), actionsClick);
    }, 2500);
  }

  /**
   * Método encargado de setear estilos a la grid
   */
  setGridClass() {
    setTimeout(() => {
      $('.k-header.k-grid-toolbar').css({
        'background-color': '#ffffff',
      });

      $('.imagenGrid').css({
        display: 'inline-block',
        width: '50px',
        height: '50px',
        'border-radius': '50%',
        'background-size': '50px 50px',
        'background-position': 'center center',
        'vertical-align': 'middle',
        'line-height': '32px',
        'box-shadow': 'inset 0 0 1px #999, inset 0 0 10px rgba(0, 0, 0, 0.2)',
        'margin-left': '5px',
        border: 'none',
      });
    }, 1000);
  }

  /**
   * Método encargado de añadir columnas a la grid mobile
   */
  addFieldToListMobile(value: any) {
    if (value.type != 'carrousel') {
      this.fieldToList.nameField.push(value.type);
      this.fieldToList.type.push(value.type);

      if (value.isFromOtherEntity) {
        if (value.RutaGrid != null && value.RutaGrid != undefined) {
          this.fieldToList.value.push(value.RutaGrid.replace('Details.', '.'));
        } else {
          this.fieldToList.value.push(
            `${value.entityParent}/${value.name.replace(
              /[^a-zA-Z 0-9.]+/g,
              ''
            )}`
          );
        }
      } else if (value.FieldStandard === null) {
        this.fieldToList.value.push(value.name.replace(/[^a-zA-Z 0-9.]+/g, ''));
      } else {
        this.fieldToList.value.push(value.FieldStandard);
      }

      if (
        value.type != 'Boolean' &&
        value.type != 'Numeric' &&
        value.type != 'DateTime' &&
        value.type != 'Date'
      ) {
        this.fieldToList.filter.push(true);
      } else {
        this.fieldToList.filter.push(false);
      }
    }
  }

  /**
   * Método encargado de configurar las columnas de la grid
   */
  configColumns(gridColumns: any, fieldsModel: any, position: any) {
    return (value: any) => {
      var column: any;
      var fieldColumn: string = value.RutaGrid
        ? value.RutaGrid.split('Details.').join('.')
        : '';
      value.name = value.name.replace('_', '');

      if (this.fieldToList.value.length < 3 && value.isToListMobile) {
        this.addFieldToListMobile(value);
      }

      if (!value.isFromOtherEntity) {
        column = {
          field: value.name.charAt(0).toUpperCase() + value.name.slice(1),
          title: value.displayName,
          filterable: true,
          editable: function (_: any) {
            return value.IsEditableInGrid;
          },
          template: `#= ${value.name} != null ? ${value.name} : '' #`,
        };
      } else {
        column = {
          field: fieldColumn ? fieldColumn : valueNameGrid,
          title: value.displayName.split('/ ')[1],
          filterable: true,
          editable: function (_: any) {
            return value.IsEditableInGrid;
          },
          template: `#= ${fieldColumn.split('.')[0]
            } != null ? ${fieldColumn} : '' #`,
        };
      }

      if (
        value.type != 'Archive' &&
        value.type != 'QRCode' &&
        value.type != 'Signature'
      ) {
        var type = this.gridService.getColumnType(value);
      }

      if (!value.IsEditable) {
        value.IsEditableInGrid = value.IsEditable;
      }

      if (
        value.type != 'Archive' &&
        value.type != 'QRCode' &&
        value.type != 'Signature'
      ) {
        if (
          type == 'time' ||
          type == 'textarea' ||
          type == 'editor' ||
          type == 'text' ||
          type == 'radio' ||
          type == 'singleSelect' ||
          type == 'map'
        ) {
          fieldsModel[fieldColumn == '' ? value.name : fieldColumn] = {
            type: 'string',
            editable: function (_: any) {
              return value.IsEditableInGrid;
            },
            validation: {
              required(input: any) {
                if (input.val().length < 1 && value.advancedLayout.required) {
                  var message = 'Este campo es requerido.';
                  input.attr(message);
                  return false;
                }
                return true;
              },
              maxlength(input: any) {
                if (input.val().length > value.advancedLayout.maximumValue) {
                  var message = `La cantidad de caracteres es mayor a la permitida (max = ${value.advancedLayout.maximumValue})`;
                  input.attr('data-maxlength-msg', message);
                  return false;
                }
                return true;
              },
              minlength(input: any) {
                if (input.val().length < value.advancedLayout.minimumValue) {
                  var message = `La cantidad de caracteres es menor a la permitida (min = ${value.advancedLayout.minimumValue})`;
                  input.attr('data-minlength-msg', message);
                  return false;
                }
                return true;
              },
            },
          };
        } else if (type == 'checkbox') {
          fieldsModel[fieldColumn == '' ? value.name : fieldColumn] = {
            type: 'boolean',
          };
        } else if (
          type == 'currency' ||
          type == 'percentage' ||
          type == 'numberWithSeparator' ||
          type == 'numeric' ||
          type == 'cronometro' ||
          type == 'number' ||
          type == 'decimal'
        ) {
          fieldsModel[fieldColumn == '' ? value.name : fieldColumn] = {
            type: 'number',
            editable: function (_: any) {
              return value.IsEditableInGrid;
            },
            validation: {
              required(input: any) {
                if (input.val().length < 1 && value.advancedLayout.required) {
                  var message = 'Este campo es requerido';
                  input.attr(message);
                  return false;
                }
                return true;
              },
              min(input: any) {
                if (input.val() == '' && !value.advancedLayout.required) {
                  return true;
                }
                if (input.val() < value.advancedLayout.minimumValue) {
                  var message = `El valor digitado es menor al permitido (min = ${value.advancedLayout.minimumValue})`;
                  input.attr('data-min-msg', message);
                  return false;
                }
                return true;
              },
              max(input: any) {
                if (input.val() == '' && !value.advancedLayout.required) {
                  return true;
                }
                if (input.val() > value.advancedLayout.maximumValue) {
                  var message = `El valor digitado es mayor al permitido (max = ${value.advancedLayout.maximumValue})`;
                  input.attr('data-max-msg', message);
                  return false;
                }
                return true;
              },
            },
          };
        } else {
          fieldsModel[fieldColumn == '' ? value.name : fieldColumn] = {
            type,
            editable: function (_: any) {
              return value.IsEditableInGrid;
            },
            validation: {
              required(input: any) {
                if (input.val().length < 1 && value.advancedLayout.required) {
                  var message = 'Este campo es requerido';
                  input.attr(message);
                  return false;
                }
                return true;
              },
            },
          };
        }
      }

      if (
        value.type == 'Archive' ||
        value.type == 'QRCode' ||
        value.type == 'Signature'
      ) {
        fieldsModel[value.name] = { type: value.type };
      }

      if (value.isToMapa && value.type == 'Map') {
        var field_evaluate = value;

        if (
          field_evaluate.campoParentId != undefined &&
          field_evaluate.campoParentId != '' &&
          field_evaluate.campoParentId != '00000000-0000-0000-0000-000000000000'
        ) {
          var real_field = $.grep(this.configTable.fields, (v: any) => {
            return field_evaluate.campoParentId === v.campoId;
          });

          this.fieldsToMap.push(
            `${this.configTable.selectOptions[real_field[0].name][0].endPoint
              .view
            }.${value.name}`
          );
        } else {
          if (
            fieldColumn == null ||
            fieldColumn == undefined ||
            fieldColumn == ''
          ) {
            this.fieldsToMap.push(value.name);
          } else {
            this.fieldsToMap.push(fieldColumn);
          }
        }
      }

      if (value.isToCarrusel) {
        if (value.carrusel.isImagen) {
          this.fieldToSlide.images.push(value.name);
        }

        if (value.carrusel.isNombre) {
          this.fieldToSlide.names.push(value.name);
        }

        if (value.carrusel.isDescription) {
          this.fieldToSlide.description.push(value.name);
        }
      }
      if (value.isToScheduler) {
        var field_name = value.name;

        if (
          value.campoParentId != undefined &&
          value.campoParentId != '' &&
          value.campoParentId != '00000000-0000-0000-0000-000000000000'
        ) {
          field_name = `${value.entityParent}.${value.name}`;
        }

        if (value.scheduler.IsTitle) {
          this.fieldToScheduler.title.fieldName.push(field_name);
          this.fieldToScheduler.title.field.push(value);
        }

        if (value.scheduler.IsStart) {
          this.fieldToScheduler.start.push(field_name);
        }

        if (value.scheduler.IsEnd) {
          this.fieldToScheduler.end.push(field_name);
        }

        if (value.scheduler.IsDescription) {
          this.fieldToScheduler.description.fieldName.push(field_name);
          this.fieldToScheduler.description.field.push(value);
        }
      }

      if (value.type == 'ShortText') {
        if (this.fieldMobile != 'var fieldsMobile = {') {
          this.fieldMobile += ',';
        }

        this.fieldMobile += `"${value.name}": { type: "string" }`;
        this.fieldMobileTemplate = '';
      }

      if (value.IsDefault) {
        if (value.entityParent !== null) {
          this.fieldNameDefault = `${fieldColumn}`;
        } else {
          this.fieldNameDefault = value.name;
        }
      }

      var typeColumn = this.gridService.getColumnType(value);
      var valueNameGrid: string =
        value.name.charAt(0).toUpperCase() + value.name.slice(1);
      valueNameGrid = valueNameGrid.split('Details').join('.');

      switch (typeColumn) {
        case 'email':
        case 'text':
          if (!value.isFromOtherEntity) {
            column = {
              field: fieldColumn ? fieldColumn : valueNameGrid,
              type: 'text',
              title: value.displayName,
              filterable: true,
              editable: function (_: any) {
                return value.IsEditableInGrid;
              },
              template: `#= ${value.name} != null ? ${value.name} : '' #`,
              // width: 150,
              attributes: {
                style: 'white-space: normal; text-overflow: inherit;',
              },
            };
          } else {
            column = {
              field: fieldColumn ? fieldColumn : valueNameGrid,
              type: 'text',
              title: value.displayName.split('/ ')[1],
              filterable: true,
              filter: fieldColumn,
              editable: function (_: any) {
                return value.IsEditableInGrid;
              },
              template: `#= ${fieldColumn.split('.')[0]
                } != null ? ${fieldColumn} : '' #`,
              // width: 150,
              attributes: {
                style: 'white-space: normal; text-overflow: inherit;',
              },
            };
          }
          break;
        case 'checkbox':
        case 'Boolean':
          column = {
            field: fieldColumn ? fieldColumn : valueNameGrid,
            title: value.displayName,
            filterable: true,
            editable: function (_: any) {
              return value.IsEditableInGrid;
            },
            template: `#= ${valueNameGrid} ? 'Si' : 'No' #`,
          };
          break;
        case 'Image':
          break;
        case 'map':
          if (
            value.campoParentId != undefined &&
            value.campoParentId != '' &&
            value.campoParentId != '00000000-0000-0000-0000-000000000000'
          ) {
            field_name = `${value.entityParent}.${valueNameGrid}`;
            column = {
              field: field_name,
              title: value.name,
              filterable: true,
              editable: function (_: any) {
                return value.IsEditableInGrid;
              },
              template: `#= ${value.entityParent} ? JSON.parse(${field_name}).address : '' #`,
            };
          } else {
            column = {
              field: fieldColumn ? fieldColumn : valueNameGrid,
              title: value.displayName,
              filterable: true,
              editable: function (_: any) {
                return value.IsEditableInGrid;
              },
              template: `#= ${value.RutaGrid ? fieldColumn : valueNameGrid
                } ? JSON.parse(${value.RutaGrid ? fieldColumn : valueNameGrid
                }).address : '' #`,
            };
          }
          break;
        case 'textarea':
          var fieldColumn: string = value.RutaGrid
            ? value.RutaGrid.split('Details.').join('.')
            : '';

          if (!value.isFromOtherEntity) {
            column = {
              field: fieldColumn ? fieldColumn : valueNameGrid,
              type: 'textarea',
              title: value.displayName,
              filterable: true,
              editable: function (_: any) {
                return value.IsEditableInGrid;
              },
              template: `#= ${valueNameGrid} ? ${valueNameGrid} : '' #`,
              // width: 400,
              attributes: {
                style:
                  'white-space: normal; text-overflow: inherit; text-align: justify; line-height: 1.2',
              },
            };
          } else {
            column = {
              field: fieldColumn ? fieldColumn : valueNameGrid,
              type: 'textarea',
              title: value.displayName.split('/ ')[1],
              filterable: true,
              editable: function (_: any) {
                return value.IsEditableInGrid;
              },
              template: `#= ${fieldColumn.split('.')[0]
                } ? ${fieldColumn} : '' #`,
              // width: 400,
              attributes: {
                style:
                  'white-space: normal; text-overflow: inherit; text-align: justify; line-height: 1.2;',
              },
            };
          }
          break;
        case 'LongText':
          break;
        case 'Url':
          break;
        case 'multiSelect':
          break;
        case 'combobox':
          if (
            (value.displayFormat == 'AutoCompleteStatWith' ||
              value.displayFormat == 'AutoCompleteMatchAny') &&
            value.entityParent == null
          ) {
            field_name = `${this.configTable.selectOptions[value.name][0].endPoint.view
              }.${this.configTable.selectOptions[value.name][0].endPoint
                .defaultField
              }`;
            var cbx_field: string = field_name.replace('Details.', '.');
            column = {
              field: cbx_field,
              title: value.displayName,
              filterable: true,
              editable: function (_: any) {
                return value.IsEditableInGrid;
              },
              template: `#= ${cbx_field.split('.')[0]} ? ${cbx_field} : '' #`,
            };
          } else if (value.entityParent === null) {
            column = {
              field: fieldColumn ? fieldColumn : valueNameGrid,
              title: value.displayName,
              filterable: true,
              editable: function (_: any) {
                return value.IsEditableInGrid;
              },
              template: `#= ${valueNameGrid} ? ${valueNameGrid} : ' ' #`,
            };
          }
          break;
        case 'date':
          fieldColumn = value.RutaGrid
            ? value.RutaGrid.split('Details.').join('.')
            : '';
          if (!value.isFromOtherEntity) {
            column = {
              field: fieldColumn ? fieldColumn : valueNameGrid,
              title: value.displayName,
              filterable: true,
              editable: function (_: any) {
                return value.IsEditableInGrid;
              },
              template: `#= ${valueNameGrid} ? ${valueNameGrid}.toLocaleDateString('es-CO',{timeZone:'UTC'}) : '' #`,
            };
          } else {
            column = {
              field: fieldColumn ? fieldColumn : valueNameGrid,
              title: value.displayName.split('/ ')[1],
              filterable: true,
              editable: function (_: any) {
                return value.IsEditableInGrid;
              },
              template: `#= ${fieldColumn.split('.')[0]
                } ? new Date(${fieldColumn}).toLocaleDateString('es-CO',{timeZone:'UTC'}) : '' #`,
            };
          }
          fieldsModel[fieldColumn == '' ? value.name : fieldColumn].type =
            'date';
          break;
        case 'datetime':
          fieldColumn = value.RutaGrid
            ? value.RutaGrid.split('Details.').join('.')
            : '';
          if (!value.isFromOtherEntity) {
            column = {
              field: fieldColumn ? fieldColumn : valueNameGrid,
              title: value.displayName,
              filterable: true,
              editable: function (_: any) {
                return value.IsEditableInGrid;
              },
              template: `#= ${valueNameGrid} ? ${valueNameGrid}.toLocaleString('es-CO',{timeZone:'UTC'}) : '' #`,
            };
          } else {
            column = {
              field: fieldColumn ? fieldColumn : valueNameGrid,
              title: value.displayName.split('/ ')[1],
              filterable: true,
              editable: function (_: any) {
                return value.IsEditableInGrid;
              },
              template: `#= ${fieldColumn.split('.')[0]
                } ? ${fieldColumn}.toLocaleString('es-CO',{timeZone:'UTC'}).replace('T', ' ').replace('Z', '').replaceAll('-','/') : '' #`,
            };
          }
          fieldsModel[fieldColumn == '' ? value.name : fieldColumn].type =
            'date';
          break;
        case 'carrousel':
          this.arrayPositions.push({ position, field: value });

          if (!value.IsMultiple) {
            column = {
              field: fieldColumn ? fieldColumn : valueNameGrid,
              title: value.displayName,
              filterable: false,
              editable: function (_: any) {
                return false;
              },
              template: `<div class='imagenGrid' data-bind="click: selectImageClick" data-position="${position}" style="background-image: url(${backandGlobal.URL_API_UPLOAD_IMAGES}#:(${valueNameGrid}||"").replace('[', '').replace(']', '')#);"></div>`,
            };
          } else {
            column = {
              field: fieldColumn ? fieldColumn : valueNameGrid,
              title: value.displayName,
              filterable: false,
              editable: function (_: any) {
                return false;
              },
              template: `<i class='fa fa-search fa-2x' aria-hidden='true' data-bind="click: selectImageClick" data-position="${position}"></i>`,
            };
          }
          position++;
          break;
        case 'Foto':
          column = {
            field: fieldColumn ? fieldColumn : valueNameGrid,
            title: value.displayName,
            filterable: false,
            editable: function (_: any) {
              return false;
            },
            template: `<img class='fotoGrid' src='${backandGlobal.URL_API_UPLOAD_IMAGES}/#:${valueNameGrid}#' />`,
          };
          break;
        case 'editor':
          column = {
            field: fieldColumn ? fieldColumn : valueNameGrid,
            title: value.displayName,
            command: [
              {
                title: value.displayName,
                template: `<a class='btn btn-sm' data-bind="click: openRichTextClick" data-code="${value.Code}"><i class='fa fa-search-plus'></i></a>`,
              },
            ],
          };
          break;
        case 'number':
          fieldColumn = value.RutaGrid
            ? value.RutaGrid.split('Details.').join('.')
            : '';
          var scalaDouble = value.advancedLayout.scala;
          column.format = `{0:n${scalaDouble}}`;
          break;
        case 'decimal':
          var scalaDouble = value.advancedLayout.scala;
          column.format = `{0:n${scalaDouble}}`;
          break;
        case 'numeric':
          fieldColumn = value.RutaGrid
            ? value.RutaGrid.split('Details.').join('.')
            : '';
          var scalaDouble = value.advancedLayout.scala;
          column.format = `{0:n${scalaDouble}}`;
          break;
        case 'currency':
          fieldColumn = value.RutaGrid
            ? value.RutaGrid.split('Details.').join('.')
            : '';
          var scalaDouble = value.advancedLayout.scala;
          column = {
            field: fieldColumn ? fieldColumn : valueNameGrid,
            title: value.displayName,
            filterable: true,
            editable: function (_: any) {
              return value.IsEditableInGrid;
            },
            format: `{0:c${scalaDouble}}`,
          };
          break;
        case 'percentage':
          fieldColumn = value.RutaGrid
            ? value.RutaGrid.split('Details.').join('.')
            : '';
          var scalaDouble = value.advancedLayout.scala;

          if (!value.isFromOtherEntity) {
            column = {
              field: fieldColumn ? fieldColumn : valueNameGrid,
              title: value.displayName,
              filterable: true,
              template: `#= kendo.format("{0:p${scalaDouble}}", ${valueNameGrid} / 100) #`,
            };
          } else {
            column = {
              field: fieldColumn ? fieldColumn : valueNameGrid,
              title: value.displayName.split('/ ')[1],
              filterable: true,
              template: `#= kendo.format("{0:p${scalaDouble}}", ${fieldColumn} / 100) #`,
            };
          }
          break;
        case 'fieldhtml':
          column = {
            title: value.displayName,
            template: `${value.Implementation}`,
          };
          break;
        case 'time':
          if (!value.isFromOtherEntity) {
            column = {
              field: fieldColumn ? fieldColumn : valueNameGrid,
              type: 'text',
              title: value.displayName,
              filterable: true,
              editable: function (_: any) {
                return value.IsEditableInGrid;
              },
              template: `#= ${value.name.split('.')[0]}  != null ? ${value.name
                }.split(':')[0] && ${value.name}.split(':')[1] ? parseInt(${value.name
                }.split(':')[0]) > 12 ? parseInt(${value.name
                }.split(':')[0]) - 12 < 10 ? '0' + String(parseInt(${value.name
                }.split(':')[0]) - 12)  + ':' + String(${value.name
                }.split(':')[1]) + ' PM' : String(parseInt(${value.name
                }.split(':')[0]) - 12)  + ':' + String(${value.name
                }.split(':')[1]) + ' PM' : parseInt(${value.name
                }.split(':')[0]) == 0 ? '12:'+ String(${value.name
                }.split(':')[1]) + ' AM' : parseInt(${value.name
                }.split(':')[0]) == 12 ? ${value.name}  +' PM' : ${value.name
                }  +' AM' : '' : '' #`,
              attributes: {
                style: 'white-space: normal; text-overflow: inherit;',
              },
            };
          } else {
            column = {
              field: fieldColumn ? fieldColumn : valueNameGrid,
              type: 'text',
              title: value.displayName.split('/ ')[1],
              filterable: true,
              filter: fieldColumn,
              editable: function (_: any) {
                return value.IsEditableInGrid;
              },
              template: `#= ${fieldColumn.split('.')[0]
                }  != null ? ${fieldColumn}.split(':')[0] && ${fieldColumn}.split(':')[1] ? parseInt(${fieldColumn}.split(':')[0]) > 12 ? parseInt(${fieldColumn}.split(':')[0]) - 12 < 10 ? '0' + String(parseInt(${fieldColumn}.split(':')[0]) - 12)  + ':' + String(${fieldColumn}.split(':')[1]) + ' PM' : String(parseInt(${fieldColumn}.split(':')[0]) - 12)  + ':' + String(${fieldColumn}.split(':')[1]) + ' PM' : parseInt(${fieldColumn}.split(':')[0]) == 0 ? '12:'+ String(${fieldColumn}.split(':')[1]) + ' AM' : parseInt(${fieldColumn}.split(':')[0]) == 12 ? ${fieldColumn}  +' PM' : ${fieldColumn}  +' AM' : '' : '' #`,
              attributes: {
                style: 'white-space: normal; text-overflow: inherit;',
              },
            };
          }
          break;
      }

      if (value.type == 'Archive') {
        gridColumns.push({
          field: fieldColumn ? fieldColumn : valueNameGrid,
          title: value.displayName,
          command: [
            {
              title: value.displayName,
              template: `<a class='btn btn-sm' data-bind="click: openDocumentsClick" data-code="${value.Code}"><i class='fa fa-folder-open'></i></a>`,
            },
          ],
        });
      } else if (value.type == 'QRCode') {
        gridColumns.push({
          field: valueNameGrid,
          title: value.displayName,
          command: [
            {
              title: value.displayName,
              template: `<a class='btn btn-sm' data-bind="click: openQRCodeClick" data-code="${value.Code}"><i class='fa fa-light fa-qrcode'></i></a>`,
            },
          ],
        });
      } else if (value.type == 'Signature') {
        gridColumns.push({
          field: valueNameGrid,
          title: value.displayName,
          command: [
            {
              title: value.displayName,
              template: `<a class='btn btn-sm' data-bind="click: openSignatureClick" data-code="${value.Code}"><i class="fa fa-signature"></i></a>`,
            },
          ],
        });
      }

      const aggregates = {
        Count: 'count',
        Sum: 'sum',
        Average: 'average',
        Max: 'max',
        Min: 'min',
      };

      const opAggregates = (type: string) => {
        var grid = kendo.jQuery(`#${this.idViewComponent}`).data('kendoGrid'),
          data = grid.dataSource.data();
        var { Code: codeField, advancedLayout: advancedField } = value,
          { scala: scalaField } = advancedField;
        var sum = 0,
          footer = type === 'Sum' ? 'Suma: ' : 'Promedio: ';
        data.forEach((item) => {
          sum += parseFloat(item[codeField]);
        });

        return aggregates[type] === 'sum'
          ? `${footer} ${kendo.toString(sum, `n${scalaField}`)}`
          : `${footer} ${kendo.toString(sum / data.length, `n${2}`)}`;
      };

      const footerAggregates = {
        Count: 'Total conteo: #=count#',
        Sum: () => opAggregates('Sum'),
        Average: () => opAggregates('Average'),
        Max: 'Máximo: #=max#',
        Min: 'Mínimo: #=min#',
      };

      if (value.Aggregate != null) {
        column.aggregates = aggregates[value.Aggregate];
        column.footerTemplate = footerAggregates[value.Aggregate];

        this.aggregates.push({
          field: fieldColumn ? fieldColumn : valueNameGrid,
          aggregate: column.aggregates,
        });
      }

      if (
        value.type != 'Archive' &&
        value.type != 'QRCode' &&
        value.type != 'Signature'
      ) {
        if (value.isToGrid) {
          gridColumns.push(column);
        }
        if (value.isToMapa) {
          gridColumns.push(column);
        }
        if (value.isToScheduler) {
          gridColumns.push(column);
        }
        if (value.isToListMobile) {
          gridColumns.push(column);
        }
      }
    };
  }

  // Función auxiliar para procesar las opciones de filtro
  processFilterOptions(filterGridOptions: any, array: any[], index: number, logic: string) {
    if (array != undefined || array != null) {
      var nIteracion = array.length / 3;
      var inicio = 0;
      var fin = 3;

      for (let i = 0; i < nIteracion; i++) {
        var part = array.slice(inicio, fin);

        if (part[2] != 'null') {
          filterGridOptions[index].filters.push({
            field: part[0],
            operator: part[1],
            value: part[2],
          });
        }

        inicio = fin;
        fin = fin + 3;
      }
    }
  }

  // Función auxiliar para procesar la opción de filtro
  processFilterOption(filterOptions: any, filterArray: string[], filterOptionsIndex: number, filterLogic: string) {
    if (filterOptions != undefined || filterOptions != null) {
      var nIteracion = filterArray.length / 3;
      var inicio = 0;
      var fin = 3;

      for (let i = 0; i < nIteracion; i++) {
        var part = filterArray.slice(inicio, fin);

        if (part[2] != 'null') {
          filterOptions.filters.push({
            field: part[0],
            operator: part[1],
            value: part[2],
          });
        }

        inicio = fin;
        fin = fin + 3;
      }
    }
  }


  /**
   * Método encargado de construir la grid
   */
  async buildGrid(viewName: any, entityId: any) {
    this.entityId = entityId;
    this.viewEntityName = viewName;

    var appViewId = this.viewCode || this.route.snapshot.queryParams.appViewId;
    var paramsViewName: any = {
      dataType: 'list',
      id: viewName,
      entityId,
    };

    if (appViewId != '' && appViewId !== null && appViewId != undefined) {
      paramsViewName.appViewId = appViewId;
    }

    await this.proxyConfigApiService.getViewConfig(paramsViewName, async (data: any) => {
      var dataEditing;
      this.configTable = data;

      for (var i = 0; i < this.configTable.dataEditing.length; i++) {
        if (sessionStorage.getItem('rolesId')) {
          if (sessionStorage.getItem('rolesId').includes(',')) {
            let stringRoles = sessionStorage.getItem('rolesId').split(',');
            stringRoles.forEach((rol) => {
              if (this.configTable.dataEditing[i].rolesId.includes(rol)) {
                dataEditing = this.configTable.dataEditing[i];
              }
            });
          } else if (this.configTable.dataEditing[i].rolesId.includes(sessionStorage.getItem('rolesId'))) {
            dataEditing = this.configTable.dataEditing[i];
          }
        } else if (this.isAnonymous == true) {
          dataEditing = this.configTable.dataEditing[i];
          // TODO: Poner servicio compartido para cambiar los temas de la aplicación
        }
      }

      this.collapseFilter = this.configTable.DisplayMenuTab;
      this.nameEntity = data.captionText;
      this.logicalDelete = this.configTable ? this.configTable.LogicalDelete : false;
      this.showAdd = this.configTable && dataEditing ? dataEditing.allowAdd : true;
      this.showCancel = this.configTable ? this.configTable.ShowCancelButton : true;
      this.showCreate = this.configTable ? this.configTable.ShowCreateButton : true;
      this.showDelete = this.configTable && dataEditing ? dataEditing.allowDelete : true;
      this.showEdit = this.configTable && dataEditing ? dataEditing.allowEdit : true;
      this.showSearch = this.configTable && this.configTable.design ? !this.configTable.design.hideSearchBox : true;
      this.showTabsMenu = this.configTable.DisplayMenutab;
      this.showToolbar = this.configTable && this.configTable.toolbarSettings ? !this.configTable.toolbarSettings.hideToolbar : true;
      this.showViewItem = this.configTable && dataEditing ? dataEditing.allowViewItem : false;
      this.viewsToOpen = { Create: data.ViewToOpenCreate, Edit: data.ViewToOpenEdit };

      if (location.hash.includes('nameBack')) {
        var parts = location.hash.split('&');
        parts.forEach((item: any) => {
          if (item.includes('nameBack')) {
            this.nameBack = (' - ' + decodeURI(item.split('=')[1])).replace(/_/g, ' ');
          }
        });
      }

      //#region Filter general
      function isNumeric(n: any) {
        return !isNaN(parseFloat(n)) && isFinite(n);
      }

      function getBoolean(str: any) {
        if ('true'.startsWith(str)) {
          return true;
        } else if ('false'.startsWith(str)) {
          return false;
        } else {
          return null;
        }
      }

      $('#filter').on('input', (e: any) => {
        this.isAinputTypeSearchFilter = true;
        var fields = this.configTable.fields;
        var grid = kendo.jQuery(`#${this.idViewComponent}`).data('kendoGrid');
        var filter = { logic: 'or', filters: [] };
        var value = e.target.value;

        fields.forEach((x: any) => {
          var field = x.RutaGrid
            ? x.RutaGrid.split('Details').join('')
            : this.viewEntityName + '.' + x.Code;

          if (
            isNumeric(value) &&
            this.gridService.getColumnType(x) == 'number'
          ) {
            value = parseInt(value);
            filter.filters.push({
              field,
              operator: 'contains',
              value,
            });
          } else {
            switch (this.gridService.getColumnType(x)) {
              case 'map':
              case 'text':
              case 'email':
              case 'textarea':
              case 'editor':
              case 'singleSelect':
                value = value.toString();
                filter.filters.push({
                  field,
                  operator: 'contains',
                  value,
                });
                break;
              case 'numeric':
              case 'decimal':
              case 'percentage':
                if (isNumeric(value)) {
                  value = parseFloat(value);
                  filter.filters.push({
                    field,
                    operator: 'eq',
                    value,
                  });
                }
                break;
              case 'Boolean':
              case 'checkbox':
                var bool = getBoolean(e.target.value);

                if (bool != null) {
                  filter.filters.push({
                    field,
                    operator: 'eq',
                    value: bool,
                  });
                }
                break;
              case 'combobox':
                filter.filters.push({
                  field,
                  operator: 'contains',
                  value,
                });
                break;
            }
          }
        });

        grid.dataSource.filter(filter);
      });
      //#endregion

      if (this.showTabsMenu) {
        $('#panelGrid').css('margin-top', '0px');
        $('#positionNavs').css({ 'margin-top': '0px', position: 'static' });
        $('#listSear').css('position', 'static');
        $('#grid-general').css('margin-top', '0px');
      } else {
        $('#panelGrid').css('margin-top', '4.3%');
        $('#positionNavs').css({ position: 'fixed' });
        $('#listSear').css('position', 'fixed');

        if ($(window).width() < 768) {
          $('#grid-general').css('margin-top', '6%');
        }
      }

      if (data.CustomMessages) {
        data.CustomMessages.forEach((message: any) => {
          this.customMessages[message.MessageType] = message.Message;
        });
      }

      this.configTable.Actions.forEach((action: any) => {
        if (sessionStorage.getItem('rolesId')) {
          if (sessionStorage.getItem('rolesId').includes(',')) {
            let stringRoles = sessionStorage.getItem('rolesId').split(',');
            stringRoles.forEach((rol) => {
              if (action.rolesId.includes(rol)) {
                if (action.GlobalAction) {
                  this.globalActions.push(action);
                }
              }
            });
          } else if (action.rolesId.includes(sessionStorage.getItem('rolesId'))) {
            if (action.GlobalAction) {
              this.globalActions.push(action);
            }
          }
        } else if (this.isAnonymous == true) {
          if (action.GlobalAction) {
            this.globalActions.push(action);
          }
        }
      });
      const uniqueActions = this.globalActions.filter((action, index) => {
        const _action = JSON.stringify(action);
        return (
          index ===
          this.globalActions.findIndex((action) => {
            return JSON.stringify(action) === _action;
          })
        );
      });
      this.globalActions = uniqueActions;
      this.actionsMenuOptions =
        this.globalActions && this.globalActions.length > 0 ? true : false;

      if (
        data.hasOwnProperty('controlLsListView') &&
        data.controlLsListView !== null
      ) {
        if (data.controlLsListView.TimeRefreshSelect) {
          var time = data.controlLsListView.TimeRefresh * 60000;
          setInterval(this.refresh, time);
        }
      }

      //#region Kendo grid create
      var aggregates = [],
        gridColumns = [],
        command = [],
        fieldsModel = {},
        permissions: any = [];
      this.gridColumns = [];

      this.configTable.fields.forEach((value: any) => {
        if (value.IsEditableInGrid) {
          this.editableFields = true;
        }
      });

      for (var i = 0; i < this.configTable.permissions.length; i++) {
        if (sessionStorage.getItem('rolesId')) {
          if (sessionStorage.getItem('rolesId').includes(',')) {
            let stringRoles = sessionStorage.getItem('rolesId').split(',');
            stringRoles.forEach((rol) => {
              if (this.configTable.permissions[i].rolesId.includes(rol)) {
                permissions = this.configTable.permissions[i];
              }
            });
          } else if (
            this.configTable.permissions[i].rolesId.includes(sessionStorage.getItem('rolesId'))) {
            permissions = this.configTable.permissions[i];
          }
        } else if (this.isAnonymous == true) {
          permissions = this.configTable.permissions[i];
        }
      }

      if (permissions.allowEditFromGrid && this.showEdit) {
        command.push({
          template: `<a class="btn btn-sm" data-bind="click: editClick" data-toogle="tooltip" title="Editar"><i class="fa fa-edit"></i></a>`,
        });
      }

      if (permissions.allowDeleteFromGrid) {
        if (this.logicalDelete) {
          command.push({
            template: `<a class="btn btn-sm" data-bind="click: logicalDeleteClick" data-toogle="tooltip" title="Eliminar"><i class="fa fa-trash"></i></a>`,
          });
        } else {
          if (this.showDelete) {
            command.push({
              template: `<a class="btn btn-sm" data-bind="click: deleteClick" data-toogle="tooltip" title="Eliminar"><i class="fa fa-trash"></i></a>`,
            });
          }
        }
      }

      if (this.editableFields) {
        command.push({
          template: `<a class="btn btn-sm" data-bind="click: saveRowClick" data-toogle="tooltip" title="Guardar"><i class="fa fa-save"></i></a>`,
        });
      }

      if (this.showViewItem) {
        command.push({
          template: `<a class="btn btn-sm" data-bind="click: viewItemClick" data-toogle="tooltip" title=" Ver Item"><i class="fa fa-search-plus"></i></a>`,
        });
      }

      if (this.configTable.Actions.length > 0) {
        this.configTable.Actions.forEach((value: any) => {
          if (sessionStorage.getItem('rolesId')) {
            if (sessionStorage.getItem('rolesId').includes(',')) {
              let stringRoles = sessionStorage.getItem('rolesId').split(',');
              stringRoles.forEach((rol) => {
                if (value.rolesId.includes(rol)) {
                  if (!value.GlobalAction) {
                    switch (value.Type) {
                      case 'NavegarVista':
                        command.push({
                          template: `<a name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm' data-bind="click: navigationClick" data-queryString="${value.QueryString}"><i class="fa ${value.IconAwesomeAction}"></i></a>`,
                        });
                        break;
                      case 'Codigo':
                        command.push({
                          template: `<a name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm' data-bind="click: actionCodeClick" data-actionId="${value.ActionId}"><i class="fa ${value.IconAwesomeAction}"></i></a>`,
                        });
                        break;
                      case 'Encuesta':
                        command.push({
                          template: `<a name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm' data-bind="click: navigationClick" data-queryString="${value.QueryString}"><i class="fa ${value.IconAwesomeAction}"></i></a>`,
                        });
                        break;
                    }
                  }
                }
              });
            } else if (value.rolesId.includes(sessionStorage.getItem('rolesId'))) {
              if (!value.GlobalAction) {
                switch (value.Type) {
                  case 'NavegarVista':
                    command.push({
                      template: `<a name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm' data-bind="click: navigationClick" data-queryString="${value.QueryString}"><i class="fa ${value.IconAwesomeAction}"></i></a>`,
                    });
                    break;
                  case 'Codigo':
                    command.push({
                      template: `<a name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm' data-bind="click: actionCodeClick" data-actionId="${value.ActionId}"><i class="fa ${value.IconAwesomeAction}"></i></a>`,
                    });
                    break;
                  case 'Encuesta':
                    command.push({
                      template: `<a name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm' data-bind="click: navigationClick" data-queryString="${value.QueryString}"><i class="fa ${value.IconAwesomeAction}"></i></a>`,
                    });
                    break;
                }
              }
            }
          } else if (this.isAnonymous == true) {
            if (!value.GlobalAction) {
              switch (value.Type) {
                case 'NavegarVista':
                  command.push({
                    template: `<a name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm' data-bind="click: navigationClick" data-queryString="${value.QueryString}"><i class="fa ${value.IconAwesomeAction}"></i></a>`,
                  });
                  break;
                case 'Codigo':
                  command.push({
                    template: `<a name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm' data-bind="click: actionCodeClick" data-actionId="${value.ActionId}"><i class="fa ${value.IconAwesomeAction}"></i></a>`,
                  });
                  break;
              }
            }
          }
        });
      }

      gridColumns.push({ command });
      this.fieldMobile = 'var fieldsmobile = {';
      this.fieldMobileTemplate = '';
      this.arrayPositions = [];
      var position = 0;

      await this.configTable.fields.forEach(
        this.configColumns(gridColumns, fieldsModel, position)
      );

      if (this.editableFields) {
        gridColumns.push({
          command: [
            {
              template: `<a class="btn btn-sm" data-bind="click: saveRowClick" data-toogle="tooltip" title="Guardar"><i class="fa fa-save"></i></a>`,
            },
          ],
        });
      }

      this.sectionItemNav = 'Grid';
      this.showGrid = true;

      if (this.fieldToList.value.length == 0) {
        this.configTable.fields.forEach((value: any) => {
          if (this.fieldToList.value.length < 3) {
            this.addFieldToListMobile(value);
          }
        });
      }

      // this.fieldMobile = `${this.fieldMobile}}; fieldsmobile;`;
      // this.fieldMobile = eval(this.fieldMobile);

      if (this.fieldsToMap.length == 0) {
        this.showMap = false;
        $('#grid-general-map').hide();
      } else {
        $('#grid-general').hide();
        $('#grid-general-map').show();
        this.sectionItemNav = 'Map';
        this.showMap = true;
      }

      if (this.fieldToSlide.images.length == 0) {
        this.showSlide = false;
        $('#grid-general-slide').hide();
      } else {
        $('#grid-general').hide();
        $('#grid-general-slide').show();
        this.sectionItemNav = 'Carrousel';
        this.showSlide = true;
      }

      var url_grid: string = '';
      this.gridColumns = gridColumns;
      var fieldsColumns = [];
      var entityInclude = [];
      var stringEntity = '';
      fieldsColumns.push('Id');
      if (!this.configTable.filter) {
        // Get all expands...
        url_grid = `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api/lappiz/get/${this.viewEntityName}`;
      } else {
        url_grid = this.contextValuesService.transformODataQuery(this.configTable.filter.Query);
      }

      this.dataSort = this.odataFilterMap.getOrderBy(url_grid);
      this.odataFilterOptions = this.odataFilterMap.extractOptions(url_grid);
      this.filterGridOptions = this.odataFilterMap.getFilterOptions(this.odataFilterOptions.filterQuery);

      this.url = this.odataFilterOptions.url;
      this.showScheduler();

      var gridFieldColumns = gridColumns;
      entityInclude = this.odataFilterMap.getIncludeEntities(this.odataFilterOptions.filterQuery, gridFieldColumns, this.viewName);
      let uniqueField = [...new Set(entityInclude)];
      stringEntity = this.odataFilterMap.getIncludes(uniqueField);

      this.mainGridOptions = {
        dataSource: {
          transport: {
            read: async (options: any) => {
              var optionsFilter = options;
              // no funciono el split operator {...this.filterGridOptions }
              const filterGridOptionsAux = JSON.parse(JSON.stringify(this.filterGridOptions));
              if (!optionsFilter.data.filter)
                optionsFilter.data.filter = filterGridOptionsAux;
              else if (filterGridOptionsAux.filters) {
                filterGridOptionsAux.filters.push(optionsFilter.data.filter)
                optionsFilter.data.filter = filterGridOptionsAux;
              }

              if (this.dataSort != null || this.dataSort != undefined) {
                optionsFilter.data.sort = this.dataSort
                this.dataSort = null;
              }

              if (!!optionsFilter.data.sort) {
                if (!!optionsFilter.data.sort[0]) {
                  var field = optionsFilter.data.sort[0].field;
                  if (optionsFilter.data.sort[0].field.split('.').length == 1) {
                    optionsFilter.data.sort[0].field = `${this.viewEntityName + '.' + field}`;
                  } else
                    optionsFilter.data.sort[0].field = `[${field}]`;
                }
              }

              var urlTx = `${backandGlobal.api2}/${sessionStorage.getItem(
                'workspace'
              )}.api/api/lappiz/get/${this.viewEntityName}`;
              var entityNameOff = this.viewEntityName;
              sessionStorage.setItem('entityname', entityNameOff);
              optionsFilter.data.includeEntities = stringEntity;
              optionsFilter.data.tenantId = sessionStorage.getItem('tenantId');
              optionsFilter.data.parameters = {
                userId: `${sessionStorage.getItem('userId')}`,
                appViewId: `${this.viewCode || this.route.snapshot.queryParams.appViewId}`,
                pType: 'showinmenu',
                aType: 'view',
                environment: `${backandGlobal.environment}`,
              };

              if (optionsFilter.data.sort) {
                this.sortGrid = optionsFilter.data.sort;
              }

              if (this.sortGrid && !optionsFilter.data.sort) {
                optionsFilter.data.sort = this.sortGrid;
              }
              await this.proxyTxApiService.getTxData(`${urlTx}`, JSON.stringify(optionsFilter.data), backandGlobal.stateOnline, entityNameOff)
                .then((response: any) => {
                  if (backandGlobal.stateOnline) {
                    if (response.data.count > 0) {
                      if (response.data.rows[0].Lappiz_Users) {
                        for (let j = 0; j < response.data.rows.length; j++) {
                          response.data.rows[j][
                            `${sessionStorage.getItem('workspace')}_Users`
                          ] = response.data.rows[j].Lappiz_Users;
                        }
                      }
                    }

                    options.success(response.data);
                  } else {
                    options.success(response);
                  }
                })
                .catch((error: any) => {
                  console.log('Error:', error);
                });
            },
          },
          schema: {
            model: {
              fields: fieldsModel,
            },
            data: (data: any) => {
              return data.rows;
            },
            total: (data: any) => {
              return backandGlobal.stateOnline ? data.count : data.total;
            },
          },
          aggregate: this.aggregates,
          serverFiltering: backandGlobal.stateOnline,
          serverPaging: true,
          // serverPaging: backandGlobal.stateOnline ? true : false,
          serverSorting: backandGlobal.stateOnline,
          selectable: 'cell',
        },
        resizable: true,
        dataBound: (e: any) => {
          var items = e.sender._data;

          if (items.length == 0) {
            return;
          }

          this.getDataToMap(items);
          this.getSlideImages(items);

          for (var i = 0; i < e.sender.columns.length; i++) {
            var type = e.sender.columns[i].type;

            if (this.isMobile) {
              e.sender.autoFitColumn(i);
            } else if (e.sender.columns.length > 4) {
              e.sender.autoFitColumn(i);
            }
          }

          this.setActionsGrid();

          setTimeout(() => {
            this.setActionsGrid();
            if (this.isMobile) {
              $('.k-current-page').hide();
              $(
                'div.k-pager-wrap.k-grid-pager.k-widget.k-floatwrap'
              ).removeClass('k-pager-sm');
            }
          }, 25);
        },
        dataBinding: () => {
          this.setActionsGrid();

          setTimeout(() => {
            this.setActionsGrid();
            if (this.isMobile) {
              $('.k-current-page').hide();
              $(
                'div.k-pager-wrap.k-grid-pager.k-widget.k-floatwrap'
              ).removeClass('k-pager-sm');
            }
          }, 25);
        },
        mobile: true,
        columnMenu: this.editableFields ? false : true,
        columns: this.gridColumns,
        editable: this.editableFields,
        sortable: true,
        pageable: {
          refresh: true,
          pageSize: 10,
          pageSizes: [5, 10, 20, 100],
        },
        filterable: {
          operator: Constants.KENDOGRIDS.operators,
          messages: Constants.KENDOGRIDS.messages,
        },
      };

      if (aggregates.length > 0) {
        if (this.mainGridOptions.dataSource.aggregate == undefined) {
          this.mainGridOptions.dataSource.aggregate = [];
        }

        $.extend(this.mainGridOptions.dataSource.aggregate, aggregates);
      }

      var exportFlag = false;

      Object.assign(this.mainGridOptions, {
        toolbar: ['excel', 'pdf'],
        excelExport(e: any) {
          var sheet = e.workbook.sheets[0];

          for (var rowIndex = 1; rowIndex < sheet.rows.length; rowIndex++) {
            if (rowIndex % 2 == 0) {
              var row = sheet.rows[rowIndex];

              for (
                var cellIndex = 0;
                cellIndex < row.cells.length;
                cellIndex++
              ) {
                row.cells[cellIndex].background = '#aabbcc';
              }
            }

            var row = sheet.rows[rowIndex];

            for (
              var cellIndex = 0;
              cellIndex < row.cells.length;
              cellIndex++
            ) {
              if (row.cells[cellIndex].value instanceof Date) {
                // row.cells[cellIndex].value.addHours(5);
                row.cells[cellIndex].value.setTime(
                  row.cells[cellIndex].value.getTime() + 5 * 60 * 60 * 1000
                );
                row.cells[cellIndex].format = 'dd/MM/yy hh:mm:ss';
              }
            }
          }
        },
        pdfExport(e: any) {
          if (!exportFlag) {
            $('.k-loading-pdf-mask').show();
            e.sender.hideColumn(0);
            e.preventDefault();
            exportFlag = true;

            e.sender.saveAsPDF().then(function () {
              $('.k-loading-pdf-mask').hide();
              e.sender.showColumn(0);
              exportFlag = false;
            });
          }
        },
        excel: {
          title: `Hoja de trabajo ${this.nameEntity}`,
          fileName: `Datos de grilla ${this.nameEntity}`,
          allPages: true,
          filterable: true,
          sortable: true,
          pageable: true,
          autoFitColumn: true,
          dataURI: `Hoja grilla ${this.nameEntity}`,
        },
        pdf: {
          landscape: true,
          scale: 0.75,
          fileName: `Datos grilla ${this.nameEntity}`,
          allPages: true,
          margin: { top: '3cm', left: '2cm', right: '4cm', bottom: '3cm' },
        },
      });

      setTimeout(() => {
        kendo.jQuery(`#${this.idViewComponent}`).kendoGrid(this.mainGridOptions);

        this.setActionsGrid();
        this.setGridClass();
        this.listLoaded(this.configTable);
      }, 1000);
    })
      .catch((error: any) => {
        if (!localStorage.getItem('Authorization') && error.status == 404) {
          this.router.navigate([
            `/auth/login/${sessionStorage.getItem('workspace')}`,
          ]);
        }
      })
      .finally(() => { });
  }

  /**
   * Método encargado de abrir vista scheduler
   */
  showScheduler() {
    if (this.fieldToScheduler.title.fieldName.length == 0) {
      this.showSchedulerView = false;
      $('#grid-general-scheduler').hide();
    } else {
      $('#grid-general').hide();
      $('#grid-general-scheduler').show();
      this.sectionItemNav = 'Scheduler';
      this.showSchedulerView = true;

      //this.schedulerComponent.refreshScheduler(this.url, this.filterGridOptions, this.fieldToScheduler, this.configTable.Actions, this.viewNameId);
    }
  }

  /**
   * Método encargado de abrir vista carrousel
   */
  getSlideImages(items: any) {
    this.slideData = [];

    items.forEach((item: any) => {
      if (
        this.fieldToSlide.images.length > 0 ||
        this.fieldToSlide.names.legth > 0 ||
        this.fieldToSlide.description.length > 0
      ) {
        var slideItem = { image: '', name: '', description: '' };
      }

      this.fieldToSlide.images.forEach((map_field: any) => {
        if (item[map_field] != undefined && item[map_field] != '') {
          slideItem.image =
            backandGlobal.URL_API_UPLOAD_IMAGES +
            JSON.parse(item[map_field])[0];
        }
      });

      this.fieldToSlide.names.forEach((map_field: any) => {
        if (item[map_field] != undefined) {
          slideItem.name += `${item[map_field]}`;
        }
      });

      this.fieldToSlide.description.forEach((map_field: any) => {
        if (item[map_field] != undefined) {
          slideItem.description += `${item[map_field]}`;
        }
      });

      if (slideItem != undefined) {
        this.slideData.push(slideItem);
      }
    });

    this.slideDescriptionComponent.refreshSlide(this, this.slideData);
  }

  /**
   * Método encargado de abrir vista trip - map
   */
  getDataToMap(items: any) {
    this.mapData = [];

    items.forEach((item: any) => {
      this.fieldsToMap.forEach((map_field: string) => {
        var navigation_field = map_field.split('.');
        var fieldToSave = item;
        var map_processed = false;

        navigation_field.forEach((nav: any) => {
          map_processed = false;
          if (fieldToSave[nav] != undefined) {
            fieldToSave = fieldToSave[nav];
            map_processed = true;
          }
        });

        if (fieldToSave != undefined && map_processed) {
          this.mapData.push(JSON.parse(fieldToSave));
        }
      });
    });

    this.tripComponent.tripLoaded(this.mapData);
  }

  /**
   * Método encargado de setear el valor en la grid cuando se está editando o guardando en la misma
   */
  setFieldValue(field: any, val: any) {
    if (field.campoParentId != '00000000-0000-0000-0000-000000000000') return;

    var numeric = false;
    var type = this.gridService.getColumnType(field);

    if (
      type == 'numeric' ||
      type == 'numberWithSeparator' ||
      type == 'number' ||
      type == 'decimal'
    ) {
      if (val >= 0 && val != '') {
        numeric = true;
      }
    }

    if (type == 'checkbox') {
      val = val ? 1 : 2;
    }

    if ((val != undefined && val != '') || numeric) {
      switch (type) {
        case 'multiSelect':
          this.dataEdit[field.name] = val.toString().replace(/"/g, '');
          break;
        case 'singleSelect':
        case 'hyperLink':
        case 'formula':
          this.dataEdit[field.name] = val.toString();
          break;
        case 'percentage':
        case 'currency':
        case 'numeric':
        case 'numberWithSeparator':
        case 'map':
        case 'carrousel':
          this.dataEdit[field.name] = val;
          break;
        case 'datetime':
          this.dataEdit[field.name] = `/Date(${val.valueOf()})/`;
          break;
        case 'date':
          this.dataEdit[field.name] = `/Date(${val.valueOf()})/`;
          break;
        case 'detalles':
        case 'checklist':
          var fieldName = `${field.gridDetails.endPoint.view}`;
          this.dataEdit[fieldName] = val;
          break;
        case 'checkbox':
          this.dataEdit[field.name] = val == 1;
          break;
        case 'radio':
          this.dataEdit[field.name] = val;
          break;
        default:
          this.dataEdit[field.name] = val ? val : '';
          break;
      }
    } else if (type != 'detalles' && type != 'checklist') {
      delete this.dataEdit[field.name];
    }
  }

  /**
   * Método encargado de detectar los cambios en la grid editable
   */
  editChangesGrid() {
    var changesGrid: any = kendo.jQuery(`#${this.idViewComponent}`).data('kendoGrid');

    changesGrid._data.forEach((changeField: any) => {
      changesGrid.dataSource._pristineData.forEach((initialField: any) => {
        if (initialField.Id == changeField.Id) {
          if (JSON.stringify(initialField) !== JSON.stringify(changeField)) {
            this.countChange++;
          }
        }
      });
    });

    changesGrid._data.forEach((changeField: any) => {
      changesGrid.dataSource._pristineData.forEach((initialField: any) => {
        if (initialField.Id == changeField.Id) {
          if (JSON.stringify(initialField) !== JSON.stringify(changeField)) {
            this.saveItemsGrid(changeField);
          }
        }
      });
    });
  }

  /**
   * Método encargado de navegar a vista modo edición en el formulario
   */
  editSelected(e: any) {
    if (e.dataItem != undefined) {
      localStorage.setItem('IdSelectedItem', e.dataItem.Id);
    } else {
      localStorage.setItem('IdSelectedItem', e.itemData.Id._value);
    }

    if (e.dataItem != undefined) {
      var params: NavigationExtras = {
        queryParams: {
          rowId: e.dataItem.Id,
          viewName: this.viewNameId,
          entityId: this.entityId,
        },
      };
    } else {
      var params: NavigationExtras = {
        queryParams: {
          rowId: e.itemData.Id._value,
          viewName: this.viewNameId,
          entityId: this.entityId,
        },
      };
    }

    if (this.viewsToOpen.Edit != undefined) {
      params.queryParams.appViewId = this.viewsToOpen.Edit;
    }

    if (this.inputStyle == 'inForm')
      this.editSelectedModal(e);
    else
      this.router.navigate(['/forms'], params);
  }

  /**
   * Método encargado de navegar a vista modo viewItem en el formulario
   */
  viewSelected(e: any) {
    if (e.dataItem != undefined) {
      localStorage.setItem('IdSelectedItem', e.dataItem.Id);
    } else {
      localStorage.setItem('IdSelectedItem', e.itemDta.Id._value);
    }

    if (e.dataItem != undefined) {
      var params: NavigationExtras = {
        queryParams: {
          rowId: e.dataItem.Id,
          viewName: this.viewNameId,
          entityId: this.entityId,
        },
      };
    } else {
      var params: NavigationExtras = {
        queryParams: {
          rowId: e.itemData.Id._value,
          viewName: this.viewNameId,
          entity: this.entityId,
        },
      };
    }

    if (this.viewsToOpen.Edit != undefined) {
      params.queryParams.appViewId = this.viewsToOpen.Edit;
    }

    this.router.navigate(['/viewItem'], params);
  }

  /**
   * Método encargado de hacer borrado físico de un registro seleccionado
   */
  async deleteSelected(e: any) {
    if (!this.showDelete) {
      this.notificationsService.notificationApp('error', this.customMessages.CanNotDelete);
      // this.notificationsService.notificationApp( 'error',this.customMessages.CanNotDelete);
      return;
    }
    let response = await this.notificationsService.confirmAlert('warning', this.customMessages.confirm);
      if (!response) {
        return
      }
      var id;
      if (e.dataItem != undefined) {
        id = e.dataItem.Id;
      } else {
        id = e.itemData.Id._value;
      }

      if (!id) {
        // console.log(this.customMessages.idMissing);
        this.notificationsService.notificationApp('error', this.customMessages.failure);
        // this.notificationsService.notificationApp( 'error',this.customMessages.failure);
        return;
      }

      var viewName = this.viewNameId;
      if (!viewName) {
        // console.log(this.customMessages.tableMissing);
        this.notificationsService.notificationApp('error', this.customMessages.failure);
        // this.notificationsService.notificationApp( 'error',this.customMessages.failure);
        return;
      }

      var dataLoadedEvent = {
        entityId: this.entityId,
        entityCode: this.viewNameId,
        dataItem: e.dataItem,
      };

      if (
        this.applicationService.fireEvents(
          'ItemDeleting',
          this.configTable.formsEvents,
          dataLoadedEvent
        )
      ) {
        var opts = {
          parameters: {
            userId: `${sessionStorage.getItem('userId')}`,
            appViewId: `${this.viewCode || this.route.snapshot.queryParams.appViewId}`,
            pType: 'Eliminar',
            aType: 'view',
            environment: `${backandGlobal.environment}`,
          },
        };

        var tenant = sessionStorage.getItem('tenantId');
        var urlTxDelete = `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api/lappiz/${viewName}/${id}/${tenant}`;
        this.proxyTxApiService.operationTxApi(`${urlTxDelete}`, JSON.stringify(opts), 'delete', backandGlobal.stateOnline, viewName)
          .then((response: any) => {
            this.trackOfflineEntityChanges(viewName);

            if (viewName.includes('Lappiz_Users')) {
              this.userService.replicateDelete(id, sessionStorage.aplicattionuserid);
            }

            this.notificationsService.notificationApp('success', this.customMessages.SuccessDelete);
            // this.notificationsService.notificationApp( 'success',this.customMessages.SuccessDelete);
          })
          .catch((error: any) => {
            this.errorCallback(error);
          })
          .finally(() => {
            this.refresh();
          });
      }
  }

  async setHistoryValues() {
    this.dataItemLogicalEntity.EventType = 'Actualizar';
    this.dataItemLogicalEntity.Edited_by = sessionStorage.getItem('userId');
    this.dataItemLogicalEntity.UserEmail = localStorage.getItem('userName');

    await this.userService
      .getIpUser()
      .then((response: any) => {
        this.dataItemLogicalEntity.IpAddress = response.ip;
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  clean(obj: any) {
    for (var propName in obj) {
      if (obj[propName] === '' || obj[propName] === null) {
        delete obj[propName];
      }
    }
  }

  /**
   * Método encargado de hacer borrado lógico de un registro seleccionado
   */
  async logicalDeleteSelected(e: any) {
    if (!this.showDelete) {
      this.notificationsService.notificationApp('error', this.customMessages.CanNotDelete);
      // this.notificationsService.notificationApp( 'error',this.customMessages.CanNotDelete);
      return;
    }
    let result = await this.notificationsService.confirmAlert('warning', this.customMessages.confirm);
    
      if (!result) {
        return
      }
      var id;
      if (e.dataItem != undefined) {
        id = e.dataItem.Id;
      } else {
        id = e.itemData.Id._value;
      }

      if (!id) {
        // console.log(this.customMessages.idMissing);
        this.notificationsService.notificationApp('error', this.customMessages.failure);
        // this.notificationsService.notificationApp( 'error',this.customMessages.failure);
        return;
      }

      var viewName = this.viewNameId;
      if (!viewName) {
        // console.log(this.customMessages.tableMissing);
        this.notificationsService.notificationApp('error', this.customMessages.failure);
        // this.notificationsService.notificationApp( 'error',this.customMessages.failure);
        return;
      }
      e.dataItem = this.validateDataItem(e.dataItem);
      var dataLoadedEvent = {
        entityId: this.entityId,
        entityCode: this.viewNameId,
        dataItem: e.dataItem,
      };

      this.dataItemLogicalEntity = e.dataItem;

      if (
        this.applicationService.fireEvents(
          'ItemDeleting',
          this.configTable.formsEvents,
          dataLoadedEvent
        )
      ) {
        var tenantId = sessionStorage.getItem('tenantId');

        this.dataItemLogicalEntity.RowStatus =
          this.dataItemLogicalEntity.RowStatus == 'Active'
            ? (this.dataItemLogicalEntity.RowStatus = 'Deleted')
            : 'Active';
        this.dataItemLogicalEntity.tenantId = tenantId;
        this.dataItemLogicalEntity.parameters = {
          userId: `${sessionStorage.getItem('userId')}`,
          appViewId: `${this.viewCode || this.route.snapshot.queryParams.appViewId}`,
          pType: 'Editar',
          aType: `${this.viewCode || this.route.snapshot.queryParams.appViewId != undefined
            ? 'view'
            : 'ffija'
            }`,
          environment: `${backandGlobal.environment}`,
        };

        if (this.configTable.HasHistorics) {
          await this.setHistoryValues();
        }

        this.clean(this.dataItemLogicalEntity);

        var urlTxLogicalDelete = `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api/lappiz/${viewName}/${id}`;
        this.proxyTxApiService.operationTxApi(`${urlTxLogicalDelete}`, JSON.stringify(this.dataItemLogicalEntity), 'update', backandGlobal.stateOnline, viewName)
          .then((response: any) => {
            // TODO: Revisar si es necesario crear un método en el api config para inactivar el usuario lógicamente
            // if (viewName.includes('Lappiz_Users')) {
            //     this.userService.replicateDelete(id);
            // }
            this.trackOfflineEntityChanges(viewName);

            this.notificationsService.notificationApp('success', this.customMessages.SuccessDelete);
            // this.notificationsService.notificationApp( 'success',this.customMessages.SuccessDelete);
          })
          .catch((error: any) => {
            this.errorCallback(error);
          })
          .finally(() => {
            this.refresh();
          });
      }
  }

  /**
   * Método encargado de navegar a vista modo creación en el formulario
   */
  addRow() {
    var defaultFieldsValues = null;
    if (!this.showAdd) {
      this.notificationsService.notificationApp('error', this.customMessages.CanNotCreate);
      // this.notificationsService.notificationApp( 'error',this.customMessages.CanNotCreate);
      return;
    }

    if (this.filterOptions) {
      defaultFieldsValues = [];
      var filterOptions = JSON.parse(this.filterOptions);
      filterOptions.forEach((filterOption: any) => {
        defaultFieldsValues.push({
          fieldName: filterOption.fieldName,
          value: filterOption.value,
        });
      });
    }

    var params: NavigationExtras = {
      queryParams: {
        viewName: this.viewNameId,
        entityId: this.entityId,
        entityName: this.viewNameId,
      },
    };

    if (defaultFieldsValues) {
      params.queryParams.defaultFieldsValues =
        JSON.stringify(defaultFieldsValues);
    }

    if (this.viewsToOpen.Create != undefined) {
      params.queryParams.appViewId = this.viewsToOpen.Create;
    }

    this.router.navigate(['/forms'], params);
  }

  /**
   * Método encargado para cerrar una alerta
   */
  closeAlert(index: any) {
    this.alerts.splice(index, 1);
  }

  cleanMetadata = function (item) {
    delete item.__modified;
    /*
        Limpiamos los campos que son temporales en la vista para que no viajen a la API
        Esto se usa especialmente para las vistas de detalles, debido a que es necesario
        para poder mostrar propiedades de navegacion al momento de agregar registros nuevos
    */
    if (item.__TempFieldsDetails) {
      for (var i in item.__TempFieldsDetails) {
        delete item[item.__TempFieldsDetails[i]];
      }
      delete item.__TempFieldsDetails;
    }
    //Se limpia el resto de la metadata
    if (!item || !(typeof item === 'object')) return;
    if (item.uid) {
      delete item.uid;
    }
    if (item._events) {
      delete item._events;
    }
    if (item.parent) {
      delete item.parent;
    }
    if (item._handlers) {
      delete item._handlers;
    }
    if (item.__metadata) {
      //Se limpia el objeto metadata
      delete item.__metadata;
    }
    for (var key in item) {
      if (!item.hasOwnProperty(key)) continue;
      if (item[key]) {
        var obj = item[key];
        if (obj.__deferred) {
          delete obj.__deferred;
        }
        if (typeof obj === 'object' && !(obj instanceof Date)) {
          this.cleanMetadata(obj);
        }
        if (
          Object.getOwnPropertyNames(obj).length === 0 &&
          typeof obj === 'object' &&
          !(obj instanceof Date)
        ) {
          delete item[key];
        }
      } else if (sessionStorage.getItem('multiSelect')) {
        if (sessionStorage.getItem('multiSelect').includes(key)) {
        }
      } else delete item[key];
    }
  };

  /**
   * Método encargado para realizar lógica de guardado de un registro seleccionado que se modificó
   */
  saveItemsGrid(row: any) {
    if (row === null || row === undefined) {
      this.notificationsService.notificationApp('error', this.customMessages.SaveItemGrid);
      // this.notificationsService.notificationApp( 'error',this.customMessages.SaveItemGrid);
      return;
    }

    this.waiting = true;
    this.alerts = [];

    this.dataEdit = null;
    this.dataEdit = row;

    var dates = [];
    var dateTimes = [];

    this.configTable.fields.forEach((field: any) => {
      switch (field.type) {
        case 'DateTime':
          dateTimes.push(field.code);
          break;
        case 'Date':
          dates.push(field.code);
          break;
      }
    });

    this.configTable.fields.forEach((field: any) => {
      this.setFieldValue(field, this.dataEdit[field.name]);
    });

    delete this.dataEdit.__metadata;
    delete this.dataEdit.parent;
    delete this.dataEdit._events;
    delete this.dataEdit.uid;
    delete this.dataEdit._handlers;
    delete this.dataEdit.dirty;
    delete this.dataEdit.dirtyFields;

    var keys = Object.keys(this.dataEdit);

    keys.forEach((key) => {
      if (key.includes('Details') || key.substr(key.length - 1, 1) == '_') {
        var keyScript = `delete this.dataEdit.${key}`;
        eval(keyScript);
      }

      if (this.dataEdit[key] === null) {
        var keyScript = `delete this.dataEdit.${key}`;
        eval(keyScript);
      }
    });

    for (let i = 0; i < keys.length; i++) {
      for (let j = 0; j < dates.length; j++) {
        if (keys[i] == dates[j]) {
          var value = parseInt(
            this.dataEdit[dates[j]].substring(
              6,
              this.dataEdit[dates[j]].length - 2
            )
          );
          var date = new Date(value);
          var formatDate = this.datePipe.transform(date, 'y-M-d');
          this.dataEdit[dates[j]] = formatDate;
        }
      }

      for (let j = 0; j < dateTimes.length; j++) {
        if (keys[i] == dateTimes[j]) {
          var value = parseInt(
            this.dataEdit[dateTimes[j]].substring(
              6,
              this.dataEdit[dateTimes[j]].length - 2
            )
          );
          var dateTime = new Date(value);
          var formatDateTime = this.datePipe.transform(dateTime, 'y-M-d h:m:s');
          this.dataEdit[dateTimes[j]] = formatDateTime;
        }
      }
    }

    this.cleanMetadata(this.dataEdit);

    this.dataEdit.tenantId = sessionStorage.getItem('tenantId');
    this.dataEdit.parameters = {
      userId: `${sessionStorage.userId}`,
      appViewId: `${this.viewCode || this.route.snapshot.queryParams.appViewId}`,
      pType: 'ffija',
      aType: 'Editar',
      environment: `${backandGlobal.environment}`,
    };

    var urlTxUpdate = `${backandGlobal.api2}/${sessionStorage.getItem(
      'workspace'
    )}.api/api/lappiz/${this.viewNameId}/${row.Id}`;

    this.proxyTxApiService.operationTxApi(`${urlTxUpdate}`, JSON.stringify(this.dataEdit), 'update', backandGlobal.stateOnline, this.viewNameId)
      .then((response: any) => {
        if (this.countChange == 1) {
          this.trackOfflineEntityChanges(this.viewNameId);
          this.notificationsService.notificationApp('success', this.customMessages.SuccessEdit);
          // this.notificationsService.notificationApp( 'success',this.customMessages.SuccessEdit);
          this.refresh();
        } else {
          this.countChange--;
        }
      })
      .catch((error: any) => {
        this.errorCallback(error);
      });
  }
  fixdata(data: any) {
    var o = '';
    var l = 0;
    var w = 10240;
    for (; l < data.byteLength / w; ++l) o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)));
    o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
    return o;
  }

  to_json(workbook: any) {
    var result = {};
    if (workbook.SheetNames) {
      workbook.SheetNames.forEach((sheetName: any) => {
        var roa = this.X.utils.sheet_to_json(workbook.Sheets[sheetName]);
        if (roa.length > 0) {
          result[sheetName] = roa;
        }
      });
    }
    return result;
  }


  importExcel($files: any) {
    var f = $files.target.files[0];
    var reader: any = new FileReader();
    reader.onload = async (e: any, viewName: any) => {
      var data = e.target.result;
      var arr = this.fixdata(data);
      var wb = this.to_json(this.X.read(btoa(arr), { type: 'base64' }));

      var nameObject = 'wb.' + Object.keys(wb)[0];
      var items = eval(nameObject);
      let dataGrid = kendo.jQuery(`#${this.idViewComponent}`).data('kendoGrid').dataSource.data();
      await items.forEach(async (item: any) => {
        await this.saveItemsGrid(item);
      });
      let dataSource = new kendo.data.DataSource({
        data: dataGrid
      });
      let grid = kendo.jQuery(`#${this.idViewComponent}`).data('kendoGrid');
      grid.setDataSource(dataSource);

      this.notificationsService.notificationApp('success', 'this.CustomMessages.SuccessImport');
    };
    reader.readAsArrayBuffer(f);
  };

  trackOfflineEntityChanges(viewName: string): void {
    if (backandGlobal.stateOnline === false) {
      var entitiesChanged = localStorage.EntityNamesChanged ? JSON.parse(localStorage.EntityNamesChanged) : [];
      entitiesChanged.filter((entity) => entity === viewName).length == 0 ? entitiesChanged.push(viewName) : '';
      localStorage.EntityNamesChanged = JSON.stringify(entitiesChanged);
    }
  }

  /**
   * Método encargado para guardar registro seleccionado que se modificó
   */
  saveSelected(e: any) {
    this.countChange = 1;
    this.saveItemsGrid(e.dataItem);
  }

  /**
   * Método encargado de ejecutar acciones globales
   */
  globalActionCode(action: any) {
    var scriptAction = this.applicationService.transformFunction(
      action.FormEvent
    );
    this.applicationService.evalEvent(scriptAction, {});
  }

  /**
   * Método encargado de ejecutar una acción de tipo código para el registro seleccionado
   */
  actionCode(actionId: any, selectedItem: any) {
    var e = {
      dataItem: selectedItem.dataItem
        ? selectedItem.dataItem
        : selectedItem.itemData,
    };

    var action = $.grep(this.configTable.Actions, (b: any) => {
      return b.ActionId === actionId;
    })[0];

    var scriptAction = this.applicationService.transformFunction(
      action.FormEvent
    );

    let implementation = this.configTable.formsEvents.reduce((implementation: string, event: any) => {
      if (event.EventTypeName == 'GlobalGeneral')
        implementation += `
        ${event.Implementation}
        `
      return implementation
    }, '');
    this.applicationService.evalEvent(`${implementation}
     ${scriptAction}`, e);
  }

  /**
   * Método encargado de ejecutar una acción de tipo navegación para el registro seleccionado
   */
  openDirective(queryString: string, selectedItem: any) {
    var params: NavigationExtras = {
      queryParams: {},
    };

    var parts = queryString.split('?')[1].split('&');

    for (let i = 0; i < parts.length; i++) {
      var value = parts[i].split('=')[1];

      if (value.includes('selectedItem')) {
        var data = {};

        if (selectedItem.dataItem != undefined) {
          data = selectedItem.dataItem;
        } else {
          data = selectedItem.itemData;
        }

        params.queryParams[parts[i].split('=')[0]] = `${data[value.replace('selectedItem.', '')]
          }`;
        // value = `${parts[i].split('=')}=${data[value.replace('selectedItem.', '')]}`;
      } else {
        params.queryParams[parts[i].split('=')[0]] = parts[i].split('=')[1];
        // value = parts[i];
      }
    }

    var path = queryString.split('?')[0];

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`${path}`], params);
    });
  }

  /**
   * Método encargado para abrir modal de documentos
   */
  openDocumentsModal(e: any, Code: any) {
    var val = '';
    var field;
    this.searchParams = this.route.snapshot.queryParams;
    // this.searchParams.rowId = e.dataItem.Id;
    this.configTable.fields.forEach((value: any) => {
      if (value.type == 'Archive' && value.Code == Code) {
        val = e.dataItem[value.name];
        field = value;
        field.entityParentId = e.dataItem.Id;
      }
    });

    if (val == '' || val === null || val === undefined) {
      val = '';
    }

    const modalRef = this.modalService.open(FileGridComponent, {
      animation: true,
      size: 'lg',
    });

    modalRef.componentInstance.documents = val.split(',');
    modalRef.componentInstance.field = field;
    modalRef.componentInstance.rowId = e.dataItem.Id;

    modalRef.result.then((newItem: any) => {
      if (newItem) {
        this.value.val = newItem;
        this.notificationsService.notificationApp('success', this.customMessages.DocumentsSave);
        // this.notificationsService.notificationApp( 'success',this.customMessages.DocumentsSave);
      }
    });
  }

  /**
   * Método encargado para abrir modal de QR
   */
  openQRCodeModal(e: any, Code: any) {
    var val = '';
    var field;
    this.searchParams = this.route.snapshot.queryParams;
    // this.searchParams.rowId = e.dataItem.Id;
    let fieldFilter = this.configTable.fields.filter(
      (value: any) => value.type == 'QRCode' && value.Code == Code
    );
    if (fieldFilter.length > 0) {
      val = e.dataItem[fieldFilter[0].name];
      field = fieldFilter[0];
      field.entityParentId = e.dataItem.Id;
    }

    if (val == '' || val === null || val === undefined) {
      val = '';
    }

    const modalRef = this.modalService.open(QrGridComponent, {
      animation: true,
      size: 'lg',
    });

    modalRef.componentInstance.value = { val: val };
    modalRef.componentInstance.field = field;
    modalRef.componentInstance.rowId = e.dataItem.Id;

    // modalRef.result.then((newItem: any) => {
    //   if (newItem) {
    //     this.value.val = newItem;
    //     this.notificationsService.notificationApp( 'success','Los documentos se han almacenado exitosamente.');
    //     // this.notificationsService.notificationApp( 'success',this.customMessages.DocumentsSave);
    //   }
    // });
  }

  /**
   * Método encargado para abrir modal de Signature
   */
  openSignatureModal(e: any, Code: any) {
    var val = '';
    var field;
    this.searchParams = this.route.snapshot.queryParams;
    // this.searchParams.rowId = e.dataItem.Id;
    let fieldFilter = this.configTable.fields.filter(
      (value: any) => value.type == 'Signature' && value.Code == Code
    );
    if (fieldFilter.length > 0) {
      val = e.dataItem[fieldFilter[0].name];
      field = fieldFilter[0];
      field.entityParentId = e.dataItem.Id;
    }

    if (val == '' || val === null || val === undefined) {
      val = '';
    }

    const modalRef = this.modalService.open(SignatureGridComponent, {
      animation: true,
      size: 'lg',
    });

    modalRef.componentInstance.value = { val: val };
    modalRef.componentInstance.field = field;
    modalRef.componentInstance.rowId = e.dataItem.Id;
  }

  /**
   * Método encargado para abrir modal con el visor de texto enriquecido
   */
  openRichText(e: any, Code: any) {
    this.searchParams = this.route.snapshot.queryParams;
    var richTextId = e.dataItem.Id;

    const modalRef = this.modalService.open(RichTextVisorComponent, {
      animation: true,
      size: 'lg',
    });

    modalRef.componentInstance.richTextId = richTextId;
    modalRef.componentInstance.codigo = Code;
    modalRef.componentInstance.viewNameId = this.viewNameId;
  }

  /**
   * Método encargado para abrir modal con el carrousel para las imagenes
   */
  selectImage(index: any) {
    var value = eval(`data.dataItem.${this.arrayPositions[index].field.Code}`);

    if (value === null) {
      var message = 'El registro no posee imagenes y/o videos asociados';
      this.notificationsService.notificationApp('info', message);
      return;
    }

    var arrayFile: string[] = value
      .replace('[', '')
      .replace(']', '')
      .split(',');
    var fileUrl = [];
    var isMultiple = false;

    if (arrayFile.length > 1) {
      arrayFile.forEach((img: any) => {
        fileUrl.push(backandGlobal.URL_API_UPLOAD_IMAGES + img);
      });

      isMultiple = true;
    } else {
      fileUrl.push(
        backandGlobal.URL_API_UPLOAD_IMAGES +
        value.replace('[', '').replace(']', '')
      );
    }

    const modalRef = this.modalService.open(CarouselModalComponent, {
      animation: true,
      size: 'lg',
    });

    modalRef.componentInstance.fileUrl = fileUrl;
    modalRef.componentInstance.isMultiple = isMultiple;
    modalRef.componentInstance.field = this.arrayPositions[index].field;
  }

  /**
   * Método encargado de mostrar errores
   */
  errorCallback(error: any) {
    this.load = false;
    if (error.status == 500 || error.status == 400) {
      if (error.error.name != undefined) {
        var messageError = error.error.name;

        if (messageError.includes('SequelizeForeignKeyConstraintError')) {
          messageError = this.customMessages.EntityUsed;
        }
      }

      if (messageError != null) {
        this.alerts = [{ type: 'danger', msg: messageError }];
        this.notificationsService.notificationApp('error', messageError);
      } else {
        this.alerts = [{ type: 'danger', msg: this.customMessages.failure }];
        this.notificationsService.notificationApp('error', this.customMessages.failure);
      }
    } else {
      this.alerts = [{ type: 'danger', msg: error.body }];
      this.notificationsService.notificationApp('error', this.customMessages.failure);
    }
  }

  /**
   * Método encargado para ejecutar evento ListLoaded
   */
  listLoaded(config: any) {
    this.applicationService.fireEvents('ListLoaded', config.formsEvents, config);
  }

  validateDataItem(data: any) {
    let keys = Object.keys(data);
    keys.forEach(key => {
      if (key.includes(sessionStorage.getItem('workspace')) && !this.isObject(data[key])) {
        delete data[key];
      }
    });
    return data;
  }

  isObject(val: any) {
    return val instanceof Object;
  }

  async addRecordDetails( /*e*/) {
    var modalInstance = await this.getModalIntence();
    await modalInstance.result.then((newItem: any) => {
      if (newItem) {
        this.addRegister(newItem);
      }
    }, () => { });
  }

  private async getModalIntence(e?: any) {
    if (e)
      e.dataItem = e.dataItem || e.itemData;
    var modalInstance = this.modalService.open(await ModalDetailsComponent, {
      animation: true,
      size: 'lg',
      backdrop: 'static',
      windowClass: 'right-modal'
    });
    modalInstance.componentInstance.viewName = this.viewName;
    modalInstance.componentInstance.rowId = e?.dataItem.Id || null;
    modalInstance.componentInstance.entityId = this.entityId;
    modalInstance.componentInstance.entityParentId = null;
    modalInstance.componentInstance.parent = 'inForm';
    modalInstance.componentInstance.parentRules = null;
    modalInstance.componentInstance.detailsId = this.idViewComponent;
    modalInstance.componentInstance.dataToSubmit = e?.dataItem || null;
    modalInstance.componentInstance.item = e?.dataItem || null;
    modalInstance.componentInstance.appViewIdDetails = this.viewsToOpen.Create;
    modalInstance.componentInstance.isViewItem = false;
    modalInstance.componentInstance.isDetails = false;
    return modalInstance;
  }

  addRegister(newItem: any) {
    newItem.__modified = true;
    newItem.isNewItem = true;
    var grid: any = kendo.jQuery(`#${this.idViewComponent}`).data('kendoGrid');
    grid.dataSource.add(newItem);

    if (newItem.continue) {
      this.addRecordDetails();
    }

    if (newItem.saveAndEdit) {
      var data = {
        dataItem: newItem
      }
      this.editSelected(data);
    }
  }

  async editSelectedModal(e: any) {
    let modalInstance = await this.getModalIntence(e);
    await modalInstance.result.then((newItem: any) => {
      if (newItem) {
        this.editRegister(newItem);
      }
    }, () => { });
  }

  editRegister(newItem: any) {
    var ListView: any = kendo.jQuery(`#${this.idViewComponent}`).data('kendoGrid');
    var dataSource = ListView.dataSource;
    var dataItem = dataSource._data.find(x => x.Id == newItem.Id);
    if (dataItem) {
      for (const key in newItem) {
        if (newItem.hasOwnProperty(key) && dataItem[key] !== undefined) {
          dataItem.set(key, newItem[key]);
        }
      }
      // If using remote data source, sync to update the backend
      //dataSource.sync();
      ListView.refresh();
    } else {
      console.error(`Item with Id ${newItem.Id} not found.`);
    }
  }

}
