import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import * as XLSX from 'xlsx';

@Injectable({
  providedIn: 'root',
})
export class ExcelService {
  constructor() {}

  public generateExcelFile<T>(arrayData: T[], fileName: string): void {
    const workSheet: XLSX.WorkSheet = this.createWorkSheet(arrayData);
    const workBook: XLSX.WorkBook = this.createWorkBook(workSheet);
    const excelData: any = this.writeWorkBook(workBook);
    const blob: Blob = this.createBlob(excelData);

    return this.downloadFile(blob, fileName);
  }

  public convertExcelToJson(worksheet: XLSX.WorkSheet): any[] {
    return XLSX.utils.sheet_to_json(worksheet, { raw: true });
  }

  public async readExcelFile(file: File): Promise<object[]> {
    const reader = new FileReader();
    const data = await this.readAsArrayBuffer(reader, file);
    const workbook: XLSX.WorkBook = this.createWorkBook(data);
    const worksheet: XLSX.WorkSheet = this.getFirstSheet(workbook);
    const jsonData: object[] = this.convertSheetToJson(worksheet);

    return jsonData;
  }

  private createWorkSheet<T>(data: T[]): XLSX.WorkSheet {
    return XLSX.utils.json_to_sheet(data);
  }

  private createWorkBook(ws: XLSX.WorkSheet): XLSX.WorkBook {
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    return wb;
  }

  private writeWorkBook(wb: XLSX.WorkBook): any {
    return XLSX.write(wb, { bookType: 'xlsx', bookSST: false, type: 'binary' });
  }

  private createBlob(excelData: any): Blob {
    return new Blob([this.s2ab(excelData)], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    });
  }

  private downloadFile(blob: Blob, fileName: string): void {
    const link: HTMLAnchorElement = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = `${fileName}.xlsx` ?? 'excel-file.xlsx';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  private s2ab(s: string): ArrayBuffer {
    const arrayBuffer: ArrayBuffer = new ArrayBuffer(s.length);
    const uint8Array: Uint8Array = new Uint8Array(arrayBuffer);
    for (let index = 0; index !== s.length; ++index)
      uint8Array[index] = s.charCodeAt(index) & 0xff;
    return arrayBuffer;
  }

  private async readAsArrayBuffer(
    reader: FileReader,
    file: File
  ): Promise<ArrayBuffer> {
    return new Promise<ArrayBuffer>((resolve, reject) => {
      reader.onload = () => resolve(reader.result as ArrayBuffer);
      reader.onerror = () => reject(reader.error);
      reader.readAsArrayBuffer(file);
    });
  }

  private getFirstSheet(workbook: XLSX.WorkBook): XLSX.WorkSheet {
    const sheetName: string = workbook.SheetNames[0];
    return workbook.Sheets[sheetName];
  }

  private convertSheetToJson(worksheet: XLSX.WorkSheet): object[] {
    return XLSX.utils.sheet_to_json(worksheet, { raw: true });
  }
}
