<div class="modal-body">
  <div class="input-group" style="width: 100%">
    <input placeholder="{{ field.displayName }}" type="text" id="search" class="form-control autocomplete"
      [required]="field.required" [disabled]="!field.IsEditable || field.disabled" />
  </div>
  <div #mapWrapper id="map" class="input-group" *ngIf="showMap" style="height: 450px; width: 100%; margin: auto"></div>
  <hr />
  <div class="btn-group" style="width: 45%; margin-left: 2.5%;">
    <button type="button" id="save" style="float: right" class="btn btn-outline-success" [disabled]="!select_location"
      (click)="saveAddress()">
      Aceptar
    </button>
  </div>
  <div class="btn-group" style="width: 45%; margin-left: 4.5%;">
    <button type="button" id="cancel" class="btn btn-outline-danger" (click)="cancelar()">
      Cancelar
    </button>
  </div>
</div>
