import { Injectable } from '@angular/core';

import { ProxyTxApiService } from '@services/proxy-tx-api.service';

import backandGlobal from '@env/env';

@Injectable({
  providedIn: 'root'
})
export class DataItemService {

  urlApiTx: string = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/lappiz`;

  constructor(
    private proxyTxApiService: ProxyTxApiService
  ) { }

  operation(t: string, viewName?: string, id?: string, objTx?: any) {
    switch (t) {
      case 'read':
        this.read(viewName);
        break;
      case 'create':
        this.create(viewName, objTx);
        break;
      case 'update':
        this.update(viewName, id, objTx);
        break;
      case 'delete':
        this.delete(viewName, id, objTx);
        break;
    }
  }

  read(viewName: any, secondparameter?:any): any { 
    return this.proxyTxApiService.dataGetTx(`${this.urlApiTx}/get/${viewName}`, backandGlobal.stateOnline);
  }

  create(viewName: string, objTx: any): any { 
    return this.proxyTxApiService.operationTxApi(`${this.urlApiTx}/${viewName}`, JSON.stringify(objTx), 'post', backandGlobal.stateOnline);
  }

  update(viewName: string, id: string, objTx: any): any { 
    return this.proxyTxApiService.operationTxApi(`${this.urlApiTx}/${viewName}/${id}`, JSON.stringify(objTx), 'update', backandGlobal.stateOnline);
  }

  delete(viewName: string, id: string, objTx: any): any { 
    return this.proxyTxApiService.operationTxApi(`${this.urlApiTx}/${viewName}/${id}/null`, JSON.stringify(objTx), 'delete', backandGlobal.stateOnline);
  }

}
