import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';

import 'devextreme/integration/jquery';
import { GroupResult } from '@progress/kendo-data-query';

import backandGlobal from '@env/env';

import { EndPointService } from '@services/end-point.service';
import { GlobalEventsService } from '@services/global-events.service';
import { ProxyConfigApiService } from '@services/proxy-config-api.service';
import { ProxyTxApiService } from '@services/proxy-tx-api.service';

import { IGetEntityTX } from 'src/app/interfaces/IGetEntityTX';
import { IOptionsTX } from 'src/app/interfaces/IOptionsTX'
import { IRol } from 'src/app/interfaces/IRol';
import { IRolesSave } from 'src/app/interfaces/IRolesSave';
import { IUser } from 'src/app/interfaces/IUser';
import { IUserRol } from 'src/app/interfaces/IUserRol';
import { IGlobalEvent } from 'src/app/interfaces/IGlobalEvent';
import { NotificationsService } from '@services/notifications.service';
import { Constants } from '@common/constants';

declare var kendo: any;

@Component({
  selector: 'app-asignar-rol',
  templateUrl: './asignar-rol.component.html',
  styleUrls: ['./asignar-rol.component.scss']
})
export class AsignarRolComponent implements OnInit {

  appViewId: string;
  defaultValue = { Email: 'Seleccione el usuario', Id: null }
  formAssignRole: UntypedFormGroup;
  multiselect: any;
  RolesDefualt: Array<string> = [];
  previous: Array<string> = [];
  actual: Array<string>;
  public users: Array<IUser>;
  public groupedData: IUser[];

  public virtual = { itemHeight: 20 };
  public listRoles: Array<IRol> = [];
  public listUsers: Array<IUser>;
  UserSel: string;
  RolesChanged: Array<IRolesSave> = [];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private proxyConfigApiService: ProxyConfigApiService,
    private proxyTxApiService: ProxyTxApiService,
    private globalEvents: GlobalEventsService,
    private endPointService: EndPointService,
    private notificationsService: NotificationsService
  ) {
    this.initializeMultiSelect();
    this.fetchUsersData();
    this.subscribeToActivatedRoute();
    this.initializeForm();
  }

  private initializeMultiSelect(): void {
    setTimeout(() => {
      this.multiselect = kendo.jQuery("#multiselect").kendoMultiSelect({
        dataTextField: "Nombre",
        dataValueField: "id",
        dataSource: this.listRoles,
        change: (e: any) => {
          this.multiSelectChange(e);
        },
        filter: "contains",
        placeholder: "Seleccionar rol...",
        tagTemplate: `<p><i class="fa fa-#: Status ?'check' : 'ban' #"></i> #: Nombre #</p>`,
        itemTemplate: `<p><i class="fa fa-#: Status ?'check' : 'ban' #"></i> #: Nombre #</p>`
      });
    }, 20);
  }

  private fetchUsersData(): void {
    let entity: string = backandGlobal.environment != 'PROD' ? `${backandGlobal.currentApp.name}_Users` : 'Lappiz_Users';
    const opc: IOptionsTX = this.buildOptionsTX(entity);
    const urlTx: string = this.endPointService.getEntityTX(entity);

    this.proxyTxApiService.getTxData(urlTx, opc, backandGlobal.stateOnline)
      .then((response: IGetEntityTX) => {
        this.handleUsersDataResponse(response.data.rows);
      })
      .catch(error => {
        console.log('Error endpoint call back => ' + error);
      });
  }

  private buildOptionsTX(entity: string): IOptionsTX {
    return {
      take: 99999,
      skip: 0,
      page: 1,
      pageSize: 99999,
      tenantId: null,
      attributes: ['Email', 'Id'],
      sort: [{ field: `${entity}.Email`, dir: 'asc' }],
      filter: {
        logic: "or",
        filters: []
      },
      parameters: {
        userId: sessionStorage.userId,
        pType: "showinmenu",
        aType: "ffija",
        environment: backandGlobal.environment
      }
    };
  }

  private handleUsersDataResponse(Users: Array<IUser>): void {
    this.listUsers = Users;
    this.users = this.listUsers.slice();
    this.groupedData = this.users;
  }

  private subscribeToActivatedRoute(): void {
    this.activatedRoute.paramMap.subscribe(params => {
      this.appViewId = params.get('appViewId');
    });
  }

  private initializeForm(): void {
    this.formAssignRole = new UntypedFormGroup({
      idUser: new UntypedFormControl(''),
      roles: new UntypedFormControl(''),
    });
  }

  ngOnInit(): void {
    this.proxyConfigApiService.getEvents('RolesUserLoaded').then((config: Array<IGlobalEvent>) => {
      this.rolesUserLoaded(config);
    })
  }

  private rolesUserLoaded(config: Array<IGlobalEvent>) {
    let params = {
      cancel: false,
    }
    this.globalEvents.fireEvents('RolesUserLoaded', config, params);
  }

  SaveChanges(): void {
    this.proxyConfigApiService.getConfigDataPost(this.endPointService.saveRol(this.UserSel), this.RolesChanged).then((data) => {
      this.notificationsService.notificationApp("success", Constants.MESSAGES.SuccessCreate);
      this.router.navigateByUrl('/roles');
    }, (data: any) => {
      this.notificationsService.notificationApp("error", Constants.MESSAGES.failureEdit);
      console.log(data.ExceptionMessage);
    });
  }

  async getRoles(data: unknown): Promise<void> {
    if (typeof data !== 'object') {
      return;
    }
    this.UserSel = data["Id"];
    if (this.listRoles.length === 0)
      this.listRoles = await this.proxyConfigApiService.getConfigDataGet(this.endPointService.getRoles());

    const UsersRoles = await this.proxyConfigApiService.getConfigDataGet(this.endPointService.getUserRoles(this.UserSel));
    this.RolesDefualt = [];
    this.RolesChanged = [];
    this.previous = [];
    this.searchRolOfUser(UsersRoles);
    this.updateMultiSelect();
  }

  private searchRolOfUser(UsersRoles: Array<IUserRol>): void {
    UsersRoles.forEach((userRol: IUserRol) => {
      const matchingRole = this.listRoles.find((rol: IRol) => rol.id === userRol.RoleId);

      if (matchingRole) {
        this.RolesDefualt.push(userRol.RoleId);
        this.previous.push(userRol.RoleId);
      }
    });
  }

  private updateMultiSelect(): void {
    this.multiselect = kendo.jQuery('#multiselect').data("kendoMultiSelect");
    if (this.multiselect) {
      this.multiselect.setDataSource(this.listRoles);
      this.multiselect.value(this.RolesDefualt);
    }
  }


  Volver(): void {
    this.router.navigateByUrl('/roles');
  }

  multiSelectChange(e: any): void {
    this.actual = e.sender.value();

    if (this.previous.length > this.actual.length) {
      this.handlePreviousLengthGreaterThanActual();
    } else if (this.previous.length <= this.actual.length) {
      this.handlePreviousLengthLessThanOrEqualActual();
    }

    this.previous = this.actual;
  }

  private handlePreviousLengthGreaterThanActual(): void {
    let itemToDel;
    for (let i = 0; i < this.previous.length; i++) {
      let isPresentInActual = this.actual.some(actualItem => actualItem === this.previous[i]);

      if (!isPresentInActual) {
        itemToDel = this.previous[i];
        this.processItemToDelete(itemToDel);
      }
    }
  }

  private handlePreviousLengthLessThanOrEqualActual(): void {
    let itemsel;
    for (let i = 0; i < this.actual.length; i++) {
      let isPresentInPrevious = this.previous.some(previousItem => previousItem === this.actual[i]);

      if (!isPresentInPrevious) {
        itemsel = this.actual[i];
        this.processItemToSave(itemsel);
      }
    }
  }

  private processItemToDelete(idRol: string): void {
    const isToDel = this.RolesDefualt.includes(idRol);

    if (isToDel) {
      const elemsToDel: IRolesSave = { IdRol: idRol, Action: 'Delete' };
      this.RolesChanged.push(elemsToDel);
    } else {
      this.RolesChanged = this.RolesChanged.filter(rolSave => rolSave.IdRol !== idRol);
    }
  }

  private processItemToSave(idRol: string): void {
    const isToSave = !this.RolesDefualt.includes(idRol);

    if (isToSave) {
      const elemsToAdd: IRolesSave = { IdRol: idRol, Action: 'Save' };
      this.RolesChanged.push(elemsToAdd);
    } else {
      this.RolesChanged = this.RolesChanged.filter(rolSave => rolSave.IdRol !== idRol);
    }
  }

  filter(value: unknown): void {
    if (typeof value !== 'string') {
      return;
    }

    const predicate = (item) => item.Email && item.Email.toLowerCase().includes(value.toLowerCase());
    this.groupedData = this.users.filter(predicate);
  }
}
