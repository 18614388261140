<form [formGroup]="innerForm">
  <div class="form-group">
    <div style="display: flex !important; align-items: stretch !important; width: 100% !important; height:2.5rem">
      <input [attr.disabled]="!field.IsEditable ? true : null || field.disabled ? true : null" (change)="change()"
        [required]="field.required" formControlName="field" [id]="field.campoId" [(ngModel)]="value.val"
        (ngModelChange)="change()" style="width: 100%;" type="text" inputmode="numeric" (click)="onFocus()"/>
      <div class="input-group-append  icons-field" style="right: 4em; bottom: 1.5em" *ngIf="field.IsEditable">
        <span (click)="onMicrophoneClick($event)" class="input-group-text icon-field icon-action" id="basic-addon0" *ngIf="field.IsSpeech" >
					<i [ngClass]="classMicrophone"></i>
				</span>
				<span class="input-group-text icon-field">{{ field.type == 'percentage' ? '%' : field.type == 'currency' ?
          field.currencySymbol : field.type == 'numberWithSeparator' ? '.00' : '#' }}</span>
      </div>
    </div>
  </div>
  <div *ngIf="formSubmitted || innerForm.controls.field.errors?.required && innerForm.controls.field.touched"
    class="ft-appengine-required-input" id="required_{{field.campoId}}" role="alert">
    {{errors.required}}
  </div>
  <div class="ft-appengine-required-input" id="min_{{field.campoId}}" role="alert"
    *ngIf="formSubmitted || innerForm.controls.field.errors?.min && innerForm.controls.field.touched">
    {{errors.minimumValue}}
  </div>
  <div class="ft-appengine-required-input" id="max_{{field.campoId}}" role="alert"
    *ngIf="formSubmitted || innerForm.controls.field.errors?.max && innerForm.controls.field.touched">
    {{errors.maximumValue}}
  </div>
</form>
