import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

declare var kendo: any;

@Component({
  selector: 'app-qr-grid',
  templateUrl: './qr-grid.component.html',
  styleUrls: ['./qr-grid.component.scss']
})
export class QrGridComponent implements OnInit {

  @Input() field: any;
  @Input() value: any;
  @Input() form: any;
  @Input() errors: string;
  @Input() inputClass: string;
  @Input() rowId: string;

  TYPE: any;
  typeInput: string;
  textQr: string;

  constructor(
    private activeModal: NgbActiveModal
  ) {
    this.TYPE = {
      'email': (textValue: string) => { return 'mailto:' + textValue },
      'url': (textValue: string) => { return textValue },
      'text': (textValue: string) => { return textValue },
      'number': (textValue: string) => { return 'tel:' + textValue },
    }
  }

  ngOnInit(): void {
    this.showQR();
  }

  closeDocuments() {
    this.activeModal.close();
  }

  showQR(){
    setTimeout(() => {
      $("#qrImage").ready(()=>{
        [this.typeInput, this.textQr] = this.value.val.split('|');
        kendo.jQuery("#qrImage").kendoQRCode({
          value: this.TYPE[this.typeInput](this.textQr),
          errorCorrection: "M",
          size: 250,
          border: {
            color: "#000000",
            width: 5
          }
        });
      })
    }, 500);
  }

}
