import { Injectable } from '@angular/core';

import { ProxyConfigApiService } from '@services/proxy-config-api.service';

import backandGlobal from '@env/env';

@Injectable({
  providedIn: 'root'
})
export class EntityService {

  urlConfigApi: string;

  constructor(
    private proxyConfigApiService: ProxyConfigApiService
  ) { 
    this.urlConfigApi = `${backandGlobal.url}`;
  }

  getEntityIdByCodeAndAppId(entityCode: string, appId: string) {
    return this.proxyConfigApiService.getConfigDataGet(`${this.urlConfigApi}/api/app/GetEntityIdByCodeAndAppId/${entityCode}/${appId}`);
  }

  getByCodeAndAppId(entityCode: string, appId: string) {
    return this.proxyConfigApiService.getConfigDataGet(`${this.urlConfigApi}/api/app/GetIdByCodeAndAppId/${entityCode}/${appId}`);
  }

  getPermisosEdicion(appId: string, entityCode: string, userId: string) {
    return this.proxyConfigApiService.getConfigDataGet(`${this.urlConfigApi}/api/app/GetPermisosEdicion/${appId}/${entityCode}/${userId}`);
  }

  isAnonymous(viewName: string, id: string) {
    return this.proxyConfigApiService.getConfigDataGet(`${this.urlConfigApi}/api/view/isanonymous/${viewName}/${id}`);
  }

  getEntityIdByAppName(appName: string) {
    return this.proxyConfigApiService.getConfigDataGet(`${this.urlConfigApi}/api/view/getentityid/${appName}`);
  }

  getEntityRegisterByAppName(appName: string) {
    return this.proxyConfigApiService.getConfigRegisterApp(`${this.urlConfigApi}/api/view/getentityid/${appName}`);
  }

  hasRegister(appName: string) {
    return this.proxyConfigApiService.getConfigDataGet(`${this.urlConfigApi}/api/view/hasregister/${appName}`);
  }

}
