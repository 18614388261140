import { Component, ElementRef, Input, OnInit, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FormsComponent } from '@components/forms/forms.component';
import { MapaModalComponent } from '@components/mapa/modal/mapa-modal/mapa-modal.component';

import { RootScopeService } from '@shared/root-scope.service';

import backandGlobal from '@env/env';

@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss']
})

export class MapaComponent implements OnInit, OnChanges {

  @Input() field: any;
  @Input() value: any;
  @Input() errors: any;
  @Input() showMap: boolean;

  @ViewChild('mapWrapper', { static: false }) mapElement: ElementRef;
  @ViewChild("search", { static: false }) searchElementRef: ElementRef;

  address: string;
  autocomplete: any;
  current_map: google.maps.Map;
  formSubmitted: any = false;
  innerForm: UntypedFormGroup;
  latitude: number;
  longitude: number;
  zoom: number;

  constructor(
    private modalService: NgbModal,
    private formsComponent: FormsComponent,
    private $rs: RootScopeService
  ) {

    this.innerForm = new UntypedFormGroup({
      field: new UntypedFormControl()
    });

  }

  ngOnInit(): void {
    this.field.value = this.value;

    if (this.field.value.val == '' && this.field.required == true) {
      this.innerForm = new UntypedFormGroup({
        field: new UntypedFormControl(this.value.val, [
          Validators.required,
          Validators.minLength(this.field.minimumValue),
          Validators.maxLength(this.field.maximumValue)
        ])
      });
    } else {
      if (this.field.required == true && this.field.value.val != '') {
        this.value.val = JSON.parse(this.value.val);
        this.address = this.value.val.address;
        this.latitude = this.value.val.pos[0];
        this.longitude = this.value.val.pos[1];
        this.innerForm = new UntypedFormGroup({
          field: new UntypedFormControl(this.value.val.address, [
            Validators.required,
            Validators.minLength(this.field.minimumValue),
            Validators.maxLength(this.field.maximumValue)
          ])
        });
      }

      if (this.field.required == true) {
        this.formsComponent.checkForm(this.field.campoId, !this.validateValue());
      } else {
        this.formsComponent.checkForm(this.field.campoId, false);
      }
    }

    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((pos) => {
        backandGlobal.MAP_DEFAULT_POSITION.pos[0] = pos.coords.latitude
        backandGlobal.MAP_DEFAULT_POSITION.pos[1] = pos.coords.longitude
      });
    }
  }

  ngAfterViewInit() {
    if (this.field.value.val === '') {
      this.innerForm = new UntypedFormGroup({
        field: new UntypedFormControl(this.value.val, [
          Validators.minLength(this.field.minimumValue),
          Validators.maxLength(this.field.maximumValue)
        ])
      });
    } else {
      if (this.field.required == false && this.field.value.val != '') {
        this.value.val = JSON.parse(this.value.val);
        this.address = this.value.val.address;
        this.latitude = this.value.val.pos[0];
        this.longitude = this.value.val.pos[1];
        this.innerForm = new UntypedFormGroup({
          field: new UntypedFormControl(this.value.val.address, [
            Validators.minLength(this.field.minimumValue),
            Validators.maxLength(this.field.maximumValue)
          ])
        });
      }
    }

    if (this.field.required == true) {
      this.formsComponent.checkForm(this.field.campoId, !this.validateValue());
    } else {
      this.formsComponent.checkForm(this.field.campoId, false);
    }

    const lngLat = new google.maps.LatLng(backandGlobal.MAP_DEFAULT_POSITION.pos[0], backandGlobal.MAP_DEFAULT_POSITION.pos[1]);
    const mapOptions: google.maps.MapOptions = {
      center: lngLat,
      zoom: 16,
      fullscreenControl: true,
      mapTypeControl: true,
      streetViewControl: true
    };

    this.autocomplete = new google.maps.places.Autocomplete(document.getElementById(`autocomplete_${this.field.campoId}`) as HTMLInputElement);

    google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
      var place = this.autocomplete.getPlace();
      this.address = place.formatted_address;
      this.latitude = place.geometry.location.lat();
      this.longitude = place.geometry.location.lng();

      $(`#autocomplete_${this.field.campoId}`).val(this.address);
      this.field.value = { val: { pos: [this.latitude, this.longitude], address: this.address } };
    });
  }

  async open() {
    const modalRef = this.modalService.open(await MapaModalComponent, {
      animation: true,
      size: 'lg',
      backdrop: 'static',
      keyboard: false
    });

    modalRef.componentInstance.field = this.field;
    modalRef.componentInstance.errors = '';
    modalRef.componentInstance.value = '';
    modalRef.componentInstance.showMap = true;
    modalRef.componentInstance.select_location = false;
    $('.autocomplete').css({ 'z-index': 1050 });
    var place = $(`#autocomplete_${this.field.campoId}`).val();

    if (place) {
      this.$rs.$rootScope.geolocalization = {};
      this.$rs.$rootScope.geolocalization.address = this.address;
      this.$rs.$rootScope.geolocalization.lat = this.latitude;
      this.$rs.$rootScope.geolocalization.lng = this.longitude;
    } else {
      this.$rs.$rootScope.geolocalization = undefined;
    }

    await modalRef.result.then((result: any) => {
      if (result) {
        this.address = result.address ? result.address : this.address;
        this.latitude = result.lat ? result.lat : this.latitude;
        this.longitude = result.lng ? result.lng : this.longitude;
        this.field.value = { val: { pos: [this.latitude, this.longitude], address: this.address } };
      }
    }, (error: any) => {
      console.log('Error:', error);
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    this.field.value.val = this.value.val;
    if (this.field.required == true) {
      this.formsComponent.checkForm(this.field.campoId, !this.validateValue());
    } else {
      this.formsComponent.checkForm(this.field.campoId, false);
    }
  }

  change() {
    if (this.value.val != '') {
      this.address = this.value.val.address;
      this.latitude = this.value.val.pos[0];
      this.longitude = this.value.val.pos[1];
    }
    if (this.field.required == true) {
      this.formsComponent.checkForm(this.field.campoId, !this.validateValue());
    } else {
      this.formsComponent.checkForm(this.field.campoId, false);
    }
  }

  validateValue(){
    if(!this.value.val)
      return false;
    if(!this.value.val.address || this.value.val.address == '')
      return false;
    return true;
  }

}
