<div style="height:250px ">
  <div class="col-lg-12 col-xs-12" style="padding: 0px">
    <div>
      <div>
        <div>
          <div [id]="'gallery_' + field.campoId"></div>
        </div>
      </div>
      <div id="'files_' + field.campoId" style="height:auto; width:100%; margin-bottom:1em; max-width: 380px;" class="row">
        <div style="margin-right: 15px;" class="media" *ngFor="let file of dataMinFiles">
          <div [ngSwitch]="file.type">
            <video [src]="file.url.replace('unsafe:','')" *ngSwitchCase="'Video'" class="videoOrImageStyle" (click)="selectItem(file.url)"
              autoplay></video>
            <img [src]="file.url" *ngSwitchCase="'Image'" class="videoOrImageStyle img-fluid" (click)="selectItem(file.url)" />
            <br><br><br><br><br>
            <button (click)="deleteSelected(file.url, file.name)" title="Eliminar selección" class="btn btn-primary">
              <i class="fa fa-times" style="color:red"></i>
            </button>
            <button (click)="ViewModelImagen(file)" title="Visualización" class="btn btn-primary">
              <i class="fa fa-search" style="color:#2A3F54"></i>
            </button>
          </div>
        </div>
      </div>
      <div class="buttonsC" style="width:100%;">
        <button class="btn btn-primary file-upload-button upload-btn">
          <input type="file" accept="image/*,video/*" (change)="onFileSelect($event.target)" multiple />
          <i class="fa fa-cloud-upload-alt" style="color:white; text-align: center; margin-right:0.5rem;"> </i>
          <span style="color:white; text-align: center;" translate="carrusel_uploadFiles"> Subir
            Archivos</span>
        </button>
        <button type="button" class="btn btn-secondary btn-icon-text mr-2 mb-md-0 btn-clear" (click)="clearCarrousel()"
          title="Limpiar" ngHide="!field.show" [disabled]="field.disabled">
          <i class="fa fa-trash" style="color:white"></i>
        </button>
      </div>
    </div>
  </div>
</div>
