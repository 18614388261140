import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { Subject, Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
// coment
import { AttachmentsService } from '@services/attachments.service';
import { UploadService } from '@services/upload.service';

import { FormsComponent } from '@components/forms/forms.component';

import backandGlobal from '@env/env';
import { DocumentsService } from '@services/documents.service';

@Component({
  selector: 'app-camara',
  templateUrl: './camara.component.html',
  styleUrls: ['./camara.component.scss']
})
export class CamaraComponent implements OnInit, AfterViewInit {

  @Input() field: any;
  @Input() value: any;
  @Input() errors: any;
  @Input() form: any;
  @Input() inputClass: string;

  // Hacer Toogle de la cámara On/Off
  public showWebcam = true;
  public allowCameraSwitch = true;
  public multipleWebcamsAvailable = false;
  public deviceId: string;
  public videoOptions: MediaTrackConstraints = {};

  public errorsWebcam: WebcamInitError[] = [];
  public webcamImage: any = null;

  private trigger: Subject<void> = new Subject<void>();
  private nextWebcam: Subject<boolean | string> = new Subject<boolean | string>();

  public imageFile: any = null;

  fotoContentType = null;
  photo = null;
  routeImg = '';
  showbcapture = true;
  showimg = false;
  showweb = true;

  idAttachment: any;
  extensionAttachment: any;

  constructor(
    private attachmentsService: AttachmentsService,
    private uploadService: UploadService,
    private formsComponent: FormsComponent,
    private documentsService: DocumentsService
  ) { }

  generateNewIdAttachment() {
    return uuidv4();
  }

  ngAfterViewInit() {
    if (this.value.val) {
      this.showWebcam = false;
      this.showimg = true;
      this.showbcapture = false;
      this.value.webcamImage = null;
      this.value.imageFile = null;
      if (backandGlobal.stateOnline === true) {
        let currentApp = JSON.parse(sessionStorage.getItem('currentApp'));
        if (currentApp.storageProvider) {
          let data = this.value.val.split("/");
          let id = data.shift();
          this.documentsService.downloadFile(data.join("|")).then(response => {
            this.value.routeImg = `data:application/octet-stream;base64,${response}`;
            if (currentApp.storageProvider == "AzureBlobStorage") {
              this.value.routeImg = `data:application/octet-stream;base64,${response}`;
            } else {
              this.value.routeImg = JSON.parse(response).url;
            }
            var img = document.getElementById(`${this.field.campoId}_btnTomarFoto`) as any;
            img.src = this.value.routeImg;
            this.routeImg = this.value.routeImg;
          }).catch((error) => {
            console.log(error)
          });
        } else {
          this.value.routeImg = backandGlobal.url + `/api/Upload/UploadImages/${this.value.val}`;
          var img = document.getElementById(`${this.field.campoId}_btnTomarFoto`) as any;
          img.src = this.value.routeImg;
          this.routeImg = this.value.routeImg;
        }
      } else {
        this.idAttachment = this.value.val.split('.')[0];
        this.attachmentsService.getAttachment(this.idAttachment).then((attachment: any) => {
          // Acá se obtiene el documento de la foto subida en Offline
          this.attachmentsService.getPhotoAttachment(attachment._id, attachment.name).then((dataAttachment: any) => {
            setTimeout(() => {
              var url = URL.createObjectURL(dataAttachment);
              var img = document.getElementById('btnTomarFoto') as any;
              img.src = url;
            }, 1000);
          }).catch((error: any) => {
            console.log(`Ha ocurrido el siguiente error al traer la data del attachment --> ${error}`);
          })
        }).catch((error: any) => {
          console.log(`Ha ocurrido el siguiente error --> ${error}`);
        });
      }

      this.value.showimg = true;
      this.value.showWebcam = false;
      this.value.showbcapture = false;
    } else {
      this.showimg = false;
    }

    this.formsComponent.checkForm(this.field.campoId, this.field.required === true ? this.value.val ? false : true : false);
  }

  ngOnInit(): void {
    WebcamUtil.getAvailableVideoInputs().then((mediaDevices: MediaDeviceInfo[]) => {
      this.multipleWebcamsAvailable = mediaDevices && mediaDevices.length > 1
    });
    this.value.webcamImage = null;
    this.value.imageFile = null;
    this.value.routeImg = '';
    this.value.showimg = false;
    this.value.showWebcam = true;
    this.value.showbcapture = true;
  }

  dataURItoBlob(dataURI: any) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(';')[0].split(':')[1];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    var bb = new Blob([ab], { type: mimeString });

    return bb;
  }

  toggleStateValue() {
    this.showbcapture = true;
    this.showweb = true;
    this.showWebcam = true;
    this.value.val = null;
    this.webcamImage = null;
    this.showimg = false;
    this.imageFile = null;
    this.value.val = null;
    this.value.webcamImage = null;
    this.value.imageFile = null;
    this.value.routeImg = null;
    this.value.showimg = false;
    this.value.showWebcam = true;
    this.value.showbcapture = true;
    this.formsComponent.checkForm(this.field.campoId, this.field.required === true ? this.value.val ? false : true : false);
  }

  clearImage(): any {
    if (backandGlobal.stateOnline === true) {
      let currentApp = JSON.parse(sessionStorage.getItem('currentApp'));
      if (currentApp.storageProvider) {
        let data = this.value.val.split("/");
        let id = data.shift();
        this.documentsService.delete(data.join("|"));
        this.documentsService.destroy(id);
      } else {
        this.uploadService.deleteFile(`${this.value.val}`);
      }
      this.toggleStateValue();
    }
    else {
      this.idAttachment = this.value.val.split('.')[0];
      this.attachmentsService.deleteAttachment(this.idAttachment).then(() => {
        this.showbcapture = true;
        this.showweb = true;
        this.showWebcam = true;
        this.value.val = null;
        this.webcamImage = null;
        this.showimg = false;
        this.imageFile = null;

        this.formsComponent.checkForm(this.field.campoId, this.field.required === true ? this.value.val ? false : true : false);
      }).catch((error: any) => {
        console.log(`Ha ocurrido el siguiente error --> ${error}`);
      });
    }
  }

  saveFiles(imageData: any, imageType: any, fileName: string) {
    // var str = `data:image/png;base64,${imageData}`;
    var str = backandGlobal.stateOnline === true ? `data:image/png;base64,${imageData}` : `data:${imageType};base64,${imageData}`;
    var blob = this.dataURItoBlob(str);

    // Acá se hace la validación para que dependiendo del estado de la aplicación vaya al UploadService o al AttachmentsService
    if (backandGlobal.stateOnline === true) {
      var fileu = [];
      fileu.push(blob);
      fileu[0].name = fileName;
      fileu[0].lastModified = fileu[0].size;
      fileu[0].lastModifiedDate = "";
      fileu[0].webkitRelativePath = "";
      let currentApp = JSON.parse(sessionStorage.getItem('currentApp'));
      if (currentApp.storageProvider) {
        const form = new FormData();
        for (let i = 0; i < fileu.length; i++) {
          form.append(`file[${i}]`, fileu[i], fileu[i].name);
        }
        let entity = this.field.viewName;
        this.documentsService.save(form, `/${entity}/${this.field.Code}`).then((response: any) => {
          var data = response;
          var fields = localStorage.fieldsUploaded == undefined ? [] : JSON.parse(localStorage.fieldsUploaded);
          data.forEach((value: any) => {
            let currentApp = JSON.parse(sessionStorage.getItem('currentApp'));
            this.field.value.val = `${value.Id}/${value.Link}`;
            fields.push(`${this.field.Code}:${this.field.value.val}`);
          });
          localStorage.fieldsUploaded = JSON.stringify(fields)
        })
      } else {
        this.uploadService.upload(fileu).then((result: any) => {
          result = result.fileNames[0];
          this.value.val = result;
          this.formsComponent.checkForm(this.field.campoId, this.field.required === true ? this.value.val ? false : true : false);
        });
      }
    }
    else {
      this.idAttachment = this.generateNewIdAttachment();

      var extension = imageType.split('/')[1];
      this.extensionAttachment = extension;

      var docAttached: any = {};
      docAttached._id = this.idAttachment;
      docAttached.name = `${this.idAttachment}.${extension}`;
      docAttached.typeAttachment = 'photo';
      docAttached.endPointApi = 'config';
      docAttached._attachments = {};
      docAttached.extraAttachments = {};
      docAttached._attachments[`${this.idAttachment}.${extension}`] = {
        'content_type': imageType,
        'data': blob
      }

      docAttached.extraAttachments[`${this.idAttachment}.${extension}`] = {
        'base64Photo': str,
        'idPhoto': this.idAttachment,
        'rowId': this.field.rowId,
        'namePhoto': `${this.idAttachment}.${extension}`,
        'typePhoto': imageType,
        'extensionPhoto': extension
      }

      this.attachmentsService.insertAttachment(docAttached).then((attachment: any) => {
        this.value.val = `${attachment.id}.${this.extensionAttachment}`;

        this.formsComponent.checkForm(this.field.campoId, this.field.required === true ? this.value.val ? false : true : false);
      }).catch((error: any) => {
        console.log(`Ha ocurrido el siguiente error --> ${error}`);
      });
    }
  };

  success(image: any, type: any, fileName: string) {
    this.photo = image;
    this.fotoContentType = type;
    this.showweb = false;
    this.saveFiles(image, type, fileName);
  };

  public triggerSnapshot(): void {
    this.trigger.next();

    if (this.webcamImage) {
      if (backandGlobal.stateOnline === true) {
        this.success(this.webcamImage._imageAsDataUrl.substr(this.webcamImage._imageAsDataUrl.indexOf('base64,') + 'base64,'.length), 'image/png', 'Foto.png');
      } else {
        this.success(this.webcamImage._imageAsDataUrl.substr(this.webcamImage._imageAsDataUrl.indexOf('base64,') + 'base64,'.length), this.webcamImage._mimeType, 'Foto.png');
      }
      this.showbcapture = false;
    }
  }

  public async getGalery(data) {
    let inputValue: any = data.target;
    var file: File = inputValue.files[0];
    var myReader: FileReader = new FileReader();
    let image: any;
    const fileName = file.name;

    myReader.onloadend = (e) => {
      image = myReader.result;
      var imageType = image.split(';')[0].split(':')[1];
      this.success(image.substr(image.indexOf('base64,') + 'base64,'.length), imageType, fileName);
      // if (backandGlobal.stateOnline === true) {
      //   this.success(image.substr(image.indexOf('base64,') + 'base64,'.length), 'image/png');
      // } else {
      //   this.success(image.substr(image.indexOf('base64,') + 'base64,'.length), imageType);
      // }

      this.imageFile = image;
      this.showbcapture = false;
      this.showWebcam = false;
      this.webcamImage = null;
      this.value.val = image;
      this.value.webcamImage = null;
      this.value.imageFile = image;
      this.value.routeImg = null;
      this.value.showimg = true;
      this.value.showWebcam = false;
      this.value.showbcapture = false;
    }

    myReader.readAsDataURL(file);
  }

  public toggleWebcam(): void {
    this.showWebcam = !this.showWebcam;
  }

  public handleInitError(error: WebcamInitError): void {
    this.errorsWebcam.push(error);
  }

  public showNextWebcam(directionOrDeviceId: boolean | string): void {
    this.nextWebcam.next(directionOrDeviceId);
  }

  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.showWebcam = false;
    this.imageFile = null;
    this.showbcapture = false;
    this.showWebcam = false;
    this.webcamImage = webcamImage;
    this.value.val = null;
    this.value.webcamImage = null;
    this.value.imageFile = null;
    this.value.routeImg = null;
    this.value.showimg = true;
    this.value.showWebcam = false;
    this.value.showbcapture = false;
  }

  public cameraWasSwitched(deviceId: string): void {
    this.deviceId = deviceId;
  }

  public get triggerObservable(): Observable<void> {
    return this.trigger.asObservable();
  }

  public get nextWebcamObservable(): Observable<boolean | string> {
    return this.nextWebcam.asObservable();
  }

  openGallery() {
    document.getElementById(`${this.field.campoId}_input_file`).click()
  }

};
