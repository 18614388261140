import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { RootScopeService } from '@shared/root-scope.service';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.scss']
})
export class PopupComponent implements OnInit, AfterViewInit {

  @Input() done: any;
  @Input() cancelModal: any;

  @Input() modalTemplate: any;
  @Input() htmlTemplate: any;

  @Input() dataItem: any;
  @Input() dataToSubmit: any;
  @Input() entityId: any;
  @Input() entityParentId: any;
  @Input() isDetails: any;
  @Input() rowId: any;
  @Input() parent: any;
  @Input() parentRules: any;
  @Input() viewName: any;

  @ViewChild('templateModal', { read: ElementRef }) templateModal: ElementRef;

  constructor(
    public activeModal: NgbActiveModal,
    private elementRef: ElementRef,
    public $rs: RootScopeService
  ) { }

  ngAfterViewInit(): void {
    this.templateModal.nativeElement.innerHTML = this.modalTemplate;

    setTimeout(() => {
      if (this.elementRef.nativeElement.querySelector('#btn-close')) {
        this.elementRef.nativeElement.querySelector('#btn-close').addEventListener('click', this.close.bind(this));
      }

      if (this.elementRef.nativeElement.querySelector('#btn-ok')) {
        this.elementRef.nativeElement.querySelector('#btn-ok').addEventListener('click', this.ok.bind(this));
      }

      if (this.elementRef.nativeElement.querySelector('#btn-cancel')) {
        this.elementRef.nativeElement.querySelector('#btn-cancel').addEventListener('click', this.cancel.bind(this));
      }
    }, 1000);
  }

  ngOnInit(): void { }

  close() {
    this.$rs.$rootScope.popUp = {};
    this.activeModal.dismiss();
  }

  ok() {
    let result = this.done;
    this.$rs.$rootScope.popUp = {};
    this.activeModal.close(result);
  }

  cancel() {
    let result = this.cancelModal;
    this.$rs.$rootScope.popUp = {};
    this.activeModal.dismiss(result);
  }

  detailsAdded(event: any, data: any) {
    this.done(data);
    this.activeModal.close(data);
  }

  closeDetails() {
    this.cancel();
    this.$rs.$rootScope.popUp = {};
    this.activeModal.close();
  }

  closeModal(event: any, data: any) {
    this.done(data);
    this.$rs.$rootScope.popUp = {};
    this.activeModal.close(data);
  }

}
