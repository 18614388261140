import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import backandGlobal from '@env/env';
import { TypeService } from './type.service';
import { ILFCall } from './../interfaces/ILFCall';
import { EndPointService } from './end-point.service';
import { ProxyTxApiService } from './proxy-tx-api.service';

@Injectable({
  providedIn: 'root',
})
export class TXService {
  constructor(
    private readonly _http: HttpClient,
    private readonly _endPoint: EndPointService,
    private readonly _proxyTxApiService: ProxyTxApiService
  ) {}

  public async execQuery(query: string, queryNoSQL?: any): Promise<Array<object>> {
    const [response] = await this.execDB(query, queryNoSQL);
    return response as Array<object>
  }

  public async execSP(
    spName: string,
    params: string[]
  ): Promise<Array<object>> {
    try {
      const command = this.loadSPParams(spName, params);
      return await this.execDB(command);
    } catch (error) {
      return error;
    }
  }

  public async execLF(LFCall: ILFCall): Promise<object> {
    const validationResult = this.validateInput(LFCall);
    const method = LFCall.method || 'post';
    if (validationResult) {
      return validationResult;
    }
    return this._http[method](
        this._endPoint.LFName(LFCall.nameFunction),
        this.loadRequestBody(LFCall),
        TXService.loadHttpHeaders()
      )
      .toPromise();
  }

  private async execDB(query: string, queryNoSQL?: any): Promise<any> {
    return backandGlobal.stateOnline
      ? await this._proxyTxApiService.execDB(query)
      : await this._proxyTxApiService.execDB(queryNoSQL);
  }

  private loadSPParams(spName: string, params: Array<string>): string {
    let command = `exec ${spName}`;

    if (params && params.length > 0) {
      command += ` ${params.join(', ')}`;
    }

    return command;
  }

  private validateInput(config: ILFCall): object | null {
    const valuesLoaded = [
      TypeService.isString(config.nameFunction),
      TypeService.isString(config.lappizFunctionId),
    ].every(Boolean);

    return !valuesLoaded
      ? { status: 400, message: 'el campo "nameFunction" es requerido' }
      : null;
  }

  private loadRequestBody(LFCall: ILFCall) {
    const requestBody = this.getRequestBody(LFCall);
    if (LFCall.body) {
      let keys = Object.keys(LFCall.body);
      keys.forEach((key) => {
        requestBody[key] = LFCall.body[key];
      });
    }
    return requestBody;
  }

  private getRequestBody(LFCall: ILFCall): object {
    return {
      parameters: {
        userId: sessionStorage.userId,
        aType: 'lappizFunction',
        pType: 'Execute',
        lappizFunctionId: LFCall.lappizFunctionId,
        environment: `${backandGlobal.environment}`,
      },
    };
  }

  public static loadHttpHeaders(): object {
    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('Authorization'),
      }),
    };
  }
}
