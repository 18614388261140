import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ServerTxApiService {

  tokenAuth: string;

  constructor(
    private http: HttpClient
  ) { 
    this.tokenAuth = localStorage.getItem('Authorization');
  }

  loadToken() {
    this.tokenAuth = localStorage.getItem('Authorization');
  }

  getTxData(url: string, opt: any): Promise<any[]> {
    this.loadToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenAuth
      })
    };

    return this.http.post<any[]>(`${url}`, opt, httpOptions).toPromise();
  }

  getTx(url: string): Promise<any[]> {
    this.loadToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenAuth
      })
    };

    return this.http.get<any[]>(`${url}`, httpOptions).toPromise();
  }

  getTxDetailsData(url: string, opt: any): Promise<any[]> {
    this.loadToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenAuth
      })
    };

    return this.http.post<any[]>(`${url}`, opt, httpOptions).toPromise();
  }

  getTxDataTimeline(url: string, opt: any): Promise<any[]> {
    this.loadToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenAuth
      })
    };

    return this.http.post<any[]>(`${url}`, opt, httpOptions).toPromise();
  }

  dataGetTx(url: string): Promise<any[]> {
    this.loadToken();
    const httpOptions = {
      headers: new HttpHeaders()
        .set('Authorization', this.tokenAuth)
        .set('Content-Type', 'application/json')
    };

    return this.http.get<any[]>(`${url}`, httpOptions).toPromise();
  }

  getTxFiles(url: string): Promise<any> {
    this.loadToken();
    const httpOptions = {
      header: new HttpHeaders()
        .set('Authorization', this.tokenAuth)
    };

    return this.http.get(`${url}`, { responseType: 'arraybuffer', headers: httpOptions.header }).toPromise();
  }

  execWF(url: string, token: string): Promise<any[]> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: `Basic ${token}`
      })
    }

    return this.http.post<any[]>(`${url}`, httpOptions).toPromise();
  }

  operationTxApi(url: string, opt: any, t: string): Promise<any> {
    this.loadToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenAuth
      })
    };
    switch (t) {
      case 'post':
        return this.http.post<any>(`${url}`, opt, httpOptions).toPromise();
        break;
      case 'update':
        return this.http.patch(`${url}`, opt, httpOptions).toPromise();
        break;
      case 'delete':
        const httpOptionsDelete = {
          headers: new HttpHeaders({
            'Content-Type': 'application/json; charset=UTF-8',
            Authorization: this.tokenAuth,
            
          }),body: opt
        }
        return this.http.delete(`${url}` , httpOptionsDelete).toPromise();
        break;
    }
  }

  insertBulkData(url: string, opt: any): Promise<any[]> {
    this.loadToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenAuth
      })
    };

    return this.http.post<any[]>(`${url}`, opt, httpOptions).toPromise();
  }

  updateAssignee(url: string, data: any): Promise<any> {
    this.loadToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenAuth
      })
    };

    return this.http.post(`${url}`, data, httpOptions).toPromise();
  }

  completeTaskTx(url: string, data: any): Promise<any> {
    this.loadToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenAuth
      })
    };

    return this.http.post(`${url}`, data, httpOptions).toPromise();
  }

  createUserTx(url: string, data: any): Promise<any> {
    this.loadToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenAuth
      })
    };

    return this.http.post(`${url}`, data, httpOptions).toPromise();
  }

  createRelationTx(url: string, data: any): Promise<any> {
    this.loadToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenAuth
      })
    };

    return this.http.post(`${url}`, data, httpOptions).toPromise();
  }

  deleteRelationTx(url: string): Promise<any> {
    this.loadToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenAuth
      })
    };

    return this.http.delete(`${url}`, httpOptions).toPromise();
  }

  updateRelationTx(url: string): Promise<any> {
    this.loadToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenAuth
      })
    };

    return this.http.patch(`${url}`, httpOptions).toPromise();
  }

  getRelationTx(url: string): Promise<any[]> {
    this.loadToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenAuth
      })
    };

    return this.http.get<any[]>(`${url}`, httpOptions).toPromise();
  }

  getNoFriendsTx(url: string): Promise<any[]> {
    this.loadToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenAuth
      })
    };

    return this.http.get<any[]>(`${url}`, httpOptions).toPromise();
  }

  synchronizeFile(ids: string[], url){
    this.loadToken();
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: this.tokenAuth
      })
    };
    let data = { ids };

    return this.http.post(`${url}`, data, httpOptions).toPromise();
  }

  execDB(url, opt): Promise<any> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('Authorization')
      })
    };
    return this.http.post(url, opt, httpOptions).toPromise();
  }

}
