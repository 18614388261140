import { Injectable } from '@angular/core';

import backandGlobal from '@env/env';
import { TypeService } from './type.service';

@Injectable({
  providedIn: 'root',
})
export class EndPointService {
  constructor() {}

  public setPassword(code: string, appCode: string): string {
    if(!code)
      code = '3155063796000000000'
    if(appCode)
      return `${backandGlobal.url}/api/Account/SetPassword?code=${code}&appCode=${appCode}`;
    return `${backandGlobal.url}/api/Account/SetPassword?code=${code}`;
  }

  public configAppName(baseUrl: string, appName: string): string {
    return `${baseUrl}/api/view/config/${appName}`;
  }

  public userInfo(): string {
    return `${backandGlobal.url}/api/account/userInfo`;
  }

  public getRoles(): string {
    return `${backandGlobal.url}/api/Roles/getRoles/${sessionStorage.workspaceId}`;
  }

  public getUserRoles(idUser: string): string {
    return `${backandGlobal.url}/api/Roles/getUserRols/${idUser}/${sessionStorage.workspaceId}`;
  }

  public saveRol(idUser: string): string {
    return `${backandGlobal.url}/api/Roles/SaveChanges?idUser=${idUser}`;
  }

  public getEntityTX(entity: string): string {
    return `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/get/${entity}`;
  }

  public getLappizPage(slug: string, appCode: string): string {
    if(localStorage.getItem('Authorization')){
      return `${backandGlobal.url}/api/page/${slug}/${appCode }/${localStorage.getItem("rolesId")}`;
    }
    return `${backandGlobal.url}/api/page/anonymous/${slug}/${appCode}`;
  }

  public getAllLappizPages(appCode: string): string {
    if(localStorage.getItem('Authorization')){
      return `${backandGlobal.url}/api/pages/${appCode}/${localStorage.getItem("rolesId")}`;
    }
    return `${backandGlobal.url}/api/pages/anonymous/${appCode}`;
  }

  private _getAppName(): string {
    const currentApp = JSON.parse(sessionStorage.getItem('currentApp'));
    const appName: string = currentApp?.name;

    if (!TypeService.isNull(appName)) {
      return `${backandGlobal.api2}/${appName}`;
    } else {
      throw new Error('Application name is missing in sessionStorage');
    }
  }

  public LFName(LFName: string): string {
    return `${this._getAppName()}.api/api/functions/${LFName}`;
  }

  public sendSMS(): string {
    return `${this._getAppName()}.api/api/utiles/sendSMS`;
  }

  public sendEmail(): string {
    return `${this._getAppName()}.api/api/utiles/sendEmail`;
  }

  public ToggleStatusRol(id: string): string{
    return `${backandGlobal.url}/api/roles/toggleStatus/${id}`;
  }
 
  public RoleWithUser(id: string): string{
    return `${backandGlobal.url}/api/roles/roleWithUser/${id}`;
  }

  public NotificationChangedPassword(userId: string, codeApp: string){
    return `${backandGlobal.url}/api/Account/notificationChangedPassword?userId=${userId}&CodeApp=${codeApp}`;
  }
}
