import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { FormsComponent } from '@components/forms/forms.component';

@Component({
  selector: 'app-radio',
  templateUrl: './radio.component.html',
  styleUrls: ['./radio.component.scss']
})
export class RadioComponent implements OnInit, OnChanges {

  @Input() field: any;
  @Input() value: any;
  @Input() form: any;
  @Input() inputClass: string;
  @Input() errors: string;

  public group: any;
  public selected: any = {}

  objetData: any;
  priorities: string[];

  innerForm: UntypedFormGroup;

  constructor(
    private formsComponent: FormsComponent
  ) {
    this.innerForm = new UntypedFormGroup({
      field: new UntypedFormControl()
    });
  }

  ngOnInit() {
    this.ConfigurationInitial();
  }

  ngOnChanges(changes: SimpleChanges) { }

  ConfigurationInitial() {
    if (this.field.required == true) {
      this.innerForm = new UntypedFormGroup({
        field: new UntypedFormControl(this.field.value.val, [
          Validators.required
        ])
      });
    }

    this.formsComponent.checkForm(this.field.campoId, this.innerForm.invalid);

    this.objetData = [];
    this.group = this.field.displayName;

    if (this.field.Values) {
      var values = this.field.Values.split(',');

      this.ValueDefault();

      for (var item in values) {
        if (this.value.val != '' && this.value.val == values[item]) {
          var index = item;
        }
        this.objetData.push({ text: values[item], value: values[item], });
      }

      this.selected = { data: this.objetData[index] };
    }

    this.formsComponent.applyUIRules(this.field, this.value);
  }

  ValueDefault() {
    if (this.value == '' || this.value === null || this.value === undefined) {
      if (this.field.defaultValue != '' || this.field.defaultValue !== null || this.field.defaultValue != undefined) {
        this.value = this.field.defaultValue;
      }
    }
  }

  changeRadio(selected) {
    this.value.val = selected;
    this.formsComponent.setValueFieldForm(this.field.campoId, this.field);
    this.formsComponent.applyUIRules(this.field, this.value);
    this.formsComponent.fireEvents('ValueChanged', this.field.events, {
      value: this.value.val,
      field: this.field
    });

    if (this.field.required == true) {
      this.formsComponent.checkForm(this.field.campoId, this.value.val ? false : this.innerForm.invalid);
    } else {
      this.formsComponent.checkForm(this.field.campoId, false);
    }
  }

}
