import { Injectable } from '@angular/core';
import PouchDB from 'pouchdb';
// import cordovaSqlitePlugin from 'pouchdb-adapter-cordova-sqlite';

import backandGlobal from '@env/env';
import { NotificationsService } from './notifications.service';
@Injectable({
  providedIn: 'root'
})
export class HttpCacheService {

  dbConfig: any;
  dbAttachments: any;
  urlApiConfig: string;
  urlRouteCache: string;
  environmentConfig: string;

  constructor(
    private notificationsService: NotificationsService
  ) {
    this.dbConfig = new PouchDB('appConfigurations');
    // PouchDB.plugin(cordovaSqlitePlugin);
    this.dbAttachments = new PouchDB('bd_AllAttachments');

    this.urlApiConfig = `${backandGlobal.url}`;
    this.urlRouteCache = `${backandGlobal.routeCache}`;
    this.environmentConfig = `${backandGlobal.environment.toLowerCase()}`;
  }

  succesMessage() {
    var message_2 = 'Listo para usar!';
    this.notificationsService.notificationApp('success',message_2);
  }

  async requestToApi(url: string, cacheId: string, data: any) {
    if (url.includes('workspaceId')) {
      if (!url.includes('undefined')) {
        var dbName = data.workspace.name.toLowerCase();

        this.dbConfig.destroy().then(() => {
          this.dbConfig = new PouchDB('appConfigurations');

          this.dbConfig.replicate.from(`${this.urlRouteCache}/${dbName}config/`, {
            retry: true
          }).on('active', () => {
            var message = 'Estamos preparando todo';
            this.notificationsService.notificationApp('info',message);
          }).on('error', () => {
            var message_1 = 'Error al cargar la configuración';
            this.notificationsService.notificationApp('warning',message_1);
          }).on('complete', () => {
            this.succesMessage();
          }).on('change', (info) => {
            // console.log('Cambios en la replicación', info);
          });
        });

        // Se comenta momentáneamente para compatibilidad de PouchDB
        // this.appContext.getAppState().then((context: any) => {
        //   var stateAppContext = context.stateApp;
        //   if (true) {
        //     var dbName = data.workspace.name.toLowerCase();

        //     this.dbConfig.destroy().then(() => {
        //       this.dbConfig = new PouchDB('appConfigurations');

        //       // this.dbConfig.replicate.from(`${this.urlRouteCache}/${dbName}${this.environmentConfig}config/`, {
        //       //   retry: true
        //       // }).on('active', () => {
        //       //   // TODO: Cambiar dependiendo del rootScope.lang
        //       //   var message = 'Sincronizando la carga de la configuración';
        //       //   this.notificationsService.notificationApp('info',message);
        //       // }).on('error', () => {
        //       //   // TODO: Cambiar dependiendo del rootScope.lang
        //       //   var message_1 = 'Error al cargar la configuración';
        //       //   this.notificationsService.notificationApp('warning',message_1);
        //       // }).on('complete', () => {
        //       //   // TODO: Cambiar dependiendo del rootScope.lang
        //       //   var message_2 = 'Configuración cargada exitosamente';
        //       //   this.notificationsService.notificationApp('success',message_2);
        //       // });
        //     });
        //   }
        // }).catch((error: any) => {
        //   console.log(`Ha ocurrido un error consultando el estado de la aplicación`);
        // });
      }

      // Se comenta momentáneamente para compatibilidad de PouchDB
      // this.appContext.getAppState().then((context: any) => {
      //   var stateAppContext = context.stateApp;
      //   if (true) {
      //     let workspaceId = data.workspace.id;
      //     this.proxyConfigApiService.getConfigDataGet(`${this.urlApiConfig}/api/getallentities/${workspaceId}`).then((response: any) => {
      //       localStorage.EntityNames = JSON.stringify(response);
      //     });
      //   }
      // }).catch((error: any) => {
      //   console.log(`Ha ocurrido un error consultando el estado de la aplicación`);
      // });
    }

    data._id = cacheId;

    // this.dbConfig.get(cacheId).then(doc => {
    //   // Sí ya existe lo removemos y lo volvemos a agregar
    //   this.dbConfig.remove(doc).then(() => {
    //     this.dbConfig.put(data);
    //   });
    // }).catch(() => {
    //   // Sí el documento no existe lo insertamos
    //   this.dbConfig.put(data);
    // });

    return data;
  }


  // async requestToApiAnonymous(url: string, callback: any) {
  //   const data: any = await this.serverConfigApiService.makeHttpGetRequest(url);
  //   callback(data);
  // }

  async get(cacheId: string, versionCache: string, forceVersionCache?: any) {
    if (cacheId) {
      try {
        const configuration: any = await this.dbConfig.get(cacheId);
        if (forceVersionCache) {
          try {
            if (sessionStorage.workspaceId) {
              if (configuration && configuration.versioncache == versionCache) {
                // Validate version
                return configuration;
              } else {
                return []
                //return await this.requestToApi(url, cacheId);
              }
            }
          } catch (error) {
            // Handle errors when getting version data
            console.log('No se pudo obtener los datos de la Pouch');
            return null;
            // return await this.requestToApi(url, cacheId);
          }
        }

        if (configuration && configuration.versioncache == sessionStorage.versionCache) {
          // Validate version
          return configuration;
        } else {
          // return await this.requestToApi(url, cacheId);
          return null;
        }
      } catch (error) {
        // Handle errors when getting configuration from the database
        // console.error('Error getting configuration from the database:', error);
        return null;
        // return await this.requestToApi(url, cacheId);
      }
    }
    return null;
    // return await this.requestToApi(url, cacheId);
  }

  // getAnonymous(url: string, callback: any) {
  //   return this.requestToApiAnonymous(url, callback);
  // }

  async getUserInfo() {
    try {
      const configuration: any = await this.dbConfig.get('userInfo');
      const userId = sessionStorage.getItem('userId');
      // const aplicationId = sessionStorage.getItem('workspaceId');
      const empresaId = sessionStorage.getItem('empresaId');
      if (configuration && configuration.Id == userId && configuration.EmpresaId == empresaId) {
        // Validate version
        return configuration;
      } else {
        // return await this.requestToApi(url, cacheId);
        return null;
      }
    } catch (error) {
      return null
    }
  }
}
