<form [formGroup]="innerForm">
    <div class="input-group input-map" style="width:100%">
        <input [attr.disabled]="!field.IsEditable ? true : null || field.disabled ? true : null" formControlName="field" [hidden]="showMap == true" id="autocomplete_{{field.campoId}}"
            placeholder="{{field.displayName}}" [(ngModel)]="value.val.address" (ngModelChange)="change()" autocorrect="off" [hidden]="showMap ==true"
            autocapitalize="off" spellcheck="off" type="text" class="form-control">
            <div class="icons-field">
                <button type="button" class="btn btn-lg btn-outline-primary btn-map-lappiz icon-field" [hidden]="showMap ==true" (click)="open()">
                    <i class="fa fa-map-marker"></i>
                </button>
             </div>   
    </div>
    <div *ngIf="formSubmitted || innerForm.controls.field.errors?.required && innerForm.controls.field.touched"
        class="ft-appengine-required-input" id="minlength_{{field.campoId}}" role="alert">
        {{errors.required}}
    </div>
</form>
