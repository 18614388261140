import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

import backandGlobal from '@env/env';

@Injectable({
  providedIn: 'root'
})
export class UploadService {

  constructor(private http: HttpClient) { }

  // Método que envia los archivos al endpoint /upload 
  upload(file: any): Promise<any> {
    const formData = new FormData();
    for (let i = 0; i < file.length; i++) {
      formData.append(`file[${i}]`, file[i], file[i].name);
    }

    return this.http.post<any>(`${backandGlobal.url}/api/Upload/Upload`, formData).toPromise();
  }

  // Método que envia las fotos offline a la api de configuración
  uploadOffline(file: any): Promise<any> {
    const formData = new FormData();
    for (let i = 0; i < file.length; i++) {
      formData.append(`file[${i}]`, file[i], file[i].name);
    }

    return this.http.post<any>(`${backandGlobal.url}/api/Upload/OfflineUpload`, formData).toPromise();
  }

  // Método para obtener los archivos
  getFiles() {
    return this.http.get(`${backandGlobal.url}/files`);
  }

  // Método para borrar los archivos
  deleteFile(filename: string) {
    return this.http.delete<any>(`${backandGlobal.url}/Delete?files=${filename}`).toPromise();
  }

}
