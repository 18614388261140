import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { FormsComponent } from '@components/forms/forms.component';

@Component({
  selector: 'app-link',
  templateUrl: './link.component.html',
  styleUrls: ['./link.component.scss']
})
export class LinkComponent implements OnInit, OnChanges {

  @Input() field: any;
  @Input() value: any;
  @Input() form: any;
  @Input() inputClass: any;
  @Input() errors: any;
  @Input() isTargetBlank: any;
  innerForm: any;
  closeResult: string;

  constructor(
    private modalService: NgbModal,
    private formsComponent: FormsComponent
  ) {

  }

  ngOnInit(): void {
    const urlRegex = /^https?:\/\/[\w\-]+(\.[\w\-]+)+[/#?]?.*$/;

    if (this.field.required == true) {
      this.innerForm = new UntypedFormGroup({
        field: new UntypedFormControl(this.field.value.val , [
          Validators.required
        ]),
        linkText: new UntypedFormControl(this.field.value.linkText, [
          Validators.required
        ]),
        linkUrl: new UntypedFormControl(this.field.value.url, [
          Validators.required,
          Validators.pattern(urlRegex)
        ])
      });
    } else {
      this.innerForm = new UntypedFormGroup({
        field: new UntypedFormControl(this.field.value.val),
        linkText: new UntypedFormControl(this.field.value.linkText),
        linkUrl: new UntypedFormControl(this.field.value.url, [Validators.pattern(urlRegex)])
      });
    }

    this.isTargetBlank = this.value.target == "_blank";

    this.formsComponent.checkForm(this.field.campoId, this.value.val ? false : this.innerForm.invalid);
  }

  targetChange() {
    this.value.target = this.isTargetBlank ? "_blank" : null;
  }

  open(content: any) {
    this.modalService.open(content, {
      animation: true,
      ariaLabelledBy: 'modal-basic-title'
    }).result.then((result: any) => {
      this.change();
    }, (resultDismiss: any) => {
      this.change();
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.innerForm) {
      this.value.url = this.innerForm.value.linkUrl;
      this.value.linkText = this.innerForm.value.linkText;
    }
  }

  change() {
    if (this.value.linkText && this.value.url) {
      this.value.val = `${this.value.linkText}|'_blank'|${this.value.url}`;
    } else {
      this.value.val = "";
    }

    this.formsComponent.setValueFieldForm(this.field.campoId, this.field);
    this.formsComponent.applyUIRules(this.field, this.value);
    this.formsComponent.actionFormula(this.field, this.value.val);
    this.formsComponent.fireEvents('ValueChanged', this.field.events, {
      value: this.value.val,
      field: this.field
    });

    if (this.field.required == true) {
      this.formsComponent.checkForm(this.field.campoId, this.value.val ? false : true);
    } else {
      this.formsComponent.checkForm(this.field.campoId, this.value.val ? false : false);
    }
  }

}
