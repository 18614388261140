<div class="row">
  <div class="col-md-12 stretch-card">
    <div class="card">
      <div class="card-body card-body-lappiz">
        <div class="header-section my-2">
          <div class="header-actions-section">
            <div class="container">
              <div class="row  d-flex align-items-center" style="column-gap: 8px">
                <img class="btn-back" src="../../../assets/images/flecha-izquierda.png" (click)="cancelar()" *ngIf="showCancel" title="Volver">
                <button class="btn btn-outline-primary crear" (click)="addRow()" *ngIf="showCreate" title="Crear">
                  <i class="fa fa-plus"></i> Crear
                </button>
              </div>
            </div>
            <div class="container">
              <div class="row d-flex justify-content-center">
                <div *ngFor="let action of globalActions" style="float:right;" class="sub_menu">
                  <div [ngSwitch]="action.Type" style="z-index: 1;">
                    <button *ngSwitchCase="'WorkFlow'" name="{{ action.ActionId }}" (click)="globalActionCode(action)"
                      title="{{ action.Name }}">
                      <i class="fa {{ action.IconAwesomeAction }}"></i>
                      {{ action.Name }}
                    </button>
                    <button *ngSwitchCase="'Codigo'" name="{{ action.ActionId }}" (click)="globalActionCode(action)"
                      title="{{ action.Name }}">
                      <i class="fa {{ action.IconAwesomeAction }}"></i>
                      {{ action.Name }}
                    </button>
                    <button *ngSwitchCase="'NavegarVista'" name="{{ action.ActionId }}"
                      (click)="openDirective(action.QueryString, action)" title="{{ action.Name }}">
                      <i class="fa {{ action.IconAwesomeAction }}"></i>
                      {{ action.Name }}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="title-section">
            <h3>{{ nameEntity }} {{ nameBack }}</h3>
          </div>
        </div>
        <hr>
        <div class="row" id="schedulerView-general">
          <div class="col-xs-12" id="add">
            <div id="schedulerView"></div>
            <div id="popup"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
