export enum EnumFontsFamily {
    Arial = "Arial, sans-serif",
    Helvetica = "Helvetica, sans-serif",
    Verdana = "Verdana, sans-serif",
    Tahoma = "Tahoma, sans-serif",
    TrebuchetMS = "Trebuchet MS, sans-serif",
    GillSans = "Gill Sans, sans-serif",
    CenturyGothic = "Century Gothic, sans-serif",
    LucidaSans = "Lucida Sans, sans-serif",
    TimesNewRoman = "Times New Roman, serif",
    Georgia = "Georgia, serif",
    PalatinoLinotype = "Palatino Linotype, serif",
    BookAntiqua = "Book Antiqua, serif",
    Lato = "Lato",
    Roboto = "'Roboto mono'",
    CourierNew = "Courier New, monospace",
    LucidaConsole = "Lucida Console, monospace",
    ComicSansMS = "Comic Sans MS, cursive",
    BrushScriptMT = "Brush Script MT, cursive",
    Impact = "Impact, fantasy",
    Jokerman = "Jokerman, fantasy",
    Times = "Times",
    Bodoni = "Bodoni",
    Garamond = "Garamond",
    cursive = "cursive",
    emoji = "emoji",
    fangsong = "fangsong",
    fantasy = "fantasy",
    math = "math",
    monospace = "monospace"
}