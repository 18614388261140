export class Constants {
  public static TOASTROPTIONS: any = {
    closeButton: false,
    newestOnTop: false,
    progressBar: false,
    positionClass: 'toast-bottom-right',
    preventDuplicates: true,
    timeOut: 10000,
    extendedTimeOut: 1000,
  };

  public static SWALOPTIONS: any = {
    toast: true,
    position: 'bottom-end',
    showConfirmButton: false,
    timer: 3000,
    timerProgressBar: true,
  }
  
  public static MESSAGES: any = {
    confirm: '¿Está seguro que desea eliminar este registro?',
    failure: 'No se pudo eliminar el registro.',
    idMissing: 'No se encontró el Id.',
    tableMissing: 'No se encontró la api.',
    CanNotDelete: 'No tiene permisos para eliminar los registros.',
    CanNotEdit: 'No tiene permisos para editar los registros.',
    CanNotCreate: 'No tiene permisos para crear registros.',
    SuccessEdit: 'El registro fue actualizado.',
    ProcessImport: 'Cargando registros',
    SuccessImport: 'Carga realizada correctamente',
    failureEdit: 'No se pudo actualizar el registro.',
    failureCreate: 'Error al crear la entidad.',
    SuccessCreate: 'Registro guardado.',
    SuccessDelete: 'Registro eliminado.',
    SaveItemGrid: 'Se presento un error con la información!',
    EntityUsed: 'La entidad esta siendo usada en otra parte de la aplicación',
    SuccessRegister: 'Bienvenido! Ya puedes iniciar sesión, no olvides confirmar tu correo.',
    RecordDuplicate: 'No se puede insertar un registro duplicado con el valor.',
    EmailDuplicate: 'El correo ya se encuentra registrado.',
    DocumentsSave: 'Los documentos se han almacenado exitosamente.',
    SuccessCall: 'Llamada exitosa y actualizada correctamente.',
    ConfirmUserEmail: 'Correo confirmado correctamente. Redireccionando...',
    FailConfirmUserEmail: 'El link ha expirado. Comunícate con el administrador.',
    NotExistUser: 'El usuario no existe',
    CatchUserValidate: 'The user does not exist',
    FailSendOTP: 'Error enviando código de confirmación',
    FailGeneral: 'Problemas, comunícate con el administrador.',
    LockUser: 'Su cuenta esta bloqueada. Revisa tu correo electrónico para recuperar tu cuenta',
    UpdatedPassword: 'Se ha actualizado su contraseña.',
    FaildUpdatedPassword: 'Ocurrió un error, asegúrate que las contraseñas tengan al menos un carácter especial (*/,.)(-_ ...), una minúscula ("a" - "z") y una mayúscula ("A" - "Z")',
    ResetPassword: 'Revisa tu cuenta de correo electrónico para restaurar tu contraseña',
    OnlyOneUpload: 'Solo se permite cargar un documento',
    MaxSizeUploadFile: 'Elija documentos menores a 100mb.',
    DeleteFile: 'Documento eliminado con exito',
    NotDownload: 'El documento no ha sido guardado, !No es posible descargarlo!',
    NotPreviewFile: 'Aún no se puede visualizar el documento, ya que no se encuentra cargado',
    EmptyUploadFile: 'No hay nuevos documentos para almacenar',
    InvalidNameFile: 'El documento a guardar tiene una "," (coma) en su nombre y no se puede almacenar, cambie su nombre e intentelo de nuevo',
    FailUploadFile: 'Ocurrio un error al subir un archivo, puede que este sea muy pesado para el sistema. Sólo se permiten archivos de menos de 30 megas.',
    TypeFileInvalidateToView: 'Tipo de archivo no soportado para visualizar',
    ErrorRules: 'Error evaluando reglas de negocio.',
    FailNotification: 'No fue posible enviar la notificacion',
    IsAlreadyRegistered: 'El usuario ya cuenta con registro en esta aplicación',
    ConfirmUpdateReplicate: '¿Actualizar información de este Usuario?',
    SuccessUserRegister: 'Usuario registrado exitosamente',
    SuccessUserRegisterAndUpdate: 'Usuario registrado y actualizado exitosamente',
    IsAlReadyUserInOtherApp: 'EL usuario se encuentra registrado en una applicación. ¿Desea agregar esta a sus aplicaciones?',
    CannotAccessResource: 'No puede acceder a este recurso',
    FailLoadRoles: 'Error al cargar los roles',
    UserRolAlReady: 'Rol con usuarios asignados',
    ActivateRol: 'Rol activado exitosamente',
    SuccessCreateRol: 'Rol creado satisfactoriamente',
    ExistsRol: 'No se pudo crear el rol ya que existe en la aplicación',
    InactivateRol: 'Rol inactivado exitosamente',
    FailToggleStatusRol: 'Problemas al cambiar el estado del rol',
    NotChangesPermits: 'No se han registrado cambios sobre los permisos',
    NotActivatedRoles: 'No cuentas con roles activos, contacta a tu administrador',
    SuccessChanges: 'Cambios efectuados correctamente',
    FailChanges: 'Error al guardar los cambios',
    SyncData: 'Sincronizando datos. Esto puede tardar unos minutos!',
    ErrorSyncData: 'Hubo un error sincronizando los datos. Por favor, contacte al administrador',
    NeedOnline: `Se necesita tener conexión a internet para realizar la sincronización`,
    SuccessSyncData: 'Datos sincronizados correctamente',
    PeviouslyUsedPassword: 'La contraseña ingresada ya ha sido utilizada anteriormente',
    CatchPreviouslyUsedPassword: 'Previously used password',
    CathInvalidCode: 'Invalid code',
    InvalidCode: 'El link ha expirado, por favor solicitar uno nuevo.',
    CatchInvalidPassword: 'Incorrect password',
    InvalidPassword: 'Contraseña incorrecta',
        // TODO: Cambiar cuando ya se tenga el multilenguaje
    //this.$rs.$rootScope.lang == 'es' ? 'Error evaluando reglas de negocio. ' : this.$rs.$rootScope.lang == 'en' ? 'Error evaluating business rules. ' : 'Error evaluando reglas de negocio. ';
    // confirm: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? '¿Está seguro que desea eliminar este registro?' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Are you sure you want to delete this record?' : '¿Está seguro que desea eliminar este registro?',
    // failure: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'No se pudo eliminar el registro.' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'The record could not be deleted.' : 'No se pudo eliminar el registro.',
    // idMissing: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'No se encontró el Id.' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Id not found.' : 'No se encontró el Id.',
    // tableMissing: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'No se encontró la api.' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'The api was not found.' : 'No se encontró la api.',
    // CanNotDelete: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'No tiene permisos para eliminar los registros.' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'You do not have permissions to delete the records.' : 'No tiene permisos para eliminar los registros.',
    // CanNotEdit: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'No tiene permisos para editar los registros.' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'You do not have permissions to edit the records.' : 'No tiene permisos para editar los registros.',
    // CanNotCreate: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'No tiene permisos para crear registros.' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'You do not have permissions to create records' : 'No tiene permisos para crear registros.',
    // SuccessEdit: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'El registro fue actualizado.' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'The record was updated.' : 'El registro fue actualizado.',
    // ProcessImport: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Cargando registros' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Loading records' : 'Cargando registros',
    // SuccessImport: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Carga realizada correctamente' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Loading done correctly' : 'Carga realizada correctamente',
    // failureEdit: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'No se pudo actualizar el registro.' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'The record could not be updated.' : 'No se pudo actualizar el registro.',
    // failureCreate: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Error al crear la entidad.' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Failed to create entity.' : 'Error al crear la entidad.',
    // SuccessCreate: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Registro guardado.' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Record saved.' : 'Registro guardado.',
    // SuccessDelete: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Registro eliminado.' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Record deleted.' : 'Registro eliminado.',
    // SaveItemGrid: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Se presento un error con la información!' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'An error ocurred with the information!' : 'Se presento un error con la información!',
    // EntityUsed: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'La entidad esta siendo usada en otra parte de la aplicación' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'The entity is being used in another part of the application' : 'La entidad esta siendo usada en otra parte de la aplicación',
    // SuccessRegister: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Bienvenido! Ya puedes iniciar sesión, no olvides confirmar tu correo.' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Welcome! You can now log in, dotn forget to confirm your email.' : 'Bienvenido! Ya puedes iniciar sesión, no olvides confirmar tu correo.',
    // RecordDuplicate: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'No se puede insertar un registro duplicado con el valor.' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Cannot insert duplicate record with value.' : 'No se puede insertar un registro duplicado con el valor.',
    // EmailDuplicate: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'El correo ya se encuentra registrado.' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'The mail is already registered.' : 'El correo ya se encuentra registrado.',
    // DocumentsSave: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Los documentos se han almacenado exitosamente.' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'The documents have been stored successfully.' : 'Los documentos se han almacenado exitosamente.',
    // SuccessCall: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Llamada exitosa y actualizada correctamente.' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Successful call and successfully updated' : 'Llamada exitosa y actualizada correctamente.'
  };

  public static MESSAGEFIELDS: any = {
    Required: 'Campo requerido.',
    MaxLength: 'Debe ser menor que ',
    MinLength: 'Debe ser mayor que ',
    Number: 'Debe ser un numero.',
    InvalidEmail: 'Debe ser un correo válido.',
    InvalidPassword: 'Contraseña invalida',
    FieldUploadFileIsRequired: 'Debe de subir un archivo'
    // TODO: Cambiar cuando ya se tenga el multilenguaje
    // Required: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Campo requerido.' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Required field.' : 'Campo requerido.',
    // MaxLength: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Debe ser menor que ' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Must be less than ' : 'Debe ser menor que ',
    // MinLength: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Debe ser mayor que ' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Must be greater than ' : 'Debe ser mayor que ',
    // Number: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Debe ser un numero.' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'It must be a number.' : 'Debe ser un numero.',
    // InvalidEmail: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Debe ser un correo válido.' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'It must be a valid email.' : 'Debe ser un correo válido.',
    // InvalidPassword: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Contraseña invalida' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Invalid password' : 'Contraseña invalida'
  };

  public static KENDOGRIDS: any = {
    messages: {
      info: 'Muestra los datos que:',
      filter: 'Filtrar',
      clear: 'Limpiar',
      and: 'Y',
      or: 'O',

      // TODO: Cambiar cuando ya se tenga el multilenguaje
      // info: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Muestra los datos que:' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Shows data that:' : 'Muestra los datos que:',
      // filter: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Filtrar' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Filter' : 'Filtrar',
      // clear: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Limpiar' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Clean' : 'Limpiar',
      // and: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Y' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'And' : 'Y',
      // or: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'O' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Or' : 'O'
    },
    operators: {
      string: {
        eq: 'Sean iguales a',
        neq: 'No sean iguales a',
        startswith: 'Comiencen con',
        contains: 'Contengan',
        endswith: 'Terminen con',

        // TODO: Cambiar cuando ya se tenga el multilenguaje
        // eq: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Sean iguales a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Be equal to' : 'Sean iguales a',
        // neq: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'No sean iguales a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Are not equal to' : 'No sean iguales a',
        // startswith: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Comiencen con' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Start with' : 'Comiencen con',
        // contains: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Contengan' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Contain' : 'Contengan',
        // endswith: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Terminen con' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Finish with' : 'Terminen con'
      },
      editor: {
        eq: 'Sean iguales a',
        neq: 'No sean iguales a',
        startswith: 'Comiencen con',
        contains: 'Contengan',
        endswith: 'Terminen con',

        // TODO: Cambiar cuando ya se tenga el multilenguaje
        // eq: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Sean iguales a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Be equal to' : 'Sean iguales a',
        // neq: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'No sean iguales a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Are not equal to' : 'No sean iguales a',
        // startswith: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Comiencen con' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Start with' : 'Comiencen con',
        // contains: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Contengan' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Contain' : 'Contengan',
        // endswith: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Terminen con' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Finish with' : 'Terminen con'
      },
      checkbox: {
        eq: 'Sean iguales a',
        neq: 'No sean iguales a',
        startswith: 'Comiencen con',
        contains: 'Contengan',
        endswith: 'Terminen con',

        // TODO: Cambiar cuando ya se tenga el multilenguaje
        // eq: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Sean iguales a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Be equal to' : 'Sean iguales a',
        // neq: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'No sean iguales a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Are not equal to' : 'No sean iguales a',
        // startswith: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Comiencen con' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Start with' : 'Comiencen con',
        // contains: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Contengan' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Contain' : 'Contengan',
        // endswith: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Terminen con' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Finish with' : 'Terminen con'
      },
      text: {
        eq: 'Sean iguales a',
        neq: 'No sean iguales a',
        startswith: 'Comiencen con',
        contains: 'Contengan',
        endswith: 'Terminen con',

        // TODO: Cambiar cuando ya se tenga el multilenguaje
        // eq: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Sean iguales a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Be equal to' : 'Sean iguales a',
        // neq: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'No sean iguales a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Are not equal to' : 'No sean iguales a',
        // startswith: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Comiencen con' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Start with' : 'Comiencen con',
        // contains: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Contengan' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Contain' : 'Contengan',
        // endswith: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Terminen con' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Finish with' : 'Terminen con'
      },
      number: {
        eq: 'Sean iguales a',
        neq: 'No sean iguales a',
        gte: 'Sean mayor o igual a',
        gt: 'Sean mayor a',
        lte: 'Sean menor o iguales a',
        lt: 'Sean menores a',

        // TODO: Cambiar cuando ya se tenga el multilenguaje
        // eq: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Sean iguales a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Be equal to' : 'Sean iguales a',
        // neq: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'No sean iguales a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Are not equal to' : 'No sean iguales a',
        // gte: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Sean mayor o igual a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Are greater than or equal to' : 'Sean mayor o igual a',
        // gt: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Sean mayor a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Are greater than' : 'Sean mayor a',
        // lte: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Sean menor o iguales a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Are less than or equal to' : 'Sean menor o iguales a',
        // lt: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Sean menores a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Are less than' : 'Sean menores a'
      },
      numeric: {
        eq: 'Sean iguales a',
        neq: 'No sean iguales a',
        gte: 'Sean mayor o igual a',
        gt: 'Sean mayor a',
        lte: 'Sean menor o iguales a',
        lt: 'Sean menores a',

        // TODO: Cambiar cuando ya se tenga el multilenguaje
        // eq: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Sean iguales a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Be equal to' : 'Sean iguales a',
        // neq: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'No sean iguales a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Are not equal to' : 'No sean iguales a',
        // gte: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Sean mayor o igual a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Are greater than or equal to' : 'Sean mayor o igual a',
        // gt: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Sean mayor a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Are greater than' : 'Sean mayor a',
        // lte: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Sean menor o iguales a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Are less than or equal to' : 'Sean menor o iguales a',
        // lt: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Sean menores a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Are less than' : 'Sean menores a'
      },
      date: {
        eq: 'Sean iguales a',
        neq: 'No sean iguales a',
        gte: 'Sean antes o en',
        gt: 'Sean antes de',
        lte: 'Sean despues o en',
        lt: 'Sean despues',

        // TODO: Cambiar cuando ya se tenga el multilenguaje
        // eq: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Sean iguales a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Be equal to' : 'Sean iguales a',
        // neq: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'No sean iguales a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Are not equal to' : 'No sean iguales a',
        // gte: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Sean antes o en' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Be it before or in' : 'Sean antes o en',
        // gt: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Sean antes de' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Before' : 'Sean antes de',
        // lte: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Sean despues o en' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Be it after or in' : 'Sean despues o en',
        // lt: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Sean despues' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Be after' : 'Sean despues'
      },
      enums: {
        eq: 'Son iguales a',
        neq: 'No son iguales a',

        // TODO: Cambiar cuando ya se tenga el multilenguaje
        // eq: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Son iguales a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Are equal to' : 'Son iguales a',
        // neq: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'No son iguales a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Are not equal to' : 'No son iguales a'
      },
      email: {
        eq: 'Sean iguales a',
        neq: 'No sean iguales a',
        startswith: 'Comiencen con',
        contains: 'Contengan',
        endswith: 'Terminen con',

        // TODO: Cambiar cuando ya se tenga el multilenguaje
        // eq: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Sean iguales a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Be equal to' : 'Sean iguales a',
        // neq: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'No sean iguales a' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Are not equal to' : 'No sean iguales a',
        // startswith: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Comiencen con' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Start with' : 'Comiencen con',
        // contains: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Contengan' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Contain' : 'Contengan',
        // endswith: localStorage.NG_TRANSLATE_LANG_KEY == 'es' ? 'Terminen con' : localStorage.NG_TRANSLATE_LANG_KEY == 'en' ? 'Finish with' : 'Terminen con'
      },
    },
  };
}
