import { Component, Input, OnInit, AfterViewInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { FormsComponent } from '@components/forms/forms.component';
import { VoiceRercognitionService } from '@services/voice-rercognition.service';

declare var kendo: any;

@Component({
  selector: 'app-numeric',
  templateUrl: './numeric.component.html',
  styleUrls: ['./numeric.component.scss']
})
export class NumericComponent implements OnInit, AfterViewInit {

  @Input() field: any;
  @Input() value: any;
  @Input() errors: any;

  formSubmitted = false;
  innerForm: UntypedFormGroup;

  microphoneActive: boolean;
  classMicrophone: string;

  public options: any = {};
  tools: any;

  constructor(
    private formsComponent: FormsComponent,
    private voiceRecognitionService: VoiceRercognitionService
  ) {

    this.innerForm = new UntypedFormGroup({
      field: new UntypedFormControl(),
    });
    this.classMicrophone ="fa fa-solid fa-microphone";
    this.microphoneActive = false;
  }

  ngAfterViewInit() {
    if (this.field.required == false) {
      let validators = [];
      if (this.field.minimumValue != 0) {
        validators.push(Validators.min(this.field.minimumValue));
      }
      if (this.field.maximumValue != 0 && this.field.maximumValue != 2147483647 ) {
        validators.push(Validators.max(this.field.maximumValue))
      }
      this.innerForm = new UntypedFormGroup({
        field: new UntypedFormControl(
          this.value.val == ''
            ? (this.value.val = 0)
            : (this.value.val = parseFloat(this.value.val)),
          {
            validators,
            updateOn: 'blur'
          }
        )
      });
    } else {
      let validators = [Validators.required];
      if (this.field.minimumValue != 0) {
        validators.push(Validators.min(this.field.minimumValue));
      }
      if (this.field.maximumValue != 0 && this.field.maximumValue != 2147483647 ) {
        validators.push(Validators.max(this.field.maximumValue))
      }
      this.innerForm = new UntypedFormGroup({
        field: new UntypedFormControl(
          this.value.val == ''
            ? (this.value.val = 0)
            : (this.value.val = parseFloat(this.value.val)),
          {
            validators,
            updateOn: 'blur'
          }
        ),
      });
    }
  }

  ngOnInit(): void {
    if (this.field.required == true) {
      let validators = [Validators.required];
      if (this.field.minimumValue != 0) {
        validators.push(Validators.min(this.field.minimumValue));
      }
      if (this.field.maximumValue != 0 && this.field.maximumValue != 2147483647 ) {
        validators.push(Validators.max(this.field.maximumValue))
      }
      this.innerForm = new UntypedFormGroup({
        field: new UntypedFormControl(
          this.value.val == ''
            ? (this.value.val = 0)
            : (this.value.val = parseFloat(this.value.val)),
          {
            validators,
            updateOn: 'blur'
          }
        ),
      });
    } else {
      let validators = [];
      if (this.field.minimumValue != 0) {
        validators.push(Validators.min(this.field.minimumValue));
      }
      if (this.field.maximumValue != 0 && this.field.maximumValue != 2147483647 ) {
        validators.push(Validators.max(this.field.maximumValue))
      }
      this.innerForm = new UntypedFormGroup({
        field: new UntypedFormControl(
          this.value.val == ''
            ? (this.value.val = 0)
            : (this.value.val = parseFloat(this.value.val)),
          {
            validators,
            updateOn: 'blur'
          }
        ),
      });
    }

    if (!this.value && this.field.defaultValue) {
      this.value = this.field.defaultValue;
    }

    this.formsComponent.checkForm(this.field.campoId, this.innerForm.invalid);

    this.field.value.val == ''
      ? (this.field.value.val = 0)
      : (this.field.value.val = this.field.value.val);

    switch (this.field.type) {
      case 'currency':
        this.field.numericFormat = `c${this.field.scala}`;
        break;
      case 'percentage':
        this.field.numericFormat = `p${this.field.scala}`;
        break;
      case 'numeric':
        this.field.numericFormat = `n${this.field.scala}`;
        break;
      case 'number':
        this.field.numericFormat = `n${this.field.scala}`;
        break;
      case 'numberWithSeparator':
        this.field.numericFormat = `n${this.field.scala}`;
        break;
    }

    this.tools = {
      min: this.field.minimumValue || this.field.minimumValue == 0 ? this.field.minimumValue : null,
      max: this.field.maximumValue != 0 && this.field.maximumValue != 2147483647 ? this.field.maximumValue : null,
      format: this.field.numericFormat,
      required: this.field.required,
      disabled: this.field.disabled || !this.field.IsEditable,
      hidden: this.field.show,
      decimals: this.field.scala > 0 ? this.field.scala : 0,
      round: false,
      restrictDecimals: this.field.scala > 0 ? true : false,
      step: this.field.scala > 0 ? 1 / Math.pow(10, this.field.scala) : 1,
      spin: (spin: any) => {
        this.field.value.val = spin.sender.value();
        this.change();
      },
      change: (e: any) => {
        this.field.value.val = e.sender.value();
        this.change();
      }
    };

    if (!this.value.val && this.field.defaultValue) {
      this.value.val = this.field.defaultValue;
    }

    setTimeout(() => {
      var numeric = kendo.jQuery(`#${this.field.campoId}`).data('kendoNumericTextBox');
      if (!numeric) {
        kendo.jQuery(`#${this.field.campoId}`)
          .kendoNumericTextBox(this.tools)
          .width('100%');
      }

      this.formsComponent.applyUIRules(this.field, this.value);
      if (this.field.IsSpeech)
        this.voiceRecognitionService.serviceAvailable.subscribe();
    }, 1000);
  }

  change() {
    this.toNumber();
    var exec = 0;
    this.actionFormula(exec);
    if(this.field.minimumValue != 0 ? this.field.minimumValue : null)
    if (this.field.value.val < this.field.minimumValue) {
      this.field.value.val = this.field.minimumValue;
      kendo.jQuery(`#${this.field.campoId}`).data('kendoNumericTextBox').value(this.field.value.val);
    }

    if(this.field.maximumValue != 0 && this.field.maximumValue != 2147483647)
    if (this.field.value.val > this.field.maximumValue) {
      this.field.value.val = this.field.maximumValue;
      kendo.jQuery(`#${this.field.campoId}`).data('kendoNumericTextBox').value(this.field.value.val);
    }

    this.field.value.val = parseFloat(this.field.value.val);
    this.formsComponent.setValueFieldForm(this.field.campoId, this.field);
    this.formsComponent.applyUIRules(this.field, this.value);
    this.formsComponent.fireEvents('ValueChanged', this.field.events, {
      value: this.value.val,
      field: this.field
    });
    this.formsComponent.checkForm(this.field.campoId, this.innerForm.invalid);
  }

  toNumber() {
    this.value.val = +this.value.val;
  }

  actionFormula(exec: any) {
    exec = 1;
  }

  onMicrophoneClick($event:any) {
    if (this.voiceRecognitionService.init('es-CO', this.field.campoId)){
      if (!this.microphoneActive){
        this.classMicrophone ="fa fa-solid fa-microphone microphone-active";
        this.voiceRecognitionService.start(this.field.campoId);
      }
      else {
        this.classMicrophone ="fa fa-solid fa-microphone";
        this.value.val = parseFloat(this.voiceRecognitionService.stop(this.field.campoId));
        this.change();
        this.formsComponent.actionFormula(this.field, this.value.val);
        this.formsComponent.fireEvents("ValueChanged", this.field.events, { value: this.value.val, field: this.field });
        this.formsComponent.checkForm(this.field.campoId, this.innerForm.invalid);
      }
      this.microphoneActive = !this.microphoneActive;
    }
  }
  onFocus(){
    let numerictextbox = kendo.jQuery(`#${this.field.campoId}`).data("kendoNumericTextBox");
    numerictextbox.focus();
  }
}
