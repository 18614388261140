import { Injectable } from '@angular/core';
import PouchDB from 'pouchdb';
// import cordovaSqlitePlugin from 'pouchdb-adapter-cordova-sqlite';

@Injectable({
  providedIn: 'root'
})
export class LocalDBManagerService {

  dbData: any;

  constructor() { }

  getData(entityName: any, doc: any) {
    this.dbData = new PouchDB(`bd_${entityName}`);
    let promise = this.dbData.get(doc).then((document: any) => {
      return document;
    }).catch((error: any) => {
      console.log(error);
    });

    return promise;
  }

  async getAllData(entityName: string, pageConfig: any, cascadeOffline: any, includeEntities: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      this.dbData = new PouchDB(`bd_${entityName}`);
      var nameEntity = entityName;
      var field = '', value = '', operator = '';
      if (cascadeOffline) {
        var cascadeoffline = cascadeOffline.filters ? cascadeOffline.filters : cascadeOffline;
        field = cascadeoffline ? cascadeoffline.field : '',
          value = cascadeoffline ? cascadeoffline.value : ''
        operator = cascadeoffline ? cascadeoffline.operator : '';
      }
  
      try {
        var result = await this.dbData.allDocs(pageConfig);
  
        var rows = result.rows.filter((row: any) => {
          if (row.doc.entityName === nameEntity) {
            if (!cascadeoffline) {
              return row.doc;
            } else {
              if (operator.toLowerCase() == 'contains'){
                if(row.doc[field])
                  if(value.includes(row.doc[field].toLowerCase())) return row.doc
              }
              else if (row.doc[field] == value) return row.doc;
            }
          }
        });
  
        if (includeEntities) {
          // includeEntities = this.parseAndFormatEntities(includeEntities);
          includeEntities = JSON.parse(includeEntities.split(',').map(x => x.split(' ').map(y => y.includes('modelo.') ? `"${y}"` : y).join(' ')).join(','))
          const entitiesOffline = JSON.parse(localStorage.getItem('EntityNames'));
          const currentEntity = entitiesOffline.find(entity => entity.Alias == entityName);
  
          const promises = rows.map(async (currentRow: any) => {
            for (let entity of includeEntities) {
              const currentRelation = currentEntity.NodeEntities.find(currentChild => currentChild.Alias == entity.as)
              if (currentRelation) {
                if(currentRow.doc[entity.as]) return currentRow;
                const filter = { field: 'Id', operator: 'eq', value: '' };
                filter.value = currentRow.doc[currentRelation.CodeFieldForeign];
                const valueEntityExtend = [await this.getAllData(entity.as, pageConfig, filter, null)]
                currentRow.doc[entity.as] = valueEntityExtend[0].rows[0];
              }
            }
            return currentRow;
          });
  
          // Esperar a que todas las promesas internas se resuelvan antes de resolver la promesa principal
          await Promise.all(promises);
        }
  
        resolve({
          total: result.total_rows,
          rows: rows.map((row: any) => {
            return row.doc;
          })
        });
      } catch (error) {
        reject(error);
      }
    });
  }
  

  getAllDetailsData(objEntityFk: any, pageConfig: any) {
    var fkRow = objEntityFk.fkCampo.split('.')[0],
      fkColumn = objEntityFk.fkCampo.split('.')[1],
      parentRow = objEntityFk.parentRow,
      idFkRow = objEntityFk.valueFkId;


    this.dbData = new PouchDB(`bd_${fkRow}`);

    var promise = this.dbData
      .allDocs(pageConfig)
      .then(function (result: any) {
        var rows = result.rows.filter((row: any) => {
          if (row.doc.entityName === fkRow && row.doc[fkColumn] === idFkRow) {
            return row.doc;
          }
        });

        return {
          total: result.total_rows,
          rows: rows.map((row: any) => {
            return row.doc;
          })
        };
      }).catch((error: any) => console.log(error));

    return promise;
  }

  getAllDocuments() {
    var promise = this.dbData.allDocs({
      include_docs: true,
      attachments: true
    }).then(function (result: any) {
      return result;
    }).catch((error: any) => console.log(error));

    return promise;
  }

  insertData(entityName: any, entityRow: any) {

    this.dbData = new PouchDB(`bd_${entityName}`);
    var promise = this.dbData.put(entityRow);

    return promise;
  }

  insertDetailData(entityName: any, entityRow: any) {

    this.dbData = new PouchDB(`bd_${entityName}`);
    var promise = this.dbData.put(entityRow);

    return promise;
  }

  insertAllDocuments(data: any[]) {
    var promise = this.dbData.bulkDocs(data);

    return promise;
  }

  updateData(entityName: any, id: any, entityRow: any) {

    this.dbData = new PouchDB(`bd_${entityName}`);
    var promise = this.dbData.get(id).then((currentDocument: any) => {
      entityRow._rev = currentDocument._rev;
      if (currentDocument.syncDate == null)
        delete entityRow.modifiedDate;
      entityRow.syncDate = null;
      return this.dbData.put(entityRow);
    }).catch((error: any) => console.log(error));

    return promise;
  }

  updateDetailData(entityName: any, id: any, entityRow: any) {

    this.dbData = new PouchDB(`bd_${entityName}`);
    var promise = this.dbData.get(id).then((document: any) => {
      entityRow._rev = document._rev;
      entityRow.createdDate = document.createdDate ? document.createdDate : null;
      if (document.syncDate == null) {
        delete entityRow.modifiedDate;
        entityRow.urlTransaction = document.urlTransaction;
        entityRow.txTransaction = document.txTransaction;
      }

      // $.extend(true, document, entityRow);
      return this.dbData.put(document);
    }).catch((error: any) => console.log(error));

    return promise;
  }

  deleteData(entityName: any, id: any, entityRow: any) {

    this.dbData = new PouchDB(`bd_${entityName}`);
    var promise = this.dbData.get(id).then((document: any) => {
      entityRow._rev = document._rev;
      entityRow.createdDate = document.createdDate ? document.createdDate : null;
      entityRow.modifiedDate = document.modifiedDate ? document.modifiedDate : null;

      return this.dbData.put(entityRow);
    }).then((response: any) => {
      var promise2 = this.dbData.get(id).then((doc: any) => {
        return this.dbData.remove(doc);
      });

      return promise2;
    }).catch((error: any) => console.log(error));

    return promise;
  }

  deleteDetailData(entityName: any, id: any, entityRow: any) {

    this.dbData = new PouchDB(`bd_${entityName}`);
    var promise = this.dbData.get(id).then((document: any) => {
      entityRow._rev = document._rev;
      entityRow.createdDate = document.createdDate ? document.createdDate : null;
      entityRow.modifiedDate = document.modifiedDate ? document.modifiedDate : null;

      return this.dbData.put(entityRow);
    }).then((response: any) => {
      var promise2 = this.dbData.get(id).then((doc: any) => {
        return this.dbData.remove(doc);
      });

      return promise2;
    }).catch((error: any) => console.log(error));

    return promise;
  }

  async destroyPouchDb(DbName: string) {
    let db = new PouchDB('bd_' + DbName);
    try {
      await db.destroy();
    } catch (err) {
      console.log(err);
    }
  }

  async removeSyncInformation(){
    const appContextDB = new PouchDB('bd_AppContext');
    const documentSincronozation = await appContextDB.get('synchronization_information');
    await appContextDB.remove(documentSincronozation);
  }

}
