import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { FormsComponent } from '@components/forms/forms.component';

@Component({
  selector: 'app-single-select',
  templateUrl: './single-select.component.html',
  styleUrls: ['./single-select.component.scss']
})
export class SingleSelectComponent implements OnInit, OnChanges {

  @Input() field: any;
  @Input() value: any;
  @Input() form: any;
  @Input() inputClass: string;
  @Input() errors: string;

  public options: any;
  innerForm: UntypedFormGroup;

  constructor(
    private router: Router,
    private formsComponent: FormsComponent
  ) {
    this.innerForm = new UntypedFormGroup({
      field: new UntypedFormControl(),
    });
  }

  async ngOnInit(): Promise<void> {
    if (this.field.required == true) {
      this.innerForm = new UntypedFormGroup({
        field: new UntypedFormControl(this.field.value.val, [
          Validators.required,
        ])
      });
    }

    this.formsComponent.checkForm(this.field.campoId, this.innerForm.invalid);
    this.formsComponent.applyUIRules(this.field, this.value);
    this.options = this.field.options;

    if (this.value.val) {
      await this.options.forEach(option => {
        if (option.value == this.value.val) {
          this.value.val = option.value;
        }
      });
    } else {
      this.value.val = undefined;
    }
  }

  inlineEditing() {
    this.router.navigate(['/grids'], { queryParams: { viewName: this.field.relatedViewName } });
  }

  changed(e: any) {
    this.field.value.val = e == "undefined" || e == undefined ? null : e;
    this.formsComponent.setValueFieldForm(this.field.campoId, this.field);
    this.formsComponent.applyUIRules(this.field, this.value);
    this.formsComponent.actionFormula(this.field, this.value.val);
    this.formsComponent.fireEvents('ValueChanged', this.field.events, {
      value: this.value.val,
      field: this.field
    });
    if (!this.value.val) {
      this.value.val = null;
    }
    if (this.field.required == true) {
      this.formsComponent.checkForm(this.field.campoId, this.value.val != null ? false : true);
    } else {
      this.formsComponent.checkForm(this.field.campoId, false);
    }
  }

  ngOnChanges(changes: SimpleChanges) { }

}
