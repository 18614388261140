<form [formGroup]="innerForm">
  <div>
    <textarea formControlName="field" [id]="Id" [required]="field.required" [name]="field.Code"
      [attr.hidden]="!field.show ? true : null"
      [attr.disabled]="!field.IsEditable ? true : null || field.disabled ? true : null" [maxlength]="field.maximumValue"
      [(ngModel)]="value.val" (change)="change()" (ngModelChange)="change()">
        </textarea>
    <div *ngIf="formSubmitted || innerForm.controls.field.errors?.required && innerForm.controls.field.touched"
      class="ft-appengine-required-input" id="minlength_{{field.campoId}}" role="alert">
      {{errors.required}}
    </div>
    <div class="ft-appengine-required-input" id="minlength_{{field.campoId}}" role="alert"
      *ngIf="formSubmitted || innerForm.controls.field.errors?.minlength && innerForm.controls.field.touched">
      {{errors.minimumValue}}</div>
    <div class="ft-appengine-required-input" id="maxlength_{{field.campoId}}" role="alert"
      *ngIf="formSubmitted || innerForm.controls.field.errors?.maxlength && innerForm.controls.field.touched">
      {{errors.maximumValue}}</div>
  </div>
</form>
