import { Injectable } from '@angular/core';
import backandGlobal from '@env/env';
import { RootScopeService } from '@shared/root-scope.service';
import { ToastrService } from 'ngx-toastr';
import { io } from 'socket.io-client';
import { Constants } from '@common/constants';
import Swal, { SweetAlertIcon, SweetAlertOptions } from 'sweetalert2';
@Injectable({
  providedIn: 'root',
})
export class NotificationsService {

  constructor(private $rs: RootScopeService, private toastr: ToastrService) { }

  configureSocket(appName: string, callback: any): any {
    var ioSocket = io(backandGlobal.urlNotifications, {
      query: { app: appName },
    });

    ioSocket.on('notification', callback);
    console.log(`socket de notificaciones para ${appName} configurado correctamente.`);
    return ioSocket;
  }

  createSocket(callback: any): any {
    return this.configureSocket(backandGlobal.currentApp.name, callback);
  }

  createSocketDesigner(): any {
    var lastDate: any;
    return this.configureSocket('LappizDesigner', (newNotification: any) => {
      if (newNotification.ApplicationId != sessionStorage.workspaceId) return;
      if (lastDate == newNotification.Date) return;
      lastDate = newNotification.Date;
      setTimeout(() => {
        sessionStorage.versionCache = null;
        // TODO: Incluir mensaje multilenguaje cuando este el multilenguaje
        var message = 'Hay una nueva versión de la aplicación disponible, recarga la página para que accedas a ella.';
        this.notificationApp('info', message);
      }, 3000);
    });
  }

  notificationApp(type: string, text: string, title?: string, otherOption?: unknown) {
    if (sessionStorage.typeAlert == 'Sweet') {
      let options = Constants.SWALOPTIONS;
      options.text = text;
      options.title = title || null;
      options.icon = type
      if(sessionStorage.getItem('configSwal'))
        otherOption = JSON.parse(sessionStorage.getItem('configSwal'));
      if (typeof otherOption == "object") {
        Object.assign(options, otherOption);
      }
      Swal.fire(options);
    } else {
      this.toastr[type](text);
    }
  }

  notificationAppWithButton(icon: SweetAlertIcon, text: string, otherOption?: unknown) {
    let options: SweetAlertOptions = {
        position: 'top',
        icon: icon,
        text,
        showCancelButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        confirmButtonText: 'Ok',
        showConfirmButton: true
    };
    if(sessionStorage.getItem('configSwalButton'))
      otherOption = JSON.parse(sessionStorage.getItem('configSwalButton'));
    if (typeof otherOption === "object") {
      Object.assign(options, otherOption);
    }
    Swal.fire(options);
  }

  async confirmAlert(icon: SweetAlertIcon, text: string, otherOption?: unknown):Promise<boolean> {
    let options: SweetAlertOptions = {
      position: 'top',
      text: text,
      icon: icon,
      showCancelButton: true,
      allowOutsideClick: false,
      allowEscapeKey: false,
      confirmButtonText: 'Si',
      cancelButtonText: 'No'
    };
    if(sessionStorage.getItem('configSwalConfirm'))
      otherOption = JSON.parse(sessionStorage.getItem('configSwalConfirm'));
    if (typeof otherOption === "object") {
      Object.assign(options, otherOption);
    }
    try{
      let result = await Swal.fire(options);
      if (result.dismiss === Swal.DismissReason.cancel) {
        return false;
      }
      return true;
    }catch(error){
      return false;      
    }
  }
}
