import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import PouchDB from 'pouchdb';

import { UploadService } from '@services/upload.service';

import backandGlobal from '@env/env';

@Injectable({
  providedIn: 'root'
})
export class AttachmentsService {

  dbAttachments: any;

  constructor(
    private http: HttpClient,
    private uploadService: UploadService
  ) {
    this.dbAttachments = new PouchDB('bd_AllAttachments');
  }

  resetDBAttachments() {
    this.dbAttachments.destroy().then(() => {
      this.dbAttachments = new PouchDB('bd_AllAttachments');
    });
  }

  getAttachment(attachmentId: any): Promise<any[]> {
    var promise = this.dbAttachments.get(attachmentId, {
      attachments: true
    }).then((attachment: any) => {
      return attachment;
    });

    return promise;
  }

  getPhotoAttachment(attachmentId: any, attachmentName: any): Promise<any[]> {
    var promise = this.dbAttachments.getAttachment(attachmentId, attachmentName).then((dataAttachment: any) => {
      return dataAttachment;
    });

    return promise;
  }

  insertAttachment(attachment: any): Promise<any[]> {
    var promise = this.dbAttachments.put(attachment);

    return promise;
  }

  deleteAttachment(attachmentId: any): Promise<any[]> {
    var promise = this.dbAttachments.get(attachmentId).then((doc: any) => {
      return this.dbAttachments.remove(doc);
    }).catch((error: any) => {
      console.log(`Ha ocurrido el siguiente error al intentar borrar el attachment --> ${error}`);
    });

    return promise;
  }

  getFileAttachments(attachmentId: any, rowId: any): Promise<any[]> {
    var promise = this.dbAttachments.get(attachmentId, {
      include_docs: true,
      attachments: true
    }).then((docFile: any) => {
      var filesAttached = [];
      var attachments = Object.keys(docFile.extraAttachments);
      for (var i = 0; i < attachments.length; i++) {
        var attachment = attachments[i];
        if (docFile.extraAttachments[attachment].rowId == rowId) {
          filesAttached.push(docFile.extraAttachments[attachment]);
        }
      }

      docFile.filesAttachments = filesAttached;

      return docFile;
    }).catch((error: any) => {
      console.log(`Ha ocurrido un error al traer los attachments --> ${error}`);
    });

    return promise;
  }

  insertFileAttachment(attachmentId: any, attachment: any): Promise<any[]> {
    var promise = this.dbAttachments.get(attachmentId).then((doc: any) => {
      attachment._rev = doc._rev;

      $.extend(true, doc, attachment);
      return this.dbAttachments.put(doc);
    }).catch((error: any) => {
      if (error.name == 'not_found') {
        // Es porque no existe el documento y toca insertar
        var promiseInsert = this.dbAttachments.put(attachment);

        return promiseInsert;
      }
    });

    return promise;
  }

  deleteFileAttachment(docId: any, idAttachment: any, nameAttachment: any, extensionAttachment: any): Promise<any[]> {
    var promise = this.dbAttachments.get(docId, {
      include_docs: true,
      attachments: true
    }).then((docFile: any) => {
      var idFileAttachment = `${idAttachment}.${extensionAttachment}`;
      delete docFile.extraAttachments[`${idAttachment}/${nameAttachment}`];
      return this.dbAttachments.put(docFile).then((newDoc: any) => {
        var revDoc = newDoc.rev;
        this.dbAttachments.removeAttachment(docId, idFileAttachment, revDoc);
      }).catch((error: any) => {
        console.log(`Ha ocurrido un error actualizando el documento`);
      });
    });

    return promise;
  }

  syncAttachments(): Promise<boolean> {
    return new Promise((resolve) => {
      this.dbAttachments.allDocs({
        include_docs: true,
        attachments: true
      }).then((result: any) => {
        for (var i = 0; i < result.rows.length; i++) {
          var doc = result.rows[i].doc;
          if (doc.typeAttachment == 'photo') {
            // Acá debo poner la lógica para enviar la información de las fotos
            var arrPhoto = [];
            var attachment = Object.keys(doc.extraAttachments)[0];

            var base64Photo = doc.extraAttachments[attachment].base64Photo;
            var blobPhoto = this.dataURItoBlob(base64Photo);
            var idPhoto = doc.extraAttachments[attachment].idPhoto;
            var namePhoto = doc.extraAttachments[attachment].namePhoto;
            var typePhoto = doc.extraAttachments[attachment].typePhoto;
            var extensionPhoto = doc.extraAttachments[attachment].extensionPhoto;

            arrPhoto.push(blobPhoto);
            arrPhoto[0].Id = idPhoto;
            arrPhoto[0].name = namePhoto;
            arrPhoto[0].typePhoto = typePhoto;
            arrPhoto[0].extensionPhoto = extensionPhoto;
            arrPhoto[0].lastModified = arrPhoto[0].size;
            arrPhoto[0].lastModifiedDate = "";
            arrPhoto[0].webkitRelativePath = "";

            this.uploadService.uploadOffline(arrPhoto).then(() => {
              console.log(`Foto sincronizada correctamente`);
            }).catch((error: any) => {
              console.log(`Ha ocurrido un error subiendo la foto offline --> ${JSON.stringify(error)}`);
            });
          } else if (doc.typeAttachment == 'file') {
            // Acá debo poner la lógica para realizar el insert a Lappiz_AllDocuments mediante query y no por la tx
            var attachments = Object.keys(doc.extraAttachments);
            for (var i = 0; i < attachments.length; i++) {
              var attachment = attachments[i];
              var attachmentData = doc.extraAttachments[attachment];
              var idAttachment = attachmentData.idFile, extAttachment = attachmentData.extensionFile,
                nameAttachment = attachmentData.nameFile, typeAttachment = attachmentData.typeFile,
                sizeAttachment = attachmentData.sizeFile, encodingAttachment = '7bit';
              var base64Attachment = doc._attachments[`${idAttachment}.${extAttachment}`].data;

              var queryInsertAttachment = `INSERT INTO Lappiz_AllDocuments ([Id], [Name], [Encoding], [MimeType], [Size], [Buffer]) VALUES ('${idAttachment}','${nameAttachment}','${encodingAttachment}','${typeAttachment}',${sizeAttachment},'${base64Attachment}')`;

              var tenantId = sessionStorage.tenantId;
              const httpOptions = {
                headers: new HttpHeaders({
                  'Content-Type': 'application/json',
                  Authorization: localStorage.getItem('Authorization')
                })
              };

              var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/lappiz/sp/query`;

              var opt = {
                query: queryInsertAttachment,
                tenantId,
                parameters: {
                  aType: "execTx",
                  environment: backandGlobal.environment
                }
              }

              this.http.post(url, opt, httpOptions).toPromise();
            }
          }
        }

        resolve(true);
      }).catch((error: any) => {
        console.log(`Ha ocurrido un error obteniendo los attachments de la aplicación`);
        resolve(false);
      });
    });
  }

  dataURItoBlob(dataURI: any) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    var bb = new Blob([ab], { type: mimeString });

    return bb;
  }

}
