import { DatePipe, Location } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, NavigationExtras, Router } from '@angular/router';
import Swal from 'sweetalert2';
import { Constants } from '@common/constants';

import { ApplicationService } from '@services/application.service';
import { ServerConfigApiService } from '@services/server-config-api.service';
import { ContextValuesService } from '@services/context-values.service';
import { OdataFilterMapService } from '@services/odata-filter-map.service';
import { ProxyTxApiService } from '@services/proxy-tx-api.service';
import { UserService } from '@services/user.service';

import backandGlobal from '@env/env';
import { NotificationsService } from '@services/notifications.service';

declare var kendo: any;

@Component({
  selector: 'app-scheduler',
  templateUrl: './scheduler.component.html',
  styleUrls: ['./scheduler.component.scss'],
})
export class SchedulerComponent implements OnInit {
  @Input() viewName: any;
  @Input() viewCode: any;
  @Input() options: any;
  @Input() filterOptions: any;
  @Input() inputStyle: any;
  @Input() buttonGroups: any;
  @Input() disableEditOnDblClick: any;

  viewNameId: any;
  viewEntityName: string;
  entityId: any;
  url: any;
  load: boolean;

  customMessages: any;
  alerts = [];

  fieldToList: any;

  configTable: any;
  mainListOptions: any;
  gridColumns: any;

  editableFields: boolean;
  isAnonymous: boolean;

  actionsMenuOptions: boolean;
  globalActions = [];

  viewsToOpen: any;
  nameBack: string;
  nameEntity: string;
  showAdd: boolean;
  showDelete: boolean;
  showEdit: boolean;
  showViewItem: boolean;
  showCancel: boolean;
  showTabsMenu: boolean;
  showToolbar: boolean;
  showSearch: boolean;
  showCreate: boolean;
  logicalDelete: boolean;
  collapseFilter: any;
  dataSort: any;
  odataFilterOptions: any;
  filterGridOptions: any;

  dataItemLogicalEntity: any;
  value: any;

  fieldToScheduler: any;
  navigationSuscription: any;
  sortGrid: any;
  templateRows: any;
  dataSource: any;
  schema: any;
  dataScheduler: any;
  sampleDataNextID: any;

  constructor(
    public datePipe: DatePipe,
    private locationApp: Location,
    private route: ActivatedRoute,
    private router: Router,
    private applicationService: ApplicationService,
    private configService: ServerConfigApiService,
    private contextValuesService: ContextValuesService,
    private odataFilterMap: OdataFilterMapService,
    private proxyTxApiService: ProxyTxApiService,
    private userService: UserService,
    private notificationsService: NotificationsService
  ) {
    kendo.culture('es-CO');

    this.customMessages = Constants.MESSAGES;
    this.initializeVariables();

    this.navigationSuscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        if ($('#schedulerView').hasClass('k-scheduler')) {
          var list = kendo.jQuery('#schedulerView').data('kendoScheduler');
          if (list) {
            list.destroy();
          }

          $('#schedulerView').empty();
        }
      }
    });
  }

  initializeVariables() {
    this.editableFields = false;
    this.isAnonymous = !localStorage.getItem('Authorization') ? true : false;

    this.fieldToScheduler = {
      title: { field: [], fieldName: [] },
      start: [],
      end: [],
      description: { field: [], fieldName: [] },
    };
    this.fieldToList = { nameField: [], value: [], filter: [], type: [] };
    this.viewsToOpen = {};

    this.dataItemLogicalEntity = {};
  }

  ngOnChanges(): void {
    this.viewName = this.route.snapshot.queryParams.viewName;
    this.viewNameId = this.viewName;
    this.entityId = this.route.snapshot.queryParams.entityId;

    this.buildList(this.viewNameId, this.entityId);
  }

  ngOnDestroy() {
    if (this.navigationSuscription) {
      this.navigationSuscription.unsubscribe();
    }
  }

  ngOnInit(): void {
    this.initializeVariables();
    this.viewName = this.route.snapshot.queryParams.viewName;
    this.viewNameId = this.viewName;
    this.entityId = this.route.snapshot.queryParams.entityId;

    kendo.ui.progress($('#schedulerView'), true);
    var baseUrl =
      'https://kendo.cdn.telerik.com/2022.2.510/js/messages/kendo.messages.';
    $.getScript(`${baseUrl}es-CO.min.js`, () => {
      kendo.ui.progress($('#schedulerView'), false);
    });
    this.buildList(this.viewNameId, this.entityId);
  }

  // #region Metodos
  /**
   * Método encargado de navegar a la vista que fue abierta antes de la lista
   */
  cancelar() {
    if (!localStorage.getItem('Authorization')) {
      this.router.navigate([
        `auth/login/${sessionStorage.getItem('workspace')}`,
      ]);
    } else {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.locationApp.back();
      });
    }
  }

  /**
   * Método encargado de refrescar/actualizar data de la grid
   */
  refresh() {
    kendo.jQuery('#schedulerView').data('kendoScheduler').dataSource.read();
    kendo.jQuery('#schedulerView').data('kendoScheduler').refresh();

    this.setActionsList();
  }

  /**
   * Método encargado de bindear las acciones a la grid
   */
  setActionsList() {
    setTimeout(() => {
      var actionsClick = kendo.observable({
        showDetails: (e: any) => {
          var id = e.currentTarget.id.replace('rowS_', '');
          e.dataItem = kendo
            .jQuery('#schedulerView')
            .data('kendoScheduler')
            .dataSource.data()
            .find((x) => x['id'] == id);
          let templete = $(`#fs_${id}`).html();
          templete = templete.replace('edit_', 'edit_popover_');
          templete = templete.replace('delete_', 'delete_popover_');
          templete = templete.replace('deleteL_', 'deleteL_popover_');
          templete = templete.replace(
            'actionsCustomPopover',
            'actionsCustomPopover_Pp'
          );
          let array = $(`#actionsCustomPopover_${id}`).children();
          for (let index = 1; index <= array.length; index++) {
            templete = templete.replace(
              `${index}_actionCustom_`,
              `${index}_actionCustom_popover_`
            );
          }
          const popupContentTemplate = function () {
            return $('<div>').append(
              $(`<p>${(e.dataItem.description ??= '')}</p>`),
              $(templete)
            );
          };

          const popup = $('#popup')
            .dxPopup({
              contentTemplate: popupContentTemplate,
              width: 300,
              height: 280,
              showTitle: true,
              title: e.dataItem.title,
              visible: true,
              dragEnabled: true,
              closeOnOutsideClick: true,
              showCloseButton: true,
              position: 'center',
            })
            .dxPopup('instance');

          popup.option({
            contentTemplate: () => popupContentTemplate(),
            'position.of': `#${e.currentTarget.id}`,
          });
          popup.show();
          setTimeout(() => {
            for (let index = 1; index <= array.length; index++) {
              $(`#${index}_actionCustom_popover_${id}`).click(() => {
                $(`#${index}_actionCustom_${id}`).click();
              });
            }
          }, 1000);
          $(`#edit_popover_${id}`).click(() => {
            $(`#edit_${id}`).click();
            popup.hide();
          });
          $(`#delete_popover_${id}`).click(() => {
            $(`#delete_${id}`).click();
            popup.hide();
          });
          $(`#deleteL_popover_${id}`).click(() => {
            $(`#deleteL_${id}`).click();
            popup.hide();
          });
          $('.dx-popup-content').css({
            'background-color': ' #fff !important',
          });
          $('.dx-toolbar').css({
            'background-color': ' #fff !important',
          });
        },
        editClick: (e: any) => {
          var id = e.currentTarget.id.split('_')[1];
          e.dataItem = kendo
            .jQuery('#schedulerView')
            .data('kendoScheduler')
            .dataSource.data()
            .find((x) => x['id'] == id);
          this.editSelected(e);
        },
        deleteClick: (e: any) => {
          var id = e.currentTarget.id.split('_')[1];
          e.dataItem = kendo
            .jQuery('#schedulerView')
            .data('kendoScheduler')
            .dataSource.data()
            .find((x) => x['id'] == id);
          this.deleteSelected(e);
          kendo.jQuery('#schedulerView').data('kendoScheduler').refresh();
        },
        logicalDeleteClick: (e: any) => {
          var id = e.currentTarget.id.split('_')[1];
          e.dataItem = kendo
            .jQuery('#schedulerView')
            .data('kendoScheduler')
            .dataSource.data()
            .find((x) => x['id'] == id);
          e.dataItem.Id = e.dataItem.id;
          this.logicalDeleteSelected(e);
        },
        viewItemClick: (e: any) => {
          var id = e.currentTarget.id;
          e.dataItem = kendo
            .jQuery('#schedulerView')
            .data('kendoScheduler')
            .dataSource.data()
            .find((x) => x['id'] == id);
          e.dataItem.Id = e.dataItem.id;
          this.viewSelected(e);
        },
        navigationClick: (e: any) => {
          var id = e.currentTarget.id;
          var queryString = $(e.currentTarget).data().querystring;
          e.dataItem = kendo
            .jQuery('#schedulerView')
            .data('kendoScheduler')
            .dataSource.data()
            .find((x) => x['id'] == id);
          e.dataItem.Id = e.dataItem.id;
          this.openDirective(queryString, e);
        },
        actionCodeClick: (e: any) => {
          var id = e.currentTarget.id.split('_')[2];
          var actionId = $(e.currentTarget).data().actionid;
          e.dataItem = kendo
            .jQuery('#schedulerView')
            .data('kendoScheduler')
            .dataSource.data()
            .find((x) => x['id'] == id);
          e.dataItem.Id = e.dataItem.id;
          this.actionCode(actionId, e);
        },
      });
      kendo.bind($('#schedulerView'), actionsClick);
    }, 5500);
  }

  /**
   * Método encargado de setear estilos a la grid
   */
  setListClass() {
    $('.k-scheduler-toolbar').css({
      'background-color': '#ffffff00',
    });
    $('.k-event:hover').css({
      height: 'auto !important',
    });

    $('.rowScheduler h3').css({
      'font-size': 'inherit',
      'white-space': 'nowrap',
      overflow: 'hidden',
      'text-overflow': 'ellipsis',
    });
  }

  globalActionCode(action: any) {
    // TODO: Implementar metodo de globalActionCode
  }
  //#endregion

  // Configurar List view
  async buildList(viewName: any, entityId: any) {
    this.entityId = entityId;
    this.viewEntityName = viewName;

    var appViewId = this.route.snapshot.queryParams.appViewId;
    var paramsViewName: any = {
      dataType: 'list',
      id: viewName,
      entityId,
    };

    if (appViewId != '' && appViewId !== null && appViewId != undefined) {
      paramsViewName.appViewId = appViewId;
    }

    await this.configService
      .read(paramsViewName, async (data: any) => {
        var dataEditing;
        this.configTable = data;

        //Validar que la vista tenga permisos o sea anonima
        for (var i = 0; i < this.configTable.dataEditing.length; i++) {
          if (sessionStorage.getItem('rolesId')) {
            if (sessionStorage.getItem('rolesId').includes(',')) {
              let stringRoles = sessionStorage.getItem('rolesId').split(',');
              stringRoles.forEach((rol) => {
                if (this.configTable.dataEditing[i].rolesId.includes(rol)) {
                  dataEditing = this.configTable.dataEditing[i];
                }
              });
            } else if (
              this.configTable.dataEditing[i].rolesId.includes(
                sessionStorage.getItem('rolesId')
              )
            ) {
              dataEditing = this.configTable.dataEditing[i];
            }
          } else if (this.isAnonymous == true) {
            dataEditing = this.configTable.dataEditing[i];
            // TODO: Poner servicio compartido para cambiar los temas de la aplicación
          }
        }

        this.collapseFilter = this.configTable.DisplayMenuTab;
        this.nameEntity = data.captionText;
        this.logicalDelete = this.configTable
          ? this.configTable.LogicalDelete
          : false;
        this.showAdd =
          this.configTable && dataEditing ? dataEditing.allowAdd : true;
        this.showCancel = this.configTable
          ? this.configTable.ShowCancelButton
          : true;
        this.showCreate = this.configTable
          ? this.configTable.ShowCreateButton
          : true;
        this.showDelete =
          this.configTable && dataEditing ? dataEditing.allowDelete : true;
        this.showEdit =
          this.configTable && dataEditing ? dataEditing.allowEdit : true;
        this.showSearch =
          this.configTable && this.configTable.design
            ? !this.configTable.design.hideSearchBox
            : true;
        this.showTabsMenu = this.configTable.DisplayMenutab;
        this.showToolbar =
          this.configTable && this.configTable.toolbarSettings
            ? !this.configTable.toolbarSettings.hideToolbar
            : true;
        this.showViewItem =
          this.configTable && dataEditing ? dataEditing.allowViewItem : false;
        this.viewsToOpen = {
          Create: data.ViewToOpenCreate,
          Edit: data.ViewToOpenEdit,
        };

        //Cambiar en nombre de la vista
        if (location.hash.includes('nameBack')) {
          var parts = location.hash.split('&');
          parts.forEach((item: any) => {
            if (item.includes('nameBack')) {
              this.nameBack = (' - ' + decodeURI(item.split('=')[1])).replace(
                /_/g,
                ' '
              );
            }
          });
        }

        if (data.CustomMessages) {
          data.CustomMessages.forEach((message: any) => {
            this.customMessages[message.MessageType] = message.Message;
          });
        }

        //Agregar acciones globales
        this.configTable.Actions.forEach((action: any) => {
          if (sessionStorage.getItem('rolesId')) {
            if (sessionStorage.getItem('rolesId').includes(',')) {
              let stringRoles = sessionStorage.getItem('rolesId').split(',');
              stringRoles.forEach((rol) => {
                if (action.rolesId.includes(rol)) {
                  if (action.GlobalAction) {
                    this.globalActions.push(action);
                  }
                }
              });
            } else if (
              action.rolesId.includes(sessionStorage.getItem('rolesId'))
            ) {
              if (action.GlobalAction) {
                this.globalActions.push(action);
              }
            }
          } else if (this.isAnonymous == true) {
            if (action.GlobalAction) {
              this.globalActions.push(action);
            }
          }
        });
        const uniqueActions = this.globalActions.filter((action, index) => {
          const _action = JSON.stringify(action);
          return (
            index ===
            this.globalActions.findIndex((action) => {
              return JSON.stringify(action) === _action;
            })
          );
        });
        this.globalActions = uniqueActions;
        this.actionsMenuOptions =
          this.globalActions && this.globalActions.length > 0 ? true : false;

        if (
          data.hasOwnProperty('controlLsschedulerView') &&
          data.controlLsListView !== null
        ) {
          if (data.controlLsListView.TimeRefreshSelect) {
            var time = data.controlLsListView.TimeRefresh * 60000;
            setInterval(this.refresh, time);
          }
        }

        var gridColumns = [],
          permissions: any = [];
        this.gridColumns = [];

        this.configTable.fields.forEach((value: any) => {
          if (value.IsEditableInGrid) {
            this.editableFields = true;
          }
        });

        for (var i = 0; i < this.configTable.permissions.length; i++) {
          if (sessionStorage.getItem('rolesId')) {
            if (sessionStorage.getItem('rolesId').includes(',')) {
              let stringRoles = sessionStorage.getItem('rolesId').split(',');
              stringRoles.forEach((rol) => {
                if (this.configTable.permissions[i].rolesId.includes(rol)) {
                  permissions = this.configTable.permissions[i];
                }
              });
            } else if (
              this.configTable.permissions[i].rolesId.includes(
                sessionStorage.getItem('rolesId')
              )
            ) {
              permissions = this.configTable.permissions[i];
            }
          } else if (this.isAnonymous == true) {
            permissions = this.configTable.permissions[i];
          }
        }

        var fields = this.configTable.fields;
        if (fields.find((a: any) => a.Order == 0)) {
          this.fieldToScheduler.title.fieldName.push(
            fields.find((a: any) => a.Order == 0).Code
          );
          this.fieldToScheduler.title.field.push(
            fields.find((a: any) => a.Order == 0)
          );
        }

        if (fields.find((a: any) => a.Order == 1)) {
          this.fieldToScheduler.start.push(
            fields.find((a: any) => a.Order == 1).Code
          );
        }

        if (fields.find((a: any) => a.Order == 2)) {
          this.fieldToScheduler.end.push(
            fields.find((a: any) => a.Order == 2).Code
          );
        }

        if (fields.find((a: any) => a.Order == 3)) {
          this.fieldToScheduler.description.fieldName.push(
            fields.find((a: any) => a.Order == 3).Code
          );
          this.fieldToScheduler.description.field.push(
            fields.find((a: any) => a.Order == 3)
          );
        }

        this.schema = {
          model: {
            fields: {
              id: { field: 'Id' },
              title: {
                field: fields.find((a: any) => a.Order == 0)
                  ? fields.find((a: any) => a.Order == 0).Code
                  : '',
                defaultValue: 'No title',
                validation: { required: true },
              },
              start: {
                type: 'date',
                field: fields.find((a: any) => a.Order == 1)
                  ? fields.find((a: any) => a.Order == 1).Code
                  : '',
              },
              end: {
                type: 'date',
                field: fields.find((a: any) => a.Order == 2)
                  ? fields.find((a: any) => a.Order == 2).Code
                  : '',
              },
              description: {
                field: fields.find((a: any) => a.Order == 3)
                  ? fields.find((a: any) => a.Order == 3).Code
                  : '',
              },
            },
          }
        };

        var gridColumns = [];

        var url_grid: string = '';

        if (!this.configTable.filter) {
          // Get all expands...
          var expanded_entities = '';
          if (expanded_entities === '') {
            url_grid = `${backandGlobal.api2}/${sessionStorage.getItem(
              'workspace'
            )}.api/api/lappiz/get/${this.viewEntityName}`;

            if (url_grid.includes('$orderby=')) {
              var dataSort = url_grid.split('$orderby=')[1].split(' ');

              if (dataSort[0].split('.').length == 1) {
                dataSort[0] = this.viewEntityName + '.' + dataSort[0];
              }

              this.dataSort = dataSort;
            } else {
              this.dataSort = null;
            }

            // Removemos el filtro y lo dejamos en una variable para ser reutilizado luego en parameterMap
            this.odataFilterOptions =
              this.odataFilterMap.extractOptions(url_grid);
            var arrayAux = [];
            var arrayAnd = [];
            var arrayOr = [];
            var arrayNone = [];

            if (this.odataFilterOptions.filterQuery != undefined) {
              var filterArray = this.odataFilterOptions.filterQuery.split(' ');
              await filterArray.forEach((value: any) => {
                var aux = value;

                if (value.includes('*+*')) {
                  aux = value.split('*+*').join(' ');
                }

                aux = aux.replace("'", '').replace("'", '');
                arrayAux.push(aux);
              });

              for (let i = 0; i < arrayAux.length; i++) {
                if (arrayAux[i] == 'and') {
                  var bandera = i + 1;

                  for (let j = 0; j < 3; j++) {
                    arrayAnd[j] = arrayAux[bandera];
                    bandera++;
                  }
                } else if (arrayAux[i] == 'or') {
                  var bandera = i + 1;
                  for (let j = 0; j < 3; j++) {
                    arrayOr[j] = arrayAux[bandera];
                    bandera++;
                  }
                } else if (i == 0) {
                  var bandera = i;
                  for (let j = 0; j < 3; j++) {
                    arrayNone[j] = arrayAux[bandera];
                    bandera++;
                  }
                }
              }

              this.filterGridOptions = {
                logic: 'and',
                filters: [
                  {
                    logic: 'or',
                    filters: [],
                  },
                  {
                    logic: 'or',
                    filters: [],
                  },
                ],
              };

              if (arrayNone != undefined || arrayNone != null) {
                var nIteracion = arrayNone.length / 3;
                var inicio = 0;
                var fin = 3;

                for (let i = 0; i < nIteracion; i++) {
                  var part = arrayNone.slice(inicio, fin);

                  if (part[2] != 'null') {
                    this.filterGridOptions.filters[0].filters.push({
                      field: part[0],
                      operator: part[1],
                      value: part[2],
                    });
                  }

                  inicio = fin;
                  fin = fin + 3;
                }
              }

              if (arrayAnd != undefined || arrayAnd != null) {
                var nIteracion = arrayAnd.length / 3;
                var inicio = 0;
                var fin = 3;

                for (let i = 0; i < nIteracion; i++) {
                  var part = arrayAnd.slice(inicio, fin);

                  if (part[2] != 'null') {
                    this.filterGridOptions.filters.push({
                      field: part[0],
                      operator: part[1],
                      value: part[2],
                    });
                  }

                  inicio = fin;
                  fin = fin + 3;
                }
              }

              if (arrayOr != undefined || arrayOr != null) {
                var nIteracion = arrayOr.length / 3;
                var inicio = 0;
                var fin = 3;

                for (let i = 0; i < nIteracion; i++) {
                  var part = arrayOr.slice(inicio, fin);

                  if (part[2] != 'null') {
                    this.filterGridOptions.filters[0].filters.push({
                      field: part[0],
                      operator: part[1],
                      value: part[2],
                    });
                  }

                  inicio = fin;
                  fin = fin + 3;
                }
              }

              url_grid = this.odataFilterOptions.url;
            }
          } else {
            expanded_entities = expanded_entities.slice(0, -1);
            url_grid = `${backandGlobal.api2}/${sessionStorage.getItem(
              'workspace'
            )}.api/api/lappiz/get/${this.viewEntityName}`;

            if (url_grid.includes('$orderby=')) {
              var dataSort = url_grid.split('$orderby=')[1].split(' ');

              if (dataSort[0].split('.').length == 1) {
                dataSort[0] = this.viewEntityName + '.' + dataSort[0];
              }

              this.dataSort = dataSort;
            } else {
              this.dataSort = null;
            }

            // Removemos el filtro y lo dejamos en una variable para ser reutilizado luego en parameterMap
            this.odataFilterOptions =
              this.odataFilterMap.extractOptions(url_grid);
            var arrayAux = [];
            var arrayAnd = [];
            var arrayOr = [];
            var arrayNone = [];

            if (this.odataFilterOptions.filterQuery != undefined) {
              var filterArray = this.odataFilterOptions.filterQuery.split(' ');

              filterArray.forEach((value: any) => {
                var aux = value;

                if (value.includes('*+*')) {
                  aux = value.split('*+*').join(' ');
                }

                aux = aux.replace("'", '').replace("'", '');
                arrayAux.push(aux);
              });

              for (let i = 0; i < arrayAux.length; i++) {
                if (arrayAux[i] == 'and') {
                  var bandera = i + 1;

                  for (let j = 0; j < 3; j++) {
                    arrayAnd[j] = arrayAux[bandera];
                    bandera++;
                  }
                } else if (arrayAux[i] == 'or') {
                  var bandera = i + 1;

                  for (let j = 0; j < 3; j++) {
                    arrayOr[j] = arrayAux[bandera];
                    bandera++;
                  }
                } else if (i == 0) {
                  var bandera = i;

                  for (let j = 0; j < 3; j++) {
                    arrayNone[j] = arrayAux[bandera];
                    bandera++;
                  }
                }
              }

              this.filterGridOptions = {
                logic: 'and',
                filters: [
                  {
                    logic: 'or',
                    filters: [],
                  },
                  {
                    logic: 'or',
                    filters: [],
                  },
                ],
              };

              if (arrayNone != undefined || arrayNone != null) {
                var nIteracion = arrayNone.length / 3;
                var inicio = 0;
                var fin = 3;

                for (let i = 0; i < nIteracion; i++) {
                  var part = arrayNone.slice(inicio, fin);

                  if (part[2] != 'null') {
                    this.filterGridOptions.filters[0].filters.push({
                      field: part[0],
                      operator: part[1],
                      value: part[2],
                    });
                  }

                  inicio = fin;
                  fin = fin + 3;
                }
              }

              if (arrayAnd != undefined || arrayAnd != null) {
                var nIteracion = arrayAnd.length / 3;
                var inicio = 0;
                var fin = 3;

                for (let i = 0; i < nIteracion; i++) {
                  var part = arrayAnd.slice(inicio, fin);

                  if (part[2] != 'null') {
                    this.filterGridOptions.filters.push({
                      field: part[0],
                      operator: part[1],
                      value: part[2],
                    });
                  }

                  inicio = fin;
                  fin = fin + 3;
                }
              }

              if (arrayOr != undefined || arrayOr != null) {
                var nIteracion = arrayOr.length / 3;
                var inicio = 0;
                var fin = 3;

                for (let i = 0; i < nIteracion; i++) {
                  var part = arrayOr.slice(inicio, fin);

                  if (part[2] != 'null') {
                    this.filterGridOptions.filters[0].filters.push({
                      field: part[0],
                      operator: part[1],
                      value: part[2],
                    });
                  }

                  inicio = fin;
                  fin = fin + 3;
                }
              }

              url_grid = this.odataFilterOptions.url;
            }
          }
        } else {
          url_grid = this.contextValuesService.transformODataQuery(
            this.configTable.filter.Query
          );

          if (url_grid.includes('$orderby=')) {
            var dataSort = url_grid.split('$orderby=')[1].split(' ');

            if (dataSort[0].split('.').length == 1) {
              dataSort[0] = this.viewEntityName + '.' + dataSort[0];
            }

            this.dataSort = dataSort;
          } else {
            this.dataSort = null;
          }

          // Removemos el filtro y lo dejamos en una variable para ser reutilizado luego en parameterMap
          this.odataFilterOptions =
            this.odataFilterMap.extractOptions(url_grid);
          var arrayAux = [];
          var arrayAnd = [];
          var arrayOr = [];
          var arrayNone = [];

          if (this.odataFilterOptions.filterQuery != undefined) {
            var filterArray = this.odataFilterOptions.filterQuery.split(' ');

            filterArray.forEach((value: any) => {
              var aux = value;

              if (value.includes('*+*')) {
                aux = value.split('*+*').join(' ');
              }

              aux = aux.replace("'", '').replace("'", '');
              arrayAux.push(aux);
            });

            for (let i = 0; i < arrayAux.length; i++) {
              if (arrayAux[i] == 'and') {
                var bandera = i + 1;

                for (let j = 0; j < 3; j++) {
                  arrayAnd[j] = arrayAux[bandera];
                  bandera++;
                }
              } else if (arrayAux[i] == 'or') {
                var bandera = i + 1;

                for (let j = 0; j < 3; j++) {
                  arrayOr[j] = arrayAux[bandera];
                  bandera++;
                }
              } else if (i == 0) {
                var bandera = i;

                for (let j = 0; j < 3; j++) {
                  arrayNone[j] = arrayAux[bandera];
                  bandera++;
                }
              }
            }

            this.filterGridOptions = {
              logic: 'and',
              filters: [
                {
                  logic: 'or',
                  filters: [],
                },
                {
                  logic: 'or',
                  filters: [],
                },
              ],
            };

            if (arrayNone != undefined || arrayNone != null) {
              var nIteracion = arrayNone.length / 3;
              var inicio = 0;
              var fin = 3;

              for (let i = 0; i < nIteracion; i++) {
                var part = arrayNone.slice(inicio, fin);

                if (part[2] != 'null') {
                  this.filterGridOptions.filters[0].filters.push({
                    field: part[0],
                    operator: part[1],
                    value: part[2],
                  });
                }

                inicio = fin;
                fin = fin + 3;
              }
            }

            if (arrayAnd != undefined || arrayAnd != null) {
              var nIteracion = arrayAnd.length / 3;
              var inicio = 0;
              var fin = 3;

              for (let i = 0; i < nIteracion; i++) {
                var part = arrayAnd.slice(inicio, fin);

                if (part[2] != 'null') {
                  this.filterGridOptions.filters.push({
                    field: part[0],
                    operator: part[1],
                    value: part[2],
                  });
                }

                inicio = fin;
                fin = fin + 3;
              }
            }

            if (arrayOr != undefined || arrayOr != null) {
              var nIteracion = arrayOr.length / 3;
              var inicio = 0;
              var fin = 3;

              for (let i = 0; i < nIteracion; i++) {
                var part = arrayOr.slice(inicio, fin);

                if (part[2] != 'null') {
                  this.filterGridOptions.filters[0].filters.push({
                    field: part[0],
                    operator: part[1],
                    value: part[2],
                  });
                }

                inicio = fin;
                fin = fin + 3;
              }
            }

            url_grid = this.odataFilterOptions.url;
          }
        }

        function uncompressIncludeEntities(entities: any[]) {
          var arr = entities;
          let arr2 = [...arr];

          for (let i = 0; i < arr.length; i++) {
            for (let j = 0; j < arr.length; j++) {
              if (i != j) {
                var strOr = arr[i];
                var strDes = arr[j];

                if (strDes.startsWith(strOr)) {
                  delete arr2[i];
                  break;
                }
              }
            }
          }

          var filtered = arr2.filter((el: any) => {
            return el != null;
          });

          let unique = [...new Set(filtered)];
          return unique;
        }

        function getIncludes(entities: any[]) {
          var aEntities = uncompressIncludeEntities(entities);
          var stringInclude = '';
          var cierre = '}]';

          for (let j = 0; j < aEntities.length; j++) {
            if (j != 0) stringInclude += ', ';
            if (!aEntities[j].includes('.')) {
              var auxInclude = '';

              if (
                aEntities[j].includes(
                  `${sessionStorage.getItem('workspace')}_Users`
                )
              ) {
                if (
                  backandGlobal.environment == 'DEV' ||
                  backandGlobal.environment == 'TEST'
                ) {
                  auxInclude = aEntities[j];
                } else if (backandGlobal.environment == 'PROD') {
                  auxInclude = 'Lappiz_Users';
                }
              } else {
                auxInclude = aEntities[j];
              }

              stringInclude += `{"model": modelo.${auxInclude}, "as": "${auxInclude}"`;
            } else {
              var auxEntityArray = aEntities[j].split('.');

              for (let i = 0; i < auxEntityArray.length; i++) {
                var auxInclude = '';

                if (
                  auxEntityArray[i].includes(
                    `${sessionStorage.getItem('workspace')}_Users`
                  )
                ) {
                  if (
                    backandGlobal.environment == 'DEV' ||
                    backandGlobal.environment == 'TEST'
                  ) {
                    auxInclude = auxEntityArray[i];
                  } else if (backandGlobal.environment == 'PROD') {
                    auxInclude = 'Lappiz_Users';
                  }
                } else {
                  auxInclude = auxEntityArray[i];
                }

                if (i == 0) {
                  stringInclude += `{"model": modelo.${auxInclude}, "as": "${auxInclude}"`;
                } else if (i == auxEntityArray.length - 1) {
                  stringInclude += `, include: [{"model": modelo.${auxInclude}, "as": "${auxInclude}"`;
                  stringInclude += cierre.repeat(i);
                } else {
                  stringInclude += `, include: [{"model": modelo.${auxInclude}, "as": "${auxInclude}"`;
                }
              }
            }

            stringInclude += `}`;
          }

          return '[' + stringInclude + ']';
        }

        var gridFieldColumns = gridColumns;
        this.gridColumns = gridColumns;
        var fieldsColumns = [];
        var entityInclude = [];
        var stringEntity = '';
        fieldsColumns.push('Id');

        gridFieldColumns.forEach((value: any) => {
          if (!value.command) {
            if (value.field) {
              if (value.field.includes(this.viewEntityName)) {
                fieldsColumns.push(
                  value.field
                    .replace('[', '')
                    .replace(']', '')
                    .replace('[', '')
                    .replace(']', '')
                    .replace('.', '')
                    .replace(this.viewEntityName, '')
                );
              } else {
                if (value.field.includes('.')) {
                  value.field.split('Details').join('.');
                  var arrayField = value.field.substr(
                    0,
                    value.field.lastIndexOf('.')
                  );
                  entityInclude.push(arrayField);
                }

                fieldsColumns.push(value.field);
              }
            }
          }

          if (value.command && value.title) {
            fieldsColumns.push(value.title.split(' ')[0]);
          }
        });

        let uniqueField = [...new Set(entityInclude)];
        stringEntity = getIncludes(uniqueField);
        this.dataSource = new kendo.data.SchedulerDataSource({
          transport: {
            read: async (options: any) => {
              var optionsFilter = options;
              var filterGridOptions = this.filterGridOptions;

              if (arrayNone != undefined || arrayNone != null) {
                var nIteracion = arrayNone.length / 3;
                var inicio = 0;
                var fin = 3;
                var bandera = 0;

                for (let i = 0; i < nIteracion; i++) {
                  var part = arrayNone.slice(inicio, fin);

                  for (
                    let j = 0;
                    j < this.filterGridOptions.filters[0].filters.length;
                    j++
                  ) {
                    if (
                      this.filterGridOptions.filters[0].filters[j].field ==
                      part[0] &&
                      this.filterGridOptions.filters[0].filters[j].operator ==
                      part[1] &&
                      this.filterGridOptions.filters[0].filters[j].value ==
                      part[2]
                    ) {
                      bandera++;
                    }
                  }

                  if (bandera == 0) {
                    if (part[2] != 'null') {
                      this.filterGridOptions.filters[0].filters.push({
                        field: part,
                        operator: part[1],
                        value: part[2],
                      });
                    }

                    inicio = fin;
                    fin = fin + 3;
                  }

                  bandera = 0;
                }
              }

              if (arrayAnd != undefined || arrayAnd != null) {
                var nIteracion = arrayAnd.length / 3;
                var inicio = 0;
                var fin = 3;
                var bandera = 0;

                for (let i = 0; i < nIteracion; i++) {
                  var part = arrayAnd.slice(inicio, fin);

                  for (
                    let j = 0;
                    j < this.filterGridOptions.filters.length;
                    j++
                  ) {
                    if (
                      this.filterGridOptions.filters[j].field == part[0] &&
                      this.filterGridOptions.filters[j].operator == part[1] &&
                      this.filterGridOptions.filters[j].value == part[2]
                    ) {
                      bandera++;
                    }
                  }

                  if (bandera == 0) {
                    if (part[2] != 'null') {
                      this.filterGridOptions.filters.push({
                        field: part,
                        operator: part[1],
                        value: part[2],
                      });
                    }

                    inicio = fin;
                    fin = fin + 3;
                  }

                  bandera = 0;
                }
              }

              if (arrayOr != undefined || arrayOr != null) {
                var nIteracion = arrayOr.length / 3;
                var inicio = 0;
                var fin = 3;
                var bandera = 0;

                for (let i = 0; i < nIteracion; i++) {
                  var part = arrayOr.slice(inicio, fin);

                  for (
                    let j = 0;
                    j < this.filterGridOptions.filters[0].filters.length;
                    j++
                  ) {
                    if (
                      this.filterGridOptions.filters[0].filters[j].field ==
                      part[0] &&
                      this.filterGridOptions.filters[0].filters[j].operator ==
                      part[1] &&
                      this.filterGridOptions.filters[0].filters[j].value ==
                      part[2]
                    ) {
                      bandera++;
                    }
                  }

                  if (bandera == 0) {
                    if (part[2] != 'null') {
                      this.filterGridOptions.filters[0].filters.push({
                        field: part,
                        operator: part[1],
                        value: part[2],
                      });
                    }

                    inicio = fin;
                    fin = fin + 3;
                  }

                  bandera = 0;
                }
              }

              if (this.filterGridOptions != undefined) {
                if (optionsFilter.data.filter == undefined) {
                  optionsFilter.data.filter = filterGridOptions;
                } else {
                  if (this.filterGridOptions == undefined) {
                    return this.filterGridOptions;
                  } else {
                    this.filterGridOptions.filters[1].filters =
                      optionsFilter.data.filter.filters;
                    optionsFilter.data.filter = this.filterGridOptions;
                  }
                }
              }

              if (this.dataSort != null || this.dataSort != undefined) {
                optionsFilter.data.sort = [
                  { field: this.dataSort[0], dir: this.dataSort[1] },
                ];
                this.dataSort = null;
              }

              if (
                optionsFilter.data.sort != null ||
                optionsFilter.data.sort != undefined
              ) {
                if (
                  optionsFilter.data.sort[0] != undefined ||
                  optionsFilter.data.sort[0] != null
                ) {
                  if (optionsFilter.data.sort[0].field.split('.').length == 1) {
                    var field = optionsFilter.data.sort[0].field;
                    optionsFilter.data.sort[0].field =
                      this.viewEntityName + '.' + field;
                  }
                }
              }

              var urlTx = `${backandGlobal.api2}/${sessionStorage.getItem(
                'workspace'
              )}.api/api/lappiz/get/${this.viewEntityName}`;
              var entityNameOff = this.viewEntityName;
              sessionStorage.setItem('entityname', entityNameOff);
              optionsFilter.data.includeEntities = stringEntity;
              optionsFilter.data.tenantId = sessionStorage.getItem('tenantId');
              optionsFilter.data.parameters = {
                userId: `${sessionStorage.getItem('userId')}`,
                appViewId: `${this.route.snapshot.queryParams.appViewId}`,
                pType: 'showinmenu',
                aType: 'ffija',
                environment: `${backandGlobal.environment}`,
              };

              if (optionsFilter.data.sort) {
                this.sortGrid = optionsFilter.data.sort;
              }

              if (this.sortGrid && !optionsFilter.data.sort) {
                optionsFilter.data.sort = this.sortGrid;
              }
              await this.proxyTxApiService
                .getTxData(
                  `${urlTx}`,
                  JSON.stringify(optionsFilter.data),
                  backandGlobal.stateOnline,
                  entityNameOff
                )
                .then((response: any) => {
                  if (backandGlobal.stateOnline) {
                    if (response.data.count > 0) {
                      if (response.data.rows[0].Lappiz_Users) {
                        for (let j = 0; j < response.data.rows.length; j++) {
                          response.data.rows[j][
                            `${sessionStorage.getItem('workspace')}_Users`
                          ] = response.data.rows[j].Lappiz_Users;
                        }
                      }
                    }
                    this.dataScheduler = response.data.rows;
                    this.sampleDataNextID = this.dataScheduler.length + 1;
                    options.success(this.dataScheduler);
                  } else {
                    options.success(response);
                  }
                })
                .catch((error: any) => {
                  console.log('Error:', error);
                });
            },
          },
          error: function (e) {
            alert('Status: ' + e.status + '; Error message: ' + e.errorThrown);
          },
          batch: false,
          schema: this.schema,
        });
        var fecha = new Date();
        var templateschedulerView = `<div style="height: inherit;" id='rowS_#:id#' class="rowScheduler" data-bind="click: showDetails">
      <div style="display: flex;justify-content: space-between;">
          <h3 data-toogle="tooltip" title="#: title ??= '' #" style="padding: 3px; text-align: center">#: title #</h3>
      </div>
      <div id="fs_#:id#" style="display: none;">
      `;
        var templateschedulerViewActions = ``;
        if (this.configTable.permissions[0].allowEditFromGrid) {
          templateschedulerViewActions += `<a style="padding: 3px" id="edit_#= id #" class="btn btn-sm" data-bind="click: editClick" data-toogle="tooltip" title="Editar"><i style="font-size:19px" class="fa fa-edit"></i></a>`;
        }

        if (this.configTable.permissions[0].allowDeleteFromGrid) {
          if (this.logicalDelete) {
            templateschedulerViewActions += `<a style="padding: 3px" id="deleteL_#= id #" class="btn btn-sm" data-bind="click: logicalDeleteClick" data-toogle="tooltip" title="Eliminar"><i style="font-size:19px" class="fa fa-trash"></i></a>`;
          } else {
            templateschedulerViewActions += `<a style="padding: 3px" id="delete_#= id #" class="btn btn-sm" data-bind="click: deleteClick" data-toogle="tooltip" title="Eliminar"><i style="font-size:19px" class="fa fa-trash"></i></a>`;
          }
        }

        if (this.configTable.Actions.length > 0) {
          templateschedulerViewActions += `<div style="display: contents;" id="actionsCustomPopover_#:id#">`;
          let index = 1;
          this.configTable.Actions.forEach((value: any) => {
            if (sessionStorage.getItem('rolesId')) {
              if (sessionStorage.getItem('rolesId').includes(',')) {
                let stringRoles = sessionStorage.getItem('rolesId').split(',');
                stringRoles.forEach((rol) => {
                  if (value.rolesId.includes(rol)) {
                    if (!value.GlobalAction) {
                      switch (value.Type) {
                        case 'NavegarVista':
                          templateschedulerViewActions += `<a style="padding: 3px" id="${index}_actionCustom_#= id #" name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm actionsCustom' data-bind="click: navigationClick" data-queryString="${value.QueryString}"><i style="font-size:19px" class="fa ${value.IconAwesomeAction}"></i></a>`;
                          break;
                        case 'Codigo':
                          templateschedulerViewActions += `<a style="padding: 3px" id="${index}_actionCustom_#= id #" name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm actionsCustom' data-bind="click: actionCodeClick" data-actionId="${value.ActionId}"><i style="font-size:19px" class="fa ${value.IconAwesomeAction}"></i></a>`;
                          break;
                        case 'Encuesta':
                          templateschedulerViewActions += `<a style="padding: 3px" id="${index}_actionCustom_#= id #" name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm actionsCustom' data-bind="click: navigationClick" data-queryString="${value.QueryString}"><i style="font-size:19px" class="fa ${value.IconAwesomeAction}"></i></a>`;
                          break;
                      }
                      index++;
                    }
                  }
                });
              } else if (
                value.rolesId.includes(sessionStorage.getItem('rolesId'))
              ) {
                if (!value.GlobalAction) {
                  switch (value.Type) {
                    case 'NavegarVista':
                      templateschedulerViewActions += `<a style="padding: 3px" id="${index}_actionCustom_#= id #" name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm actionsCustom' data-bind="click: navigationClick" data-queryString="${value.QueryString}"><i style="font-size:19px" class="fa ${value.IconAwesomeAction}"></i></a>`;
                      break;
                    case 'Codigo':
                      templateschedulerViewActions += `<a style="padding: 3px" id="${index}_actionCustom_#= id #" name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm actionsCustom' data-bind="click: actionCodeClick" data-actionId="${value.ActionId}"><i style="font-size:19px" class="fa ${value.IconAwesomeAction}"></i></a>`;
                      break;
                    case 'Encuesta':
                      templateschedulerViewActions += `<a style="padding: 3px" id="${index}_actionCustom_#= id #" name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm actionsCustom' data-bind="click: navigationClick" data-queryString="${value.QueryString}"><i style="font-size:19px" class="fa ${value.IconAwesomeAction}"></i></a>`;
                      break;
                  }
                  index++;
                }
              }
            } else if (this.isAnonymous == true) {
              if (!value.GlobalAction) {
                switch (value.Type) {
                  case 'NavegarVista':
                    templateschedulerViewActions += `<a style="padding: 3px" id="${index}_actionCustom_#= id #" name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm actionsCustom' data-bind="click: navigationClick" data-queryString="${value.QueryString}"><i style="font-size:19px" class="fa ${value.IconAwesomeAction}"></i></a>`;
                    break;
                  case 'Codigo':
                    templateschedulerViewActions += `<a style="padding: 3px" id="${index}_actionCustom_#= id #" name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm actionsCustom' data-bind="click: actionCodeClick" data-actionId="${value.ActionId}"><i style="font-size:19px" class="fa ${value.IconAwesomeAction}"></i></a>`;
                    break;
                }
              }
              index++;
            }
          });
          templateschedulerViewActions += `</div>`;
        }

        if (templateschedulerViewActions != '') {
          templateschedulerView += `<h3 style="font-size: inherit; white-space: nowrap; overflow: hidden; text-overflow: ellipsis;">Acciones</h3>
        <br> ${templateschedulerViewActions}`;
        }

        templateschedulerView += ` </div>
                      </div>
                  </div>
                </div>`;
        this.templateRows = kendo.template(templateschedulerView);
        this.mainListOptions = {
          date: fecha,
          height: 600,
          views: [
            { type: 'day', title: 'Día' },
            { type: 'week', selected: true, title: 'Semana' },
            { type: 'workWeek', title: 'Semana laboral' },
            { type: 'month', title: 'Mes' },
            { type: 'agenda', title: 'Agenda ' },
          ],
          editable: false,
          eventTemplate: this.templateRows,
          allDayEventTemplate: this.templateRows,
          dataSource: this.dataSource,
          serverFiltering: true,
          dataBound: (e: any) => {
            setTimeout(() => {
              this.setActionsList();
              this.setListClass();
            }, 25);
          },
        };

        setTimeout(() => {
          this.setListClass();
          kendo.jQuery('#schedulerView').kendoScheduler(this.mainListOptions);

          this.setActionsList();
          this.listLoaded(this.configTable);
        }, 1500);
      })
      .catch((error: any) => {
        if (!localStorage.getItem('Authorization') && error.status == 404) {
          this.router.navigate([
            `/auth/login/${sessionStorage.getItem('workspace')}`,
          ]);
        }
      })
      .finally(() => { });
  }

  getIndexById(id: any) {
    var idx,
      l = this.dataScheduler.length;

    for (var j = 0; j < l; j++) {
      if (this.dataScheduler[j].id == id) {
        return j;
      }
    }
    return null;
  }

  /**
   * Método encargado para ejecutar evento ListLoaded
   */
  listLoaded(config: any) {
    this.applicationService.fireEvents(
      'ListLoaded',
      config.formsEvents,
      config
    );
  }

  addRow() {
    var defaultFieldsValues = null;
    if (!this.showAdd) {
      this.notificationsService.notificationApp('error', Constants.MESSAGES.CanNotCreate);
      return;
    }

    if (this.filterOptions) {
      defaultFieldsValues = [];
      var filterOptions = JSON.parse(this.filterOptions);
      filterOptions.forEach((filterOption: any) => {
        defaultFieldsValues.push({
          fieldName: filterOption.fieldName,
          value: filterOption.value,
        });
      });
    }

    var params: NavigationExtras = {
      queryParams: {
        viewName: this.viewNameId,
        entityId: this.entityId,
        entityName: this.viewNameId,
      },
    };

    if (defaultFieldsValues) {
      params.queryParams.defaultFieldsValues =
        JSON.stringify(defaultFieldsValues);
    }

    if (this.viewsToOpen.Create != undefined) {
      params.queryParams.appViewId = this.viewsToOpen.Create;
    }

    this.router.navigate(['/forms'], params);
  }

  async getDataschedulerView(
    options: any,
    arrayNone: any,
    arrayAnd: any,
    arrayOr: any
  ) {
    var optionsFilter = options;
    var filterGridOptions = this.filterGridOptions;

    if (arrayNone != undefined || arrayNone != null) {
      var nIteracion = arrayNone.length / 3;
      var inicio = 0;
      var fin = 3;
      var bandera = 0;

      for (let i = 0; i < nIteracion; i++) {
        var part = arrayNone.slice(inicio, fin);

        for (
          let j = 0;
          j < this.filterGridOptions.filters[0].filters.length;
          j++
        ) {
          if (
            this.filterGridOptions.filters[0].filters[j].field == part[0] &&
            this.filterGridOptions.filters[0].filters[j].operator == part[1] &&
            this.filterGridOptions.filters[0].filters[j].value == part[2]
          ) {
            bandera++;
          }
        }

        if (bandera == 0) {
          if (part[2] != 'null') {
            this.filterGridOptions.filters[0].filters.push({
              field: part,
              operator: part[1],
              value: part[2],
            });
          }

          inicio = fin;
          fin = fin + 3;
        }

        bandera = 0;
      }
    }

    if (arrayAnd != undefined || arrayAnd != null) {
      var nIteracion = arrayAnd.length / 3;
      var inicio = 0;
      var fin = 3;
      var bandera = 0;

      for (let i = 0; i < nIteracion; i++) {
        var part = arrayAnd.slice(inicio, fin);

        for (let j = 0; j < this.filterGridOptions.filters.length; j++) {
          if (
            this.filterGridOptions.filters[j].field == part[0] &&
            this.filterGridOptions.filters[j].operator == part[1] &&
            this.filterGridOptions.filters[j].value == part[2]
          ) {
            bandera++;
          }
        }

        if (bandera == 0) {
          if (part[2] != 'null') {
            this.filterGridOptions.filters.push({
              field: part,
              operator: part[1],
              value: part[2],
            });
          }

          inicio = fin;
          fin = fin + 3;
        }

        bandera = 0;
      }
    }

    if (arrayOr != undefined || arrayOr != null) {
      var nIteracion = arrayOr.length / 3;
      var inicio = 0;
      var fin = 3;
      var bandera = 0;

      for (let i = 0; i < nIteracion; i++) {
        var part = arrayOr.slice(inicio, fin);

        for (
          let j = 0;
          j < this.filterGridOptions.filters[0].filters.length;
          j++
        ) {
          if (
            this.filterGridOptions.filters[0].filters[j].field == part[0] &&
            this.filterGridOptions.filters[0].filters[j].operator == part[1] &&
            this.filterGridOptions.filters[0].filters[j].value == part[2]
          ) {
            bandera++;
          }
        }

        if (bandera == 0) {
          if (part[2] != 'null') {
            this.filterGridOptions.filters[0].filters.push({
              field: part,
              operator: part[1],
              value: part[2],
            });
          }

          inicio = fin;
          fin = fin + 3;
        }

        bandera = 0;
      }
    }

    if (this.filterGridOptions != undefined) {
      if (optionsFilter.data.filter == undefined) {
        optionsFilter.data.filter = filterGridOptions;
      } else {
        if (this.filterGridOptions == undefined) {
          return this.filterGridOptions;
        } else {
          this.filterGridOptions.filters[1].filters =
            optionsFilter.data.filter.filters;
          optionsFilter.data.filter = this.filterGridOptions;
        }
      }
    }

    if (this.dataSort != null || this.dataSort != undefined) {
      optionsFilter.data.sort = [
        { field: this.dataSort[0], dir: this.dataSort[1] },
      ];
      this.dataSort = null;
    }

    if (
      optionsFilter.data.sort != null ||
      optionsFilter.data.sort != undefined
    ) {
      if (
        optionsFilter.data.sort[0] != undefined ||
        optionsFilter.data.sort[0] != null
      ) {
        if (optionsFilter.data.sort[0].field.split('.').length == 1) {
          var field = optionsFilter.data.sort[0].field;
          optionsFilter.data.sort[0].field = this.viewEntityName + '.' + field;
        }
      }
    }

    var urlTx = `${backandGlobal.api2}/${sessionStorage.getItem(
      'workspace'
    )}.api/api/lappiz/get/${this.viewEntityName}`;
    var entityNameOff = this.viewEntityName;
    sessionStorage.setItem('entityname', entityNameOff);
    optionsFilter.data.tenantId = sessionStorage.getItem('tenantId');
    optionsFilter.data.parameters = {
      userId: `${sessionStorage.getItem('userId')}`,
      appViewId: `${this.route.snapshot.queryParams.appViewId}`,
      pType: 'showinmenu',
      aType: 'ffija',
      environment: `${backandGlobal.environment}`,
    };

    if (optionsFilter.data.sort) {
      this.sortGrid = optionsFilter.data.sort;
    }

    if (this.sortGrid && !optionsFilter.data.sort) {
      optionsFilter.data.sort = this.sortGrid;
    }
  }

  /**
   * Método encargado de ejecutar una acción de tipo código para el registro seleccionado
   */
  actionCode(actionId: any, selectedItem: any) {
    var e = {
      dataItem: selectedItem.dataItem
        ? selectedItem.dataItem
        : selectedItem.itemData,
    };

    var action = $.grep(this.configTable.Actions, (b: any) => {
      return b.ActionId === actionId;
    })[0];

    var scriptAction = this.applicationService.transformFunction(
      action.FormEvent
    );
    this.applicationService.evalEvent(scriptAction, e);
  }

  /**
   * Método encargado de ejecutar una acción de tipo navegación para el registro seleccionado
   */
  openDirective(queryString: string, selectedItem: any) {
    var params: NavigationExtras = {
      queryParams: {},
    };

    var parts = queryString.split('?')[1].split('&');

    for (let i = 0; i < parts.length; i++) {
      var value = parts[i].split('=')[1];

      if (value.includes('selectedItem')) {
        var data = {};

        if (selectedItem.dataItem != undefined) {
          data = selectedItem.dataItem;
        } else {
          data = selectedItem.itemData;
        }

        params.queryParams[parts[i].split('=')[0]] = `${data[value.replace('selectedItem.', '')]
          }`;
      } else {
        params.queryParams[parts[i].split('=')[0]] = parts[i].split('=')[1];
      }
    }

    var path = queryString.split('?')[0];

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`${path}`], params);
    });
  }

  editSelected(e: any) {
    var id = e.dataItem.id;
    if (e.dataItem != undefined) {
      localStorage.setItem('IdSelectedItem', id);
    } else {
      localStorage.setItem('IdSelectedItem', e.itemData.Id._value);
    }

    if (e.dataItem != undefined) {
      var params: NavigationExtras = {
        queryParams: {
          rowId: id,
          viewName: this.viewNameId,
          entityId: this.entityId,
        },
      };
    } else {
      var params: NavigationExtras = {
        queryParams: {
          rowId: e.itemData.Id._value,
          viewName: this.viewNameId,
          entityId: this.entityId,
        },
      };
    }

    if (this.viewsToOpen.Edit != undefined) {
      params.queryParams.appViewId = this.viewsToOpen.Edit;
    }

    this.router.navigate(['/forms'], params);
  }

  /**
   * Método encargado de hacer borrado lógico de un registro seleccionado
   */
  async logicalDeleteSelected(e: any) {
    if (!this.showDelete) {
      this.notificationsService.notificationApp('error', Constants.MESSAGES.CanNotDelete);
      return;
    }

    let result = await this.notificationsService.confirmAlert('warning', this.customMessages.confirm);
      if (!result) {
        return
      }
      var id;
      if (e.dataItem != undefined) {
        id = id;
      } else {
        id = e.itemData.Id._value;
      }

      if (!id) {
        this.notificationsService.notificationApp('error', Constants.MESSAGES.failure);
        return;
      }

      var viewName = this.viewNameId;
      if (!viewName) {
        this.notificationsService.notificationApp('error', Constants.MESSAGES.failure);
        return;
      }

      var dataLoadedEvent = {
        entityId: this.entityId,
        entityCode: this.viewNameId,
        dataItem: e.dataItem,
      };

      this.dataItemLogicalEntity = e.dataItem;

      if (
        this.applicationService.fireEvents(
          'ItemDeleting',
          this.configTable.formsEvents,
          dataLoadedEvent
        )
      ) {
        var tenantId = sessionStorage.getItem('tenantId');

        this.dataItemLogicalEntity.RowStatus =
          this.dataItemLogicalEntity.RowStatus == 'Active'
            ? (this.dataItemLogicalEntity.RowStatus = 'Deleted')
            : 'Active';
        this.dataItemLogicalEntity.tenantId = tenantId;
        this.dataItemLogicalEntity.parameters = {
          userId: `${sessionStorage.getItem('userId')}`,
          appViewId: `${this.route.snapshot.queryParams.appViewId}`,
          pType: 'Editar',
          aType: `${this.route.snapshot.queryParams.appViewId != undefined
            ? 'view'
            : 'ffija'
            }`,
          environment: `${backandGlobal.environment}`,
        };

        if (this.configTable.HasHistorics) {
          await this.setHistoryValues();
        }

        var urlTxLogicalDelete = `${backandGlobal.api2}/${sessionStorage.getItem(
          'workspace'
        )}.api/api/lappiz/${viewName}/${id}`;

        this.proxyTxApiService
          .operationTxApi(
            `${urlTxLogicalDelete}`,
            JSON.stringify(this.dataItemLogicalEntity),
            'update',
            backandGlobal.stateOnline,
            viewName
          )
          .then((response: any) => {
            if (backandGlobal.stateOnline === false) {
              var entitiesChanged = localStorage.EntityNamesChanged
                ? JSON.parse(localStorage.EntityNamesChanged)
                : [];
              entitiesChanged.filter((entity) => entity === viewName).length == 0
                ? entitiesChanged.push(viewName)
                : '';
              localStorage.EntityNamesChanged = JSON.stringify(entitiesChanged);
            }

            this.notificationsService.notificationApp('success', Constants.MESSAGES.SuccessDelete);
          })
          .catch((error: any) => {
            this.errorCallback(error);
          })
          .finally(() => {
            this.refresh();
          });
      }
  }

  /**
   * Método encargado de navegar a vista modo viewItem en el formulario
   */
  viewSelected(e: any) {
    if (e.dataItem != undefined) {
      localStorage.setItem('IdSelectedItem', e.dataItem.Id);
    } else {
      localStorage.setItem('IdSelectedItem', e.itemDta.Id._value);
    }

    if (e.dataItem != undefined) {
      var params: NavigationExtras = {
        queryParams: {
          rowId: e.dataItem.Id,
          viewName: this.viewNameId,
          entityId: this.entityId,
        },
      };
    } else {
      var params: NavigationExtras = {
        queryParams: {
          rowId: e.itemData.Id._value,
          viewName: this.viewNameId,
          entity: this.entityId,
        },
      };
    }

    if (this.viewsToOpen.Edit != undefined) {
      params.queryParams.appViewId = this.viewsToOpen.Edit;
    }

    this.router.navigate(['/viewItem'], params);
  }

  /**
   * Método encargado de hacer borrado físico de un registro seleccionado
   */
  async deleteSelected(e: any) {
    if (!this.showDelete) {
      this.notificationsService.notificationApp('error', Constants.MESSAGES.CanNotDelete);
      return;
    }

    let result = await this.notificationsService.confirmAlert('warning', this.customMessages.confirm);
      if (!result) {
        return
      }
      var id;
      if (e.dataItem != undefined) {
        id = e.dataItem.id;
      } else {
        id = e.itemData.Id._value;
      }

      if (!id) {
        this.notificationsService.notificationApp('error', Constants.MESSAGES.failure);
        return;
      }

      var viewName = this.viewNameId;
      if (!viewName) {
        this.notificationsService.notificationApp('error', Constants.MESSAGES.failure);
        return;
      }

      var dataLoadedEvent = {
        entityId: this.entityId,
        entityCode: this.viewNameId,
        dataItem: e.dataItem,
      };

      if (
        this.applicationService.fireEvents(
          'ItemDeleting',
          this.configTable.formsEvents,
          dataLoadedEvent
        )
      ) {
        var opts = {
          parameters: {
            userId: `${sessionStorage.getItem('userId')}`,
            appViewId: `${this.route.snapshot.queryParams.appViewId}`,
            pType: 'Eliminar',
            aType: 'ffija',
            environment: `${backandGlobal.environment}`,
          },
        };

        var tenant = sessionStorage.getItem('tenantId');
        var urlTxDelete = `${backandGlobal.api2}/${sessionStorage.getItem(
          'workspace'
        )}.api/api/lappiz/${viewName}/${id}/${tenant}`;

        this.proxyTxApiService
          .operationTxApi(
            `${urlTxDelete}`,
            JSON.stringify(opts),
            'delete',
            backandGlobal.stateOnline,
            viewName
          )
          .then((response: any) => {
            if (backandGlobal.stateOnline === false) {
              var entitiesChanged = localStorage.EntityNamesChanged
                ? JSON.parse(localStorage.EntityNamesChanged)
                : [];
              entitiesChanged.filter((entity) => entity === viewName).length == 0
                ? entitiesChanged.push(viewName)
                : '';
              localStorage.EntityNamesChanged = JSON.stringify(entitiesChanged);
            }

            if (viewName.includes('Lappiz_Users')) {
              this.userService.replicateDelete(
                id,
                sessionStorage.aplicattionuserid
              );
            }

            this.notificationsService.notificationApp('success', Constants.MESSAGES.SuccessDelete);
          })
          .catch((error: any) => {
            this.errorCallback(error);
          })
          .finally(() => {
            this.refresh();
          });
      }
  }

  async setHistoryValues() {
    this.dataItemLogicalEntity.EventType = 'Actualizar';
    this.dataItemLogicalEntity.Edited_by = sessionStorage.getItem('userId');
    this.dataItemLogicalEntity.UserEmail = localStorage.getItem('userName');

    await this.userService
      .getIpUser()
      .then((response: any) => {
        this.dataItemLogicalEntity.IpAddress = response.ip;
      })
      .catch((error: any) => {
        console.log(error);
      });
  }

  /**
   * Método encargado de mostrar toastr
   */
  mostrarMensaje(type: any, message: any) {
    this.notificationsService.notificationApp(type, message);
  }

  errorCallback(error: any) {
    this.load = false;
    if (error.status == 500 || error.status == 400) {
      if (error.body.error.message != undefined) {
        var messageError = error.body.error.message.value;

        if (messageError.includes('ConstraintViolationException')) {
          messageError = Constants.MESSAGES.EntityUsed;
        }
      }

      if (messageError != null) {
        this.alerts = [{ type: 'danger', msg: messageError }];
        this.notificationsService.notificationApp('error', messageError);
      } else {
        this.alerts = [{ type: 'danger', msg: this.customMessages.failure }];
        this.notificationsService.notificationApp('error', Constants.MESSAGES.failure);
      }
    } else {
      this.alerts = [{ type: 'danger', msg: error.body }];
      this.notificationsService.notificationApp('error', Constants.MESSAGES.failure);
    }
  }

}
