import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { FormsComponent } from '@components/forms/forms.component';

declare var kendo: any;

@Component({
  selector: 'app-editor',
  templateUrl: './editor.component.html',
  styleUrls: ['./editor.component.scss']
})
export class EditorComponent implements OnInit, AfterViewInit {

  @Input() field: any;
  @Input() value: any;
  @Input() form: any;
  @Input() errors: any;

  Id: any;
  tools: any;
  innerVal: any;
  isEmpty: any;
  cells:string;
  actionFormula: any;
  formSubmitted: any = false;

  innerForm = new UntypedFormGroup({
    field: new UntypedFormControl(),
  });

  constructor(
    private formsComponent: FormsComponent
  ) { }

  ngAfterViewInit() {
    if (this.field.required == false) {
      this.innerForm = new UntypedFormGroup({
        field: new UntypedFormControl(this.field.value.val, [
          Validators.minLength(this.field.minimumValue),
          Validators.maxLength(this.field.maximumValue)
        ])
      });
    }
  }

  ngOnInit(): void {
    if (this.field.required == true) {
      this.innerForm = new UntypedFormGroup({
        field: new UntypedFormControl(this.field.value.val, [
          Validators.required,
          Validators.minLength(this.field.minimumValue),
          Validators.maxLength(this.field.maximumValue),
        ])
      });
    }

    this.formsComponent.checkForm(this.field.campoId, this.innerForm.invalid);

    // kendo-editor
    this.Id = this.field.campoId;
    this.cells = `<div class='k-ct-popup'>`;
    for (let i = 0; i < 48; i++) {
      this.cells += `<span class='k-ct-cell k-state-disabled'></span>`;
    }

    this.cells += `<div class='k-status'></div></div>`;
    this.tools = {
      tools: [
        "pdf",
        "print",
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "justifyLeft",
        "justifyCenter",
        "justifyRight",
        "justifyFull",
        "insertUnorderedList",
        "insertOrderedList",
        "indent",
        "outdent",
        "createLink",
        "unlink",
        "insertImage",
        "insertFile",
        "subscript",
        "superscript",
        {
          name: "createTable",
          popupTemplate: this.cells
        },
        "tableWizard",
        "addRowAbove",
        "addRowBelow",
        "addColumnLeft",
        "addColumnRight",
        "deleteRow",
        "deleteColumn",
        "mergeCellsHorizontally",
        "mergeCellsVertically",
        "splitCellHorizontally",
        "splitCellVertically",
        "viewHtml",
        "applyFormat",
        "copyFormat",
        "cleanFormatting",
        "fontName",
        "fontSize",
        "formatting",
        "foreColor",
        "backColor"
      ],
      pdf: {
        paperSize: "a4",
        margin: {
          bottom: 20,
          left: 20,
          right: 20,
          top: 20
        }
      },
      change: (change: any) => {
        this.field.value.val = change.sender.value();
        this.change(this.field);
      }
    }

    setTimeout(() => {
      var editor = kendo.jQuery(`#${this.field.campoId}`).data("kendoEditor");
      if (!editor) {
        kendo.jQuery(`#${this.field.campoId}`).kendoEditor(this.tools);
      }
    }, 25);

    if (!this.value.val) {
      this.value.val = this.field.defaultValue;
    }

    this.innerVal = {
      val: this.value.val
    }

    this.isEmpty = !this.innerVal.val;
  }

  change($event?: any) {
    this.formsComponent.setValueFieldForm(this.field.campoId, this.field);
    this.formsComponent.applyUIRules(this.field, this.value);
    this.formsComponent.fireEvents('ValueChanged', this.field.events, {
      value: this.value.val,
      field: this.field
    });
    if (this.field.required) {
      this.formsComponent.checkForm(this.field.campoId, this.value.val ? false : true);
    } else {
      this.formsComponent.checkForm(this.field.campoId, this.value.val ? false : false);
    }
  }

}
