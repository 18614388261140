import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { NgbDropdownModule, NgbCollapseModule } from '@ng-bootstrap/ng-bootstrap';
import { 
  PerfectScrollbarModule, 
  PERFECT_SCROLLBAR_CONFIG, 
  PerfectScrollbarConfigInterface 
} from 'ngx-perfect-scrollbar';
import { NgxSpinnerModule } from 'ngx-spinner';

import { ContentAnimateDirective } from '@core/content-animate/content-animate.directive';
import { FeahterIconModule } from '@core/feather-icon/feather-icon.module';

import { LoaderComponent } from '@components/loader/loader.component';

import { BaseComponent } from '@components/layout/base/base.component';
import { NavbarComponent } from '@components/layout/navbar/navbar.component';
import { SidebarComponent } from '@components/layout/sidebar/sidebar.component';
import { MobileBarComponent } from '@components/layout/mobile-bar/mobile-bar.component';
import { FooterComponent } from '@components/layout/footer/footer.component';

import { SyncDataComponent } from '@components/sync-data/sync-data.component';
import { UserProfileComponent } from '@components/user-profile/user-profile.component';
import { AlertContractComponent } from './alert-contract/alert-contract.component';
import { AlertPayComponent } from './alert-pay/alert-pay.component';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

@NgModule({
  declarations: [
    BaseComponent,
    NavbarComponent,
    SidebarComponent,
    MobileBarComponent,
    FooterComponent,
    SyncDataComponent,
    UserProfileComponent,
    LoaderComponent,
    ContentAnimateDirective,
    AlertContractComponent,
    AlertPayComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    NgbDropdownModule,
    NgbCollapseModule,
    PerfectScrollbarModule,
    FeahterIconModule,
    NgxSpinnerModule,
  ],
  providers: [
    {
      provide: PERFECT_SCROLLBAR_CONFIG,
      useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
    }
  ],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ]
})
export class LayoutModule { }
