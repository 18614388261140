import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-loader-view',
  templateUrl: './loader-view.component.html',
  styleUrls: ['./loader-view.component.scss']
})
export class LoaderViewComponent implements OnInit {

  constructor(
    private spinner: NgxSpinnerService
  ) { }

  ngOnInit(): void { }

  loader(isVisible: boolean) {
    if (isVisible === true) {
      this.spinner.show('loader-view')
    } else {
      this.spinner.hide('loader-view');
    }
  }

}
