import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { FormsComponent } from '@components/forms/forms.component';

@Component({
  selector: 'app-time',
  templateUrl: './time.component.html',
  styleUrls: ['./time.component.scss']
})
export class TimeComponent implements OnInit {

  @Input() errors: any;
  @Input() field: any;
  @Input() showQr: boolean;
  @Input() value: any;

  innerForm: UntypedFormGroup;
  showTime: boolean;
  timeBox: any;

  constructor(private formsComponent: FormsComponent) {
    this.innerForm = new UntypedFormGroup({
      field: new UntypedFormControl()
    })
   }

  ngOnInit(): void {
    this.formsComponent.applyUIRules(this.field, this.value);
    let date = null;
    if (this.field.value && this.field.value != "") {
      if (typeof (this.value.val) == 'string') {
        if (this.value.val.toLowerCase() == "now"){
          date = new Date();
          this.field.value.val = `${date.getHours()}:${date.getMinutes()}`
        }
      }
    }

    this.timeBox = {
      type: "time",
      value: this.field.value.val,
      disabled: this.field.disabled,
      showClearButton: true,
      onValueChanged: (e: any) => {
        this.field.value.val = e.value;
        this.change(e);
        this.formsComponent.applyUIRules(this.field, this.value);
      }
    }

    setTimeout(() => {
      $(`#${this.field.campoId}`).dxDateBox(this.timeBox);
      $(`#${this.field.campoId}`).val(this.field.value.val)
      this.formsComponent.checkForm(this.field.campoId, this.value.val != null && this.value.val != "" ? false : true);
    }, 25);
  }

  change(e: any) {
    this.formsComponent.setValueFieldForm(this.field.campoId, this.field);
    if (this.value.val) {
      this.value.val = this.formatTime(this.value.val);
      this.formsComponent.fireEvents('ValueChanged', this.field.events, {
        value: this.value.val,
        field: this.field
      });
    }
    if (this.field.required === true) {
      this.formsComponent.checkForm(this.field.campoId, this.value.val != null && this.value.val != "" ? false : true);
    } else {
      this.formsComponent.checkForm(this.field.campoId, false);
    }
  }

  formatTime(time: string) {
    const [hours, minutes] = time.split(':');
    if (!hours || !minutes) return '';
    else if (parseInt(hours) >= 0 && parseInt(hours) < 24 && parseInt(minutes) >=0 && parseInt(minutes) < 60) return time;
  }

}
