import { Component, OnInit, AfterViewInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { ProxyTxApiService } from '@services/proxy-tx-api.service';

import backandGlobal from '@env/env';

declare var kendo: any;

@Component({
  selector: 'app-history',
  templateUrl: './history.component.html',
  styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, AfterViewInit {

  @Input() viewName: any;
  @Input() rowId: any;

  formHistoric: UntypedFormGroup;
  
  isDetails: boolean;
  urlHistory: string;
  arrHistory: any[];
  historics = [];
  gridHistoryOptions: any;

  constructor(
    private proxyTxApiService: ProxyTxApiService,
    private activeModal: NgbActiveModal,
    private route: ActivatedRoute
  ) { 
    
    this.formHistoric = new UntypedFormGroup({});

    this.isDetails = true;
    this.urlHistory = '';
    this.arrHistory = [];
    this.gridHistoryOptions = {};

  }

  ngAfterViewInit(): void {
    this.loadHistorics();
  }

  ngOnInit() {
    
  }

  loadHistorics() {
    this.urlHistory = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/history/${this.viewName}/${this.rowId}`;

    this.proxyTxApiService.dataGetTx(`${this.urlHistory}`, backandGlobal.stateOnline).then((data: any) => {
      this.arrHistory = data;
      var users = [];

      this.arrHistory.forEach(async (item: any) => {
        var user = $.grep(users, function (b) {
          return b.Id == item.UserId.toUpperCase();
        })[0];

        if (!user) {
          var urlUser = '';
          var fieldUser = '';

          if (backandGlobal.environment == 'DEV' || backandGlobal.environment == 'TEST') {
            urlUser = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/lappiz/get/${backandGlobal.currentApp.name}_Users`;
            fieldUser = `[${backandGlobal.currentApp.name}_Users].[Id]`;
          } else if (backandGlobal.environment == 'PROD') {
            urlUser = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/lappiz/get/Lappiz_Users`;
            fieldUser = `[Lappiz_Users].[Id]`;
          }

          var opc = {
            'take': 100, 'skip': 0, 'page': 1, 'pageSize': 10,
            'filter': {
              'logic': 'or',
              'filters': [{
                'field': fieldUser,
                'operator': 'eq',
                'value': item.UserId
              }]
            },
            'tenantId': `${sessionStorage.getItem('tenantId')}`,
            'parameters': {
              'userId': `${sessionStorage.getItem('userId')}`,
              'pType': 'showinmenu',
              'aType': 'ffija',
              'environment': backandGlobal.environment
            }
          };

          await this.proxyTxApiService.getTxData(`${urlUser}`, opc, backandGlobal.stateOnline).then((response: any) => {
            user = response.data.rows[0];
            users.push(user);

            var text = '';
            var dataItemHistorics = Object.entries(item.Data);
            dataItemHistorics.forEach(item => {
              if (!item[0].includes('Created_by') && !item[0].includes('Edited_by') && !item[0].includes('Created_date') && !item[0].includes('Edited_date')) {
                if (item[1] != null && item[1] != undefined && item[1] != 'null') {
                  text += `<span style='font-weight: bold;'>${item[0]} </span>: ${item[1]}\n`;
                }
              }
            });

            this.historics.push({ CreatedDate: new Date(item.CreatedDate), User: user.FullName, Data: `<pre style="background-color: #f5f5f500;border: 1px solid #ccc0;font-family:Roboto-Regular, Roboto, Helvetica Neue, Arial, Droid Sans, sans-serif; !important">${text}</pre>` });
          }).then(() => {
            if (this.arrHistory.length == this.historics.length) {
              this.gridHistoryOptions = {
                dataSource: {
                  data: this.historics,
                  pageSize: 5,
                  sort: { field: 'CreatedDate', dir: 'desc' }
                },
                sortable: true,
                pageable: true,
                dataBound() {
                  this.expandRow(this.tbody.find('tr.k-master-row').first());
                },
                columns: [{
                  field: 'CreatedDate',
                  title: 'Fecha Creación',
                  width: '70px',
                  template: '#= kendo.toString(kendo.parseDate(CreatedDate), \'dd/MM/yyyy hh:mm:ss\') #'
                }, {
                    field: 'User',
                    title: 'Usuario',
                    width: '120px'
                }, {
                    field: 'Data',
                    width: '170px',
                    template: '#=Data#'
                }]
              };
            }
          }).finally(() => {
            kendo.jQuery('#gridHistory').kendoGrid(this.gridHistoryOptions);
          });
        } else {
          var text = '';
          var partsHistorics = JSON.stringify(item.Data).split(',');

          partsHistorics.forEach((item: any) => {
            if (!item.includes('Created_by') && !item.includes('Edited_by')) {
              var values = item.replace(/['"{}]+/g, '').split(':');
              text += `<span style='font-weight: bold;'>${values[0]} </span>: ${values[1]}\n`;
            }
          });
          this.historics.push({ CreatedDate: new Date(item.CreatedDate), User: user.FullName, Data: `<pre style="background-color: #f5f5f500; border: 1px solid #ccc0; font-family: Roboto-Regular, Roboto, Helvetica Neue, Arial, Droid Sans, sans-serif; !important">${text}</pre>` });
        }
      });
    }).catch((error: any) => {
      console.log(error);
    });
  }

  ok() {
    this.activeModal.close(this.historics);
  }

  cancelar() {
    this.activeModal.close(this.historics);
  }

}
