<div>
  <div class="form-group">
    <div class="input-group mb-3">
      <input type="{{field.type}}" name="field" class="form-control" (ngModel)="value.val"
        ng-disabled="field.disabled || !field.IsEditable" ngClass="inputClass">
      <div class="input-group-prepend">
        <span class="input-group-text" id="basic-addon1" ngHide="field.show"><i class="fa fa-calculator"></i></span>
      </div>
    </div>
    <!-- <div *ngIf="errors" class="input-error" role="alert">{{errors}}</div> -->
  </div>
</div>