<form [formGroup]="innerForm">
  <input type="text" [id]="field.campoId" [hidden]="true" (change)="change()" [(ngModel)]="value.val"
    formControlName="field" (ngModelChange)="change()">
    
  <div class="signature-container">
    <canvas class="signature-pad" [hidden]="value.val && value.val != ''" id="canva_{{field.campoId}}" width="400" height="100"></canvas>
  <!-- Agarrador para redimensionar -->
  <img src="{{value.val}}" alt="Firma" id="value_{{field.campoId}}" *ngIf="value.val && value.val != ''">
  </div>

  <button class="btn btn-signature-lappiz" (click)="getSignature()"
    [attr.disabled]="!field.IsEditable ? true : null || field.disabled ? true : null"
    [hidden]="value.val && value.val != ''">Firmar</button>

  <button class="btn btn-signature-lappiz" (click)="clear()"
    [attr.disabled]="!field.IsEditable ? true : null || field.disabled ? true : null">Limpiar</button>

  <div *ngIf="(errorGeneral != innerForm.controls.field.errors?.required) && innerForm.controls.field.touched"
    class="ft-appengine-required-input" id="error_{{field.campoId}}" role="alert">
    {{errorGeneral}}
  </div>
</form>
