import { Injectable } from '@angular/core';
import { v4 as uuidv4 } from 'uuid';

import { LocalDBManagerService } from '@services/local-dbmanager.service';

@Injectable({
  providedIn: 'root'
})
export class LocalTxApiService {

  localRowEntity: any;

  constructor(
    private localDBManagerService: LocalDBManagerService
  ) {
    this.localRowEntity = {};
  }

  generateNewId() {
    return uuidv4();
  }

  getLocalData(nameEntity: string, docId: string) {
    this.localRowEntity.id = docId.toLowerCase();

    return this.localDBManagerService.getData(nameEntity, this.localRowEntity.id);
  }

  getAllLocalData(entityName: any, pageConfig?: any, cascade?: any, includeEntities?: any) {
    return this.localDBManagerService.getAllData(entityName, pageConfig, cascade, includeEntities);
  }

  getAllLocalDetailsData(objEntity: any, pageConfig: any) {
    this.localRowEntity.fkCampo = objEntity.fkViewNameCampo;
    this.localRowEntity.valueFkId = objEntity.idFkRelation;
    this.localRowEntity.parentRow = objEntity.parentEntity;

    return this.localDBManagerService.getAllDetailsData(this.localRowEntity, pageConfig);
  }

  operationLocalData(nameEntity: any, data: any, operation: any, urlTransaction: any) {
    let typeOperation = operation;
    switch (typeOperation) {
      case 'post':
        return this.insertLocalData(nameEntity, data, urlTransaction, 'POST');
        break;
      case 'update':
        return this.updateLocalData(nameEntity, data, urlTransaction, 'PATCH');
        break;
      case 'delete':
        return this.deleteLocalData(nameEntity, data, urlTransaction, 'DELETE');
        break;
    }
  }

  operationLocalDetailsData(nameEntity: any, data: any, operation: any) {
    let typeOperation = operation;
    switch (typeOperation) {
      case 'post':
        return this.insertLocalDetailData(nameEntity, data);
        break;
      case 'update':
        return this.updateLocalDetailData(nameEntity, data);
        break;
      case 'delete':
        return this.deleteLocalDetailData(nameEntity, data);
        break;
    }
  }

  insertLocalData(nameEntity: any, data: any, urlTransaction: any, txTransaction: any) {
    data = JSON.parse(data);
    // data.Id = this.generateNewId();
    this.localRowEntity = data;
    this.localRowEntity._id = data.Id;
    this.localRowEntity.entityName = nameEntity;
    this.localRowEntity.createdDate = new Date();
    this.localRowEntity.urlTransaction = urlTransaction;
    this.localRowEntity.txTransaction = txTransaction;
    this.localRowEntity.syncDate = null;

    return this.localDBManagerService.insertData(nameEntity, this.localRowEntity);
  }

  insertLocalDetailData(nameEntity: any, data: any) {
    data = JSON.parse(data);

    this.localRowEntity = data;
    this.localRowEntity._id = data.Id;
    this.localRowEntity.entityName = nameEntity;
    this.localRowEntity.createdDate = new Date();

    this.localRowEntity.TempFieldsDetails = this.localRowEntity.__TempFieldsDetails;
    delete this.localRowEntity.__TempFieldsDetails;

    return this.localDBManagerService.insertDetailData(nameEntity, this.localRowEntity);
  }

  updateLocalData(nameEntity: any, data: any, urlTransaction: any, txTransaction: any) {
    data = JSON.parse(data);
    this.localRowEntity = data;
    this.localRowEntity._id = data.Id.toLowerCase();
    this.localRowEntity.entityName = nameEntity;
    this.localRowEntity.modifiedDate = new Date();
    this.localRowEntity.urlTransaction = urlTransaction;
    this.localRowEntity.txTransaction = txTransaction;
    this.localRowEntity.syncDate = null;
    var idRow = data.Id;
    idRow = idRow.toLowerCase();

    return this.localDBManagerService.updateData(nameEntity, idRow, this.localRowEntity);
  }

  updateLocalDetailData(nameEntity: any, data: any) {
    data = JSON.parse(data);

    this.localRowEntity = data;
    this.localRowEntity._id = data.Id.toLowerCase();
    this.localRowEntity.entityName = nameEntity;
    this.localRowEntity.modifiedDate = new Date();

    this.localRowEntity.TempFieldsDetails = this.localRowEntity.__TempFieldsDetails;
    delete this.localRowEntity.__TempFieldsDetails;

    var idRow = data.Id;
    idRow = idRow.toLowerCase();

    return this.localDBManagerService.updateDetailData(nameEntity, idRow, this.localRowEntity);
  }

  deleteLocalData(nameEntity: any, data: any, urlTransaction: any, txTransaction: any) {
    let dt = JSON.parse(data);
    delete data._events;
    delete data._handlers;
    delete data.dirty;
    delete data.uid;
    delete data.parent;
    var idRow = urlTransaction.split("/")[urlTransaction.split("/").length - 2];
    idRow = idRow.toLowerCase();
    this.localRowEntity.Id = idRow;
    this.localRowEntity._id = idRow.toLowerCase();
    this.localRowEntity._rev = data._rev;
    this.localRowEntity.entityName = nameEntity;
    this.localRowEntity.deletedDate = new Date();
    this.localRowEntity.urlTransaction = urlTransaction;
    this.localRowEntity.txTransaction = txTransaction;
    this.localRowEntity.syncDate = null;

    return this.localDBManagerService.deleteData(nameEntity, idRow, this.localRowEntity);
  }

  deleteLocalDetailData(nameEntity: any, data: any) {
    let dt = JSON.parse(data);

    delete data._events;
    delete data._handlers;
    delete data.dirty;
    delete data.uid;
    delete data.parent;

    this.localRowEntity.Id = dt.Id;
    this.localRowEntity._id = dt.Id.toLowerCase();
    this.localRowEntity._rev = data._rev;
    this.localRowEntity.entityName = nameEntity;
    this.localRowEntity.deletedDate = new Date();
    var idRow = dt.Id;
    idRow = idRow.toLowerCase();

    return this.localDBManagerService.deleteDetailData(nameEntity, idRow, this.localRowEntity);
  }

  eliminarDuplicados(array) {
    if(array.length == 0) return array;
    const conjuntoDeObjetos = new Set();
    const propiedades = Object.keys(array[0]);
    return array.filter(objeto => {
      const propiedadesUnicas = propiedades.map(propiedad => objeto[propiedad]).join('|');

      if (!conjuntoDeObjetos.has(propiedadesUnicas)) {
        conjuntoDeObjetos.add(propiedadesUnicas);
        return true;
      }
      return false;
    });
  }

  async execBD(query: any) {
    try {
      const response = [];
      const results = [];
      const responseFinally = { tableReference: '', data: [] };
      const { relatedTables } = query;
      const pageConfig = { limit: 100000, skip: 0, include_docs: true };

      const expandEntities = async (entities: any) => {
        await Promise.all(entities.map(async (entity: any) => {
          const { filter, tableName, relatedTable, as, filterLappiz } = entity;
          let responseService = { rows: [] };
          const responseRelatedTable = results.find(x => x.tableName == relatedTable);
          let myFilter;
          if (filter)
            myFilter = filter.filters[0];
          if (filterLappiz) {
            myFilter = filterLappiz.filters[0];
            const valuesIdsRelatedTable = responseRelatedTable.response.rows.reduce((y, x) => { y.push(x[myFilter.value.split('_')[1]].toLowerCase()); return y; }, []);
            myFilter.interpretValue = myFilter.value
            myFilter.value = valuesIdsRelatedTable;
            responseService = await this.localDBManagerService.getAllData(tableName, pageConfig, myFilter, null);
          } else {
            responseService = await this.localDBManagerService.getAllData(tableName, pageConfig, filter, null);
          }
          const valuesMerge = responseService.rows.reduce((values: any, row: any) => { values.push(row[myFilter.field].toLowerCase()); return values; }, [])
          responseRelatedTable.response.rows = responseRelatedTable.response.rows.filter((row: any) => {
            const matchRow = valuesMerge.includes(row[myFilter.interpretValue ? myFilter.interpretValue.split('_')[1] : myFilter.value.split('_')[1]].toLowerCase());
            if (matchRow) {
              responseFinally.data.map(x => {
                if (x[relatedTable]) {
                  x[relatedTable] = x[relatedTable].filter(newRow => newRow[myFilter.interpretValue ? myFilter.interpretValue.split('_')[1] : myFilter.value.split('_')[1]].toLowerCase() == row[myFilter.interpretValue ? myFilter.interpretValue.split('_')[1] : myFilter.value.split('_')[1]].toLowerCase());
                  if (x[relatedTable].length == 0)
                    x[relatedTable].push(row);
                }
                else {
                  x[relatedTable] = [];
                  x[relatedTable].push(row);
                }
              });
            }
            return matchRow;
          });

          responseRelatedTable.response.rows.forEach(lastRow => {
            responseFinally.data.map(x => {
              responseService.rows.forEach(currentRow => {
                if (responseFinally.tableReference == relatedTable) {
                  if (x[myFilter.interpretValue ? myFilter.interpretValue.split('_')[1] : myFilter.value.split('_')[1]].toLowerCase() == currentRow[myFilter.field].toLowerCase()) {
                    if (!Array.isArray(x[tableName]))
                      x[tableName] = []
                    x[tableName].push(currentRow);
                  }
                } else if (Array.isArray(x[relatedTable])) {
                  x[relatedTable].map(rowExpanded => {
                    if (lastRow[myFilter.interpretValue ? myFilter.interpretValue.split('_')[1] : myFilter.value.split('_')[1]].toLowerCase() == currentRow[myFilter.field].toLowerCase()) {
                      if (!Array.isArray(rowExpanded[tableName]))
                        x[tableName] = []
                      x[tableName].push(currentRow);
                    }
                  })
                } else {
                  if (x[relatedTable][myFilter.interpretValue ? myFilter.interpretValue.split('_')[1] : myFilter.value.split('_')[1]].toLowerCase() == currentRow[myFilter.field].toLowerCase()) {
                    if (!Array.isArray(x[tableName]))
                      x[tableName] = []
                    x[tableName].push(currentRow);
                  }
                }
              })
            });
          })
          const response = responseService.rows;
          results.push({ as, tableName, response: { total: response.length, rows: this.eliminarDuplicados(response) } });
          await expandEntities(entity.nodeEntities);
        }));
      };

      await Promise.all(query.entities.map(async (entity: any) => {
        const { filter, tableName, as } = entity;
        if (!results.some(x => x.tableName == tableName)) {
          const response = await this.localDBManagerService.getAllData(tableName, pageConfig, filter, null);
          if (!response) throw new Error("Error en la respuesta"); // Puedes ajustar esto según tus necesidades
          results.push({ as, tableName, response });
          responseFinally.tableReference = tableName;
          responseFinally.data = response.rows;
          if (entity.nodeEntities)
            await expandEntities(entity.nodeEntities);
        } else {
          const lastResponse = results.find(x => x.tableName == tableName);
          // Hacer algo con lastResponse si es necesario
        }
      }));

      for (let i = 0; i < responseFinally.data.length; i++) {
        let newResponse = {};
        await Promise.all(query.fields.map(async (field: any) => {
          if (field.defaultValue || field.defaultValue == "") {
            newResponse[field.as] = field.defaultValue;
          } else {
            const tableResponse = results.find((x) => x.as == field.tableAlias);
            if (responseFinally.tableReference == tableResponse.tableName)
              newResponse[field.as] = responseFinally.data[i][field.field];
            else
              newResponse[field.as] = responseFinally.data[i][tableResponse.tableName][0][field.field];
          }
        }));
        response.push(newResponse);
      }

      return [response];
    } catch (error) {
      return Promise.reject(error);
    }
  }


}
