import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { FormsComponent } from '@components/forms/forms.component';

declare var kendo: any;

@Component({
  selector: 'app-percentage',
  templateUrl: './percentage.component.html',
  styleUrls: ['./percentage.component.scss']
})
export class PercentageComponent implements OnInit, AfterViewInit {

  @Input() field: any;
  @Input() value: any;
  @Input() form: any;
  @Input() inputClass: string;
  @Input() errors: any;

  formSubmitted = false;
  innerForm: UntypedFormGroup;

  public options: any = {};
  tools: any;

  countRepeatFormat: any;
  letterFormat = "#";
  numerictextbox: any;

  constructor(
    private formsComponent: FormsComponent
  ) {
    this.innerForm = new UntypedFormGroup({
      field: new UntypedFormControl(),
    });
    this.countRepeatFormat = 0;
    this.numerictextbox = 0;
  }

  ngAfterViewInit() {
    if (this.field.required == false) {
      this.innerForm = new UntypedFormGroup({
        field: new UntypedFormControl(this.field.value.val, [
          Validators.minLength(this.field.minimumValue),
          Validators.maxLength(this.field.maximumValue)
        ])
      });
    }
  }

  ngOnInit(): void {
    if (this.field.required == true) {
      this.innerForm = new UntypedFormGroup({
        field: new UntypedFormControl(this.field.value.val, [
          Validators.required,
          Validators.minLength(this.field.minimumValue),
          Validators.maxLength(this.field.maximumValue)
        ])
      });
    }

    if (!this.value && this.field.defaultValue) {
      this.value = this.field.defaultValue;
    }

    this.formsComponent.checkForm(this.field.campoId, this.innerForm.invalid);

    this.field.value.val == "" ? this.field.value.val = 0 : this.field.value.val = this.field.value.val;

    this.countRepeatFormat = this.field.scala;
    this.field.numericFormat = `##.${this.letterFormat.repeat(this.countRepeatFormat)} \\%`;

    this.tools = {
      min: this.field.minimumValue,
      max: this.field.maximumValue,
      format: this.field.numericFormat,
      required: this.field.required,
      disabled: this.field.disabled || !this.field.IsEditable,
      hidden: this.field.show,
      decimals: this.field.scala > 0 ? this.field.scala : 0,
      round: false,
      restrictDecimals: this.field.scala > 0 ? true : false,
      step: this.field.scala > 0 ? 1 / Math.pow(10, this.field.scala) : 1,
      spin: (spin: any) => {
        this.field.value.val = spin.sender.value();
        this.change(this.field);
      }
    };

    if (!this.value.val && this.field.defaultValue) {
      this.value.val = this.field.defaultValue;
    }

    setTimeout(() => {
      var percentage = kendo.jQuery('#' + this.field.campoId).data("kendoNumericTextBox");
      if (!percentage) {
        kendo.jQuery(`#${this.field.campoId}`).kendoNumericTextBox(this.tools).width("100%");
      } else {
        var origin = percentage.element.show();
        origin.insertAfter(percentage.wrapper);

        percentage.destroy();
        percentage.wrapper.remove();

        kendo.jQuery(`#${this.field.campoId}`).kendoNumericTextBox(this.tools).width("100%");
      }

      this.formsComponent.applyUIRules(this.field, this.value);
    }, 1000);
  }

  change($event?: any) {
    var exec = 0;
    this.actionFormula(exec);
    this.formsComponent.setValueFieldForm(this.field.campoId, this.field);
    this.formsComponent.applyUIRules(this.field, this.value);
    this.formsComponent.fireEvents('ValueChanged', this.field.events, {
      value: this.value.val,
      field: this.field
    });
    this.formsComponent.checkForm(this.field.campoId, this.value.val ? false : this.innerForm.invalid);
  }

  actionFormula(exec: any) {
    exec = 1;
  }

}
