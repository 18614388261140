import { Injectable } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import PouchDB from 'pouchdb';

import { PopupComponent } from '@components/popup/popup.component';
// import cordovaSqlitePlugin from 'pouchdb-adapter-cordova-sqlite';
import { RootScopeService } from '@shared/root-scope.service';
import { ProxyConfigApiService } from './proxy-config-api.service';
import backandGlobal from '@env/env';
import { AppContextService } from './app-context.service';
import { OnlineWorkerService } from './online-worker.service';
import { OfflineWorkerService } from './offline-worker.service';
import { AttachmentsService } from './attachments.service';
import { IGlobalEvent } from '../interfaces/IGlobalEvent';
import  swal from 'sweetalert2'
import { ProxyTxApiService } from '@services/proxy-tx-api.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { v4 as uuidv4 } from 'uuid';
import { Router } from '@angular/router';
import { ILFCall } from '../interfaces/ILFCall';
import { NotificationsService } from './notifications.service';
import { Constants } from '@common/constants';

@Injectable({
  providedIn: 'root'
})
export class GlobalEventsService {

  public appState: string;
  stateApp: boolean;
  stateOnline: boolean;
  configStateApp: boolean;
  urlApiConfig: string;

  intervalSync: NodeJS.Timeout;
  intervalDelete: NodeJS.Timeout;
  Swal: any;
  
  private popupComponent!: PopupComponent;

  constructor(
    private modalService: NgbModal,
    private toastr: ToastrService,
    private $rs: RootScopeService,
    private proxyConfigApiService: ProxyConfigApiService,
    private appContext: AppContextService,
    private attachmentService: AttachmentsService,
    private offlineWorker: OfflineWorkerService,
    private onlineWorker: OnlineWorkerService,
    private proxyTxApiService: ProxyTxApiService,
    private http: HttpClient,
    private router: Router,
    private notificationService: NotificationsService    
  ) {
    this.urlApiConfig = backandGlobal.url;
    this.initStateApp();
    this.initMyServices();
    this.Swal = swal;
  }

  initMyServices() {
    var myService = this;
    myService.evalEvent = (implementation: any, e: any) => {
      var functions = `var toastr = myService.toastr; var openCustomModal = myService.openCustomModal; var loadOfflineEntities = myService.loadOfflineEntities;
      var getUser = myService.getUser;var getNewId = myService.getNewId;var goLocation = myService.goLocation;var sendEmail = myService.sendEmail;var execDB = myService.execDB;var execQuery = myService.execQuery;var execSP = myService.execSP;var closeModal = myService.closeModal;var sendSMS = myService.sendSMS;var sendPush = myService.sendPush;var execLF = myService.execLF;var execTX = myService.execTX; var Swal = myService.Swal;`;

      var wrapper = `try { ${implementation} } catch (error) { console.error("Error: " + error); }`;

      try {
        eval(functions + wrapper);
        if (e?.cancel !== undefined) return !e.cancel;
        else return true;
      } catch (error) {
        var message = Constants.MESSAGES.ErrorRules;
        this.notificationService.notificationApp('error',`${message} ${error}`);
      }
    }

    myService.fireEvents = (eventName: any, events: any, param: any) => {
      var result = true;
      if (!events) return result;
      let generalEvents = events.filter((event: any) => {
        return event.EventTypeName == 'General' || event.EventTypeName == 'GlobalGeneral';
      });
      var implementations = events.filter((event: any) => {
        return event.EventTypeName == eventName;
      });
      implementations = generalEvents.concat(implementations)
      let stringImplementtions = '';
      for (var i in implementations) {
        var impl = implementations[i];
        stringImplementtions += impl.Implementation;
      }
      try {
        result = this.evalEvent(stringImplementtions, param);
      } catch (error) {
        var message = Constants.MESSAGES.ErrorRules;
        this.notificationService.notificationApp('error',`${message} ${error}`);
      }
      return result;
    }

    myService.openCustomModal = (config: any, done: any, cancel: any) => {
      if (config) {
        var modalTemplate = '', headerTemplate = '', bodyTemplate = '', footerTemplate = '';

        if (config.htmlTemplate) {
          // Se abre una popup con el html inyectado
          if (config.headerTemplate) {
            headerTemplate = `<div class="modal-header">
              ${config.headerTemplate}
              <button type="button" id="btn-close" class="close" aria-label="Close" (click)="close()">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>`;
          }

          if (config.bodyTemplate) {
            let classModal = `modal-body ${config.scrollable ? 'modal-body-scrollable' : ''}`;
            bodyTemplate = `<div class="${classModal}">
              ${config.bodyTemplate}
            </div>`;
          }

          if (config.footerTemplate) {
            footerTemplate = `<div class="modal-footer">
              ${config.footerTemplate}
              <br>`;
            if (config.showBtnsFooter) {
              footerTemplate += `<div class="col-md-12 text-center">
                <button class="btn btn-default active" type="button" id="btn-ok" (click)="ok()">Aceptar</button>
                <button class="btn btn-default active-color" type="button" id="btn-cancel" (click)="cancel()">Cancelar</button>
              </div>`;
            }

            footerTemplate += `</div>`;
          }

          modalTemplate = `${headerTemplate}
          ${bodyTemplate}
          ${footerTemplate}`;
        } else {
          // Se abre una popup con un formulario dependiendo de la configura
          modalTemplate = `<div class="modal-body" style="padding: 0px !important; margin-top: -38px; margin-left: -9px; margin-right: -9px; margin-bottom: -19px; min-height:100px">
            <div *ngIf="!hasHtmlTemplate"> <app-forms [viewName]="viewName" [rowId]="rowId" [entityId]="entityId" [isDetails]="isDetails" [entityParentId]="entityParentId" [parent]="parent" [parentRules]="parentRules" [item]="dataToSubmit" ></app-forms></div>
        </div>`;
        }

        var modalInstance = this.modalService.open(PopupComponent, {
          animation: true,
          size: config.size ? config.size : 'lg',
          scrollable: config.scrollable ? config.scrollable : false,
          centered: config.centered ? config.centered : false,
          backdrop: 'static',
          keyboard: config.keyboard ? config.keyboard : true
        });

        modalInstance.componentInstance.modalTemplate = modalTemplate;
        modalInstance.componentInstance.htmlTemplate = modalTemplate;

        modalInstance.componentInstance.done = done;
        modalInstance.componentInstance.cancelModal = cancel;

        modalInstance.result.then(done).catch(cancel);

        return modalInstance;
      }
    }

    myService.loadOfflineEntities = (entities?: any) => {
      this.initOfflineEnviroment(entities);
    }

    myService.getUser = () => {
      return JSON.parse(sessionStorage.LappizUser);
    }

    myService.getNewId = () => {
      return uuidv4();
    }

    myService.goLocation = (url: string) => {
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        location.assign(url);
      });
    }

    myService.sendEmail = (smtpsender: string, to: any, subject: any, text: any, html: any, attachments: any[] = [], cc: any[] = [], bcc: any[] = []) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('Authorization')
        })
      };

      var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/utiles/sendEmail`;
      var dataEmail = JSON.stringify({ smtpsender: smtpsender, to: to, subject: subject, text: text, html: html, attachments: attachments, cc: cc, bcc: bcc, parameters: { aType: "sendMail", environment: `${backandGlobal.environment}` } });

      return this.http.post(url, dataEmail, httpOptions).toPromise();
    }

    myService.execDB = (query: string, queryNoSQL?: any) => {
      if (backandGlobal.stateOnline) {
        return this.proxyTxApiService.execDB(query);
      } else {
        return this.proxyTxApiService.execDB(queryNoSQL);
      }
    }

    myService.execQuery = (query: string, queryNoSQL?: any) => {
      return myService.execDB(query, queryNoSQL);
    }

    myService.execSP = (spname: string, params: any[]) => {
      var command = `exec ${spname}`;
      if (params && params.length > 0) {
        command += ` ${params[0]}`;
        for (var i = 1; i < params.length; i++) {
          var element = params[i];
          command += `, ${element}`;
        }
      }

      return myService.execDB(command);
    }

    
    myService.closeModal = (data: any) => {
      this.popupComponent.closeModal(this, data);
    }

    myService.sendSMS = (destination: string, message: string) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('Authorization')
        })
      };
      var data = {
        "message": message,
        "destination": destination
      }
      var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/utiles/sendSMS`;
      var dataSend = JSON.stringify({ data: data, parameters: { aType: "sendSMS", environment: `${backandGlobal.environment}` } });
      return this.http.post(url, dataSend, httpOptions).toPromise();
    }

    myService.sendPush = (usersId: string, data: any) => {
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('Authorization')
        })
      };
      var urlTx = `${backandGlobal.url}/api/notification/getAllowNotification`;
      this.proxyConfigApiService.getSuscriptions(usersId, urlTx).then(pushSuscription => {
        var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/utiles/pushNotification`;
        var dataNotification = JSON.stringify({ pushSuscription: pushSuscription, data: data, parameters: { aType: "pushNotification", environment: `${backandGlobal.environment}` } });
        return this.http.post(url, dataNotification, httpOptions).toPromise();
      }).catch(err => {
        console.log(err);
        this.notificationService.notificationApp('error',Constants.MESSAGES.FailNotification);
        return err;
      })
    }

    myService.execLF = (config: ILFCall) => {
      if (!config.nameFunction) return { status: 400, message: 'el campo "nameFunction" es requerido' };
      if (!config.lappizFunctionId) return { status: 400, message: 'el campo "lappizFunctionId" es requerido' };
      const method = config.method || 'post';
      const httpOptions = {
        headers: new HttpHeaders({
          'Content-Type': 'application/json',
          Authorization: localStorage.getItem('Authorization') || ''
        })
      };
      var codeApp = !backandGlobal.currentApp ?  !!sessionStorage.tempApp ? sessionStorage.tempApp : sessionStorage.workspace : backandGlobal.currentApp.name;
      var url = `${backandGlobal.api2}/${codeApp}.api/api/functions/${config.nameFunction}`;

      var opt = {
        parameters: {
          userId: sessionStorage.userId,
          aType: "lappizFunction",
          pType: "Execute",
          lappizFunctionId: config.lappizFunctionId,
          environment: `${backandGlobal.environment}`
        }
      }
      if (config.body) {
        let keys = Object.keys(config.body)
        keys.forEach(key => {
          opt[key] = config.body[key];
        })
      }
      return this.http[method.toLowerCase()](url, opt, httpOptions).toPromise();
    }

    myService.execTX = (config): Promise<any> => {
      const { dataItem, entity, Id } = config;
      return new Promise(async (resolve, reject) => {
        try {
          let url = `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api/lappiz/${entity}`;
          let method = 'post';
          if (Id) {
            url = `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api/lappiz/${entity}/${Id}`
            method = 'update';
          }
          let response = await myService.proxyTxApiService.operationTxApi(url, JSON.stringify(dataItem), method, backandGlobal.stateOnline, entity)
          if (backandGlobal.stateOnline === false) {
            var entitiesChanged = localStorage.EntityNamesChanged ? JSON.parse(localStorage.EntityNamesChanged) : [];
            entitiesChanged.filter((entityName) => entityName === entity).length == 0 ? entitiesChanged.push(entity) : '';
            localStorage.EntityNamesChanged = JSON.stringify(entitiesChanged);
          }
          resolve(response);
        } catch (error) {
          reject(error);
        }
      })
    };
  }
  initStateApp() {
    this.appContext.getAppState().then((context: any) => {
      var stateAppContext = context.stateApp;

      this.stateApp = stateAppContext;
      this.configStateApp = backandGlobal.stateConfig;

      this.appState = this.stateApp ? 'Online' : 'Offline';

      if (this.stateApp === false) {
        this.stateOnline = false;
      } else {
        this.stateOnline = true;
      }
    }).catch((error: any) => {
      console.log(`Ha ocurrido un error consultando el estado de la aplicación`);
    });
  }

  orderEntities(a: any, b: any) {
    if (a.Alias > b.Alias) {
      return 1;
    }
    if (a.Alias < b.Alias) {
      return -1;
    }
    return 0;
  }

  filterNodeEntities(nodeEntities, entities) {
    return nodeEntities.filter(x => {
      const nameEntity = x.Alias;
      const matchingEntity = entities.find(y => y.Alias === nameEntity);
      if (matchingEntity) {
        x.NodeEntities = this.filterNodeEntities(x.NodeEntities, entities);
        // Agrega la propiedad filter al objeto x solo si existe y.filter en el objeto correspondiente en entities.
        x.filter = matchingEntity.filter !== undefined ? matchingEntity.filter : null;
        return true; // Mantén el objeto en entitiesOffline.
      }
      return false; // Descarta el objeto de entitiesOffline.
    });
  }

  async getEntitiesOffline(entities?: any) {
    let workspaceId = sessionStorage.getItem("workspaceId");
    let entitiesOffline = await this.proxyConfigApiService.getallentitiesoffline(`${this.urlApiConfig}/api/getallentitiesoffline/${workspaceId}`);
    if (entities) {
      entitiesOffline = entitiesOffline.filter(x => {
        const nameEntity = x.Alias;
        const matchingEntity = entities.find(y => y.Alias === nameEntity);
        if (!matchingEntity) return false;
        if (matchingEntity.expandingEntities === false) {
          x.NodeEntities = [];
          return true;
        } else if (matchingEntity) {
          x.NodeEntities = this.filterNodeEntities(x.NodeEntities, entities);
          // Agrega la propiedad filter al objeto x solo si existe y.filter en el objeto correspondiente en entities.
          x.filter = matchingEntity.filter !== undefined ? matchingEntity.filter : null;
          return true; // Mantén el objeto en entitiesOffline.
        }
        return false; // Descarta el objeto de entitiesOffline.
      });
    }
    return entitiesOffline.sort(this.orderEntities);;
  }

  async databasesSynchronizedOk(entities: any) {
    try {
      const dbCurrent = new PouchDB(`bd_AppContext`);
      const dbInfo = await dbCurrent.info();
      if (!dbInfo) return entities;
      const responseData: any = await dbCurrent.get('synchronization_information');
      if (responseData?.lastSynchronization) {
        for (const entity of entities) {
          entity.filter = this.setFilterByDate(entity, responseData.lastSynchronization);
        }
      }
    } catch (error) {
      return entities;
    }
    return entities;
  }

  setFilterByDate(entity: any, date: string) {
    let newFilter = {
      logic: 'and',
      filters: [
        {
          logic: 'or',
          filters: [
            { field: `[${entity.Alias}].[Edited_date]`, operator: 'gt', value: date },
            { field: `[${entity.Alias}].[Created_date]`, operator: 'gt', value: date },
          ],
        }
      ]
    };
    if (entity?.filter && entity?.filter?.filters) {
      newFilter.filters.push(entity.filter);
    }
    return newFilter;
  }

  async initOfflineEnviroment(entities?: any) {
    if (backandGlobal.stateOnline === true) {
      var dbEntitiesSync = [], dbEntitiesDeleted = [], dbEntitiesSQLite = [], dbEntitiesReplicated = [], dbEntitiesForDelete = [], dbEntitiesForDeleted = [];
      this.notificationService.notificationApp('info',Constants.MESSAGES.SyncData, 'Atención!', {
        closeButton: false, extendedTimeOut: 0, timeOut: 3000
      });
      var entitiesOffline = await this.getEntitiesOffline(entities ? entities : null);
      entitiesOffline = await this.databasesSynchronizedOk(entitiesOffline);
      this.onlineWorker.executeOnlineWorker(entitiesOffline, JSON.stringify(backandGlobal), localStorage.getItem('Authorization'), sessionStorage.getItem('tenantId')).then((data: any) => {

        if (data) {
          for (let i = 0; i < entitiesOffline.length; i++) {
            var entity = entitiesOffline[i].Alias;
            dbEntitiesSync[i] = new PouchDB(`bd_${entity}`);
          }

          Promise.all(dbEntitiesSync).then((response: any) => {
            this.notificationService.notificationApp('success',Constants.MESSAGES.SuccessSyncData, '', {
              timeOut: 0, extendedTimeOut: 0, closeButton: true
            });
          });
        } else {
          this.notificationService.notificationApp('error',Constants.MESSAGES.ErrorSyncData, '', {
            timeOut: 0, extendedTimeOut: 0, closeButton: true
          });
        }
      }).catch((err: any) => console.log('ErrorDataSyncOffline', err));
      this.notificationService.notificationApp('info',`El estado de la data y la configuración de la aplicación es: ${this.appState}`, '', {
        closeButton: false, extendedTimeOut: 0, timeOut: 3000
      });

    } else {
      this.notificationService.notificationApp('warning',Constants.MESSAGES.NeedOnline, '', {
        closeButton: false, extendedTimeOut: 0, timeOut: 3000
      });
    }
  }

  switchStateApp(isOnline: boolean): void {
    this.appContext.switchAppState();
    this.stateOnline = !isOnline;
    this.stateApp = isOnline;
    // backandGlobal.stateOnline = !isOnline;
  }

  evalEvent(implementation: any, e: any) {
    var functions = `var toastr = myService.toastr; var openCustomModal = myService.openCustomModal; var loadOfflineEntities = myService.loadOfflineEntities;
var getUser = myService.getUser;var getNewId = myService.getNewId;var goLocation = myService.goLocation;var sendEmail = myService.sendEmail;var execDB = myService.execDB;var execQuery = myService.execQuery;var execSP = myService.execSP;var closeModal = myService.closeModal;var sendSMS = myService.sendSMS;var sendPush = myService.sendPush;var execLF = myService.execLF;var execTX = myService.execTX; var Swal = myService.Swal;`;
    var wrapper = `try { ${implementation} } catch (error) { console.error("Error: " + error); }`;

    try {
      eval(functions + wrapper);
      if (e?.cancel !== undefined ) return !e.cancel;
      else return true;
    } catch (error) {
      var message = this.$rs.$rootScope.lang == 'es' ? 'Error evaluando reglas de negocio. ' : this.$rs.$rootScope.lang == 'en' ? 'Error evaluating business rules. ' : 'Error evaluando reglas de negocio. ';
      this.notificationService.notificationApp('error',`${message} ${error}`);
    }
  }

  fireEvents(eventName: any, events: Array<IGlobalEvent>, param: any) {
    var result = true;
    if (!events) return result;
    let generalEvents = events.filter((event: IGlobalEvent) => {
      return event.EventTypeName == 'General' || event.EventTypeName == 'GlobalGeneral';
    });
    var implementations = events.filter((event: IGlobalEvent) => {
      return event.EventTypeName == eventName;
    });
    if (implementations.length == 0) return result;
    implementations = generalEvents.concat(implementations)
    let stringImplementtions = '';
    for (var i in implementations) {
      var impl = implementations[i];
      stringImplementtions += impl.Implementation;
    }
    try {
      result = this.evalEvent(stringImplementtions, param);
    } catch (error) {
      var message = Constants.MESSAGES.ErrorRules;
      this.notificationService.notificationApp('error',`${message} ${error}`);
    }
    return result;
  }

  openCustomModal(config: any, done: any, cancel: any) {
    if (config) {
      var modalTemplate = '', headerTemplate = '', bodyTemplate = '', footerTemplate = '';

      if (config.htmlTemplate) {
        // Se abre una popup con el html inyectado
        if (config.headerTemplate) {
          headerTemplate = `<div class="modal-header">
            ${config.headerTemplate}
            <button type="button" id="btn-close" class="close" aria-label="Close" (click)="close()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>`;
        }

        if (config.bodyTemplate) {
          let classModal = `modal-body ${config.scrollable ? 'modal-body-scrollable' : ''}`;
          bodyTemplate = `<div class="${classModal}">
            ${config.bodyTemplate}
          </div>`;
        }

        if (config.footerTemplate) {
          footerTemplate = `<div class="modal-footer">
            ${config.footerTemplate}
            <br>`;
          if (config.showBtnsFooter) {
            footerTemplate += `<div class="col-md-12 text-center">
              <button class="btn btn-default active" type="button" id="btn-ok" (click)="ok()">Aceptar</button>
              <button class="btn btn-default active-color" type="button" id="btn-cancel" (click)="cancel()">Cancelar</button>
            </div>`;
          }

          footerTemplate += `</div>`;
        }

        modalTemplate = `${headerTemplate}
        ${bodyTemplate}
        ${footerTemplate}`;
      } else {
        // Se abre una popup con un formulario dependiendo de la configura
        modalTemplate = `<div class="modal-body" style="padding: 0px !important; margin-top: -38px; margin-left: -9px; margin-right: -9px; margin-bottom: -19px; min-height:100px">
          <div *ngIf="!hasHtmlTemplate"> <app-forms [viewName]="viewName" [rowId]="rowId" [entityId]="entityId" [isDetails]="isDetails" [entityParentId]="entityParentId" [parent]="parent" [parentRules]="parentRules" [item]="dataToSubmit" ></app-forms></div>
      </div>`;
      }

      var modalInstance = this.modalService.open(PopupComponent, {
        animation: true,
        size: config.size ? config.size : 'lg',
        scrollable: config.scrollable ? config.scrollable : false,
        centered: config.centered ? config.centered : false,
        backdrop: 'static',
        keyboard: config.keyboard ? config.keyboard : true
      });

      modalInstance.componentInstance.modalTemplate = modalTemplate;
      modalInstance.componentInstance.htmlTemplate = modalTemplate;

      modalInstance.componentInstance.done = done;
      modalInstance.componentInstance.cancelModal = cancel;

      modalInstance.result.then(done).catch(cancel);

      return modalInstance;
    }
  }

  loadOfflineEntities(entities?: any) {
    this.initOfflineEnviroment(entities);
  }

  getUser() {
    return JSON.parse(sessionStorage.LappizUser);
  }

  getNewId() {
    return uuidv4();
  }

  goLocation(url: string) {
    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      location.assign(url);
    });
  }

  sendEmail(smtpsender: string, to: any, subject: any, text: any, html: any, attachments: any[] = [], cc: any[] = [], bcc: any[] = []) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('Authorization')
      })
    };

    var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/utiles/sendEmail`;
    var dataEmail = JSON.stringify({ smtpsender: smtpsender, to: to, subject: subject, text: text, html: html, cc: cc, bcc: bcc, attachments: attachments, parameters: { aType: "sendMail", environment: `${backandGlobal.environment}` } });

    return this.http.post(url, dataEmail, httpOptions).toPromise();
  }

  execDB(query: string, queryNoSQL?: any) {
    if (backandGlobal.stateOnline) {
      return this.proxyTxApiService.execDB(query);
    } else {
      return this.proxyTxApiService.execDB(queryNoSQL);
    }
  }

  execQuery(query: string, queryNoSQL?: any) {
    return this.execDB(query, queryNoSQL);
  }

  execSP(spname: string, params: any[]) {
    var command = `exec ${spname}`;
    if (params && params.length > 0) {
      command += ` ${params[0]}`;
      for (var i = 1; i < params.length; i++) {
        var element = params[i];
        command += `, ${element}`;
      }
    }

    return this.execDB(command);
  }
  sendSMS = (destination: string, message: string) => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('Authorization')
      })
    };
    var data = {
      "message": message,
      "destination": destination
    }
    var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/utiles/sendSMS`;
    var dataSend = JSON.stringify({ data: data, parameters: { aType: "sendSMS", environment: `${backandGlobal.environment}` } });
    return this.http.post(url, dataSend, httpOptions).toPromise();
  }

  sendPush = (usersId: string, data: any) => {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('Authorization')
      })
    };

    var urlTx = `${backandGlobal.url}/api/notification/getAllowNotification`;
    this.proxyConfigApiService.getSuscriptions(usersId, urlTx).then(pushSuscription => {
      var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/utiles/pushNotification`;
      var dataNotification = JSON.stringify({ pushSuscription: pushSuscription, data: data, parameters: { aType: "pushNotification", environment: `${backandGlobal.environment}` } });
      return this.http.post(url, dataNotification, httpOptions).toPromise();
    }).catch(err => {
      console.log(err);
      this.notificationService.notificationApp('error',Constants.MESSAGES.FailNotification);
      return err;
    });
  }

  execLF(config: ILFCall) {
    if (!config.nameFunction) return { status: 400, message: 'el campo "nameFunction" es requerido' };
    if (!config.lappizFunctionId) return { status: 400, message: 'el campo "lappizFunctionId" es requerido' };
    const method = config.method || 'post';
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: localStorage.getItem('Authorization') || ''
      })
    };

    var url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/functions/${config.nameFunction}`;

    var opt = {
      parameters: {
        userId: sessionStorage.userId,
        aType: "lappizFunction",
        pType: "Execute",
        lappizFunctionId: config.lappizFunctionId,
        environment: `${backandGlobal.environment}`
      }
    }
    if (config.body) {
      let keys = Object.keys(config.body)
      keys.forEach(key => {
        opt[key] = config.body[key];
      })
    }
    return this.http[method.toLowerCase()](url, opt, httpOptions).toPromise();
  }
  execTX(config): Promise<any> {
    const { dataItem, entity, Id } = config;
    return new Promise(async (resolve, reject) => {
      try {
        let url = `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api/lappiz/${entity}`;
        let method = 'post';
        if (Id) {
          url = `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api/lappiz/${entity}/${Id}`
          method = 'update';
        }
        let response = await this.proxyTxApiService.operationTxApi(url, JSON.stringify(dataItem), method, backandGlobal.stateOnline, entity)
        if (backandGlobal.stateOnline === false) {
          var entitiesChanged = localStorage.EntityNamesChanged ? JSON.parse(localStorage.EntityNamesChanged) : [];
          entitiesChanged.filter((entityName) => entityName === entity).length == 0 ? entitiesChanged.push(entity) : '';
          localStorage.EntityNamesChanged = JSON.stringify(entitiesChanged);
        }
        resolve(response);
      } catch (error) {
        reject(error);
      }
    })
  };

  closeModal(data: any) {
      this.popupComponent.closeModal(this, data);
    }

    private getImplemetationCode(functions: string, wrapper: string): string {
      return `(async function(
          LAssert,
          LDelay,
          LExcel,
          LForm,
          LHttp,
          LScript,
          LToastr,
          LTX,
          LType,
          LUser,
          LMath
        ) { ${functions + wrapper} })
        (
          this.LAssert,
          this.LDelay,
          this.excelService,
          this.LForm,
          this.httpService,
          this.LScript,
          this.toastr,
          this.LTX,
          this.LType,
          this.LUser,
          this.mathService
        );
      `
    }

}
