<div class="row" [hidden]="!isReady || !isReadyEvent" style="height: 100%;">
  <div class="col-md-12 stretch-card p-0">
    <div class="card">
      <div class="card-body card-body-lappiz">
        <div *ngIf="isFormRegister">
          <img [src]="logoApp" class="img-logo-register">
        </div>
        <div class="header-section my-2">
          <div class="title-section d-flex align-items-center" style="column-gap: 8px">
            <img class="btn-back" src="../../../assets/images/flecha-izquierda.png" (click)="cancelar()" *ngIf="showCancel" title="Volver" [id]="appViewId + '_cancel'">
            <h3>{{ configInfo.title }} {{ nameBack }}</h3>
          </div>
          <div class="actions-form-header d-flex">
            <div *ngFor="let action of configInfo.Actions">
              <div [ngSwitch]="action.Type">
                <button class="btn btn-outline-primary" *ngSwitchCase="'Save'" [hidden]="!allowSave && !showSave"
                  (click)="submit(1, action)" [title]="nameSave"
                  [attr.disabled]="!formValid ? true : null || !action.isEditable ? true : null"
                  [ngClass]="!formValid || !action.isEditable ? 'invalid' : ''" [id]="appViewId + '_save'">
                  <i class="{{ iconSave }}"></i>
                </button>
                <button class="btn btn-outline-primary" *ngSwitchCase="'Save'"
                  [hidden]="!allowSave && !showSaveContinue" (click)="submit(2, action)" [title]="nameSaveContinue"
                  [attr.disabled]="!formValid ? true : null || !action.isEditable ? true : null"
                  [ngClass]="!formValid || !action.isEditable ? 'invalid' : ''" [id]="appViewId + '_saveAndNew'">
                  <i class="fa fa-plus-square"></i>
                </button>
                <button class="btn btn-outline-primary" *ngSwitchCase="'Save'"
                  [hidden]="!allowSave && !showSaveEdit" (click)="submit(3, action)" [title]="nameSaveEdit"
                  [attr.disabled]="!formValid ? true : null || !action.isEditable ? true : null"
                  [ngClass]="!formValid || !action.isEditable ? 'invalid' : ''" [id]="appViewId + '_saveAndEdit'">
                  <i class="fa fa-clipboard"></i>
                </button>
              </div>
            </div>
            <button class="btn-basic" (click)="viewHistory()" *ngIf="showHistory && !isNew" title="Historial" [id]="appViewId + '_history'">
              <i class="fa fa-history"></i> Ver Historial
            </button>
            <div *ngIf="actionsMenuOptions" ngbDropdown class="d-inline-block">
              <button class="btn btn-outline-primary" id="moreActions" ngbDropdownToggle [id]="appViewId + '_more'">
                <i class="fa fa-list"></i> Más Acciones
              </button>
              <div ngbDropdownMenu aria-labelledby="moreActions">
                <li *ngFor="let action of configInfo.Actions" class="sub_menu">
                  <div [ngSwitch]="action.Type">
                    <button ngbDropdownItem class="btn-basic" *ngSwitchCase="'WorkFlow'" [hidden]="!action.show"
                      name="{{ action.ActionId }}" (click)="executeWf(action.WorkflowId)" title="{{ action.Name }}"
                      [attr.disabled]="!formValid ? true : null || !action.isEditable ? true : null">
                      <i class="fa {{ action.IconAwesomeAction }}" [id]="action.ActionId"></i>
                      {{ action.Name }}
                    </button>
                    <button ngbDropdownItem class="btn-basic" *ngSwitchCase="'Codigo'" [hidden]="!action.show"
                      name="{{ action.ActionId }}" (click)="actionCode(action)" title="{{ action.Name }}"
                      [attr.disabled]="!formValid ? true : null || !action.isEditable ? true : null">
                      <i class="fa {{ action.IconAwesomeAction }}"></i>
                      {{ action.Name }}
                    </button>
                    <button ngbDropdownItem class="btn-basic" *ngSwitchCase="'NavegarVista'" [hidden]="!action.show"
                      name="{{ action.ActionId }}" (click)="openDirective(action.QueryString, action)"
                      title="{{ action.Name }}"
                      [attr.disabled]="!formValid ? true : null || !action.isEditable ? true : null">
                      <i class="fa {{ action.IconAwesomeAction }}"></i>
                      {{ action.Name }}
                    </button>
                  </div>
                </li>
              </div>
            </div>
          </div>
        </div>

        <form role="form" name="form" novalidate (ngSubmit)="submit()">
          <hr hidden="true">
          <div class="clearfix  {{spaceHeader}}"></div>
          <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav-tabs">
            <li *ngFor="let category of configInfo.categories" [ngbNavItem]=category.catName>
              <a ngbNavLink [hidden]="configInfo.categories.length == 1">
                {{category.catNameTab}}
              </a>
              <ng-template ngbNavContent>
                <div class="d-flex flex-wrap">
                  <div *ngFor="let section of $any(category.sections | keyvalue : returnZero)"
                    [id]="section.value.sectionId" [hidden]="!section.value.show" class="col-md-{{12 / +category.NroColumn}} col-xs-12">
                    <br />
                    <div [id]="section.value.sectionName" [hidden]="!section.value.show"
                    [ngStyle]="section.value.sectionConfiguration ? section.value.sectionConfiguration.styles : null">
                      <div class="form-section-title">{{section.value.sectionName}}</div>
                      <span style="cursor: pointer;" (click)="collapseSection($event, section.value.sectionId, category.categoryConfiguration?.expandOne )" *ngIf="section.value.sectionConfiguration.type == 'collapsible'">
                        <i [id]="section.value.sectionId +'-icon-collapse'" [class]="category.categoryConfiguration?.expandOne ? 'fa fa-chevron-down' : 'fas fa-chevron-up'"></i>
                      </span>
                    </div>
                    <div class="panel-body {{category.categoryConfiguration?.expandOne && section.value.sectionConfiguration?.type == 'collapsible' ? 'oculto': ''}}" [id]="section.value.sectionId +'_panel'" (ngInit)="showSections()">
                      <div class="row">
                        <ng-container *ngFor="let row of section.value.rows">
                          <div id="SectionsFields"
                            class="col-md-{{12 / section.value.NroColumn * field.value.columns }} col-xs-12 form-group"
                            *ngFor="let field of $any(row.fields | keyvalue)" [hidden]="!field.value.show">
                            <div class="col-xs-12">
                              <label class="control-label" [hidden]="field.value.type == 'checkbox' || field.value.type == 'listview' || field.value.type == 'list'">
                                <span [ngClass]="{ 'lappiz-field-required': field.value.required }">
                                  {{field.value.displayName}}
                                </span>
                                <span class="lappiz-field-required" *ngIf="field.value.required">*</span>
                              </label>
                              <div>
                                <div *ngIf="field.value.type == 'carrousel'">
                                  <app-carousel [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-carousel>
                                </div>
                                <div *ngIf="field.value.type == 'checklist'">
                                  <app-checklist [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-checklist>
                                </div>
                                <div *ngIf="field.value.type == 'combobox'">
                                  <app-combobox [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-combobox>
                                </div>
                                <div *ngIf="field.value.type == 'cronometro'">
                                  <app-cronometro [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}">
                                  </app-cronometro>
                                </div>
                                <div *ngIf="field.value.type == 'currency'">
                                  <app-numeric [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-numeric>
                                </div>
                                <div *ngIf="field.value.type == 'customviewPart'">
                                  <app-html-content [field]="field.value" [item]="field.value.dataItem"
                                    title="{{field.value.tooltip}}">
                                  </app-html-content>
                                </div>
                                <div *ngIf="field.value.type == 'date'">
                                  <app-date [format]="'date'" [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-date>
                                </div>
                                <div *ngIf="field.value.type == 'datetime'">
                                  <app-date [format]="'datetime'" [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-date>
                                </div>
                                <div *ngIf="field.value.type == 'detalles'">
                                  <app-details [isNew]="'isNew'" [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-details>
                                </div>
                                <div *ngIf="field.value.type == 'editor'">
                                  <app-editor [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-editor>
                                </div>
                                <div *ngIf="field.value.type == 'email'">
                                  <app-email [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-email>
                                </div>
                                <div *ngIf="field.value.type == 'Entero'">
                                  <app-numeric [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-numeric>
                                </div>
                                <div *ngIf="field.value.type == 'file'">
                                  <app-file [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-file>
                                </div>
                                <div *ngIf="field.value.type == 'formula'">
                                  <app-formula [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-formula>
                                </div>
                                <div *ngIf="field.value.type == 'foto'">
                                  <app-camara [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-camara>
                                </div>
                                <div *ngIf="field.value.type == 'hyperlink'">
                                  <app-link [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-link>
                                </div>
                                <div *ngIf="field.value.type == 'image'">
                                  <app-image [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-image>
                                </div>
                                <div *ngIf="field.value.type == 'map'">
                                  <app-mapa [showMap]="false" [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-mapa>
                                </div>
                                <div *ngIf="field.value.type == 'multiSelect'">
                                  <app-multi-select [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}" [fieldDisabled]="field.disabled" disabled="{{field.disabled}}">
                                  </app-multi-select>
                                </div>
                                <div *ngIf="field.value.type == 'number'">
                                  <app-numeric [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-numeric>
                                </div>
                                <div *ngIf="field.value.type == 'numberWithSeparator'">
                                  <app-numeric [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-numeric>
                                </div>
                                <div *ngIf="field.value.type == 'password'">
                                  <app-password [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-password>
                                </div>
                                <div *ngIf="field.value.type == 'percentage'">
                                  <app-percentage [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}">
                                  </app-percentage>
                                </div>
                                <div *ngIf="field.value.type == 'QRCode'">
                                  <app-qr [showQr]="false" [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-qr>
                                </div>
                                <div *ngIf="field.value.type == 'radio'">
                                  <app-radio [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-radio>
                                </div>
                                <div *ngIf="field.value.type == 'singleSelect'">
                                  <app-single-select [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}">
                                  </app-single-select>
                                </div>
                                <div *ngIf="field.value.type == 'text'">
                                  <app-input [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-input>
                                </div>
                                <div *ngIf="field.value.type == 'textarea'">
                                  <app-textarea class="control-label" [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-textarea>
                                </div>
                                <div *ngIf="field.value.type == 'time'">
                                  <app-time [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-time>
                                </div>
                                <div *ngIf="field.value.type == 'signature'">
                                  <app-signature [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-signature>
                                </div>
                                <div *ngIf="field.value.type == 'list'">
                                  <app-grids [viewName]="field.value.Code" [viewCode]="field.value.campoId" [options]="" [filterOptions]="" [inputStyle]="'inForm'" [buttonGroups]="" [disableEditOnDblClick]="" [entityId]="field.value.TablaId"></app-grids>
                                </div>
                                <div *ngIf="field.value.type == 'listview'">
                                  <app-list [viewName]="field.value.Code" [viewCode]="field.value.campoId" [options]="" [filterOptions]="" [inputStyle]="'inForm'" [buttonGroups]="" [disableEditOnDblClick]="" [entityId]="field.value.TablaId"></app-list>
                                </div>
                                <label *ngIf="field.value.type == 'checkbox'" class="checkbox-inline control-label">
                                  <app-checkbox [field]="field.value" [value]="field.value.value"
                                    [errors]="field.value.errors" title="{{field.value.tooltip}}"></app-checkbox>
                                  <span [ngClass]="{ 'lappiz-field-required': field.value.required }">
                                    {{field.value.displayName}}
                                  </span>
                                  <span class="lappiz-field-required" *ngIf="field.value.required">*</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </ng-container>
                      </div>
                    </div>
                  </div>
                </div>
                <hr hidden="true">
              </ng-template>
            </li>
          </ul>

          <div [ngbNavOutlet]="nav" class="mt-2"></div>

        </form>
      </div>
    </div>
  </div>
</div>

<app-loader-view></app-loader-view>
