import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import * as signalR from '@aspnet/signalr';

import { ProxyTxApiService } from '@services/proxy-tx-api.service';

import backandGlobal from '@env/env';

declare var kendo: any;

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})

export class ChatComponent implements OnInit {

  window: any;
  appViewId: any;
  Contactsuser: any;
  LappizUser: any;
  NameEntity: string;
  noRecords: boolean;
  messageNoRecords: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private proxyTxApiService: ProxyTxApiService
  ) {

    this.activatedRoute.paramMap.subscribe(params => {
      this.appViewId = params.get('appViewId');
    });

    this.Contactsuser = [];
    this.NameEntity = 'Chats';
    this.noRecords = false;
    this.messageNoRecords = '';
    this.LappizUser = JSON.parse(sessionStorage.getItem('LappizUser'));
    this.window = window;

  }

  ngOnInit(): void {
    var viewName: string = "";
    var urlchats: string = "";
    urlchats = `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api/lappiz/get/${sessionStorage.getItem('workspace')}_ChatContacts`;
    viewName = `${sessionStorage.getItem('workspace')}_ChatContacts`;

    var opc = {
      "take": 10, "skip": 0, "page": 1, "pageSize": 10,
      "filter": {
        "logic": "and",
        "filters": [
          {
            "logic": "or",
            "filters": [
              {
                "field": `${viewName}.UserId`,
                "operator": "eq",
                "value": `${sessionStorage.getItem('userId')}`
              }, {
                "field": `${viewName}.ContactId`,
                "operator": "eq",
                "value": `${sessionStorage.getItem('userId')}`
              }
            ]
          }
        ]
      },
      "parameters": { 
        "userId": `${sessionStorage.getItem('userId')}`,
        "pType": "showinmenu", 
        "aType": "ffija", 
        "environment": backandGlobal.environment
      }
    };

    this.proxyTxApiService.getTxData(urlchats, opc, backandGlobal.stateOnline).then((response: any) => {
      this.Contactsuser = response.data.rows.length > 0 ? response.data.rows : [];
      this.noRecords = this.Contactsuser.length > 0 ? false : true;
      this.messageNoRecords = this.noRecords === true ? 'No hay chats disponibles' : '';

      for (var i = 0; i < this.Contactsuser.length; i++) {
        this.Contactsuser[i]["Contact"] = [];
        var opc = {
          "take": 10, "skip": 0, "page": 1, "pageSize": 10,
          "filter": {
            "logic": "and",
            "filters": [
              {
                "field": `${sessionStorage.getItem('workspace')}_Users.Id`,
                "operator": "eq",
                "value": this.Contactsuser[i].ContactId
              }
            ]
          },
          "parameters": { 
            "userId": `${sessionStorage.getItem('userId')}`,
            "pType": "showinmenu", 
            "aType": "ffija", 
            "environment": backandGlobal.environment
          }
        };
        
        viewName = sessionStorage.workspace + "_Users";
        urlchats = `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api/lappiz/get/${sessionStorage.getItem('workspace')}_Users`;
        var j = 0;
        this.proxyTxApiService.getTxData(urlchats, opc, backandGlobal.stateOnline).then((response: any) => {
          this.Contactsuser[j]["Contact"] = response.data.rows[0];
          this.Contactsuser[j]["User"] = [];
          this.Contactsuser[j]["User"] = JSON.parse(sessionStorage.getItem('LappizUser'));
          this.Contactsuser[j]["Contact"].Photo = this.formatPhoto(this.Contactsuser[j]["Contact"].Photo);
          this.Contactsuser[j]["User"].Photo = this.formatPhoto(this.Contactsuser[j]["User"].Photo);
          j++;
        });
      }
    });
  }

  onClose(e: any) {
    var chatWindow = kendo.jQuery("#window").data("kendoWindow");
    chatWindow.destroy();
  }

  formatPhoto = (files: any) => {
    if (files == undefined || files == null || files == "null" || files == "")
      return "assets/images/default-user.png";
    files = files.split(',');
    var file = files[files.length - 1];
    return backandGlobal.URL_API_UPLOAD_IMAGES + file.replace('"', '').replace('"', '').replace('[', '').replace(']', '');
  }

  initChat = (e: any, idUser: string) => {
    $('#windowContainer').append(`<div id="window_${idUser}">
    <div id="chat_${idUser}"></div>
</div>`);

    var chatWindow = kendo.jQuery(`#window_${idUser}`).kendoWindow({
      actions: ["Maximize", "Minimize", "Close"],
      animation: { },
      height: "300px",
      resizable: false,
      title: "Chat " + e.Contact.FullName,
      width: "500px",
      deactivate: function () {
        this.destroy();
      }
    }).data("kendoWindow");

    chatWindow.open();
    chatWindow.center();
    $(`#window_${idUser}`).parent().find('.k-window-action').css('backgroundColor', 'transparent');

    var chat = kendo.jQuery(`#chat_${idUser}`).kendoChat({
      // Each instance of the application will generate a unique username.
      // In this way, the SignalR Hub "knows" who is the user that sends the message
      // and who are the clients that have to receive that message.
      user: {
        name: this.LappizUser.FullName,
        iconUrl: this.formatPhoto(this.LappizUser.photo)
      },
      // This will notify the SignallR Hub that the current client is typing.
      // The Hub, in turn, will notify all the other clients
      // that the user has started typing.
      typingStart: () => {
        var groupName = e.Id;
        this.window.chatHub.invoke("sendTyping", chat.getUser(), groupName);
      },
      // The post handler will send the user data and the typed text to the SignalR Hub.
      // The Hub will then forward that info to the other clients.
      post: (args) => {
        var groupName = e.Id
        this.window.chatHub.invoke("send", chat.getUser(), args.text, groupName);
      }
    }).data("kendoChat");

    // Point to the Hub remote endpoint.
    this.window.chatHub = new signalR.HubConnectionBuilder()
      .withUrl(backandGlobal.urlsignalr)
      .build();

    this.window.chatHub.start().then((data) => {
      this.window.chatHub.invoke("AddToGroup", e.Id);
    }).catch((err) => {
      console.error(err.toString());
    });

    this.window.chatHub.on('typing', function (sender) {
      // Display the typing notification in the Chat.
      chat.renderMessage({ type: 'typing' }, sender);
    });

    this.window.chatHub.on('broadcastMessage', function (sender, message, groupName) {
      var message: any = {
        type: 'text',
        text: message
      };
      // Render the received message in the Chat.
      chat.renderMessage(message, sender);
    });
  }

}
