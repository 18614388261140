import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-signature-grid',
  templateUrl: './signature-grid.component.html',
  styleUrls: ['./signature-grid.component.scss']
})
export class SignatureGridComponent implements OnInit {

  @Input() field: any;
  @Input() value: any;
  @Input() form: any;
  @Input() errors: string;
  @Input() inputClass: string;
  @Input() rowId: string;

  constructor(private activeModal: NgbActiveModal) { }

  ngOnInit(): void { }

  closeDocuments() {
    this.activeModal.close();
  }
}
