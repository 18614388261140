import { Injectable } from '@angular/core';

import { ProxyTxApiService } from '@services/proxy-tx-api.service';
import { ProxyConfigApiService } from '@services/proxy-config-api.service';

import backandGlobal from '@env/env';

@Injectable({
  providedIn: 'root'
})
export class WorkFlowService {

  constructor(
    private proxyTxApiService: ProxyTxApiService,
    private proxyConfigApiService: ProxyConfigApiService
  ) { }

  updateAssignee(taskId: any, user: any): any {
    let urlTx = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/workflows/task/${taskId}/assignee`;
    let data = {
      userId: user
    };

    return this.proxyTxApiService.updateAssignee(urlTx, JSON.stringify(data), backandGlobal.stateOnline);
  }

  postActionWorkFlow(ActionId: any, Entity: any): any {
    let json = JSON.stringify(Entity);
    let request = this.proxyConfigApiService.getConfigDataPost(`${backandGlobal.url}/api/workflow/execute?actionId=${ActionId}`, json);

    return request;
  }

  completeTask(taskId: any, data: any): any {
    var urlTx = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/workflows/task/${taskId}/submit-form`;

    return this.proxyTxApiService.completeTaskTx(urlTx, JSON.stringify(data), backandGlobal.stateOnline);
  }
}
