<form [formGroup]="innerForm">
  <select formControlName="field" [attr.disabled]="!field.IsEditable ? true : null || field.disabled ? true : null"
    (ngModelChange)="changed($event)" [hidden]="!field.inlineEditing && !field.show" name="field" class="{{inputClass}}"
    required="field.required" [(ngModel)]="value.val" [id]="field.campoId">
    <option selected value="undefined">Seleccione un registro</option>
    <option *ngFor="let op of options" [ngValue]="op.value?op.value:op.name">
      {{ op.name }}
    </option>
  </select>
</form>
