import { Injectable } from '@angular/core';

import backandGlobal from '@env/env';

@Injectable({
  providedIn: 'root'
})
export class ContextValuesService {

  urlTxApi: string;

  constructor() { 
    this.urlTxApi = `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api`;
  }

  getLappizUserId() {
    return this.currentLappizUser();
  }

  transformFieldValue(url: string, field: any, value: any) {
    return url.replace(`#getFieldValue('${field.campoId}')#`, value);
  }

  transformODataQuery(query: string) {
    if (query.includes('UrlBase')) {
      let base = query.split('?');
      let conditions = base[1];
      base = base[0].split('/');
      let entity = base[base.length - 1];
      query = `#UrlBase#/lappiz/get/${entity}?${conditions}`;
    }

    let paths = query.split('#');
    let newQuery = '';
    for (let i = 0; i < paths.length; i++) {
      if (paths[i].includes('getFieldValue')) continue;
      newQuery = newQuery + this.getContextValueFilter(paths[i]);
    }

    return newQuery;
  }

  getContextValueFilter(method: string) {
    switch (method.toLocaleLowerCase()) {
      case 'currentuser':
        return this.currentUser();
      case 'urlbase':
        return this.urlBase();
      case 'now':
        return this.now();
      case 'lappizuserid':
        return this.currentLappizUser();
      case 'null':
        return null;
    }

    if (method.includes('sessionStorage.') || method.includes('eval(') || method.includes('localStorage')) {
      if (method.includes('eval(')) {
        method = method.replace('eval(', '');
        method = method.substr(0, method.length - 1);
      }

      return eval(method);
    }

    return method;
  }

  currentLappizUser() {
    return sessionStorage.userId;
  }

  urlBase() {
    return `${this.urlTxApi}`;
  }

  currentUser() {
    return localStorage.getItem('userName');
  }

  now() {
    return new Date().toJSON().slice(0, 10);
  }

}
