<nav
  [class]="(configLayout.web.headerHide || configLayout.web.header == 'InMenu') && configLayout.web.menu == 'HorizontalBottom'  ? 'sidebar sidebar-web-horizontal nav-hide-header-horizontal sidebar-bottom lappiz-bottom' : (configLayout.web.headerHide || configLayout.web.header == 'InMenu')   && configLayout.web.menu == 'HorizontalTop'  ? 'sidebar sidebar-web-horizontal nav-hide-header-horizontal lappiz-top' : configLayout.web.menu == 'HorizontalTop' ? 'sidebar sidebar-web-horizontal' : configLayout.web.menu == 'verticalRight' ? 'sidebar sidebar-web' : 'sidebar'"
  [style]="configLayout.backgroundMenu && configLayout.backgroundMenu != 'Default' ? 'background: ' + configLayout.backgroundMenu: ''">
  <div
    [class]="configLayout.web.menu == 'HorizontalTop' || configLayout.web.menu == 'HorizontalBottom' ? 'sidebar-header sidebar-header-horizontal' : 'sidebar-header'"
    [hidden]="configLayout.web.menuHide && configLayout.web.headerHide"
    style="background: rgb(0 0 0 / 0%)">

    <!--- Logo -->
    <a [class]="configLayout.web.menu == 'HorizontalTop' || configLayout.web.menu == 'HorizontalBottom' ? 'sidebar-brand sidebar-brand-horizontal' : 'sidebar-brand'"
      (click)="goHomeApp()" style="height: inherit;">
      <img [src]="imageApp">
      <!-- <span>UI</span> -->
    </a>

    <!--- Toggler -->
    <div class="sidebar-toggler not-active" #sidebarToggler (click)="toggleSidebar($event)">
      <span [style]="configLayout.colorHeader && configLayout.colorHeader != 'Default' ? 'background: ' + configLayout.colorHeader: ''"></span>
      <span [style]="configLayout.colorHeader && configLayout.colorHeader != 'Default' ? 'background: ' + configLayout.colorHeader: ''"></span>
      <span [style]="configLayout.colorHeader && configLayout.colorHeader != 'Default' ? 'background: ' + configLayout.colorHeader: ''"></span>
    </div>

  </div>
  <div
    [class]="configLayout.web.menu == 'HorizontalTop' || configLayout.web.menu == 'HorizontalBottom'  ? 'sidebar-body sidebar-body-horizontal' : configLayout.web.menuHide && configLayout.web.headerHide ? 'sidebar-body sidebar-body-w0' : 'sidebar-body'"
    (mouseenter)="operSidebarFolded()" (mouseleave)="closeSidebarFolded()" [perfectScrollbar]
    [style]="configLayout.backgroundMenu && configLayout.backgroundMenu != 'Default' ? 'background: ' + configLayout.backgroundMenu + ' !important': ''">

    <!--- Sidemenu start -->


    <ul
      [class]="(configLayout.web.headerHide || configLayout.web.header == 'InMenu')  && configLayout.web.menu == 'HorizontalBottom' ? 'sidebar-nav metismenu sidebar-nav-horizontal hideHeaderHorizontal hideHeaderHorizontal-bottom' : (configLayout.web.headerHide || configLayout.web.header == 'InMenu')  && configLayout.web.menu == 'HorizontalTop' ? 'sidebar-nav metismenu sidebar-nav-horizontal hideHeaderHorizontal hideHeaderHorizontal-top' : configLayout.web.menu == 'HorizontalTop' ? 'sidebar-nav metismenu sidebar-nav-horizontal' : 'sidebar-nav metismenu'"
      [hidden]="configLayout.web.menuHide && configLayout.web.headerHide" id="sidebar-menu" #sidebarMenu
      [style]="configLayout.backgroundMenu && configLayout.backgroundMenu != 'Default' && (configLayout.web.menu == 'HorizontalTop' || configLayout.web.menu == 'HorizontalBottom') ? 'background: #ffffff00': ''">

      <ng-container *ngFor="let item of menuItems">

        <!-- <li class="nav-item nav-category" *ngIf="item.isModule == 'ismodule'">{{ item.name }}</li> -->

        <li class="nav-item nav-link-ref"
          (click)="setCurrentMenuSelection({ isModule: item.isModule, partId: item.partId, name: item.name, partType: item.partType, entityId: item.id, appViewId: item.AppViewId, expresionId: item.ExpresionId, viewMode: item.ViewMode, LappizPageId: item.LappizPageId, apiPath: item.apiPath }, null, $event)">
          <a class="nav-link" name="{{ item.partId != null ? item.partId : item.name }}"
          [style]="configLayout.colorMenu && configLayout.colorMenu != 'Default' ? 'color: ' + configLayout.colorMenu: ''">
            <i
              class="fa {{ item.IconoAweModul != 'empty' && item.IconoAweModul != undefined && item.IconoAweModul != '' && item.IconoAweModul != null && item.IconoAweModul != 'System.String[]' ? item.IconoAweModul : item.IconoAwe != 'empty' && item.IconoAwe != undefined && item.IconoAwe != '' && item.IconoAwe != null && item.IconoAwe != 'System.String[]' ? item.IconoAwe : defaultClassIcon }} link-icon"></i>
            <span class="link-title"> {{ item.name }}</span>
            <span class="link-arrow" data-feather="chevron-down" appFeatherIcon *ngIf="item.pages"></span>
          </a>

          <ul
            [class]="configLayout.web.menu == 'HorizontalBottom' ? 'sidebar-nav sub-menu nav-second-level sub-menu-horizontal-bottom' :'sidebar-nav sub-menu nav-second-level'"
            aria-expanded="false" [hidden]="!item.pages"
            [style]="configLayout.backgroundMenu && configLayout.backgroundMenu != 'Default' && (configLayout.web.menu == 'HorizontalTop' || configLayout.web.menu == 'HorizontalBottom') ? 'background: ' + configLayout.backgroundMenu + ' !important': '#ffffff00'">

            <li class="nav-item" *ngFor="let subItem of item.pages" [ngClass]="{'side-nav-item': subItem.pages }"
              (click)="setCurrentMenuSelection({ isModule: subItem.isModule, partId: subItem.partId, name: subItem.name, partType: subItem.partType, entityId: subItem.id, appViewId: subItem.AppViewId, expresionId: subItem.ExpresionId, viewMode: subItem.ViewMode }, { partId: item.partId, name: item.name, partType: item.partType }, $event)">
              <a class="nav-link side-nav-link-a-ref"
                name="{{ subItem.partId != null ? subItem.partId : subItem.name }}"
                [style]="configLayout.colorMenu && configLayout.colorMenu != 'Default' ? 'color: ' + configLayout.colorMenu: ''">
                <i
                  class="fa {{ subItem.IconoAweModul != 'empty' && subItem.IconoAweModul != undefined && subItem.IconoAweModul != '' && subItem.IconoAweModul != 'System.String[]' ? subItem.IconoAweModul : subItem.IconoAwe != 'empty' && subItem.IconoAwe != undefined && subItem.IconoAwe != '' && subItem.IconoAwe != 'System.String[]' ? subItem.IconoAwe : defaultClassIcon }} link-icon"></i>
                <span class="item-name"> {{ subItem.name }}</span>
                <span class="link-arrow" data-feather="chevron-down" appFeatherIcon *ngIf="subItem.pages"></span>
              </a>

              <ul
                [class]="configLayout.web.menu == 'HorizontalTop' || configLayout.web.menu == 'HorizontalBottom'  ? 'sidebar-nav sub-menu nav-third-level nav-third-level-horizontal' :'sidebar-nav sub-menu nav-third-level'"
                aria-expanded="false" id="{{ subItem.id }}" [hidden]="!subItem.pages"
                [style]="configLayout.backgroundMenu && configLayout.backgroundMenu != 'Default' && (configLayout.web.menu == 'HorizontalTop' || configLayout.web.menu == 'HorizontalBottom') ? 'background: ' + configLayout.backgroundMenu + ' !important': '#ffffff00'">
                <li class="nav-item" *ngFor="let subSubItem of subItem.pages"
                  (click)="setCurrentMenuSelection({ partId: subSubItem.partId, name: subSubItem.name, partType: subSubItem.partType, entityId: subSubItem.id, appViewId: subSubItem.AppViewId, expresionId: subSubItem.ExpresionId, viewMode: subSubItem.ViewMode }, { partId: subItem.partId, name: subItem.name, partType: subItem.partType }, $event)">
                  <a class="nav-link nav-link-ref"
                    name="{{ subSubItem.partId != null ? subSubItem.partId : subSubItem.name }}"
                    [style]="configLayout.colorMenu && configLayout.colorMenu != 'Default' ? 'color: ' + configLayout.colorMenu: ''">
                    <i
                      class="fa {{ subSubItem.IconoAwe != 'empty' && subSubItem.IconoAwe != undefined && subSubItem.IconoAwe != '' && subSubItem.IconoAwe != 'System.String[]' ? subSubItem.IconoAwe : defaultClassIcon }} link-icon"></i>
                    <span class="item-name"> {{ subSubItem.name }}</span>
                  </a>
                </li>
              </ul>
            </li>

          </ul>
        </li>
        <!-- headerInMenu -->
      </ng-container>

    </ul>
    <div
      [class]="((configLayout.web.headerHide || configLayout.web.header == 'InMenu') && configLayout.web.menu == 'HorizontalTop') ? 'profile-horizontal-header-hidden lappiz-top' : ((configLayout.web.headerHide || configLayout.web.header == 'InMenu') && configLayout.web.menu == 'HorizontalBottom') ? 'profile-horizontal-header-hidden lappiz-bottom sombra' : configLayout.web.headerHide ? 'profile-horizontal-header-hidden lappiz-top' : '' "
      *ngIf="(configLayout.web.header == 'InMenu' || configLayout.web.headerHide)  ||  configLayout.web.menu == 'HorizontalBottom'">
      <div class="profile">
        <div class="info-user"
          [hidden]="((configLayout.web.headerHide || configLayout.web.header == 'InMenu') && (configLayout.web.menu == 'HorizontalTop' || configLayout.web.menu == 'HorizontalBottom'))"
          [style]="configLayout.colorHeader && configLayout.colorHeader != 'Default' ? 'color: ' + configLayout.colorHeader: ''">
          <p>{{nameApp}}</p>
          <p class="text-muted" [style]="configLayout.colorHeader && configLayout.colorHeader != 'Default' ? 'color: ' + configLayout.colorHeader + '!important': ''">{{userName}}</p>
        </div>
        <div class="img-user" (click)="viewActions()">
          <img class="img-fluid" src="../../../../assets/images/default-user.png" alt="User">
        </div>
      </div>
      <div
        [class]="((configLayout.web.headerHide || configLayout.web.header == 'InMenu') && configLayout.web.menu == 'HorizontalBottom') ? 'action-users-header-hidden action-users-header-bottom' : configLayout.web.headerHide || ( configLayout.web.header == 'InMenu' && configLayout.web.menu == 'HorizontalTop') ? 'action-users-header-hidden' : 'action-users'"
        [hidden]="configLayout.web.headerHide || (configLayout.web.header == 'InMenu' && (configLayout.web.menu == 'HorizontalTop' || configLayout.web.menu == 'HorizontalBottom'))"
        [style]="(( configLayout.web.header == 'InMenu' && configLayout.web.menu == 'HorizontalTop') || configLayout.web.headerHide) && configLayout.backgroundMenu && configLayout.backgroundMenu != 'Default' ? 'background: ' + configLayout.backgroundMenu + ' !important': '#ffffff00'">

        <div class="icon-action other-link" *ngIf="isAdmin">
          <a title="Diseñar" (click)="designer()" class="nav-link" [style]="configLayout.colorHeader && configLayout.colorHeader != 'Default' ? 'color: ' + configLayout.colorHeader: ''">
            <i class="fa fa-pencil"></i>
          </a>
        </div>
        <!-- <div class="icon-action other-link">
          <button class="nav-link" [style]="configLayout.colorHeader && configLayout.colorHeader != 'Default' ? 'color: ' + configLayout.colorHeader: ''">
            <i class="fa fa-file-contract"></i>
            <span>Contratos</span>
          </button>
        </div>
        <div class="icon-action other-link">
          <button class="nav-link" [style]="configLayout.colorHeader && configLayout.colorHeader != 'Default' ? 'color: ' + configLayout.colorHeader: ''">
            <i class="fa fa-pencil"></i>
            <span>Diseñar</span>
          </button>
        </div> -->
        <div class="icon-action other-link">
          <a title="Cambiar contraseña" (click)="recoveryLink()" class="nav-link" [style]="configLayout.colorHeader && configLayout.colorHeader != 'Default' ? 'color: ' + configLayout.colorHeader: ''">
            <i class="feather icon-lock"></i>
          </a>
        </div>
        <div class="icon-action other-link" ngbDropdown>
          <a title="Apps" class="nav-link" [style]="configLayout.colorHeader && configLayout.colorHeader != 'Default' ? 'color: ' + configLayout.colorHeader: ''" id="appsDropdown2" ngbDropdownToggle>
            <i class="link-icon feather icon-grid"></i>
          </a>
          <div ngbDropdownMenu aria-labelledby="appsDropdown2" class="app-style">
            <div class="dropdown-header d-flex align-items-center justify-content-between">
              <p class="mb-0 font-weight-medium">Apps</p>
            </div>
            <div class="dropdown-body">
              <div class="d-flex align-items-center apps">
                <ul class="app-list">
                  <li *ngFor="let app of additionalWorkspaces">
                    <a class="a-apps">
                      <div class="container-apps">
                        <a (click)="loadMenu(app.Id)" class="a-apps">
                          <div class="container-img-apps">
                            <img *ngIf="app.iconApp != null && app.iconApp != ''" src="{{baseImagen + app.iconApp}}"
                              style="width: 80px; height: 40px;">
                            <img *ngIf="app.iconApp == null || app.iconApp == ''" [src]="imageApp"
                              style="width: 50px; height: 50px;">
                            <p>{{ app.name }}</p>
                          </div>
                        </a>
                      </div>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="icon-action">
          <a title="Cerrar sesión" (click)="onlogout()" class="nav-link" [style]="configLayout.colorHeader && configLayout.colorHeader != 'Default' ? 'color: ' + configLayout.colorHeader: ''">
            <i class="feather icon-log-out"></i>
          </a>
        </div>
      </div>
    </div>
    <!--- Sidemenu end -->

  </div>
</nav>
