<form [formGroup]="innerForm">
    <div class="form-group">
        <div class="input-group mb-3">
            <input formControlName="field" *ngIf="field.format" type="{{field.type}}" id="{{field.campoId}}"
                class="{{inputClass}} form-control" [required]="field.required" [(ngModel)]="value.val"
                [attr.hidden]="!field.show ? true : null" [ngClass]="inputClass"
                [attr.disabled]="!field.IsEditable ? true : null || field.disabled ? true : null" (change)="change()"
                [pattern]="field.format" placeholder="{{field.displayName}}" />
            <input formControlName="field" *ngIf="!field.format" type="{{field.type}}" id="{{field.campoId}}"
                class="{{inputClass}} form-control" [required]="field.required" [(ngModel)]="value.val"
                [attr.hidden]="!field.show ? true : null" [ngClass]="inputClass"
                [attr.disabled]="!field.IsEditable ? true : null || field.disabled ? true : null" (change)="change()"
                placeholder="{{field.displayName}}" />
            <div class="input-group-prepend  icons-field" *ngIf="field.IsEditable">
                <span (click)="onMicrophoneClick($event)" class="input-group-text icon-field icon-action" id="basic-addon0" *ngIf="field.IsSpeech">
                    <i [ngClass]="classMicrophone"></i>
                </span>
            </div>
        </div>
    </div>
    <div *ngIf="formSubmitted || innerForm.controls.field.errors?.required && innerForm.controls.field.touched"
        class="ft-appengine-required-input" id="minlength_{{field.campoId}}" role="alert">
        {{errors.required}}
    </div>
    <div class="ft-appengine-required-input" id="minlength_{{field.campoId}}" role="alert"
        *ngIf="formSubmitted || innerForm.controls.field.errors?.minlength && innerForm.controls.field.touched">
        {{errors.minimumValue}}</div>
    <div class="ft-appengine-required-input" id="maxlength_{{field.campoId}}" role="alert"
        *ngIf="formSubmitted || innerForm.controls.field.errors?.maxlength && innerForm.controls.field.touched">
        {{errors.maximumValue}}</div>
    <div *ngIf="field.format" id="invalid_{{field.campoId}}" class="ft-appengine-required-input" role="alert">
        {{errors.format}}</div>
    <div *ngIf="field.type == 'email'" id="required_{{field.campoId}}" class="alert alert-danger" role="alert">
        {{errors.email}}</div>
    <div *ngIf="field.type == 'formula'" id="required_{{field.campoId}}" class="alert alert-danger" role="alert">
        {{errors.email}}</div>
</form>
