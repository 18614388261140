import { Component, ElementRef, Input, OnInit, ViewChild, Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import backandGlobal from '@env/env';

@Injectable({ providedIn: 'root' })

@Component({
  selector: 'sapp-trip',
  templateUrl: './trip.component.html',
  styleUrls: ['./trip.component.scss']
})
export class TripComponent implements OnInit {

  innerForm: UntypedFormGroup;

  @Input()
  optimize_trip: any;
  waypoints: any;
  directionsService: any;
  directionsDisplay: any;
  current_map: google.maps.Map;
  @ViewChild('mapWrapper', { static: false }) mapElement: ElementRef;
  firts_point: any;
  last_point: any;
  start_points: any[];
  mapData: any;

  constructor() {
    const center: google.maps.LatLngLiteral = {
      lat: backandGlobal.MAP_DEFAULT_POSITION.pos[0],
      lng: backandGlobal.MAP_DEFAULT_POSITION.pos[1]
    };

    this.firts_point = [6.2775315, -75.5982166];
    this.last_point = [6.2315133, -75.6089165];
    this.innerForm = new UntypedFormGroup({
      optimize_trip: new UntypedFormControl(),
    });
  }

  tripLoaded(mapData: any) {
    if (mapData != undefined && mapData.length > 0) {
      this.mapData = mapData;
      var last_point = mapData.length - 1;

      for (var ix = 0; ix < mapData.length; ix++) {
        if (mapData[ix] != null) {
          this.firts_point = mapData[0].pos;
          break;
        }
      }

      for (var ix = (mapData.length - 1); ix >= 0; ix--) {
        if (mapData[ix] != null) {
          this.last_point = mapData[ix].pos;
          break;
        }
      }

      this.start_points = [this.firts_point, this.last_point];
      for (var ix = 1; ix < (mapData.length - 1); ix++) {

        if (mapData[ix] != null)
          this.waypoints.push({
            location: new google.maps.LatLng(mapData[ix].pos[0], mapData[ix].pos[1]),
            stopover: true
          });
      }

      this.calculateTrip();
    }
  }

  ngAfterViewInit() {
    const lngLat = new google.maps.LatLng(backandGlobal.MAP_DEFAULT_POSITION.pos[0], backandGlobal.MAP_DEFAULT_POSITION.pos[1]);
    const mapOptions: google.maps.MapOptions = {
      center: lngLat,
      zoom: 16,
      fullscreenControl: true,
      mapTypeControl: true,
      streetViewControl: true
    };

    this.current_map = new google.maps.Map(this.mapElement.nativeElement, mapOptions);
    this.directionsService = new google.maps.DirectionsService;
    this.directionsDisplay = new google.maps.DirectionsRenderer({ map: this.current_map });
  }

  ngOnInit() {
    this.getPosition();
  }

  getPosition(): Promise<any> {
    return new Promise(async (resolve, reject) => {
      await navigator.geolocation.getCurrentPosition(resp => {
        backandGlobal.MAP_DEFAULT_POSITION.pos = [resp.coords.latitude, resp.coords.longitude];
        const lngLat = new google.maps.LatLng(backandGlobal.MAP_DEFAULT_POSITION.pos[0], backandGlobal.MAP_DEFAULT_POSITION.pos[1]);
        this.current_map.setCenter(lngLat);
        this.current_map.setOptions;
        new google.maps.Marker({
          position: lngLat,
          map: this.current_map,
        });

        resolve({ lng: resp.coords.longitude, lat: resp.coords.latitude });
      },
        err => {
          reject(err);
        });
    });
  }

  calculateTrip() {
    if (this.current_map) {
      google.maps.event.trigger(this.current_map, 'resize');
      let getZoom: any = this.current_map.getZoom();
      if (getZoom) {
        this.current_map.setZoom(getZoom);
      } else {
        this.current_map.setZoom(13);
      }

      this.directionsDisplay.setDirections({ routes: [] });
      let p1 = new google.maps.LatLng(this.firts_point[0], this.firts_point[1]);
      let p2 = new google.maps.LatLng(this.last_point[0], this.last_point[1]);
      this.directionsService.route({
        origin: p1,
        destination: p2,
        waypoints: this.waypoints,
        optimizeWaypoints: this.optimize_trip,
        travelMode: google.maps.TravelMode.DRIVING
      }, (response: any, status: any) => {
        if (status == google.maps.DirectionsStatus.OK) {
          this.directionsDisplay.setDirections(response);
        } else {
          window.alert('Directions request failed due to ' + status);
        }
      });
    }
  }

}
