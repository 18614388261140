//Posicion por defecto en mapas
var MAP_DEFAULT_POSITION = { pos: [6.2527682, -75.5652314], address: "" };
var envs = {
  LOCAL: "LOCAL",
  DESARROLLO: "DEV",
  TESTING: "TEST",
  PRODUCTION: "PROD"
};

var env = "TEST"; //LOCAL, DEV, TEST, PROD

var URL_API_UPLOAD_IMAGES,
  url,
  urlAPI,
  api2,
  runtime,
  designer,
  urlNotifications,
  routeCache,
  environment,
  videoCall,
  chat,
  urlsignalr,
  urlWf,
  runtimeBeta;

if (env == envs.LOCAL) {
  //LOCAL
  URL_API_UPLOAD_IMAGES = "http://designertest.lappiz.io/Api/api/Upload/UploadImages/"; //Images
  url = "http://localhost:49335"; //Configuration
  urlAPI = ""; //Data
  api2 = "http://localhost:3000"; //Nodejs
  designer = "http://localhost:9292"; //Designer
  urlNotifications = "https://notificationstest.lappiz.io"; //Servicio de notificaciones
  // routeCache = 'http://137.116.51.93:5984/'; //Ruta CouchDB
  environment = "TEST"; //Ambiente del Runtime;
  urlsignalr = "http://designertest.lappiz.io/ChatServer/chat"; // Ruta Chat Server
  urlWf = "http://localhost:8000/wexflow/start?w="; // Ruta Workflows
  runtimeBeta = "http://localhost:8200"; // Url Runtime Alpha
  runtime = "http://localhost:4200"; // Url Runtime Beta
}

if (env == envs.DESARROLLO) {
  //DEVELOPER
  URL_API_UPLOAD_IMAGES = "http://designerdev.lappiz.io/Api/api/Upload/UploadImages/"; //Images
  url = "http://designerdev.lappiz.io/Api"; //Configuration
  urlAPI = ""; //Data
  api2 = "http://txdev.lappiz.io"; //Nodejs
  designer = "http://designerdev.lappiz.io/Admin"; //Designer
  urlNotifications = "https://notificationstest.lappiz.io"; //servicio de notificaciones
  routeCache = "https://cdbdev.lappiz.io:6984/"; //Ruta CouchDB
  environment = "DEV"; //Ambiente del Runtime;
  urlsignalr = "http://designerdev.lappiz.io/ChatServer/chat"; // Ruta Chat Server
  urlWf = "http://localhost:8000/wexflow/start?w="; // Ruta Workflows
  runtime = "https://runtimedev.lappiz.io"; // Url Runtime Alpha
  runtimeBeta = "https://runtimedevbeta.lappiz.io"; // Url Runtime Beta
}

if (env == envs.TESTING) {
  //TESTING
  URL_API_UPLOAD_IMAGES = "https://designertest.lappiz.io/Api/api/Upload/UploadImages/"; //Images
  url = "https://designertest.lappiz.io/Api"; //Configuration
  urlAPI = ""; //Data
  api2 = "https://txtest.lappiz.io"; //Nodejs
  designer = "https://designertest.lappiz.io/Admin"; //Designer
  urlNotifications = "https://notificationstest.lappiz.io"; //Servicio de notificaciones
  routeCache = "https://cdbtest.lappiz.io:6984/"; //Ruta CouchDB
  environment = "TEST"; //Ambiente del Runtime;
  urlsignalr = "http://designertest.lappiz.io/ChatServer/chat"; // Ruta Chat Server
  urlWf = "http://wftest.lappiz.io/wexflow/start?w="; // Ruta Workflows
  runtime = "https://runtimetestbeta.lappiz.io"; // Url Runtime Alpha
  runtimeBeta = "https://runtimetest.lappiz.io"; // Url Runtime Beta
}

//Produccion debe ir en HTTPS para production
if (env == envs.PRODUCTION) {
  //PRODUCTION
  URL_API_UPLOAD_IMAGES = "https://designer.lappiz.io/Api/api/Upload/UploadImages/"; //Images
  url = "https://designer.lappiz.io/Api"; //Configuration
  urlAPI = ""; //Data
  api2 = "https://tx.lappiz.io"; //Nodejs
  designer = "https://designer.lappiz.io/Admin"; //Designer
  urlNotifications = "https://notifications.lappiz.io"; //Servicio de notificaciones
  routeCache = "https://cdb.lappiz.io:6984/"; //Ruta CouchDB
  environment = "PROD"; //Ambiente del Runtime;
  urlsignalr = "https://designer.lappiz.io/ChatServer/chat"; // Ruta Chat Server
  urlWf = "http://wf.lappiz.io/wexflow/start?w="; // Ruta Workflows
  runtimeBeta = "https://runtime.lappiz.io"; // Url Runtime Alpha
  runtime = "https://runtimebeta.lappiz.io"; // Url Runtime Beta
}

var backandGlobal = {
  url,
  urlAPI,
  api2,
  runtime,
  runtimeBeta,
  routeCache,
  stateOnline: true,
  stateConfig: true,
  environment,
  videoCall,
  chat,
  urlWf,
  urlsignalr,
  urlNotifications,
  designer,
  defaultApp: null,
  enableBusy: true,
  timeline: false,
  CurrentLoginApp: "",
  URL_API_UPLOAD_IMAGES,
  MAP_DEFAULT_POSITION
};

module.exports = backandGlobal;
