<div class="row">
  <div class="col-md-12 stretch-card">
    <div class="card card-lappiz">
      <div class="card-body card-body-lappiz" [style]="inputStyle == 'inForm' ? 'padding: 0px;' : ''">
        <div class="header-section my-2">
          <div class="title-section d-flex align-items-center" style="column-gap: 8px">
            <img class="btn-back" src="../../../assets/images/flecha-izquierda.png" (click)="cancelar()" *ngIf="showCancel && inputStyle != 'inForm'" title="Volver" [id]="entityId + '_back'">
            <h3 *ngIf="inputStyle != 'inForm'">{{ nameEntity }} {{ nameBack }}</h3>
            <div class="form-section-title" *ngIf="inputStyle == 'inForm'">{{ nameEntity }} {{ nameBack }}</div>
          </div>
          <div class="d-flex justify-content-end">
            <div class="d-flex">
                <div *ngFor="let action of globalActions" style="float:right;" class="sub_menu">
                  <div [ngSwitch]="action.Type">
                    <button *ngSwitchCase="'WorkFlow'" name="{{ action.ActionId }}" (click)="globalActionCode(action)"
                      title="{{ action.Name }}" [id]="action.ActionId">
                      <i class="fa {{ action.IconAwesomeAction }}"></i>
                      {{ action.Name }}
                    </button>
                    <button *ngSwitchCase="'Codigo'" name="{{ action.ActionId }}" (click)="globalActionCode(action)"
                      title="{{ action.Name }}" [id]="action.ActionId">
                      <i class="fa {{ action.IconAwesomeAction }}"></i>
                      {{ action.Name }}
                    </button>
                    <button *ngSwitchCase="'NavegarVista'" name="{{ action.ActionId }}"
                      (click)="openDirective(action.QueryString, action)" title="{{ action.Name }}"
                      [id]="action.ActionId">
                      <i class="fa {{ action.IconAwesomeAction }}"></i>
                      {{ action.Name }}
                    </button>
                  </div>
            </div>
                <div class="d-flex">
                  <button class="btn btn-outline-primary crear" (click)="addRecordDetails()" *ngIf="showCreate && inputStyle == 'inForm'" title="Crear" [id]="entityId + '_create'">
                    <i class="fa fa-plus i-icon"></i> Crear
                  </button>
                  <button class="btn btn-outline-primary crear" (click)="addRow()" *ngIf="showCreate && inputStyle != 'inForm'" title="Crear" [id]="entityId + '_create'">
                    <i class="fa fa-plus i-icon"></i> Crear
                  </button>
                  <button class="btn btn-outline-primary guardar" (click)="editChangesGrid()" *ngIf="editableFields"
                    [id]="entityId + '_edit'">
                    <i class="fa fa-floppy-o i-icon"></i>
                  </button>
                  <button type="file" class="active mdl-button mdl-button--acciones mdl-button--primary"
                    [hidden]="!showImportData">
                    <i class="fa fa-upload"></i>
                    <input type="file" class="file" (change)="importExcel($event)"
                      accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
                  </button>
                </div>
            </div>
          </div>
        </div> 

        <ul ngbNav #nav="ngbNav" [(activeId)]="sectionItemNav" [destroyOnHide]="isDestroyOnHide" class="nav-tabs">
          <li [ngbNavItem]="'Grid'" [hidden]="!showGrid">
            <ng-template ngbNavContent>
              <div>
                <div class="row" id="grid-general">
                  <div class="col-xs-12" id="add">
                    <div>
                      <div class="input-container">
                        <i class="fa fa-search input-icon"></i>
                        <input type="search" class="input-basic input-expand" id="filter"
                          placeholder="{{ placeholderSearch }}" />
                      </div>
                    </div>
                    <div [id]="idViewComponent"></div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="'Map'" [hidden]="!showMap">
            <ng-template ngbNavContent>
              <div>
                <div class="row">
                  <div class="col-xs-12">
                    <div id="grid-general-map">
                      <!-- Componente de Trip/Map -->

                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="'Carrousel'" [hidden]="!showSlide">
            <ng-template ngbNavContent>
              <div>
                <div class="row">
                  <div class="col-xs-12">
                    <div id="grid-general-slide">
                      <!-- Componente de Carrousel -->

                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
          <li [ngbNavItem]="'Scheduler'" [hidden]="!showSchedulerView">
            <ng-template ngbNavContent>
              <div>
                <div class="row">
                  <div class="col-xs-12">
                    <div id="grid-general-scheduler">
                      <!-- Componente de Scheduler -->

                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </li>
        </ul>

        <div [ngbNavOutlet]="nav" class="mt-2"></div>
      </div>
    </div>
  </div>
</div>