import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

import * as XLSX from 'xlsx';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import { Constants } from '@common/constants';

import { FormsComponent } from '@components/forms/forms.component';
// import { FileComponent } from '@components/file/file.component';
import { FileGridComponent } from '@components/file-grid/file-grid.component';
import { ModalDetailsComponent } from '@components/details/modal-details/modal-details.component';
import { QrGridComponent } from '@components/qr-grid/qr-grid.component';
import { SignatureGridComponent } from '@components/signature-grid/signature-grid.component';
import Swal from 'sweetalert2';
import { ApplicationService } from '@services/application.service';
import { ServerConfigApiService } from '@services/server-config-api.service';
import { ContextValuesService } from '@services/context-values.service';
import { GridService } from '@services/grid.service';
import { OdataFilterMapService } from '@services/odata-filter-map.service';
import { ProxyTxApiService } from '@services/proxy-tx-api.service';
import { UserService } from '@services/user.service';

import { RootScopeService } from '@shared/root-scope.service';

import backandGlobal from '@env/env';
import { NotificationsService } from '@services/notifications.service';

declare var kendo: any;

@Component({
  selector: 'app-details',
  templateUrl: './details.component.html',
  styleUrls: ['./details.component.scss']
})
export class DetailsComponent implements OnInit {

  @Input() field: any;
  @Input() value: any;
  @Input() form: any;
  @Input() inputClass: any;
  @Input() errors: any;
  @Input() isNew: any;
  // @ViewChild(FormsComponent)
  // private formsComponent!: FormsComponent;

  continuar: boolean;
  dataEdit: any;
  editableFields: boolean;
  dataInitialGrid: any[];
  countChage: number;
  Aggregates: any[];
  moreActions: string;
  details: any;
  configTable: any;
  isAnonymous: boolean;
  isViewItem: boolean;
  showToolbar: boolean;
  showSearch: boolean;
  showAdd: any;
  showEdit: any;
  showDelete: any;
  logicalDelete: any;
  showViewItem: any;
  dataItemLogicalEntity: any;
  collapseFilter: boolean;
  viewsToOpen: { Create: any; Edit: any; };
  returnType: any;
  myPromise: any;
  GlobalActions: any[];
  fieldsToMap: any[];
  fieldToSlide: { images: any[]; names: any[]; description: any[]; };
  fieldToScheduler: { title: { field: any[]; fieldName: any[]; }; start: any[]; end: any[]; description: { field: any[]; fieldName: any[]; }; };
  fieldsToList: { NameFiel: any[]; Value: any[]; Filter: any[]; Type: any[]; };
  gridColumns: any;
  permissions: any;
  command: any;
  actionsMenuOptions: boolean;
  column: any;
  dataSort: any;
  fieldColumn: any;
  viewEntityName: any;
  categoryDropDownEditor: (container: any, options: any) => void;
  ddlDataSource: kendo.data.DataSource;
  FieldMobile: string;
  FieldMobileTemplate: string;
  fieldNameDefault: string;
  odataFilterOptions: any;
  mainGridOptions: any;
  appViewId: any;
  CustomMessages: any;
  selectedItem: any;
  waiting: boolean;
  alerts: any[];
  closeAlert: (index: any) => void;
  Load: any;
  messages: any;
  X: typeof XLSX;
  searchParams: any;
  visibleImport: boolean;
  filterGridOptions: { logic: string; filters: ({ logic: string; filters: any[]; field?: undefined; operator?: undefined; value?: undefined; } | { field: string; operator: string; value: any; logic?: undefined; filters?: undefined; })[]; };
  dataGrid: any;
  dataGridNew: any[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private applicationService: ApplicationService,
    private configService: ServerConfigApiService,
    private contextValuesService: ContextValuesService,
    private gridService: GridService,
    private modalService: NgbModal,
    private odataFilterMapService: OdataFilterMapService,
    private proxyTxApiService: ProxyTxApiService,
    private userService: UserService,
    private $rs: RootScopeService,
    private formsComponent: FormsComponent,
    private notificationsService: NotificationsService
  ) {

    this.route.queryParamMap.subscribe((params) => {
      this.appViewId = params.get('appViewId');
    });

    this.continuar = false;
    this.gridColumns = [];
    var fieldsModel: any = {};
    this.dataEdit = null;
    this.editableFields = false;
    this.dataInitialGrid = [];
    this.countChage = 0;
    this.Aggregates = [];
    this.moreActions = this.$rs.$rootScope.lang == 'es' ? 'Más Acciones' : this.$rs.$rootScope.lang == 'en' ? 'More Actions' : 'Más Acciones';
    this.dataGrid = [];
    this.dataGridNew = [];
    this.visibleImport = false;
    this.isViewItem = false;
  }

  async ngOnInit(): Promise<void> {
    this.formsComponent.checkForm(this.field.campoId, false);
    if (!localStorage.getItem("Authorization")) {
      this.isAnonymous = true;
    } else {
      this.isAnonymous = false;
    }

    var Params: any = {
      dataType: 'list',
      id: this.field.gridDetails.endPoint.view,
      entityId: this.field.gridDetails.endPoint.entityId,
    };

    if (this.field.gridDetails.endPoint.AppViewDetailsId != null) {
      Params.appViewId = this.field.gridDetails.endPoint.AppViewDetailsId;
    }

    this.returnType = this.gridService.getColumnType;

    this.myPromise = await this.configService.read(Params, (data: any) => {
      //Se usa la funcion $timeout para que cuando la configuracion viene de cache
      //siempre se muestre y no toque esperar que otro ciclo de apply ocurra para poder ver la grid de detalles
      setTimeout(() => {
        this.configGrid(data);
        setTimeout(() => {
          this.details = kendo.jQuery(`#${this.field.campoId}`).kendoGrid(this.mainGridOptions);
          this.setActionsDetails(this.field.campoId);
        }, 1500);
      }, 50);
    });

    this.CustomMessages = Constants.MESSAGES;
    this.messages = Constants.MESSAGES;
    this.X = XLSX;
    this.visibleImport = false;
    if (this.field.Import == 'Insert' || this.field.Import == 'Action') {
      this.visibleImport = true;
    }

  }

  importExcel($files: any, Entitie: any) {
    var f = $files.target.files[0];
    var reader: any = new FileReader();
    reader.onload = async (e: any, viewName: any) => {
      var data = e.target.result;
      var arr = this.fixdata(data);
      var wb = this.to_json(this.X.read(btoa(arr), { type: 'base64' }));

      var nameObject = 'wb.' + Object.keys(wb)[0];
      var items = eval(nameObject);
      if (this.field.Import == 'Insert') {
        this.dataGrid = kendo.jQuery(`#${this.field.campoId}`).data('kendoGrid').dataSource.data();
        await items.forEach(async (item: any) => {
          await this.saveItemsGrid(item);
        });
        let dataSource = new kendo.data.DataSource({
          data: this.dataGrid
        });
        let grid = kendo.jQuery(`#${this.field.campoId}`).data('kendoGrid');
        grid.setDataSource(dataSource);

        this.notificationsService.notificationApp('success', this.CustomMessages.SuccessImport);
      } else if (this.field.Import == 'Action') {
        //TODO: cambiar cuando se cree el metodo en el formscomponent
        // this.getCurrentItem;
        var e: any = {
          dataItem: this.$rs.$rootScope.getCurrentItem.dataItem,
          items
        };

        var impl = this.field.ImportAction;
        var methodName = impl.split('function ');
        if (methodName.length > 1) {
          methodName = methodName[1].split('(');
          if (methodName.length > 1) {
            methodName = methodName[0];
            impl += `${methodName}();`;
          }
        }

        this.applicationService.evalEvent(impl, e);
      }
    };

    reader.readAsArrayBuffer(f);
  };

  to_json(workbook: any) {
    var result = {};
    if (workbook.SheetNames) {
      workbook.SheetNames.forEach((sheetName: any) => {
        var roa = this.X.utils.sheet_to_json(workbook.Sheets[sheetName]);
        if (roa.length > 0) {
          result[sheetName] = roa;
        }
      });
    }
    return result;
  }

  refreshDetails() {
    this.refreshGrid();
  }

  openDocumentsModal(e: any, Code: any, EntityCode?: any) {
    var val = '';
    var field;
    this.searchParams = this.route.snapshot.queryParams;

    this.configTable.fields.forEach((value: any) => {
      if (value.type == 'Archive' && value.Code == Code) {
        val = e.dataItem[value.name];
        field = value;
        field.entityParentId = e.dataItem.Id;
      }
    });

    const modalRef = this.modalService.open(FileGridComponent, {
      animation: true,
      size: 'lg'
    });

    modalRef.componentInstance.documents = val.split(',');
    modalRef.componentInstance.field = field;
    modalRef.componentInstance.IsGrid = false;
    modalRef.componentInstance.rowId = e.dataItem.Id

    modalRef.result.then((newItem: any) => {
      if (newItem) {
        this.value.val = newItem;
        this.formsComponent.messageDocumentsSave(newItem);
      }
    });
  }

  /**
   * Método encargado para abrir modal de QR
   */
  openQRCodeModal(e: any, Code: any) {
    var val = '';
    var field;
    this.searchParams = this.route.snapshot.queryParams;
    // this.searchParams.rowId = e.dataItem.Id;
    let fieldFilter = this.configTable.fields.filter((value: any) => value.type == 'QRCode' && value.Code == Code)
    if (fieldFilter.length > 0) {
      val = e.dataItem[fieldFilter[0].name];
      field = fieldFilter[0];
      field.entityParentId = e.dataItem.Id;
    }

    if (val == '' || val === null || val === undefined) {
      val = '';
    }

    const modalRef = this.modalService.open(QrGridComponent, {
      animation: true,
      size: 'lg'
    });

    modalRef.componentInstance.value = { val: val };
    modalRef.componentInstance.field = field;
    modalRef.componentInstance.rowId = e.dataItem.Id;

    // modalRef.result.then((newItem: any) => {
    //   if (newItem) {
    //     this.value.val = newItem;
    //     this.toastr.success('Los documentos se han almacenado exitosamente.');
    //     // this.toastr.success(this.customMessages.DocumentsSave);
    //   }
    // });
  }

  /**
   * Método encargado para abrir modal de Signature
   */
  openSignatureCodeModal(e: any, Code: any) {
    var val = '';
    var field;
    this.searchParams = this.route.snapshot.queryParams;
    // this.searchParams.rowId = e.dataItem.Id;
    let fieldFilter = this.configTable.fields.filter((value: any) => value.type == 'Signature' && value.Code == Code)
    if (fieldFilter.length > 0) {
      val = e.dataItem[fieldFilter[0].name];
      field = fieldFilter[0];
      field.entityParentId = e.dataItem.Id;
    }

    if (val == '' || val === null || val === undefined) {
      val = '';
    }

    const modalRef = this.modalService.open(SignatureGridComponent, {
      animation: true,
      size: 'lg'
    });

    modalRef.componentInstance.value = { val: val };
    modalRef.componentInstance.field = field;
    modalRef.componentInstance.rowId = e.dataItem.Id;
  }

  fixdata(data: any) {
    var o = '';
    var l = 0;
    var w = 10240;
    for (; l < data.byteLength / w; ++l) o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w, l * w + w)));
    o += String.fromCharCode.apply(null, new Uint8Array(data.slice(l * w)));
    return o;
  }

  async deleteSelected(e: any) {
    if (!this.showDelete) {
      this.notificationsService.notificationApp('error', this.messages.CanNotDelete);
      return;
    }
    let result = await this.notificationsService.confirmAlert('warning',this.messages.confirm);
    if (result) {
      var id = e.dataItem.Id;
      var isNewItem = e.dataItem.isNewItem;
      var viewName = this.field.gridDetails.endPoint.view.replace('Details', '');

      var params = {
        dataType: 'view',
        id,
        viewName
      };

      var dataLoadedEvent = {
        viewName: params.viewName,
        dataItem: e.dataItem,
        field: this.field
      };

      if (isNewItem === true) {
        var grid: any = kendo.jQuery(`#${this.field.campoId}`).data('kendoGrid');
        var indexItem = grid._data.indexOf(e.dataItem);
        if (this.applicationService.fireEvents('ItemDeleting', this.configTable.formsEvents, dataLoadedEvent)) {
          grid._data.splice(indexItem, 1);
          grid.dataSource._data.splice(indexItem, 1);

          this.field.value.val = grid._data;
        }
      } else {
        if (!viewName) {
          console.error(this.messages.tableMissing);
            this.notificationsService.notificationApp('error', this.messages.failure)
          return;
        }

        if (this.applicationService.fireEvents('ItemDeleting', this.configTable.formsEvents, dataLoadedEvent)) {
          var obj = sessionStorage.tenantId;
          var opts = {
            "parameters": {
              "userId": sessionStorage.userId,
              "pType": "Eliminar",
              "aType": "ffija",
              "environment": backandGlobal.environment,
              "appViewId": this.appViewId
            }
          };
          if (backandGlobal.stateOnline == true) {
            var urlTxDelete = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/${params.viewName}/${params.id}/${obj}`;

            this.proxyTxApiService.operationTxApi(`${urlTxDelete}`, JSON.stringify(opts), 'delete', backandGlobal.stateOnline, viewName).then((params: any) => {
              var grid: any = kendo.jQuery(`#${this.field.campoId}`).data('kendoGrid');
              var indexItem = grid._data.indexOf(e.dataItem);
              grid._data.splice(indexItem, 1);
              grid.dataSource._data.splice(indexItem, 1);

              grid.setDataSource(grid._data);
              grid.refresh();

              this.field.value.val = grid._data;
              this.notificationsService.notificationApp('success', this.messages.SuccessDelete)
            }, (error: any) => {
              this.errorCallback(error);
            });
          } else {
            var entitiesChanged = localStorage.EntityNamesChanged ? JSON.parse(localStorage.EntityNamesChanged) : [];
            entitiesChanged.filter((entity) => entity === viewName).length == 0 ? entitiesChanged.push(viewName) : '';
            localStorage.EntityNamesChanged = JSON.stringify(entitiesChanged);

            grid = kendo.jQuery(`#${this.field.campoId}`).data('kendoGrid');
            var indexItem = grid._data.indexOf(e.dataItem);
            grid._data.splice(indexItem, 1);
            grid.dataSource._data.splice(indexItem, 1);
          }
        }
      }
    } else {
      return
    }
  };

  /**
   * Método encargado de hacer borrado lógico de un registro seleccionado
   */
  async logicalDeleteSelected(e: any) {
    if (!this.showDelete) {
      this.notificationsService.notificationApp('error', this.messages.CanNotDelete)
      return;
    }
    let result = await this.notificationsService.confirmAlert('warning', this.messages.confirm)
    
      if (result) {
        var id;
        if (e.dataItem != undefined) {
          id = e.dataItem.Id;
        } else {
          id = e.itemData.Id._value;
        }

        if (!id) {
          this.notificationsService.notificationApp('error', this.messages.failure)
          return;
        }

        var viewName = this.field.gridDetails.endPoint.view.replace('Details', '');
        if (!viewName) {
          this.notificationsService.notificationApp('error', this.messages.failure)
          return;
        }

        var id = e.dataItem.Id;
        var isNewItem = e.dataItem.isNewItem;

        var params = {
          dataType: 'view',
          id,
          viewName
        };

        var dataLoadedEvent = {
          viewName: params.viewName,
          dataItem: e.dataItem,
          field: this.field
        };

        this.dataItemLogicalEntity = e.dataItem;

        if (this.applicationService.fireEvents('ItemDeleting', this.configTable.formsEvents, dataLoadedEvent)) {
          var tenantId = sessionStorage.getItem('tenantId');

          this.dataItemLogicalEntity.RowStatus = this.dataItemLogicalEntity.RowStatus == 'Active' ? this.dataItemLogicalEntity.RowStatus = "Deleted" : "Active";
          this.dataItemLogicalEntity.tenantId = tenantId;
          this.dataItemLogicalEntity.parameters = {
            'userId': `${sessionStorage.getItem('userId')}`,
            'appViewId': `${this.route.snapshot.queryParams.appViewId}`,
            'pType': 'Editar',
            'aType': `${this.route.snapshot.queryParams.appViewId != undefined ? 'view' : 'ffija'}`,
            'environment': `${backandGlobal.environment}`
          };

          if (this.configTable.HasHistorics) {
            await this.setHistoryValues();
          }

          this.clean(this.dataItemLogicalEntity);

          var urlTxLogicalDelete = `${backandGlobal.api2}/${sessionStorage.getItem('workspace')}.api/api/lappiz/${viewName}/${id}`;

          if (isNewItem) {
            var grid: any = kendo.jQuery(`#${this.field.campoId}`).data('kendoGrid');
            var indexItem = grid._data.indexOf(e.dataItem);
            grid._data.splice(indexItem, 1);
            grid.dataSource._data.splice(indexItem, 1);

            grid.setDataSource(grid._data);
            grid.refresh();

            this.field.value.val = grid._data;

            this.notificationsService.notificationApp('success', this.messages.SuccessDelete);
          } else {
            this.proxyTxApiService.operationTxApi(`${urlTxLogicalDelete}`, JSON.stringify(this.dataItemLogicalEntity), 'update', backandGlobal.stateOnline, viewName).then((response: any) => {
              // TODO: Revisar si es necesario crear un método en el api config para inactivar el usuario lógicamente
              // if (viewName.includes('Lappiz_Users')) {
              //     this.userService.replicateDelete(id);
              // }
              if (backandGlobal.stateOnline === false) {
                var entitiesChanged = localStorage.EntityNamesChanged ? JSON.parse(localStorage.EntityNamesChanged) : [];
                entitiesChanged.filter((entity) => entity === viewName).length == 0 ? entitiesChanged.push(viewName) : '';
                localStorage.EntityNamesChanged = JSON.stringify(entitiesChanged);
              }

              var grid: any = kendo.jQuery(`#${this.field.campoId}`).data('kendoGrid');
              var indexItem = grid._data.indexOf(e.dataItem);
              grid._data.splice(indexItem, 1);
              grid.dataSource._data.splice(indexItem, 1);

              grid.setDataSource(grid._data);
              grid.refresh();

              this.field.value.val = grid._data;

              this.notificationsService.notificationApp('success', this.messages.SuccessDelete);
            }).catch((error: any) => {
              this.errorCallback(error);
            });
          }
        }
      }  else {
        return
      }
  }

  async setHistoryValues() {
    this.dataItemLogicalEntity.EventType = 'Actualizar';
    this.dataItemLogicalEntity.Edited_by = sessionStorage.getItem('userId');
    this.dataItemLogicalEntity.UserEmail = localStorage.getItem('userName');

    await this.userService.getIpUser()
      .then((response: any) => {
        this.dataItemLogicalEntity.IpAddress = response.ip;
      }).catch((error: any) => {
        console.log(error);
      });
  }

  clean(obj: any) {
    for (var propName in obj) {
      // if (obj[propName] === '' || obj[propName] === null) {
      if(obj[propName] == undefined){
        delete obj[propName];
      }
      if (obj[propName] === "")
        this.dataItemLogicalEntity[propName] = null;
    }

    Object.keys(obj).forEach(item => {
      if (item.includes(this.configTable.appName)) delete obj[item]
    });
  }

  GlobalActionCode(action: any) {
    //Pendiente implementación
    //scope.MostrarMensaje('info', 'Ejecutando acción' + action.Name);
    var impl = this.applicationService.transformFunction(action.FormEvent);
    this.applicationService.evalEvent(impl, {});
  };

  OpenDirective(queryString: any, selectedItem: any) {
    var params: NavigationExtras = {
      queryParams: {}
    };

    var parts = queryString.split('?')[1].split('&');

    for (let i = 0; i < parts.length; i++) {
      var value = parts[i].split('=')[1];

      if (value.includes('selectedItem')) {
        var data = {};

        if (selectedItem.dataItem != undefined) {
          data = selectedItem.dataItem;
        } else {
          data = selectedItem.itemData;
        }

        params.queryParams[parts[i].split('=')[0]] = `${data[value.replace('selectedItem.', '')]}`;
        // value = `${parts[i].split('=')}=${data[value.replace('selectedItem.', '')]}`;
      } else {
        params.queryParams[parts[i].split('=')[0]] = parts[i].split('=')[1];
        // value = parts[i];
      }
    }

    var path = queryString.split('?')[0];

    this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
      this.router.navigate([`${path}`], params);
    });
  };

  /**
    * Permite ejecutar una acción de tipo código para el registro seleccionado
  */
  ActionCode(ActionId: any, selectedItem: any) {
    var e = {
      dataItem: selectedItem.dataItem ? selectedItem.dataItem : selectedItem.itemData
    };

    var Action = $.grep(this.configTable.Actions, (b: any) => {
      return b.ActionId === ActionId;
    })[0];

    var impl = this.applicationService.transformFunction(Action.FormEvent);
    this.applicationService.evalEvent(impl, e);
  };

  async addRecordDetails( /*e*/) {
    var modalInstance = this.modalService.open(await ModalDetailsComponent, {
      animation: true,
      size: 'lg',
      backdrop: 'static',
      windowClass: 'right-modal'
    });

    modalInstance.componentInstance.viewName = this.field.gridDetails.endPoint.view;
    modalInstance.componentInstance.rowId = '';
    modalInstance.componentInstance.entityId = this.field.gridDetails.endPoint.entityId;
    modalInstance.componentInstance.entityParentId = backandGlobal.stateOnline === false ? this.field.rowId ? this.field.rowId : this.formsComponent.idOffline : this.field.rowId;
    modalInstance.componentInstance.parent = this.field.gridDetails.endPoint.fKCode;
    modalInstance.componentInstance.parentRules = this.field.events;
    modalInstance.componentInstance.detailsId = this.field.campoId;
    modalInstance.componentInstance.dataToSubmit = null;
    this.selectedItem = null;
    modalInstance.componentInstance.appViewIdDetails = this.viewsToOpen.Create;
    modalInstance.componentInstance.isViewItem = false;
    modalInstance.componentInstance.isDetails = true;

    await modalInstance.result.then((newItem: any) => {
      if (newItem) {
        this.addRegister(newItem);
      }
    }, () => { });
  };

  addEventsToArrayObjects(newItem: any) {
    for (var key in newItem) {
      var element = newItem[key];
      if (element instanceof Array && key != '__TempFieldsDetails') {
        for (var i in element) {
          var item = element[i];
          item._events = {
            //change() { },
            //get() {}
          };

          this.addEventsToArrayObjects(item);
        }
      }
    }
  }

  addRegister(newItem: any) {
    if (!this.field.value.val) {
      this.field.value.val = [];
    }

    this.addEventsToArrayObjects(newItem);
    newItem.__modified = true;
    newItem.isNewItem = true;
    var grid: any = kendo.jQuery(`#${this.field.campoId}`).data('kendoGrid');
    grid.dataSource.add(newItem);
    this.field.value.val = grid._data;

    if (newItem.continue) {
      this.addRecordDetails();
    }

    if (newItem.saveAndEdit) {
      var data = {
        dataItem: newItem
      }
      this.editSelected(data);
    }
  }

  async EditChangesGrid() {
    var changes: any = kendo.jQuery(`#${this.field.campoId}`).data('kendoGrid');
    await changes._data.forEach(async (changeField: any) => {
      await changes.dataSource._pristineData.forEach((initialField: any) => {
        if (initialField.Id == changeField.Id) {
          if (JSON.stringify(initialField) !== JSON.stringify(changeField)) {
            this.countChage++;
          }
        }
      });
    });

    await changes._data.forEach(async (changeField: any) => {
      await changes.dataSource._pristineData.forEach((initialField: any) => {
        if (initialField.Id == changeField.Id) {
          if (JSON.stringify(initialField) !== JSON.stringify(changeField)) {
            this.saveItemsGrid(changeField);
          }
        }
      });
    });
  };

  async saveItemsGrid(row: any) {
    if (row == null || row == undefined) {
      // var messageError = 'Se presento un error con la información!';
      this.notificationsService.notificationApp('error', this.CustomMessages.SaveItemGrid);
      return;
    }

    var parameters = {
      viewName: `${this.field.gridDetails.endPoint.view.replace('Details', '')}`,
      id: row.Id
    };

    this.waiting = true;
    this.alerts = [];
    this.closeAlert = (index: any) => {
      this.alerts.splice(index, 1);
    };

    this.dataEdit = null;
    this.dataEdit = row;

    var dates = [];
    var dateTimes = [];
    this.configTable.fields.forEach((field: any) => {
      switch (field.type) {
        case 'DateTime':
          dateTimes.push(field.Code);
          break;
        case 'Date':
          dates.push(field.Code);
          break;
      }
    });

    if (!this.field.Import || this.field.Import != 'Insert') {
      await this.configTable.fields.forEach((field: any) => {
        this.setFieldValue(field, this.dataEdit[field.name]);
      });
    }

    delete this.dataEdit.__metadata;
    delete this.dataEdit.parent;
    delete this.dataEdit._events;
    delete this.dataEdit.uid;
    delete this.dataEdit._handlers;
    delete this.dataEdit.dirty;

    var keys = Object.keys(this.dataEdit);
    var gridColumns = kendo.jQuery(`#${this.field.campoId}`).data('kendoGrid').columns;

    keys.forEach((key: any, i: number) => {
      if (key.includes('Details') || key.substr(key.length - 1, 1) == '_ ') {
        var name = `delete this.dataEdit.${key}`;
        eval(name);
      }

      if (this.dataEdit[key] === null) {
        var name = `delete this.dataEdit.${key}`;
        eval(name);
      }
      var codOriginal = gridColumns.filter(x => x.title == key);
      if (codOriginal.length > 0) {
        if (codOriginal[0].field != keys[i]) {
          this.dataEdit[codOriginal[0].field] = this.dataEdit[keys[i]];
          delete this.dataEdit[keys[i]];
          keys[i] = codOriginal[0].field;
        }
      }
    });

    if (!this.field.Import || this.field.Import != 'Insert') {
      for (let i = 0; i < keys.length; i++) {
        for (let j = 0; j < dates.length; j++) {
          if (keys[i] == dates[j]) {
            var value = parseInt(this.dataEdit[dates[j]].substring(6, this.dataEdit[dates[j]].length - 2));
            var Fecha = new Date(value).toString();
            this.dataEdit[dates[j]] = Fecha;
          }
        }

        for (let j = 0; j < dateTimes.length; j++) {
          if (keys[i] == dateTimes[j]) {
            var value = parseInt(this.dataEdit[dateTimes[j]].substring(6, this.dataEdit[dateTimes[j]].length - 2));
            var Fecha = new Date(value).toString();
            this.dataEdit[dateTimes[j]] = Fecha;
          }
        }
      }
    }

    if (!parameters.id) {
      var urlTxInsertImportDetails = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/${parameters.viewName}`;

      this.dataEdit.tenantId = sessionStorage.tenantId;
      this.dataEdit.parameters = {
        "userId": sessionStorage.userId,
        "tablaId": "",
        "appViewId": this.appViewId,
        "actionId": "00000000-0000-0000-0000-000000000000",
        "pType": "Guardar",
        "aType": "ffija",
        "environment": backandGlobal.environment,
        "lappizFunctionId": "00000000-0000-0000-0000-000000000000"
      }

      this.dataEdit[this.field.Code] = this.field.rowId;
      this.myPromise = await this.proxyTxApiService.operationTxApi(urlTxInsertImportDetails, JSON.stringify(this.dataEdit), 'post', backandGlobal.stateOnline, this.viewEntityName).then((param: any) => {
        if (backandGlobal.stateOnline === false) {
          var entitiesChanged = localStorage.EntityNamesChanged ? JSON.parse(localStorage.EntityNamesChanged) : [];
          entitiesChanged.filter((entity) => entity === parameters.viewName).length == 0 ? entitiesChanged.push(parameters.viewName) : '';
          localStorage.EntityNamesChanged = JSON.stringify(entitiesChanged);
        }
        this.dataGrid.push(param);
      }, (error: any) => {
        this.errorCallback(error);
      });
    } else {
      var urlTxUpdateDetails = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/${parameters.viewName}/${parameters.id}`;

      this.dataEdit.tenantId = sessionStorage.tenantId;
      this.dataEdit.parameters = {
        "userId": sessionStorage.userId,
        "tablaId": "",
        "appViewId": this.appViewId,
        "actionId": "00000000-0000-0000-0000-000000000000",
        "pType": "Editar",
        "aType": "ffija",
        "environment": backandGlobal.environment,
        "lappizFunctionId": "00000000-0000-0000-0000-000000000000"
      }

      this.myPromise = await this.proxyTxApiService.operationTxApi(urlTxUpdateDetails, JSON.stringify(this.dataEdit), 'update', navigator.onLine).then((param: any) => {
        if (this.countChage == 1) {
          if (backandGlobal.stateOnline === false) {
            var entitiesChanged = localStorage.EntityNamesChanged ? JSON.parse(localStorage.EntityNamesChanged) : [];
            entitiesChanged.filter((entity) => entity === parameters.viewName).length == 0 ? entitiesChanged.push(parameters.viewName) : '';
            localStorage.EntityNamesChanged = JSON.stringify(entitiesChanged);
          }

          this.notificationsService.notificationApp('success', this.CustomMessages.SuccessEdit);
          kendo.jQuery(`#${this.field.campoId}`).data('kendoGrid').dataSource.read();
        } else {
          this.countChage--;
        }
      }, (error: any) => {
        this.errorCallback(error);
      });
    }
  };

  saveSelected(e: any) {
    this.countChage = 1;
    this.saveItemsGrid(e.dataItem);
  };

  errorCallback(error: any) {
    this.Load = false;
    if (error.status == 500 || error.status == 400) {
      if (error.error.message != undefined) {
        var messageError = error.error.name;

        if (messageError.includes('ConstraintViolationException') || messageError.includes('SequelizeForeignKeyConstraintError')) {
          messageError = this.CustomMessages.EntityUsed;
        }
      }

      if (messageError != null) {
        this.alerts = [{ type: 'danger', msg: messageError }];
        this.notificationsService.notificationApp('error', messageError);
      } else {
        this.alerts = [{ type: 'danger', msg: this.CustomMessages.failure }];
        this.notificationsService.notificationApp('error', this.CustomMessages.failure);
      }
    } else {
      this.notificationsService.notificationApp('error', this.CustomMessages.failure);
    }
  };

  setFieldValue(field: any, val: any) {
    if (field.type == 'file') {
      //Esta funcion se implementó debido a que al guardar los archivos, se borra el primer archivo que habia en bd
      if (localStorage.fieldsDeleted) {
        var arrayLocalStorage = JSON.parse(localStorage.fieldsDeleted)
        for (let i = 0; i < arrayLocalStorage.length; i++) {
          if (field.Code == arrayLocalStorage[i].split(':')[0]) {
            field.value.val = arrayLocalStorage[i].split(':')[1] != 'null' ? arrayLocalStorage[i].split(':')[1] : null
          }
        }
      }

      if (localStorage.fieldsUploaded) {
        var arrayLocalStorage = JSON.parse(localStorage.fieldsUploaded)
        for (let i = 0; i < arrayLocalStorage.length; i++) {
          if (field.Code == arrayLocalStorage[i].split(':')[0]) {
            field.value.val = arrayLocalStorage[i].split(':')[1] != 'null' ? arrayLocalStorage[i].split(':')[1] : null
          }
        }
      }
    }

    if (field.campoParentId != '00000000-0000-0000-0000-000000000000') return;
    var numeric = false;
    if (this.returnType(field, this.isNew) == 'numeric' || this.returnType(field, this.isNew) == 'numberWithSeparator' || this.returnType(field, this.isNew) == 'number' || this.returnType(field, this.isNew) == 'decimal') {
      if (val >= 0 && val != '') {
        numeric = true;
      }
    }

    if (this.returnType(field) == 'checkbox', this.isNew) {
      val = val ? 1 : 2;
    }

    if (val != undefined && val != '' || numeric) {
      switch (this.returnType(field, this.isNew)) {
        case 'multiSelect':
          this.dataEdit[field.name] = val.toString().replace(/"/g, '');
          break;
        case 'singleSelect':
        case 'hyperlink':
        case 'formula':
          this.dataEdit[field.name] = val.toString();
          break;
        case 'percentage':
        case 'currency':
        case 'numeric':
        case 'numberWithSeparator':
        case 'map':
        case 'radio':
        case 'carrousel':

          this.dataEdit[field.name] = val;
          break;
        case 'datetime':
        case 'date':
          this.dataEdit[field.name] = `/Date(${val.valueOf()})/`;
          break;
        case 'detalles':
        case 'checklist':
          if (this.isNew) {
            var fieldName = `${field.gridDetails.endPoint.view}`;
            this.dataEdit[fieldName] = val;
          }
          break;
        case 'checkbox':
          this.dataEdit[field.name] = val == 1;
          break;
        default:
          this.dataEdit[field.name] = val ? val : '';
          break;
      }
    } else if (this.returnType(field, this.isNew) != 'detalles' && this.returnType(field, this.isNew) != 'checklist') {
      delete this.dataEdit[field.name];
    }
  };

  async editSelected(e: any) {
    var ViewName = this.field.gridDetails.endPoint.view.replace('Details', '');
    var modalInstance = this.modalService.open(ModalDetailsComponent, {
      animation: true,
      size: 'lg',
      backdrop: 'static',
      windowClass: 'right-modal'
    });

    modalInstance.componentInstance.viewName = ViewName;
    modalInstance.componentInstance.rowId = e.dataItem.Id;
    modalInstance.componentInstance.entityId = this.field.gridDetails.endPoint.entityId;
    modalInstance.componentInstance.entityParentId = backandGlobal.stateOnline === false ? this.field.rowId ? this.field.rowId : this.formsComponent.idOffline : this.field.rowId;
    modalInstance.componentInstance.parent = this.field.gridDetails.endPoint.fKCode;
    modalInstance.componentInstance.parentRules = this.field.events;
    modalInstance.componentInstance.dataToSubmit = e.dataItem;
    this.selectedItem = e.dataItem;
    modalInstance.componentInstance.appViewIdDetails = this.viewsToOpen.Edit;
    modalInstance.componentInstance.isViewItem = false;
    modalInstance.componentInstance.isDetails = true;

    modalInstance.result.then((newItem: any) => {
      if (!newItem) return;
      newItem.__modified = true;
      newItem.isEditItem = true;
      var d = this.selectedItem;
      for (var key in newItem) {
        d[key] = newItem[key];
      }

      var grid: any = kendo.jQuery(`#${this.field.campoId}`).data('kendoGrid');
      this.field.value.val = grid._data;

      var posRow = grid._data.findIndex(row => row.Id == d.Id);
      newItem.Id = d.Id;
      for (const key in newItem) {
        grid._data[posRow][key] = newItem[key];
      }

      grid.setDataSource(grid._data);
      grid.refresh();

      if (newItem.continue) {
        this.addRecordDetails();
      }

      if (newItem.saveAndEdit) {
        var data = {
          dataItem: newItem
        }
        this.editSelected(data);
      }
    }, () => { });
  };

  async viewSelected(e: any) {
    var ViewName = this.field.gridDetails.endPoint.view.replace('Details', '');
    var modalInstance = this.modalService.open(ModalDetailsComponent, {
      animation: true,
      size: 'lg',
      windowClass: 'right-modal'
    });

    modalInstance.componentInstance.viewName = ViewName;
    modalInstance.componentInstance.rowId = e.dataItem.Id;
    modalInstance.componentInstance.entityId = this.field.gridDetails.endPoint.entityId;
    modalInstance.componentInstance.entityParentId = backandGlobal.stateOnline === false ? this.field.rowId ? this.field.rowId : this.formsComponent.idOffline : this.field.rowId;
    modalInstance.componentInstance.parent = this.field.gridDetails.endPoint.fKCode;
    modalInstance.componentInstance.parentRules = this.field.events;
    modalInstance.componentInstance.dataToSubmit = e.dataItem;
    this.selectedItem = e.dataItem;
    modalInstance.componentInstance.appViewIdDetails = this.viewsToOpen.Edit;
    modalInstance.componentInstance.isViewItem = true;
    modalInstance.componentInstance.isDetails = true;

    modalInstance.result.then((newItem: any) => {
      if (!newItem) return;
      delete newItem.undefined
      newItem.__modified = true;
      var d = this.selectedItem;
      for (var key in newItem) {
        d[key] = newItem[key];
      }

      var grid: any = kendo.jQuery(`#${this.field.campoId}`).data('kendoGrid');
      this.field.value.val = grid._data;
      grid.refresh();
    }, () => { });
  }

  refreshGrid() {
    var grid = kendo.jQuery(`#${this.field.campoId}`).data('kendoGrid');
    grid.dataSource.read();
    grid.refresh();
  };

  async configGrid(data: any) {
    this.isViewItem = location.hash.includes('viewItem') || this.field.disabled ? true : false;
    this.GlobalActions = [];
    this.fieldsToMap = [];
    this.fieldToSlide = { images: [], names: [], description: [] };
    this.fieldToScheduler = { title: { field: [], fieldName: [] }, start: [], end: [], description: { field: [], fieldName: [] } };
    this.fieldsToList = { NameFiel: [], Value: [], Filter: [], Type: [] };
    this.configTable = data;

    var dataEditing: any = []
    for (var i = 0; i < this.configTable.dataEditing.length; i++) {
      if (sessionStorage.getItem("rolesId")) {
        if (sessionStorage.getItem("rolesId").includes(",")) {
          let stringRoles = sessionStorage.getItem("rolesId").split(",");
          await stringRoles.forEach(rol => {
            if (this.configTable.dataEditing[i].rolesId.includes(rol)) {
              dataEditing = this.configTable.dataEditing[i];
            }
          });
        } else if (this.configTable.dataEditing[i].rolesId.includes(sessionStorage.getItem("rolesId"))) {
          dataEditing = this.configTable.dataEditing[i];
        }
      } else if (this.isAnonymous == true) {
        dataEditing = this.configTable.dataEditing[i];
      }
    }

    this.showToolbar = this.configTable && this.configTable.toolbarSettings ? !this.configTable.toolbarSettings.hideToolbar : true;
    this.showSearch = this.configTable && this.configTable.design ? !this.configTable.design.hideSearchBox : true;
    this.showAdd = this.configTable && dataEditing ? dataEditing.allowAdd : true;
    this.showEdit = this.configTable && dataEditing ? dataEditing.allowEdit : true;
    this.showDelete = this.configTable && dataEditing ? dataEditing.allowDelete : true;
    this.logicalDelete = this.configTable ? this.configTable.LogicalDelete : false;
    this.showViewItem = this.configTable && dataEditing ? dataEditing.allowViewItem : false;
    //this.showFilter = this.configTable && !this.configTable.hideFilter;
    this.collapseFilter = this.configTable && !this.configTable.collapseFilter;
    this.viewsToOpen = { Create: data.ViewToOpenCreate, Edit: data.ViewToOpenEdit };
    this.gridColumns = [];
    var fieldsModel = {};
    this.editableFields = this.configTable.fields.some((value: any) => {
      return value.IsEditableInGrid;
    });

    this.command = [];
    this.permissions = [];
    for (var i = 0; i < this.configTable.permissions.length; i++) {
      if (sessionStorage.getItem("rolesId")) {
        if (sessionStorage.getItem("rolesId").includes(",")) {
          let stringRoles = sessionStorage.getItem("rolesId").split(",");
          await stringRoles.forEach(rol => {
            if (this.configTable.permissions[i].rolesId.includes(rol)) {
              this.permissions = this.configTable.permissions[i];
            }
          });
        } else if (this.configTable.permissions[i].rolesId.includes(sessionStorage.getItem("rolesId"))) {
          this.permissions = this.configTable.permissions[i];
        }
      } else if (this.isAnonymous == true) {
        this.permissions = this.configTable.permissions[i];
      }
    }

    if (!this.isViewItem) {
      if (this.permissions.allowEditFromGrid) {
        this.command.push({ template: '<a class=\'btn btn-sm\'  data-bind="click: editSelectedClick" ng-click=\'editSelected(this)\' data-toogle="tooltip" title=\'Editar\'><i class=\'fa fa-edit active-color\'></i></a>' });
      }

      if (this.permissions.allowDeleteFromGrid) {
        if (this.logicalDelete) {
          this.command.push({ template: `<a class="btn btn-sm" data-bind="click: logicalDeleteClick" data-toogle="tooltip" title="Eliminar"><i class="fa fa-trash-alt active-color"></i></a>` });
        } else {
          this.command.push({ template: `<a class="btn btn-sm" data-bind="click: deleteSelectedClick" data-toogle="tooltip" title="Eliminar"><i class="fa fa-trash-alt active-color"></i></a>` });
        }
      }

      if (this.editableFields) {
        this.command.push({ template: '<a class=\'btn btn-sm\' data-bind="click: saveSelectedClick"  ng-click=\'saveSelected(this)\' data-toogle="tooltip" title=\'Guardar\'><i class=\'fa fa-floppy-o active-color\'></i></a>' });
      }
    }

    if (this.showViewItem) {
      this.command.push({ template: '<a class=\'btn btn-sm\' data-bind="click: viewSelectedClick"  ng-click=\'viewSelected(this)\' data-toogle="tooltip" title=\'Ver Item\'><i class=\'fa fa-search-plus active-color\'></i></a>' });
    }

    if (this.configTable.Actions.length > 0) {
      await this.configTable.Actions.forEach(async (value: any) => {
        if (sessionStorage.getItem("rolesId")) {
          if (sessionStorage.getItem("rolesId").includes(",")) {
            let stringRoles = sessionStorage.getItem("rolesId").split(",");
            await stringRoles.forEach(rol => {
              if (value.rolesId.includes(rol)) {
                if (!value.GlobalAction) {
                  switch (value.Type) {
                    case 'NavegarVista':
                      this.command.push({ template: ` <a name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm' data-queryString="${value.QueryString}" data-bind="click: OpenDirectiveClick" ng-click='OpenDirective("${value.QueryString}", this)'><i class=\'fa ${value.IconAwesomeAction} active-color\'></i></a>` });
                      break;
                    case 'Codigo':
                      this.command.push({ template: ` <a name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm' data-actionId="${value.ActionId}" data-bind="click: ActionCodeClick" ng-click='ActionCode("${value.ActionId}", this)'><i class=\'fa ${value.IconAwesomeAction} active-color\'></i></a>` });
                      break;
                  }
                } else if (value.GlobalAction) {
                  this.GlobalActions.push(value);
                }
              }
            });
          } else if (value.rolesId.includes(sessionStorage.getItem("rolesId"))) {
            // buttons.push({ template: ` <a type='button' class='btn btn-default btn-sm' ng-click='OpenDirective("${value.QueryString}", this)'>${value.Name}</button>` });
            if (!value.GlobalAction) {
              switch (value.Type) {
                case 'NavegarVista':
                  this.command.push({ template: ` <a name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm' data-queryString="${value.QueryString}"  data-bind="click: OpenDirectiveClick"  ng-click='OpenDirective("${value.QueryString}", this)'><i class=\'fa ${value.IconAwesomeAction} active-color\'></i></a>` });
                  break;
                case 'Codigo':
                  this.command.push({ template: ` <a name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm' data-actionId="${value.ActionId}" data-bind="click: ActionCodeClick" ng-click='ActionCode("${value.ActionId}", this)'><i class=\'fa ${value.IconAwesomeAction} active-color\'></i></a>` });
                  break;
              }
            } else if (value.GlobalAction) {
              this.GlobalActions.push(value);
            }
          }
        } else if (this.isAnonymous == true) {
          if (!value.GlobalAction) {
            switch (value.Type) {
              case 'NavegarVista':
                this.command.push({ template: ` <a name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm' data-queryString="${value.QueryString}"  data-bind="click: OpenDirectiveClick" ng-click='OpenDirective("${value.QueryString}", this)'><i class=\'fa ${value.IconAwesomeAction} active-color\'></i></a>` });
                break;
              case 'Codigo':
                this.command.push({ template: ` <a name='${value.ActionId}' data-toogle="tooltip" title="${value.Name}" class='btn btn-sm' data-actionId="${value.ActionId}" data-bind="click: ActionCodeClick"  ng-click='ActionCode("${value.ActionId}", this)'><i class=\'fa ${value.IconAwesomeAction} active-color\'></i></a>` });
                break;
            }
          } else if (value.GlobalAction) {
            this.GlobalActions.push(value);
          }
        }
      });
    }

    this.actionsMenuOptions = this.GlobalActions && this.GlobalActions.length > 0 ? true : false;
    var command = this.command;
    this.gridColumns.push({ command, width: 75 });
    await this.configTable.fields.forEach((field: any) => {
      this.configColumns(field);
    });

    var url_grid = '';
    if (!this.configTable.filter) {
      //Get all expands...
      var expanded_entities = '';
      Object.entries(data.selectOptions).forEach(([key, value]: any) => {
        if (value[0].endPoint != undefined) {
          var contains = expanded_entities.includes(value[0].endPoint.view);
          if (!contains) {
            expanded_entities += `${value[0].endPoint.view.replace("Details", "")},`;
          }
        }
      })


      if (expanded_entities === '') {
        //url_grid = `${backandGlobal.urlAPI + scope.field.gridDetails.endPoint.url}'${scope.field.rowId}'`;
        url_grid = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/get/${this.field.gridDetails.endPoint.view}`;
      } else {
        expanded_entities = expanded_entities.slice(0, -1);
        //url_grid = `${backandGlobal.urlAPI + scope.field.gridDetails.endPoint.url}'${scope.field.rowId}'` + `&$expand=${expanded_entities}`;

        url_grid = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/get/${this.field.gridDetails.endPoint.view}`;
      }
    } else {
      url_grid = this.contextValuesService.transformODataQuery(this.configTable.filter.Query);
      if (url_grid.includes('$orderby=')) {
        var dataSort = url_grid.split('$orderby=')[1].split(' ');
        this.viewEntityName = this.field.gridDetails.endPoint.view
        if (dataSort[0].split('.').length == 1) {
          dataSort[0] = this.viewEntityName + '.' + dataSort[0];
        }

        this.dataSort = dataSort;
      } else {
        this.dataSort = null;
      }
    }
    // Removemos el filtro y lo dejamos en una variable para ser reutilizado luego en parameterMap
    var entityNameOff = this.field.gridDetails.endPoint.view.replace('Details', '');
    this.odataFilterOptions = this.odataFilterMapService.extractOptions(url_grid);
    url_grid = this.odataFilterOptions.url;
    var filter: any;
    var arrayAux = [];
    var arrayAnd = [];
    var arrayOr = [];
    var arrayNone = [];
    if (this.odataFilterOptions.filterQuery != undefined) {
      var filterArray = this.odataFilterOptions.filterQuery.split(" ");
      filterArray.forEach(function (value) {
        var aux = value;
        if (value.includes("*+*")) {
          aux = value.split("*+*").join(" ");
        }
        aux = aux.replace("'", "").replace("'", "");
        arrayAux.push(aux);
      });
      for (let i = 0; i < arrayAux.length; i++) {
        if (arrayAux[i] == 'and') {
          var bandera = i + 1;
          for (let j = 0; j < 3; j++) {
            arrayAnd.push(arrayAux[bandera]);
            bandera++;
          }
        } else if (arrayAux[i] == 'or') {
          var bandera = i + 1;
          for (let j = 0; j < 3; j++) {
            arrayOr.push(arrayAux[bandera]);
            bandera++;
          }
        } else if (i == 0) {
          var bandera = i;
          for (let j = 0; j < 3; j++) {
            arrayNone[j] = arrayAux[bandera];
            bandera++;
          }
        }
      }

      this.filterGridOptions = {
        logic: 'and',
        filters: [{
          logic: 'or',
          filters: []
        }, {
          logic: "or",
          filters: []
        }, {
          field: this.field.gridDetails.endPoint.view.replace('Details', '') + "." + this.field.gridDetails.endPoint.fKCode,
          operator: "eq",
          value: this.field.rowId
        }]
      }

      if (arrayNone != undefined || arrayNone != null) {
        var nIteracion = arrayNone.length / 3;
        var inicio = 0;
        var fin = 3;
        for (let i = 0; i < nIteracion; i++) {
          var part = arrayNone.slice(inicio, fin);
          if (part[2] != "null") {
            this.filterGridOptions.filters[0].filters.push({
              field: part[0],
              operator: part[1],
              value: part[2]
            });
          }

          inicio = fin;
          fin = fin + 3;
        }
      }

      if (arrayAnd != undefined || arrayAnd != null) {
        var nIteracion = arrayAnd.length / 3;
        var inicio = 0;
        var fin = 3;
        for (let i = 0; i < nIteracion; i++) {
          var part = arrayAnd.slice(inicio, fin);
          if (part[2] != "null") {
            this.filterGridOptions.filters.push({
              field: part[0],
              operator: part[1],
              value: part[2]
            });
          }

          inicio = fin;
          fin = fin + 3;
        }
      }

      if (arrayOr != undefined || arrayOr != null) {
        var nIteracion = arrayOr.length / 3;
        var inicio = 0;
        var fin = 3;
        for (let i = 0; i < nIteracion; i++) {
          var part = arrayOr.slice(inicio, fin);
          if (part[2] != "null") {
            this.filterGridOptions.filters[0].filters.push({
              field: part[0],
              operator: part[1],
              value: part[2]
            });
          }

          inicio = fin;
          fin = fin + 3;
        }
      }

      filter = this.filterGridOptions;


    } else {
      filter = {
        logic: "and",
        filters: [{
          field: this.field.gridDetails.endPoint.view.replace('Details', '') + "." + this.field.gridDetails.endPoint.fKCode,
          operator: "eq",
          value: this.field.rowId
        }]
      }
    }

    this.mainGridOptions = {
      dataSource: {
        transport: {
          read: async (options: any) => {
            var optionsFilter = options;
            if (this.dataSort != null || this.dataSort != undefined) {
              optionsFilter.data.sort = [{ field: this.dataSort[0], dir: this.dataSort[1] }];
              this.dataSort = null;
            }
            url_grid = url_grid.split('$orderby=')[0]
            var gridFieldColumns = this.gridColumns;
            var fieldsColumns = [];
            var entityInclude = [];
            var stringEntity = "";
            fieldsColumns.push("Id");

            await gridFieldColumns.forEach((value: any) => {
              if (!value.command) {
                if (value.field.includes(this.viewEntityName)) {
                  fieldsColumns.push(value.field.replace("[", "").replace("]", "").replace("[", "").replace("]", "").replace(".", "").replace(this.viewEntityName, ""))
                } else {
                  if (value.field.includes(".")) {
                    value.field.split("Details.").join(".");
                    var arrayField = value.field.substr(0, value.field.lastIndexOf('.'));
                    entityInclude.push(arrayField);
                  }

                  fieldsColumns.push(value.field);
                }
              }

              if (value.command && value.title) {
                fieldsColumns.push(value.title)
              }
            });

            let uniqueField = [...new Set(entityInclude)];
            stringEntity = this.getIncludes(uniqueField);

            optionsFilter.data.filter = filter;
            optionsFilter.data.includeEntities = stringEntity;

            var entityRelation = this.field.gridDetails.endPoint.view.replace('Details', '');
            var entityNameRelation = this.field.gridDetails.endPoint.view.replace('Details', '') + "." + this.field.gridDetails.endPoint.fKCode;
            var idRelationFk = this.field.rowId;
            var parentEntity = this.field.viewName;

            var paramRelation = {
              fkViewNameCampo: entityNameRelation,
              idFkRelation: idRelationFk,
              parentEntity: parentEntity
            };

            if (optionsFilter.data.sort != null || optionsFilter.data.sort != undefined) {
              if (optionsFilter.data.sort[0] != undefined || optionsFilter.data.sort[0] != null) {
                if (optionsFilter.data.sort[0].field.split(".").length == 1) {
                  var field = optionsFilter.data.sort[0].field;
                  optionsFilter.data.sort[0].field = entityRelation + "." + field;
                }
              }
            }

            optionsFilter.data.tenantId = sessionStorage.tenantId;
            optionsFilter.data.parameters = {
              "userId": sessionStorage.userId,
              "tablaId": "",
              "appViewId": "00000000-0000-0000-0000-000000000000",
              "actionId": "00000000-0000-0000-0000-000000000000",
              "pType": "showinmenu",
              "aType": "ffija",
              "environment": backandGlobal.environment,
              "lappizFunctionId": "00000000-0000-0000-0000-000000000000"
            }

            await this.proxyTxApiService.getTxDetailsData(url_grid, JSON.stringify(optionsFilter.data), backandGlobal.stateOnline, paramRelation).then((response: any) => {
              if (backandGlobal.stateOnline) {
                var entityRelation = this.field.gridDetails.endPoint.view.replace('Details', '');
                var arrayRelation = this.formsComponent.dataToSubmit[entityRelation];
                if (arrayRelation) {
                  arrayRelation.forEach((row: any) => {
                    if (row.isNewItem || row.isEditItem) {
                      this.dataGridNew.push(row);
                    }
                  });
                }

                this.field.value.val = response.data.rows;
                options.success(response.data);
              } else {
                this.field.value.val = response.rows;
                options.success(response);
              }
            }).catch((error: any) => {
              console.log(error);
            });
          },
        },
        requestStart(e: any) {
          //Agregamos esta validación ya que cuando el registro Padre es nuevo
          //entonces no es necesario ir a la api a preguntar si tiene detalles
          if (this.isNew) {
            e.preventDefault();
          }
        },
        schema: {
          model: {
            fields: fieldsModel
          },
          data(data: any) {
            if (backandGlobal.stateOnline) {
              return data.rows;
            } else {
              return data.rows;
            }
          },
          total(data) {
            if (backandGlobal.stateOnline) {
              return data.count;
            } else {
              return data.total;
            }
          }
        },
        aggregate: this.Aggregates,
        serverFiltering: backandGlobal.stateOnline ? true : false,
        serverPaging: true,
        // serverPaging: backandGlobal.stateOnline ? true : false,
        serverSorting: backandGlobal.stateOnline ? true : false,
        selectable: 'cell',
        requestEnd: (e: any) => {
          if (this.dataGridNew.length > 0) {
            setTimeout(() => {
              this.setDetailsNewData();
            }, 250);
          }
          /*
          En caso que el grid tenga configurada alguna columna con propiedad de navegacion (Ej: Paises.Nombre)
          y que el objeto (Paises) venga vacío el grid no muestra los registros, para eso lo que hacemos es inicializar ese objeto
          como vacío al momento de recibir los datos de la api
          */

          // if (e.response && e.response.d.results) {
          //     var data = e.response.d.results;
          //     for (var i in data) {
          //         var element = data[i];
          //         for (var prop in element) {
          //             if (!Object.hasOwnProperty(prop) && prop.endsWith('Details') && !element[prop]) {
          //                 element[prop] = {};
          //             }
          //         }
          //     }
          // }
        }
      },
      // resizable: true,
      dataBound: (e: any) => {
        var items = e.sender._data;

        if (items.length == 0) {
          return;
        }

        for (var i = 0; i < e.sender.columns.length; i++) {
          var type = e.sender.columns[i].type;
          // e.sender.autoFitColumn(i);

          if ($(window).width() < 768) {
            e.sender.autoFitColumn(i);
          } else if (e.sender.columns.length > 4) {
            e.sender.autoFitColumn(i);
          }
        }

        this.setActionsDetails(this.field.campoId);

        setTimeout(() => {
          if ($(window).width() < 768) {
            $('.k-current-page').hide();
            $('div.k-pager-wrap.k-grid-pager.k-widget.k-floatwrap').removeClass('k-pager-sm');
          }
        }, 25);
      },
      dataBinding: () => {
        this.setActionsDetails(this.field.campoId);

        setTimeout(() => {
          if ($(window).width() < 768) {
            $('.k-current-page').hide();
            $('div.k-pager-wrap.k-grid-pager.k-widget.k-floatwrap').removeClass('k-pager-sm');
          }
        }, 25);
      },
      mobile: true,
      columnMenu: this.editableFields ? false : true,
      columns: this.gridColumns,
      editable: this.editableFields,
      sortable: true,
      scrollable: true,
      pageable: {
        alwaysVisible: false,
        refresh: true,
        pageSize: 10
      },
      filterable: {
        operators: Constants.KENDOGRIDS.operators,
        messages: Constants.KENDOGRIDS.messages
      }
    };

    if (this.editableFields) {
      this.gridColumns.push({
        command: [{
          template: '<a class=\'btn btn-sm\' data-bind="click: saveSelectedClick" ng-click=\'saveSelected(this)\' data-toogle="tooltip" title=\'Guardar\'><i class=\'fa fa-floppy-o\'></i></a>'
        }],
        width: 35
      });
    }

    var exportFlag = false;
    var dispositivo = navigator.userAgent.toLowerCase();
    var downloadfile = {
      toolbar: ['excel', 'pdf'],
      excelExport(e: any) {
        var sheet = e.workbook.sheets[0];
        for (var rowIndex = 1; rowIndex < sheet.rows.length; rowIndex++) {
          if (rowIndex % 2 == 0) {
            var row = sheet.rows[rowIndex];
            for (var cellIndex = 0; cellIndex < row.cells.length; cellIndex++) {
              row.cells[cellIndex].background = "#aabbcc";
            }
          }

          var row = sheet.rows[rowIndex];
          for (var cellIndex = 0; cellIndex < row.cells.length; cellIndex++) {
            if (row.cells[cellIndex].value instanceof Date) {
              row.cells[cellIndex].value.addHours(5);
              row.cells[cellIndex].format = 'dd/MM/yy hh:mm:ss';
            }
          }
        }
      },
      pdfExport(e: any) {
        if (!exportFlag) {
          $('.k-loading-pdf-mask').show()
          e.sender.hideColumn(0);
          e.preventDefault();
          exportFlag = true;

          e.sender.saveAsPDF().then(function () {
            $('.k-loading-pdf-mask').hide()
            e.sender.showColumn(0);
            exportFlag = false;
          });
        }
      },
      excel: {
        title: this.$rs.$rootScope.lang == 'es' ? `Hoja de trabajo ${this.configTable.name}` : this.$rs.$rootScope.lang == 'en' ? `Worksheet ${this.configTable.name}` : `Hoja de trabajo ${this.configTable.name}`,
        fileName: this.$rs.$rootScope.lang == 'es' ? `Datos de grilla ${this.configTable.name}` : this.$rs.$rootScope.lang == 'en' ? `Grid data ${this.configTable.name}` : `Datos de grilla ${this.configTable.name}`,
        allPages: true,
        filterable: true,
        sortable: true,
        pageable: true,
        autoFitColumn: true,
        dataURI: this.$rs.$rootScope.lang == 'es' ? `Hoja Grilla${this.configTable.name}` : this.$rs.$rootScope.lang == 'en' ? `Grid sheet ${this.configTable.name}` : `Hoja Grilla${this.configTable.name}`,
      },
      pdf: {
        landscape: true,
        scale: 0.75,
        fileName: this.$rs.$rootScope.lang == 'es' ? `Datos grilla ${this.configTable.name}` : this.$rs.$rootScope.lang == 'en' ? `Grid data ${this.configTable.name}` : `Datos grilla ${this.configTable.name}`,
        allPages: true,
        margin: { top: '3cm', left: '2cm', right: '4cm', bottom: '3cm' },
      },
    };
    $.extend(this.mainGridOptions, downloadfile);
  }

  getIncludes(entities: any) {
    var aEntities = this.uncompressIncludeEntities(entities);
    var stringInclude = "";
    var cierre = "}]";
    for (let j = 0; j < aEntities.length; j++) {
      if (j != 0) stringInclude += ', ';
      if (!aEntities[j].includes(".")) {
        stringInclude += `{"model": modelo.${aEntities[j]}, "as":"${aEntities[j]}"`;
      } else {
        var auxEntityArray = aEntities[j].split(".");
        for (let i = 0; i < auxEntityArray.length; i++) {
          var entitiesp = auxEntityArray[i].includes("Details") ? auxEntityArray[i].replace("Details", "") : auxEntityArray[i];
          if (i == 0) {
            stringInclude += `{"model": modelo.${entitiesp}, "as": "${entitiesp}"`;
          } else if (i == auxEntityArray.length - 1) {
            stringInclude += `, include: [{"model": modelo.${entitiesp}, "as": "${entitiesp}"`;
            stringInclude += cierre.repeat(i);
          } else {
            stringInclude += `, include: [{"model": modelo.${entitiesp}, "as": "${entitiesp}"`;
          }
        }
      }

      stringInclude += `}`;
    }

    return "[" + stringInclude + "]";
  }

  uncompressIncludeEntities(entities: any) {
    var arr = entities;
    let arr2 = [...arr];

    var arrFin = [];
    for (let i = 0; i < arr.length; i++) {
      for (let j = 0; j < arr.length; j++) {
        if (i != j) {
          var strOr = arr[i];
          var strDes = arr[j];
          if (strDes.startsWith(strOr)) {
            delete arr2[i];
            break;
          }
        }
      }
    }

    var filtered = arr2.filter((el) => {
      return el != null;
    });

    let unique = [...new Set(filtered)];
    return unique;
  }

  setDetailsNewData() {
    var grid = kendo.jQuery(`#${this.field.campoId}`).data('kendoGrid');
    this.dataGridNew.forEach((row: any) => {
      if (row.isEditItem && !row.isNewItem) {
        var actualData = grid.dataItems(),
          rowDelete = actualData.filter((x: any) => x.Id == row.Id && !x.isEditItem)[0];

        if (rowDelete) grid.dataSource.remove(rowDelete);
      }
      // TODO: Agregar structuredClone cuando se incluya en ionic build
      // grid.dataSource.add(structuredClone(row.toJSON()));
      grid.dataSource.add(JSON.parse(JSON.stringify(row.toJSON())));
    });
  }

  async configColumns(value: any) {
    var fieldsModel: any = {};
    this.column = {};

    if (value.type == 'Archive') {
      this.$rs.$rootScope.EntityDetails = this.configTable._id.split("list")[0]; //Cambio
    }

    this.fieldColumn = value.RutaGrid ? value.RutaGrid.split("Details.").join(".") : '';
    if (this.fieldsToList.Value.length < 3 && value.isToListMobile) {
      // this.addFieldToListMobile(value);
    }

    if (!value.isFromOtherEntity) {
      this.column = {
        field: this.viewEntityName ? this.viewEntityName + "." + value.name.charAt(0).toUpperCase() + value.name.slice(1) : value.name,
        title: value.displayName,
        filterable: true,
        editable: (_: any) => {
          return value.IsEditableInGrid;
        },
        template: `#= ${value.name} != null ? ${value.name} : '' #`
      };
    } else {
      this.column = {
        field: this.fieldColumn,
        title: value.displayName.split('/ ')[1],
        filterable: true,
        editable: (_: any) => {
          return value.IsEditableInGrid;
        },
        template: `#= ${this.fieldColumn.split(".")[0]} != null ? ${this.fieldColumn} != null ? ${this.fieldColumn} : '' : '' #`
      };
    }

    if (value.entityParentId == this.field.gridDetails.endPoint.entityId) {
      //column.field = value.name.charAt(0).toUpperCase() + value.name.slice(1);
    } else {
      this.column.field = value.RutaGrid !== null ? value.RutaGrid.split('Details').join('') : this.viewEntityName ? this.viewEntityName + "." + value.name.charAt(0).toUpperCase() + value.name.slice(1) : value.name;
      this.column.filter = value.RutaGrid !== null ? value.RutaGrid.split('Details').join('') : '';
    }

    var type = this.returnType(value, this.isNew);

    if (!value.IsEditable) {
      value.IsEditableInGrid = value.IsEditable;
    }

    if ((type == 'textarea' || type == 'editor' || type == 'text' || type == 'radio' || type == 'map')) {
      fieldsModel[value.name] = {
        type: 'string',
        editable: (_) => {
          return value.IsEditableInGrid;
        },
        validation: {
          required(input) {
            if (input.val().length < 1 && value.advancedLayout.required) {
              var message = this.$rs.$rootScope.lang == 'es' ? 'Este campo es requerido' : this.$rs.$rootScope.lang == 'en' ? 'This field is required' : 'Este campo es requerido';
              input.attr(message);
              return false;
            }

            return true;
          },
          maxlength(input) {
            if (input.val().length > value.advancedLayout.maximumValue) {
              var message = this.$rs.$rootScope.lang == 'es' ? `La cantidad de caracteres es mayor a la permitida (max = ${value.advancedLayout.maximumValue})` : this.$rs.$rootScope.lang == 'en' ? `The number of characters is greater than allowed (max = ${value.advancedLayout.maximumValue})` : `La cantidad de caracteres es mayor a la permitida (max = ${value.advancedLayout.maximumValue})`;
              input.attr('data-maxlength-msg', message);
              return false;
            }

            return true;
          },
          minlength(input) {
            if (input.val().length < value.advancedLayout.minimumValue) {
              var message = this.$rs.$rootScope.lang == 'es' ? `La cantidad de caracteres es menor a la permitida (min = ${value.advancedLayout.minimumValue})` : this.$rs.$rootScope.lang == 'en' ? `The number of characters is less than allowed (min = ${value.advancedLayout.minimumValue})` : `La cantidad de caracteres es menor a la permitida (min = ${value.advancedLayout.minimumValue})`;
              input.attr('data-minlength-msg', message);
              return false;
            }

            return true;
          },
        },
      };
    } else if (type == 'checkbox') {
      fieldsModel[value.name] = {
        type: 'boolean'
      };
    } else if (type == 'currency' || type == 'percentage' || type == 'numberWithSeparator' || type == 'numeric' || type == 'cronometro' || type == 'number' || type == 'decimal') {
      fieldsModel[value.name] = {
        type: 'number',
        editable: (_) => {
          return value.IsEditableInGrid;
        },
        validation: {
          required(input: any) {
            if (input.val().length < 1 && value.advancedLayout.required) {
              var message = this.$rs.$rootScope.lang == 'es' ? 'Este campo es requerido' : this.$rs.$rootScope.lang == 'en' ? 'This field is required' : 'Este campo es requerido';
              input.attr(message);
              return false;
            }

            return true;
          },
          min(input) {
            if (input.val() == '' && !value.advancedLayout.required) {
              return true;
            }

            if (input.val() < value.advancedLayout.minimumValue) {
              var message = this.$rs.$rootScope.lang == 'es' ? `El valor digitado es menor al permitido (min = ${value.advancedLayout.minimumValue})` : this.$rs.$rootScope.lang == 'en' ? `The entered value is less than allowed (min = ${value.advancedLayout.minimumValue})` : `El valor digitado es menor al permitido (min = ${value.advancedLayout.minimumValue})`;
              input.attr('data-min-msg', message);
              return false;
            }

            return true;
          },
          max(input) {
            if (input.val() == '' && !value.advancedLayout.required) {
              return true;
            }

            if (input.val() > value.advancedLayout.maximumValue) {
              var message = this.$rs.$rootScope.lang == 'es' ? `El valor digitado es mayor al permitido (max = ${value.advancedLayout.maximumValue})` : this.$rs.$rootScope.lang == 'en' ? `The entered value is greater than the allowed one (max = ${value.advancedLayout.maximumValue})` : `El valor digitado es mayor al permitido (max = ${value.advancedLayout.maximumValue})`;
              input.attr('data-max-msg', message);
              return false;
            }

            return true;
          }
        }
      };
    } else {
      fieldsModel[value.name] = {
        type,
        editable: (_: any) => {
          return value.IsEditableInGrid;
        },
        validation: {
          required(input: any) {
            if (input.val().length < 1 && value.advancedLayout.required) {
              var message = this.$rs.$rootScope.lang == 'es' ? 'Este campo es requerido' : this.$rs.$rootScope.lang == 'en' ? 'This field is required' : 'Este campo es requerido';
              input.attr(message);
              return false;
            }

            return true;
          },
        }
      };
    }

    //se garantiza que sea para mapa
    if (value.isToMapa && value.type == 'Map') {
      var field_evaluate = value;

      if (field_evaluate.campoParentId != undefined && field_evaluate.campoParentId != '' && field_evaluate.campoParentId != '00000000-0000-0000-0000-000000000000') {
        var real_field = $.grep(this.configTable.fields, (v: any, k: any) => {
          return field_evaluate.campoParentId === v.campoId;
        });

        this.fieldsToMap.push(`${this.configTable.selectOptions[real_field[0].name][0].endPoint.view}.${value.name}`);
      } else {
        this.fieldsToMap.push(value.name);
      }
    }

    //Los campos van hacia el carrousel
    if (value.isToCarrusel) {
      if (value.carrusel.isImagen) {
        this.fieldToSlide.images.push(value.name);
      }

      if (value.carrusel.isNombre) {
        this.fieldToSlide.names.push(value.name);
      }

      if (value.carrusel.isDescription) {
        this.fieldToSlide.description.push(value.name);
      }
    }

    if (value.isToScheduler) {
      var field_name = value.name;
      if (value.campoParentId != undefined && value.campoParentId != '' && value.campoParentId != '00000000-0000-0000-0000-000000000000') {
        field_name = `${value.entityParent}.${value.name}`;
      }

      if (value.scheduler.IsTitle) {
        this.fieldToScheduler.title.fieldName.push(field_name);
        this.fieldToScheduler.title.field.push(value);
      }

      if (value.scheduler.IsStart) {
        this.fieldToScheduler.start.push(field_name);
      }

      if (value.scheduler.IsEnd) {
        this.fieldToScheduler.end.push(field_name);
      }

      if (value.scheduler.IsDescription) {
        this.fieldToScheduler.description.fieldName.push(field_name);
        this.fieldToScheduler.description.field.push(value);
      }
    }

    if (value.type == 'ShortText') {
      if (this.FieldMobile != 'var fieldsmobile = {') {
        this.FieldMobile += ',';
      }

      this.FieldMobile += `"${value.name}":{ type: "string" }`;
      this.FieldMobileTemplate += `<span class="vlr-list"> {{dataItem.${value.name}}}</span>`;
    }

    if (value.IsDefault) {
      if (value.entityParent !== null) {
        this.fieldNameDefault = `${value.entityParent}.${value.name}`;
      } else {
        this.fieldNameDefault = value.name;
      }
    }

    switch (value.type) {
      case 'ShortText':
        this.column.type = 'text';
        this.column.width = 150;
        this.column.attributes = {
          style: 'white-space: normal; text-overflow: inherit;'
        };
        break;
      case 'Boolean':
        this.column.template = `#= ${value.name} ? 'Si' : 'No' #`;
        this.column.width = 100;
        break;
      case 'Image':
        break;
      case 'Map':
        if (value.campoParentId != undefined && value.campoParentId != '' && value.campoParentId != '00000000-0000-0000-0000-000000000000') {
          field_name = `${value.entityParent}.${value.name}`;
          var valTemplate = `${field_name}? JSON.parse(${field_name}).address: ''`;
          this.column.field = field_name;
          this.column.template = `#= ${value.entityParent}? ${valTemplate}: '' #`;
          this.column.width = 300;
        } else {
          var valTemplate = `${value.name}? JSON.parse(${value.name}).address: ''`;
          this.column.template = `#= ${valTemplate} #`;
          this.column.width = 300;
        }
        break;
      case 'LongText':
        // column['minResizableWidth'] = 200;
        this.column.width = 300;
        this.column.template = value.isFromOtherEntity ? `<div style="white-space: normal; text-overflow: inherit;text-align : justify; line-height:1.2;">#= ${this.fieldColumn.split(".")[0]} != null ? ${this.fieldColumn} : '' #</div>` : `<div style="white-space: normal; text-overflow: inherit;text-align : justify; line-height:1.2;">#= ${value.name}?${value.name} :'' #</div>`;
        break;
      case 'Url':
        break;
      case 'MultiSelect':
        break;
      case 'Foto':
        this.column.template = value.isFromOtherEntity ? `<img  width="150" style="border-radius: 30px" class='fotoGrid' src='${backandGlobal.URL_API_UPLOAD_IMAGES}/#:${this.fieldColumn.split(".")[0]} != null ? ${this.fieldColumn} : '' #' />` : `<img width="150" style="border-radius: 30px" class='fotoGrid' src='${backandGlobal.URL_API_UPLOAD_IMAGES}/#:${value.name}#' />`;
        this.column.filterable = false;
        this.column.editable = function (_: any) {
          return false;
        };
        break;
      case 'SingleSelect':
        //column.editor: scope.categoryDropDownEditor;
        if ((value.displayFormat == 'AutoCompleteStartWith' || value.displayFormat == 'AutoCompleteMatchAny') && value.entityParent === null) {
          //Escenario para las columnas tipo relación de la misma entidad
          var table_name = this.configTable.selectOptions[value.name][0].endPoint.view;
          field_name = `${this.configTable.selectOptions[value.name][0].endPoint.view}.${this.configTable.selectOptions[value.name][0].endPoint.defaultField}`;
          var cbx_field = field_name.replace("Details.", ".");
          this.column.field = cbx_field;
          this.column.template = `#= ${cbx_field.split(".")[0]}? ${cbx_field}: '' #`;
          this.column.width = 200;
        } else if (value.entityParent !== null) {
          //escenario cuando es una lista (relacion o singleselect) que es de otra entidad
          this.column.field = this.fieldColumn;
          this.column.template = `#=${this.fieldColumn}#`;
          this.column.width = 200;
        } else {
          //escenario para las listas normales (NO relación)
          this.column.template = `#=${value.name}?${value.name}:''#`;
          this.column.width = 200;
        }
        break;
      case 'DateTime':
        if (value.displayFormat == 'Date') {
          this.column.template = `#=${this.column.field} ? ${this.column.field}.toString().includes('Z') ? new Date(${this.column.field}).toLocaleDateString('es-CO',{timeZone:'UTC'}) : ${this.column.field}.toLocaleDateString('es-CO',{timeZone:'UTC'}):'' #`;
          this.column.format = "dd/MM/yyyy";
          this.column.filterable = false;
          this.column.width = 200;
          // column.editor = scope.dateEditor;
          fieldsModel[value.name].type = 'date';
        } else {
          this.column.template = `#=${this.column.field} ? ${this.column.field}.toString().includes('Z') ? new Date(${this.column.field}).toLocaleString('es-CO',{timeZone:'UTC'}) : ${this.column.field}.toLocaleString('es-CO',{timeZone:'UTC'}):'' #`;
          this.column.format = "dd/MM/yyyy, hh:mm:ss tt";
          this.column.filterable = false;
          this.column.width = 200;
          // column.editor = scope.dateTimeEditor;
          fieldsModel[value.name].type = 'date';
        }
        break;
      case 'Numeric':
        if (value.displayFormat == 'Currency') {
          // template: `#=${value.RutaGrid}?(${value.RutaGrid}):kendo.toString(parseInt(${ValueNameGrid}), "n0")#`
          this.column.template = `#= ${value.RutaGrid}? kendo.toString(parseFloat(${value.RutaGrid}), "c0"): kendo.toString(parseFloat(${value.name}), "c0") #`;
          this.column.width = 200;
          // column.template = `#=${column.field}? ${column.field}.toString().includes('Z') ? new Date(${column.field}).toLocaleString('es-CO',{timeZone:'UTC'}) : ${column.field}.toLocaleString('es-CO',{timeZone:'UTC'}):'' #`;
        }
        // fieldsModel[value.name].type = 'number';
        break;
      case 'Archive':
        this.column = {
          title: value.displayName,
          command: [{
            template: `<a class='btn btn-sm' data-bind="click: openDocumentsClick" data-code="${value.Code}"><i class='fa fa-folder-open'></i></a>`
          }]
        };
        break;
      case 'QRCode':
        this.column = {
          title: value.displayName,
          command: [{
            title: value.displayName,
            template: `<a class='btn btn-sm' data-bind="click: openQRCodeClick" data-code="${value.Code}"><i class='fa fa-light fa-qrcode'></i></a>`
          }]
        };
        break;
      case 'Signature':
        this.column = {
          title: value.displayName,
          command: [{
            title: value.displayName,
            template: `<a class='btn btn-sm' data-bind="click: openSignatureCodeClick" data-code="${value.Code}"><i class="fa fa-signature"></i></a>`
          }]
        };
        break;
    }

    if (this.column.title && this.column.title.includes('/') && value.entityParentId == this.field.gridDetails.endPoint.entityId) {
      this.column.title = this.column.title.split('/')[1];
    }

    if (type == 'currency' || type == 'numeric' || type == 'number' || type == 'decimal' || type == 'percentage') {
      switch (type) {
        case 'currency':
          var scalaDouble = value.advancedLayout.scala;
          this.column.format = `{0:c${scalaDouble}}`;
          this.column.width = 200;
          break;
        case 'numeric':
          var scalaDouble = value.advancedLayout.scala;
          this.column.format = `{0:n${scalaDouble}}`;
          this.column.width = 200;
          break;
        case 'number':
          var scalaDouble = value.advancedLayout.scala;
          this.column.format = `{0:n${scalaDouble}}`;
          this.column.width = 200;
          break;
        case 'decimal':
          var scalaDouble = value.advancedLayout.scala;
          this.column.format = `{0:n${scalaDouble}}`;
          this.column.width = 200;
          break;
        case 'percentage':
          var scalaDouble = value.advancedLayout.scala;
          this.column.format = `{0:p${scalaDouble}}`;
          this.column.width = 200;
          break;
      }
      // column.format = '{0:0.00}';
    }

    function aggregateSum() {
      var field = value.Code;
      var grid = kendo.jQuery(`#${this.field.campoId}`).data("kendoGrid");
      var data = grid.dataSource.data();
      var item, sum = 0;
      var footer = 'Suma: ';
      for (var i = 0; i < data.length; i++) {
        item = data[i];
        sum += parseFloat(item[field]);
      }
      return `${footer} ${kendo.toString(sum, 'n2')}`;
    }

    if (value.Aggregate != null) {
      this.column.aggregates = [value.Aggregate.toLowerCase()];
      var footerEs;
      switch (value.Aggregate) {
        case 'Count':
          footerEs = this.$rs.$rootScope.lang == 'es' ? 'Conteo: ' : this.$rs.$rootScope.lang == 'en' ? 'Counting: ' : 'Conteo: ';
          this.column.footerTemplate = footerEs + "# console.log(data); # #= count #";
          // this.column.footerTemplate = footerEs + "# console.log(data); # #= kendo.toString(parseFloat(" + value.Aggregate.toLowerCase() + "), 'n2') #"; //value.Aggregate.toLowerCase()
          break;
        case 'Sum':
          footerEs = this.$rs.$rootScope.lang == 'es' ? 'Suma: ' : this.$rs.$rootScope.lang == 'en' ? 'Sum: ' : 'Suma: ';
          this.column.footerTemplate = () => {
            var field = value.Code;
            var grid = kendo.jQuery(`#${this.field.campoId}`).data("kendoGrid");
            var data = grid.dataSource.data();
            var item, sum = 0;
            var footer = 'Suma: ';
            for (var i = 0; i < data.length; i++) {
              item = data[i];
              sum += parseFloat(item[field]);
            }
            return `${footer} ${kendo.toString(sum, 'n2')}`;
          }
          break;
        case 'Average':
          footerEs = this.$rs.$rootScope.lang == 'es' ? 'Promedio: ' : this.$rs.$rootScope.lang == 'en' ? 'Average: ' : 'Promedio: ';
          this.column.footerTemplate = footerEs + "# console.log(data); # #= average #";
          // this.column.footerTemplate = footerEs + "# console.log(data); # #= kendo.toString(parseFloat(" + value.Aggregate.toLowerCase() + "), 'n2') #"; //value.Aggregate.toLowerCase()
          break;
        case 'Max':
          footerEs = this.$rs.$rootScope.lang == 'es' ? 'Valor máximo: ' : this.$rs.$rootScope.lang == 'en' ? 'Maximum value: ' : 'Valor máximo: ';
          this.column.footerTemplate = footerEs + "# console.log(data); # #= max #";
          // this.column.footerTemplate = footerEs + "# console.log(data); # #= kendo.toString(parseFloat(" + value.Aggregate.toLowerCase() + "), 'n2') #"; //value.Aggregate.toLowerCase()
          break;
        case 'Min':
          footerEs = this.$rs.$rootScope.lang == 'es' ? 'Valor mínimo: ' : this.$rs.$rootScope.lang == 'en' ? 'Minimum value: ' : 'Valor mínimo: ';
          this.column.footerTemplate = footerEs + "# console.log(data); # #= min #";
          // this.column.footerTemplate = footerEs + "# console.log(data); # #= kendo.toString(parseFloat(" + value.Aggregate.toLowerCase() + "), 'n2') #"; //value.Aggregate.toLowerCase()
          break;
      }
      // this.column.footerTemplate = footerEs + "# console.log(data); # #= kendo.toString(parseFloat(" + value.Aggregate.toLowerCase() + "), 'n2') #"; //value.Aggregate.toLowerCase()
      this.Aggregates.push({ field: value.name, aggregate: value.Aggregate.toLowerCase() });
    }

    if (value.isToGrid) {
      this.column.filterable = false;
      this.gridColumns.push(this.column);
    }
  }

  setActionsDetails(campoId: any) {
    setTimeout(() => {
      var actionsClick = kendo.observable({
        deleteSelectedClick: (e: any) => {
          e.dataItem = kendo.jQuery(`#${campoId}`).data("kendoGrid").dataItem($(e.currentTarget).closest("tr"));
          this.deleteSelected(e);
        },
        logicalDeleteClick: (e: any) => {
          var params = $(e.currentTarget).data();
          e.dataItem = kendo.jQuery(`#${campoId}`).data("kendoGrid").dataItem($(e.currentTarget).closest("tr"));
          this.logicalDeleteSelected(e);
        },
        editSelectedClick: (e: any) => {
          e.dataItem = kendo.jQuery(`#${campoId}`).data("kendoGrid").dataItem($(e.currentTarget).closest("tr"));
          this.editSelected(e);
        },
        viewSelectedClick: (e: any) => {
          e.dataItem = kendo.jQuery(`#${campoId}`).data("kendoGrid").dataItem($(e.currentTarget).closest("tr"));
          this.viewSelected(e);
        },
        saveSelectedClick: (e: any) => {
          e.dataItem = kendo.jQuery(`#${campoId}`).data("kendoGrid").dataItem($(e.currentTarget).closest("tr"));
          this.saveSelected(e);
        },
        OpenDirectiveClick: (e: any) => {
          var parameters = $(e.currentTarget).data();
          var queryString = parameters.querystring;
          e.dataItem = kendo.jQuery(`#${campoId}`).data("kendoGrid").dataItem($(e.currentTarget).closest("tr"));
          this.OpenDirective(queryString, e);
        },
        ActionCodeClick: (e: any) => {
          var parameters = $(e.currentTarget).data();
          var actionId = parameters.actionid;
          e.dataItem = kendo.jQuery(`#${campoId}`).data("kendoGrid").dataItem($(e.currentTarget).closest("tr"));
          this.ActionCode(actionId, e);
        },
        openDocumentsClick: (e: any) => {
          var params = $(e.currentTarget).data();
          var codeDocument = params.code;
          e.dataItem = kendo.jQuery(`#${campoId}`).data("kendoGrid").dataItem($(e.currentTarget).closest("tr"));
          this.openDocumentsModal(e, codeDocument);
        },
        openQRCodeClick: (e: any) => {
          var params = $(e.currentTarget).data();
          var codeDocument = params.code;
          e.dataItem = kendo.jQuery(`#${this.details[0].id}`).data("kendoGrid").dataItem($(e.currentTarget).closest("tr"));
          this.openQRCodeModal(e, codeDocument);
        },
        openSignatureCodeClick: (e: any) => {
          var params = $(e.currentTarget).data();
          var codeDocument = params.code;
          e.dataItem = kendo.jQuery(`#${this.details[0].id}`).data("kendoGrid").dataItem($(e.currentTarget).closest("tr"));
          this.openSignatureCodeModal(e, codeDocument);
        }
      });

      kendo.bind((`#${campoId}`), actionsClick);
    }, 1000);
  }
}
