import {
  Component, SimpleChanges, OnInit,
  Input, AfterViewInit
} from '@angular/core';

import { ServerConfigApiService } from '@services/server-config-api.service';

import { FormsComponent } from '@components/forms/forms.component';
import { ProxyConfigApiService } from '@services/proxy-config-api.service';

@Component({
  selector: 'app-html-content',
  templateUrl: './html-content.component.html',
  styleUrls: ['./html-content.component.scss']
})
export class HtmlContentComponent implements OnInit, AfterViewInit {

  @Input() customTitle: string;
  @Input() dataItem: any;
  @Input() item: any;
  @Input() field: any;

  constructor(
   private configService: ServerConfigApiService,
   private proxyConfigApiService: ProxyConfigApiService,
    private formsComponent: FormsComponent
  ) {}

  ngOnChanges(changes: SimpleChanges) {
    if (changes.item) {
      this.dataItem = this.item;
    }
  }

  ngOnInit(): void { }

  ngAfterViewInit() {
    this.proxyConfigApiService.getViewConfig({
      dataType: 'content',
      id: this.field.campoId
    }, (data: any) => {
      $(`.${this.field.campoId}`).replaceWith(data.ContentHtml);
      this.formsComponent.checkForm(this.field.campoId, false);
    });
  }

}
