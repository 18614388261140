import { Component, OnInit, Input, OnChanges, SimpleChanges, AfterViewInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { FormsComponent } from '@components/forms/forms.component';

@Component({
  selector: 'app-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss']
})
export class PasswordComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() field: any;
  @Input() value: any;
  @Input() form: any;
  @Input() inputClass: string;
  @Input() errors: any;
  public passwordRegExp: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[^_{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[^_{|}~]{10,}$/;
  public showPassword: boolean;

  innerForm = new UntypedFormGroup({
    field: new UntypedFormControl(null, [
      Validators.pattern(this.passwordRegExp) 
    ]),
  });
  
  formSubmitted: any = false;

  constructor(
    private formsComponent: FormsComponent
  ) { }

  ngOnInit(): void {
    if (this.field.required == true) {
      this.innerForm = new UntypedFormGroup({
        field: new UntypedFormControl(this.field.value.val, [
          Validators.required,
          Validators.minLength(this.field.minimumValue),
          Validators.maxLength(this.field.maximumValue),
          Validators.pattern(this.passwordRegExp) 
        ])
      });
    }

    this.formsComponent.checkForm(this.field.campoId, this.innerForm.invalid);

    if (!this.value) {
      this.value = this.field.defaultValue;
    }
  }

  ngAfterViewInit() {
    if (this.field.required == false) {
      this.innerForm = new UntypedFormGroup({
        field: new UntypedFormControl(this.field.value.val, [
          Validators.minLength(this.field.minimumValue),
          Validators.maxLength(this.field.maximumValue),
          Validators.pattern(this.passwordRegExp) 
        ])
      });
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.formsComponent.checkForm(this.field.campoId, this.innerForm.invalid);
  }

  change() {
    this.formsComponent.setValueFieldForm(this.field.campoId, this.field);
    this.innerForm.setValue({field: this.field.value.val})
    setTimeout(() => {
      this.formsComponent.checkForm(this.field.campoId, this.innerForm.invalid);
    }, 100);
  }

}
