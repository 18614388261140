<form [formGroup]="innerForm">
  <div class="input-group">
    <a href="{{innerForm.value.linkUrl}}" target="true" (change)="change()" class="form-control link-url"
      [id]="'link_'+ field.campoId" (ngModelChange)="change()">{{field.value.linkText}}</a>
    <span class="input-group-btn icons-field">
      <button type="button" class="btn btn-lg btn-link-lappiz icon-field" (click)="open(mymodal)">
        <i class="mdi mdi-link-variant"></i>
      </button>
    </span>
  </div>
  <!-- Modal -->
  <ng-template #mymodal let-modal>
    <div class="modal-content">
      <div class="modal-body">
        <div class="form-group">
          <label for="linkText" [ngClass]="{ 'lappiz-field-required': field.required }" translate="textoLabel_link">
            Texto
          </label>
          <span class="lappiz-field-required" *ngIf="field.required">*</span>
          <input type="text" formControlName="linkText" [(ngModel)]="value.linkText" class="form-control" id="linkText"
            (ngModelChange)="change()">
        </div>
        <div class="form-group">
          <label for="linkUrl" [ngClass]="{ 'lappiz-field-required': field.required }" translate="linkLabel_link">
            Enlace
          </label>
          <span class="lappiz-field-required" *ngIf="field.required">*</span>
          <input type="url" formControlName="linkUrl" [(ngModel)]="value.url" name="editUrl" class="form-control"
            id="linkUrl" (ngModelChange)="change()">
        </div>
        <div class="alert alert-danger" role="alert" [hidden]="!innerForm.controls.linkUrl.errors">
          Debe introducir una url correcta.
        </div>
      </div>
      <div class="modal-footer">
        <button [disabled]="innerForm.controls.linkUrl.errors" type="button" class="btn btn-info"
          (click)="modal.close()" translate="closeLink_link">Listo</button>
        <button type="button" class="btn btn-danger" (click)="modal.dismiss('Cross click')"
          translate="closeLink_link">Cerrar</button>
      </div>
    </div>
  </ng-template>
</form>
