import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-cronometro',
  templateUrl: './cronometro.component.html',
  styleUrls: ['./cronometro.component.scss']
})

export class CronometroComponent {

  @Input() field: any;
  @Input() value: any;
  @Input() errors: any;

  hh = 0;
  mm = 0;
  ss = 0;
  ms = 0;

  isRunning = false;
  timerId: any = 0;
  isreset = false

  constructor() {
  }

  ngOnInit(): void {
  }

  clickHandler() {
    if (!this.isRunning) {
      this.isreset = true;
      // Stop => Running
      this.timerId = setInterval(() => {
        this.ms++;

        if (this.ms >= 100) {
          this.ss++;
          this.ms = 0;
        }
        if (this.ss >= 60) {
          this.mm++;
          this.ss = 0
        }
        if(this.mm >= 60){
          this.hh++;
          this.mm =0
        }
      }, 10);
    } else {
      clearInterval(this.timerId);
    }

    if (this.isRunning == true) {
      this.isreset = true;
    } else {
      this.isreset = false
    }

    this.isRunning = !this.isRunning;
  }

  format(num: number) {
    return (num + '').length === 1 ? '0' + num : num + '';
  }

  clearTimer() {
    this.mm = 0;
    this.ss = 0;
    this.ms = 0;
    this.hh= 0;
    this.isRunning = false;
    this.timerId = 0;
    clearInterval(this.timerId);
    this.isreset = false;
  }

}
