import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';

import { FormsComponent } from '@components/forms/forms.component';

import { VoiceRercognitionService } from '@services/voice-rercognition.service';

@Component({
  selector: 'app-textarea',
  templateUrl: './textarea.component.html',
  styleUrls: ['./textarea.component.scss']
})
export class TextareaComponent implements OnInit, AfterViewInit {

  @Input() field: any;
  @Input() value: any;
  @Input() form: any;
  @Input() errors: any;

  formSubmitted = false;
  innerForm: UntypedFormGroup;
  
  microphoneActive: boolean;
  classMicrophone: string;

  public charachtersCount: number;
  public textareaCounter: string;

  actionFormula: () => void;

  constructor(
    private formsComponent: FormsComponent,
    private voiceRecognitionService: VoiceRercognitionService
  ) {
    this.innerForm = new UntypedFormGroup({
      field: new UntypedFormControl(),
    });
    this.classMicrophone ="fa fa-solid fa-microphone";
    this.microphoneActive = false;
  }

  ngAfterViewInit() {
    if (this.field.required == false) {
      if (this.field.maximumValue != -1) {
        this.innerForm = new UntypedFormGroup({
          field: new UntypedFormControl(this.field.value.val, [
            Validators.maxLength(this.field.maximumValue)
          ])
        });
      } else {
        this.innerForm = new UntypedFormGroup({
          field: new UntypedFormControl(this.field.value.val, [])
        });
      }
    }
  }

  ngOnInit(): void {
    if (this.field.required == true) {
      if (this.field.maximumValue != -1) {
        this.innerForm = new UntypedFormGroup({
          field: new UntypedFormControl(this.field.value.val, [
            Validators.maxLength(this.field.maximumValue),
            Validators.required
          ])
        });
      } else {
        this.innerForm = new UntypedFormGroup({
          field: new UntypedFormControl(this.field.value.val,
            this.field.maximumValue != -1 ? [Validators.maxLength(this.field.maximumValue), Validators.required] : [Validators.required]
          )
        });
      }
    }

    if (!this.value) {
      this.value = this.field.defaultValue;
    }

    this.charachtersCount = this.value.val != undefined || this.value.val != null || this.value.val != "" ? this.value.val.length : 0;
    this.textareaCounter = `${this.charachtersCount}/${this.field.maximumValue}`;

    this.formsComponent.checkForm(this.field.campoId, this.innerForm.invalid);
    if (this.field.IsSpeech)
        this.voiceRecognitionService.serviceAvailable.subscribe();
  }

  change() {
    this.formsComponent.setValueFieldForm(this.field.campoId, this.field);
    this.formsComponent.applyUIRules(this.field, this.value);
    this.formsComponent.fireEvents('ValueChanged', this.field.events, {
      value: this.value.val,
      field: this.field
    });
    setTimeout(() => {
      this.formsComponent.checkForm(this.field.campoId, this.innerForm.invalid);
    }, 100);
  }

  onValueChange(value: any) {
    this.charachtersCount = value.length;
    this.textareaCounter = `${this.charachtersCount}/${this.field.maximumValue}`;
  }
  
  onMicrophoneClick($event:any) {
    if (this.voiceRecognitionService.init('es-CO', this.field.campoId)){
      if (!this.microphoneActive){
        this.classMicrophone ="fa fa-solid fa-microphone microphone-active";
        this.voiceRecognitionService.start(this.field.campoId);
      }
      else {
        this.classMicrophone ="fa fa-solid fa-microphone";
        this.value.val = !this.value.val ? this.voiceRecognitionService.stop(this.field.campoId) : this.value.val + this.voiceRecognitionService.stop(this.field.campoId);
        this.change();
        this.formsComponent.actionFormula(this.field, this.value.val);
        this.formsComponent.fireEvents("ValueChanged", this.field.events, { value: this.value.val, field: this.field });
        this.formsComponent.checkForm(this.field.campoId, this.innerForm.invalid);
      }
      this.microphoneActive = !this.microphoneActive;
    }
  }

}
