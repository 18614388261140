import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { v4 as uuidv4 } from 'uuid';

import { DocumentsService } from '@services/documents.service';
import { ProxyTxApiService } from '@services/proxy-tx-api.service';

import { ImageVisorComponent } from '@components/image-visor/image-visor.component';
import { PDFVisorComponent } from '@components/pdfvisor/pdfvisor.component';

import { RootScopeService } from '@shared/root-scope.service';

import backandGlobal from '@env/env';
import { NotificationsService } from '@services/notifications.service';
import { Constants } from '@common/constants';

declare var kendo: any;

@Component({
  selector: 'app-file-grid',
  templateUrl: './file-grid.component.html',
  styleUrls: ['./file-grid.component.scss'],
})
export class FileGridComponent implements OnInit {

  @Input() field: any;
  @Input() value: any;
  @Input() form: any;
  @Input() errors: string;
  @Input() inputClass: string;
  @Input() rowId: string;
  @Input() documents: any[];

  files: any[] = [];
  filesAttach: any = [];
  dataSave: any = [];
  isNew: any = false;
  options: any = true;
  searchParams: any;
  visibleUnload: boolean = false;
  canUpload: boolean = true;
  IsGrid: boolean = false;

  controlId: string = '';
  display: string = '';
  messages: any;
  basicModalCloseResult: any;
  gridColumns: any;
  parameters: any;
  myPromise: any;
  fieldsModel: any = { Name: { type: 'text' }, Id: { type: 'text' }, Link: { type: 'text' }, State: { type: 'text' } };

  modalFile: any;

  constructor(
    private route: ActivatedRoute,
    // private router: Router,
    private activeModal: NgbActiveModal,
    private modalService: NgbModal,
    private documentsService: DocumentsService,
    private proxyTxApiService: ProxyTxApiService,
    private $rs: RootScopeService,
    private notificationsService: NotificationsService
  ) {
    this.initMessage();
    this.searchParams = this.route.snapshot.queryParams;
    this.$rs.$rootScope.arrayFilesDeleted = this.$rs.$rootScope.arrayFilesDeleted ? this.$rs.$rootScope.arrayFilesDeleted : [];
    this.isNew = !this.searchParams.rowId;
    this.validateWorkspaceId();
  }

  private initMessage(): void {
    this.messages = Constants.MESSAGES;
    // {
    //   Onlyone: this.$rs.$rootScope.lang == 'es'? 'Este campo solo permite la carga de un archivo': this.$rs.$rootScope.lang == 'en' ? 'This field only allows the upload of a file' : 'Este campo solo permite la carga de un archivo',
    //   confirm: this.$rs.$rootScope.lang == 'es' ? '¿Está seguro que desea eliminar este documento?' : this.$rs.$rootScope.lang == 'en' ? 'Are you sure you want to delete this document?' : '¿Está seguro que desea eliminar este documento?',
    //   failure: this.$rs.$rootScope.lang == 'es' ? 'No se pudo guardar el documento.' : this.$rs.$rootScope.lang == 'en' ? 'The document could not be saved' : 'No se pudo guardar el documento.',
    //   idMissing: this.$rs.$rootScope.lang == 'es' ? 'No se encontró el Id.' : this.$rs.$rootScope.lang == 'en' ? 'Id not found' : 'No se encontró el Id.',
    //   tableMissing: this.$rs.$rootScope.lang == 'es' ? 'No se encontró la api.' : this.$rs.$rootScope.lang == 'en' ? 'Api not found' : 'No se encontró la api.',
    //   CanNotDelete: this.$rs.$rootScope.lang == 'es' ? 'No tiene permisos para eliminar los documentos.' : this.$rs.$rootScope.lang == 'en' ? 'You do not have permission to delete the documents' : 'No tiene permisos para eliminar los documentos.',
    //   CanNotEdit: this.$rs.$rootScope.lang == 'es' ? 'No tiene permisos para editar los documentos.' : this.$rs.$rootScope.lang == 'en' ? 'You do not have permission to edit the documents' : 'No tiene permisos para editar los documentos.',
    //   CanNotCreate: this.$rs.$rootScope.lang == 'es' ? 'No tiene permisos para crear documentos.' : this.$rs.$rootScope.lang == 'en' ? 'You do not have permission to create documents' : 'No tiene permisos para crear documentos.',
    //   Success: this.$rs.$rootScope.lang == 'es' ? 'Los documentos se han almacenado exitosamente.' : this.$rs.$rootScope.lang == 'en' ? 'Documents have been successfully stored' : 'Los documentos se han almacenado exitosamente.',
    //   Error: this.$rs.$rootScope.lang == 'es' ? 'No hay nuevos documentos para almacenar' : this.$rs.$rootScope.lang == 'en' ? 'There are no documents to store' : 'No hay nuevos documentos para almacenar',
    //   NotDowload: this.$rs.$rootScope.lang == 'es' ? 'El archivo no ha sido guardado, !No es posible descargarlo!' : this.$rs.$rootScope.lang == 'en' ? 'The file has not been saved, it is not possible to download it!' : 'El archivo no ha sido guardado, !No es posible descargarlo!',
    //   Delete: this.$rs.$rootScope.lang == 'es' ? 'Documento eliminado con exito' : this.$rs.$rootScope.lang == 'en' ? 'Document deleted successfully' : 'Documento eliminado con exito',
    //   invalidName: this.$rs.$rootScope.lang == 'es' ? `El documento a guardar tiene una "," (coma) en su nombre y no se puede almacenar, cambie su nombre e intentelo de nuevo` : this.$rs.$rootScope.lang == 'en' ? `The document to save has a "," (comma) in its name and cannot be stored, change its name and try again` : `El documento a guardar tiene una "," (coma) en su nombre y no se puede almacenar, cambie su nombre e intentelo de nuevo`,
    // };
  }

  private validateWorkspaceId(): void{
    if (this.searchParams.workspaceId || this.searchParams.dato) {
      this.IsGrid = true;
      $('#btnUpload').hide();
      $('#btnFind').hide();
      $('#labelSelect').hide();
    }
  }

  generateNewIdAttachment() {
    return uuidv4();
  }

  ngOnInit(): void {
    this.searchParams = this.route.snapshot.queryParams;
    this.isNew = !this.searchParams.rowId;
    this.validateWorkspaceId();
    this.controlId = this.field.type + 's';
    this.display = ' Seleccionar archivos';
    this.addFiles();
  }

  addFiles(content?: any) {
    var arrayDocuments = [];
    this.canUpload = true;
    if (!!this.documents) {
      arrayDocuments = this.documents;
    }

    this.gridColumns = [
      { field: 'Name', title: 'Nombre de Archivo' },
      { field: 'State', title: 'Estado' },
    ];

    var buttons = [];
    // buttons.push({
    //   template: `<a class="btn btn-sm" id="btnDelete" data-bind="click: deleteClick" data-toogle="tooltip" title="Eliminar"><i class="mdi mdi-delete active-color"></i></a>`
    // });

    if (backandGlobal.stateOnline === true) {
      buttons.push({
        template: `<a class="btn btn-sm" data-bind="click: downloadClick" data-toogle="tooltip" title="Descargar"><i class="mdi mdi-cloud-download active-color"></i></a>`,
      });

      buttons.push({
        template: `<a class="btn btn-sm" data-bind="click: showClick" data-toogle="tooltip" title="Visualizar"><i class="mdi mdi-eye active-color"></i></a>`,
      });
    }

    this.gridColumns.push({
      command: buttons,
      attributes: {
        style: 'text-align:left; width:25%;',
      },
    });

    var dataFiles = [];
    if (arrayDocuments.length > 0) {
      arrayDocuments.forEach(function (value, index) {
        if (value != '') {
          let array = value.split('/');
          dataFiles.push({
            Name: value.split('/')[value.split('/').length-1].split(';')[value.split(';').length-1],
            Id: array.shift(),
            State: 'Cargado',
            Link:  array.join("/")
          });
        }
      });
    }

    setTimeout(() => {
      kendo.jQuery('#gridFiles').kendoGrid({
        dataSource: {
          data: dataFiles,
          schema: {
            model: {
              fields: this.fieldsModel,
            },
            data(data) {
              return data;
            },
            total(data) {
              return data.length;
            },
          },
          pageSize: 10,
          serverPaging: true,
          serverFiltering: true,
          serverSorting: true,
        },
        height: 250,
        filterable: {
          operators: {
            string: {
              eq: this.$rs.$rootScope.lang == 'es' ? 'Sean iguales a' : this.$rs.$rootScope.lang == 'en' ? 'Be equal to': 'Sean iguales a',
              neq: this.$rs.$rootScope.lang == 'es' ? 'No sean iguales a' : this.$rs.$rootScope.lang == 'en' ? 'Are not equal to' : 'Sean iguales a',
              startswith: this.$rs.$rootScope.lang == 'es' ? 'Comiencen con' : this.$rs.$rootScope.lang == 'en' ? 'Start with' : 'Comiencen con',
              contains: this.$rs.$rootScope.lang == 'es' ? 'Contengan' : this.$rs.$rootScope.lang == 'en' ? 'Contain' : 'Contengan',
              endswith: this.$rs.$rootScope.lang == 'es' ? 'Terminen con' : this.$rs.$rootScope.lang == 'en' ? 'Finish with' : 'Terminen con',
            },
            number: {
              eq: this.$rs.$rootScope.lang == 'es' ? 'Sean iguales a' : this.$rs.$rootScope.lang == 'en' ? 'Be equal to' : 'Sean iguales a',
              neq: this.$rs.$rootScope.lang == 'es' ? 'No sean iguales a' : this.$rs.$rootScope.lang == 'en' ? 'Are not equal to' : 'No sean iguales a',
              gte: this.$rs.$rootScope.lang == 'es' ? 'Sean mayor o igual a' : this.$rs.$rootScope.lang == 'en' ? 'Are greater than or equal to' : 'Sean mayor o igual a',
              gt: this.$rs.$rootScope.lang == 'es' ? 'Sean mayor a' : this.$rs.$rootScope.lang == 'en' ? 'Are greater than' : 'Sean mayor a',
              lte: this.$rs.$rootScope.lang == 'es' ? 'Sean menor o igual a' : this.$rs.$rootScope.lang == 'en' ? 'Are less than or equal to' : 'Sean menor o igual a',
              lt: this.$rs.$rootScope.lang == 'es' ? 'Sean menor a' : this.$rs.$rootScope.lang == 'en' ? 'Are less than' : 'Sean menor a',
            },
            date: {
              eq: this.$rs.$rootScope.lang == 'es' ? 'Sean iguales a' : this.$rs.$rootScope.lang == 'en' ? 'Be equal to' : 'Sean iguales a',
              neq: this.$rs.$rootScope.lang == 'es' ? 'No sean iguales a' : this.$rs.$rootScope.lang == 'en' ? 'Are not equal to' : 'No sean iguales a',
              gte: this.$rs.$rootScope.lang == 'es' ? 'Sean antes o en' : this.$rs.$rootScope.lang == 'en' ? 'Be it before or im' : 'Sean antes o en',
              gt: this.$rs.$rootScope.lang == 'es' ? 'Sean antes de' : this.$rs.$rootScope.lang == 'en' ? 'Before' : 'Sean antes de',
              lte: this.$rs.$rootScope.lang == 'es' ? 'Sean despues o en' : this.$rs.$rootScope.lang == 'en' ? 'Be it after or in' : 'Sean despues o en',
              lt: this.$rs.$rootScope.lang == 'es' ? 'Sean despues' : this.$rs.$rootScope.lang == 'en' ? 'After' : 'Sean despues',
            },
            enums: {
              eq: this.$rs.$rootScope.lang == 'es' ? 'Son iguales a' : this.$rs.$rootScope.lang == 'en' ? 'Be equal to' : 'Son iguales a',
              neq:  this.$rs.$rootScope.lang == 'es'  ? 'No son iguales a'  : this.$rs.$rootScope.lang == 'en'  ? 'Are not equal to'  : 'No son iguales a',
            },
          },
          messages: {
            info: this.$rs.$rootScope.lang == 'es' ? 'Muestra los datos que:' : this.$rs.$rootScope.lang == 'en' ? 'Show data that:' : 'Muestra los datos que:',
            filter: this.$rs.$rootScope.lang == 'es' ? 'Filtrar' : this.$rs.$rootScope.lang == 'en' ? 'Filter' : 'Filtrar',
            clear: this.$rs.$rootScope.lang == 'es' ? 'Limpiar' : this.$rs.$rootScope.lang == 'en' ? 'Clean' : 'Limpiar',
            and: this.$rs.$rootScope.lang == 'es' ? 'Y' : this.$rs.$rootScope.lang == 'en' ? 'And' : 'Y',
            or: this.$rs.$rootScope.lang == 'es' ? 'O' : this.$rs.$rootScope.lang == 'en' ? 'Or' : 'O',
          },
        },
        sortable: true,
        pageable: true,
        groupable: false,
        reorderable: true,
        resizable: true,
        columns: this.gridColumns,
      });

      setTimeout(() => {
        this.setActionsGrid();
      });
    }, 25);
  }

  setActionsGrid() {
    setTimeout(() => {
      var actionsClick = kendo.observable({
        // deleteClick: (e: any) => {
        //   var params = $(e.currentTarget).data();
        //   e.dataItem = $("#gridFiles").data("kendoGrid").dataItem($(e.currentTarget).closest("tr"));
        //   this.deleteFile(e);
        // },
        downloadClick: (e: any) => {
          // var params = $(e.currentTarget).data();
          e.dataItem = kendo.jQuery('#gridFiles')
            .data('kendoGrid')
            .dataItem($(e.currentTarget).closest('tr'));
          this.downloadFile(e);
        },
        showClick: (e: any) => {
          // var params = $(e.currentTarget).data();
          e.dataItem = kendo.jQuery('#gridFiles')
            .data('kendoGrid')
            .dataItem($(e.currentTarget).closest('tr'));
          this.showFile(e);
        },
      });

      kendo.bind($('#gridFiles'), actionsClick);
    }, 2500);
  }

  handleFilesSelect(e: any) {
    for (var j = 0; j < e.target.files.length; j++) {
      this.files.push(e.target.files[j]);
    }

    var buttons = [];
    buttons.push({
      template: `<a class="btn btn-sm" id="btnDelete" data-bind="click: deleteClick" data-toogle="tooltip" title="Eliminar"><i class="mdi mdi-delete active-color"></i></a>`,
    });

    if (backandGlobal.stateOnline === true) {
      buttons.push({
        template: `<a class="btn btn-sm" data-bind="click: downloadClick" data-toogle="tooltip" title="Descargar"><i class="mdi mdi-cloud-download active-color"></i></a>`,
      });

      buttons.push({
        template: `<a class="btn btn-sm" data-bind="click: showClick" data-toogle="tooltip" title="Visualizar"><i class="mdi mdi-eye active-color"></i></a>`,
      });
    }

    this.visibleUnload = true;
    var filesArr = Array.prototype.slice.call(e.target.files);
    var grid = kendo.jQuery('#gridFiles').data('kendoGrid').dataSource;
    filesArr.forEach((file: any) => {
      var reader = new FileReader();

      reader.onload = function (onReader) {
        var binaryFile = onReader.target.result;
        var base64File = btoa(binaryFile.toString());
        file.BufferBase = base64File;
        if (backandGlobal.stateOnline === false) {
          file.Base64 = binaryFile;
        }
      };

      reader.readAsDataURL(file);
      grid.add({
        Name: file.name,
        State: 'No cargado', 
        command: buttons, 
        attributes: { style: "text-align:left; width:25%;" } 
      });

      this.setActionsGrid();
    });

    this.filesAttach = filesArr;
  }

  closeDocuments() {
    this.activeModal.close();
  }

  //Funcion para guardar archivos
  saveFiles() {
    this.canUpload = false;
    if (this.field.IsMultiple == false) {
      if (this.field.value.val != '') {
        this.canUpload = true;
        this.MostrarMensaje('error', this.messages.OnlyOneUpload);
        return;
      }

      if (this.files.length > 1) {
        this.canUpload = true;
        this.MostrarMensaje('error', this.messages.OnlyOneUpload);
        return;
      }
    }

    if (this.files.length < 1) {
      this.canUpload = true;
      this.MostrarMensaje('error', this.messages.EmptyUploadFile);
      return;
    }

    for (let i = 0; i < this.files.length; i++) {
      if (this.files[i].Name.includes(',')) {
        // var index = i;
        //delete this.files.splice(index, 1);
        this.MostrarMensaje('error', this.messages.InvalidNameFile);
        var grid = kendo.jQuery('#gridFiles').data('kendoGrid').dataSource;
        var raw = grid.data();
        if (raw[1].State == 'No cargado') grid.remove(raw[1]);
        if (raw[1].State == 'Not loaded') grid.remove(raw[1]);
        return;
      }
    }

    if (backandGlobal.stateOnline === true) {
      var form = new FormData();
      for (var i in this.files) {
        form.append('data', this.files[i]);
      }
      let currentApp = JSON.parse(sessionStorage.getItem('currentApp'));
      let url = '';
      if (currentApp.storageProvider) {
        let entity = !this.searchParams.EntityCode ? this.field.viewName : this.searchParams.EntityCode;
        url = `/${entity}/${this.field.Code}`
      }
      this.documentsService.save(form, url).then((response: any) => {
        //Cambio
        var grid = kendo.jQuery('#gridFiles').data('kendoGrid').dataSource;
        var raw = grid.data();
        for (var j = raw.length - 1; j >= 0; j--) {
          if (!raw[j].Id) {
            grid.remove(raw[j]);
          }
        }

        var data = response;
        if (this.field.value) {
          if (this.field.value.val) this.field.value.val = '';
        }

        data.forEach((value: any) => {
          try {
            if (this.field.value.val != '') this.field.value.val += ',';
            let currentApp = JSON.parse(sessionStorage.getItem('currentApp'));
            this.field.value.val += currentApp.storageProvider ? `${value.Id}/${value.Link}` : `${value.Id}/${value.Name}`;
            grid.add({ Name: value.Name, Id: value.Id, State: 'Cargado', extensionFile: value.Name.split('.')[value.Name.split('.').length - 1], Link: value.Link });
          } catch (e) {
            if (!this.field.value) {
              this.field.value = [];
              if (!this.field.value.val) this.field.value.val = '';
            }
            let currentApp = JSON.parse(sessionStorage.getItem('currentApp'));
            this.field.value.val += `${value.Id}/${currentApp.storageProvider ? value.Link : value.Name}`;
            grid.add({ Name: value.Name, Id: value.Id, State: 'Cargado', extensionFile: value.Name.split('.')[value.Name.split('.').length - 1], Link: value.Link });
          }
        });

        if (this.field.value) {
          if (this.field.value.val) this.field.value.val = '';
        }

        raw.forEach((value: any) => {
          try {
            if (this.field.value.val != '') this.field.value.val += ',';
            let currentApp = JSON.parse(sessionStorage.getItem('currentApp'));
            this.field.value.val += `${value.Id}/${currentApp.storageProvider ? value.Link : value.Name}`;
          } catch (e) {
            if (!this.field.value) {
              this.field.value = [];
              if (!this.field.value.val) this.field.value.val = '';
            }
            let currentApp = JSON.parse(sessionStorage.getItem('currentApp'));
            this.field.value.val += `${value.Id}/${currentApp.storageProvider ? value.Link : value.Name}`;
          }
        });

        var dataIdAndName = this.field.value.val;
        try {
          var entity, field, Rowid;
          if (!this.searchParams.EntityCode) {
            (entity = this.field.viewName),
              (field = this.field.Code),
              (Rowid = this.field.rowId); //Estos parametros llegan si se carga el registro desde formulario
          } else {
            (entity = this.searchParams.EntityCode),
              (field = this.field.Code),
              (Rowid = this.searchParams.rowId); //Estos parametros llegan si se carga el registro desde la grid
          }
          var lastData = '';

          if (dataIdAndName != '' && field && entity && Rowid) {
            var Query_Select_Document = `Select [${field}] FROM ${entity} WHERE Id = '${Rowid}'`;
            var x = this.EvalQuery(Query_Select_Document);
            var response: any = JSON.stringify(x[0])
              .split(':')[0]
              .substring(2, JSON.stringify(x[0]).split(':')[0].length - 1);
            lastData = x[0][response] ? `${x[0][response]},` : '';
            var Query_Update_Document = `UPDATE ${entity} SET [${field}] = '${dataIdAndName}' WHERE Id = '${Rowid}'`;
            this.EvalQuery(Query_Update_Document);
          }

          var fields = localStorage.fieldsUploaded == undefined ? [] : JSON.parse(localStorage.fieldsUploaded);
          var value = dataIdAndName != '' ? `${field}:${dataIdAndName}` : `${field}:${null}`;
          fields.push(value);
          for (let i = 1; i < fields.length; i++) {
            var fieldaux = fields[i - 1].split(':')[0];
            var fieldarray = fields[i].split(':')[0];
            if (fieldarray == fieldaux && fieldarray == field) {
              fields.splice(i - 1, 1);
            }
          }

          delete localStorage.fieldsDeleted;
          localStorage.fieldsUploaded = JSON.stringify(fields);
        } catch (e) { }

        this.files = [];
        this.visibleUnload = false;
        this.canUpload = true;
        this.MostrarMensaje('success', this.messages.DocumentsSave);
        this.setActionsGrid();
      })
        .catch((e) => {
          this.MostrarMensaje('error', this.messages.FailUploadFile);
        });
    }
    // else {
    //   // Acá iría la lógica para guardar los archivos offline
    //   var docFiles: any = {};
    //   docFiles._id = this.field.campoId.toLowerCase();
    //   docFiles.typeAttachment = 'file';
    //   docFiles.endPointApi = 'tx';
    //   docFiles._attachments = {};
    //   docFiles.extraAttachments = {};
    //   for (var i in this.files) {
    //     var idFileAttached = this.generateNewIdAttachment();
    //     var imageType = this.files[i].type;
    //     var extensionFile = imageType.split('/')[1];
    //     var nameFile = this.files[i].name;
    //     var sizeFile = this.files[i].size;
    //     var imageBase64 = this.files[i].Base64;
    //     var stringFileAttach = this.dataURItoBlob(imageBase64);
    //     docFiles._attachments[`${idFileAttached}.${extensionFile}`] = {
    //       'content_type': imageType,
    //       'data': stringFileAttach
    //     }

    //     docFiles.extraAttachments[`${idFileAttached}/${nameFile}`] = {
    //       'idFile': idFileAttached,
    //       'rowId': this.field.rowId,
    //       'nameFile': nameFile,
    //       'typeFile': imageType,
    //       'extensionFile': extensionFile,
    //       'sizeFile': sizeFile
    //     }
    //   }

    //   // Acá llamaría el attachment service para insertar el documento con los respectivos attachments
    //   this.attachmentService.insertFileAttachment(docFiles._id, docFiles).then((attachment: any) => {
    //     this.attachmentService.getFileAttachments(attachment.id, this.field.rowId).then((docAttachment: any) => {
    //       // Acá se limpia la grid para poder volverla a pintar con los nuevos archivos cargados
    //       var grid = $('#gridFiles').data('kendoGrid').dataSource;
    //       var raw = grid.data();
    //       for (var i = raw.length - 1; i >= 0; i--) {
    //         grid.remove(raw[i]);
    //       }

    //       var dataFiles = docAttachment.filesAttachments;
    //       if (this.field.value) {
    //         if (this.field.value.val) {
    //           this.field.value.val = '';
    //         }
    //       }

    //       dataFiles.forEach((value: any) => {
    //         try {
    //           if (this.field.value.val != "")
    //             this.field.value.val += ',';
    //           this.field.value.val += `${value.idFile}/${value.nameFile}`;
    //           grid.add({ name: value.nameFile, Id: value.idFile, estado: 'Cargado', extensionFile: value.nameFile.split('.')[1] });
    //         } catch (e) {
    //           if (!this.field.value) {
    //             this.field.value = [];
    //             if (!this.field.value.val)
    //               this.field.value.val = '';
    //           }
    //           this.field.value.val += `${value.idFile}/${value.nameFile}`;
    //           grid.add({ name: value.nameFile, Id: value.idFile, estado: 'Cargado', extensionFile: value.nameFile.split('.')[1] });
    //         }
    //       });

    //       var dataIdAndName = this.field.value.val;
    //       try {
    //         var field;
    //         if (!this.searchParams.EntityCode) {
    //           field = this.field.Code; //Estos parametros llegan si se carga el registro desde formulario
    //         } else {
    //           field = this.field.Code;  //Estos parametros llegan si se carga el registro desde la grid
    //         }

    //         var fields = localStorage.fieldsUploaded == undefined ? [] : JSON.parse(localStorage.fieldsUploaded);
    //         var value = dataIdAndName != '' ? `${field}:${dataIdAndName}` : `${field}:${null}`;
    //         fields.push(value);
    //         for (let i = 1; i < fields.length; i++) {
    //           var fieldaux = fields[i - 1].split(':')[0];
    //           var fieldarray = fields[i].split(':')[0];
    //           if ((fieldarray == fieldaux) && (fieldarray == field)) {
    //             fields.splice(i - 1, 1);
    //           }
    //         }

    //         delete localStorage.fieldsDeleted;
    //         localStorage.fieldsUploaded = JSON.stringify(fields);
    //       } catch (e) { }

    //       this.files = [];
    //       this.visibleUnload = false;
    //       this.canUpload = true;
    //       this.MostrarMensaje('success', this.messages.Success);
    //       this.setActionsGrid();
    //     }).catch((error: any) => {
    //       console.log(`Ha ocurrido un error obteniendo los archivos insertados`);
    //     })
    //   }).catch((error: any) => {
    //     console.log(`Ha ocurrido un error insertando el file attachment, el error es el siguiente --> ${error}`);
    //     var message = this.$rs.$rootScope.lang == 'es' ? 'Ocurrio un error al subir un archivo, puede que este sea muy pesado para el sistema. Sólo se permiten archivos de menos de 30 megas.' : this.$rs.$rootScope.lang == 'en' ? 'An error occurred while uploading a file, it may be too heavy for the system. Only files under 30 megabytes are allowed.' : 'Ocurrio un error al subir un archivo, puede que este sea muy pesado para el sistema. Sólo se permiten archivos de menos de 30 megas.';
    //     this.MostrarMensaje('error', message);
    //   })
    // }
  }

  dataURItoBlob(dataURI: any) {
    var byteString = atob(dataURI.split(',')[1]);
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);

    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }

    var bb = new Blob([ab], { type: mimeString });

    return bb;
  }

  MostrarMensaje = function (Type, Message) {
    this.notificationsService.notificationApp(Type, Message);
  };

  EvalQuery(Query) {
    var Response = '';
    $.ajax({
      async: false,
      url: `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/sp/query`,
      type: 'POST',
      data: JSON.stringify({
        query: Query,
        parameters: {
          aType: 'sendMail',
          environment: `${backandGlobal.environment}`,
        },
      }),
      beforeSend: function (xhr) {
        xhr.setRequestHeader('Content-Type', 'application/json');
        xhr.setRequestHeader('Authorization', localStorage.Authorization);
      },
      success: function (success) {
        Response = success[0];
      },
      error: function (error) {
        console.log(`Error-->${JSON.stringify(error)}`);
      },
    });

    return Response;
  }

  //Funcion para borrar archivos
  deleteFile(e) {
    if (backandGlobal.stateOnline === true) {
      var grid = kendo.jQuery('#gridFiles').data('kendoGrid').dataSource;
      if (e.dataItem.Id == '' || e.dataItem.Id == undefined) {
        grid.remove(e.dataItem);
        var filesAux = this.files;
        this.files = [];
        for (var j = 0; j < filesAux.length; j++) {
          if (filesAux[j].Name != e.dataItem.Name) this.files.push(filesAux[j]);
        }

        if (this.files.length == 0) {
          this.visibleUnload = false;
        }

        this.MostrarMensaje('success', this.messages.DeleteFile);
        this.setActionsGrid();
        return; //Retorna si el archivo que se eliminó todavia no estaba cargado
      }

      var Query_Delete_From_Lappiz_All_Documents = `DELETE FROM Lappiz_AllDocuments WHERE Id = '${e.dataItem.Id}'`;
      this.EvalQuery(Query_Delete_From_Lappiz_All_Documents);
      let currentApp = JSON.parse(sessionStorage.getItem('currentApp'));
      if (currentApp.storageProvider) {
        this.documentsService.delete(e.dataItem.Link.replaceAll("/", "|"));
      }
      var arrayDocuments = [];
      var IsGrid = false;
      var deleteFromGrid = false; //Para saber si el documento que se elimina, viene desde la grid o no
      var entity, field, Rowid;
      if (this.field.value != undefined) {
        if (this.field.value.val != undefined || this.field.value.val != null) {
          //Estos parametros llegan si se borra el registro desde formulario
          arrayDocuments = this.field.value.val.split(',');
          var index = arrayDocuments.findIndex(item => item.split('/')[0].toString() === e.dataItem.Id.toString());
          entity = (this.field.viewName != undefined) ? this.field.viewName : this.searchParams.EntityCode;
          field = this.field.Code;
          Rowid = this.field.rowId != undefined ? this.field.rowId : this.searchParams.rowId;
          this.$rs.$rootScope.arrayFilesDeleted.push(arrayDocuments[index]);
        }
      } else {
        if (this.field.viewName == undefined) {
          //Estos parametros llegan si se borra el registro desde la grid
          deleteFromGrid = true;
          entity = this.searchParams.EntityCode;
          field = this.field.Code;
          Rowid = this.searchParams.rowId;
          var Query = `Select [${field}] FROM ${entity} WHERE Id = '${Rowid}'`;
          var x = this.EvalQuery(Query);
          //Se selecciona el valor que tiene el campo en BD para luego dividirlo en un array de N posiciones al hacer el split por ,
          var response = JSON.stringify(x[0]).split(':')[0].substring(2, JSON.stringify(x[0]).split(':')[0].length - 1);
          var lastData = x[0][response];
          arrayDocuments = lastData.split(',');
          var index = arrayDocuments.findIndex(
            (item) => item.split('/')[0].toString() === e.dataItem.Id.toString()
          );
          this.$rs.$rootScope.arrayFilesDeleted.push(arrayDocuments[index]);
        }
      }

      if (arrayDocuments.length > 0) {
        //Encontrar la posicion del array en donde se encuentra el id del documento que se elimino, retorna el numero de la posicion y se asignar a la variable index
        var index = arrayDocuments.findIndex(
          (item) => item.split('/')[0].toString() === e.dataItem.Id.toString()
        );
        // $rootScope.arrayFilesDeleted.push(arrayDocuments[index]);

        if (index > -1) {
          arrayDocuments.splice(index, 1); /*eliminar el archivo del array*/
        }

        var dataIdAndName =
          arrayDocuments.length > 0 ? arrayDocuments.join() : ''; //concanteno los nombres y id
        if (entity && field && Rowid) {
          var Query =
            dataIdAndName != ''
              ? `UPDATE ${entity} SET [${field}] = '${dataIdAndName}' WHERE Id = '${Rowid}'`
              : `UPDATE ${entity} SET [${field}] = NULL WHERE Id = '${Rowid}'`;
          this.EvalQuery(Query);
        }

        if (!deleteFromGrid) {
          var fields =
            localStorage.fieldsDeleted == undefined
              ? []
              : JSON.parse(localStorage.fieldsDeleted);
          var value =
            dataIdAndName != ''
              ? `${field}:${dataIdAndName}`
              : `${field}:${null}`;
          fields.push(value);
          for (let i = 1; i < fields.length; i++) {
            var fieldaux = fields[i - 1].split(':')[0];
            var fieldarray = fields[i].split(':')[0];
            if (fieldarray == fieldaux && fieldarray == field) {
              fields.splice(i - 1, 1);
            }
          }
          delete localStorage.fieldsUploaded;
          localStorage.fieldsDeleted = JSON.stringify(fields);
        }

        if (this.searchParams.dato) {
          IsGrid = true;
        }

        if (IsGrid) {
          this.parameters = {
            viewName: this.searchParams.viewName,
            id: this.field.entityParentId,
            app: backandGlobal.currentApp.name,
          };
        } else {
          this.parameters = {
            viewName: this.field.viewName
              ? this.field.viewName
              : this.searchParams.EntityCode,
            id: this.searchParams.rowId,
          };
        }

        if (!this.field.value) {
          this.field.value = [];
          if (!this.field.value.val) this.field.value.val = '';
        }

        this.dataSave[this.field.Code] =
          dataIdAndName != '' ? dataIdAndName : null;
        this.field.value.val =
          dataIdAndName != 'null' ? `${dataIdAndName}` : null;

        var IsNew = !location.hash.includes('rowId');
        if (!IsNew) {
          if (
            dataIdAndName != '' &&
            dataIdAndName != null &&
            dataIdAndName != 'null'
          ) {
            var urlTxUploadFiles = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/${this.parameters.viewName}/${this.parameters.id}`;
            this.myPromise = this.proxyTxApiService.operationTxApi(
              urlTxUploadFiles,
              JSON.stringify(this.dataSave),
              'update',
              backandGlobal.stateOnline
            );
          }
          this.MostrarMensaje('success', this.messages.DeleteFile);
          var grid = kendo.jQuery('#gridFiles').data('kendoGrid').dataSource;
          grid.remove(e.dataItem);
          this.setActionsGrid();
        } else {
          this.MostrarMensaje('success', this.messages.DeleteFile);
          var grid = kendo.jQuery('#gridFiles').data('kendoGrid').dataSource;
          grid.remove(e.dataItem);
          this.setActionsGrid();
        }
      } else {
        this.MostrarMensaje('success', this.messages.DeleteFile);
        var grid = kendo.jQuery('#gridFiles').data('kendoGrid').dataSource;
        grid.remove(e.dataItem);
        this.setActionsGrid();
      }
    }
    // else {
    //   var grid = $('#gridFiles').data('kendoGrid').dataSource;
    //   if (e.dataItem.Id == '' || e.dataItem.Id == undefined) {
    //     grid.remove(e.dataItem);
    //     var filesAux = this.files;
    //     this.files = [];
    //     for (var i = 0; i < filesAux.length; i++) {
    //       if (filesAux[i].name != e.dataItem.name)
    //         this.files.push(filesAux[i]);
    //     }

    //     if (this.files.length == 0) {
    //       this.visibleUnload = false;
    //     }

    //     this.MostrarMensaje('success', this.messages.Delete);
    //     this.setActionsGrid();
    //     return;
    //   }

    //   if (this.field.viewName == undefined) {
    //     // Acá llega cuando se borra el registro desde la grid
    //     this.MostrarMensaje('warning', `El borrado de archivos desde la vista tipo lista no está habilitada en modo Offline`);
    //     return;
    //   }

    //   this.attachmentService.deleteFileAttachment(this.field.campoId, e.dataItem.Id, e.dataItem.name, e.dataItem.extensionFile).then(() => {
    //     var arrayDocuments = [], IsGrid = false, deleteFromGrid = false, entity, field, rowId;
    //     if (this.field.value != undefined) {
    //       if (this.field.value.val != undefined || this.field.value.val != null) {
    //         arrayDocuments = this.field.value.val.split(',');
    //         var index = arrayDocuments.findIndex(item => item.split('/')[0].toString() === e.dataItem.Id.toString());
    //         entity = (this.field.viewName != undefined) ? this.field.viewName : this.searchParams.EntityCode;
    //         field = this.field.Code;
    //         rowId = this.field.rowId != undefined ? this.field.rowId : this.searchParams.rowId;
    //         this.$rs.$rootScope.arrayFilesDeleted.push(arrayDocuments[index]);
    //       }
    //     }

    //     if (arrayDocuments.length > 0) {
    //       var index = arrayDocuments.findIndex(item => item.split('/')[0].toString() === e.dataItem.Id.toString());

    //       if (index > -1) { arrayDocuments.splice(index, 1); }

    //       var dataIdAndName = arrayDocuments.length > 0 ? arrayDocuments.join() : "";

    //       if (!deleteFromGrid) {
    //         var fields = localStorage.fieldsDeleted == undefined ? [] : JSON.parse(localStorage.fieldsDeleted);
    //         var value = dataIdAndName != '' ? `${field}:${dataIdAndName}` : `${field}:${null}`;
    //         fields.push(value);
    //         for (let i = 1; i < fields.length; i++) {
    //           var fieldaux = fields[i - 1].split(':')[0];
    //           var fieldarray = fields[i].split(':')[0];
    //           if ((fieldarray == fieldaux) && (fieldarray == field)) {
    //             fields.splice(i - 1, 1);
    //           }
    //         }
    //         delete localStorage.fieldsUploaded;
    //         localStorage.fieldsDeleted = JSON.stringify(fields);
    //       }

    //       if (!this.field.value) {
    //         this.field.value = [];
    //         if (!this.field.value.val)
    //           this.field.value.val = '';
    //       }

    //       this.field.value.val = dataIdAndName != 'null' ? `${dataIdAndName}` : null;
    //       this.MostrarMensaje('success', this.messages.Delete);
    //       var grid = $('#gridFiles').data('kendoGrid').dataSource;
    //       grid.remove(e.dataItem);
    //       this.setActionsGrid();
    //     } else {
    //       this.MostrarMensaje('success', this.messages.Delete);
    //       var grid = $('#gridFiles').data('kendoGrid').dataSource;
    //       grid.remove(e.dataItem);
    //       this.setActionsGrid();
    //     }
    //   }).catch((error: any) => {
    //     console.log(`Ha ocurrido un error eliminando el archivo --> ${JSON.stringify(error)}`);
    //   });
    // }
  }

  downloadFile(e: any) {
    if (e.dataItem.Id == '' || e.dataItem.Id == undefined || e.dataItem.Id == null) {
      this.MostrarMensaje('error', this.messages.NotDownload);
      return;
    }
    let currentApp = JSON.parse(sessionStorage.getItem('currentApp'));
    let url = '';
    if (currentApp.storageProvider) {
      this.documentsService.downloadFile(e.dataItem.Link.replaceAll("/", "|")).then((response: any) => {
        // Usa la librería 'file-saver' para guardar el archivo
        let urlDownload ='';
        if(currentApp.storageProvider == "AzureBlobStorage"){
          urlDownload = `data:application/octet-stream;base64,${response}`;
        }else{
          urlDownload = JSON.parse(response).url;
        }
        // Usa la librería 'file-saver' para guardar el archivo
        // Crear un elemento <a> para descargar el archivo
        const a = document.createElement('a');
        a.href = urlDownload;``
        a.download = e.dataItem.Name; // Cambia el nombre del archivo según sea necesario
        document.body.appendChild(a);
        // Hacer clic en el enlace para iniciar la descarga
        a.click();
        // Liberar el obje.to URL
        window.URL.revokeObjectURL(urlDownload);
      }).catch((error)=>{
        console.log(error)
      });
    } else {
      url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/documents/${e.dataItem.Id}`;
      window.open(url, '_blank');
    }
  }

  showFile(e: any) {
    if (e.dataItem.Id == '' || e.dataItem.Id == undefined || e.dataItem.Id == null) {
      this.MostrarMensaje('error', this.messages.NotPreviewFile);
      return;
    }
    let currentApp = JSON.parse(sessionStorage.getItem('currentApp'));
    var fileId = !currentApp.storageProvider ? e.dataItem.Id : e.dataItem.Link.replaceAll("/", "|");
    var fileExt: string = e.dataItem.Name.split('.')[e.dataItem.Name.split('.').length - 1];

    if (fileExt.includes('png') || fileExt.includes('jpeg') || fileExt.includes('jpg')) {
      // Cuando es imagen
      const modalRef = this.modalService.open(ImageVisorComponent, {
        animation: true,
        size: 'lg',
      });

      modalRef.componentInstance.imgId = fileId;
      modalRef.componentInstance.imgExt = fileExt;

      modalRef.result.then((response: any) => { }).catch((error: any) => { });
    } else if (fileExt.includes('pdf')) {
      // Cuando es PDF
      const modalRef = this.modalService.open(PDFVisorComponent, {
        animation: true,
        size: 'lg',
      });

      modalRef.componentInstance.fileId = fileId;

      modalRef.result.then((response: any) => { }).catch((error: any) => { });
    } else {
      this.MostrarMensaje('warning', this.messages.TypeFileInvalidateToView)
    }
  }

  getDateFormat(date): string {
    let charts = ['T', ':', '.', '-', 'Z']
    charts.forEach(chart => {
      date = date.split(chart).join('');
    })
    return date;
  }
}
