<div class="input-group">
  <div class="row" style="width: 100%; text-align: right;">
    <span class="pull-right" style="margin-top:0px; width: 100%;">
      <button class="btn btn-outline-primary invalid ng-star-inserted" (click)="addRecordDetails()"
        [hidden]="isViewItem">
        <i class="fa fa-plus"></i>
      </button>
      <button class="btn btn-outline-primary invalid ng-star-inserted" (click)="EditChangesGrid()"
        [hidden]="!editableFields || isViewItem">
        <i class="fa fa-floppy-o"></i>
      </button>
      <button type="file" class="btn btn-outline-primary invalid ng-star-inserted"
        [hidden]="!visibleImport  || isViewItem">
        <i class="fa fa-upload"></i>
        <input type="file" class="file" (change)="importExcel($event,field.campoId)"
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel">
      </button>
    </span>
    <nav class="inner-nav-actions" [hidden]="!actionsMenuOptions">
      <div class="container-fluid">
        <ul class="nav navbar-nav">
          <li class="dropdown">
            <button [hidden]="!actionsMenuOptions" data-toggle="dropdown"><i
                class="fa fa-list"></i>&nbsp;&nbsp;{{moreActions}}</button>
            <ul class="dropdown-menu contextual-menu">
              <li *ngFor="let Action of GlobalActions" class="sub_menu">
                <div [ngSwitch]="Action.Type">
                  <button class="active-color mdl-button mdl-button--acciones mdl-button--primary"
                    name='{{Action.ActionId}}' *ngSwitchCase="'WorkFlow'"
                    [disabled]="form.$invalid || !Action.isEditable" (click)="GlobalActionCode(Action);"
                    title="{{Action.Name}}">
                    <i class="fa {{Action.IconAwesomeAction}}"></i>
                    {{Action.Name}}
                  </button>
                  <button class="active-color mdl-button mdl-button--acciones mdl-button--primary"
                    name='{{Action.ActionId}}' *ngSwitchCase="'Codigo'" [disabled]="form.$invalid || !Action.isEditable"
                    (click)="GlobalActionCode(Action);" title="{{Action.Name}}">
                    <i class="fa {{Action.IconAwesomeAction}}"></i>
                    {{Action.Name}}
                  </button>
                  <button class="active-color mdl-button mdl-button--acciones mdl-button--primary"
                    name='{{Action.ActionId}}' *ngSwitchCase="'NavegarVista'"
                    [disabled]="form.$invalid || !Action.isEditable"
                    (click)="OpenDirective(Action.QueryString, Action);" title="{{Action.Name}}">
                    <i class="fa {{Action.IconAwesomeAction}}"></i>
                    {{Action.Name}}
                  </button>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
    </nav>
  </div>
  <br />
</div>

<div class="row" id="grid-general">
  <div class="col-xs-12">
    <div id="{{field.campoId}}"></div>
  </div>
</div>
