import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// TODO: Crear Error Component para cuando haya un error mostrar dicha página

// Guard
import { AuthGuard } from "@core/guard/auth.guard";

// Components
import { BaseComponent } from "@components/layout/base/base.component";

// Lappiz Components
import { AsignarRolComponent } from '@components/asignar-rol/asignar-rol.component';
import { ChatComponent } from '@components/chat/chat.component';
// import { DashboardComponent } from '@components/dashboard/dashboard.component';
import { EncuestaComponent } from '@components/encuesta/encuesta.component';
import { FormsComponent } from '@components/forms/forms.component';
import { GridsComponent } from '@components/grids/grids.component';
import { ListComponent } from '@components/list/list.component';
import { PermisosComponent } from '@components/permisos/permisos.component';
import { RecoveryComponent } from '@components/recovery/recovery.component';
import { RolesComponent } from '@components/roles/roles.component';
import { RolComponent } from '@components/roles/rol/rol.component';
import { TimelineComponent } from '@components/timeline/timeline.component'
// import { VideoCallComponent } from '@components/video-call/video-call.component';
import { SchedulerComponent } from '@components/scheduler/scheduler.component';
import { KanbanComponent } from '@components/kanban/kanban.component';
import { PageComponent } from '@components/page/page.component';

const routes: Routes = [
  // {
  //   path: '',
  //   component: BaseComponent,
  //   children: [
  //     { path: 'friends' },
  //     { path: 'navigation' },
  //     { path: 'videoCall' }
  //   ]
  // },
  { path: 'auth', loadChildren: () => import('@components/auth/auth.module').then(m => m.AuthModule) },
  {
    path: '',
    component: BaseComponent,
    children: [
      {
        path: 'chat',
        component: ChatComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'createrol',
        component: RolComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'createrol/:idRol',
        component: RolComponent,
        canActivate: [AuthGuard]
      },
      // {
      //   path: 'dashboard',
      //   component: DashboardComponent,
      //   canActivate: [AuthGuard]
      // },
      {
        path: 'encuesta',
        component: EncuestaComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'kanban',
        component: KanbanComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'forms',
        component: FormsComponent
      },
      {
        path: 'viewItem',
        component: FormsComponent
      },
      {
        path: 'grids',
        component: GridsComponent
      },
      {
        path: 'list',
        component: ListComponent
      },
      {
        path: 'scheduler',
        component: SchedulerComponent
      },
      {
        path: 'permisos',
        component: PermisosComponent,
      },
      {
        path: 'permisos/:idRol',
        component: PermisosComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'recovery',
        component: RecoveryComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'roles',
        component: RolesComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'rolesxusers',
        component: AsignarRolComponent,
        canActivate: [AuthGuard]
      },
      {
        path: 'timeline',
        component: TimelineComponent,
        canActivate: [AuthGuard]
      },
      { path: '', redirectTo:'/', pathMatch: 'full' }
    ]
  },
  {
    path: 'page/:slug/:appName',
    component: PageComponent
  },
  { path: '**', redirectTo: 'auth', pathMatch: 'full' }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', useHash: true }),
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
