<div class="cronometro">
  <div class="display">
    {{format(hh)}} Horas, {{format(mm)}} Minutos, {{format(ss)}} Segundos.
  </div>
  <div class="buttons">
    <button id="play" [hidden]="isRunning" (click)="clickHandler()" 
      type="button" class="btn btn-outline-dark">
      <i class="fa fa-play"></i>
    </button>
    <button id="pause" [hidden]="!isRunning" (click)="clickHandler()" 
      type="button" class="btn btn-outline-dark">
      <i class="fa fa-pause"></i>
    </button>
    <button id="reset" (click)="clearTimer()" type="button" 
      [hidden]="!isreset" class="btn btn-outline-dark">
      <i class="fa fa-refresh"></i>
    </button>
  </div>
</div>