<form [formGroup]="innerForm">
  <div class="container" [id]="field.campoId">
    <div *ngFor="let data of objetData; let $index = index;">
      <input class="radio" type="radio" [id]="field.campoId + '_' + $index" [(ngModel)]="selected.data" name="{{ group }}"
        [value]="data" ngClass="inputClass" (change)="changeRadio(data.text)" required="field.required"
        formControlName="field" [attr.disabled]="!field.IsEditable ? true : null || field.disabled ? true : null" />
      <label>{{data.text}}</label>
    </div>
  </div>
</form>
