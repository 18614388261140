<div>
  <div class="input-group">
    <label for="email" style="margin-bottom: 0rem">{{field.name}}
      <i *ngIf="field.required" class="form-group" style="color:red;">*</i>
    </label>
    <span class="input-group-btn" [hidden]="!field.largeImage">
      <img [src]="field.urlPrefix/value.val" class="btn btn-default"
        style="padding: 0; margin-right: 34px; width: 34px;" />
    </span>
  </div>
  <img [src]="field.urlPrefix/value.val" style="width: 300px;" [hidden]="field.largeImage" />

  <div *ngIf="errors" [(ngModel)]="errors" class="input-error" role="alert">{{errors}}</div>
</div>
