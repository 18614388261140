import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class OnlineWorkerService {

  constructor() { }

  inlineOnlineWorker = function () {
    // @ts-ignore
    importScripts('https://cdn.jsdelivr.net/npm/pouchdb@7.2.1/dist/pouchdb.min.js');

    this.addEventListener('message', function (e) {
      var entityNames = e.data.functionArgs != undefined ? e.data.functionArgs : [];
      const appContextDB = new PouchDB('bd_AppContext');
      appContextDB.post({ _id: 'synchronization_information', Id: 'synchronization_information', lastSynchronization: getDate(0) })
      uncompressAllIncludeEntities(entityNames);
      var backandGlobal = JSON.parse(e.data.functionGlobal);
      var Authorization = e.data.Token, tenantId = e.data.TenantId,
        index = 0, dataforpouch = [],
        dbs = [], dbEntities = [], dbSqlEntities = [],
        rowsEntities = [], changesEntities = [];

      function ajaxPost(url, params, callback?: any): any {
        var req = new XMLHttpRequest();
        req.open("POST", url, false);
        req.setRequestHeader('Content-Type', 'application/json');
        req.setRequestHeader('Authorization', Authorization);
        req.addEventListener('load', function () {
          // Llamada a la función callback pasándole la respuesta
          callback(req.responseText);
        });
        req.addEventListener('error', function () {
          //console.error("Error de red");
        });
        req.send(params);
      }

      function view(obj: any): any {
        let tipo = typeof obj;
        if (tipo !== "object") {
          return tipo === "undefined" ? "undefined" :
            tipo === "string" ? `"${obj}"` :
              obj.toString();
        } else if (obj === null) {
          return "null";
        } else {
          let isArray = Array.isArray(obj);
          let cad = isArray ? "[" : "{";
          let keys = Object.keys(obj);
          keys.forEach(key => {
            if (!isArray || /\d+/.test(key)) {
              if (cad.length > 1) cad += ",";
              cad += (isArray ? "" : `"${key}":`) + view(obj[key]);
            }
          })
          cad += isArray ? "]" : "}";
          return cad;
        }
      }

      function uncompressAllIncludeEntities(entities: any): any {
        var aEntities = entities;
        for (let j = 0; j < aEntities.length; j++) {
          delete aEntities[j].TablaId;
          if (aEntities[j].NodeEntities.length != 0) {
            uncompressAllIncludeEntities(aEntities[j].NodeEntities);
          }
        }
        return "Ok";
      }

      function orderEntities(a, b) {
        const field = a.Alias ? 'Alias' : 'Entity'
        if (a[field] > b[field]) {
          return 1;
        }
        if (a[field] < b[field]) {
          return -1;
        }
        return 0;
      }

      function periodicLogic(entities) {
        const contextDB = new PouchDB(`bd_AppContext`);
        contextDB.get(`synchronization_information`).then(responseDoc => {
          const body = { date: null, entities };
          const url = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/history/getNewRows`
          if (responseDoc) {
            body.date = responseDoc["lastSynchronization"];
          }
          try {
            ajaxPost(url, JSON.stringify(body), (response) => {
              if (!response) { resetSyncronization(entities); return };
              response = JSON.parse(response);
              if (response.length <= 0) { resetSyncronization(entities); return };
              const orderlyResponse = response.sort(orderEntities);
              synchronizeChanges(orderlyResponse).then(response => {
                responseDoc["lastSynchronization"] = getDate(0);
                contextDB.put(responseDoc);
                resetSyncronization(entities);
                return;
              });
            })
          } catch (error) {
            resetSyncronization(entities);
          }
        });
      }
      function resetSyncronization(entities) {
        setTimeout(() => {
          periodicLogic(entities);
        }, 60000);
      }
      function synchronizeChanges(changes) {
        try {
          let currentEntity = changes[0].Entity;
          let contextDB = new PouchDB(`bd_${currentEntity}`);
          let docsToSynchronized = [];
          let myDate = getDate(-5);
          docsToSynchronized.push(prepareInfoDoc(changes[0].Data, currentEntity, myDate));

          // Crear un array de promesas
          const promises = [];

          for (let i = 1; i < changes.length; i++) {
            if (changes[i].Entity != currentEntity) {
              (function (contextDB, currentEntity, docsToSynchronized) {
                const currentDocsPromise = contextDB.allDocs({ include_docs: true, attachments: true });
                promises.push(currentDocsPromise.then((currentDocs) => {
                  for (let doc of docsToSynchronized) {
                    let indexMatchRows = currentDocs.rows.findIndex(currentDoc => currentDoc.id.toUpperCase() == doc.Id.toUpperCase());
                    if (indexMatchRows > 0)
                      doc._rev = currentDocs.rows[indexMatchRows].doc._rev;
                  }
                  try {
                    return contextDB.bulkDocs(docsToSynchronized);
                  } catch (error) {
                    console.log('linea 141')
                    return
                  }
                }).catch(error => {console.log('linea 144')}));
              })(contextDB, currentEntity, [...docsToSynchronized]);

              currentEntity = changes[i].Entity;
              contextDB = new PouchDB(`bd_${currentEntity}`);
              docsToSynchronized = [];
            }
            docsToSynchronized.push(prepareInfoDoc(changes[i].Data, currentEntity, myDate));
          }

          // Agregar la última operación después del bucle
          const currentDocsPromise = contextDB.allDocs({ include_docs: true, attachments: true });
          promises.push(currentDocsPromise.then((currentDocs) => {
            for (let doc of docsToSynchronized) {
              let indexMatchRows = currentDocs.rows.findIndex(currentDoc => currentDoc.id.toUpperCase() == doc.Id.toUpperCase());
              if (indexMatchRows > 0)
                doc._rev = currentDocs.rows[indexMatchRows].doc._rev;
            }
            try {
              return contextDB.bulkDocs(docsToSynchronized);
            } catch (error) {
              console.log('linea 165')
              return
            }
          }).catch(error =>{ console.log('linea 168')}));

          // Devolver la promesa combinada usando Promise.all
          return Promise.all(promises);
        } catch (error) {
          console.log('linea 173')
          return;
        }
      }


      function prepareInfoDoc(data, entity, myDate) {
        data.entityName = entity;
        data.syncDate = myDate;
        data._id = data.Id.toLowerCase();
        return data;
      }

      function getDate(add) {
        let date = new Date(new Date().toISOString())
        let myDate = new Date(date.setHours(date.getHours() + (add))).toISOString();
        return myDate;
      }

      entityNames = entityNames.sort(orderEntities);

      for (let i = 0, lengthEntities = entityNames.length; i < lengthEntities; i++) {
        index = i;
        var entity = entityNames[i].Alias;
        dbEntities[i] = new PouchDB(`bd_${entity}`);
      }
      for (let i = 0, lengthEntities = entityNames.length; i < lengthEntities; i++) {
        index = i;
        dbs[i] = new PouchDB(`bd_${entityNames[i].Alias}`);
        var entity = entityNames[i].Code.split("modelo.")[1];
        var urldataentity = '';
        var opc: any = {};
        var filter = entityNames[i].filter;
        urldataentity = `${backandGlobal.api2}/${backandGlobal.currentApp.name}.api/api/lappiz/getdatasync/${entity}/${tenantId}`;
        opc = { take: 10, skip: 0, page: 1, pageSize: 10, tenantId: tenantId, filter };
        var includeEntities = view(entityNames[i].NodeEntities);
        var codeIncludeEntities = includeEntities.split("Code").join("model");
        var aliasIncludeEntities = codeIncludeEntities.split("Alias").join("as");
        var includeEntitiesString = aliasIncludeEntities.split("NodeEntities").join("include");
        var stringInclude = `${includeEntitiesString}`;
        opc.includeEntities = stringInclude.split(`"modelo.`).join("modelo.").split(`","as"`).join(',"as"');
        var settings: any = {
          "url": urldataentity,
          "method": "POST",
          "timeout": 0,
          "headers": {
            "Authorization": Authorization,
            "Content-Type": "application/json"
          },
          "data": JSON.stringify(opc)
        };

        settings = JSON.stringify(settings);

        ajaxPost(urldataentity, settings, function (respuesta: any): any {
          respuesta = JSON.parse(respuesta);
          if (respuesta.data) {
            let myDate = getDate(-5);
            rowsEntities[index] = respuesta;
            rowsEntities[index].data.rows.map(function (row) {
              row.entityName = entityNames[index].Alias;
              row.syncDate = myDate;
              row._id = row.Id.toLowerCase();
              dataforpouch.push(row);
            });
            // dataforpouch.push({ _id: 'synchronization_information', Id: 'synchronization_information', lastSynchronization: myDate })
            // Array.prototype.push.apply(dataBulk, dataforpouch);

            if (dataforpouch.length > 1000) {
              //dataforpouch = dataforpouch.slice(0, 1000);
              // Hacer bulk parcial cada 1000 registros (docs)
              for (var i = 0; i < dataforpouch.length; i += 1000) {
                var datapousch = dataforpouch.slice(i, i + 1000);
                dbs[index].bulkDocs(datapousch).then(function (result) { })
                  .catch(function (err) { console.log("Error > 1000:", err) });
              }
            } else if (dataforpouch.length <= 1000) {
              dbs[index].bulkDocs(dataforpouch).then(function (result) { })
                .catch(function (err) { console.log("Error <= 1000:", err) });
            }

            dataforpouch = [];
          } else {
            rowsEntities[index] = respuesta[0];
            if (!rowsEntities[index]) return;
            rowsEntities[index].map(function (row) {
              row.entityName = entityNames[index].Alias;
              row.syncDate = new Date();
              row._id = row.Id.toLowerCase();
              dataforpouch.push(row);
            });

            dbs[index].bulkDocs(dataforpouch)
              .then(function (result) { })
              .catch(function (err) { console.log("Error", err) });

            dataforpouch = [];
          }
        });
      }

      for (let i = 0, lengthEntities = entityNames.length; i < lengthEntities; i++) {
        index = i;
        var entity = entityNames[i].Alias;
        dbEntities[i] = new PouchDB(`bd_${entity}`);
        if (i == entityNames.length - 1) {
          dbEntities[i].info().then(function (info) {
            console.log("Done sync");
            const entities = entityNames.reduce((concat, entity) => { concat.push(entity.Alias); return concat }, [])
            setTimeout(() => {
              periodicLogic(entities);  // Aquí deberías poner tu lógica a ejecutar cada minuto
            }, 60000);
            //@ts-ignore
            postMessage("Sync done");
          }).catch(function (err) {
            console.log(err);
          });
        } else {
          dbEntities[i].info().then(function (info) {

          }).catch(function (err) {
            console.log(err);
          });
        }
      }
    }, false);
  }

  myOnlineWorker = new Worker(URL.createObjectURL(new Blob(["(" + this.inlineOnlineWorker.toString() + ")()"], { type: 'text/javascript' })));

  _getWorker(functionParams: any, functionGlobal: any, Token: any, TenantId: any): any {
    var promise = new Promise((resolve, reject) => {
      this.myOnlineWorker.onmessage = function onmessage(oEvent) {
        resolve(oEvent.data);
      };
    });

    // @ts-ignore
    this.myOnlineWorker.postMessage({
      'functionArgs': functionParams,
      'functionGlobal': functionGlobal,
      'Token': Token,
      'TenantId': TenantId
    });

    return promise;
  }

  executeOnlineWorker(functionParams: any, functionGlobal: any, Token: any, TenantId: any): Promise<any[]> {
    return this._getWorker(functionParams, functionGlobal, Token, TenantId).then((response: any) => {
      if (response == 'Sync done') {
        return true;
      } else if (response == 'Error sync') {
        return false;
      }
    });
  }
}
