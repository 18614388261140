import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { RouteConfigLoadEnd, RouteConfigLoadStart, Router } from '@angular/router';

import { EndPointService } from '@services/end-point.service';
import { ISetPassword } from 'src/app/interfaces/ISetPassword';
import { GlobalEventsService } from '@services/global-events.service';
import { ProxyConfigApiService } from '@services/proxy-config-api.service';
import { NotificationsService } from '@services/notifications.service';
import { Constants } from '@common/constants';

@Component({
  selector: 'app-recovery',
  templateUrl: './recovery.component.html',
  styleUrls: ['./recovery.component.scss'],
})
export class RecoveryComponent implements OnInit {
  public loading: boolean;
  public errorMessage: string;
  public showPassword: boolean;
  public formRecovery: UntypedFormGroup;
  public showNewPassword: boolean;
  public errorMessagePassword: string;
  public showConfirmNewPassword: boolean;
  public passwordFaild: boolean = false;;
  private readonly passwordRegExp: RegExp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!"#$%&'()*+,-./:;<=>?@[^_{|}~])[A-Za-z\d!"#$%&'()*+,-./:;<=>?@[^_{|}~]{10,}$/;

  public constructor(
    private readonly router: Router,
    private readonly endPointService: EndPointService,
    private readonly globalEvents: GlobalEventsService,
    private readonly proxyConfigApiService: ProxyConfigApiService,
    private notificationsService: NotificationsService
  ) {
    // Spinner for lazyload modules
    this.router.events.forEach((event) => {
      if (event instanceof RouteConfigLoadStart) {
        this.loading = true;
      } else if (event instanceof RouteConfigLoadEnd) {
        this.loading = false;
      }
    });
    this._setErrorVariable();
    this._loadFormGroup();
  }

  public async ngOnInit(): Promise<void> {
    try {
      const config: any = await this.proxyConfigApiService.getEvents('RecoveryLoaded');
      this.recoveryLoaded(config);
    } catch (error) {
      console.log(error);
    }
  }

  private _setErrorVariable(): void {
    this.loading = false;
    this.errorMessage = '';
    this.errorMessagePassword = '';
  }

  private _loadFormGroup(): void {
    this.formRecovery = new UntypedFormGroup({
      password: this._basicFormControlValidation(),
      newPassword: this._basicFormControlValidation(),
      confirmNewPassword: this._basicFormControlValidation(),
    }, { validators: this.passwordMatchValidator });
  }

  private _basicFormControlValidation(): UntypedFormControl {
    return new UntypedFormControl('', [
      Validators.required,
      Validators.pattern(this.passwordRegExp),
    ])
  }

  private passwordMatchValidator = (group: UntypedFormGroup) => {
    const password = group.get('password')?.value;
    const newPassword = group.get('newPassword')?.value;
    const confirmNewPassword = group.get('confirmNewPassword')?.value;
  
    return password !== newPassword && newPassword === confirmNewPassword
      ? null
      : { passwordMismatch: true };
  };

  private recoveryLoaded(config): void {
    let params = {
      cancel: false,
    };
    this.globalEvents.fireEvents('RecoveryLoaded', config, params);
  }

  public async onRecovery(e: any) {
    try {
      e.preventDefault();
      this.errorMessage = '';
      this.errorMessagePassword = '';
      await this.recovery();
    } catch (error) {
      console.log(error);
    }
  }

  public validatePassword(): void {
    this.errorMessage = '';
    this.errorMessagePassword = '';
    let password = this.formRecovery.value.password;
    let newPassword = this.formRecovery.value.newPassword;
    let confirmNewPassword = this.formRecovery.value.confirmNewPassword;
    if (newPassword != confirmNewPassword) {
      this.errorMessagePassword = 'La contraseña actual no coincide con la confirmación';
      return;
    }

    if (password === newPassword) {
      this.errorMessagePassword = 'La contraseña nueva no puede ser igual a la anterior';
      return;
    }
    this.passwordFaild = false;
  }

  private getRecoveryObject(): ISetPassword {
    const setPassword: ISetPassword = {
      Id: sessionStorage.getItem('userId'),
      OldPassword: this.formRecovery.value.password,
      NewPassword: this.formRecovery.value.newPassword,
      ConfirmPassword: this.formRecovery.value.confirmNewPassword,
    };
    return setPassword;
  }

  private redirectToLogin(): void {
    setTimeout(() => {
      localStorage.removeItem('Authorization');
      sessionStorage.removeItem('workspaceId');
      sessionStorage.removeItem('userId');

      if (!localStorage.getItem('Authorization')) {
        this.router.navigate([`/auth/login/${sessionStorage.appName}`]);
      }
    }, 1000);
  }

  public async recovery(): Promise<void> {
    this.passwordFaild = false;
    try {
      const response: any = await this.proxyConfigApiService.configRecovery(
        this.endPointService.setPassword(null, sessionStorage.getItem('workspace')),
        this.getRecoveryObject()
      );
      if (!response) {
        await this.proxyConfigApiService.NotificationChangedPassword(this.endPointService.NotificationChangedPassword(sessionStorage.getItem('userId'), sessionStorage.appName))
        this.notificationsService.notificationApp('success',Constants.MESSAGES.UpdatedPassword);
        this.redirectToLogin();
      } else if (response?.ModelState) {
        const errorMessage: string = response?.ModelState['model.ConfirmPassword'][0];
        this.notificationsService.notificationApp('error',errorMessage);
      } else {
        this.notificationsService.notificationApp('error', Constants.MESSAGES.FailGeneral);
      }
    } catch (error) {
      if(error.error.Message == Constants.MESSAGES.CatchPreviouslyUsedPassword){
        this.errorMessage = Constants.MESSAGES.PeviouslyUsedPassword;
      }else if(error.error.Message == Constants.MESSAGES.CatchInvalidPassword){
        this.passwordFaild = true;
      }else if(error.error.Message == Constants.MESSAGES.CatchUserValidate){
        this.notificationsService.notificationApp('error', Constants.MESSAGES.NotExistUser);
      }else{
        this.notificationsService.notificationApp('error', Constants.MESSAGES.FailGeneral);
      }
    } finally {
      this.loading = false;
    }
  }
}
