<div class="col-md-12 stretch-card">
  <div class="card">
    <div class="card-body card-body-lappiz">
      <div class="header-section my-2">
        <div class="actions-form-header">
          <div class="container">
            <div class="row d-flex justify-content-center">
              <div class="title-section">
                <h1 class="ng-binding text-center">{{ Encuesta.CENombre }}</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="EncuestaForm">
        <div class="row">
          <div class="container">
            <div class="col-md-12 modulo" *ngFor="let modulo of dataModulos">
              <h1 class="ng-binding">{{ modulo.NombreModulo }}</h1>
              <div *ngIf="modulo.Lappiz_PreguntasDetails">
                <div class="col-md-12 pregunta" *ngFor="let pregunta of modulo.Lappiz_PreguntasDetails">
                  <p><STRONG> {{ pregunta.Orden }}.</STRONG> {{ pregunta.CEPregunta }}.</p>
                  <p></p>
                  <div [ngSwitch]="pregunta.TipoRespuesta">
                    <div *ngSwitchCase="'Selección única'">
                      <div id="{{ pregunta.Id }}" class="question">
                        <div class="col-md-12 respuestas"
                          *ngFor="let respuesta of pregunta.Lappiz_PosiblesRespuestasDetails">
                          <input class="mr-2" [disabled]="editable" *ngIf="respuesta.lappiz_PosiblesRespuestas"
                            type="radio" id="{{ respuesta.lappiz_PosiblesRespuestas.Id }}" name="{{ pregunta.Id }}"
                            value="{{ respuesta.lappiz_PosiblesRespuestas.CENombre }}"
                            [checked]="respuesta.lappiz_PosiblesRespuestas.Checked">
                          <label *ngIf="respuesta.lappiz_PosiblesRespuestas" for="{{ pregunta.Id }}">
                            {{ respuesta.lappiz_PosiblesRespuestas.CENombre }}</label>
                          <input class="mr-2" [disabled]="editable" *ngIf="!respuesta.lappiz_PosiblesRespuestas"
                            type="radio" id="{{ respuesta.Id }}" name="{{ pregunta.Id }}"
                            value="{{ respuesta.CENombre }}" [checked]="respuesta.Checked">
                          <label *ngIf="respuesta" for="{{ pregunta.Id }}">
                            {{ respuesta.CENombre }}</label>
                        </div>
                      </div>
                    </div>
                    <div *ngSwitchCase="'Selección múltiple'">
                      <div id="{{ pregunta.Id }}" class="question">
                        <div class="col-md-12 respuestas"
                          *ngFor="let respuesta of pregunta.Lappiz_PosiblesRespuestasDetails">
                          <div *ngIf="respuesta.lappiz_PosiblesRespuestas">
                            <input class="mr-2" [disabled]="editable" type="checkbox"
                              id="{{ respuesta.lappiz_PosiblesRespuestas.Id }}"
                              name="{{ respuesta.lappiz_PosiblesRespuestas.Id }}"
                              [checked]="respuesta.lappiz_PosiblesRespuestas.Checked">
                            <label for="{{ respuesta.lappiz_PosiblesRespuestas.Id }}">
                              {{ respuesta.lappiz_PosiblesRespuestas.CENombre }}</label>
                          </div>
                          <div *ngIf="!respuesta.lappiz_PosiblesRespuestas">
                            <input class="mr-2" [disabled]="editable" type="checkbox" id="{{ respuesta.Id }}"
                              name="{{ respuesta.Id }}" [checked]="respuesta.Checked">
                            <label for="{{ respuesta.Id }}">{{ respuesta.CENombre }}</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div *ngSwitchCase="'Texto'">
                      <div class="input-group question">
                        <textarea [disabled]="editable" class="form-control" aria-label="With textarea"
                          id="{{ pregunta.Id }}" [value]="pregunta.Text"></textarea>
                      </div>
                    </div>
                    <div *ngSwitchCase="'Numérico'">
                      <div class="input-group mb-3 question">
                        <input [disabled]="editable" id="{{ pregunta.Id }}" type="number" class="form-control"
                          aria-label="Amount (to the nearest dollar)" [value]="pregunta.Text">
                        <div class="input-group-append">
                          <span class="input-group-text">#</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container" *ngIf="modulo.Lappiz_SubModulosDetails">
                <div class="col-md-12 container-fluid submodulo"
                  *ngFor="let submodulo of modulo.Lappiz_SubModulosDetails">
                  <h5 translate="description_grid" class="ng-scope">{{ submodulo.CENombre }}</h5>
                  <div class="container questions">
                    <div class="col-md-12 pregunta" *ngFor="let pregunta of submodulo.Lappiz_PreguntasDetails">
                      <p><STRONG> {{ pregunta.Orden }}.</STRONG> {{ pregunta.CEPregunta }}.</p>
                      <p></p>
                      <div [ngSwitch]="pregunta.TipoRespuesta">
                        <div *ngSwitchCase="'Selección única'">
                          <div id="{{ pregunta.Id }}" class="question">
                            <div class="col-md-12 respuestas"
                              *ngFor="let respuesta of pregunta.Lappiz_PosiblesRespuestasDetails">
                              <input class="mr-2" [disabled]="editable" *ngIf="respuesta.lappiz_PosiblesRespuestas"
                                type="radio" id="{{ respuesta.lappiz_PosiblesRespuestas.Id }}" name="{{ pregunta.Id }}"
                                value="{{ respuesta.lappiz_PosiblesRespuestas.CENombre }} "
                                [checked]="respuesta.lappiz_PosiblesRespuestas.Checked">
                              <label *ngIf="respuesta.lappiz_PosiblesRespuestas" for="{{ pregunta.Id }}">
                                {{ respuesta.lappiz_PosiblesRespuestas.CENombre }}</label>
                              <input class="mr-2" [disabled]="editable" *ngIf="!respuesta.lappiz_PosiblesRespuestas"
                                type="radio" id="{{ respuesta.Id }}" name="{{ pregunta.Id }}"
                                value="{{ respuesta.CENombre }}" [checked]="respuesta.Checked">
                              <label *ngIf="respuesta" for="{{ pregunta.Id }}">
                                {{ respuesta.CENombre }}</label>
                            </div>
                          </div>
                        </div>
                        <div *ngSwitchCase="'Selección múltiple'">
                          <div id="{{ pregunta.Id }}" class="question">
                            <div class="col-md-12 respuestas"
                              *ngFor="let respuesta of pregunta.Lappiz_PosiblesRespuestasDetails">
                              <div *ngIf="respuesta.lappiz_PosiblesRespuestas">
                                <input class="mr-2" [disabled]="editable" type="checkbox"
                                  id="{{ respuesta.lappiz_PosiblesRespuestas.Id }}"
                                  name="{{ respuesta.lappiz_PosiblesRespuestas.Id }}"
                                  [checked]="respuesta.lappiz_PosiblesRespuestas.Checked">
                                <label for="{{ respuesta.lappiz_PosiblesRespuestas.Id }}">
                                  {{ respuesta.lappiz_PosiblesRespuestas.CENombre }}</label>
                              </div>
                              <div *ngIf="!respuesta.lappiz_PosiblesRespuestas">
                                <input class="mr-2" [disabled]="editable" type="checkbox" id="{{ respuesta.Id }}"
                                  name="{{ respuesta.Id }}" [checked]="respuesta.Checked">
                                <label for="{{ respuesta.Id }}">{{ respuesta.CENombre }}</label>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div *ngSwitchCase="'Texto'">
                          <div class="input-group question">
                            <textarea [disabled]="editable" class="form-control" aria-label="With textarea"
                              id="{{ pregunta.Id }}" [value]="pregunta.Text"></textarea>
                          </div>
                        </div>
                        <div *ngSwitchCase="'Numérico'">
                          <div class="input-group mb-3 question">
                            <input [disabled]="editable" id="{{ pregunta.Id }}" type="number" class="form-control"
                              aria-label="Amount (to the nearest dollar)" [value]="pregunta.Text">
                            <div class="input-group-append">
                              <span class="input-group-text">#</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-10 list-container" style="margin-left:10%">
              <!-- <div dx-list="DataSourceList"></div> -->

              <div id="listOptions"></div>
              <!-- <hr style="height: 1px; background-color: #DAD2D0">
                                <h5 style='text-align:left'>Observaciones</h5><textarea id="txtObservaciones" cols=60 rows=8></textarea>
                                <hr style="height: 1px; background-color: #DAD2D0"> -->
              <div class="col-md-12 list-container" style="margin-left:0%">
                <hr style="height: 1px; background-color: #DAD2D0">
                <h5 style='text-align:left'>Observaciones</h5><textarea id="txtObservaciones"
                  [(ngModel)]="txtObservaciones" cols=60 rows=8 [disabled]="editable"></textarea>
                <hr style="height: 1px; background-color: #DAD2D0">

                <div style="text-align:center">
                  <button type="button" class="btn btn-success" [disabled]="editable" (click)="SaveEncuesta(false);"
                    translate="save_encuesta">Guardar Encuesta</button>
                  <button type="button" class="btn btn-success" [disabled]="editable" (click)="SaveEncuesta(true);"
                    translate="finish_encuesta">Finalizar Encuesta</button>
                  <button type="button" class="btn btn-success" (click)="Cancelar();"
                    translate="cancel_encuesta">Cancelar</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
