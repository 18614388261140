import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { ToastrService } from 'ngx-toastr';

import { ProxyTxApiService } from '@services/proxy-tx-api.service';
import { DataItemService } from '@services/data-item.service';
import { RootScopeService } from '@shared/root-scope.service';

import backandGlobal from '@env/env.js';

@Component({
  selector: 'app-encuesta',
  templateUrl: './encuesta.component.html',
  styleUrls: ['./encuesta.component.scss'],
})
export class EncuestaComponent implements OnInit {

  @Input() Encuesta: any;
  @Input() Estado: any;
  @Input() EncuestaId: any;
  @Input() EncuestadoId: any;
  @Input() Encuestado: any;
  @Input() Respuestas: any;
  @Input() Resultado: any;
  @Input() Resultados: any;
  @Input() Modulos: any;

  listOptions: any;

  dataModulos: any;
  dataSubModulos: any;
  dataPreguntas: any;
  dataPosiblesRespuestas: any;
  dataPosiblesRespuestas2: any;
  DataSourceList: DataSource;

  queryEncuesta: any;
  opc: any;
  appViewId: any;
  dataToSubmit: any;
  dataToSubmitResultados: any;  

  myPromise: any;
  myPromiseDelete: any;
  editable: any;
  txtObservaciones: any;
  PreguntasSinRespuesta: any;

  constructor(
    private route: ActivatedRoute,
    private toastr: ToastrService,
    private dataItemService: DataItemService,
    private proxyTxApiService: ProxyTxApiService,
    private $rs: RootScopeService
  ) {
    this.PreguntasSinRespuesta = '';
    this.Encuesta = {
      CENombre: '',
    };

    this.dataSubModulos = [];

    this.route.queryParamMap.subscribe((params) => {
      this.appViewId = params.get('appViewId');
      this.EncuestaId = params.get('EncuestaFk');
      this.EncuestadoId = params.get('EncuestadoFk');
      this.Estado = params.get('Estado');
      this.editable = this.Estado == 'Terminado' ? true : false;
    });
    this.$rs.$rootScope.AppName = sessionStorage.configAppName;
    sessionStorage.workspace = sessionStorage.configAppName;
  }

  async ngOnInit() {
    await this.init();
  }

  async init() {
    this.opc = {
      take: 50,
      skip: 0,
      page: 1,
      pageSize: 50,
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'Id',
            operator: 'eq',
            value: this.EncuestaId,
          },
        ],
      },
      tenantId: sessionStorage.tenantId,
      parameters: {
        userId: sessionStorage.userId,
        pType: 'showinmenu',
        aType: 'ffija',
        environment: backandGlobal.environment,
      },
    };

    this.queryEncuesta = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/get/${this.$rs.$rootScope.AppName}_Encuestas`;

    await this.proxyTxApiService
      .getTxData(this.queryEncuesta, this.opc, backandGlobal.stateOnline)
      .then((result: any) => {
        this.Encuesta = result.data.rows[0];
      });

    this.opc = {
      take: 50,
      skip: 0,
      page: 1,
      pageSize: 50,
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'Id',
            operator: 'eq',
            value: this.EncuestadoId,
          },
        ],
      },
      tenantId: sessionStorage.tenantId,
      parameters: {
        userId: sessionStorage.userId,
        tablaId: '',
        appViewId: this.appViewId
          ? this.appViewId
          : '00000000-0000-0000-0000-000000000000',
        actionId: '00000000-0000-0000-0000-000000000000',
        pType: 'showinmenu',
        aType: 'ffija',
        environment: backandGlobal.environment,
        lappizFunctionId: '00000000-0000-0000-0000-000000000000',
      },
    };
    let queryEncuestado: any = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/get/${this.$rs.$rootScope.AppName}_Encuestados`;

    await this.proxyTxApiService
      .getTxData(queryEncuestado, this.opc, backandGlobal.stateOnline)
      .then((result: any) => {
        this.Encuestado = result.data.rows[0];
        $('#txtObservaciones').val(result.data.rows[0].Observaciones);
      });

    this.opc = {
      take: 99999,
      skip: 0,
      page: 1,
      pageSize: 99999,
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'EncuestadoFk',
            operator: 'eq',
            value: this.EncuestadoId,
          },
        ],
      },
      tenantId: sessionStorage.tenantId,
      parameters: {
        tablaId: '',
        appViewId: '00000000-0000-0000-0000-000000000000',
        actionId: '00000000-0000-0000-0000-000000000000',
        pType: 'showinmenu',
        aType: 'ffija',
        environment: backandGlobal.environment,
        lappizFunctionId: '00000000-0000-0000-0000-000000000000',
      },
    };

    let queryRespuestas = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/get/${this.$rs.$rootScope.AppName}_Respuestas`;
    await this.proxyTxApiService
      .getTxData(queryRespuestas, this.opc, backandGlobal.stateOnline)
      .then((result: any) => {
        this.Respuestas = result.data.rows;
      });

    this.opc = {
      take: 99999,
      skip: 0,
      page: 1,
      pageSize: 99999,
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'EncuestadoFk',
            operator: 'eq',
            value: this.EncuestadoId,
          },
        ],
      },
      tenantId: sessionStorage.tenantId,
      parameters: {
        tablaId: '',
        appViewId: '00000000-0000-0000-0000-000000000000',
        actionId: '00000000-0000-0000-0000-000000000000',
        pType: 'showinmenu',
        aType: 'ffija',
        environment: backandGlobal.environment,
        lappizFunctionId: '00000000-0000-0000-0000-000000000000',
      },
    };

    let queryResultados = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/get/${this.$rs.$rootScope.AppName}_Resultados`;
    await this.proxyTxApiService
      .getTxData(queryResultados, this.opc, backandGlobal.stateOnline)
      .then((result: any) => {
        this.Resultados = result.data.rows;
      });

    let url: any = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/get/${this.$rs.$rootScope.AppName}_Modulos`;

    this.opc = {
      take: 50,
      skip: 0,
      page: 1,
      pageSize: 50,
      sort: [
        { field: `${sessionStorage.workspace}` + '_Modulos.Orden', dir: 'asc' },
      ],
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'EncuestaFk',
            operator: 'eq',
            value: this.EncuestaId,
          },
        ],
      },
      tenantId: sessionStorage.tenantId,
      parameters: {
        tablaId: '',
        appViewId: '00000000-0000-0000-0000-000000000000',
        actionId: '00000000-0000-0000-0000-000000000000',
        pType: 'showinmenu',
        aType: 'ffija',
        environment: backandGlobal.environment,
        lappizFunctionId: '00000000-0000-0000-0000-000000000000',
      },
    };

    await this.proxyTxApiService
      .getTxData(url, this.opc, backandGlobal.stateOnline)
      .then((result: any) => {
        for (let i = 0; i < result.data.rows.length; i++) {
          for (let j = 0; j < result.data.rows.length - 1; j++) {
            if (result.data.rows[j].Orden > result.data.rows[j + 1].Orden) {
              let aux: any = result.data.rows[j];
              result.data.rows[j] = result.data.rows[j + 1];
              result.data.rows[j + 1] = aux;
            }
          }
        }

        this.DataSourceList = new DataSource({
          store: new ArrayStore({
            key: 'id',
            data: result.data.rows,
          }),
          searchOperation: 'contains',
          searchExpr: 'CEPregunta',
        });
      });

    let queryModulos: any = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/get/${this.$rs.$rootScope.AppName}_Modulos`;
    this.opc = {
      take: 50,
      skip: 0,
      page: 1,
      pageSize: 50,
      sort: [
        { field: `${sessionStorage.workspace}` + '_Modulos.Orden', dir: 'asc' },
      ],
      filter: {
        logic: 'or',
        filters: [
          {
            field: 'EncuestaFk',
            operator: 'eq',
            value: this.EncuestaId,
          },
        ],
      },
      tenantId: sessionStorage.tenantId,
      parameters: {
        pType: 'showinmenu',
        aType: 'ffija',
        environment: backandGlobal.environment,
      },
    };

    await this.proxyTxApiService
      .getTxData(queryModulos, this.opc, backandGlobal.stateOnline)
      .then(async (response: any) => {
        this.Modulos = response.data.rows.sort((a, b) => {
          return a.Orden - b.Orden;
        });

        // this.Modulos.forEach(async (Modulos: any) => {
        for (let index = 0; index < this.Modulos.length; index++) {
          this.opc = {
            take: 999,
            skip: 0,
            page: 1,
            pageSize: 999,
            filter: {
              logic: 'and',
              filters: [
                {
                  field: 'ModuloFk',
                  operator: 'eq',
                  value: this.Modulos[index].Id,
                },
                {
                  field: 'SubModuloFk',
                  operator: 'eq',
                  value: null,
                },
              ],
            },
            tenantId: sessionStorage.tenantId,
            parameters: {
              tablaId: '',
              appViewId: '00000000-0000-0000-0000-000000000000',
              actionId: '00000000-0000-0000-0000-000000000000',
              pType: 'showinmenu',
              aType: 'ffija',
              environment: backandGlobal.environment,
              lappizFunctionId: '00000000-0000-0000-0000-000000000000',
            },
          };
          
          let queryPreguntas: any = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/get/${this.$rs.$rootScope.AppName}_Preguntas`;

          await this.proxyTxApiService
            .getTxData(queryPreguntas, this.opc, backandGlobal.stateOnline)
            .then(async (response: any) => {
              if (response.data.rows.length > 0) {
                this.Modulos[index].Lappiz_PreguntasDetails =
                  response.data.rows.sort((a, b) => {
                    return a.Orden - b.Orden;
                  });
                for (
                  let index3 = 0;
                  index3 < this.Modulos[index].Lappiz_PreguntasDetails.length;
                  index3++
                ) {
                  if (this.Respuestas != undefined && this.Respuestas != '') {
                    if (this.Respuestas.length > 0) {
                      var element = this.Respuestas.filter(
                        (x) =>
                          x.PreguntaFk ==
                            this.Modulos[index].Lappiz_PreguntasDetails[index3]
                              .Id && x.CENombre != null
                      );
                      element.forEach((data) => {
                        this.Modulos[index].Lappiz_PreguntasDetails[
                          index3
                        ].Text = data.CENombre ? data.CENombre : '';
                      });
                    }
                  }
                  this.opc = {
                    take: 999,
                    skip: 0,
                    page: 1,
                    pageSize: 999,
                    sort: [
                      {
                        field:
                          `${sessionStorage.workspace}` +
                          '_PosiblesRespuestas.Orden',
                        dir: 'ASC',
                      },
                    ],
                    filter: {
                      logic: 'and',
                      filters: [
                        {
                          field:
                            `${sessionStorage.workspace}` +
                            '_PreguntaXposibleRespuesta.PreguntaFk',
                          operator: 'eq',
                          value:
                            this.Modulos[index].Lappiz_PreguntasDetails[index3]
                              .Id,
                        },
                      ],
                    },
                    tenantId: sessionStorage.tenantId,
                    parameters: {
                      tablaId: '',
                      appViewId: '00000000-0000-0000-0000-000000000000',
                      actionId: '00000000-0000-0000-0000-000000000000',
                      pType: 'showinmenu',
                      aType: 'ffija',
                      environment: backandGlobal.environment,
                      lappizFunctionId: '00000000-0000-0000-0000-000000000000',
                    },
                    attributes: [
                      'Id',
                      `${sessionStorage.workspace}` +
                        '_PosiblesRespuestas.Orden',
                      `${sessionStorage.workspace}` +
                        '_PosiblesRespuestas.CENombre',
                      `${sessionStorage.workspace}` +
                        '_PosiblesRespuestas.Nota',
                    ],
                    includeEntities: `[{\"model\": modelo.${sessionStorage.workspace}_PosiblesRespuestas, \"as\":\"${sessionStorage.workspace}_PosiblesRespuestas\"}]`,
                  };
                  let queryPosiblesRespuestas: any = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/get/${this.$rs.$rootScope.AppName}_PreguntaXposibleRespuesta`;

                  await this.proxyTxApiService
                    .getTxData(
                      queryPosiblesRespuestas,
                      this.opc,
                      backandGlobal.stateOnline
                    )
                    .then((result: any) => {
                      for (let i = 0; i < result.data.rows.length; i++) {
                        result.data.rows[i].lappiz_PosiblesRespuestas =
                          result.data.rows[i][
                            `${this.$rs.$rootScope.AppName}_PosiblesRespuestas`
                          ];
                        if (
                          this.Respuestas != undefined &&
                          this.Respuestas != ''
                        ) {
                          if (this.Respuestas.length > 0) {
                            var element = this.Respuestas.filter(
                              (x) =>
                                x.PosibleRespuestaFk ==
                                  result.data.rows[i][
                                    `${this.$rs.$rootScope.AppName}_PosiblesRespuestas`
                                  ].Id && x.CENombre == null
                            );
                            element.forEach((data) => {
                              result.data.rows[
                                i
                              ].lappiz_PosiblesRespuestas.Checked = data.PosibleRespuestaFk
                                ? true
                                : false;
                            });
                          }
                        }
                      }
                      this.Modulos[index].Lappiz_PreguntasDetails[
                        index3
                      ].Lappiz_PosiblesRespuestasDetails = result.data.rows;
                      this.dataPosiblesRespuestas = result.data.rows;
                    });
                }
                this.dataPreguntas = response.data.rows;
              }
            });

          this.opc = {
            take: 50,
            skip: 0,
            page: 1,
            pageSize: 50,
            sort: [
              {
                field: `${sessionStorage.workspace}` + '_SubModulos.Orden',
                dir: 'asc',
              },
            ],
            filter: {
              logic: 'or',
              filters: [
                {
                  field: 'ModuloFk',
                  operator: 'eq',
                  value: this.Modulos[index].Id,
                },
              ],
            },
            tenantId: sessionStorage.tenantId,
            parameters: {
              tablaId: '',
              appViewId: '00000000-0000-0000-0000-000000000000',
              actionId: '00000000-0000-0000-0000-000000000000',
              pType: 'showinmenu',
              aType: 'ffija',
              environment: backandGlobal.environment,
              lappizFunctionId: '00000000-0000-0000-0000-000000000000',
            },
          };

          let querySubModulos = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/get/${this.$rs.$rootScope.AppName}_SubModulos`;
          
          await this.proxyTxApiService
            .getTxData(querySubModulos, this.opc, backandGlobal.stateOnline)
            .then(async (response: any) => {
              if (response.data.count > 0) {
                this.Modulos[index].Lappiz_SubModulosDetails =
                  response.data.rows.sort((a, b) => {
                    return a.Orden - b.Orden;
                  });
                if (!this.Modulos[index].Lappiz_SubModulosDetails) {
                  this.Modulos[index].Lappiz_SubModulosDetails = [{}];
                }

                for (
                  let index2 = 0;
                  index2 < this.Modulos[index].Lappiz_SubModulosDetails.length;
                  index2++
                ) {
                  if (this.Modulos[index].Lappiz_SubModulosDetails[index2]) {
                    this.opc = {
                      take: 999,
                      skip: 0,
                      page: 1,
                      pageSize: 999,
                      filter: {
                        logic: 'or',
                        filters: [
                          {
                            field: 'SubModuloFk',
                            operator: 'eq',
                            value:
                              this.Modulos[index].Lappiz_SubModulosDetails[
                                index2
                              ].Id,
                          },
                        ],
                      },
                      tenantId: sessionStorage.tenantId,
                      parameters: {
                        tablaId: '',
                        appViewId: '00000000-0000-0000-0000-000000000000',
                        actionId: '00000000-0000-0000-0000-000000000000',
                        pType: 'showinmenu',
                        aType: 'ffija',
                        environment: backandGlobal.environment,
                        lappizFunctionId:
                          '00000000-0000-0000-0000-000000000000',
                      },
                    };
                    
                    let queryPreguntas: any = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/get/${this.$rs.$rootScope.AppName}_Preguntas`;

                    await this.proxyTxApiService
                      .getTxData(
                        queryPreguntas,
                        this.opc,
                        backandGlobal.stateOnline
                      )
                      .then(async (response: any) => {
                        this.Modulos[index].Lappiz_SubModulosDetails[
                          index2
                        ].Lappiz_PreguntasDetails = response.data.rows.sort(
                          (a, b) => {
                            return a.Orden - b.Orden;
                          }
                        );

                        for (
                          let index3 = 0;
                          index3 <
                          this.Modulos[index].Lappiz_SubModulosDetails[index2]
                            .Lappiz_PreguntasDetails.length;
                          index3++
                        ) {
                          this.Modulos[index].Lappiz_SubModulosDetails[
                            index2
                          ].Lappiz_PreguntasDetails[index3].Text = '';
                          if (
                            this.Respuestas != undefined &&
                            this.Respuestas != ''
                          ) {
                            if (this.Respuestas.length > 0) {
                              var element = this.Respuestas.filter(
                                (x) =>
                                  x.PreguntaFk ==
                                    this.Modulos[index]
                                      .Lappiz_SubModulosDetails[index2]
                                      .Lappiz_PreguntasDetails[index3].Id &&
                                  x.CENombre != null
                              );
                              element.forEach((data) => {
                                this.Modulos[index].Lappiz_SubModulosDetails[
                                  index2
                                ].Lappiz_PreguntasDetails[index3].Text +=
                                  data.CENombre ? data.CENombre : '';
                              });
                            }
                          }
                          this.opc = {
                            take: 999,
                            skip: 0,
                            page: 1,
                            pageSize: 999,
                            sort: [
                              {
                                field:
                                  `${sessionStorage.workspace}` +
                                  '_PosiblesRespuestas.Orden',
                                dir: 'ASC',
                              },
                            ],
                            filter: {
                              logic: 'and',
                              filters: [
                                {
                                  field:
                                    `${sessionStorage.workspace}` +
                                    '_PreguntaXposibleRespuesta.PreguntaFk',
                                  operator: 'eq',
                                  value:
                                    this.Modulos[index]
                                      .Lappiz_SubModulosDetails[index2]
                                      .Lappiz_PreguntasDetails[index3].Id,
                                },
                              ],
                            },
                            tenantId: sessionStorage.tenantId,
                            parameters: {
                              tablaId: '',
                              appViewId: '00000000-0000-0000-0000-000000000000',
                              actionId: '00000000-0000-0000-0000-000000000000',
                              pType: 'showinmenu',
                              aType: 'ffija',
                              environment: backandGlobal.environment,
                              lappizFunctionId:
                                '00000000-0000-0000-0000-000000000000',
                            },
                            attributes: [
                              'Id',
                              `${sessionStorage.workspace}` +
                                '_PosiblesRespuestas.Orden',
                              `${sessionStorage.workspace}` +
                                '_PosiblesRespuestas.CENombre',
                              `${sessionStorage.workspace}` +
                                '_PosiblesRespuestas.Nota',
                            ],
                            includeEntities: `[{\"model\": modelo.${sessionStorage.workspace}_PosiblesRespuestas, \"as\":\"${sessionStorage.workspace}_PosiblesRespuestas\"}]`,
                          };
                          let queryPosiblesRespuestas: any = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/get/${this.$rs.$rootScope.AppName}_PreguntaXposibleRespuesta`;

                          await this.proxyTxApiService
                            .getTxData(
                              queryPosiblesRespuestas,
                              this.opc,
                              backandGlobal.stateOnline
                            )
                            .then((result: any) => {
                              for (
                                let i = 0;
                                i < result.data.rows.length;
                                i++
                              ) {
                                result.data.rows[i].lappiz_PosiblesRespuestas =
                                  result.data.rows[i][
                                    `${this.$rs.$rootScope.AppName}_PosiblesRespuestas`
                                  ];
                                if (
                                  this.Respuestas != undefined &&
                                  this.Respuestas != ''
                                ) {
                                  if (this.Respuestas.length > 0) {
                                    var element = this.Respuestas.filter(
                                      (x) =>
                                        x.PosibleRespuestaFk ==
                                          result.data.rows[i][
                                            `${this.$rs.$rootScope.AppName}_PosiblesRespuestas`
                                          ].Id && x.CENombre == null
                                    );
                                    element.forEach((data) => {
                                      result.data.rows[
                                        i
                                      ].lappiz_PosiblesRespuestas.Checked = data.PosibleRespuestaFk
                                        ? true
                                        : false;
                                    });
                                  }
                                }
                              }
                              this.Modulos[index].Lappiz_SubModulosDetails[
                                index2
                              ].Lappiz_PreguntasDetails[
                                index3
                              ].Lappiz_PosiblesRespuestasDetails =
                                result.data.rows;
                              this.dataPosiblesRespuestas = result.data.rows;
                            });
                        }
                        this.dataPreguntas = response.data.rows;
                      });
                  }
                }
                this.dataSubModulos = response.data.rows;
              }
            });
        }
        this.dataModulos = response.data.rows;
      });
  }

  SaveEncuesta(Terminar: any) {
    this.dataToSubmit = [];
    this.dataToSubmitResultados = [];
    var PuntajeTotal = 0;
    this.dataModulos.forEach((Respuesta: any) => {
      var PuntajeModulo = 0;
      if (Respuesta.Lappiz_PreguntasDetails) {
        PuntajeModulo += this.PuntajePreguntas(Respuesta);
      }
      if (Respuesta.Lappiz_SubModulosDetails) {
        let subModulos = Respuesta.Lappiz_SubModulosDetails;
        subModulos.forEach((Respuesta: any) => {
          PuntajeModulo += this.PuntajePreguntas(Respuesta);
        });
      }
      PuntajeTotal += (PuntajeModulo * Respuesta.Peso) / 100;
    });
    let observaciones: any = this.txtObservaciones;
    let hoy: any = new Date();
    let fecha: any =
      hoy.getFullYear() + '-' + (hoy.getMonth() + 1) + '-' + hoy.getDate();
    let hora: any =
      hoy.getHours() + ':' + hoy.getMinutes() + ':' + hoy.getSeconds();
    let fechayhora: any = fecha + ' ' + hora;

    this.Respuestas.forEach((Respuesta: any) => {
      let params: any = {
        dataType: 'view',
        id: Respuesta.Id,
        viewName: `${this.$rs.$rootScope.AppName}_Respuestas`,
        objTx: {
          parameters: {
            userId: sessionStorage.UserId,
            appViewId: '00000000-0000-0000-0000-000000000000',
            pType: 'Eliminar',
            aType: 'ffija',
            environment: backandGlobal.environment,
          },
        },
        // {
        //     "tenantId": sessionStorage.tenantId,
        //     "parameters": {
        //       "tablaId": "",
        //       "appViewId": "00000000-0000-0000-0000-000000000000",
        //       "actionId": "00000000-0000-0000-0000-000000000000",
        //       "pType": "showinmenu",
        //       "aType": "ffija",
        //       "environment": "TEST",
        //       "lappizFunctionId": "00000000-0000-0000-0000-000000000000"
        //     }
        //   }
      };
      this.myPromiseDelete = this.dataItemService.delete(
        params.viewName,
        params.id,
        params.objTx
      );
    });

    this.Resultados.forEach((Resultado: any) => {
      let params: any = {
        dataType: 'view',
        id: Resultado.Id,
        viewName: `${this.$rs.$rootScope.AppName}_Respuestas`,
        objTx: {
          tenantId: sessionStorage.tenantId,
          parameters: {
            tablaId: '',
            appViewId: '00000000-0000-0000-0000-000000000000',
            actionId: '00000000-0000-0000-0000-000000000000',
            pType: 'showinmenu',
            aType: 'ffija',
            environment: backandGlobal.environment,
            lappizFunctionId: '00000000-0000-0000-0000-000000000000',
          },
        },
      };
      this.myPromiseDelete = this.dataItemService.delete(
        params.viewName,
        params.id,
        params.objTx
      );
    });
    let Continue: any = true;
    if (this.PreguntasSinRespuesta != '') {
      Continue = confirm(
        'Las preguntas: \n' +
          this.PreguntasSinRespuesta +
          ' No se han diligenciado  \n¿desea continuar?'
      );
    }
    if (Continue) {
      let submitParams: any = {
        viewName: `${this.$rs.$rootScope.AppName}_Respuestas`,
      };
      this.dataToSubmit.forEach((data: any) => {
        data.parameters = {
          userId: sessionStorage.UserId,
          appViewId: '00000000-0000-0000-0000-000000000000',
          pType: 'view',
          aType: 'ffija',
          environment: backandGlobal.environment,
        };
        this.myPromise = this.dataItemService.create(
          submitParams.viewName,
          data
        );
      });
      //Guardar area de texto observaciones(?)
      let submitParamsResultados: any = {
        viewName: `${this.$rs.$rootScope.AppName}_Resultados`,
      };
      this.dataToSubmitResultados.forEach((data: any) => {
        this.myPromise = this.dataItemService.create(
          submitParamsResultados.viewName,
          JSON.stringify(data)
        );
      });
      //let submitParamsUpdate = { id: scope.EncuestadoId, viewName: `${$rootScope.AppName}_Encuestados` };
      this.Encuestado['Estado'] = Terminar ? 'Terminado' : 'Pendiente';
      this.Encuestado['Fecha'] = fechayhora;
      this.Encuestado['PuntajeFinal'] = Number(PuntajeTotal.toFixed(2));
      //scope.myPromiseUpdate = dataItemService.update(submitParamsUpdate, JSON.stringify(scope.Encuestado))

      //Realizar el update
      let urlRequest: any = `${backandGlobal.api2}/${sessionStorage.workspace}.api/api/lappiz/sp/query`;

      let option: any = {
        Query: `update ${sessionStorage.workspace}_Encuestados set Observaciones = '${observaciones}', Estado = '${this.Encuestado['Estado']}', PuntajeFinal = '${this.Encuestado['PuntajeFinal']}', FechaResponde = '${this.Encuestado['Fecha']}' WHERE Id = '${this.EncuestadoId}' `,
      };

      $.ajax({
        async: false,
        url: urlRequest,
        type: 'POST',
        data: JSON.stringify({
          query: option.Query,
          parameters: {
            aType: 'execTx',
            environment: backandGlobal.environment,
          },
        }),
        beforeSend: (xhr) => {
          xhr.setRequestHeader('Content-Type', 'application/json');
          xhr.setRequestHeader('Authorization', localStorage.Authorization);
        },
        success: (respon) => {
          if (
            `${sessionStorage.workspace}` == 'Pactia_Lappiz' ||
            `${sessionStorage.workspace}` == 'PactiaTest_Lappiz'
          ) {
            if (Terminar) {
              this.toastr.success(
                'Ha respondido correctamente la inspección o evaluación',
                'Bien hecho'
              );
              if (this.Encuestado['PuntajeFinal'] <= this.Encuesta['Meta']) {
                this.toastr.error(
                  `El resultado no cumple con la meta. Alternativas:<br>
                            1.En caso de de ser una inspección, generar solicitud correctiva.<br>
                            2.En caso de ser una evalución de proveedor, seguir política de cadena de abastecimiento.`,
                  'Tener en consideración.'
                );
              }
            } else {
              this.toastr.error(
                'Recuerde responder las preguntas faltantes y/o terminar. Se han hecho calculos segun lo respondido pero no ha cambiado el estado.',
                'Advertencia'
              );
            }
          } else {
            this.toastr.success(
              'Ha respondido correctamente la encuesta',
              'Bien hecho'
            );
          }
          // }
        },
        error: (respon) => {
          this.toastr.error('Error al actualizar');
        },
      });
      history.go(-1);
    }
  }

  Cancelar() {
    history.go(-1);
  }

  PuntajePreguntas(Respuesta: any): number {
    let PuntajePregunta = 0;
    let Puntajetotal = 0;
    Respuesta.Lappiz_PreguntasDetails.forEach((Pregunta: any) => {
      let SinResponder: any = true;
      let respuesta: any = {};
      let pesoPregunta = 0;
      if (Pregunta.TipoRespuesta == 'Texto') {
        let Value = $('#' + Pregunta.Id).val();
        if (Value != '') {
          SinResponder = false;
          PuntajePregunta = 100;
          respuesta = {
            CENombre: Value,
            EncuestadoFk: this.EncuestadoId,
            PreguntaFk: Pregunta.Id,
            tenantId: sessionStorage.tenantId,
          };
          this.dataToSubmit.push(respuesta);
        } else {
          this.PreguntasSinRespuesta += '\n' + Pregunta.CEPregunta;
          PuntajePregunta = 0;
          respuesta = {
            CENombre: Value,
            EncuestadoFk: this.EncuestadoId,
            PreguntaFk: Pregunta.Id,
            tenantId: sessionStorage.tenantId,
          };
          this.dataToSubmit.push(respuesta);
        }
      } else if (Pregunta.TipoRespuesta == 'Numérico') {
        let Value: any = $('#' + Pregunta.Id).val();
        if (Value != '') {
          if (Value >= Pregunta.Minimo && Value <= Pregunta.Maximo) {
            SinResponder = false;
            PuntajePregunta = 100;
            respuesta = {
              CENombre: Value,
              EncuestadoFk: this.EncuestadoId,
              PreguntaFk: Pregunta.Id,
              tenantId: sessionStorage.tenantId,
            };
            this.dataToSubmit.push(respuesta);
          } else {
            PuntajePregunta = 0;
            pesoPregunta = parseInt(Pregunta['Peso']);
            respuesta = {
              CENombre: Value,
              EncuestadoFk: this.EncuestadoId,
              PreguntaFk: Pregunta.Id,
              tenantId: sessionStorage.tenantId,
            };
            this.dataToSubmit.push(respuesta);
          }
        } else {
          PuntajePregunta = 0;
          this.PreguntasSinRespuesta += '\n' + Pregunta.CEPregunta;
        }
      } else if (Pregunta.TipoRespuesta == 'Selección múltiple') {
        let Respuestas: any = Pregunta[`Lappiz_PosiblesRespuestasDetails`];
        let Values: any = $(`#${Pregunta.Id}`)[0].children;
        PuntajePregunta = 0;
        let PuntajeTotalPregunta = 0;
        let sw = true;
        Respuestas.forEach((Respuesta: any) => {
          PuntajeTotalPregunta += parseFloat(
            Respuesta[`${this.$rs.$rootScope.AppName}_PosiblesRespuestas`]
              .Nota == null
              ? 0
              : Respuesta[`${this.$rs.$rootScope.AppName}_PosiblesRespuestas`]
                  .Nota
          );
          for (let index = 0; index < Values.length; index++) {
            if (
              Respuesta[`${this.$rs.$rootScope.AppName}_PosiblesRespuestas`]
                .Id == Values[index].children[0].children[0].id
            ) {
              if (Values[index].children[0].children[0].checked) {
                PuntajePregunta += parseFloat(
                  Respuesta[`${this.$rs.$rootScope.AppName}_PosiblesRespuestas`]
                    .Nota == null
                    ? 0
                    : Respuesta[
                        `${this.$rs.$rootScope.AppName}_PosiblesRespuestas`
                      ].Nota
                );
                sw = false;
                respuesta = {
                  EncuestadoFk: this.EncuestadoId,
                  PosibleRespuestaFk:
                    Respuesta[
                      `${this.$rs.$rootScope.AppName}_PosiblesRespuestas`
                    ].Id,
                  PreguntaFk: Pregunta.Id,
                  PreguntaXposibleRespuestaFk: Respuesta.Id,
                  tenantId: sessionStorage.tenantId,
                };
                this.dataToSubmit.push(respuesta);
              } else PuntajePregunta += 0;
            }
          }
        });
        PuntajePregunta = (PuntajePregunta * 100) / PuntajeTotalPregunta;
        if (sw) {
          this.PreguntasSinRespuesta += '\n' + Pregunta.CEPregunta;
        }
      } else if (Pregunta.TipoRespuesta == 'Selección única') {
        PuntajePregunta = 0;
        let Respuestas = Pregunta[`Lappiz_PosiblesRespuestasDetails`];
        var Values = $(`#${Pregunta.Id}`)[0].children;
        let sw = true;
        for (let i = 0; i < Values.length; i++) {
          if ($(Values[i].children[0]).is(':checked')) {
            sw = false;
            let resp: any = [];
            Respuestas.forEach((Respuesta: any) => {
              if (
                Respuesta[`${this.$rs.$rootScope.AppName}_PosiblesRespuestas`]
                  .Id == Values[i].children[0].id
              ) {
                resp =
                  Respuesta[
                    `${this.$rs.$rootScope.AppName}_PosiblesRespuestas`
                  ];
                PuntajePregunta +=
                  resp.Nota == null || resp.Nota == undefined ? 0 : resp.Nota;
                respuesta = {
                  EncuestadoFk: this.EncuestadoId,
                  PosibleRespuestaFk: resp.Id,
                  PreguntaFk: Pregunta.Id,
                  PreguntaXposibleRespuestaFk: Respuesta.Id,
                  tenantId: sessionStorage.tenantId,
                };
                this.dataToSubmit.push(respuesta);
              }
            });
          }
        }
        if (sw) {
          this.PreguntasSinRespuesta += '\n' + Pregunta.CEPregunta;
        }
      }
      Puntajetotal += (PuntajePregunta * Pregunta.Peso) / 100;
    });
    return Puntajetotal;
  }
}
