import { Component, ElementRef, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ILappizPage } from 'src/app/interfaces/ILappizPage';
import { ProxyConfigApiService } from '@services/proxy-config-api.service';
import { GlobalEventsService } from '@services/global-events.service';
import { NotificationsService } from '@services/notifications.service';
import { Constants } from '@common/constants';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit {

  constructor(
    private route: ActivatedRoute,
    private proxyConfigApiService: ProxyConfigApiService,
    private globalEvents: GlobalEventsService,
    private elRef: ElementRef,
    private router: Router,
    private notificationsServices: NotificationsService
  ) { }

  lappizPage: ILappizPage;
  content: string;
  styles: string;

  ngOnInit(): void {
    this.initLappizPage();
    this.getParamsRoute();
    this.getConfigLappizPage();
  }

  private initLappizPage(){
    this.lappizPage = { 
      LappizPageId:'',
      AppName: '',
      ApplicationId: '',
      Slug: '',
      IsAnonymous: false,
      ImplementationCSS: '',
      ImplementationHTML: '',
      ImplementationJS: '',
      GlobalEvents: []
    };
  }

  private getParamsRoute(): void {
    this.route.params.subscribe(params => {
      this.lappizPage.Slug = params['slug'];
      this.lappizPage.AppName = params['appName'];
      sessionStorage.tempApp = params['appName'];
      sessionStorage.workspace = sessionStorage.workspace || params['appName'];
      this.lappizPage.IsAnonymous = !localStorage.getItem('Authorization');
    });
  }

  private async getConfigLappizPage(): Promise<void> {
    try{
      this.lappizPage = await this.proxyConfigApiService.getConfigLappizPages(this.lappizPage);
      if(!localStorage.getItem('Authorization') && !this.lappizPage)
        this.goToLogin();
      else if(!this.lappizPage)
        this.router.navigate([`/`]);
      sessionStorage.aplicattionuserid = this.lappizPage.ApplicationId;
      this.lappizPage.GlobalEvents = await this.proxyConfigApiService.getEvents('GlobalGeneral');
      delete sessionStorage.aplicattionuserid;
      this.setStyles();
      this.rederHtmlContent();
      this.pageLoaded();
    }catch(error){
      this.goToLogin();
    }
  }

  private goToLogin(): void {
    this.notificationsServices.notificationApp('warning',Constants.MESSAGES.CannotAccessResource);
    this.router.navigate([`/auth/login/${sessionStorage.workspace}`]);
  }
  private rederHtmlContent(): void {
    this.content = this.lappizPage.ImplementationHTML;
    if(!this.content) return;
    $(`#${this.lappizPage.LappizPageId}`).replaceWith(this.content);
  }

  private setStyles(): void {
    this.styles = this.lappizPage.ImplementationCSS;
    if(!this.styles) return;
    const styleTag = document.createElement('style');
    styleTag.innerHTML = this.styles;
    this.elRef.nativeElement.appendChild(styleTag);
  }

  private pageLoaded(): void {
    if(!this.lappizPage.ImplementationJS) return;
    if(!this.lappizPage.GlobalEvents)
      this.lappizPage.GlobalEvents = [];
    this.lappizPage.GlobalEvents.push({
      GlobaEventId: this.lappizPage.LappizPageId,
      Implementation: this.lappizPage.ImplementationJS,
      ApplicationId: this.lappizPage.ApplicationId,
      Description: this.lappizPage.Slug,
      EventTypeName: 'PageLoaded',
      IsBackend: false,
      IsDisabled: false,
      Order: 0,
      Application: this.lappizPage.AppName
    });
    this.globalEvents.fireEvents('PageLoaded', this.lappizPage.GlobalEvents, null);
  }
}
