import { Injectable } from '@angular/core';
import PouchDB from 'pouchdb';
// import  *  as cordovaSqlitePlugin from 'pouchdb-adapter-cordova-sqlite';

@Injectable({
  providedIn: 'root'
})
export class AppContextService {

  dbAppContext: any;

  constructor() {
    /**
     * Se comenta momentaneamente para compatibilidad de PouchDB
     */
    //this.dbAppContext = new PouchDB('bd_AppContext', { adapter: 'cordova-sqlite' });
    this.dbAppContext = new PouchDB('bd_AppContext');
  }

  switchAppState(): Promise<any[]> {
    /**
     * Se comenta momentaneamente para compatibilidad de PouchDB
     */
    var promise = this.dbAppContext.get('stateApp').then((context: any) => {
      context.stateApp = !context.stateApp;

      return this.dbAppContext.put(context);
    }).catch((error: any) => {
      //console.log(error)
    });

    return promise;
  }

  getAppState(): Promise<any[]> {
    var promise = this.dbAppContext.get('stateApp').then((context: any) => {
      return context;
    });

    return promise;
  }

  initState(): Promise<any[]> {
    var promise = this.dbAppContext.get('stateApp').then((context: any) => {
    }).catch((error: any) => {
      if (error.name == 'not_found') {
        var appStateDoc = {
          _id: 'stateApp',
          stateApp: true
        };
        var promiseInsert = this.dbAppContext.put(appStateDoc);

        return promiseInsert;
      }
    });

    return promise;
  }

  insertUserApps(contextDoc: any): Promise<any[]> {
    var promise = this.dbAppContext.get('userApps').then((context: any) => {
      return this.dbAppContext.remove(context);
    }).then(() => {

      return this.dbAppContext.put(contextDoc);
    }).catch((error: any) => {
      if (error.name == 'not_found') {
        var promiseInsert = this.dbAppContext.put(contextDoc);

        return promiseInsert;
      }
    });

    return promise;
  }

  getUserApps(): Promise<any[]> {
    var promise = this.dbAppContext.get('userApps').then((context: any) => {
      return context;
    });

    return promise;
  }

  insertAppConfig(contextDoc: any): Promise<any[]> {
    var promise = this.dbAppContext.get('configApp').then((context: any) => {
      return this.dbAppContext.remove(context);
    }).then(() => {
      return this.dbAppContext.put(contextDoc);
    }).catch((error: any) => {
      if (error.name == 'not_found') {
        var promiseInsert = this.dbAppContext.put(contextDoc);

        return promiseInsert;
      }
    });

    return promise;
  }

  getAppConfig(): Promise<any[]> {
    var promise = this.dbAppContext.get('configApp').then((context: any) => {
      return context;
    });

    return promise;
  }

  insertUserInfo(contextDoc: any): Promise<any[]> {
    var promise = this.dbAppContext.get('userInfo').then((context: any) => {
      return this.dbAppContext.remove(context);
    }).then(() => {
      return this.dbAppContext.put(contextDoc);
    }).catch((error: any) => {
      if (error.name == 'not_found') {
        var promiseInsert = this.dbAppContext.put(contextDoc);

        return promiseInsert;
      }
    });

    return promise;
  }

  getUserInfo(): Promise<any[]> {
    var promise = this.dbAppContext.get('userInfo').then((context: any) => {
      return context;
    });

    return promise;
  }

  insertUserData(contextDoc: any): Promise<any[]> {
    var promise = this.dbAppContext.get('userData').then((context: any) => {
      return this.dbAppContext.remove(context);
    }).then(() => {
      return this.dbAppContext.put(contextDoc);
    }).catch((error: any) => {
      if (error.name == 'not_found') {
        var promiseInsert = this.dbAppContext.put(contextDoc);

        return promiseInsert;
      }
    });

    return promise;
  }

  getUserData(): Promise<any[]> {
    var promise = this.dbAppContext.get('userData').then((context: any) => {
      return context;
    });

    return promise;
  }

}
