import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-details',
  templateUrl: './modal-details.component.html',
  styleUrls: ['./modal-details.component.scss']
})
export class ModalDetailsComponent implements OnInit {

  @Input() isDetails: any = true;
  @Input() viewName: any;
  @Input() rowId: any;
  @Input() entityId: any;
  @Input() entityParentId: any;
  @Input() parent: any;
  @Input() parentRules: any;
  @Input() dataToSubmit: any;
  @Input() appViewIdDetails: any;
  @Input() isViewItem: any;
  @Input() detailsId: any;

  constructor(
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit(): void {
  }

  closeModal() {
    this.activeModal.close();
  }

  detailsAdded(data: any) {
    this.activeModal.close(data);
  }

  detailsEdited(data: any) {
    this.activeModal.close(data);
  }

}
